<template>
  <div class="dropdown-settings relative">
    <button
      aria-label="actions-menu"
      type="button"
      class="button btn-td-hover"
      :class="openClose ? 'active' : ''"
      v-click-outside="hide"
      @click="openClose = !openClose"
    >
      <inline-svg
        class="m-auto"
        :src="require('@/assets/image/icons/more-alt.svg')"
      />
    </button>
    <div v-if="openClose">
      <div class="dropdown" v-if="!item.item.access.edit">
        <div @click="claimView">View</div>
        <div @click="viewOriginal" v-if="item.item.has_original">
          View Original
        </div>
        <div @click="printClaim">Print</div>
        <div
          v-if="userCanDelete"
          class="delete"
          @click="showDeleteClaimModal = true"
        >
          Delete
        </div>
      </div>
      <div v-else>
        <div class="dropdown" v-if="item.item.status.id === 4">
          <div
            v-if="userCanDelete"
            class="delete"
            @click="showDeleteClaimModal = true"
          >
            Delete Draft
          </div>
          <div @click="continueClaim">Continue Claim</div>
        </div>
        <div class="dropdown" v-else>
          <div @click="claimView">View</div>
          <div @click="viewOriginal" v-if="item.item.has_original">
            View Original
          </div>
          <div @click="printClaim">Print</div>
          <div @click="editClaim" v-if="user && userCanEdit">Edit</div>
          <div
            class="delete"
            @click="showDeleteClaimModal = true"
            v-if="user && userCanDelete"
          >
            Delete
          </div>
          <div @click="ClaimCompensated" v-if="user && userCanApprove">
            Mark as Compensated
          </div>
          <div @click="claimHold" v-if="user && userCanApprove">
            Mark for Office Review
          </div>
          <div @click="returnDraft" v-if="user && userCanApprove">
            Return to Draft
          </div>
          <div @click="duplicateClaim" v-if="user && userCanDuplicate">
            Duplicate
          </div>
        </div>
      </div>
    </div>
    <ConfirmModal
      text="Are you sure you want to delete this claim?"
      confirmBtn="Delete claim"
      :show="showDeleteClaimModal"
      @confirm="deleteClaim"
      @close="showDeleteClaimModal = false"
    />
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import { http } from "../../../api";
import { mapActions, mapState } from "vuex";
import ConfirmModal from "../ConfirmModal";

export default {
  name: "ClaimsTableDropdown",
  props: ["item"],
  data() {
    return {
      openClose: false,
      showDeleteClaimModal: false,
    };
  },
  components: {
    ConfirmModal,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    userCanEdit: function () {
      return (
        this.user.permissions.includes("GLOBAL") ||
        this.user.permissions.includes("CLAIM_EDIT")
      );
    },
    userCanDelete: function () {
      return (
        this.user.permissions.includes("GLOBAL") ||
        this.user.permissions.includes("CLAIM_DEST")
      );
    },
    userCanDuplicate: function () {
      return (
        this.user.permissions.includes("GLOBAL") ||
        this.user.permissions.includes("CLAIM_DUPL")
      );
    },
    userCanApprove: function () {
      return (
        this.user.permissions.includes("GLOBAL") ||
        this.user.permissions.includes("CLAIM_APPR")
      );
    },
  },
  methods: {
    ...mapActions({
      deleteClaimById: "claim/deleteClaimById",
      getClaims: "claim/getClaims",
      getRegistrationsDetails: "registration/getRegistrationsDetails",
    }),
    hide() {
      this.openClose = false;
    },
    printClaim() {
      http
        .get("claims/" + this.item.item.id + "/pdf", {
          responseType: "blob",
        })
        .then((res) => {
          let blob = new Blob([res.data], { type: "application/pdf" });
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL);
        });
    },
    ClaimCompensated() {
      http
        .get("claims/" + this.item.item.id + "/compensate")
        .then((res) => {
          this.getClaims({ page: this.item.page });
          this.$toasted.success(res.data.message);
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    },
    editClaim() {
      if (this.item.item.access.edit) {
        this.$router.push("/claims/" + this.item.item.id + "/edit");
      } else {
        this.$toasted.error("You can only view orders that belong to you.");
      }
    },
    continueClaim() {
      if (this.item.item.access.edit) {
        this.$router.push("/claims/" + this.item.item.id + "/continue");
      } else {
        this.$toasted.error("You can only view orders that belong to you.");
      }
    },
    claimView() {
      this.$router.push("/claims/" + this.item.item.id);
    },
    returnDraft() {
      http
        .get("claims/" + this.item.item.id + "/return-to-draft")
        .then((res) => {
          this.getClaims({ page: this.item.page });
          this.getRegistrationsDetails({ id: this.$route.params.id });
          this.$toasted.success(res.data.message);
        });
    },
    claimHold() {
      http
        .get("claims/" + this.item.item.id + "/hold")
        .then((res) => {
          this.getClaims({ page: this.item.page });
          this.getRegistrationsDetails({ id: this.$route.params.id });
          this.$toasted.success(res.data.message);
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    },
    viewOriginal() {
      this.$router.push("/claims/" + this.item.item.id + "/original");
    },
    deleteClaim() {
      this.deleteClaimById({ id: this.item.item.id })
        .then((res) => {
          this.getClaims({ page: this.item.page });
          this.getRegistrationsDetails({ id: this.$route.params.id });
          this.$toasted.success(res.data.message);
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        })
        .finally(() => {
          this.showDeleteClaimModal = false;
        });
    },
    duplicateClaim() {
      let data = new FormData();
      data.append("original_claim", this.item.item.id);
      data.append("serial_no[]", this.item.item.tractor_sn);
      http
        .post("claims/copy-claim", data)
        .then((res) => {
          this.getClaims({ page: this.item.page });
          this.getRegistrationsDetails({ id: this.$route.params.id });
          this.$toasted.success(res.data.message);
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>
