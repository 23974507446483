import { http } from '../../../api';

const radioScripts = {
    namespaced: true,
    state: {
        radioScripts: [],
        pagination: {},
        initialPage: 1
    },
    actions: {
        getRadioScriptsList: ({ commit }, params) => {
            const q = new URLSearchParams(params);
            return http.get('/radio-scripts?' + q.toString()).then(res => {
                commit('setRadioScripts', res.data.data.radio_scripts)
            });
        },
        getInitialPage: ({ commit }, params) => {
            commit('setInitialPage', params)
        },
    },
    mutations: {
        setInitialPage(state, page) {
            state.initialPage = page
        },
        setRadioScripts(state, data){
            state.radioScripts = data.data;
            state.pagination = data.pagination;
        },
    },
    getters: {
        radioScripts: state => state.radioScripts,
        types: state => state.types,
        initialPage: state => state.initialPage,
    }
};

export default radioScripts;
