<template>
  <section class="manage col-span-12">
    <div class="container grid grid-cols-1 gap-0">
      <PageHeader
        title="Manage Categories"
        :saveButton="false"
        :discardLink="false"
        :addButton="true"
        addText="Add New Category"
        @add="$router.push({ name: 'addCategories' })"
        backText="Back to Manage Media"
        :backRoute="{ name: 'ManageMedia' }"
      />
      <div class="filter-block">
        <div>
          <div class="table-filter-search-input">
            <div class="form-group">
              <label class="control-label">Search</label>
              <input
                type="text"
                name="search"
                v-model="filterData.term"
                v-on:keyup.enter="filter"
                class="search"
                placeholder="Media"
              />
              <inline-svg
                class="search-icon"
                :src="require('@/assets/image/icons/search.svg')"
              />
            </div>
          </div>
          <div class="table-filter-dealership-select">
            <dropdown
              :data="types"
              :status="false"
              fieldName="name"
              label="Media Type"
              @select="selectMediaType"
              :validation="false"
              inputName="media_type"
              :editValue="selectedMediaType"
            ></dropdown>
          </div>
        </div>
        <div class="card">
          <div class="btn btn-black px-40" @click="filter">
            <div class="text"><a>Apply</a></div>
          </div>
          <div class="btn redo px-15" @click="resetFilter">
            <a>
              <inline-svg :src="require('@/assets/image/icons/redo.svg')" />
            </a>
          </div>
        </div>
      </div>
      <div class="table-block">
        <data-table
          :page="filterData.page"
          :header="fieldCategoriesTable.header"
          :data="mediaCategories"
          :link="{ url: '/admin/media/categories/', name: 'title' }"
          class="media-table"
          :isLoading="isLoading"
        />
      </div>
      <div class="pages">
        <div class="pages-quantity">
          <template v-if="!isLoading">
            Page {{ filterData.page }} of {{ pagination.total_pages }} - Showing
            {{ pagination.per_page }} Results of {{ pagination.total }} Total
          </template>
        </div>
        <paginate
          v-if="pagination.total_pages"
          :page-count="pagination.total_pages"
          :page-range="6"
          :initial-page="initialPage - 1"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="'&laquo;'"
          :next-text="'&raquo;'"
          :container-class="'pages-btn'"
          :active-class="pageActive"
        >
        </paginate>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../../component/PageHeader";
import DataTable from "../../../component/DataTable";
import Dropdown from "../../../component/Dropdown";
import { mapActions, mapState } from "vuex";

export default {
  name: "ManageCategories",
  data() {
    return {
      isLoading: false,
      selectedMediaType: "All",
      fieldCategoriesTable: {
        header: [
          {
            field: "title",
            name: "Category Title",
            transformToComponent: "TransformToLink",
            width: "31%",
            headerWidth: "30%",
          },
          { field: "media_type.name", name: "Media Type" },
          {
            field: "status",
            name: "Status",
            transformField: "status",
            transform: this.transformToStatus,
          },
          { field: "date_added", name: "Date Added" },
          {
            field: "id",
            name: "Actions",
            transformToComponent: "MediaCategoryDropdownSettings",
          },
        ],
      },
      filterData: {
        page: 1,
      },
      pageActive: "active",
    };
  },
  components: {
    PageHeader,
    DataTable,
    Dropdown,
  },
  computed: {
    ...mapState({
      mediaCategories: (state) => state.mediaCategory.mediaCategories,
      pagination: (state) => state.mediaCategory.pagination,
      types: (state) => state.mediaCategory.types,
      initialPage: (state) => state.mediaCategory.initialPage,
    }),
  },
  mounted() {
    this.getMediaCategoriesList();
  },
  methods: {
    ...mapActions({
      getMediaCategories: "mediaCategory/getMediaCategories",
      filterCategories: "mediaCategory/filterCategories",
      getInitialPage: "mediaCategory/getInitialPage",
    }),
    getMediaCategoriesList() {
      this.filterData.page = this.initialPage;
      this.isLoading = true;
      this.getMediaCategories({ page: this.filterData.page })
        .then((res) => {})
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    filter() {
      this.filterCategories(this.filterData)
        .then((res) => {})
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    resetFilter() {
      this.pageActive = "";
      this.filterData = {
        page: 1,
      };
      this.selectedMediaType = "All";
      this.filterData.type_id = "";
      this.filterData.term = "";
      this.getInitialPage(1);
      this.filter();
    },
    transformToStatus(id, val) {
      let background;
      let border;
      let color;
      let width;
      switch (id) {
        case "Active":
          background = "rgba(99, 220, 43, 0.3)";
          color = "#24610E";
          border = "none";
          width = "77px";
          break;
        case "Inactive":
          background = "rgba(212, 4, 4, 0.2)";
          color = "#7D0505";
          border = "none";
          width = "77px";
          break;
      }
      return (
        '<div class="td-status" style="background-color:' +
        background +
        ";color:" +
        color +
        ";border:" +
        border +
        ";width:" +
        width +
        '">' +
        val +
        "</div>"
      );
    },
    selectMediaType(type) {
      this.filterData.page = 1;
      this.selectedMediaType = type.name;
      this.filterData.type_id = type.id;
      this.filter();
    },
    clickCallback(pageNum) {
      this.getInitialPage(pageNum);
      this.filterData.page = pageNum;
      this.pageActive = "active";
      this.filter();
    },
  },
};
</script>

<style scoped>
</style>
