<template>
  <section class="user-details col-span-12">
    <div class="container">
      <PageHeader
        backText="Back to Manage Tractors"
        :backRoute="{ name: 'ManageTractors' }"
        :saveButton="false"
        :discardLink="false"
        :details="{
          title: tractor_item.tractor_model,
          subtitle:
            (tractor_item.mower && tractor_item.mower.model) || 'None Selected',
        }"
      >
        <template v-slot:actions>
          <router-link
            tag="button"
            class="btn btn-info px-40"
            :to="'/admin/tractor/' + tractor_item.id + '/edit'"
            >Edit</router-link
          >
        </template>
      </PageHeader>
      <div class="user-information">
        <div class="information" v-if="tractor_item">
          <div class="subtitle">Tractor Details</div>
          <div class="information-block">
            <div class="block-container">
              <div
                v-if="
                  tractor_item.transmissions &&
                  tractor_item.transmissions.length
                "
              >
                <p>Transmission Types</p>
                <div>
                  <span v-for="type in tractor_item.transmissions"
                    >{{ type.trans_type }}
                  </span>
                </div>
              </div>

              <div
                v-if="
                  tractor_item.configurations &&
                  tractor_item.configurations.length
                "
              >
                <p>Configurations</p>
                <p
                  class="mt-1"
                  v-for="configuration in tractor_item.configurations"
                >
                  <span>{{ configuration.description }} </span>
                </p>
              </div>
              <div v-if="tractor_item.rops && tractor_item.rops.length">
                <p>ROPs</p>
                <div>
                  <span v-for="rop in tractor_item.rops">{{ rop.rops }} </span>
                </div>
              </div>
              <div v-if="tractor_item.tires && tractor_item.tires.length">
                <p>Tires</p>
                <ul>
                  <li v-for="tire in tractor_item.tires">
                    {{ tire.description }}
                  </li>
                </ul>
              </div>
              <div v-if="tractor_item.loaders && tractor_item.loaders.length">
                <p>Loader Model</p>
                <span v-for="loader in tractor_item.loaders">{{
                  loader.name
                }}</span>
              </div>
              <div v-if="tractor_item.backhoes && tractor_item.backhoes.length">
                <p>Loader Buckets</p>
                <ul>
                  <li v-for="backhoe in tractor_item.backhoes">
                    {{ backhoe.name }}
                  </li>
                  <li>16" Bucket</li>
                </ul>
              </div>
              <div v-if="tractor_item.bachoes && tractor_item.bachoes.length">
                <p>Backhoe Model</p>
                <ul>
                  <li v-for="bachoe in tractor_item.bachoes">
                    {{ bachoe.name }}
                  </li>
                </ul>
              </div>
              <div>
                <p>Mid Mount Mower</p>
                <span v-if="tractor_item.mower">{{
                  tractor_item.mower.model
                }}</span>
                <span v-else>None Selected</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tractor" v-if="tractor_item">
        <div class="subtitle">Tractor Image</div>
        <div>
          <div class="tractor-image">
            <img
              v-if="tractor_item.tractor_image.file"
              :src="tractor_item.tractor_image.file"
              :alt="tractor_item.tractor_image.name"
            />
            <img src="@/assets/image/no-image.png" v-else alt="tractor-img" />
          </div>
          <div class="file">
            <a
              :href="tractor_item.tractor_image.file"
              @click.prevent="
                downloadTractorImage(tractor_item.tractor_image.file)
              "
              >{{ tractor_item.tractor_image.name }}
              <inline-svg
                :src="require('@/assets/image/icons/download-blue.svg')"
            /></a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../../component/PageHeader";
import { mapActions, mapState } from "vuex";
import { http } from "@/api";

export default {
  name: "tractorDetail",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      tractor_item: (state) => state.tractor.tractor_item,
    }),
  },
  mounted() {
    this.getTractorItem({ id: this.$route.params.id })
      .then((res) => {})
      .catch((err) => {
        this.$toasted.error(err.response.data.message || "Server Error");
      });
  },
  methods: {
    ...mapActions({
      getTractorItem: "tractor/getTractorItem",
    }),
    downloadTractorImage(url) {
      http
        .get(url, {
          responseType: "blob",
        })
        .then((res) => {
          let blob = new Blob([res.data], {
            type: res.headers["content-type"],
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "tractor";
          link.click();
        })
        .catch((err) => {
          if (err.response.status === 404) {
            this.$toasted.error(
              "File could not be located on server. Please contact TYM for assistance."
            );
          }
        });
    },
  },
  components: {
    PageHeader,
  },
};
</script>

<style scoped>
</style>
