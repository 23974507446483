<template>
  <ValidationObserver tag="div" ref="form" class="c-retailProgramEntry text-sm">
    <div class="p-5">
      <div v-if="
        remainingManualEligibilities.length || //programs available to submit
        entries_auto.length || //auto programs available
        entries_manual.filter(p => !p.hide).length //manual entries that are not hidden
      ">
        <template v-if="type.use_salesperson">
          <h4 class="inline-block mb-2 pr-4 font-medium">Salesperson:</h4>
          <div class="mb-6 inline-block">
            <dropdown
              :data="salespeople"
              :status="false"
              fieldName="name"
              inputName="salesperson"
              @select="selectSalesperson"
              :all="false"
              :empty="true"
              :emptyLabel="`No ${type.name}`"
              :search="false"
              :editValue="selectedSalesperson && selectedSalesperson.name"
            ></dropdown>
          </div>
        </template>
        <div class="c-retailProgramEntryLine c-retailProgramEntryLine--header">
          <div class="c-retailProgramEntryLine__name pr-4">
            Eligible Program
          </div>
          <div class="pl-4 c-retailProgramEntryLine__description">
            Description
          </div>
          <div class="pl-1 c-retailProgramEntryLine__amount">Amount</div>
          <div class="pl-2 c-retailProgramEntryLine__upload">Attachment</div>
        </div>
        <div
          v-for="(program, index) in entries_auto"
          class="form-group c-retailProgramEntryLine"
          :key="`retail_program_${type.id}_entry_auto_${index}`"
        >
          <div class="c-retailProgramEntryLine__name flex items-center">
            <span
              class="mr-1 submit_type_icon"
              v-bind:class="{
                'text-gray-800': program.submit_type == 'auto',
                'text-green-600': program.submit_type == 'preapproval',
              }"
              style="flex: 0 0 16px"
            >
              <inline-svg
                v-if="submitTypeIcon(program.submit_type)"
                class="w-full h-auto"
                :src="
                  require(`@/assets/image/icons/${submitTypeIcon(
                    program.submit_type
                  )}.svg`)
                "
              />
            </span>
            {{ program.nameWithSn }}
          </div>
          <div class="c-retailProgramEntryLine__description">
            <span class="inline-block pl-4">{{ program.description }}</span>
          </div>

          <div class="pl-2 c-retailProgramEntryLine__amount">
            $ {{ program.amount && program.amount.toLocaleString("en-US") }}
          </div>
          <div class="pl-2 c-retailProgramEntryLine__upload"></div>
        </div>
        <template
          v-for="(program, index) in entries_manual"
        >
          <div
            class="form-group c-retailProgramEntryLine"
            v-if="!program.hide"
            :key="`retail_program_${type.id}_entry_${index}`"
          >
            <div class="c-retailProgramEntryLine__name">
              <dropdown
                :data="remainingManualEligibilities"
                :status="false"
                fieldName="nameWithSn"
                inputName="program"
                @select="selectBonusEntry($event, index)"
                :all="false"
                :search="false"
                :editValue="program.nameWithSn"
              ></dropdown>
              <!-- <span class="text-red-500">{{ errorMessageDealership }}</span> -->
            </div>
            <div class="c-retailProgramEntryLine__description">
              <input
                type="text"
                v-model="program.description"
                name="program_description"
                placeholder="Enter Description"
              />
            </div>

            <div class="c-retailProgramEntryLine__amount">
              <div class="relative">
                <span
                  style="top: 50%"
                  class="block absolute left-0 pl-2 transform -translate-y-1/2"
                  >$</span
                >
                <input
                  v-if="program.pricing == 'variable'"
                  type="text"
                  placeholder=" -"
                  v-model="program.amount"
                  style="padding-left: 18px; padding-right: 5px"
                />
                <input
                  v-else
                  type="text"
                  :value="
                    program.amount && program.amount.toLocaleString('en-US')
                  "
                  disabled
                  class="opacity-50"
                  style="padding-left: 18px; padding-right: 5px"
                />
              </div>
            </div>
            <div class="c-retailProgramEntryLine__upload">
              <FileInput
                v-if="program.require_attachment"
                name="retail_program_attachment"
                inputClass="h-full"
                accept="image/jpeg, image/jpg, image/png, image/gif, .pdf"
                :validation="true"
                @change="
                  program.file = {
                    data: $event.fileData,
                    name: $event.file.name,
                  }
                "
                @delete-file="delete program.file"
              />
            </div>

            <button
              type="button"
              class="right-0 c-removeButton"
              @click="removeProgram(index)"
            ></button>
          </div>
        </template>
        <button
          type="button"
          class="c-btn c-btn--outline mt-6 mb-2"
          v-if="remainingManualEligibilities.length"
          @click="addBonusEntry"
        >
          + Add {{ type.name_abv }}
        </button>
      </div>
      <div v-else class="py-5">
        <p class="italic">There are no bonuses available in this category</p>
      </div>
    </div>
    <div class="dropdown-bottom">
      <div class="font-medium">
        <span>Total Requested Amount: </span>
        <span class="text-gray-800 inline-block ml-1"
          >${{
            bonus_total.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          }}</span
        >
      </div>

      <div class="flex">
        <RadioInput
          :model.sync="paymentMethod"
          labelClass="mr-4"
          name="payment_method"
          value="check"
          label="Issue Check"
          :disabled="payMethodFixed"
        />
        <RadioInput
          :model.sync="paymentMethod"
          labelClass="mr-8"
          name="payment_method"
          value="credit"
          label="Issue Credit"
          :disabled="payMethodFixed"
        />
      </div>
    </div>
  </ValidationObserver>
</template>


<script>
import { mapActions, mapState } from "vuex";
import Dropdown from "../../Dropdown";
import RadioInput from "../../Inputs/Radio";
import FileInput from "../../Inputs/File";

export default {
  name: "RetailProgramTypeForm",
  data() {
    return {
      entries_manual: [],
      paymentMethod: "credit",

      selectedSalesperson: null,
    };
  },
  props: {
    type: {
      type: Object,
      required: true,
    },
    products: {
      type: Array,
      required: true,
    },
    salespeople: {
      type: Array,
      required: true,
    },
    dealership: {
      type: Object,
      required: false,
      default() {
        return null;
      },
    },
  },
  components: {
    Dropdown,
    RadioInput,
    FileInput,
  },
  computed: {
    payMethodFixed: function () {
      return this.selectedSalesperson?.id ? true : false;
    },
    availablePrograms: function () {
      return this.type.programs;
    },
    remainingEligibilities: function () {
      const vm = this;

      return this.products.reduce(
        (prev, prod) => [
          ...prev,
          ...vm.availablePrograms
            //pricing is variable, or has a listing for the selected model
            ?.filter(
              (program) =>
                //sn exists, model not required
                prod.sn &&
                //filter out programs with matching preapprovals
                !prod.preapprovals?.find((x) => x.program?.id === program.id) &&
                //pricing is variable, or has a bonus for this model
                (program.pricing == "variable" ||
                  program.eligibilities?.find((e) => e.model == prod.model)) &&
                //program has not already been filed
                !prod.filed_programs.includes(program.id)
            )
            //copy program details, adding product sn, model, and eligible amount
            ?.map((program) => ({
              program_id: program.id,
              name: program.name,
              description: program.description,
              pricing: program.pricing,
              require_attachment: program.require_attachment,
              auto_populate: program.auto_populate,
              model: prod.model,
              sn: prod.sn,
              nameWithSn: program.name + " - " + prod.sn,
              amount: program.eligibilities?.find((e) => e.model == prod.model)
                ?.amount,
              submit_type: program.auto_populate ? "auto" : "manual",
            })),
          //product preapprovals
          ...prod.preapprovals
            //match preapprovals to this program type
            ?.filter((pa) => pa.program?.type?.id === this.type.id)
            //match preapprovals to selected dealership
            ?.filter((pa) => pa.dealership?.id === this.dealership?.id)
            .map((pa) => ({
              program_id: pa.program?.id,
              name: pa.program?.name,
              description: pa.notes,
              pricing: "flat",
              require_attachment: false,
              auto_populate: true,
              model: prod.model,
              sn: prod.sn,
              nameWithSn: pa.program?.name + " - " + prod.sn,
              amount: pa.amount,
              submit_type: "preapproval",
              preapproval_id: pa.id,
            })),
        ],
        []
      );
    },
    remainingManualEligibilities: function () {
      return (
        this.remainingEligibilities
          //skip auto-populate items
          ?.filter((program) => !program.auto_populate)
          //remove program entries that have already been selected
          ?.filter(
            (program) =>
              !this.entries_manual.find(
                (p) => p.name === program.name && p.sn === program.sn
              )
          )
          ?.sort(
            (a, b) => a.name.localeCompare(b.sn) || a.name.localeCompare(b.name)
          ) ?? []
      );
    },
    entries_auto: function () {
      return this.remainingEligibilities
        .filter((program) => program.auto_populate)
        .sort((a, b) => {
          if (a.submit_type != b.submit_type && a.submit_type == "preapproval")
            return -1;
          return a.name.localeCompare(b.sn) || a.name.localeCompare(b.name);
        });
    },
    entries: function () {
      return [...this.entries_auto, ...this.entries_manual.filter(p => !p.hide)];
    },
    bonus_total: function () {
      if (this.entries?.length > 0) {
        let total = this.entries.reduce((prev, curr) => {
          let nextNumber = parseFloat(curr.amount) || 0;
          return parseFloat(prev) + nextNumber;
        }, 0);

        return parseFloat(total);
        // return parseFloat(total).toFixed(2);
      } else {
        return 0;
      }
    },

    submitData: function () {
      if (!this.entries.length) return null;
      if (this.type.use_salesperson && !this.selectedSalesperson) return null;

      return {
        program_type_id: this.type.id,
        lines: [...this.entries.filter((p) => p.program_id)],
        pay_method: this.paymentMethod,
        salesperson_id: this.selectedSalesperson?.id,
      };
    },
  },

  watch: {
    // dealership: function (val) {
    //   if (val && this.selectedSalesperson?.dealership_id != val?.id) {
    //     // this.selectedSalesperson = this.salespeople.find(p => !p.id) ?? null;
    //   }
    // },
    salespeople(val, oldVal) {
      console.log('salespeople change');
      if (this.type.use_salesperson) {
        this.selectedSalesperson = this.salespeople.find(p => !p.id) ?? null;
      }
    },
  },

  created() {
  },

  methods: {
    ...mapActions({}),

    selectSalesperson(person) {
      this.selectedSalesperson = person;
      if (!person.name) this.selectedSalesperson = null;

      this.paymentMethod = this.selectedSalesperson?.id ? "check" : "credit";
    },

    selectBonusEntry(program, i) {
      this.$set(this.entries_manual, i, program);

      this.$forceUpdate();
    },
    addBonusEntry() {
      this.entries_manual.push({
        program_id: null,
        nameWithSn: "",
        description: "",
        amount: null,
      });

    },
    removeProgram(i) {
      this.entries_manual.splice(i, 1);
    },

    getProgramAmount(program) {
      const entry = program.eligibilities?.find(
        (e) => e.model == this.selectedModels.tractor
      );
      return entry?.amount;
    },

    submitTypeIcon(type) {
      if (!type) return null;

      const icons = {
        preapproval: "check_circle",
        auto: "settings",
      };
      return icons[type] ?? null;
    },

    updateManualEntries() {
      //loop through manual entries
      this.entries_manual.forEach((program, index) => {

        //find each manual entry in the list of remaining eligibilities
        const found = this.remainingEligibilities.find(p => !p.auto_populate && p.name === program.name && p.sn === program.sn);

        //if found, display entry and sync pricing for fixed programs
        const updates = found ? {
          hide: false,
          amount: found.pricing == 'variable' ? program.amount : found.amount,
        } : {
          hide: true,
        }
        
        //replace manual entry with new program object and merged updates
        this.entries_manual.splice(index, 1, {
          ...program,
          ...updates,
        });
      });

      //refresh front-end once manual entries are updated
      this.$forceUpdate();
    }
  },
};
</script>


<style lang="scss" scoped>
.c-retailProgramEntry {
  $border: 1px solid #cdcdcd;

  margin-bottom: -20px;

  .dropdown-bottom {
    border-top: $border;
    display: flex;

    @media (max-width: 640px) {
      flex-direction: column;
    }

    > * {
      padding: 20px;

      &:not(:last-child) {
        border-right: $border;

        @media (max-width: 640px) {
          border-right: none;
          border-bottom: $border;
        }
      }
    }
  }
}

.c-retailProgramEntryLine {
  margin-top: 10px;
  padding-right: 16px;
  position: relative;
  display: flex;

  // & /deep/ input[type="text"] {
  //   padding-left: 10px;
  //   padding-right: 10px;
  // }

  &--header {
    font-weight: $font-weight-medium;
  }

  @media (max-width: 900px) {
    padding-right: 8px;

    .c-removeButton {
      right: -6px;
    }
  }

  > div {
    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }

  &__name {
    flex: 1 1 50%;

    & /deep/ input.input {
      padding-left: 20px;
    }
  }

  &__description {
    flex: 1 1 50%;
  }

  &__amount {
    flex: 0 0 65px;
  }

  &__upload {
    flex: 0 0 250px;
    padding-right: 8px;

    @media (max-width: 900px) {
      flex: 0 0 170px;
      max-width: 170px;

      > div {
        justify-content: center;
      }

      /deep/ {
        [data-no-file-message] {
          display: none;
        }

        [data-choose-file-button] {
          margin-right: 0px;
        }

        [data-uploaded-file-name] {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          margin-right: 10px;
        }
      }
    }
  }
}

.c-btn {
  height: unset;
  padding: 10px 12px;
}

.c-removeButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.submit_type_icon {
  /deep/ path {
    fill: currentColor;
  }
}
</style>