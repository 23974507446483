<template>
  <div class="dropdown-settings relative" v-click-outside="hide">
    <button
      aria-label="actions-menu"
      type="button"
      class="button btn-td-hover"
      :class="openClose ? 'active' : ''"
      @click="openClose = !openClose"
    >
      <inline-svg
        class="m-auto"
        :src="require('@/assets/image/icons/more-alt.svg')"
      />
    </button>
    <div class="dropdown" v-if="openClose">
      <router-link
        tag="div"
        :to="`/retail-programs/${item.item.id}/edit`"
        v-if="userCanEditProgram(item)"
      >
        Edit Submission
      </router-link>
      <router-link tag="div" :to="`/retail-programs/${item.item.id}`" v-else>
        View Submission
      </router-link>
      <router-link
        tag="div"
        v-if="userCanViewRegistrations"
        :to="`/registrations/${item.item.registration_id}`"
      >
        View Registration
      </router-link>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import { mapActions, mapState } from "vuex";
import { http } from "../../../api";

export default {
  name: "ProgramsTableDropdown",
  props: ["item"],
  components: {},
  data() {
    return {
      openClose: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    userCanViewRegistrations: function () {
      return (
        this.user.permissions.includes("GLOBAL") ||
        this.user.permissions.includes("REG_VIEW")
      );
    },
  },
  created() {},
  methods: {
    ...mapActions({
      //   deleteWarranty: "registration/deleteWarranty",
      //   getRegistrations: "registration/getRegistrations",
    }),
    hide() {
      this.openClose = false;
    },
    userCanEditProgram: function (item) {
      return (
        this.user.permissions.includes("GLOBAL") ||
        this.user.permission_actions?.[
          "Retail Program"
        ]?.Edit?.from_status?.includes(item.item.status.description)
      );
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style lang="scss" scoped>
</style>
