<template>
  <div class="home">
    <div>
      <sidebar />
      <div class="main grid grid-cols-12 gap-0">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "../../component/Sidebar";

export default {
  name: "Main",
  components: {
    Sidebar,
  },
};
</script>

<style scoped>
</style>
