<template>
    <div class="auth">
        <div class="container">
            <div class="logo">
                <img
                    class="m-auto"
                    :src="require('@/assets/image/logo.png')"
                    alt="Tym-Tractors"
                    :srcset="`${require('@/assets/image/logo.png')},
                            ${require('@/assets/image/logo@2x.png')} 2x`"
                />
            </div>
           <div class="panel">
               <div class="panel-heading">Login</div>
               <ValidationObserver tag="div" class="panel-body" ref="form">
                   <form class="edit-form" @submit.prevent="signIn">
                       <div class="form-group">
                           <label class="control-label">Email Address</label>
                           <ValidationProvider rules="required|email" tag="div" v-slot="{ classes }">
                               <input type="email" v-model="loginFormData.email" name="email" value="" :class="classes">
                           </ValidationProvider>
                       </div>
                       <div class="form-group">
                           <label class="control-label ">Password</label>
                           <ValidationProvider rules="required" tag="div" v-slot="{ classes }">
                               <input type="password" v-model="loginFormData.password" autocomplete="off" name="password" value="" :class="classes">
                           </ValidationProvider>
                       </div>
                       <div class="form-group">
                           <label class="control-label"></label>
                           <div class="check-txt">
                               <label for="remember" class="cursor-pointer">
                                   <input type="checkbox" id="remember" name="remember" value="Bike" >
                                   <span></span>
                                   Remember Me
                               </label>
                           </div>
                       </div>
                       <div class="form-group">
                           <div class="formBtn">
                               <button type="submit" class="btn">Log In</button>
                               <router-link :to="{name: 'ForgotPassword'}" class="note">Forgot your password?</router-link>
                           </div>
                       </div>
                       <div class="error-msg" v-if="errorMessage">
                           <p>{{errorMessage}}</p>
                       </div>
                   </form>
               </ValidationObserver>
           </div>
        </div>
    </div>
</template>
<script>

import {mapActions} from 'vuex'

export default {
    name: 'Auth',
    data () {
        return {
            loginFormData:{
                email:'',
                password:'',
            },
            errorMessage: ''
        }
    },
    methods: {
        ...mapActions({
            'login': 'auth/login',
        }),
        signIn(){
            this.$refs.form.validate().then(success => {
                if (success) {
                    this.login({data: this.loginFormData}).then(() => {
                        this.$router.push('/dashboard');
                    }).catch(err => {
                        if(err.response.data.message == "Sorry, wrong email address or password. Please try again") {
                            this.errorMessage = 'Login and/or Password are incorrect!'
                        }
                    })
                }
            })
        },
    }
}

</script>
