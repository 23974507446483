import { http } from '../../../api';

const user = {
    namespaced: true,
    state: {
        user: {},
        users: {},
        userDealershipList: [],
        userTypesList: [],
        initialPage: 1,
        userPermissionsList: [],

        indexFilters: {}
    },
    actions: {
        getUsers: ({ commit }) => {
            return http.get('users')
                .then(res => {
                    commit('setUsers', res.data.data.users)
                    commit('setUsersDealership', res.data.data.dealership)
                    commit('setUsersUserTypes', res.data.data.user_types)
                    commit('setUsersPermissions', res.data.data.permissions)
                })
                .catch(error => {
                    throw error;
                });
        },
        getUsersData: ({commit}) => {
            return http.get('users')
                .then(res => {
                    commit('setUsersDealership', res.data.data.dealership)
                    commit('setUsersUserTypes', res.data.data.user_types)
                    commit('setUsersPermissions', res.data.data.permissions)
                })
                .catch(error => {
                    throw error;
                });
        },
        getUser: ({ commit }, params) => {
            return http.get('users/' + params.id)
                .then(res => {
                    commit('setUser', res.data.data.user);
                    return res.data.data.user;
                })
        },
        createUsers: ({ commit }, params) => {
            return http.post('users', params)
                .then(res => {
                    return res;
                })
                .catch(error => {
                    throw error;
                });
        },
        editUser: ({commit}, params) => {
            return http.get("/users/" + params.id + "/edit", params)
                .then(res => {
                    return res;
                })
                .catch(error => {
                    throw error;
                });
        },
        updateUser: ({ commit }, params) => {
            return http.post('users/' + params.id, params.data)
                .then(res => {
                    return res;
                })
                .catch(error => {
                    throw error;
                });
        },
        removeUser: ({ commit }, params) => {
            return http.delete('/users/' + params.id)
                .then(res => {
                    commit('deleteUser', params.id)
                    return res;
                })
                .catch(error => {
                    throw error;
                });
        },
        filterUsers: ({ commit }, params) => {
            const q = new URLSearchParams(params);
            return http.get('users/filter?' + q.toString())
                .then(res => {
                    commit('setUsers', res.data.data.users)
                    commit('setUsersDealership', res.data.data.dealership)
                    commit('setUsersUserTypes', res.data.data.user_types);
                    commit('setUsersPermissions', res.data.data.permissions)
                    return res;
                })
                .catch(error => {
                    throw error;
                });
        },
        getInitialPage: ({ commit }, params) => {
            commit('setInitialPage', params)
        },
        setIndexFilters: ({commit}, params) => {
            commit('setIndexFilters', {...params});
        }
    },
    mutations: {
        setInitialPage(state, page) {
            state.initialPage = page
        },
        setUser(state, data) {
            state.user = data;
        },
        setUsers(state, data) {
            state.users = data;
        },
        deleteUser(state, id) {
            state.users.data = state.users.data.map(item => {
                if (id === item.id) {
                    item.status = 'Inactive'
                }
                return item;
            })
        },
        setUsersDealership(state, data) {
            state.userDealershipList = data;
        },
        setUsersUserTypes(state, data) {
            state.userTypesList = data;
        },
        setUsersPermissions(state, data) {
            if (!data) return;
            state.userPermissionsList = data.sort((a,b) => {
                return a.name > b.name ? 1 : -1;
            });
        },
        setIndexFilters(state, data) {
            state.indexFilters = data;
        }
    },
    getters: {
        users: state =>  state.users,
        user: state =>  state.user,
        userDealershipList: state =>  state.userDealershipList,
        userTypesList: state =>  state.userTypesList,
        initialPage: state =>  state.initialPage,
        userPermissionsList: state =>  state.userPermissionsList,
        indexFilters: state => state.indexFilters,
    }
};

export default user;
