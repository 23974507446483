<template>
  <div class="fixed z-50 inset-0 overflow-y-auto" v-if="showModal">
    <div
      class="
        flex
        items-end
        justify-center
        min-h-screen
        pt-4
        px-4
        pb-20
        text-center
        sm:block sm:p-0
      "
    >
      <div
        @click="hide"
        class="fixed inset-0 transition-opacity"
        aria-hidden="true"
      >
        <div class="absolute inset-0 bg-block"></div>
      </div>
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="
          inline-block
          align-bottom
          bg-white
          rounded-lg
          text-left
          overflow-hidden
          shadow-xl
          transform
          transition-all
          sm:my-8 sm:align-middle sm:max-w-lg sm:w-full
        "
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="container-modal">
            <div>{{ text }}</div>
            <div>
              <button
                type="button"
                v-bind:class="{ restored: button === 'restored' }"
                @click="confirm"
                ref="confirmButton"
              >
                {{ confirmBtn }}
              </button>
              <p @click="hide">{{ cancelBtn }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmModal",
  props: {
    show: {
      type: Boolean,
      default: false,
      required: false,
    },
    text: {
      type: String,
      required: false,
    },
    confirmBtn: {
      type: String,
      default: "Delete",
      required: false,
    },
    cancelBtn: {
      type: String,
      default: "Cancel",
      required: false,
    },
    button: {
      type: String,
      default: "delete",
      required: false,
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {
    hide() {
      this.showModal = false;
      this.$emit("close");
    },
    confirm() {
      this.$emit("confirm");
    },
  },
  watch: {
    show: function (newVal, oldVal) {
      this.showModal = newVal;

      if (newVal === true) {
        this.$nextTick((_) => {
          this.$refs.confirmButton.focus();
        });
      }
    },
  },
};
</script>

<style type="text/css" scoped lang="scss">
.bg-white {
  height: min-content;
  max-width: max-content !important;
  .container-modal {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    div:first-of-type {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #383838;
    }
    div:last-of-type {
      display: flex;
      align-items: center;
      margin-left: 10px;
      padding-top: 5px;
      padding-bottom: 5px;

      button {
        padding: 8px 10px;
        background: rgba(212, 4, 4, 0.2);
        border-radius: 6px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #7d0505;
        margin-right: 20px;
        outline: none;
        white-space: nowrap;

        &:focus {
          outline: 1px solid black;
        }
      }
      .restored {
        background: rgba(0, 128, 0, 0.2);
        color: #007d13;
        white-space: nowrap;
      }
      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        text-decoration-line: underline;
        color: #383838;
        cursor: pointer;
        white-space: nowrap;
      }
    }
  }
}

.bg-block {
  background: rgba(113, 113, 113, 0.8);
}
</style>
