<template>
  <div class="tables">
    <div class="table">
      <div class="data-table-head">
        <div class="table-head-container">
          <div class="tr">
            <div
              class="th text-center"
              :style="
                `width: ${item.headerWidth};margin-left:${item.headerMl};max-width:${item.maxWidth}`
              "
              v-for="(item, index) in header"
              :key="index"
              v-if="user && user.role"
              v-bind:class="{
                'td-admin': user.role.id === 3,
                'sort-arrow': sortArrow,
                'sorted-column': tableSortField.name == item.field,
                'sorted-reverse':
                  tableSortField.name == item.field && tableSortField.reverse,
              }"
              @click="sortArrow && sortField(item.field)"
            >
              {{ item.name
              }}<inline-svg
                style="flex: 0 0 auto"
                v-if="sortArrow"
                :src="require('@/assets/image/icons/sort-arrows.svg')"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="tbody" :class="scroll ? 'table-scroll' : ''">
        <div v-if="isLoading" class="text-center no-result">
          <loading />
        </div>
        <div v-else>
          <div v-if="data && data.length === 0" class="text-center no-result">
            <span>No results found. Please adjust your search criteria.</span>
          </div>
          <div class="tr" v-for="(item, i) in data" :key="i">
            <div
              class="td"
              v-for="(headerItem, h) in header"
              :key="`header_item_${h}`"
              :style="
                `width: ${headerItem.width};margin-left:${headerItem.ml};max-width:${headerItem.maxWidth}`
              "
              :class="headerItem.class"
            >
              <span v-if="headerItem.showIndex">
                {{ ++i }}
              </span>
              <span class="text-center" v-if="headerItem.transformToComponent">
                <component
                  :is="headerItem.transformToComponent"
                  :warranty="{ page: page, item: item }"
                  :item="{ item: item, page: page }"
                  :order="{ item: item, page: page }"
                  :link="link"
                  :linkItem="item"
                ></component>
              </span>
              <span
                class="text-center"
                v-else-if="headerItem.transform"
                v-html="
                  headerItem.transform(
                    transformDataStructure(item, headerItem.transformField),
                    transformDataStructure(item, headerItem.field)
                  )
                "
              ></span>
              <span v-else class="text-center">
                <template v-if="headerItem.field == 'turnRatio'">
                  {{
                    transformDataStructure(item, headerItem.field).toFixed(2)
                  }}
                </template>
                <template v-else>
                  {{ transformDataStructure(item, headerItem.field) }}
                </template>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ManageUsersDropdownSettings from "../component/ManageUsersDropdownSettings";
import OrdersTableDropdown from "../component/OrdersTableDropdown";
import ClaimsTableDropdown from "../component/ClaimsTableDropdown";
import RegistrationsTableDropdown from "../component/RegistrationsTableDropdown";
import InventoryTableDropdown from "../component/InventoryTableDropdown";
import MediaDropdownSettings from "../component/MediaDropdownSettings";
import TractorDropdownSettings from "../component/TractorDropdownSettings";
import MediaCategoryDropdownSettings from "../component/MediaCategoryDropdownSettings";
import VideoDropdownSettings from "../component/VideoDropdownSettings";
import TransformToLink from "../component/TransformToLink";
import DealersTableDropdown from "../component/DealersTableDropdown";
import CoOpClaimDropdown from "../component/CoOpClaimDropdown";
import NewsTableDropdown from "../component/NewsTableDropdown";
import ProgramsTableDropdown from "./ProgramsTableDropdown";
import ProgramPreapprovalsTableDropdown from "./ProgramPreapprovalsTableDropdown";
import ModelInfoTableDropdown from "./ModelInfoTableDropdown";
import SerialsTableDropdown from "./SerialsDropdown";
import { mapState } from "vuex";
import Loading from "./Loading";

export default {
  name: "DataTable",
  props: {
    page: {
      type: Number,
      required: false,
    },
    scroll: {
      type: Boolean,
      required: false,
      default: false,
    },
    sortArrow: {
      type: Boolean,
      required: false,
      default: false,
    },
    header: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    link: {
      type: Object,
      required: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      tableSortField: {
        name: "",
        reverse: false,
      },
    };
  },
  components: {
    NewsTableDropdown,
    ClaimsTableDropdown,
    ManageUsersDropdownSettings,
    OrdersTableDropdown,
    RegistrationsTableDropdown,
    InventoryTableDropdown,
    MediaDropdownSettings,
    TransformToLink,
    DealersTableDropdown,
    VideoDropdownSettings,
    MediaCategoryDropdownSettings,
    TractorDropdownSettings,
    CoOpClaimDropdown,
    ProgramsTableDropdown,
    ProgramPreapprovalsTableDropdown,
    ModelInfoTableDropdown,
    SerialsTableDropdown,
    Loading,
  },
  methods: {
    transformDataStructure(obj, path) {
      if (path && path.length)
        return path.split(".").reduce(function (prev, curr) {
          return prev ? prev[curr] : null;
        }, obj || self);
    },
    sortField(field) {
      if (this.tableSortField.name != field || this.tableSortField.reverse) {
        this.data.sort((a, b) => (a[field] > b[field] ? 1 : -1));
        this.tableSortField.reverse = false;
      } else {
        this.data.sort((a, b) => (a[field] < b[field] ? 1 : -1));
        this.tableSortField.reverse = true;
      }

      this.tableSortField.name = field;
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
};
</script>
