<template>
  <section class="retail-programs-index col-span-12">
    <div class="container grid grid-cols-1 gap-0">
      <PageHeader
        title="Retail Programs"
        :saveButton="false"
        :discardLink="false"
        :backLink="false"
        :addButton="userCanCreate"
        addText="Start New Application"
        @add="$router.push({ name: 'RetailProgramsAdd' })"
      >
        <template v-slot:actions>
          <button
            class="btn btn-info px-15"
            @click="exportExcel"
            :disabled="disableExportExcel"
            v-if="userCanExport"
          >
            <loading v-if="disableExportExcel" />
            <div class="text mr-3"><span>Export</span></div>
            <div class="export">
              <inline-svg :src="require('@/assets/image/icons/download.svg')" />
            </div>
          </button>
        </template>
      </PageHeader>
      <div class="filter-block">
        <div class="flex flex-wrap -m-1 sm:mr-4">
          <div
            class="dropdown-group m-1 w-full sm:w-auto"
            v-if="user.role.id !== 3 || user.permissions.includes('GLOBAL')"
          >
            <dropdown
              :data="dealerships"
              :status="false"
              fieldName="name"
              label="Dealership"
              @select="selectDealership"
              :validation="true"
              :all="true"
              :search="true"
              placeholder="Dealership"
              inputName="dealership"
              :editValue="selectedDealership"
            ></dropdown>
          </div>
          <div class="form-group m-1 w-full sm:w-auto">
            <label class="control-label">Search</label>
            <div class="relative">
              <input
                v-model="filterData.search"
                type="text"
                name="search"
                value=""
                class="search"
                v-on:keyup.enter="applyFilter"
                placeholder="Tractor S/N"
              />
              <inline-svg
                class="search-icon"
                :src="require('@/assets/image/icons/search.svg')"
              />
            </div>
          </div>
           <div class="form-group m-1 w-full sm:w-auto">
            <label class="control-label">Check #</label>
            <div class="relative">
              <input
                v-model="filterData.check_num"
                type="text"
                name="check_num"
                value=""
                class="search"
                v-on:keyup.enter="applyFilter"
                placeholder="Search"
              />
              <inline-svg
                class="search-icon"
                :src="require('@/assets/image/icons/clipboard.svg')"
              />
            </div>
          </div>
          <div class="dropdown-group m-1 w-full sm:w-40">
            <dropdown
              :data="allProgramTypes"
              fieldName="name"
              label="Program Type"
              @select="selectProgramType"
              inputName="program_type"
              :editValue="selectedProgramType.name"
            ></dropdown>
          </div>
          <div class="dropdown-group m-1 w-full sm:w-40">
            <dropdown
              :data="statusList"
              :status="true"
              :statusColors="{
                1: '#606060',
                2: '#d7ad41',
                3: '#1db8d89a',
                4: '#63dc2bcc',
                5: '#d404048f',
                6: '#d7ad41',
              }"
              fieldName="description"
              label="Status"
              @select="selectStatus"
              inputName="program_type"
              :editValue="selectedStatus.description"
            ></dropdown>
          </div>
          <div class="form-group m-1 w-full sm:w-64">
            <label class="control-label">Submission Date</label>
            <div class="relative">
              <functional-calendar
                :placeholder="' '"
                v-model="calendarData"
                :sundayStart="true"
                :date-format="'mm/dd/yyyy'"
                :is-modal="true"
                :is-date-range="true"
                :is-multiple="true"
                :calendars-count="2"
                :change-year-function="true"
                @opened="openCalendar()"
              ></functional-calendar>
              <inline-svg
                class="date-down"
                :src="require('@/assets/image/icons/date-down.svg')"
              />
              <span class="dash">-</span>
            </div>
          </div>
        </div>
        <div class="card">
          <div
            class="btn btn-black cursor-pointer px-40"
            @click.prevent="applyFilter"
          >
            <div class="text"><a>Apply</a></div>
          </div>
          <div class="btn redo px-15" @click.prevent="resetFilter">
            <a>
              <inline-svg :src="require('@/assets/image/icons/redo.svg')" />
            </a>
          </div>
        </div>
      </div>
      <div class="text-sm table-block col-span-1">
        <data-table
          :page="filterData.page"
          :header="retailProgramsTable.header"
          :data="programBatches"
          :isLoading="isLoading"
          :link="{ url: '/retail-programs/', name: 'id' }"
        />
      </div>
      <div class="pages" v-if="pagination.total && pagination.total_pages > 1">
        <div class="pages-quantity">
          <template v-if="!isLoading">
            Page {{ filterData.page }} of {{ pagination.total_pages }} - Showing
            {{ pagination.per_page }} Results of {{ pagination.total }} Total
          </template>
        </div>
        <paginate
          :page-count="pagination.total_pages"
          :page-range="screenWidth > 467 ? 6 : screenWidth > 320 ? 3 : 2"
          :margin-pages="1"
          :initial-page="filterData.page - 1"
          :click-handler="paginationClick"
          :prev-text="'&laquo;'"
          :next-text="'&raquo;'"
          :container-class="'pages-btn'"
          :active-class="isLoading ? '' : 'active'"
        >
        </paginate>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../component/PageHeader";
import DataTable from "../../component/DataTable";
import Dropdown from "../../component/Dropdown";
import Loading from "../../component/Loading";
import { FunctionalCalendar } from "vue-functional-calendar";
import { mapActions, mapState } from "vuex";
import ClickOutside from "vue-click-outside";
import { http } from "../../../api";
import dateFormat from "dateformat";

export default {
  name: "RetailPrograms",
  data() {
    return {
      baseUrl: "/retail-programs",
      screenWidth: window.innerWidth,
      isLoading: false,

      disableExportExcel: false,

      filterData: {
        search: this.$route.query?.search ?? "",
        page: parseInt(this.$route.query?.page ?? 1),
      },

      selectedProgramType: {
        name: "All",
      },
      selectedDealership: "All",
      selectedStatus: { description: "All" },

      calendarData: {
        dateRange: {
          start: "",
          end: "",
        },
      },

      retailProgramsTable: {
        header: [
          {
            field: "id",
            name: "ID",
            width: "5%",
            headerWidth: "5%",
            transformToComponent: "TransformToLink",
          },
          {
            field: "dealership",
            name: "Dealership",
            width: "15%",
            headerWidth: "15%",
          },
          {
            field: "serials",
            name: "Serial(s)",
            width: "15%",
            headerWidth: "15%",
          },
          {
            field: "program_type",
            name: "Program Type",
            width: "10%",
            headerWidth: "10%",
            // transform: this.transformBold,
            // transformField: "program_type",
          },
          {
            field: "salesperson",
            name: "Salesperson",
            width: "10%",
            headerWidth: "10%",
          },
          {
            field: "created_at",
            name: "Date",
            width: "10%",
            headerWidth: "10%",
          },
          {
            field: "amount_claimed",
            name: "Claimed",
            transformField: "amount_claimed",
            transform: this.transformToCurrency,
            width: "9%",
            headerWidth: "9%",
          },
          {
            field: "amount_adjusted",
            name: "Adjusted",
            transformField: "amount_adjusted",
            transform: this.transformToCurrency,
            width: "9%",
            headerWidth: "9%",
          },
          {
            field: "status.description",
            name: "Status",
            transformField: "status.id",
            transform: this.transformToStatus,
            width: "10%",
            headerWidth: "10%",
          },
          {
            transformField: "id",
            name: "Actions",
            transformToComponent: "ProgramsTableDropdown",
            width: "6%",
            headerWidth: "6%",
          },
        ],
      },
    };
  },
  async created() {
    window.addEventListener("resize", this.handleResize);

    this.isLoading = true;

    await Promise.all([this.getProgramsList(), this.getStatusList()]);

    //=== set up parameters based on route query ===//

    //DEFINED IN DATA:
    // page
    // search

    //PROGRAM TYPE
    if (this.$route.query.program_type_id) {
      this.filterData.program_type_id = this.$route.query.program_type_id;

      const programType = this.allProgramTypes.find(
        (p) => p.id == this.$route.query.program_type_id
      );
      if (programType) {
        this.selectedProgramType = programType;
      }
    }

    //DEALERSHIP
    if (this.$route.query.dealership_id) {
      this.filterData.dealership_id = this.$route.query.dealership_id;

      this.selectedDealership = this.dealerships?.find(
        (item) => item.id == this.$route.query.dealership_id
      )?.name;
    }

    //CHECK NUM
    if (this.$route.query.check_num) {
      this.filterData.check_num = this.$route.query.check_num;
    }


    //STATUS
    if (this.$route.query.status_id) {
      this.filterData.status_id = this.$route.query.status_id;

      const status = this.statusList.find(
        (p) => p.id == this.$route.query.status_id
      );
      if (status) {
        this.selectedStatus = status;
      }
    }

    //START DATE
    if (this.$route.query.start_date) {
      this.calendarData.dateRange.start = this.$route.query.start_date;
      this.filterData.start_date = this.$route.query.start_date;
    }
    //END DATE
    if (this.$route.query.end_date) {
      this.calendarData.dateRange.end = this.$route.query.end_date;
      this.filterData.end_date = this.$route.query.end_date;
    }

    try {
      await this.getProgramBatches(this.filterData);
    } catch (err) {
      this.$toasted.error(err.response.data.message || "Server Error");
    }

    this.isLoading = false;

    if (this.filterData.dealership_id) {
      this.selectedDealership = this.dealerships.find(
        (d) => d.id == this.filterData.dealership_id
      )?.name;
    }

    this.updateRouter();
  },
  components: {
    PageHeader,
    Loading,
    DataTable,
    FunctionalCalendar,
    Dropdown,
  },
  computed: {
    ...mapState({
      dealerships: (state) => state.programs.dealerships,

      user: (state) => state.auth.user,
      allProgramTypes: (state) => state.programs.programsList.types,
      statusList: (state) => state.programs.statusList,
      programBatches: (state) =>
        state.programs.programBatches.batches.map((batch) => ({
          ...batch,
          serials:
            batch.serials?.slice(0, 2).join(", ") +
            (batch.serials.length > 2 ? " ..." : ""),
        })),
      pagination: (state) => state.programs.programBatches.pagination,
    }),
    userCanCreate: function () {
      return (
        this.user.permissions.includes("GLOBAL") ||
        this.user.permission_actions?.["Retail Program"]?.Create
      );
    },
    userCanExport: function () {
      return this.user ? true : false;
    },
  },
  methods: {
    ...mapActions({
      getProgramsList: "programs/getProgramsList",
      getStatusList: "programs/getStatusList",
      getProgramBatches: "programs/getProgramBatches",
      setProgramBatchFilters: "programs/setProgramBatchFilters",
    }),

    handleResize() {
      this.screenWidth = window.innerWidth;
    },

    openCalendar() {
      const cal = this.$el.querySelector(".vfc-main-container");
      if (cal.getBoundingClientRect().x < 240) {
        cal.style.left = "0";
      } else {
        cal.style.left = null;
      }
    },

    selectDealership(dealership) {
      this.selectedDealership = dealership.name;

      if (dealership.id || dealership.id === 0) {
        this.filterData.dealership_id = dealership.id;
      } else {
        delete this.filterData.dealership_id;
      }

      this.applyFilter();
    },
    selectProgramType(programType) {
      this.selectedProgramType = programType;

      if (programType.id) {
        this.filterData.program_type_id = programType.id;
      } else {
        delete this.filterData.program_type_id;
      }

      this.applyFilter();
    },
    selectStatus(status) {
      this.selectedStatus = status;

      if (status.id) {
        this.filterData.status_id = status.id;
      } else {
        delete this.filterData.status_id;
      }

      this.applyFilter();
    },

    resetFilter() {
      this.selectedDealership = "All";
      this.selectedProgramType = {
        name: "All",
      };
      this.filterData = {
        page: 1,
      };
      if (this.calendarData.dateRange) {
        this.calendarData.dateRange.end = "";
        this.calendarData.dateRange.start = "";
      }
      this.applyFilter();
    },
    applyFilter() {
      if (this.calendarData.dateRange != null) {
        if (this.calendarData.dateRange.start) {
          this.filterData.start_date = this.calendarData.dateRange.start;
        }
        if (this.calendarData.dateRange.end) {
          this.filterData.end_date = this.calendarData.dateRange.end;
        }
      }
      if (this.filterData.check_num == "") {
        delete this.filterData.check_num;
      }
      this.filterData.page = 1;
      this.updateRouter();

      this.isLoading = true;
      this.getProgramBatches(this.filterData)
        .then((res) => {})
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    transformBold(text) {
      return `<strong>${text}</strong>`;
    },
    transformToStatus(id, val) {
      let statusName = "pending";

      switch (id) {
        case 1: //submitted
        default:
          statusName = "draft";
          break;
        case 2: //under review
          statusName = "pending";
          break;
        case 3: //reviewed
          statusName = "blue";
          break;
        case 4: //approved
          statusName = "success";
          break;
        case 5: //closed
          statusName = "rejected";
          break;
        case 6: //needs attention
          statusName = "pending";
          break;
      }
      return `<div class="c-status c-status--${statusName} capitalize">${val}</div>`;
    },
    transformToCurrency(amount, _) {
      return (
        "$" +
        (amount ?? 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
    },
    paginationClick(pageNum) {
      this.filterData.page = pageNum;
      if (this.calendarData.dateRange != null) {
        if (this.calendarData.dateRange.start) {
          this.filterData.start_date = this.calendarData.dateRange.start;
        }
        if (this.calendarData.dateRange.end) {
          this.filterData.end_date = this.calendarData.dateRange.end;
        }
      }
      this.updateRouter();
      this.isLoading = true;
      this.getProgramBatches(this.filterData)
        .then((res) => {})
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },

    updateRouter(saveState = true) {
      this.$router.push(
        this.baseUrl + "?" + new URLSearchParams(this.filterData).toString()
      );
      if (saveState) this.setProgramBatchFilters(this.filterData);
    },

    async exportExcel() {
      if (!this.disableExportExcel) {
        this.disableExportExcel = true;
        const q = new URLSearchParams(this.filterData);

        try {
          const res = await http.get(
            "retail-program-batches/export?" + q.toString(),
            {
              responseType: "blob",
            }
          );

          let blob = new Blob([res.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);

          const date = dateFormat(new Date(), "mmm dd yyyy");

          link.download = `Retail program batches - ${date} .xlsx`;
          link.click();
        } catch (err) {
          console.log(err);
          this.$toasted.error("Retail Program Batches Export failed");
        } finally {
          this.disableExportExcel = false;
        }
      }
    },
  },
  directives: {
    ClickOutside,
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
.filter-block {
  display: flex;
  align-items: flex-end;

  .dropdown-group {
    display: flex;
    // margin-top: 40px;

    @media (max-width: 700px) {
      flex-direction: column;

      .dealer,
      .address {
        margin-top: 10px;
        width: 100% !important;
      }
    }
  }

  .card {
    display: flex;
    padding-top: 27.5px;
    margin-left: auto;
  }
}

.search-icon { 
  width: 12px;
  height: 12px;
  
  & /deep/ path {
    fill: currentColor;
  }
}
</style>
