var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-retailProgramEdit text-sm"},[_c('div',{staticClass:"p-5"},[(_vm.details.use_salesperson || _vm.details.approved_by)?_c('div',{staticClass:"\n        flex\n        mb-4\n        md:mb-6\n        flex-col\n        md:flex-row\n        items-start\n        md:items-center\n      "},[(_vm.details.use_salesperson)?[(_vm.viewOnly)?_c('p',{staticClass:"mb-4 md:mr-8 md:mb-0"},[_c('span',{staticClass:"font-medium"},[_vm._v("Salesperson: ")]),_c('span',{staticClass:"whitespace-no-wrap"},[_vm._v(_vm._s(_vm.selectedBonusSalesperson && _vm.selectedBonusSalesperson.name))])]):_c('div',{staticClass:"mb-4 md:mr-8 md:mb-0"},[_c('span',{staticClass:"inline-block font-medium mb-2 mr-4"},[_vm._v("Salesperson:")]),_c('div',{staticClass:"inline-block"},[_c('dropdown',{attrs:{"data":_vm.salespeople,"status":false,"fieldName":"name","inputName":"salesperson","all":false,"search":false,"disabled":"","editValue":_vm.selectedBonusSalesperson && _vm.selectedBonusSalesperson.name},on:{"select":_vm.selectBonusSalesperson}})],1)])]:_vm._e(),(_vm.details.approved_by)?_c('div',{staticClass:"mb-4 md:mb-0"},[_c('span',{staticClass:"font-medium"},[_vm._v("Approved By: ")]),_c('span',{staticClass:"whitespace-no-wrap"},[_vm._v(_vm._s(_vm.details.approved_by))])]):_vm._e()],2):_vm._e(),_c('div',{staticClass:"overflow-x-scroll"},[_c('table',{class:{
          'mb-6': !_vm.details.lines.length,
        },style:(("border: none; min-width: " + _vm.tableMinWidth + "; max-width: " + _vm.tableMaxWidth + ";"))},[_c('thead',[_c('th',{staticClass:"text-left font-normal"},[_vm._v("Serial Number")]),_c('th',{staticClass:"text-center font-normal"},[_vm._v("Model")]),(_vm.details.program_type_id > 1)?_c('th',{staticClass:"text-left font-normal"},[_vm._v(" Program ")]):_vm._e(),_c('th',{staticClass:"text-left font-normal"},[_vm._v(" Description ")]),_c('th',{staticClass:"text-center font-normal"},[_vm._v("Claimed")]),_c('th',{staticClass:"text-center font-normal"},[_vm._v("Adjusted")]),(_vm.details.program_type_id > 1)?_c('th',{staticClass:"text-left font-normal"},[_vm._v(" File ")]):_vm._e(),_c('th',{staticClass:"text-left font-normal"},[_vm._v("Status")])]),_c('tbody',{staticClass:"text-gray-800"},[_vm._l((_vm.details.lines),function(line,i){return [_c('tr',{key:'sales_bonus_serial_' + i,staticClass:"text-gray-700 text-sm relative"},[_c('td',[_c('div',{staticClass:"flex items-center"},[_c('span',{staticClass:"mr-1 submit_type_icon",class:{
                      'text-gray-800': line.submit_type == 'auto',
                      'text-green-600': line.submit_type == 'preapproval',
                      'text-red-700': line.submit_type == 'variable',
                    },staticStyle:{"width":"16px","height":"auto"}},[(_vm.submitTypeIcon(line.submit_type))?_c('inline-svg',{staticClass:"w-full h-auto",attrs:{"src":require(("@/assets/image/icons/" + (_vm.submitTypeIcon(
                          line.submit_type
                        )) + ".svg"))}}):_vm._e()],1),_vm._v(" "+_vm._s(line.serial)+" ")])]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(line.model)+" ")]),(_vm.details.program_type_id > 1)?_c('td',{staticClass:"leading-none py-2"},[_vm._v(" "+_vm._s(line.program_name)+" ")]):_vm._e(),_c('td',{staticClass:"leading-none py-2"},[_vm._v(" "+_vm._s(line.description)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" $"+_vm._s(line.amount_orig.toLocaleString("en-US"))+" ")]),_c('td',{staticClass:"text-center whitespace-no-wrap"},[_c('span',[_vm._v("$")]),(_vm.viewOnly)?_c('span',[_vm._v(_vm._s(line.amount_curr))]):_c('input',{directives:[{name:"model",rawName:"v-model",value:(line.amount_curr),expression:"line.amount_curr"}],staticClass:"inline-block rounded text-right",class:_vm.getStatusClass(line),staticStyle:{"width":"68px","padding-right":"10px"},attrs:{"type":"text","disabled":_vm.disabled},domProps:{"value":(line.amount_curr)},on:{"blur":function($event){return _vm.setLineAmount(i)},"input":function($event){if($event.target.composing){ return; }_vm.$set(line, "amount_curr", $event.target.value)}}})]),(_vm.details.program_type_id > 1)?_c('td',{attrs:{"data-file-upload-container":""}},[_c('FileInput',{attrs:{"name":"retail_program_file","input-class":"h-full my-1","accept":"image/*,.pdf","loadFile":Object.assign({}, line.attachment,
                    {displayName: 'Download'}),"disabled":_vm.disabled,"options":{
                    showNoFileMessage: false,
                    chooseFileMessage: 'Upload',
                  }},on:{"change":function($event){line.file = {
                      data: $event.fileData,
                      name: $event.file.name,
                    }},"delete-file":function($event){return _vm.deleteAttachment(line)}}})],1):_vm._e(),_c('td',{class:_vm.getStatusClass(line, true)},[_vm._v(" "+_vm._s(_vm.getStatus(line, true))+" ")])])]})],2)])])]),_c('div',{staticClass:"dropdown-bottom"},[_c('div',{staticClass:"font-medium"},[_c('span',[_vm._v("Total Amount: ")]),_c('span',{staticClass:"text-gray-800 inline-block ml-1"},[_vm._v("$"+_vm._s(_vm.details.lines .reduce(function (prev, curr) { return prev + parseFloat(curr.amount_orig); }, 0) .toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2, })))])]),_c('div',{staticClass:"font-medium"},[_c('span',[_vm._v("Total Adjusted Amount: ")]),_c('span',{staticClass:"text-gray-800 inline-block ml-1"},[_vm._v("$"+_vm._s(_vm.details.lines .reduce( function (prev, curr) { return prev + (parseFloat(curr.amount_curr) || 0); }, 0 ) .toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2, })))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }