<template>
  <div
    class="
      col-span-12
      sm:col-span-12
      md:col-span-12
      lg:col-span-12
      xl:col-span-12
      grid grid-cols-12
      gap-0
    "
  >
    <section
      class="
        section-center
        col-span-12
        sm:col-span-12
        md:col-span-12
        lg:col-span-9
        xl:col-span-9
      "
    >
      <div class="container grid grid-cols-1 gap-0">
        <div v-if="user && user.role.id == 3" class="dealer-button">
          <router-link
            to="/claims/add"
            class="button"
            v-if="user.permissions.includes('CLAIM_CREATE')"
          >
            <div class="create">
              <inline-svg :src="require('@/assets/image/icons/add.svg')" />
            </div>
            <div class="text">Add New Claim</div>
          </router-link>
          <router-link
            to="/registrations/add"
            class="button"
            v-if="user.permissions.includes('REG_CREATE')"
          >
            <div class="create">
              <inline-svg :src="require('@/assets/image/icons/add.svg')" />
            </div>
            <div class="text">Start Registration</div>
          </router-link>
          <router-link
            to="/orders-create"
            class="button"
            v-if="user.permissions.includes('ORDER_CREATE')"
          >
            <div class="create">
              <inline-svg :src="require('@/assets/image/icons/add.svg')" />
            </div>
            <div class="text">Create Order</div>
          </router-link>
        </div>
        <div
          class="
            grid grid-cols-1
            gap-0
            col-span-1
            sm:col-span-1
            md:col-span-1
            lg:col-span-1
            xl:col-span-1
          "
          v-else
        >
          <h2
            class="
              col-span-1
              sm:col-span-1
              md:col-span-1
              lg:col-span-1
              xl:col-span-1
            "
          >
            Warranty Registrations
          </h2>
          <div class="block-list" v-if="warrantyRegistrations">
            <div class="block">
              <p v-if="warrantyRegistrations.mtd">
                {{ warrantyRegistrations.mtd }}
              </p>
              <p v-else>0</p>
              <span>Month-To-Date</span>
            </div>
            <div class="block">
              <p v-if="warrantyRegistrations.prior">
                {{ warrantyRegistrations.prior }}
              </p>
              <p v-else>0</p>
              <span>Prior Month</span>
            </div>
            <div class="block">
              <p v-if="warrantyRegistrations.ytd">
                {{ warrantyRegistrations.ytd }}
              </p>
              <p v-else>0</p>
              <span>Year to Date</span>
            </div>
            <div class="block">
              <p v-if="warrantyRegistrations.pYtd">
                {{ warrantyRegistrations.pYtd }}
              </p>
              <p v-else>0</p>
              <span>Prior-YTD</span>
            </div>
            <div class="block">
              <p v-if="warrantyRegistrations.pYear">
                {{ warrantyRegistrations.pYear }}
              </p>
              <p v-else>0</p>
              <span>Prior Year</span>
            </div>
          </div>
          <carousel
            class="warranty-carousel"
            :navigationEnabled="true"
            :speed="2000"
            :paginationEnabled="false"
            navigationPrevLabel=""
            navigationNextLabel=""
          >
            <slide>
              <div class="warranty-carousel-card">
                <p v-if="warrantyRegistrations.mtd">
                  {{ warrantyRegistrations.mtd }}
                </p>
                <p v-else>0</p>
                <span>Month-To-Date</span>
              </div>
            </slide>
            <slide>
              <div class="warranty-carousel-card">
                <p v-if="warrantyRegistrations.prior">
                  {{ warrantyRegistrations.prior }}
                </p>
                <p v-else>0</p>
                <span>Prior Month</span>
              </div>
            </slide>
            <slide>
              <div class="warranty-carousel-card">
                <p v-if="warrantyRegistrations.ytd">
                  {{ warrantyRegistrations.ytd }}
                </p>
                <p v-else>0</p>
                <span>Year to Date</span>
              </div>
            </slide>
            <slide>
              <div class="warranty-carousel-card">
                <p v-if="warrantyRegistrations.pYtd">
                  {{ warrantyRegistrations.pYtd }}
                </p>
                <p v-else>0</p>
                <span>Prior-YTD</span>
              </div>
            </slide>
            <slide>
              <div class="warranty-carousel-card">
                <p v-if="warrantyRegistrations.pYear">
                  {{ warrantyRegistrations.pYear }}
                </p>
                <p v-else>0</p>
                <span>Prior Year</span>
              </div>
            </slide>
          </carousel>
        </div>
        <div
          class="
            table-block
            col-span-1
            sm:col-span-1
            md:col-span-1
            lg:col-span-1
            xl:col-span-1
          "
        >
          <data-table
            v-if="user && user.role.id === 1 && territoryManagers"
            :data="territoryManagers"
            :header="territoryTable.header"
            :link="{ url: '/registrations?tm=', name: 'name' }"
            :sortArrow="true"
            :isLoading="isLoadingDash"
          />
          <data-table
            v-if="user && user.role.id === 2 && dealers"
            :data="dealers"
            :header="dealersTable.header"
            :scroll="true"
            :showingDataCount="dealersTable.showingDataCount"
            :link="{ url: '/registrations?dealer=', name: 'name' }"
            :sortArrow="true"
            :isLoading="isLoadingDash"
          />
          <div
            class="table-block-content card-group"
            v-if="user && user.role.id === 3 && warrantyClaims"
          >
            <div class="title">
              <h3>Warranty Claims</h3>
              <router-link tag="div" to="/claims">View All</router-link>
            </div>

            <data-table
              :data="warrantyClaims"
              :header="warrantyClaimsTable.header"
              :showingDataCount="warrantyClaimsTable"
              :link="{ url: '/claims/', name: 'id' }"
              :isLoading="isLoadingDash"
            />
          </div>
        </div>

        <div
          class="
            card-group
            col-span-1
            sm:col-span-1
            md:col-span-1
            lg:col-span-1
            xl:col-span-1
          "
        >
          <div class="title">
            <h3>TYM Updates</h3>
            <router-link
              tag="div"
              to="/news"
              @click.prevent="showAllTableData()"
              >View All</router-link
            >
          </div>
          <div class="cards grid grid-cols-2 gap-4">
            <div
              v-for="(newsItem, j) in news"
              v-show="j < 2"
              class="
                card
                col-span-2
                sm:col-span-2
                md:col-span-1
                lg:col-span-1
                xl:col-span-1
              "
            >
              <div class="name">
                <span>{{ newsItem.created_at | moment("M/D/YY") }}</span>
                <div>{{ newsItem.news_title }}</div>
                <router-link :to="'news/' + newsItem.id">Read More</router-link>
              </div>
            </div>
          </div>
        </div>
        <div
          class="
            card-group
            col-span-1
            sm:col-span-1
            md:col-span-1
            lg:col-span-1
            xl:col-span-1
          "
        >
          <div class="title">
            <h3>Service Bulletins</h3>
            <router-link tag="div" to="/service-bulletins"
              >View All</router-link
            >
          </div>
          <div
            class="cards grid grid-cols-2 gap-4"
            v-if="serviceBulletins.length"
          >
            <div
              v-for="(service, i) in serviceBulletins"
              class="
                card
                col-span-2
                sm:col-span-2
                md:col-span-1
                lg:col-span-1
                xl:col-span-1
              "
              style="border-top: 1px solid #dedede"
            >
              <div class="name">
                <span>{{ service.posted_on }}</span>
                <div>{{ service.title }}</div>
                <button
                  class="btn btn-info px-15 service-button"
                  @click="download(service)"
                >
                  <loading v-if="disableIndex.includes(service.id)" />
                  <div class="service-button-text">Download</div>
                  <div class="create">
                    <inline-svg
                      :src="require('@/assets/image/icons/download.svg')"
                    />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="
            card-group
            col-span-1
            sm:col-span-1
            md:col-span-1
            lg:col-span-1
            xl:col-span-1
          "
        >
          <div class="title">
            <h3>Downloads</h3>
          </div>
          <div class="cards grid grid-cols-1 gap-4">
            <div
              class="
                card
                col-span-1
                sm:col-span-1
                md:col-span-1
                lg:col-span-1
                xl:col-span-1
              "
            >
              <div class="name pb-2">
                <p>
                  <a
                    class="underline"
                    href="https://d17kmd0va0f0mp.cloudfront.net/sos/SplashtopSOS.exe"
                    >Splashtop SOS (Remote Support)</a
                  >
                </p>
                <p class="mt-2">
                  <a
                    class="underline"
                    href="http://61.42.10.114/PCAPP/WPBC_C/WPBC_C.application"
                    >Parts Catalog</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section-right lg:col-span-3 xl:col-span-3">
      <h2>Recent Activity</h2>
      <div class="card-list">
        <div v-for="item in recentActivity" class="card">
          <RecentActivity :item="item" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ClickOutside from "vue-click-outside";
import { http } from "@/api";
import Loading from "@/components/component/Loading";
import DataTable from "../../component/DataTable";
import RecentActivity from "../../component/RecentActivity";
import { Carousel, Slide } from "vue-carousel";
import dateFormat from "dateformat";

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const now = new Date();
const lastMonth = months[now.getMonth() - 1];
// const lastMonth = new Date();
// lastMonth.setHours(0, 0, 0, 0);
// lastMonth.setMonth(lastMonth.getMonth() - 1);

const lastYear = new Date();
lastYear.setHours(0, 0, 0, 0);
lastYear.setFullYear(lastYear.getFullYear() - 1);

export default {
  name: "Home",
  data() {
    return {
      disableIndex: [],
      isLoadingDash: false,
      dealersTable: {
        header: [
          {
            field: "name",
            transformField: "id",
            transformToComponent: "TransformToLink",
            name: "Dealer Name",
            width: "25%",
            headerWidth: "30%",
          },
          {
            field: "mtd",
            name: dateFormat(new Date(), "mmm"),
            width: "12%",
            headerWidth: "12%",
          },
          {
            field: "prior",
            // name: dateFormat(lastMonth, "mmm"),
            name: lastMonth,
            width: "12%",
            headerWidth: "12%",
          },
          {
            field: "ytd",
            name: dateFormat(new Date(), "yyyy") + " YTD",
            width: "12%",
            headerWidth: "12%",
          },
          {
            field: "pYtd",
            name: dateFormat(lastYear, "yyyy") + " YTD",
            width: "12%",
            headerWidth: "12%",
          },
          {
            field: "pYear",
            name: dateFormat(lastYear, "yyyy") + " Total",
            width: "12%",
            headerWidth: "12%",
          },
          {
            field: "lYear",
            name: "Last 12 Mo",
            width: "12%",
            headerWidth: "12%",
          },
          {
            field: "inventory",
            name: "Inventory",
            width: "18%",
            headerWidth: "18%",
          },
          {
            field: "turnRatio",
            name: "Turn Ratio",
            width: "10%",
            headerWidth: "10%",
          },
        ],
      },
      territoryTable: {
        header: [
          {
            field: "name",
            transformField: "name",
            name: "Territory Manager",
            transformToComponent: "TransformToLink",
            width: "15%",
          },
          { field: "mtd", name: dateFormat(new Date(), "mmm") },
          { field: "prior", name: lastMonth },
          {
            field: "ytd",
            name: dateFormat(new Date(), "yyyy") + " YTD",
          },
          {
            field: "pYtd",
            name: dateFormat(lastYear, "yyyy") + " YTD",
          },
          { field: "pYear", name: dateFormat(lastYear, "yyyy") + " Total" },
          { field: "lYear", name: "Last 12 Mo" },
          { field: "inventory", name: "Inventory" },
          { field: "turnRatio", name: "Turn Ratio" },
        ],
      },
      warrantyClaimsTable: {
        header: [
          {
            transformField: "id",
            name: "Claim #",
            transformToComponent: "TransformToLink",
          },
          { field: "date", name: "Claim Date" },
          { field: "tractor_sn", name: "Serial #" },
          { field: "owner_name", name: "Owner Name" },
          {
            transformField: "original_cost",
            transform: this.transformToCost,
            field: "original_cost",
            name: "Original $",
          },
          {
            transformField: "current_cost",
            transform: this.transformToCost,
            field: "current_cost",
            name: "Current $",
          },
          {
            transformField: "status.id",
            transform: this.transformToStatus,
            field: "status.name",
            name: "Status",
          },
        ],
      },
      fieldInventoryTable: {
        header: [
          { field: "id", name: "Invoice #" },
          { name: "Invoice #" },
          { name: "Inv. Date " },
          { name: "Model" },
          { name: "Serial #" },
          { name: "Attachment S/N" },
          { name: "Invoice $" },
          { name: "Terms" },
          {
            transformField: "id",
            transform: this.transformToButton,
            field: "id",
            name: "",
          },
        ],
      },
    };
  },
  components: {
    DataTable,
    RecentActivity,
    Loading,
    Carousel,
    Slide,
  },
  mounted() {
    this.isLoadingDash = true;
    this.getDashboard()
      .then((res) => {})
      .finally(() => {
        this.isLoadingDash = false;
      });
    this.getNewsBulletins().then((res) => {});
  },
  computed: {
    ...mapState({
      dealers: (state) => state.dashboard.dealers,
      news: (state) => state.news.news,
      fieldInventory: (state) => state.dashboard.fieldInventory,
      warrantyClaims: (state) => state.dashboard.warrantyClaims,
      recentActivity: (state) => state.news.recentActivity,
      serviceBulletins: (state) => state.news.serviceBulletins,
      warrantyRegistrations: (state) => state.dashboard.warrantyRegistrations,
      territoryManagers: (state) => state.dashboard.territoryManagers,
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    ...mapActions({
      getDashboard: "dashboard/getDashboard",
      getNewsBulletins: "news/getNewsBulletins",
      logout: "auth/logout",
    }),
    download(url) {
      this.disableIndex.push(url.id);
      let file = url.file;
      http
        .get(file, {
          responseType: "blob",
        })
        .then((res) => {
          let blob = new Blob([res.data], {
            type: res.headers["content-type"],
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          let link_name = url.title.toLowerCase();
          link_name = link_name.replace(/\s/g, "_");
          link.download = link_name;
          link.click();
        })
        .catch((err) => {
          if (err.response.status === 404) {
            this.$toasted.error(
              "File could not be located on server. Please contact TYM for assistance."
            );
          }
        })
        .finally(() => {
          this.disableIndex = this.disableIndex.filter((i) => i !== url.id);
        });
    },
    showAllTableData() {
      this.dealersTable.showingDataCount = "all";
    },
    transformToLink(id, val) {
      return '<a href="">' + val + "</a>";
    },
    transformToCost(id, val) {
      if (val) {
        val = val + "";
        if (val.indexOf(".") == -1) {
          val = val + ".00";
        }
        let arr = val.split(".");
        if (arr[0].length > 3) {
          arr[0] =
            arr[0].substr(0, arr[0].length - 3) +
            "," +
            arr[0].substr(arr[0].length - 3, arr[0].length);
        }
        if (arr[1].length > 2) {
          let a = arr[1].length - 3;
          let b = 10;
          for (let i = 0; i < a; i++) {
            b *= 10;
          }
          arr[1] = Math.round(arr[1] / b);
        }
        if (arr[1].length === 1) {
          arr[1] = arr[1] + "0";
        }
        val = arr.join(".");
        return "$" + val;
      } else {
        return "$" + 0.0;
      }
    },

    transformToStatus(id, val) {
      let background;
      let border;
      let color;
      let width;
      color = "#0B4067";
      switch (id) {
        case 1:
          background = "rgba(99, 220, 43, 0.3)";
          color = "#24610E";
          border = "none";
          width = "77px";
          break;
        case 2:
          background = "rgba(212, 4, 4, 0.2)";
          color = "#7D0505";
          border = "none";
          width = "77px";
          break;
        case 3:
          background = "rgba(215, 173, 65, 0.4)";
          color = "#5C4E00";
          border = "none";
          width = "67px";
          break;
        case 4:
          background = "#F9F9F9";
          color = "#606060";
          border = "1px solid #606060";
          width = "62px";
          break;
        case 5:
          background = "rgba(29, 184, 216, 0.2)";
          color = "#0B4067";
          border = "none";
          width = "95px";
          break;
        case 6:
          background = "rgba(212, 4, 4, 0.2)";
          color = "#7d0b67";
          border = "none";
          width = "100px";
          break;
      }
      return (
        '<div class="td-status" style="background-color:' +
        background +
        ";color:" +
        color +
        ";border:" +
        border +
        ";width:" +
        width +
        '">' +
        val +
        "</div>"
      );
    },
    transformToButton() {
      return (
        '<button type="button" class="button btn-td">' +
        '<svg class="btn-img" width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '<path d="M4 2C4 3.10457 3.10457 4 2 4C0.895431 4 0 3.10457 0 2C0 0.895431 0.895431 0 2 0C3.10457 0 4 0.895431 4 2Z" fill="#444444"/>\n' +
        '<path d="M10 2C10 3.10457 9.10457 4 8 4C6.89543 4 6 3.10457 6 2C6 0.895431 6.89543 0 8 0C9.10457 0 10 0.895431 10 2Z" fill="#444444"/>\n' +
        '<path d="M14 4C15.1046 4 16 3.10457 16 2C16 0.895431 15.1046 0 14 0C12.8954 0 12 0.895431 12 2C12 3.10457 12.8954 4 14 4Z" fill="#444444"/>\n' +
        "</svg>" +
        "</button>"
      );
    },
  },
  filters: {
    user_role: function (value) {
      if (!value) return "";
      let text = "";
      value.split("_").map((item) => {
        text += " " + item;
      });
      return text.toLowerCase();
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style scoped lang="scss">
.service-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .service-button-text {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    line-height: 16px !important;
    color: #383838 !important;
    padding: 0 !important;
    margin-right: 10px;
  }
  .create {
    padding: 0 !important;
  }
}
</style>
