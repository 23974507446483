var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"add-edit-page col-span-12"},[_c('ValidationObserver',{ref:"form",staticClass:"container",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.submitModelInfo($event)}}},[_c('PageHeader',{attrs:{"title":_vm.isEdit ? ("Edit Model " + (_vm.$route.params.model)) : 'Add Model Info',"backText":_vm.isEdit ? 'Back to Model Info Details' : 'Back to Model Info Index',"backRoute":_vm.backRoute,"saveText":"Submit","buttonsDisabled":_vm.buttonDisabled},on:{"save":_vm.submitModelInfo,"discard":function($event){_vm.showDiscardModal = true}}}),_c('div',{staticClass:"edit-details"},[_c('div',{staticClass:"model-info-section"},[_c('div',{staticClass:"subtitle"},[_vm._v("Enter Model Info")]),_c('div',{staticClass:"info-block"},[_c('div',{staticClass:"info-block-container"},[_c('div',{staticClass:"form-group w-full"},[_c('label',{staticClass:"control-label"},[_vm._v("Model ID")]),_c('ValidationProvider',{attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.model),expression:"formData.model"}],class:classes,attrs:{"type":"text","name":"model","placeholder":"(i.e. T25)"},domProps:{"value":(_vm.formData.model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "model", $event.target.value)}}})]}}])})],1),_c('div',{staticClass:"form-group w-full"},[_c('label',{staticClass:"control-label"},[_vm._v("Service Model")]),_c('ValidationProvider',{attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.service_model),expression:"formData.service_model"}],class:classes,attrs:{"type":"text","name":"service_model","placeholder":"(i.e. T25NHUS1)"},domProps:{"value":(_vm.formData.service_model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "service_model", $event.target.value)}}})]}}])})],1),_c('div',{staticClass:"form-group w-full"},[_c('dropdown',{attrs:{"data":_vm.modelTypes,"fieldName":"type","label":"Model Type","inputName":"model_type","editValue":_vm.formData.model_type,"validation":true},on:{"select":_vm.selectModelType}})],1),_c('div',{staticClass:"form-group w-full"},[_c('label',{staticClass:"control-label"},[_vm._v("Weight")]),_c('ValidationProvider',{attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.weight),expression:"formData.weight"}],class:classes,attrs:{"type":"number","name":"weight","placeholder":"(i.e. 1800)"},domProps:{"value":(_vm.formData.weight)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "weight", $event.target.value)}}})]}}])})],1),_c('div',{staticClass:"form-group w-full"},[_c('label',{staticClass:"control-label"},[_vm._v("Description")]),_c('ValidationProvider',{attrs:{"rules":"","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.description),expression:"formData.description"}],class:classes,attrs:{"rows":"2","name":"description","placeholder":"Enter description..."},domProps:{"value":(_vm.formData.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "description", $event.target.value)}}})]}}])})],1),_c('div',{staticClass:"form-group w-full"},[_c('CheckboxInput',{staticClass:"mb-2",attrs:{"model":_vm.formData.program_default,"name":"program_default","label":"Include in default program model list"},on:{"update:model":function($event){return _vm.$set(_vm.formData, "program_default", $event)}}})],1)])])]),_c('div',{staticClass:"serial-prefixes-section"},[_c('div',{staticClass:"subtitle"},[_vm._v("Serial Prefixes")]),_c('div',{staticClass:"info-block"},[_c('div',{staticClass:"info-block-container"},[_c('div',{staticClass:"serial-prefixes"},[_vm._l((_vm.updatedPrefixes),function(prefix,p){return _c('div',{key:("prefix_" + p),staticClass:"serial-prefixes__row",class:{
                  'bg-gray-100': p % 2 === 1,
                }},[_c('span',{staticClass:"serial-prefixes__prefix"},[_vm._v(_vm._s(prefix))]),_c('button',{staticClass:"c-removeButton",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();$event.target.blur(); _vm.removePrefix(p)}}})])}),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newPrefix),expression:"newPrefix"}],class:{
                    'error' : _vm.errorMessages.prefixes
                  },attrs:{"type":"text","placeholder":"+ Add Prefix"},domProps:{"value":(_vm.newPrefix)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addPrefix($event)},"input":[function($event){if($event.target.composing){ return; }_vm.newPrefix=$event.target.value},function($event){_vm.errorMessages.prefixes = null}]}}),_c('button',{staticClass:"add-icon",attrs:{"type":"button"},on:{"click":_vm.addPrefix}},[_c('inline-svg',{attrs:{"src":require('@/assets/image/icons/add.svg')}})],1)]),(_vm.errorMessages.prefixes)?_c('div',{staticClass:"error-message"},[_vm._v(_vm._s(_vm.errorMessages.prefixes))]):_vm._e()],2)])])]),_c('div',{staticClass:"send"},[_c('div',{staticClass:"edit-link",on:{"click":function($event){_vm.showDiscardModal = true}}},[_vm._v("Discard")]),_c('button',{staticClass:"btn btn-black px-60",class:_vm.buttonDisabled ? 'opacity-50 cursor-wait' : '',attrs:{"disabled":_vm.buttonDisabled,"type":"submit"}},[_vm._v(" Submit ")])])]),_c('ConfirmModal',{attrs:{"text":"Discard changes?","confirmBtn":"Discard","show":_vm.showDiscardModal},on:{"confirm":function($event){return _vm.$router.push(_vm.indexRoute)},"close":function($event){_vm.showDiscardModal = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }