<template>
  <section class="user-details col-span-12">
    <div class="container manage-container">
      <PageHeader
        backText="Back to Advertisements"
        :backRoute="{
          name: 'GalleryAdvertisements',
          params: { id: galleryItem.media_type },
        }"
        :saveButton="false"
        :discardLink="false"
        :details="{
          title: galleryItem.title,
          date: galleryItem.posted_on,
          dateFormat: 'MMMM Do, YYYY',
        }"
      >
        <template v-slot:actions>
          <div class="btn btn-info px-15" @click="download(galleryItem)">
            <button
              :readonly="disableFileDownload"
              type="button"
              class="btn btn-info mr-3"
            >
              <loading v-if="disableFileDownload" />

              <div class="text mr-3"><span>Download</span></div>
              <div class="export">
                <inline-svg
                  :src="require('@/assets/image/icons/download.svg')"
                />
              </div>
            </button>
          </div>
        </template>
      </PageHeader>
      <div class="video-card">
        <div class="image">
          <img :src="galleryItem.file" alt="" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../component/PageHeader";
import { mapActions, mapState } from "vuex";
import { http } from "../../../api";
import Loading from "../../component/Loading";

export default {
  name: "GalleryDetail",
  data() {
    return {
      disableFileDownload: false,
    };
  },
  components: {
    PageHeader,
    Loading,
  },
  computed: {
    ...mapState({
      ...mapState({
        galleryItem: (state) => state.gallery.galleryItem,
      }),
    }),
  },
  mounted() {
    this.getGallery();
  },
  methods: {
    ...mapActions({
      getGalleryItem: "gallery/getGalleryItem",
    }),
    getGallery() {
      this.getGalleryItem({
        slug: this.$route.params.id,
        imageSlug: this.$route.params.slug,
      })
        .then((res) => {
          console.log(this.galleryItem);
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    },
    download(gallery) {
      let url = gallery.file;
      this.disableFileDownload = true;
      http
        .get(url, {
          responseType: "blob",
        })
        .then((res) => {
          let blob = new Blob([res.data], {
            type: res.headers["content-type"],
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = gallery.title;
          link.click();
        })
        .catch((err) => {
          this.$toasted.error("Download failed");
        })
        .finally(() => {
          this.disableFileDownload = false;
        });
    },
  },
};
</script>

<style scoped>
</style>
