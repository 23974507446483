<template>
  <section class="add-user col-span-12">
    <ValidationObserver
      autocomplete="off"
      @submit.prevent="saveUser"
      tag="form"
      class="container"
      ref="form"
    >
      <PageHeader
        :title="isEdit ? 'Edit User' : 'Add User'"
        backText="Back to Mange Users"
        :backRoute="{ name: 'ManageUsers', query: { ...userIndexFilters } }"
        @save="saveUser"
        @discard="showDiscardModal = true"
        :buttonsDisabled="disableBtn"
      />
      <div class="user-details">
        <div style="margin: 0 0 40px"></div>
        <div style="margin-bottom: 10px">
          <button
            type="button"
            @click="displayTab = 'account'"
            class="c-tab-button"
            v-bind:class="{ active: displayTab == 'account' }"
          >
            Account
          </button>
          <button
            type="button"
            @click="displayTab = 'basics'"
            class="c-tab-button sm:hidden"
            v-bind:class="{ active: displayTab == 'basics' }"
          >
            User Type
          </button>
          <button
            type="button"
            @click="displayTab = 'permissions'"
            class="c-tab-button"
            v-bind:class="{ active: displayTab == 'permissions' }"
          >
            Permissions
          </button>
        </div>
        <div class="user-block-group">
          <div class="user-block">
            <div
              class="user-block-container"
              v-bind:class="{ 'u-hide': displayTab != 'account' }"
            >
              <div class="form-group w-full">
                <label class="control-label">Name</label>
                <ValidationProvider
                  rules="required"
                  tag="div"
                  v-slot="{ classes }"
                >
                  <input
                    v-model="formData.name"
                    :class="classes"
                    type="text"
                    name="name"
                  />
                </ValidationProvider>
              </div>
              <div class="form-group w-full">
                <label class="control-label">Email Address</label>
                <ValidationProvider
                  rules="email|required"
                  tag="div"
                  v-slot="{ classes }"
                >
                  <input
                    v-model="formData.email"
                    type="email"
                    :class="classes"
                    name="email"
                  />
                </ValidationProvider>
                <span v-if="user.role.id === 1">
                  A valid e-mail address. All e-mails from the system will be
                  sent to this address. The e-mail address is not made public
                  and will only be used if you wish to receive a new password or
                  wish to receive certain news or notifications by e-mail.
                </span>
              </div>

              <div class="form-group w-full">
                <label class="control-label" v-if="isEdit">New Password</label>
                <label class="control-label" v-else>Password</label>
                <div style="display: none">
                  <input type="text" autocomplete="new-password" />
                  <input type="password" autocomplete="new-password" />
                </div>
                <ValidationProvider
                  :rules="user_id === 'add' ? 'required|password:@confirm' : ''"
                  tag="div"
                  v-slot="{ classes }"
                >
                  <input
                    v-model="formData.password"
                    autocomplete="off"
                    type="password"
                    :class="classes"
                    name="password"
                    value=""
                  />
                </ValidationProvider>
              </div>
              <div class="form-group w-full">
                <label class="control-label" v-if="isEdit"
                  >Confirm New Password</label
                >
                <label class="control-label" v-else>Confirm Password</label>
                <ValidationProvider
                  vid="confirm"
                  :rules="user_id === 'add' ? 'required' : ''"
                  tag="div"
                  v-slot="{ classes }"
                >
                  <input
                    v-model="formData.password_confirmation"
                    type="password"
                    :class="classes"
                    name="confirm_password"
                    value=""
                  />
                </ValidationProvider>
              </div>
            </div>
            <div
              class="user-block-container"
              v-bind:class="{ 'u-hide': displayTab != 'permissions' }"
            >
              <div class="user-flex-info" v-if="selectedUserGroup">
                <button
                  v-if="canCustomizePermissions"
                  type="button"
                  @click="resetPermissions()"
                >
                  Reset to default group permissions
                </button>
                <p v-else style="flex: 1 1 60%; margin-right: 20px">
                  Permissions cannot be customized for this user group.
                </p>
                <div>
                  <span><em>*default permission for user group</em></span>
                </div>
              </div>
              <div
                class="c-permissions-list"
                v-bind:class="{ disabled: !canCustomizePermissions }"
              >
                <template v-if="!selectedUserGroup">
                  <div style="padding: 20px 0">
                    <p>
                      <em>Select a User Group to start setting permissions</em>
                    </p>
                  </div>
                </template>
                <template v-else v-for="catName in sortedPermissionCategories">
                  <div
                    v-if="permissions_fields[catName].length"
                    class="c-permissions-list__category"
                    :ref="`permission-cat-${catName}`"
                  >
                    <label class="c-permissions-list__title">
                      <input
                        type="checkbox"
                        v-model="permissions_categories"
                        :value="catName"
                        @click="
                          permissionClick($event);
                          toggleCategory(catName);
                        "
                      />
                      <span>{{ catName }}</span>
                    </label>
                    <label
                      class="c-permissions-list__item"
                      v-for="(permission, i) in permissions_fields[catName]"
                    >
                      <input
                        type="checkbox"
                        :value="permission.code"
                        v-model="formData.permissions"
                        @change="permissionChange()"
                        :ref="`permission-${catName}-${i}`"
                        @click="permissionClick($event)"
                      />
                      <span>{{ permission.name }}</span>
                      <span
                        class="asterisk"
                        v-if="isInDefaultPermissions(permission.code)"
                        >*</span
                      >
                    </label>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div
            class="user-block user-block--basics"
            v-bind:class="{ hideOnMobile: displayTab != 'basics' }"
          >
            <div class="user-block-container">
              <div class="user-type">
                <dropdown
                  :data="user_types"
                  :status="false"
                  fieldName="name"
                  label="User Type"
                  @select="selectUserType"
                  :validation="true"
                  inputName="user_type"
                  :editValue="selectedUserType"
                  :all="false"
                  :search="true"
                  :disabled="user_types.length === 1"
                  :textOnly="user_types.length === 1"
                ></dropdown>
              </div>
              <div class="user-type">
                <dropdown
                  :data="selected_user_groups"
                  :status="false"
                  fieldName="name"
                  label="User Group"
                  @select="selectUserGroup"
                  :validation="true"
                  inputName="user_group"
                  :editValue="selectedUserGroup"
                  :all="false"
                  :search="true"
                ></dropdown>
              </div>
              <div class="user-type">
                <dropdown
                  :data="dealership"
                  :status="false"
                  fieldName="name"
                  label="Dealership"
                  @select="selectDealership"
                  :validation="true"
                  inputName="dealership"
                  :editValue="selectedDealership"
                  :all="false"
                  :search="true"
                  :disabled="dealership.length === 1"
                  :textOnly="dealership.length === 1"
                ></dropdown>
              </div>
              <div class="user-type">
                <dropdown
                  :data="userStatus"
                  :status="false"
                  fieldName="status"
                  label="User status"
                  @select="selectUserStatus"
                  :validation="true"
                  inputName="status"
                  :editValue="selectedUserStatus"
                  :all="false"
                  :search="true"
                ></dropdown>
              </div>
            </div>
          </div>
        </div>

        <div class="send">
          <div class="edit-link" @click="showDiscardModal = true">Discard</div>
          <button
            :disabled="disableBtn"
            :class="disableBtn ? 'opacity-50 cursor-wait' : ''"
            class="btn btn-black px-60"
            type="submit"
          >
            Save
          </button>
        </div>
      </div>
    </ValidationObserver>
    <ConfirmModal
      text="Discard changes?"
      confirmBtn="Discard"
      :show="showDiscardModal"
      @confirm="returnToIndex"
      @close="showDiscardModal = false"
    />
  </section>
</template>



<script>
import PageHeader from "../../../component/PageHeader";
import Dropdown from "../../../component/Dropdown";
import ConfirmModal from "../../../component/ConfirmModal";
import { http } from "../../../../api";
import { mapActions, mapState } from "vuex";
export default {
  name: "addUser",
  data() {
    return {
      formData: {
        permissions: [],
      },
      disableBtn: false,
      showDiscardModal: false,
      selectedUserType: null,
      selectedUserGroup: null,
      selectedUserStatus: null,
      selectedDealership: null,
      displayTab: "account",
      user_types: [],
      user_groups: [],
      dealership: [],
      auth_permissions_fields: [],
      permissions_fields: [],
      permissions_categories: [],
      customized_permissions: null,
      isEdit: false,
      userStatus: [
        {
          status: "Active",
          id: 1,
        },
        {
          status: "Inactive",
          id: 0,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      userIndexFilters: (state) => state.user.indexFilters,
    }),
    sortedPermissionCategories: function () {
      return Object.keys(this.permissions_fields).sort((a, b) =>
        a.localeCompare(b)
      );
    },
    selected_user_groups: function () {
      if (this.selectedUserType) {
        return this.user_groups.filter(
          (group) => group.user_role_id === this.formData.usertype
        );
      } else {
        return this.user_groups;
      }
    },
    userCanEditBasics: function () {
      return (
        this.user.permissions.includes("GLOBAL") ||
        this.user.permissions.includes("ADMIN_USERS")
      );
    },
    canCustomizePermissions: function () {
      if (this.selectedUserGroup && this.user_groups.length) {
        return (
          this.user_groups.find((group) => group.name == this.selectedUserGroup)
            .customized_permissions == 1
        );
      }
      return false;
    },
  },
  created() {
    window.addEventListener(
      "resize",
      () => {
        if (window.innerWidth > 640 && this.displayTab == "basics") {
          this.displayTab = "account";
        }
      },
      { passive: true }
    );

    this.user_id = this.$route.params.id;
    if (this.user_id !== "add") {
      this.isEdit = true;
      this.getUser({ id: this.user_id })
        .then((res) => {
          if (!res.allowed_to_edit) {
            this.$router.push(
              { name: "userDetail", params: { id: this.$route.params.id } },
              () => {
                setTimeout(() => {
                  this.$toasted.error("You are not allowed to edit this user");
                }, 300);
              }
            );
          }

          if (!res.permissions) {
            res.permissions = [];
          }

          this.formData = res;

          this.selectUserType(res.role);
          if (res.dealer) {
            this.selectDealership(res.dealer);
          }
          this.formData.password = this.formData.password_confirmation =
            res.password;
          this.selectedUserType = res.role.name;
          this.selectedUserGroup = res.role.group;
          this.selectedUserStatus = res.status.name;
          this.formData.status = res.status.id;
        })
        .catch((err) => {
          console.error(err);
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    }
    http
      .get("/users/create")
      .then((res) => {
        this.user_types = res.data.data.user_types;
        this.user_groups = res.data.data.user_groups;
        this.dealership = res.data.data.dealership;
        this.auth_permissions_fields = res.data.data.permissions_fields;
        this.filterPermissions();

        //set new user defaults
        if (!this.isEdit) {
          //default status is active
          const defaultStatus = this.userStatus[0]; //active
          this.selectedUserStatus = defaultStatus.status;
          this.formData.status = defaultStatus.id;

          //select default user type if only one available
          if (this.user_types.length === 1) {
            this.selectUserType(this.user_types[0]);

            //If the default type was dealer, set default group to Salesperson, if available
            if (this.user_types[0].id === 3) {
              const defaultGroup = this.user_groups.find((obj) => {
                return obj.id === 6;
              });

              if (defaultGroup) {
                this.selectUserGroup(defaultGroup);
              }
            }
          }

          //select default dealership if only one available
          if (this.dealership.length === 1) {
            this.selectDealership(this.dealership[0]);
          }
        }
      })
      .catch((err) => {
        this.$toasted.error(err.response.data.message || "Server Error");
      });
  },
  components: {
    PageHeader,
    Dropdown,
    ConfirmModal,
  },
  methods: {
    ...mapActions({
      createUsers: "user/createUsers",
      updateUser: "user/updateUser",
      getUser: "user/getUser",
    }),
    selectUserType(item) {
      this.selectedUserType = item.name;
      this.formData.usertype = item.id;

      //reset customized permissions (available permissions will change)
      this.customized_permissions = null;

      if (!this.selectedUserGroup) return;

      let currentUserGroup = this.user_groups.filter(
        (group) => group.name == this.selectedUserGroup
      )[0];

      if (currentUserGroup && currentUserGroup.user_role_id !== item.id) {
        //territory manager can be auto-assigned
        if (item.id == 2) {
          const newGroup = this.user_groups.find((obj) => {
            return obj.user_role_id == 2;
          });
          this.selectUserGroup(newGroup);
        } else {
          this.selectedUserGroup = "";
          this.formData.usergroup = null;
          this.filterPermissions();
        }
      }
    },
    selectUserGroup(item) {
      this.selectedUserGroup = item.name;
      this.formData.usergroup = item.id;

      this.filterPermissions();

      //reset if current permissions are empty (on page load), or if the selected user group is not customizable.
      if (!this.customized_permissions || item.customized_permissions == 0) {
        this.resetPermissions({
          resetCustom: false,
        });
      } else {
        this.formData.permissions = this.customized_permissions;
      }

      if (!this.selectedUserType) {
        const newName = this.user_types.filter(
          (type) => type.id == item.user_role_id
        )[0];

        if (newName) {
          this.selectedUserType = newName.name;
          this.formData.usertype = item.user_role_id;
        }
      }
    },
    userGroupByName(name) {
      return this.user_groups.find((obj) => {
        return obj.name == name;
      });
    },
    userGroupByID(id) {
      return this.user_groups.find((obj) => {
        return obj.id == id;
      });
    },
    selectDealership(item) {
      this.selectedDealership = item.name;
      this.formData.dealership_id = item.id;
    },
    selectUserStatus(item) {
      this.selectedUserStatus = item.status;
      this.formData.status = item.id;
    },
    saveUser() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          this.$toasted.error("Your submission contains errors");
          return;
        }

        this.disableBtn = true;
        if (this.user_id === "add") {
          this.createUsers(this.formData)
            .then((res) => {
              this.returnToIndex(() => {
                setTimeout(() => {
                  this.$toasted.success(res.data?.message ?? "Success");
                }, 200);
              });
            })
            .catch((error) => {
              if (error.response.data.errors) {
                let errors = error.response.data.errors;
                this.$toasted.error(errors[Object.keys(errors)[0]]);
                return false;
              }
              this.$toasted.error(err.response.data.message || "Server Error");
            })
            .finally(() => {
              this.disableBtn = false;
            });
        } else {
          this.updateUser({ id: this.user_id, data: this.formData })
            .then((res) => {
              this.returnToIndex(() => {
                setTimeout(() => {
                  this.$toasted.success(res.data.message);
                }, 200);
              });
            })
            .catch((error) => {
              console.log("error");
              if (error.response.data.errors) {
                let errors = error.response.data.errors;
                this.$toasted.error(errors[Object.keys(errors)[0]]);
                return false;
              }
              this.$toasted.error(
                error.response.data.message || "Server Error"
              );
            })
            .finally(() => {
              this.disableBtn = false;
            });
        }
      });
    },
    permissionChange() {
      this.customized_permissions = this.formData.permissions;
    },
    permissionClick(e) {
      if (!this.canCustomizePermissions) {
        e.preventDefault();
      }
    },
    filterPermissions() {
      if (!this.selectedUserType || !this.selectedUserGroup) return;

      let group = this.userGroupByName(this.selectedUserGroup);

      if (group.user_role_id === 1 && group.id !== 1) {
        // admins, except for super users, can have all permissions (except super user and emails)
        this.permissions_fields = Object.assign(
          {},
          this.auth_permissions_fields
        );

        delete this.permissions_fields["Super User"];
        delete this.permissions_fields.emails;

        this.sortPermissions();
        return;
      } else if (group.user_role_id === 3) {
        // dealers use dealer principal as the basis
        group = this.userGroupByID(5);
      }

      const newPermissions = {};
      Object.assign(newPermissions, this.auth_permissions_fields);

      for (const cat in newPermissions) {
        newPermissions[cat] = newPermissions[cat].filter((perm) => {
          return group.permissions.includes(perm.code);
        });

      }

      this.permissions_fields = newPermissions;
      this.sortPermissions();
    },
    sortPermissions() {
      for (const cat in this.permissions_fields) {
        this.permissions_fields[cat].sort(
          (a, b) => a.group_order - b.group_order
        );
      }
    },
    resetPermissions({ resetCustom = true } = {}) {
      const item = this.userGroupByName(this.selectedUserGroup);

      const allPermissions = [];
      for (const cat in this.permissions_fields) {
        allPermissions.push(
          ...this.permissions_fields[cat].map((pf) => pf.code)
        );
      }

      this.formData.permissions = item.permissions.filter((p) =>
        allPermissions.includes(p)
      );

      if (resetCustom) this.customized_permissions = null;
    },
    isInDefaultPermissions(code) {
      if (!this.selectedUserGroup) return false;

      const group = this.userGroupByName(this.selectedUserGroup);
      return group.permissions.includes(code);
    },
    toggleCategory(cat) {
      const ref = "permission-cat-" + cat;

      const inputs = this.$refs[ref][0].querySelectorAll(
        'input[type="checkbox"]'
      );

      const newVal = !this.permissions_categories.includes(cat);

      inputs.forEach((input) => {
        if (input.checked !== newVal) {
          input.click();
        }
      });
    },

    returnToIndex(cb = () => {}) {
      this.$router.push(
        { name: "ManageUsers", query: { ...this.userIndexFilters } },
        cb
      );
    },
  },
};
</script>

<style scoped>
.control-label-title {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  padding-bottom: 8px;
}
</style>
