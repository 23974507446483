<template>
  <div>
    <div v-click-outside="menuHide">
      <div
        class="menu"
        :class="menu ? 'fixed' : 'absolute'"
        @click.stop="openMenu"
      >
        <inline-svg
          v-if="!menu"
          :src="require('@/assets/image/icons/menu.svg')"
        />
        <inline-svg v-else :src="require('@/assets/image/icons/close.svg')" />
      </div>
      <div class="header">
        <router-link
          tag="div"
          aria-label="dashboard"
          to="/dashboard"
          class="header-logo"
          @click.native="menu = false"
        >
          <img
            :src="require('@/assets/image/logo.png')"
            alt="Tym-Tractors"
            :srcset="`${require('@/assets/image/logo.png')},
                    ${require('@/assets/image/logo@2x.png')} 2x`"
          />
        </router-link>
        <button type="button" class="header-menu_button print:hidden" @click="openMenu">
          <img
            src="@/assets/image/icons/header-menu-button.svg"
            alt="Menu"
          />
        </button>
      </div>
      <section
        class="section-left xl:col-span-2 relative"
        :class="menu ? 'd-block' : ''"
      >
        <div class="sidebar">
          <router-link to="/dashboard" class="logo">
            <img
                :src="require('@/assets/image/logo.png')"
                alt="Tym-Tractors"
                :srcset="`${require('@/assets/image/logo.png')},
                        ${require('@/assets/image/logo@2x.png')} 2x`"
            />
          </router-link>
          <div class="list-group">
            <div class="list" v-if="userCanSeeWarrantyCategory">
              <div class="title">
                <inline-svg :src="require('@/assets/image/icons/copy.svg')" />
                <div>Warranties</div>
              </div>
              <ul>
                <router-link
                  tag="li"
                  to="/registrations"
                  @click.native="openMenu"
                  v-if="
                    user.permissions.includes('GLOBAL') ||
                    user.permissions.includes('REG_VIEW')
                  "
                >
                  <span>Registrations</span>
                </router-link>
                <router-link
                  tag="li"
                  to="/claims"
                  @click.native="openMenu"
                  v-if="
                    user.permissions.includes('GLOBAL') ||
                    user.permissions.includes('CLAIM_VIEW')
                  "
                >
                  <span>Claims</span>
                </router-link>
              </ul>
            </div>
            <div class="list" v-if="userCanSeeSalesCategory">
              <div class="title">
                <inline-svg
                  :src="require('@/assets/image/icons/website.svg')"
                />
                <div>Sales</div>
              </div>
              <ul>
                <router-link
                  tag="li"
                  to="/orders"
                  @click.native="openMenu"
                  v-if="
                    user.permissions.includes('GLOBAL') ||
                    user.permissions.includes('ORDER_VIEW')
                  "
                >
                  <span>Orders</span>
                </router-link>
                <router-link
                  tag="li"
                  to="/field-inventory"
                  @click.native="openMenu"
                  v-if="
                    user.role.id < 3 &&
                    (user.permissions.includes('GLOBAL') ||
                      user.permissions.includes('INV_VIEW'))
                  "
                >
                  <span>Field Inventory</span>
                </router-link>
                <router-link
                  tag="li"
                  to="/retail-programs"
                  @click.native="openMenu"
                  v-if="
                    user.permissions.includes('GLOBAL') ||
                    (user.permission_actions['Retail Program'] &&
                      user.permission_actions['Retail Program'].View)
                  "
                >
                  <span>Retail Programs</span>
                </router-link>
                <router-link
                  tag="li"
                  :to="{ name: 'ProgramPreapprovalIndex' }"
                  @click.native="openMenu"
                  v-if="
                    user.permissions.includes('GLOBAL') ||
                    (user.permission_actions['Program Preapproval'] &&
                      user.permission_actions['Program Preapproval'].View)
                  "
                >
                  <span>Retail Program Preapprovals</span>
                </router-link>
              </ul>
            </div>

            <div
              class="list"
              v-if="
                user.permissions.includes('GLOBAL') ||
                user.permissions.includes('AD_VIEW')
              "
            >
              <div class="title">
                <inline-svg :src="require('@/assets/image/icons/image.svg')" />
                <div>Advertising</div>
              </div>
              <ul>
                <!--                                <router-link tag="li" to="/co-op-claims"><span>Co-op Claims</span></router-link>-->
                <router-link @click.native="openMenu" tag="li" to="/gallery"
                  ><span>Gallery</span></router-link
                >
                <router-link @click.native="openMenu" tag="li" to="/brochures"
                  ><span>Brochures</span></router-link
                >
                <router-link
                  @click.native="openMenu"
                  tag="li"
                  to="/radio-scripts"
                  ><span>Radio Scripts</span></router-link
                >
                <router-link @click.native="openMenu" tag="li" to="/videos"
                  ><span>Videos</span></router-link
                >
              </ul>
            </div>

            <div class="list">
              <div class="title">
                <inline-svg
                  :src="require('@/assets/image/icons/clipboard.svg')"
                />
                <div>Resources</div>
              </div>
              <ul>
                <router-link
                  @click.native="openMenu"
                  tag="li"
                  to="/manuals"
                  v-if="
                    user.permissions.includes('GLOBAL') ||
                    user.permissions.includes('RES_MANUAL_VIEW')
                  "
                  ><span>Manuals</span></router-link
                >
                <router-link
                  @click.native="openMenu"
                  tag="li"
                  to="/sales-documentation"
                  v-if="
                    user.permissions.includes('GLOBAL') ||
                    user.permissions.includes('RES_DOC_VIEW')
                  "
                  ><span>Sales Documentation</span></router-link
                >
                <router-link @click.native="openMenu" tag="li" to="/news"
                  ><span>News</span></router-link
                >
                <router-link
                  @click.native="openMenu"
                  tag="li"
                  to="/service-bulletins"
                  ><span>Service Bulletins</span></router-link
                >
              </ul>
            </div>
            <div class="list" style="margin-top: 28px;">
              <div class="title">
                <inline-svg
                  :src="require('@/assets/image/icons/clipboard.svg')"
                />
                <ul>
                  <li>
                    <a class="link" href="https://lms.tym.world/" target="_blank">
                      <div>TYM LMS</div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="auth-user-area">
            <div class="admin" v-if="user && userHasAdminPermissions">
              <div class="w-11/12 admin-settings grid grid-cols-12 gap-0">
                <inline-svg
                  class="lg:col-span-2 xl:col-span-0"
                  :src="require('@/assets/image/icons/settings.svg')"
                />
                <div class="lg:col-span-10 xl:col-span-10">
                  <router-link @click.native="openMenu" to="/admin"
                    >Admin</router-link
                  >
                </div>
              </div>
            </div>
            <div class="auth-user grid grid-cols-6 gap-0">
              <div class="avatar-content">
                <div
                  class="
                    avatar
                    col-span-1
                    sm:col-span-1
                    md:col-span-1
                    lg:col-span-1
                    xl:col-span-1
                  "
                >
                  <img v-if="user && user.avatar" :src="user.avatar" alt="" />
                  <div v-else>
                    <div v-if="user && user.name">
                      {{ user.name | get_letters }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="auth-user-name">
                <div v-if="user && user.name">{{ user.name }}</div>
                <div v-if="user && user.role">
                  {{ user.role.role | user_role }}
                </div>
              </div>
              <div class="settings-button">
                <div class="flex flex-wrap">
                  <div class="relative">
                    <button
                      type="button"
                      v-click-outside="hideSettings"
                      @click="openSettings = !openSettings"
                    >
                      <inline-svg
                        class="settings-img"
                        :src="require('@/assets/image/icons/more-alt.svg')"
                      />
                    </button>
                    <transition
                      enter-active-class="transition ease-out duration-100 transform"
                      enter-class="opacity-0 scale-95"
                      enter-to-class="opacity-100 scale-100"
                      leave-active-class="transition ease-in duration-75 transform"
                      leave-class="opacity-100 scale-100"
                      leave-to-class="opacity-0 scale-95"
                    >
                      <div
                        v-if="openSettings"
                        class="absolute right-0 mt-2 w-56 rounded-md shadow-lg"
                        style="
                          width: 184px;
                          will-change: transform;
                          left: 0px;
                          transform: translate3d(-147px, -149px, 0px);
                        "
                      >
                        <div class="rounded-md bg-white shadow-xs">
                          <a
                            class="
                              link
                              py-2
                              px-4
                              font-normal
                              block
                              w-full
                              whitespace-no-wrap
                              bg-transparent
                              text-gray-800
                              cursor-pointer
                            "
                          >
                            <div class="link-img">
                              <inline-svg
                                class="settings-img"
                                :src="
                                  require('@/assets/image/icons/git-commit.svg')
                                "
                              />
                            </div>
                            <router-link
                              v-if="user"
                              :to="'/account-settings'"
                              class="link-text"
                              >Account Settings</router-link
                            >
                          </a>
                          <a
                            @click="userLogout"
                            class="
                              link
                              py-2
                              px-4
                              font-normal
                              block
                              w-full
                              whitespace-no-wrap
                              bg-transparent
                              text-gray-800
                              cursor-pointer
                            "
                          >
                            <div class="link-img">
                              <inline-svg
                                class="settings-img"
                                :src="
                                  require('@/assets/image/icons/log-in.svg')
                                "
                              />
                            </div>
                            <div class="link-text">Log Out</div>
                          </a>
                        </div>
                      </div>
                    </transition>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ClickOutside from "vue-click-outside";

export default {
  name: "sidebar",
  data() {
    return {
      openSettings: false,
      menu: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    userHasAdminPermissions: function () {
      return (
        this.user.permissions.includes("GLOBAL") ||
        this.user.permissions.includes("ADMIN_USERS") ||
        this.user.permissions.includes("ADMIN_DEALERS") ||
        this.user.permissions.includes("ADMIN_MEDIA") ||
        this.user.permissions.includes("ADMIN_NEWS") ||
        this.user.permissions.includes("ADMIN_PROGRAMS") ||
        this.user.permissions.includes("ADMIN_PROD_CAT") ||
        this.user.permissions.includes("ADMIN_WARR_INFO_VIEW")
      );
    },

    userCanSeeWarrantyCategory() {
      return (
        this.user.permissions.includes("GLOBAL") ||
        this.user.permissions.includes("REG_VIEW") ||
        this.user.permissions.includes("CLAIM_VIEW")
      );
    },

    userCanSeeSalesCategory() {
      return (
        this.user.permissions.includes("GLOBAL") ||
        this.user.permissions.includes("ORDER_VIEW") ||
        this.user.permissions.includes("REG_VIEW") ||
        (this.user.permission_actions["Retail Program"] &&
          this.user.permission_actions["Retail Program"].View)
      );
    },
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
    }),
    menuHide() {
      this.menu = false;
    },
    hideSettings() {
      this.openSettings = false;
    },
    userLogout() {
      this.logout()
        .then(() => {
          this.$router.push("/login", () => {
            // sessionStorage.clear();
          });
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    },
    openMenu() {
      this.menu = !this.menu;
    },
  },
  filters: {
    user_role: function (value) {
      if (!value) return "";
      let text = "";
      value.split("_").map((item) => {
        text += " " + item;
      });
      return text.toLowerCase();
    },
    get_letters: function (value) {
      if (!value) return "";
      let text = "";
      value.split(" ").map((item, index) => {
        if (index < 2) text += " " + item.charAt(0);
      });
      return text;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style scoped lang="scss">
.sidebar {
  height: 100%;
  position: fixed;
  width: 290px;
  background-color: white;
  z-index: 50;

  @media (max-width: 768px) {
    width: 100% !important;
    padding-top: 30px;
  }
}
</style>
