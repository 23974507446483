<template>
  <div class="dropdown-settings relative">
    <button
      aria-label="actions-menu"
      type="button"
      class="button btn-td-hover"
      :class="openClose ? 'active' : ''"
      v-click-outside="hide"
      @click="openClose = !openClose"
    >
      <inline-svg
        class="m-auto"
        :src="require('@/assets/image/icons/more-alt.svg')"
      />
    </button>
    <div class="dropdown" v-if="openClose">
      <div @click="createRegistration" v-if="userCanCreateRegistration">
        Start Registration
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import { mapActions, mapState } from "vuex";
import { http } from "../../../api";

export default {
  name: "InventoryTableDropdown",
  props: ["item"],
  components: {
    // ConfirmModal,
  },
  data() {
    return {
      openClose: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    userCanCreateRegistration: function () {
      return (
        this.user.permissions.includes("GLOBAL") ||
        this.user.permissions.includes("REG_CREATE")
      );
    },
  },
  methods: {
    ...mapActions({}),
    hide() {
      this.openClose = false;
    },
    createRegistration() {
      if (
        this.user.permissions.includes("GLOBAL") ||
        this.user.permissions.includes("REG_CREATE")
      ) {
        //check tractor dealership and create permissions
        this.$router.push(
          "/registrations/add?tractor_sn=" +
            this.item.item.tractor_sn +
            "&dealership=" +
            this.item.item.dealership
        ); //create route to begin registration and pass tractor serial number
      } else {
        this.$toasted.error(
          "You do not have permission to register this tractor."
        );
      }
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>
