<template>
  <section class="review-order col-span-12">
    <div class="container">
      <PageHeader
        backText="Back to Orders"
        :backRoute="{ name: 'Orders', query: { ...orderFilters } }"
        :saveButton="false"
        :discardLink="false"
        :details="{
          title: '#' + order.id,
          status: order.status && order.status.name,
          date: order.created_at,
        }"
      >
        <template v-slot:actions>
          <router-link
            v-if="canEdit"
            tag="button"
            type="button"
            :to="{
              name: 'editTractorOrder',
              params: {
                id: $route.params.id
              }
            }"
            class="btn btn-info px-40"
            >Edit Order</router-link
          >
        </template>
      </PageHeader>
      <div class="review-section">
        <div class="dealer-information">
          <div class="subtitle">Dealer & Shipping Information</div>
          <div class="border-information">
            <div class="information mt-5" v-if="order && order.dealer">
              <div class="title">
                <div>Dealer</div>
                <div v-if="order.dealer">{{ order.dealer.name }}</div>
              </div>
              <div class="title">
                <div>Shipping Address</div>
                <div>{{ order.shipping_address }}</div>
              </div>
              <div class="title">
                <div>Phone Number</div>
                <div>
                  <p v-for="dealer in order.dealer.phone_numbers">
                    {{ dealer.phone_number }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="order-summary border-none pb-5">
          <div class="container m-0">
            <div class="flex items-center justify-between">
              <div class="subtitle">Order Summary</div>
              <p>
                <span class="key">
                  <inline-svg :src="require('@/assets/image/icons/key.svg')" />
                </span>
                Delivered Assembled
              </p>
            </div>
            <div v-if="lineItems" class="orders" v-for="lineItem in lineItems">
              <div class="order-image">
                <img
                  v-if="lineItem.img"
                  :src="lineItem.img"
                  alt="tractor-img"
                  class="h-full object-cover w-full"
                />
                <img
                  src="@/assets/image/no-image.png"
                  v-else
                  alt="tractor-img"
                  class="h-full object-cover w-full"
                />
              </div>
              <div class="order-information">
                <div>
                  <div class="information">
                    <div class="title">
                      <div>Model</div>
                      <div>
                        {{ lineItem.model ? lineItem.model.model : "" }}
                      </div>
                    </div>
                    <div class="title">
                      <div>Configuration</div>
                      <div v-if="lineItem.configuration">
                        {{ lineItem.configuration.name }} /
                        {{ lineItem.configuration.description }}
                      </div>
                      <div v-else>None Selected</div>
                    </div>
                    <div class="title">
                      <div>Tires</div>
                      <div>
                        {{ lineItem.tires ? lineItem.tires.name : "" }}
                        <span
                          class="key"
                          v-if="lineItem.configuration_assembled"
                        >
                          <inline-svg
                            :src="require('@/assets/image/icons/key.svg')"
                          />
                        </span>
                      </div>
                    </div>
                    <div class="title">
                      <div>Options</div>
                      <div>
                        <div v-for="option in lineItem.options">
                          <div class="description">
                            {{ option.name_description }}
                            <span class="key" v-if="option.is_installed">
                              <inline-svg
                                :src="require('@/assets/image/icons/key.svg')"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="title"
                      v-for="(attachment, index) in lineItem.attachments"
                      :key="index"
                    >
                      <div>{{ attachment.category.name }}</div>
                      <div>
                        {{ attachment.name_description }}
                        <span class="key" v-if="attachment.is_installed">
                          <inline-svg
                            :src="require('@/assets/image/icons/key.svg')"
                          />
                        </span>
                      </div>
                    </div>
                    <div class="title">
                      <div>Quantity</div>
                      <div>{{ lineItem.quantity }}</div>
                    </div>
                    <div class="title">
                      <div>Terms</div>
                      <div v-if="lineItem.terms.length">
                        {{ lineItem.terms[0].term }}
                      </div>
                    </div>
                    <div class="title">
                      <div>Notes</div>
                      <div>{{ lineItem.notes }}</div>
                    </div>
                    <div class="title">
                      <div>PO Number</div>
                      <div>{{ lineItem.po_number }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="activity-history" v-if="history && history.length">
          <div class="subtitle">Order Activity History</div>
          <div class="info-block">
            <div class="info" v-for="item in history">
              <inline-svg :src="require('@/assets/image/icons/history.svg')" />
              <p v-if="item.created_by">{{ item.created_by.name }}</p>
              &#160; {{ item.description }} on {{ item.created_at }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../component/PageHeader";
import ClickOutside from "vue-click-outside";
import { http } from "../../../api";
import { mapActions, mapState } from "vuex";

export default {
  name: "orderDetail",
  data() {
    return {
      background: "",
      color: "",
      lineItems: [],
      order: {},
      history: [],
      dealerOpen: false,
      addressOpen: false,
      dealerName: "",
      addressName: "",
    };
  },
  components: {
    PageHeader,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      orderFilters: (state) => state.order.indexFilters,
      allowedEditStatusIds: state => state.order.allowedEditStatusIds,
    }),
    canEdit() {
      //block editing based on order status
      if ( !this.allowedEditStatusIds.includes(this.order.status.id) ) return false;

      return this.user.permissions.includes("GLOBAL") ||
        this.user.permissions.includes("ORDER_APPR")
    }
  },
  mounted() {
    this.getOrder();
  },
  methods: {
    getOrder() {
      http
        .get("orders/" + this.$route.params.id)
        .then((res) => {
          this.lineItems = res.data.data.line_items;
          this.order = res.data.data.order;
          this.history = res.data.data.history;
          this.transformStatus();
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    },
    transformStatus() {
      switch (this.order.status.id) {
        case 7:
          this.background = "rgba(215, 173, 65, 0.4)";
          this.color = "#5C4E00";
          this.border = "none";
          break;
        case 6:
          this.background = "rgba(205, 216, 29, 0.2)";
          this.color = "#7c8110";
          this.border = "none";
          break;
        case 5:
          this.background = "rgba(29, 184, 216, 0.2)";
          this.color = "#0B4067";
          this.border = "none";
          break;
        case 4:
          this.background = "#F9F9F9";
          this.color = "#606060";
          this.this.border = "1px solid #606060";
          break;
        case 3:
          this.background = "rgba(215, 173, 65, 0.4)";
          this.color = "#5C4E00";
          this.border = "none";
          break;
        case 2:
          this.background = "rgba(212, 4, 4, 0.2)";
          this.color = "#7D0505";
          this.border = "none";
          break;
        case 1:
          this.background = "rgba(99, 220, 43, 0.3)";
          this.color = "#24610E";
          this.border = "none";
          break;
      }
    },
    dropdownOpenModal() {
      this.dealerOpen = !this.dealerOpen;
    },
    dropdownOpenAddressModal() {
      this.addressOpen = !this.addressOpen;
    },
    dropdownHide() {
      this.dealerOpen = false;
    },
    dropdownAddressHide() {
      this.addressOpen = false;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style scoped>
</style>
