import Vue from 'vue';
import createPersistedState from "vuex-persistedstate";
import Vuex from 'vuex'
import auth from './modules/auth';
import dashboard from './modules/dashboard';
import emailLists from './modules/email-lists';
import news from './modules/news';
import videos from './modules/videos';
import claim from './modules/claim';
import registration from './modules/registration';
import inventory from './modules/inventory';
import order from './modules/order';
import user from './modules/user';
import media from './modules/media';
import tractor from './modules/tractor';
import gallery from './modules/gallery';
import salesDocumentation from './modules/salesDocumentation';
import radioScripts from './modules/radioScripts';
import mediaCategory from './modules/media-category';
import manageVideos from './modules/manage-videos';
import dealers from './modules/dealers';
import coops from './modules/coops';
import laborCode from './modules/laborCode';
import modelInfo from './modules/modelInfo';
import manuals from './modules/manuals';
import serviceBulletins from './modules/serviceBulletins';
import programs from './modules/programs';
import programPreapprovals from './modules/program-preapprovals';

Vue.use(Vuex);

const authState = createPersistedState({
    paths: ['auth']
});

// const dataState = createPersistedState({
//     paths: ['programs', 'registration', 'claim','order'],
//     storage: window.sessionStorage,
// });

export default new Vuex.Store({
    modules: {
        auth: auth,
        dashboard: dashboard,
        news: news,
        dealers: dealers,
        claim: claim,
        emailLists: emailLists,
        registration: registration,
        inventory: inventory,
        order: order,
        user: user,
        videos: videos,
        gallery: gallery,
        salesDocumentation: salesDocumentation,
        radioScripts: radioScripts,
        media: media,
        tractor: tractor,
        manageVideos: manageVideos,
        mediaCategory: mediaCategory,
        coops: coops,
        manuals: manuals,
        laborCode: laborCode,
        modelInfo: modelInfo,
        serviceBulletins: serviceBulletins,
        programs: programs,
        programPreapprovals, programPreapprovals,
    },
    plugins: [authState]
});

