<template>
  <section class="add-edit-page col-span-12">
    <ValidationObserver
      @submit.prevent="submitModelInfo"
      tag="form"
      class="container"
      ref="form"
    >
      <PageHeader
        :title="isEdit ? `Edit Model ${$route.params.model}` : 'Add Model Info'"
        :backText="
          isEdit ? 'Back to Model Info Details' : 'Back to Model Info Index'
        "
        :backRoute="backRoute"
        saveText="Submit"
        @save="submitModelInfo"
        @discard="showDiscardModal = true"
        :buttonsDisabled="buttonDisabled"
      />
      <div class="edit-details">
        <div class="model-info-section">
          <div class="subtitle">Enter Model Info</div>
          <div class="info-block">
            <div class="info-block-container">
              <div class="form-group w-full">
                <label class="control-label">Model ID</label>
                <ValidationProvider
                  rules="required"
                  tag="div"
                  v-slot="{ classes }"
                >
                  <input
                    :class="classes"
                    type="text"
                    v-model="formData.model"
                    name="model"
                    placeholder="(i.e. T25)"
                  />
                </ValidationProvider>
              </div>
              <div class="form-group w-full">
                <label class="control-label">Service Model</label>
                <ValidationProvider
                  rules="required"
                  tag="div"
                  v-slot="{ classes }"
                >
                  <input
                    :class="classes"
                    type="text"
                    v-model="formData.service_model"
                    name="service_model"
                    placeholder="(i.e. T25NHUS1)"
                  />
                </ValidationProvider>
              </div>            
              <div class="form-group w-full">
                  <dropdown
                    :data="modelTypes"
                    fieldName="type"
                    label="Model Type"
                    @select="selectModelType"
                    inputName="model_type"
                    :editValue="formData.model_type"
                    :validation="true"
                  ></dropdown>
              </div>
              <div class="form-group w-full">
                <label class="control-label">Weight</label>
                <ValidationProvider
                  rules="required"
                  tag="div"
                  v-slot="{ classes }"
                >
                  <input
                    :class="classes"
                    type="number"
                    v-model="formData.weight"
                    name="weight"
                    placeholder="(i.e. 1800)"
                  />
                </ValidationProvider>
              </div>
              <div class="form-group w-full">
                <label class="control-label">Description</label>
                <ValidationProvider
                  rules=""
                  tag="div"
                  v-slot="{ classes }"
                >
                  <textarea
                    :class="classes"
                    rows="2"
                    v-model="formData.description"
                    name="description"
                    placeholder="Enter description..."
                  />
                </ValidationProvider>
              </div>
              <div class="form-group w-full">
                <CheckboxInput
                  class="mb-2"
                  :model.sync="formData.program_default"
                  name="program_default"
                  label="Include in default program model list"
                />
              </div>
            </div>
          </div>
        </div>
        
        <div class="serial-prefixes-section" >
          <div class="subtitle">Serial Prefixes</div>
          <div class="info-block">
            <div class="info-block-container">
              <div class="serial-prefixes">
                <div
                  class="serial-prefixes__row"
                  v-for="(prefix, p) in updatedPrefixes"
                  :key="`prefix_${p}`"
                  v-bind:class="{
                    'bg-gray-100': p % 2 === 1,
                  }"
                >
                  <span class="serial-prefixes__prefix">{{prefix}}</span>
                  <button
                    type="button"
                    class="c-removeButton"
                    @click.prevent="$event.target.blur(); removePrefix(p)"
                  ></button>
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    v-bind:class="{
                      'error' : errorMessages.prefixes
                    }"
                    placeholder="+ Add Prefix"
                    v-model="newPrefix"
                    v-on:keydown.enter.prevent=""
                    v-on:keyup.enter="addPrefix"
                    @input="errorMessages.prefixes = null"
                  />
                  <button
                    type="button"
                    class="add-icon"
                    @click="addPrefix"
                  >
                    <inline-svg :src="require('@/assets/image/icons/add.svg')" />
                  </button>
                </div>
                <div v-if="errorMessages.prefixes" class="error-message">{{errorMessages.prefixes}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="send">
          <div class="edit-link" @click="showDiscardModal = true">Discard</div>
          <button
            class="btn btn-black px-60"
            :disabled="buttonDisabled"
            :class="buttonDisabled ? 'opacity-50 cursor-wait' : ''"
            type="submit"
          >
            Submit
          </button>
        </div>
      </div>
      <ConfirmModal
        text="Discard changes?"
        confirmBtn="Discard"
        :show="showDiscardModal"
        @confirm="$router.push(indexRoute)"
        @close="showDiscardModal = false"
      />
    </ValidationObserver>
  </section>
</template>

<script>
import PageHeader from "../../../component/PageHeader";
import Dropdown from "../../../component/Dropdown";
import ConfirmModal from "../../../component/ConfirmModal";
import CheckboxInput from "../../../component/Inputs/Checkbox";
import { mapActions, mapState } from "vuex";

export default {
  name: "AddModelInfo",
  props: ["isEdit"],
  data() {
    return {
      buttonDisabled: false,
      showDiscardModal: false,
      formData: {
        model: "",
        model_type: null,
        service_model: "",
        weight: null,
        description: "",
        program_default: false,
      },
      originalPrefixes: [],
      updatedPrefixes: [],
      newPrefix: "",
      errorMessages: {
        prefixes: null,
      }
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      modelEntry: state => state.modelInfo.model,
      modelTypes: state => state.modelInfo.modelTypes.map(type => ({type})),
      assignedPrefixes: state => state.modelInfo.prefixList,
      indexFilters: state => state.modelInfo.indexFilters,
    }),

    backRoute: function () {
      if (this.isEdit) {
        return {
          name: "modelInfoDetail",
          params: { id: this.$route.params.model },
        };
      } else {
        return this.indexRoute;
      }
    },
    indexRoute: function() {
      return {
        name: 'modelInfoLookup',
        query: { ...this.indexFilters }
      }
    }
  },
  components: {
    PageHeader,
    Dropdown,
    ConfirmModal,
    CheckboxInput,
  },
  created() {
    if (this.isEdit) {
      this.editModel();
    } else {
      this.getCreate();
    }
  },
  methods: {
    ...mapActions({
      getCreate: "modelInfo/getModelInfoCreate",
      createModelInfo: "modelInfo/createModelInfo",
      getEdit: "modelInfo/getModelInfoEdit",
      updateModelInfo: "modelInfo/updateModelInfo",
    }),
    editModel() {
      this.getEdit(this.$route.params.model)
        .then((res) => {
          this.formData = this.modelEntry;
          this.originalPrefixes = [...res.prefixes];
          this.updatedPrefixes = [...this.originalPrefixes];
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    },
    selectModelType(item) {
      this.formData.model_type = item.type;
    },
    addPrefix() {
      // entered prefix is blank
      if (!this.newPrefix) {
        this.errorMessages.prefixes = "The prefix cannot be blank";
        return;
      };

      // entered prefix is already in list
      if (this.updatedPrefixes.some(p => p.toLowerCase() === this.newPrefix.toLowerCase())) {
        this.errorMessages.prefixes = "That prefix has already been assigned to this model";
        return;
      }

      // entered prefix is already assigned to another model
      if (this.assignedPrefixes.some(p => p.toLowerCase() === this.newPrefix.toLowerCase())) {
        this.errorMessages.prefixes = "That prefix has already been assigned to another model";
        return;
      }

      this.errorMessages.prefixes = null;

      this.updatedPrefixes.push(this.newPrefix);
      this.updatedPrefixes.sort();
      this.newPrefix = "";
    },
    removePrefix(index) {
      this.updatedPrefixes.splice(index, 1);
    },
    submitModelInfo() {
      //check form

      this.$refs.form
        .validate()
        .then((success) => {
          if (success) {
            this.buttonDisabled = true;

            // Add prefix changes to form data
            if (this.isEdit) {
              const prefixChanges = [];
              this.updatedPrefixes.forEach(prefix => {
                if (!this.originalPrefixes.includes(prefix)) {
                  prefixChanges.push({prefix, action: "add"});
                }
              })
              this.originalPrefixes.forEach(prefix => {
                if (!this.updatedPrefixes.includes(prefix)) {
                  prefixChanges.push({prefix, action: "remove"});
                }
              });
              this.formData.prefix_changes = prefixChanges;
            } else {
              this.formData.serial_prefixes = this.updatedPrefixes;
            }


            if (this.isEdit) {
              this.updateModelInfo({ model: this.$route.params.model, formData: this.formData })
                .then((res) => {
                  console.log(res);

                  //return to search index if the model ID stayed the same. If it changed, clear the filters.
                  if (res.data.data.updated_model_info?.model_id === this.$route.params.model) {
                    this.$router.push(this.indexRoute);
                  } else {
                    this.$router.push({name: 'modelInfoLookup'});
                  }
                })
                .catch((err) => {
                  this.buttonDisabled = false;
                  this.$toasted.error(
                    err.response.data.message || "Server Error"
                  );
                });
            } else {
              this.createModelInfo({formData: this.formData})
                .then((res) => {
                  this.$router.push(this.indexRoute);
                })
                .catch((err) => {
                  this.buttonDisabled = false;
                  this.$toasted.error(
                    err.response.data.message || "Server Error"
                  );
                });
            }
          } else {
            this.$toasted.error("Your submission contains errors");
          }
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    },
  },
};
</script>

<style type="text/css" scoped lang="scss">

  .edit-details {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    > * {
      flex: 0 0;
    }

    .model-info-section {
      flex-basis: 100%;
      margin-right: 40px;
      max-width: 600px;
    }

    .serial-prefixes-section {
      flex-basis: 250px;
      max-width: 100%;
    }

    .send {
      flex-basis: 100%;
    }
  }

  input[type="number"] {
    
    // HIDE NUMBER BUTTONS

    /* Firefox */
    -moz-appearance: textfield;

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .info-block {
    width: 100%;
    display: inline-block;
  }

  .serial-prefixes {

    &__row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 -12px;
      padding: 8px 12px;

      &:hover .c-removeButton {
        opacity: 1;
      }
    }

    .c-removeButton {
      margin-right: 6px;
      opacity: 0;
      transition: opacity 0.1s;

      &:focus {
        opacity: 1;
      }
      @media (hover: none), (pointer: coarse) {
        opacity: 1;
      }
    }

    .form-group {
      display: flex;
      align-items: center;
      // margin-left: -12px;
      // margin-right: -12px;

      input {
        flex: 1 1 100%;
        height: 32px;
        font-size: 13px;
        padding-right: 10px;
        margin-right: auto;

        &::placeholder {
          font-size: 12px;
        }

        &.error {
          border: 1px solid #c53030;
        }
      }

      .add-icon {
        flex: 0 0 auto;
      }
    }

    .error-message {
      color: #c53030;
      margin-top: 10px;
      font-size: 13px;
    }
  }

  .add-icon {
    background-color: black;
    border-radius: 6px;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
  }

</style>
