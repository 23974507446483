<template>
  <section class="manage col-span-12">
    <div class="container grid grid-cols-1 gap-0">
      <PageHeader
        title="Manage Dealers"
        :saveButton="false"
        :discardLink="false"
        :addButton="true"
        addText="Add New Dealer"
        @add="$router.push({ name: 'addDealer', params: { id: 'add' } })"
        backText="Back to Settings"
        :backRoute="{ name: 'AdminSettings' }"
      >
        <template v-slot:actions>
          <button
            class="btn btn-info px-15"
            @click="exportExcel"
            :disabled="disableExportExcel"
            v-if="user && userCanExport"
          >
            <loading v-if="disableExportExcel" />
            <div class="text mr-3"><span>Export</span></div>
            <div class="export">
              <inline-svg :src="require('@/assets/image/icons/download.svg')" />
            </div>
          </button>
        </template>
      </PageHeader>
      <div class="filter-block">
        <div class="filter-block-filter">
          <div class="table-filter-search-input">
            <div class="form-group">
              <label class="control-label">Search</label>
              <input
                v-on:keyup.enter="filter"
                v-model="filterData.name"
                type="text"
                name="search"
                class="search"
                placeholder="Dealer Name"
              />
              <inline-svg
                class="search-icon"
                :src="require('@/assets/image/icons/search.svg')"
              />
            </div>
          </div>
          <div class="table-filter-dealership-select">
            <dropdown
              :data="territoryManager"
              :status="false"
              fieldName="name"
              label="Territory Manager"
              @select="selectTerritoryManager"
              :validation="false"
              inputName="territory_manager"
              :editValue="selectedTerritoryManager"
              v-if="territoryManager.length > 1"
            ></dropdown>
          </div>
        </div>
        <div class="card">
          <div class="btn btn-black px-40" @click="filter">
            <div class="text"><a>Apply</a></div>
          </div>
          <div class="btn redo px-15" @click="resetFilter">
            <a>
              <inline-svg :src="require('@/assets/image/icons/redo.svg')" />
            </a>
          </div>
        </div>
      </div>
      <div class="table-block col-span-1">
        <data-table
          v-bind:page="filterData.page"
          :header="fieldDealerTable.header"
          :data="dealers"
          :link="{ url: '/admin/dealer/', name: 'name' }"
          class="media-table"
          :isLoading="isLoading"
        />
      </div>
      <div class="pages" v-if="pagination.total && pagination.total_pages > 1">
        <div class="pages-quantity">
          <template v-if="!isLoading">
            Page {{ filterData.page }} of {{ pagination.total_pages }} - Showing
            {{ pagination.per_page }} Results of {{ pagination.total }} Total
          </template>
        </div>
        <paginate
          v-if="pagination.total_pages"
          :page-count="pagination.total_pages"
          :page-range="screenWidth < 468 ? 3 : 6"
          :initial-page="initialPage - 1"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="'&laquo;'"
          :next-text="'&raquo;'"
          :container-class="'pages-btn'"
          :active-class="pageActive"
        >
        </paginate>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../../component/PageHeader";
import DataTable from "../../../component/DataTable";
import Dropdown from "../../../component/Dropdown";
import Loading from "../../../component/Loading";
import { mapActions, mapState } from "vuex";
import { http } from "../../../../api";


export default {
  name: "ManageDealers",
  data() {
    return {
      isLoading: false,
      screenWidth: 0,
      selectedTerritoryManager: "All",
      fieldDealerTable: {
        header: [
          {
            field: "name",
            name: "Dealer Name",
            transformToComponent: "TransformToLink",
          },
          { field: "location", name: "Location" },
          { field: "labor_rate", name: "Labor Rate" },
          { field: "territory_manager", name: "Territory Manager" },
          {
            field: "status",
            name: "Status",
            transformField: "status",
            transform: this.transformToStatus,
          },
          {
            field: "id",
            name: "Actions",
            transformToComponent: "DealersTableDropdown",
          },
        ],
      },
      filterData: {
        page: 1,
      },
      pageActive: "active",
      disableExportExcel: false,
    };
  },
  components: {
    PageHeader,
    DataTable,
    Dropdown,
    Loading
  },
  computed: {
    ...mapState({
      dealers: (state) => state.dealers.dealers,
      territoryManager: (state) => state.dealers.territoryManager,
      pagination: (state) => state.dealers.pagination,
      initialPage: (state) => state.dealers.initialPage,
      user: (state) => state.auth.user,
    }),
    userCanExport: function() {
      return true;
    }
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (this.$route.query.page) {
      if (this.$route.query.name) {
        this.filterData.name = this.$route.query.name;
      }
      if (this.$route.query.tm_id) {
        this.filterData.tm_id = this.$route.query.tm_id;
        let tm = this.territoryManager.filter(
          (item) => item.id == this.$route.query.tm_id
        );
        this.selectedTerritoryManager = tm[0].name;
      }
      this.filterData.page = +this.$route.query.page;
      this.getInitialPage(this.filterData.page);

      this.isLoading = true;
      this.filterDealers(this.filterData).finally(() => {
        this.isLoading = false;
      });
    } else {
      this.isLoading = true;
      this.getDealersList();
    }
  },
  methods: {
    ...mapActions({
      getDealers: "dealers/getDealers",
      filterDealers: "dealers/filterDealers",
      getInitialPage: "dealers/getInitialPage",
    }),
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
    getDealersList() {
      this.filterData.page = this.initialPage;
      this.isLoading = true;
      this.getDealers({ page: this.filterData.page })
        .then((res) => {})
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    filter() {
      this.$router.push(
        "/admin/dealers?" + new URLSearchParams(this.filterData).toString()
      );
      this.isLoading = true;
      this.filterDealers(this.filterData)
        .then((res) => {})
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    resetFilter() {
      this.pageActive = "";
      this.filterData = {
        page: 1,
      };
      this.selectedTerritoryManager = "All";
      this.filterData.tm_id = "";
      this.getInitialPage(1);
      this.filter();
    },
    transformToStatus(id, val) {
      let background;
      let border;
      let color;
      let width;
      switch (id) {
        case "Active":
          background = "rgba(99, 220, 43, 0.3)";
          color = "#24610E";
          border = "none";
          width = "77px";
          break;
        case "Inactive":
          background = "rgba(212, 4, 4, 0.2)";
          color = "#7D0505";
          border = "none";
          width = "77px";
          break;
      }
      return (
        '<div class="td-status" style="background-color:' +
        background +
        ";color:" +
        color +
        ";border:" +
        border +
        ";width:" +
        width +
        '">' +
        val +
        "</div>"
      );
    },
    selectTerritoryManager(tm) {
      this.selectedTerritoryManager = tm.name;
      this.filterData.tm_id = tm.id;
      this.filter();
    },
    clickCallback(pageNum) {
      this.getInitialPage(pageNum);
      this.filterData.page = pageNum;
      this.pageActive = "active";
      this.filter();
    },
    exportExcel() {
      if (!this.disableExportExcel) {
        this.disableExportExcel = true;
        const q = new URLSearchParams(this.filterData);
        http
          .get("dealers/export?" + q.toString(), {
            responseType: "blob",
          })
          .then((res) => {
            let blob = new Blob([res.data]);
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "dealers.xlsx";
            link.click();
          })
          .catch((err) => {
            this.$toasted.error("Dealers Export failed");
          })
          .finally(() => {
            this.disableExportExcel = false;
          });
      }
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
</style>
