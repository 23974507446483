<template>
  <div class="dropdown-settings relative">
    <button
      aria-label="actions-menu"
      type="button"
      class="button btn-td-hover"
      :class="openClose ? 'active' : ''"
      v-click-outside="hide"
      @click="openClose = !openClose"
    >
      <inline-svg
        class="m-auto"
        :src="require('@/assets/image/icons/more-alt.svg')"
      />
    </button>
    <div class="dropdown" v-if="openClose">
      <router-link tag="div" :to="{ name: 'modelInfoDetail', params: { model: item.item.model}}"
        >View</router-link
      >
      <router-link tag="div" :to="{ name: 'modelInfoEdit', params: { model: item.item.model}}"
        >Edit</router-link
      >
      <div class="delete" @click="openConfirmModal">
        <span>{{ deleteText }}</span>
      </div>
    </div>
    <ConfirmModal
      :text="`Are you sure you want to ${deleteText.toLowerCase()} model ID ${item.item.model}?`"
      :confirmBtn="`${deleteText}`"
      :show="showDeleteModal"
      @confirm="deleteEntry"
      @close="closeConfirmModal"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ConfirmModal from "../ConfirmModal";

export default {
  name: "ModelInfoTableDropdown",
  props: ["item"],
  data() {
    return {
      openClose: false,
      showDeleteModal: false,
    };
  },
  computed: {
    isActive: function() {
      return this.item.item.status?.toLowerCase() === 'active';
    },
    deleteText: function() {
      return this.isActive ? 'Deactivate' : 'Restore';
    }
  },
  components: {
    ConfirmModal,
  },
  methods: {
    ...mapActions({
      deleteModelInfo: "modelInfo/deleteEntry",
      filterModelInfo: "modelInfo/getModelInfo",
    }),
    openConfirmModal() {
      this.showDeleteModal = true;
    },
    deleteEntry() {
      this.deleteModelInfo(this.item.item.model)
        .then((res) => {
          const query = {...this.$route.query}
          this.filterModelInfo(query);
        
          this.$toasted.success(res.data.message);
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        })
        .finally(() => {
          this.closeConfirmModal();
        });
    },
    closeConfirmModal() {
      this.showDeleteModal = false;
    },
    hide() {
      this.openClose = false;
    },
  },
};
</script>
