<template>
  <section class="create-claims col-span-12">
    <div class="container">
      <PageHeader
        title="Create Claim"
        backText="Back to Claims"
        :backRoute="{ name: 'Claims', query: { ...claimFilters } }"
        :saveButton="false"
        :discardLink="false"
        class="col-span-12"
      ></PageHeader>
      <div class="create">
        <div class="title">Enter Primary Serial Number</div>
        <div class="serial-number">
          <div class="search">
            <div class="form-group">
              <label class="control-label">Primary SN</label>
              <input type="text" v-model="searchValue" @input="filterTractor" />
            </div>
          </div>
          <!--<div class="status-dropdown">-->
          <!--<dropdown-->
          <!--:data='tractorStatus'-->
          <!--:status="true"-->
          <!--fieldName="description"-->
          <!--label="Tractor Status"-->
          <!--@select = "filterStatus"-->
          <!--:validation="false"-->
          <!--inputName="status"-->
          <!--:editValue = "statusName"-->
          <!--&gt;</dropdown>-->
          <!--</div>-->
        </div>
      </div>
      <div class="table-block col-span-1">
        <div class="tables">
          <div class="table">
            <div class="data-table-head">
              <div class="table-head-container">
                <div class="tr">
                  <div class="th" v-for="(item, index) in header" :key="index">
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </div>
            <div class="tbody">
              <div
                v-if="
                  !tractorSn.tractor_sns || tractorSn.tractor_sns.length == 0
                "
                class="text-center no-result"
              >
                No results found. Please adjust your search criteria.
              </div>
              <div
                class="tr cursor-pointer"
                v-else
                v-for="(item, i) in tractorSn.tractor_sns"
                :key="i"
                @click="selectTractor(item)"
              >
                <div class="td">
                  <span class="uppercase">
                    {{ item.primary_sn }}
                  </span>
                </div>
                <div class="td">
                  <span class="capitalize">
                    {{ item.owner_name | owner_name }}
                  </span>
                </div>
                <div class="td">
                  <span>
                    {{ item.loader_sn }}
                  </span>
                </div>
                <div class="td">
                  <span v-if="item && item.backhoe_sn">
                    {{ item.backhoe_sn }}
                  </span>
                  <span v-else> - </span>
                </div>
                <div class="td">
                  <span>
                    {{ item.date_registered }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span class="text-red-600" v-if="errMessage">{{ errMessage }}</span>
      <!-- <div class="btn justify-end">
        <button type="button" @click="nextCreateClaim">Next</button>
      </div> -->
    </div>
  </section>
</template>

<script>
import PageHeader from "../../component/PageHeader";
import Dropdown from "../../component/Dropdown";
import ClickOutside from "vue-click-outside";
import DataTable from "../../component/DataTable";
import { mapActions, mapState } from "vuex";
import { http } from "../../../api";

export default {
  name: "CreateClaim",
  data() {
    return {
      statusName: "All",
      header: [
        { name: "Primary SN" },
        { name: "Owner" },
        { name: "Loader SN" },
        { name: "Backhoe SN" },
        { name: "Date Registered" },
      ],
      errMessage: "",
      filterSnData: {},
      tractorStatus: [],
      searchValue: "",
      selectedSn: "",
    };
  },
  components: {
    PageHeader,
    DataTable,
    Dropdown,
  },
  computed: {
    ...mapState({
      claimCreate: (state) => state.claim.claimCreate,
      tractorSn: (state) => state.claim.tractorSn,
      claimFilters: (state) => state.claim.indexFilters,
    }),
  },
  destroyed() {
    this.getTractorSn("")
      .then((res) => {})
      .catch((err) => {});
  },
  mounted() {
    this.createClaimStep_1();
  },
  methods: {
    ...mapActions({
      getTractorSn: "claim/getTractorSn",
    }),
    getDotColor(status) {
      switch (status) {
        case 1:
          return "#A7CBE5";
        case 2:
          return "#EA9797";
        case 3:
          return "#B1D69F";
        case 5:
          return "#EBDBB0";
        case 6:
          return "#606060";
      }
    },
    selectTractor(item) {
      this.selectedSn = item.primary_sn;
      this.errMessage = "";
      this.nextCreateClaim();
    },
    nextCreateClaim() {
      if (this.selectedSn) {
        this.$router.push("/claims/add/step-two?primary_sn=" + this.selectedSn);
      } else {
        this.errMessage = "Please select Registered SN";
      }
    },
    filterTractor() {
      this.filterSnData.primary_sn = this.searchValue;
      this.getTractorSn(this.filterSnData)
        .then((res) => {})
        .catch((err) => {});
    },
    createClaimStep_1() {
      http.get("claims/create/first-step").then((res) => {
        this.tractorStatus = res.data.data.statuses;
      });
    },
    // filterStatus(claimTractor) {
    //   this.statusName = claimTractor.description;
    //   if (claimTractor.description == "All") {
    //     claimTractor.id = "";
    //   }
    //   this.filterSnData.status = claimTractor.id;
    //   this.getTractorSn(this.filterSnData)
    //     .then((res) => {})
    //     .catch((err) => {});
    // },
  },
  filters: {
    owner_name: function (value) {
      if (!value) return "";
      let text = "";
      value.split(" ").map((item) => {
        text += " " + item;
      });
      return text.toLowerCase();
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style scoped lang="scss">
.table-block .tables .table .tbody .no-result {
  height: 280px;
}

.table-block .tbody {
  max-height: 600px;
  overflow-x: clip;
  overflow-y: auto;

  .tr:hover {
    background-color: #e5e5e5;
  }
}
</style>

