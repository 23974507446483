<template>
  <label class="flex items-center" v-bind:class="{ disabled: disabled }">
    <div class="mr-2">
      <input
        type="radio"
        :name="name"
        :value="value"
        :selected="isSelected"
        :disabled="disabled"
        v-bind:class="{ selected: isSelected }"
        @change="$emit('update:model', value)"
      />
      <span></span>
    </div>

    <div :class="labelClass">
      {{ label }}
    </div>
  </label>
</template>

<script>
export default {
  name: "RadioInput",
  data() {
    return {};
  },
  props: {
    model: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    labelClass: {
      type: String,
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    isSelected() {
      return this.value == this.model;
    },
  },
};
</script>

<style lang="scss" scoped>
$radioSize: 16px;

label {
  cursor: pointer;

  &.disabled {
    opacity: 0.8;
    cursor: default;
  }
}

input[type="radio"] {
  display: none;

  + span {
    position: relative;
    display: block;
    width: $radioSize;
    height: $radioSize;
    border-radius: 50%;
    border: 1px solid #5f5f5f;
  }

  &:disabled + span {
    opacity: 0.7;
  }

  &.selected + span::after {
    content: "";
    position: absolute;
    display: block;
    width: $radioSize - 6px;
    height: $radioSize - 6px;
    border-radius: 50%;
    background-color: #5f5f5f;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
