<template>
  <div class="c-pageHeader">
    <a v-if="backLink" @click="goBack" class="back">&larr; {{ backText }}</a>
    <div v-else class="pb-8"></div>
    <div
      class="flex justify-between"
      :class="
        details
          ? 'flex-col tablet:flex-row items-start tablet:items-center'
          : 'flex-wrap items-center'
      "
    >
      <div
        v-if="details"
        class="c-pageHeader__details self-stretch md:self-auto"
      >
        <div class="flex flex-wrap items-center">
          <h1 class="title mb-2 xs:mb-0">{{ details.title }}</h1>
          <span class="hidden print:block text-sm">Status: </span>
          <div
            v-if="details.status"
            :class="'c-status c-status--' + statusClass"
          >
            {{ details.status }}
          </div>
        </div>
        <div
          class="font-medium leading-4 pt-1 print:ml-auto print:mb-2 print:pl-6"
          style="font-size: 13px"
        >
          <div v-if="details.subtitle" class="text-gray-800 pt-1">
            {{ details.subtitle }}
          </div>
          <slot name="details-subtitle"></slot>
          <div
            v-for="field in Object.keys(details.fields || {})"
            class="pt-1"
            v-bind:key="`details_field_${field}`"
          >
            {{ field }}:<span class="inline-block ml-2 text-gray-800">{{
              details.fields[field]
            }}</span>
          </div>
          <div v-if="details.date" class="text-gray-800 pt-1 c-pageHeader__date">
            {{
              details.date
                | moment(details.dateFormat || "MMMM Do, YYYY  h:mm:ss a")
            }}
          </div>
          <slot name="details-end"></slot>
        </div>
      </div>
      <h1 v-else class="title">
        {{ title }}

        <p v-if="subtitle" class="text">
          {{ subtitle }}
        </p>
      </h1>
      <div
        class="
          actions
          flex flex-col
          self-stretch
          xs:self-auto xs:flex-row xs:items-center xs:flex-wrap
        "
      >
        <slot name="actions"> </slot>
        <div class="flex items-center">
          <div v-if="discardLink" class="discard-link" @click="discardClicked">
            <a v-if="discardUrl" :href="discardUrl">{{ discardText }}</a>
            <template v-else>
              {{ discardText }}
            </template>
          </div>
          <button
            v-if="saveButton"
            @click="saveClicked"
            :readonly="buttonsDisabled"
            :disabled="!canSave"
            :class="disabledClass"
            type="button"
            class="btn btn-black px-40 print:text-black"
          >
            {{ saveText }}
          </button>
          <button
            v-if="addButton"
            @click="addClicked"
            :readonly="buttonsDisabled"
            :disabled="!canSave"
            :class="disabledClass"
            type="button"
            class="btn btn-black px-3 print:text-black"
          >
            <div class="add-icon">
              <inline-svg :src="require('@/assets/image/icons/add.svg')" />
            </div>
            {{ addText }}
          </button>
        </div>
        <slot name="actions-after"> </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageHeader",
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    subtitle: {
      type: String,
      required: false,
      default: null,
    },
    details: {
      type: Object,
      required: false,
      default: function () {
        return null;
      },
    },
    discardLink: {
      type: Boolean,
      required: false,
      default: true,
    },
    discardText: {
      type: String,
      required: false,
      default: "Discard",
    },
    discardUrl: {
      type: String,
      required: false,
      default: null,
    },
    saveButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    saveText: {
      type: String,
      required: false,
      default: "Save",
    },
    addButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    addText: {
      type: String,
      required: false,
      default: "Create",
    },
    backLink: {
      type: Boolean,
      required: false,
      default: true,
    },
    backText: {
      type: String,
      required: false,
      default: "Back",
    },
    backRoute: {
      type: [String, Object],
      required: false,
      default: "back",
    },
    buttonsDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    canSave: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      statusClasses: {
        default: "draft",
        draft: "draft",
        submitted: "draft",
        pending: "pending",
        "under review": "pending",
        "needs attention": "pending",
        reviewed: "blue",
        "credit issued": "blue",
        approved: "success",
        accepted: "success",
        processed: "success",
        rejected: "rejected",
        denied: "rejected",
        closed: "rejected",
        cancelled: "rejected",
        original: "rejected",
      },
    };
  },
  computed: {
    disabledClass: function () {
      let classList = [];

      if (this.buttonsDisabled) classList.push("cursor-wait");
      if (!this.canSave) classList.push("cursor-not-allowed");
      if (this.buttonsDisabled || !this.canSave) classList.push("opacity-50");

      return classList.join(" ");
    },

    statusClass: function () {
      if (this.details?.statusClass) return this.details.statusClass;
      if (!this.details?.status) return "";

      return (
        this.statusClasses[this.details.status?.toLowerCase()] ??
        this.statusClasses.default
      );
    },

    formattedDate: function () {
      if (!this.details?.date) return "";

      return this.details.date | moment("MMMM Do, YYYY  h:mm:ss a");
    },
  },

  created() {},

  methods: {
    goBack: function () {
      if (this.backRoute == "back") {
        this.$router.go(-1);
      } else {
        this.$router.push(this.backRoute);
      }
    },
    discardClicked: function () {
      this.$emit("discard");
    },
    saveClicked: function () {
      this.$emit("save");
    },
    addClicked: function () {
      this.$emit("add");
    },
  },
};
</script>

<style lang="scss" scoped>
.c-pageHeader {
  padding-top: 20px;
  padding-bottom: 35px;
  border-bottom: 1px solid #cdcdcd;

  // @media (max-width: 1279px) {
  //   padding-top: 52px;
  // }

  @media (max-width: 768px) {
    padding-top: 20px;
  }

  @media print {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  & &__details {
    background: #ececec;
    border-radius: 6px;
    padding: 16px 25px 19px;
    flex-shrink: 1;

    @media (min-width: 901px) {
      margin-right: 20px;
    }

    @media print {
      display: flex;
      align-items: center;
      padding: 15px 0px;
    }
  }

  & &__date {
    @media print {
      margin-left: auto;
    }
  }
}

.title {
  font-weight: $font-weight-medium;
  font-size: 34px;
  line-height: 1.25;
  padding-right: 20px;

  flex-shrink: 1;

  @media (max-width: 768px) {
    font-size: 24px;
  }
}

.back {
  display: inline-block;
  margin-bottom: 16px;
  font-weight: $font-weight-medium;
  font-size: 13px;
  line-height: 16px;
  color: #565656;

  @media (max-width: 1279px) {
    padding-left: calc(60px - 3.5vw);
  }

  @media (max-width: 768px) {
    padding-left: 0px;
  }

  @media (max-width: 468px) {
    display: none;
  }

  @media print {
    display: none;
  }
}

.flex {
  margin: -10px -5px;

  > * {
    margin: 10px 5px;

    &.flex {
      margin: 0;
    }
  }
  // }
}

.actions {
  flex-shrink: 3;
}

.discard-link {
  font-weight: $font-weight-medium;
  font-size: 13px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #383838;
  cursor: pointer;
  margin-right: 25px;
}

.add-icon {
  background-color: #d40404;
  border-radius: 6px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.c-status {
  min-width: 80px;
}
</style>
