<template>
    <inline-svg :class="classes" :src="require('@/assets/image/icons/loading.svg')"/>
</template>

<script>
    export default {
        name: "loading",
        props: {
            position: {
                required: false,
                type: String,
                default: 'left',
            }
        },
        computed: {
            classes() {
                const classes = ['m-auto','animate-spin', 'text-white', 'w-5', 'h-5'];
                if (this.position == 'left') {
                    classes.push('-ml-1', 'mr-3');
                } else if (this.position == 'right') {
                    classes.push('-mr-1');
                }

                return classes.join(' ');
            }
        }
    }
</script>