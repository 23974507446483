<template>
  <section class="add-user col-span-12">
    <ValidationObserver tag="div" class="container" ref="form">
      <PageHeader
        title="Add Category"
        backText="Back to Manage Categories"
        :backRoute="{ name: 'ManageCategories' }"
        saveText="Save Category"
        @save="addMedia"
        @discard="showDiscardModal = true"
        :buttonsDisabled="buttonDisabled"
      />
      <div class="user-details">
        <div class="subtitle">Enter Category Details</div>
        <div class="user-block">
          <div class="user-block-container">
            <div class="form-group w-full">
              <label class="control-label">Title</label>
              <ValidationProvider
                rules="required"
                tag="div"
                v-slot="{ classes }"
              >
                <input
                  :class="(errors ? 'error' : '', classes)"
                  type="text"
                  @input="errors = false"
                  name="name"
                  v-model="formData.media_type"
                  placeholder="Category Title Here"
                />
              </ValidationProvider>
            </div>
            <div class="user-type">
              <dropdown
                :data="types"
                :status="false"
                fieldName="name"
                label="Media Type"
                @select="selectMediaType"
                :validation="true"
                inputName="media_type"
                :editValue="selectedMediaType"
                :all="false"
                placeholder="Manuals"
              ></dropdown>
            </div>
            <div class="form-group w-full">
              <label class="control-label">Description</label>
              <ValidationProvider
                rules="required"
                tag="div"
                v-slot="{ classes }"
              >
                <input
                  :class="classes"
                  type="text"
                  name="name"
                  v-model="formData.description"
                  placeholder="Description Goes Here"
                />
              </ValidationProvider>
            </div>
          </div>
        </div>
        <div class="send">
          <div class="edit-link" @click="showDiscardModal = true">Discard</div>
          <button
            class="btn btn-black px-60"
            type="button"
            @click="addMedia"
            :disabled="buttonDisabled"
            :class="buttonDisabled ? 'opacity-50 cursor-wait' : ''"
          >
            Save Category
          </button>
        </div>
      </div>
      <ConfirmModal
        text="Discard changes?"
        confirmBtn="Discard"
        :show="showDiscardModal"
        @confirm="$router.push('/admin/media/categories')"
        @close="showDiscardModal = false"
      />
    </ValidationObserver>
  </section>
</template>

<script>
import PageHeader from "../../../component/PageHeader";
import Dropdown from "../../../component/Dropdown";
import ConfirmModal from "../../../component/ConfirmModal";
import { mapActions, mapState } from "vuex";

export default {
  name: "addCategories",
  data() {
    return {
      selectedMediaType: "",
      errors: false,
      showDiscardModal: false,
      formData: {
        media_type: "",
        description: "",
        type_id: "",
      },
      buttonDisabled: false,
      isEdit: false,
    };
  },
  computed: {
    ...mapState({
      createdCategory: (state) => state.mediaCategory.createdCategory,
      types: (state) => state.mediaCategory.types,
    }),
  },
  created() {
    if (this.$route.params.id) {
      this.isEdit = true;
      this.editCategory();
    } else {
      this.isEdit = false;
      this.createCategoryData();
    }
  },
  components: {
    PageHeader,
    Dropdown,
    ConfirmModal,
  },
  methods: {
    ...mapActions({
      createCategory: "mediaCategory/createCategory",
      editCategoryData: "mediaCategory/editCategoryData",
      updateCategory: "mediaCategory/updateCategory",
      createCategoryData: "mediaCategory/createCategoryData",
    }),
    editCategory() {
      this.editCategoryData({ id: this.$route.params.id }).then((res) => {
        this.formData.media_type = this.createdCategory.title;
        this.formData.description = this.createdCategory.description;
        this.formData.type_id = this.createdCategory.media_type.id;
        this.selectedMediaType = this.createdCategory.media_type.name;
      });
    },
    addMedia() {
      this.$refs.form
        .validate()
        .then((success) => {
          if (success) {
            this.buttonDisabled = true;

            if (this.isEdit) {
              this.updateCategory({
                id: this.$route.params.id,
                formData: this.formData,
              })
                .then((res) => {
                  this.$router.push("/admin/media/categories");
                })
                .catch((err) => {
                  this.buttonDisabled = false;
                  this.$toasted.error(
                    err.response.data.message || "Server Error"
                  );
                });
            } else {
              this.createCategory(this.formData)
                .then((res) => {
                  this.$router.push("/admin/media/categories");
                })
                .catch((err) => {
                  this.buttonDisabled = false;
                  if (err.response.data.errors.media_type) {
                    this.errors = true;
                    this.$toasted.error("The Title has already been taken.");
                  }
                  this.$toasted.error(
                    err.response.data.message || "Server Error"
                  );
                });
            }
          } else {
            this.$toasted.error("Your submission contains errors");
          }
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    },
    selectMediaType(type) {
      this.selectedMediaType = type.name;
      this.formData.type_id = type.id;
    },
  },
};
</script>

<style scoped>
.error {
  border: 2px solid #e3342f !important;
}
</style>
