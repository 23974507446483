<template>

    <section class="add-edit-page edit-email-lists col-span-12">
        <div class="container">
            <ValidationObserver
                @submit.prevent="saveLists"
                tag="form"
                class=""
                ref="form"
            >
                <PageHeader
                    title="Manage Email Lists"
                    backText="Back to Admin Settings"
                    :backRoute="backRoute"
                    saveText="Save Email Lists"
                    @save="saveLists"
                    :canSave="didChange"
                    @discard="showDiscardModal = true"
                    :buttonsDisabled="buttonDisabled"
                />

                <div class="edit-lists-section edit-details">

                    <div
                        class="email-list"
                        v-for="list in emailListTypes"
                        v-bind:key="`email_list_${list.key}`"
                    >
                        <h2 class="title">{{list.name}}</h2>
                        <template v-if="emailLists[list.key]" >
                            <template
                                class="email-list-row"
                                v-for="line, index in emailLists[list.key]"
                            >
                                <div
                                    :key="`email_list_${list.key}_email_${index}`"
                                    class="email-list-row"
                                    v-if="!line.remove"
                                >
                                    
                                    <ValidationProvider
                                        rules="email|required"
                                        tag="div"
                                        v-slot="{ classes }"
                                    >
                                        <input
                                            :class="classes"
                                            type="text"
                                            v-model="line.new"
                                            placeholder=""
                                        />
                                    </ValidationProvider>
                                    <button
                                        type="button"
                                        role="button"
                                        class="c-removeButton"
                                        @click.prevent="$event.target.blur(); removeEmail(line)"
                                    ></button>
                                </div>
                            </template>
                        </template>
                        <button
                            type="button"
                            class="add-email-button btn btn-info btn--outline px-12 mb-4"
                            @click="addEmail(list.key)"
                        >
                            + Add Email
                        </button>
                    </div>


                    <div class="send">
                        <div class="edit-link" @click="showDiscardModal = true">Discard</div>
                        <button
                            class="btn btn-black px-60"
                            :disabled="buttonDisabled || !didChange"
                            v-bind:class="{
                                'opacity-50' : buttonDisabled || !didChange,
                                'cursor-not-allowed' : !didChange,
                                'cursor-wait' : buttonDisabled,
                            }"
                            type="submit"
                        >
                            Submit
                        </button>
                    </div>
                </div>



                <ConfirmModal
                    text="Discard changes?"
                    confirmBtn="Discard"
                    :show="showDiscardModal"
                    @confirm="$router.push(backRoute)"
                    @close="showDiscardModal = false"
                />
            </ValidationObserver>
        </div>
    </section>
</template>
  
<script>
import PageHeader from "../../../component/PageHeader";
import ConfirmModal from "../../../component/ConfirmModal";
import { mapActions, mapState } from "vuex";

export default {
    data() {
        return {
            showDiscardModal: false,
            backRoute: {
                name: 'AdminSettings'
            },

            buttonDisabled: false,
            emailListTypes: [
                {name: "New Order", key: "order"},
                {name: "New Dealership", key: "dealership"},
                {name: "New User", key: "user"},
            ],
            emailLists: {
                order: [],
                dealership: [],
                user: [],
            },
            emailsToRemove: [],
        }
    }, 
    name: "ManageEmailLists",
    components: {
        PageHeader,
        ConfirmModal,
    },
    computed: {
        ...mapState({
            user: (state) => state.auth.user,
            listEntries: (state) => state.emailLists.lists,
        }),
        didChange() {
            const result = Object.keys(this.emailLists).reduce((changed, key) => {
                const listResult = this.emailLists[key].reduce((listChanged, line) => {
                    return listChanged || line.remove || line.new != line.original;
                }, false);
                return changed || listResult;
            }, false);
            return result;
        },
        // emailLists() {
        //     let lists = {};

        //     this.listEntries.forEach(line => {
        //         lists[line.email_type] = [
        //             ...(lists[line.email_type] ?? []),
        //             line.user.email
        //         ];
        //     });

        //     console.log(lists);

        //     return lists;
        // }
    },
    async created() {
        await this.getEmailLists();

        this.listEntries.forEach(line => {
            this.emailLists[line.email_type] = [
                ...(this.emailLists[line.email_type] ?? []),
                {
                    id: line.id,
                    original: line.email,
                    new: line.email,
                    remove: false,
                }
            ];
        });

        Object.keys(this.emailLists).forEach(key => {
            this.emailLists[key].sort((a, b) => a.original.toLowerCase() > b.original.toLowerCase() ? 1:-1);
        })
    },
    methods: {
        ...mapActions({
            getEmailLists: "emailLists/getEmailLists",
            updateEmailLists: "emailLists/updateEmailLists",
        }),
        removeEmail(line) {
            line.remove = true;
        },
        addEmail(key) {
            if (!this.emailLists[key]) return;

            this.emailLists[key].push({
                id: null,
                original: "",
                new: "",
                remove: false,
            })
        },
        async saveLists() {
            try {
                const valid = await this.$refs.form.validate();
                if (valid) {
                    await this.updateEmailLists({
                        email_lists: this.emailLists
                    });
                    this.$router.push(this.backRoute, () => {
                        setTimeout(() => {
                            this.$toasted.success("Email lists have been updated");
                        }, 200);
                    })
                } else {
                    this.$toasted.error("Your submission contains errors. Please make sure all entries are valid email addresses.");
                }
            } catch(err) {
                this.$toasted.error(err.response?.data?.message || err || "Server Error");
            }
        },
    },
};
</script>

<style scoped lang="scss">
    // .edit-email-lists {

    .edit-lists-section {
        padding: 35px 0px;
    }

    .email-list {
        max-width: 600px;
        display: flex;
        flex-direction: column;

        &:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    .email-list-row {
        position: relative;

        &:not(:last-child) {
            margin-bottom: 10px;
        }

        .c-removeButton {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            opacity: 0;
            transition: opacity 0.1s;
        }

        &:hover .c-removeButton,
        input:focus + .c-removeButton,
        .c-removeButton:focus {
            opacity: 1;
        }

        input {
            width: 100%;
            height: 38px;
            font-size: 14px;
            padding-left: 10px;
            margin-right: 36px;
            border: 1px solid $gray-800;
            border-radius: 5px;

            &::placeholder {
                font-size: 12px;
            }

            &.error {
                border: 1px solid #c53030;
            }
        }

    }
    .title {
        font-weight: $font-weight-medium;
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 14px;
    }

    .add-email-button {
        &:focus {
            outline: 1px solid blue !important;
        }
    }
    // }
</style>
  