import { http } from "../../../api";

const programPreapproval = {
    namespaced: true,
    state: {
        preapprovals: {
            data: [],
            pagination: {},
        },
        preapprovalDetails: {

        },
        statuses: [],
        dealerships: [],
        programs: [],
    },
    actions: {
        getPreapprovals: ({commit}, params) => {
            return http.get('retail-programs/preapprovals', {
                params,
            }).then(res => {
                commit('setPreapprovals', res.data.data.preapprovals);
                commit('setDealerships', res.data.data.dealerships);
                commit('setStatuses', res.data.data.statuses);
            })
        },
        getPreapprovalCreate: ({commit}, id) => {
            return http.get(`retail-programs/preapprovals/create`).then(res => {
                commit('setDealerships', res.data.data.dealerships);
                commit('setPrograms', res.data.data.programs);
            })
        },
        getPreapprovalEdit: ({commit}, id) => {
            return http.get(`retail-programs/preapprovals/${id}/edit`).then(res => {
                commit('setPreapprovalDetails', res.data.data.preapproval_details);
                commit('setDealerships', res.data.data.dealerships);
                commit('setPrograms', res.data.data.programs);
            })
        },
        createPreapproval: ({commit}, params) => {
            return http.post(`retail-programs/preapprovals`, {...params}).then(res => {
                return res;
            })
        },
        updatePreapproval: ({commit}, params) => {
            return http.put(`retail-programs/preapprovals/${params.id}`, {...params.data}).then(res => {
                return res;
            })
        },
        deletePreapproval: ({commit}, params) => {
            return http.delete(`retail-programs/preapprovals/${params.id}`).then(
                res => {
                    return res;
                }
            )
        }
    },
    mutations: {
        setPreapprovals(state, data) {
            state.preapprovals = {
                data: data?.data,
                pagination: data?.pagination,
            };
        },
        setPreapprovalDetails(state, data) {
            state.preapprovalDetails = data;
        },
        setDealerships(state, data) {
            state.dealerships = data;
        },
        setStatuses(state, data) {
            state.statuses = data;
        },
        setPrograms(state, data) {
            state.programs = data;
        },
    },
    getters: {
        preapprovals: state => state.preapprovals,
        preapprovalDetails: state => state.preapprovalDetails,
        dealerships: state => state.dealerships,
        programs: state => state.programs,
        statuses: state => state.statuses,
    }
}

export default programPreapproval;