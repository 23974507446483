<template>
  <div class="dropdown-settings relative">
    <button
      aria-label="actions-menu"
      type="button"
      class="button btn-td-hover"
      :class="openClose ? 'active' : ''"
      v-click-outside="hide"
      @click="openClose = !openClose"
    >
      <inline-svg
        class="m-auto"
        :src="require('@/assets/image/icons/more-alt.svg')"
      />
    </button>
    <div class="dropdown" v-if="openClose">
      <router-link tag="div" :to="{ name: 'dealerDetail', params: { id: item.item.id}}"
        >View</router-link
      >
      <router-link tag="div" :to="{ name: 'editDealer', params: { id: item.item.id}}"
        >Edit</router-link
      >
      <div class="delete" @click="openConfirmModal">
        <span class="text-green-700" v-if="item.item.status === 'Inactive'"
          >Activate</span
        >
        <span v-else>Deactivate</span>
      </div>
    </div>
    <ConfirmModal
      :text="`Are you sure you want to ${
        item.item.status === 'Inactive' ? 'Activate' : 'Deactivate'
      } this Dealer?`"
      :confirmBtn="`${
        item.item.status === 'Inactive' ? 'Activate' : 'Deactivate'
      } Dealer`"
      :show="showDeleteDealerModal"
      @confirm="deleteDealer"
      @close="closeConfirmModal"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ConfirmModal from "../ConfirmModal";

export default {
  name: "DealersTableDropdown",
  props: ["item"],
  data() {
    return {
      openClose: false,
      showDeleteDealerModal: false,
    };
  },
  components: {
    ConfirmModal,
  },
  methods: {
    ...mapActions({
      removeDealer: "dealers/removeDealer",
      getDealers: "dealers/getDealers",
      filterDealers: "dealers/filterDealers",
    }),
    openConfirmModal() {
      this.showDeleteDealerModal = true;
    },
    deleteDealer() {
      this.removeDealer({ id: this.item.item.id })
        .then((res) => {
          if (this.$route.query.page) {
            let form = {};
            if (this.$route.query.name) {
              form.name = this.$route.query.name;
            }
            if (this.$route.query.tm_id) {
              form.tm_id = this.$route.query.tm_id;
            }
            form.page = +this.$route.query.page;
            this.filterDealers(form);
          } else {
            this.filterDealers();
          }
          this.$toasted.success(res.data.message);
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        })
        .finally(() => {
          this.closeConfirmModal();
        });
    },
    closeConfirmModal() {
      this.showDeleteDealerModal = false;
    },
    hide() {
      this.openClose = false;
    },
  },
};
</script>
