<template>
  <div class="c-dropdown-container">
    <div class="c-dropdown" v-bind:class="{ isOpen: 'active' }">
      <span class="c-dropdown-title">{{ title }}</span>
      <button
        v-if="showHideButton"
        class="c-dropdown-showHideButton"
        type="button"
        @click="toggle"
      >
        {{ isOpen ? "Hide" : "Show" }}
      </button>
    </div>
    <div class="dropdown-section" v-show="isOpen">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShowHideSection",
  props: {
    title: {
      type: String,
      required: false,
    },
    showHideButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    startOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isOpen: this.startOpen || !this.showHideButton,
    };
  },
  mounted() {},
  watch: {},
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
.c-dropdown {
  width: 100%;
  height: 58px;
  background: #e9e9e9;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.c-dropdown.active {
  border-radius: 5px 5px 0 0;
}

.c-dropdown-title {
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  margin-left: 20px;
}

.c-dropdown-showHideButton {
  width: 50px;
  height: 26px;
  background: #000000;
  border-radius: 6px;
  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  margin-right: 20px;
  outline: none;
}
.dropdown-section {
  width: 100%;
  padding-bottom: 20px;
  background: #ffffff;
  border: 1px solid #dedede;
  border-top: none;
  box-sizing: border-box;
  border-radius: 0 0 6px 6px;
  height: auto;
}
</style>
