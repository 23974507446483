import { http } from '../../../api';

const media = {
    namespaced: true,
    state: {
        media: [],
        types: [],
        createdMedia: {},
        categories: [],
        media_item: {},
        pagination: {},
        initialPage: 1,

        indexFilters: {},
    },
    actions: {
        getMedia: ({ commit }, params) => {
            const q = new URLSearchParams(params);
            return http.get('/media?' + q.toString()).then(res => {
                commit('setMediaData', res.data.data)
            });
        },
        filterMedia: ({ commit }, params) => {
            const q = new URLSearchParams(params);
            return http.get('media/filter?' + q.toString()).then(res => {
                commit('setMedia', res.data.data)
            });
        },
        removeMedia: ({ commit }, params) => {
            return http.delete('/media/' + params.id).then(res => {
                commit('deleteMedia', params.id)
                return res;
            });
        },
        getMediaItem: ({ commit }, params) => {
            return http.get('/media/' + params.id).then(res => {
                commit('setMediaItem', res.data.data);
                return res.data.data;
            });
        },
        createMedia: ({ commit }, params) => {
            return http.post('/media', params).then(res => {
                return res;
            });
        },
        createMediaData: ({ commit }, params) => {
            return http.get('/media/create', params).then(res => {
                commit('setCreateMedia', res.data.data)
            });
        },
        editMediaData: ({ commit }, params) => {
            return http.get('/media/' + params.id + '/edit').then(res => {
                commit('setCreateMedia', res.data.data)
            });
        },
        updateMedia: ({ commit }, params) => {
            return http.post('/media/' + params.id, params.formData).then(res => {
                return res;
            });
        },
        getInitialPage: ({ commit }, params) => {
            commit('setInitialPage', params)
        },

        setIndexFilters: ({commit}, params) => {
            commit('setIndexFilters', {...params});
        }
    },
    mutations: {
        setInitialPage(state, page) {
            state.initialPage = page
        },
        deleteMedia(state, id) {
            state.media = state.media.filter(item => id !== item.id)
        },
        setCreateMedia(state, data) {
            state.createdMedia = data
        },
        setMediaData(state, data){
            state.media = data.media.data;
            state.pagination = data.media.pagination;
            state.types = data.types;
            state.categories = data.categories;
        },
        setMedia(state, data){
            state.media = data.media.data;
            state.pagination = data.media.pagination;
        },
        setMediaItem(state, data){
            state.media_item = data.media;
            state.types = data.types;
            state.categories = data.categories;
            state.pagination = data.pagination;
        },
        setIndexFilters(state, data) {
            state.indexFilters = data;
        }
    },
    getters: {
        media: state => state.media,
        types: state => state.types,
        categories: state => state.categories,
        pagination: state => state.pagination,
        media_item: state => state.media_item,
        createdMedia: state => state.createdMedia,
        initialPage: state => state.initialPage,
        indexFilters: state => state.indexFilters,
    }
};

export default media;
