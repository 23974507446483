import { http } from '../../../api';

const dealers = {
    namespaced: true,
    state: {
        dealers: [],
        territoryManager: [],
        dealerItem: {},
        pagination: {},
        createdDealer: {},
        initialPage: 1
    },
    actions: {
        getDealers: ({ commit }, params) => {
            const q = new URLSearchParams(params);
            return http.get('/dealers?' + q.toString()).then(res => {
                commit('setDealersData', res.data.data)
            });
        },
        filterDealers: ({ commit }, params) => {
            const q = new URLSearchParams(params);
            return http.get('dealers/filter?' + q.toString()).then(res => {
                commit('setDealers', res.data.data)
            });
        },
        createDealerData: ({ commit }, params) => {
            return http.get('/dealers/create', params).then(res => {
                commit('setCreateDealer', res.data.data)
            });
        },
        getDealerItem: ({ commit }, params) => {
            return http.get('/dealers/' + params.id).then(res => {
                commit('setDealerItem', res.data.data);
                return res.data.data;
            });
        },
        createDealer: ({ commit }, params) => {
            return http.post('/dealers', params).then(res => {
                return res;
            });
        },
        editDealerData: ({ commit }, params) => {
            return http.get('/dealers/' + params.id + '/edit').then(res => {
                commit('setCreateDealer', res.data.data)
            });
        },
        updateDealer: ({ commit }, params) => {
            return http.post('/dealers/' + params.id, params.data).then(res => {
                return res;
            });
        },
        removeDealer: ({ commit }, params) => {
            return http.delete('/dealers/' + params.id).then(res => {
                commit('deleteDealer', params.id)
                return res;
            });
        },
        getInitialPage: ({ commit }, params) => {
            commit('setInitialPage', params)
        },
    },
    mutations: {
        setInitialPage(state, page) {
            state.initialPage = page
        },
        setCreateDealer(state, data) {
            state.createdDealer = data
        },
        deleteDealer(state, id) {
            state.dealers = state.dealers.filter(item => id !== item.id)
        },
        setDealersData(state, data){
            state.dealers = data.dealers.data;
            state.pagination = data.dealers.pagination;
            state.territoryManager = data.territory_managers;
        },
        setDealers(state, data){
            state.dealers = data.dealers.data;
            state.pagination = data.dealers.pagination;
        },
        setDealerItem(state, data){
            state.dealerItem = data.dealership;
        },
    },
    getters: {
        dealers: state => state.dealers,
        dealerItem: state => state.dealerItem,
        pagination: state => state.pagination,
        territoryManager: state => state.territoryManager,
        createdDealer: state => state.createdDealer,
        initialPage: state => state.initialPage,
    }
};

export default dealers;
