<template>
  <section class="settings col-span-12">
    <div class="container">
      <PageHeader
        title="Settings"
        :saveButton="false"
        :discardLink="false"
        :backLink="false"
      />
      <div class="settings-block">
        <div class="settings-container">
          <router-link
            to="/admin/users"
            tag="div"
            class="setting-card cursor-pointer"
            v-if="userHasPermission('ADMIN_USERS')"
          >
            <div class="avatar">
              <inline-svg :src="require('@/assets/image/icons/user.svg')" />
            </div>
            <div class="description">
              <div>Manage Users</div>
              <span>Add, Find, and Manage Users and Permissions</span>
            </div>
          </router-link>
          <router-link
            to="/admin/dealers"
            tag="div"
            class="setting-card cursor-pointer"
            v-if="userHasPermission('ADMIN_DEALERS')"
          >
            <div class="avatar">
              <inline-svg :src="require('@/assets/image/icons/dealer.svg')" />
            </div>
            <div class="description">
              <div>Manage Dealers</div>
              <span>Add, Find, and Manage Dealers</span>
            </div>
          </router-link>
          <router-link
            class="setting-card cursor-pointer"
            to="/admin/tractors"
            tag="div"
            v-if="userHasPermission('ADMIN_PROD_CAT')"
          >
            <div class="avatar">
              <inline-svg :src="require('@/assets/image/icons/tractor.svg')" />
            </div>
            <div class="description">
              <div>Manage Tractors</div>
              <span>Add, Find, and Manage Users and Permissions</span>
            </div>
          </router-link>
          <router-link
            tag="div"
            to="/admin/media"
            class="setting-card cursor-pointer"
            v-if="userHasPermission('ADMIN_MEDIA')"
          >
            <div class="avatar">
              <inline-svg :src="require('@/assets/image/icons/media.svg')" />
            </div>
            <div class="description">
              <div>Manage Media</div>
              <span>Add, View, and Manage Media</span>
            </div>
          </router-link>
          <router-link
            tag="div"
            class="setting-card cursor-pointer"
            to="/admin/news"
            v-if="userHasPermission('ADMIN_NEWS')"
          >
            <div class="avatar">
              <inline-svg :src="require('@/assets/image/icons/news.svg')" />
            </div>
            <div class="description">
              <div>Manage News</div>
              <span>Add, Edit, and Update TYM News Articles</span>
            </div>
          </router-link>
          <router-link
            tag="div"
            class="setting-card cursor-pointer"
            to="/admin/videos"
            v-if="userHasPermission('ADMIN_MEDIA')"
          >
            <div class="avatar">
              <inline-svg :src="require('@/assets/image/icons/media.svg')" />
            </div>
            <div class="description">
              <div>Manage Videos</div>
              <span>Add, View, and Manage Media</span>
            </div>
          </router-link>
          <router-link
            tag="div"
            class="setting-card cursor-pointer"
            to="/admin/old-warranty-claims"
            v-if="userHasPermission('ADMIN_WARR_INFO_VIEW')"
          >
            <div class="avatar">
              <inline-svg
                :src="require('@/assets/image/icons/old-claim.svg')"
              />
            </div>
            <div class="description">
              <div>Old Warranty Claims</div>
              <span>Search For and Find Old Warranty Claims</span>
            </div>
          </router-link>
          <router-link
            tag="div"
            class="setting-card cursor-pointer"
            to="/admin/search-labor"
            v-if="userHasPermission('ADMIN_WARR_INFO_VIEW')"
          >
            <div class="avatar">
              <inline-svg :src="require('@/assets/image/icons/labor.svg')" />
            </div>
            <div class="description">
              <div>Labor Codes Lookup</div>
              <span>Search For Labor Codes</span>
            </div>
          </router-link>
          <router-link
            tag="div"
            class="setting-card cursor-pointer"
            to="/admin/retail-programs"
            v-if="userHasPermission('ADMIN_PROGRAMS')"
          >
            <div class="avatar">
              <inline-svg :src="require('@/assets/image/icons/claim.svg')" />
            </div>
            <div class="description">
              <div>Manage Programs</div>
              <span>Edit bonus and rebate amounts</span>
            </div>
          </router-link>
          <router-link
            tag="div"
            class="setting-card cursor-pointer"
            to="/admin/model-info"
            v-if="userHasPermission('ADMIN_MODEL_INFO')"
          >
            <div class="avatar">
              <inline-svg :src="require('@/assets/image/icons/labor.svg')" />
            </div>
            <div class="description">
              <div>Model Info Lookup</div>
              <span>Search For Model Info</span>
            </div>
          </router-link>
          <router-link
            tag="div"
            class="setting-card cursor-pointer"
            to="/admin/email-lists"
            v-if="userHasPermission('GLOBAL')"
          >
            <div class="avatar">
              <inline-svg :src="require('@/assets/image/icons/email-dark.svg')" />
            </div>
            <div class="description">
              <div>Manage Email Lists</div>
              <span>Edit email lists for new users, dealers, and orders</span>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../component/PageHeader";
import { mapState } from "vuex";

export default {
  name: "AdminSettings",
  components: {
    PageHeader,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    userHasPermission(perm) {
      return (
        this.user.permissions.includes("GLOBAL") ||
        this.user.permissions.includes(perm)
      );
    },
  },
};
</script>

<style scoped>
</style>
