var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tables"},[_c('div',{staticClass:"table"},[_c('div',{staticClass:"data-table-head"},[_c('div',{staticClass:"table-head-container"},[_c('div',{staticClass:"tr"},_vm._l((_vm.header),function(item,index){return (_vm.user && _vm.user.role)?_c('div',{key:index,staticClass:"th text-center",class:{
              'td-admin': _vm.user.role.id === 3,
              'sort-arrow': _vm.sortArrow,
              'sorted-column': _vm.tableSortField.name == item.field,
              'sorted-reverse':
                _vm.tableSortField.name == item.field && _vm.tableSortField.reverse,
            },style:(("width: " + (item.headerWidth) + ";margin-left:" + (item.headerMl) + ";max-width:" + (item.maxWidth))),on:{"click":function($event){_vm.sortArrow && _vm.sortField(item.field)}}},[_vm._v(" "+_vm._s(item.name)),(_vm.sortArrow)?_c('inline-svg',{staticStyle:{"flex":"0 0 auto"},attrs:{"src":require('@/assets/image/icons/sort-arrows.svg')}}):_vm._e()],1):_vm._e()}),0)])]),_c('div',{staticClass:"tbody",class:_vm.scroll ? 'table-scroll' : ''},[(_vm.isLoading)?_c('div',{staticClass:"text-center no-result"},[_c('loading')],1):_c('div',[(_vm.data && _vm.data.length === 0)?_c('div',{staticClass:"text-center no-result"},[_c('span',[_vm._v("No results found. Please adjust your search criteria.")])]):_vm._e(),_vm._l((_vm.data),function(item,i){return _c('div',{key:i,staticClass:"tr"},_vm._l((_vm.header),function(headerItem,h){return _c('div',{key:("header_item_" + h),staticClass:"td",class:headerItem.class,style:(("width: " + (headerItem.width) + ";margin-left:" + (headerItem.ml) + ";max-width:" + (headerItem.maxWidth)))},[(headerItem.showIndex)?_c('span',[_vm._v(" "+_vm._s(++i)+" ")]):_vm._e(),(headerItem.transformToComponent)?_c('span',{staticClass:"text-center"},[_c(headerItem.transformToComponent,{tag:"component",attrs:{"warranty":{ page: _vm.page, item: item },"item":{ item: item, page: _vm.page },"order":{ item: item, page: _vm.page },"link":_vm.link,"linkItem":item}})],1):(headerItem.transform)?_c('span',{staticClass:"text-center",domProps:{"innerHTML":_vm._s(
                headerItem.transform(
                  _vm.transformDataStructure(item, headerItem.transformField),
                  _vm.transformDataStructure(item, headerItem.field)
                )
              )}}):_c('span',{staticClass:"text-center"},[(headerItem.field == 'turnRatio')?[_vm._v(" "+_vm._s(_vm.transformDataStructure(item, headerItem.field).toFixed(2))+" ")]:[_vm._v(" "+_vm._s(_vm.transformDataStructure(item, headerItem.field))+" ")]],2)])}),0)})],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }