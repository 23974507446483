<template>
  <section class="manage col-span-12">
    <div class="container grid grid-cols-1 gap-0">
      <PageHeader
        title="Model Info Lookup"
        :saveButton="false"
        :discardLink="false"
        backText="Back to Settings"
        :backRoute="{ name: 'AdminSettings' }"
        :addButton="userCanCreate"
        addText="Add Model Info"
        @add="$router.push({ name: 'modelInfoAdd' })"
      />
      <div class="filter-block">
        <div class="flex">
          <div class="table-filter-search-input">
            <div class="form-group">
              <label class="control-label">Search</label>
              <input
                type="text"
                name="search"
                v-model="filterData.model"
                v-on:keyup.enter="applyFilter"
                class="search"
                placeholder="Model Number"
              />
              <inline-svg
                class="search-icon"
                :src="require('@/assets/image/icons/search.svg')"
              />
            </div>
          </div>
          <div class="table-filter-model-type-select">
              <dropdown
              :data="modelTypes"
              fieldName="type"
              label="Model Type"
              @select="selectModelType"
              inputName="model_type"
              :editValue="filterData.model_type"
              ></dropdown>
          </div>
          <div class="table-filter-status-select">
            <dropdown
              :data="statuses"
              :status="true"
              :statusColors="statusColors"
              fieldName="id"
              label="Status"
              @select="selectStatus"
              :validation="false"
              inputName="status"
              :editValue="filterData.status"
            ></dropdown>
          </div>
        </div>
        <div class="card">
          <div class="btn btn-black px-40" @click="refreshData">
            <div class="text"><a>Apply</a></div>
          </div>
          <div class="btn redo px-15" @click="resetFilter">
            <a>
              <inline-svg :src="require('@/assets/image/icons/redo.svg')" />
            </a>
          </div>
        </div>
      </div>
      <div class="table-block col-span-1">
        <data-table
          :page="filterData.page"
          :header="modelInfoTable.header"
          :data="modelEntries"
          :link="{ url: baseUrl + '/', name: 'model' }"
          class="media-table"
          :isLoading="isLoading"
        />
      </div>
      <div class="pages" v-if="pagination.total_pages > 1">
        <div class="pages-quantity">
          <template v-if="!isLoading">
            Page {{ filterData.page }} of {{ pagination.total_pages }} - Showing
            {{ pagination.per_page }} Results of {{ pagination.total }} Total
          </template>
        </div>
        <paginate
          v-if="pagination.total_pages"
          :page-count="pagination.total_pages"
          :page-range="6"
          :initial-page="initialPage - 1"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="'&laquo;'"
          :next-text="'&raquo;'"
          :container-class="'pages-btn'"
          :active-class="pageActive"
        >
        </paginate>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../../component/PageHeader";
import DataTable from "../../../component/DataTable";
import Dropdown from "../../../component/Dropdown";
import { mapActions, mapState } from "vuex";

export default {
  name: "ModelInfoLookup",
  data() {
    return {
      baseUrl: '/admin/model-info',
      isLoading: true,
      statuses: [
        {
          id: 'Active',
          color: '#63dc2bcc',
        },
        {
          id: 'Inactive',
          color: '#d404048f'
        },
      ],
      modelInfoTable: {
        header: [
            {
                field: "model_type",
                name: "Model Type",
                width: "10%",
                headerWidth: "10%",
            },
            {
                field: "model",
                name: "Model ID",
                width: "9%",
                headerWidth: "9%",
                transformToComponent: "TransformToLink",
                transformField: "model",
            },
            {
                field: "service_model",
                name: "Service Model",
                width: "18%",
                headerWidth: "18%",
            },
            {
                field: "description",
                name: "Description",
                width: "44%",
                headerWidth: "44%",
            },
            {
                field: "status",
                name: "Status",
                transformField: "status",
                transform: this.transformToStatus,
                width: "12%",
                headerWidth: "12%",
            },
            {
                field: "model",
                name: "Actions",
                transformToComponent: "ModelInfoTableDropdown",
                width: "7%",
                headerWidth: "7%",
            },
        ],
      },
      filterData: {
        page: 1,
        status: 'Active',
      },
      pageActive: "active",
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      modelEntries: (state) => state.modelInfo.entries.map(e => ({...e, id: e.model})),
      pagination: (state) => state.modelInfo.pagination,
      initialPage: (state) => state.modelInfo.initialPage,
      modelTypes: (state) => state.modelInfo.modelTypes.map(type => ({type})),
    }),
    userCanCreate() {
      return (
        this.user.permissions.includes('GLOBAL') || 
        this.user.permission_actions?.["Model Info"]?.Create
      );
    },
    statusColors() {
      // map colors object - keys from status id, values from status color
      return this.statuses.reduce((colorsObj, status) => ({
        ...colorsObj,
        [status.id]: status.color
      }), {});
    }
  },
  components: {
    PageHeader,
    DataTable,
    Dropdown,
  },
  created() {
    if (this.$route.query.page) {
      if (this.$route.query.model) {
        this.filterData.model = this.$route.query.model;
      }
      if (this.$route.query.model_type) {
        this.filterData.model_type = this.$route.query.model_type;
      }
      if (this.$route.query.status) {
        this.filterData.status = this.$route.query.status;
      }
      this.filterData.page = +this.$route.query.page;
      this.getInitialPage(this.filterData.page);
      this.isLoading = true;
      this.$forceUpdate();
      this.getModelInfo(this.filterData)
        .then((res) => {
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    } else {
      this.refreshData();
    }
  },
  methods: {
    ...mapActions({
      getModelInfo: "modelInfo/getModelInfo",
      getInitialPage: "modelInfo/getInitialPage",
      setFilterState: "modelInfo/setIndexFilters",
    }),
    transformToStatus(status) {
      const statusClass = (status.toLowerCase() === 'active') ? 'success' : 'rejected';
      return `<div class="c-status c-status--${statusClass}">${status}</div>`
    },
    resetFilter() {
      this.pageActive = "";
      this.filterData = {
        page: 1,
      };
      this.getInitialPage(1);
      this.applyFilter();
    },
    applyFilter() {
      this.updateRouter();
      this.isLoading = true;
      this.getModelInfo(this.filterData)
        .then((res) => {
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    },
    clickCallback(pageNum) {
      this.getInitialPage(pageNum);
      this.filterData.page = pageNum;
      this.pageActive = "active";
      this.refreshData();
    },
    refreshData() {
      this.filterData.page = this.initialPage;
      this.isLoading = true;
      this.getModelInfo(this.filterData)
        .then((res) => {
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    },
    selectModelType(item) {
        if (item.type.toLowerCase() == 'all') {
            delete this.filterData.model_type;
        } else {
            this.$set(this.filterData, 'model_type', item.type);
        }
        this.applyFilter();
    },
    selectStatus(status) {
      this.$set(this.filterData, 'status', status.id);
      this.applyFilter();
    },
    updateRouter(saveState = true) {
      this.$router.push(
        this.baseUrl + "?" + new URLSearchParams(this.filterData).toString()
      );
      if (saveState) this.setFilterState(this.filterData);
    },
  },
};
</script>

<style lang="scss" scoped>
.manage .container .filter-block .table-filter-model-type-select {
    display: flex;
    .form-group {
        width: 140px !important;
    }
}
.table-filter-status-select {
  max-width: 130px;
}
</style>
