import { http } from '../../../api';

const registration = {
    namespaced: true,
    state: {
        registrationList: [],
        registrations: [],
        registrationStatuses: [],
        program_batches: [],
        territoryManagers: [],
        dealerships: [],
        registrationsDetails: {},
        createRegistrations: [],
        pagination: {},
        tractorSns:{},
        initialPage: 1,
        indexFilters: {},
    },
    actions: {
        getRegistrationList: ({ commit }, params) => {
            return http.get('registrations/list', {params})
                .then(res => {
                    commit('setRegistrationList', res.data.data.registrations);
                })
                .catch(err => {
                    console.error(err);
                });
        },
        getRegistrations: ({ commit }, params) => {
            return http.get('registrations?page=' + params.page)
                .then(res => {
                    commit('setRegistrations', res.data.data.registrations.data)
                    commit('setPagination', res.data.data.registrations.pagination)
                    commit('setRegistrationsStatuses', res.data.data.registrationStatuses);
                    commit('setTerritoryManagers', res.data.data.territoryManagers);
                    commit('setDealerships', res.data.data.dealership)
                })
                .catch(err => {
                    console.error(err);
                });
        },
        filterRegistrations: ({ commit }, params) => {
            const q = new URLSearchParams(params);
            return http.get('registrations/filter?' + q.toString()).then(res => {
                commit('setRegistrations', res.data.data.registrations.data)
                commit('setPagination', res.data.data.registrations.pagination)
            })
            .catch(err => {
                console.error(err);
            });
            ;
        },
        getCreateRegistrations: ({commit},params)=>{
            return http.get('registrations/create',params).then(res => {
                commit('setCreateRegistrations', res.data.data)
            })
        },
        deleteWarranty: ({commit},params)=>{
            return http.delete('registrations/'+ params.id).then(res => {
                commit('setDeleteWarranty', params.id);
                return res;
            })
        },
        getRegistrationTractorSn: ({commit},params)=>{
            return http.get('tractor-sn?dealership_id=' + params.id).then(res => {
                commit('setTractorSn', res.data.data.tractor_sns)
            })
        },
        getRegistrationsDetails: ({commit},params)=>{
            return http.get('registrations/' + params.id).then(res => {
                commit('setRegistrationsDetails', res.data.data);
                return res.data.data;
            })
        },
        createNewTractor: ({commit},params)=>{
            return http.post('registrations',params).then(res => {
                return res.data.data
            })
        },
        updateRegistration: ({commit},params)=>{
            return http.post('registrations/' + params.id, params.formData).then(res => {
            })
        },
        getInitialPage: ({ commit }, params) => {
            commit('setInitialPage', params)
        },

        setIndexFilters: ({commit}, params) => {
            commit('setIndexFilters', {...params});
        }
    },
    mutations: {
        setRegistrationList(state, data) {
            state.registrationList = data;
        },
        setInitialPage(state, page) {
            state.initialPage = page
        },
        setDeleteWarranty(state, id) {
            state.registrations = state.registrations.filter(el => el.id !== id )
        },
        setTractorSn(state, data) {
            state.tractorSns = data;
        },
        setTerritoryManagers(state, data) {
            state.territoryManagers = data;
        },
        setRegistrationsDetails(state, data) {
            state.registrationsDetails = {
                ...data.registration,
                'warranty_terms': data.warr_terms,
            }
            state.registrationStatuses = data.registrationStatus;
            state.program_batches = data.program_batches;
        },
        setCreateRegistrations(state, data) {
            state.createRegistrations = data;
        },
        setRegistrations(state, data) {
            state.registrations = data;
        },
        setPagination(state, data) {
            state.pagination = data;
        },
        setRegistrationsStatuses(state, data) {
            state.registrationStatuses = data;
        },
        setDealerships(state, data) {
            state.dealerships = data;
        },
        setIndexFilters(state, data) {
            state.indexFilters = data;
        }
    },
    getters: {
        registrationList: state => state.registrationList,
        registrations: state =>  state.registrations,
        registrationStatus: state =>  state.registrationStatus,
        program_batches: state => state.program_batches,
        dealerships: state =>  state.dealerships,
        pagination: state =>  state.pagination,
        registrationStatuses: state =>  state.registrationStatuses,
        createRegistrations: state =>  state.createRegistrations,
        tractorSns: state =>  state.tractorSns,
        registrationsDetails: state =>  state.registrationsDetails,
        territoryManagers: state =>  state.territoryManagers,
        initialPage: state =>  state.initialPage,
        indexFilters: state => state.indexFilters,
    }
};

export default registration;
