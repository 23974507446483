<template>
  <div class="dropdown-settings relative">
    <button
      aria-label="actions-menu"
      type="button"
      class="button btn-td-hover"
      :class="openClose ? 'active' : ''"
      v-click-outside="hide"
      @click="openClose = !openClose"
    >
      <inline-svg
        class="m-auto"
        :src="require('@/assets/image/icons/more-alt.svg')"
      />
    </button>
    <div class="dropdown" v-if="openClose">
      <router-link tag="div" :to="{ name: 'EditSerialLookup', params: { prefix: item.item.prefix }}"
        >Edit</router-link
      >
      <div class="delete" @click="openConfirmModal">Delete</div>
    </div>
    <ConfirmModal
      text="Are you sure you want to delete this serial entry?"
      confirmBtn="Delete Serial"
      :show="showDeleteSerialModal"
      @confirm="deleteSerial"
      @close="closeConfirmModal"
    />
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import { mapActions, mapState } from "vuex";
import ConfirmModal from "../ConfirmModal";

export default {
  name: "SerialsTableDropdown",
  props: ["item"],
  data() {
    return {
      openClose: false,
      showDeleteSerialModal: false,
    };
  },
  components: {
    ConfirmModal,
  },
  created() {
    // console.log(this.item);
  },
  methods: {
    ...mapActions({
      deleteSerialEntry: "serials/deleteEntry",
      getSerials: "serials/getModelInfo",
    }),
    openConfirmModal() {
      this.showDeleteSerialModal = true;
    },
    deleteSerial() {
      this.deleteSerialEntry({ prefix: this.item.item.prefix })
        .then((res) => {
          this.getSerials({ page: this.item.page });
          this.$toasted.success(res.data.message);
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        })
        .finally(() => {
          this.closeConfirmModal();
        });
    },
    closeConfirmModal() {
      this.showDeleteSerialModal = false;
    },
    hide() {
      this.openClose = false;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>