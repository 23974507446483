<template>
  <div class="page-404">
    <div>
      <h1>Oops!</h1>
      <h2 class="mb-4">We can't seem to find the page you're looking for.</h2>
      <h3 class="mb-8">Error code: 404</h3>
      <router-link :to="{ name: 'Home' }" class="btn btn-black">
        Return to Dashboard
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "page404",
  data() {
    return {};
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.page-404 {
  grid-column: span 12;
  height: 100%;
  background-color: $gray-100;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;

  @media (max-width: 500px) {
    padding: 30px;
  }

  > div {
    padding-bottom: 10vh;
  }
}

h1 {
  font-size: 72px;
  font-weight: 500;
  @media (max-width: 900px) {
    font-size: 58px;
  }
  @media (max-width: 500px) {
    font-size: 48px;
  }
}

h2 {
  font-size: 34px;
  @media (max-width: 900px) {
    font-size: 30px;
  }
  @media (max-width: 500px) {
    font-size: 26px;
  }
}

h3 {
  font-size: 22px;
  @media (max-width: 900px) {
    font-size: 20px;
  }
  @media (max-width: 500px) {
    font-size: 18px;
  }
}

.btn {
  display: inline-flex;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 16px;
  @media (max-width: 900px) {
    font-size: 14px;
  }
  @media (max-width: 500px) {
    font-size: 12px;
  }
}
</style>