import Vue from 'vue';
import Toasted from 'vue-toasted';
import ToastedOptions from '../../utils/toasted.config';

Vue.use(Toasted, {
  ...ToastedOptions,
  duration: 2000,
});


export default function hasAction (docType, action, errorRoute = 'Home'){

    return function({ next, store }) {
        
        const permissions = store.state.auth.user?.permissions;
        if (permissions?.includes("GLOBAL")) return next();

        const actions = store.state.auth.user?.permission_actions;
        if (actions?.[docType]?.[action]) return next();


        //redirect to error route, show message
        setTimeout(() => {
            Vue.toasted.error('You do not have permission to view this page');
            localStorage.toastedId = Vue.toasted.id;
        }, 200);

        return next({
            name: errorRoute
        });

    }
  }
  