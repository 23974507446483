<template>
  <section class="manage col-span-12">
    <div class="container grid grid-cols-1 gap-0">
      <PageHeader
        title="Manage Videos"
        :saveButton="false"
        :discardLink="false"
        :addButton="true"
        addText="Add New Video"
        @add="$router.push({ name: 'addVideo', params: { id: 'add' } })"
        backText="Back to Settings"
        :backRoute="{ name: 'AdminSettings' }"
      />
      <div class="filter-block">
        <div>
          <div class="table-filter-search-input">
            <div class="form-group">
              <label class="control-label">Search</label>
              <input
                v-model="filterData.media"
                v-on:keyup.enter="filter"
                type="text"
                name="search"
                value=""
                class="search"
                placeholder="Media"
              />
              <inline-svg
                class="search-icon"
                :src="require('@/assets/image/icons/search.svg')"
              />
            </div>
          </div>
        </div>
        <div class="card">
          <div class="btn btn-black px-40" @click="filter">
            <div class="text"><a>Apply</a></div>
          </div>
          <div class="btn redo px-15" @click="resetFilter">
            <a>
              <inline-svg :src="require('@/assets/image/icons/redo.svg')" />
            </a>
          </div>
        </div>
      </div>
      <div class="table-block col-span-1">
        <data-table
          :page="filterData.page"
          :header="videosTable.header"
          :data="videos"
          :link="{ url: '/admin/videos/', name: 'title' }"
          class="media-table"
          :isLoading="isLoading"
        />
      </div>
      <div class="pages" v-if="pagination.total && pagination.total_pages > 1">
        <div class="pages-quantity">
          <template v-if="!isLoading">
            Page {{ filterData.page }} of {{ pagination.total_pages }} - Showing
            {{ pagination.per_page }} Results of {{ pagination.total }} Total
          </template>
        </div>
        <paginate
          v-if="pagination.total_pages"
          :page-count="pagination.total_pages"
          :page-range="6"
          :margin-pages="1"
          :initial-page="initialPage - 1"
          :click-handler="clickCallback"
          :prev-text="'&laquo;'"
          :next-text="'&raquo;'"
          :container-class="'pages-btn'"
          :active-class="pageActive"
        >
        </paginate>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../../component/PageHeader";
import DataTable from "../../../component/DataTable";
import Dropdown from "../../../component/Dropdown";
import { mapActions, mapState } from "vuex";

export default {
  name: "ManageVideos",
  data() {
    return {
      isLoading: false,
      videosTable: {
        header: [
          {
            field: "title",
            name: "Video Title",
            transformToComponent: "TransformToLink",
            width: "29%",
            headerWidth: "28%",
          },
          { field: "posted_on", name: "Posted On" },
          {
            field: "id",
            name: "Actions",
            transformToComponent: "VideoDropdownSettings",
          },
        ],
      },
      filterData: {
        page: 1,
        media: "",
      },
      pageActive: "active",
    };
  },
  components: {
    PageHeader,
    DataTable,
    Dropdown,
  },
  computed: {
    ...mapState({
      videos: (state) => state.manageVideos.videos,
      pagination: (state) => state.manageVideos.pagination,
      initialPage: (state) => state.manageVideos.initialPage,
    }),
  },
  mounted() {
    if (this.$route.query.page) {
      if (this.$route.query.media) {
        this.filterData.media = this.$route.query.media;
      }
      this.filterData.page = +this.$route.query.page;
      this.getInitialPage(this.filterData.page);
      this.filterNews(this.filterData);
    } else {
      this.getVideosList();
    }
  },
  methods: {
    ...mapActions({
      getVideos: "manageVideos/getVideos",
      filterVideos: "manageVideos/filterVideos",
      getInitialPage: "manageVideos/getInitialPage",
    }),
    getVideosList() {
      this.filterData.page = this.initialPage;
      this.isLoading = true;
      this.getVideos({ page: this.filterData.page })
        .then((res) => {})
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    filter() {
      this.$router.push(
        "/admin/videos?" + new URLSearchParams(this.filterData).toString()
      );
      this.isLoading = true;
      this.filterVideos(this.filterData)
        .then((res) => {})
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    resetFilter() {
      this.pageActive = "";
      this.filterData = {
        page: 1,
      };
      this.filterData.media = "";
      this.getInitialPage(1);
      this.filter();
    },
    clickCallback(pageNum) {
      this.getInitialPage(pageNum);
      this.filterData.page = pageNum;
      this.pageActive = "active";
      this.filter();
    },
  },
};
</script>

<style lang="scss">
.media-table {
  .data-table-head {
    .tr {
      .th:first-of-type {
        text-align: left;
        padding-left: 12px;
      }
    }
  }
  .tbody {
    .tr {
      .td:first-of-type {
        justify-content: flex-start;
        span {
          text-align: left;
          padding-left: 12px;
        }
      }
    }
  }
}
</style>
