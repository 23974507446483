<template>
    <div class="link" @click="openItem">
        {{ linkItem[link.name] }}
    </div>
</template>

<script>
export default {
  name: "TransformToLink",
  props: ['link', 'linkItem'],
  created() {
  },
  methods:{
    openItem(){
      if(this.linkItem.access && !this.linkItem.access.show) {
        this.$toasted.error('You can only view orders that belong to you.')
      } else {
        //Find paramater in url. If none provided, add id to end of url
        const found = this.link.url.match(/:[^\/]+/);
        if (found) {
          const param = found[0].substring(1);
          const newUrl = this.link.url.replace(found[0], this.linkItem[param]);
          this.$router.push(newUrl);
        } else {
          this.$router.push(this.link.url + this.linkItem.id);
        }
      }
    }
  }
}
</script>

<style scoped>
div.link {
    cursor: pointer;
    text-decoration: underline;
    color: #237DBE;
}
</style>
