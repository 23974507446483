import { http } from '../../../api';

const videos = {
    namespaced: true,
    state: {
        pagination:{},
        videos: [],
        videoItem: {},
        initialPage: 1
    },
    actions: {
        getVideosList: ({ commit }, params) => {
            return http.get('/videos',params).then(res => {
                commit('setVideosList', res.data.data.videos)
          });
        },
        getVideoItem: ({ commit }, params) => {
            return http.get('/videos/' + params.id).then(res => {
                commit('setVideoItem', res.data.data)
          });
        },
        filterVideos: ({ commit }, params) => {
            const q = new URLSearchParams(params);
            return http.get('videos/filter?' + q.toString()).then(res => {
                commit('setVideosList', res.data.data.media)
            });
        },
        downloadVideo: ({ commit }, params) => {
            return http.get('videos/' + params.video_id + '/youtube-download').then(res => {
            });
        },
        getInitialPage: ({ commit }, params) => {
            commit('setInitialPage', params)
        },
    },
    mutations: {
        setInitialPage(state, page) {
            state.initialPage = page
        },
        setVideosList(state, data){
            state.videos = data.data;
            state.pagination = data.pagination;
        },
        setVideoItem(state, data){
            state.videoItem = data.video;
        },
    },
    getters: {
        videos: state => state.videos,
        pagination: state => state.pagination,
        videoItem: state => state.videoItem,
        initialPage: state => state.initialPage,
    }
};

export default videos;
