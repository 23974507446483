<template>
  <section class="user-details col-span-12">
    <div class="container">
      <PageHeader
        backText="Back to Manage Users"
        :backRoute="{ name: 'ManageUsers', query: { ...userIndexFilters } }"
        :saveButton="false"
        :discardLink="false"
        :details="{
          title: user.name,
        }"
      >
        <template v-slot:actions>
          <router-link
            tag="button"
            class="btn btn-info px-40"
            :to="`/admin/user/${user.id}/edit`"
            v-if="user && userCanEdit"
            >Edit</router-link
          >
        </template>
        <template v-slot:details-subtitle>
          <a :href="`mailto:${user.email}`" target="_blank" class="c-link">{{
            user.email
          }}</a>
        </template>
      </PageHeader>
      <div class="user-information" v-if="user">
        <div class="information">
          <div class="subtitle">Account Information</div>
          <div class="information-block">
            <div class="block-container">
              <div>
                <p>Name</p>
                <span>{{ user.name }}</span>
              </div>
              <div>
                <p>Email Address</p>
                <a
                  :href="`mailto:${user.email}`"
                  target="_blank"
                  class="link"
                  >{{ user.email }}</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="information">
          <div class="subtitle">Dealership Information</div>
          <div class="information-block" v-if="user.dealer">
            <div class="block-container">
              <div>
                <p>Name</p>
                <span>{{ user.dealer.name }}</span>
              </div>
              <div>
                <p>Dealer Code</p>
                <span>{{ user.dealer.code }}</span>
              </div>
              <div v-if="user.dealer.territory_manager">
                <p>Territory Manager</p>
                <span>{{ user.dealer.territory_manager.name }}</span>
              </div>
              <div v-for="phone in user.dealer.phone_numbers">
                <p>
                  {{ phone.type === "FAX" ? "Fax Number" : "Office Number" }}
                </p>
                <span>{{ phone.phone_number }}</span>
              </div>
              <div>
                <p>Shipping Address</p>
                <span>{{ user.dealer.shipping_address }}</span>
              </div>
              <div>
                <p>Billing Address</p>
                <span>{{ user.dealer.billing_address }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../../component/PageHeader";
import { mapActions, mapState } from "vuex";

export default {
  name: "userDetail",
  props: ["id"],
  mounted() {
    this.getUser({ id: this.$route.params.id })
      .then()
      .catch((err) => {
        this.$toasted.error(err.response.data.message || "Server Error");
      });
  },
  computed: {
    ...mapState({
      user: (state) => state.user.user,
      authUser: (state) => state.auth.user,
      userIndexFilters: (state) => state.user.indexFilters,
    }),
    userCanEdit: function () {
      return this.user.allowed_to_edit;
    },
  },
  methods: {
    ...mapActions({
      getUser: "user/getUser",
    }),
  },
  components: {
    PageHeader,
  },
};
</script>

<style scoped>
</style>
