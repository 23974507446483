import { http } from '../../../api';

const auth = {
    namespaced: true,
    state: {
        isAuthenticated: false,
        user: null,
        access_token: null,
    },
    actions: {
        login: ({ commit }, params) => {
            localStorage.removeItem("vuex");
            return http.post('auth/login',params.data).then(res => {
              commit('userLogin', res.data.data)
          });
        },
        sendForgotPasswordEmail: ({ commit }, email) => {
            return http.post('auth/password/forgot', {email})
                .then(res => {
                    return res;
                }).catch(err => {
                    throw err.response?.data ?? err.message;
                });
        },
        resetPassword: ({ commit }, params) => {
            return http.post('auth/password/reset', params)
                .then(res => {
                    return res;
                }).catch(err => {
                    throw err.response?.data ?? err.message;
                });
        },
        logout: ({ commit }, params) => {
            console.log('logging out');
            return http.get('auth/logout',params)
            .catch(err => {
                console.error(err);
            })
            .finally( () => {
                commit('userLogout')
            });
        },
    },
    mutations: {
        userLogin(state, data) {
            let accessToken  = data.access_token;
            localStorage.setItem('access_token',accessToken);
            // localStorage.setItem('user', JSON.stringify(data.user));
            http.defaults.headers.common.Authorization = accessToken;
            state.isAuthenticated = true;
            state.access_token = accessToken;
            state.user = data.user;
        },

        userLogout(state, redirect = false){
            localStorage.removeItem('access_token');
            localStorage.removeItem('user');
            localStorage.removeItem('vuex');
            http.defaults.headers.common.Authorization = '';
            state.isAuthenticated = false;
            state.access_token = null;
            state.user = null;

            if (redirect) {
                window.location.pathname = '/login';
            }
            // sessionStorage.clear();
        },

        setUserData(state, data) {
            state.user = data;
        },
    },
    getters: {
        isAuthenticated: state => state.isAuthenticated,
        user: state =>  state.user,

    }
};

export default auth;
