<template>
  <section
    class="
      col-span-12
      retail-programs
      print:transform print:w-x5/3 print:scale-60 print:origin-top-left
    "
  >
    <ValidationObserver tag="div" ref="form" class="container">
      <!-- <div class="print:hidden"> -->
      <PageHeader
        :title="`${isEdit && !historyVersion ? 'Edit ' : ''}Submission #${
          batchDetails.id
        }`"
        :subtitle="historySubtitle"
        :backText="
          isEdit ? 'Back to Submission Details' : 'Back to Retail Programs'
        "
        :backRoute="
          isEdit
            ? { name: 'RetailProgramView', params: { id: batchID } }
            : { name: 'RetailPrograms', query: { ...programBatchFilters } }
        "
        :saveButton="isEdit"
        :canSave="!viewOnly"
        @save="saveClicked"
        :discardLink="historyVersion || isEdit ? true : false"
        :discardText="historyVersion ? 'Current' : 'Discard'"
        @discard="
          historyVersion
            ? selectHistoryEntry({ id: null })
            : (showModal.discard = true)
        "
        :buttonsDisabled="buttonDisabled"
        :details="
          isEdit
            ? null
            : {
                title: `Submission #${batchDetails.id}`,
                status: selectedStatus.description,
                date: batchDetails.timestamp,
                dateFormat: 'MMMM Do, YYYY',
              }
        "
      >
        <template v-slot:actions>
          <div
            style="padding-right: 25px; font-size: 13px"
            class="print:hidden"
            v-if="userCanViewHistory"
          >
            <button
              class="underline focus:outline-none relative"
              @click="toggleHistory"
            >
              <div class="opacity-0">Show History</div>
              <div
                class="absolute top-0 w-full h-full text-center"
                style="text-decoration: inherit"
              >
                {{ showHistory ? "Hide History" : "Show History" }}
              </div>
            </button>
          </div>
          <div
            style="margin-right: 25px"
            class="print:hidden"
            v-if="!isEdit && userCanEdit"
          >
            <router-link
              tag="button"
              class="btn btn-info px-40"
              :to="{ name: 'RetailProgramEdit', params: { id: batchID } }"
            >
              Edit
            </router-link>
          </div>
          <div style="margin-right: 25px" v-if="isEdit">
            <dropdown
              :data="statusList"
              :status="true"
              fieldName="description"
              :validation="false"
              inputName="program_status"
              :all="false"
              :editValue="selectedStatus.description"
              @select="selectStatus"
              :disabled="viewOnly"
              :statusColors="{
                1: '#606060',
                2: '#d7ad41',
                3: '#1db8d89a',
                4: '#63dc2bcc',
                5: '#d404048f',
              }"
            ></dropdown>
          </div>
        </template>
      </PageHeader>
      <!-- </div> -->
      <div class="main-section" style="max-width: 1200px">
        <h2 class="title">Warranty Information</h2>
        <div class="info-block">
          <div class="info">
            <div v-if="dealership">
              <label>Retail Dealership</label>
              <span>{{ dealership.name }}</span>
            </div>
            <template v-if="warrantyRegistration">
              <div class="">
                <label>Sale Date</label>
                <span>{{ warrantyRegistration.sale_date }}</span>
              </div>
              <div>
                <label>Owner</label>
                <span>{{ warrantyRegistration.owner_name }}</span>
              </div>
              <div>
                <label>Invoice #</label>
                <span>{{ warrantyRegistration.invoice }}</span>
              </div>

              <div v-if="warrantyRegistration.tractor_sn">
                <label>Tractor S/N</label>
                <span
                  v-if="
                    warrantyRegistration.tractor_sn ==
                    warrantyRegistration.primary_sn
                  "
                >
                  <router-link
                    tag="a"
                    class="text-blue underline"
                    :to="'/registrations/' + warrantyRegistration.id"
                    >{{ warrantyRegistration.primary_sn }}
                  </router-link>
                </span>
                <span v-else>
                  {{ warrantyRegistration.tractor_sn }}
                </span>
              </div>
              <div v-if="warrantyRegistration.loader_sn">
                <label>Loader S/N</label>
                <span
                  v-if="
                    warrantyRegistration.loader_sn ==
                    warrantyRegistration.primary_sn
                  "
                >
                  <router-link
                    tag="a"
                    class="text-blue underline"
                    :to="'/registrations/' + warrantyRegistration.id"
                    >{{ warrantyRegistration.primary_sn }}
                  </router-link>
                </span>
                <span v-else>
                  {{ warrantyRegistration.loader_sn }}
                </span>
              </div>
              <div v-if="warrantyRegistration.backhoe_sn">
                <label>Backhoe SN</label>
                <span
                  v-if="
                    warrantyRegistration.backhoe_sn ==
                    warrantyRegistration.primary_sn
                  "
                >
                  <router-link
                    tag="a"
                    class="text-blue underline"
                    :to="'/registrations/' + warrantyRegistration.id"
                    >{{ warrantyRegistration.primary_sn }}
                  </router-link>
                </span>
                <span v-else>
                  {{ warrantyRegistration.backhoe_sn }}
                </span>
              </div>
              <div v-if="warrantyRegistration.mower_sn">
                <label>Mid Mount Mower</label>
                <span
                  v-if="
                    warrantyRegistration.mower_sn ==
                    warrantyRegistration.primary_sn
                  "
                >
                  <router-link
                    tag="a"
                    class="text-blue underline"
                    :to="'/registrations/' + warrantyRegistration.id"
                    >{{ warrantyRegistration.primary_sn }}
                  </router-link>
                </span>
                <span v-else>
                  {{ warrantyRegistration.mower_sn }}
                </span>
              </div>
              <div v-if="warrantyRegistration.cabin_sn">
                <label>Cabin</label>
                <span
                  v-if="
                    warrantyRegistration.cabin_sn ==
                    warrantyRegistration.primary_sn
                  "
                >
                  <router-link
                    tag="a"
                    class="text-blue underline"
                    :to="'/registrations/' + warrantyRegistration.id"
                    >{{ warrantyRegistration.primary_sn }}
                  </router-link>
                </span>
                <span v-else>
                  {{ warrantyRegistration.cabin_sn }}
                </span>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="main-section" style="max-width: 1200px">
        <div id="retail-programs" class="mb-10">
          <ShowHideSection
            :title="batchDetails.program_type_name"
            :showHideButton="false"
          >
            <RetailBatchEdit
              :details="batchDetails"
              :dealership="dealership"
              :disabled="viewOnly"
              :viewOnly="!isEdit"
              ref="batchDetails"
            />
          </ShowHideSection>
        </div>
        <div
          class="mb-10"
          v-bind:class="{
            'opacity-50': isEdit && !userCanEditPaymentInfo,
          }"
        >
          <h2 class="title">Payment Information</h2>
          <div class="form-group" style="width: 270px" v-if="isEdit">
            <div
              class="mb-4 flex items-center"
              v-bind:class="{
                'opacity-50':
                  isEdit &&
                  userCanEditPaymentInfo &&
                  !batchDetails.salesperson_id,
              }"
            >
              <span class="font-medium mr-4" style="min-width: 130px">
                SAP Vendor #:
              </span>
              <span class="flex-1">
                <input
                  type="text"
                  v-model="batchDetails.sap_acct"
                  :disabled="
                    !batchDetails.salesperson_id || !userCanEditPaymentInfo
                  "
                />
              </span>
            </div>
            <div
              v-if="userCanEditPaymentInfo"
              class="capitalizeDropdownInput mb-4 flex items-center"
            >
              <span class="font-medium mr-4" style="min-width: 130px">
                Payment Method:
              </span>
              <span class="flex-1">
                <dropdown
                  :data="payMethods"
                  fieldName="description"
                  :validation="false"
                  inputName="pay_method"
                  :all="false"
                  :editValue="batchDetails.pay_method"
                  @select="selectPayMethod"
                ></dropdown>
              </span>
            </div>
            <div v-else class="mb-4 flex items-center">
              <span class="font-medium mr-4" style="min-width: 130px">
                Payment Method:
              </span>
              <span class="flex-1">
                <input
                  type="text"
                  class="capitalize"
                  v-model="batchDetails.pay_method"
                  disabled
                />
              </span>
            </div>
            <div class="mb-4 flex items-center">
              <span class="font-medium mr-4" style="min-width: 130px">
                Doc #:
              </span>
              <span class="flex-1">
                <input
                  type="text"
                  v-model="batchDetails.doc_num"
                  :disabled="!userCanEditPaymentInfo"
                />
              </span>
            </div>
            <div class="flex items-center">
              <span class="font-medium mr-4" style="min-width: 130px">
                Check #:
              </span>
              <span class="flex-1">
                <input
                  type="text"
                  v-model="batchDetails.check_num"
                  :disabled="!userCanEditPaymentInfo"
                />
              </span>
            </div>
          </div>
          <div v-else class="text-sm">
            <div class="mb-4">
              <span
                class="inline-block font-medium mr-2"
                style="min-width: 130px"
                >SAP Vendor #:
              </span>
              {{ batchDetails.sap_acct }}
            </div>
            <div class="mb-4 capitalize">
              <span
                class="inline-block font-medium mr-2"
                style="min-width: 130px"
              >
                Payment Method:
              </span>
              {{ batchDetails.pay_method }}
            </div>
            <div class="mb-4">
              <span
                class="inline-block font-medium mr-2"
                style="min-width: 130px"
                >Doc #:
              </span>
              {{ batchDetails.doc_num }}
            </div>
            <div class="">
              <span
                class="inline-block font-medium mr-2"
                style="min-width: 130px"
                >Check #:
              </span>
              {{ batchDetails.check_num }}
            </div>
          </div>
        </div>
        <h2 class="title">Notes</h2>
        <div class="mb-10">
          <!-- <p class="pb-2">Write Comments Here:</p> -->
          <p v-if="viewOnly" style="min-height: 2em">
            {{ batchDetails.admin_comments }}
          </p>
          <textarea
            v-else
            rows="5"
            class="border border-gray-300 bg-white rounded p-2 block w-full"
            v-model="batchDetails.admin_comments"
            :disabled="viewOnly"
          ></textarea>
        </div>
        <template v-if="userCanViewAdminNotes">
          <h2 class="title"><span class="inline-block mr-6">Admin Notes</span><em class="inline-block text-sm font-normal">*Visible only to admins and territory managers</em></h2>
          <div class="mb-10">
            <!-- <p class="pb-2">Write Comments Here:</p> -->
            <p v-if="viewOnly" style="min-height: 2em">
              {{ batchDetails.admin_notes }}
            </p>
            <textarea
              v-else
              rows="5"
              class="border border-gray-300 bg-white rounded p-2 block w-full"
              v-model="batchDetails.admin_notes"
              :disabled="viewOnly"
            ></textarea>
          </div>
        </template>
        <div class="flex items-center" v-if="isEdit">
          <button
            @click="saveClicked"
            :readonly="buttonDisabled"
            v-bind:class="{
              'opacity-50': buttonDisabled || viewOnly,
              'cursor-wait': buttonDisabled,
              'cursor-not-allowed': viewOnly,
            }"
            :disabled="viewOnly"
            type="button"
            class="btn btn-black px-40"
          >
            Save
          </button>
          <a
            v-if="historyVersion"
            class="discard-link ml-4"
            :href="`/retail-programs/${this.batchID}/edit`"
          >
            Return to Current
          </a>
          <div
            v-else
            class="discard-link ml-4"
            @click="showModal.discard = true"
          >
            Discard
          </div>
        </div>

        <HistoryViewer
          v-if="userCanViewHistory"
          :data="historyLines"
          :fields="[
            { field: 'user.name', name: 'Edited By' },
            {
              field: 'amount_adjusted',
              name: 'Adjusted Amount',
              currency: true,
            },
          ]"
          :statusClasses="{
            1: 'draft',
            2: 'pending',
            3: 'blue',
            4: 'success',
            5: 'rejected',
          }"
          :visible.sync="showHistory"
          :selection="historyVersion"
          @select="selectHistoryEntry"
        />
      </div>
      <ConfirmModal
        text="Discard changes?"
        confirmBtn="Discard"
        :show="showModal.discard"
        @confirm="returnToProgramsIndex"
        @close="showModal.discard = false"
      />
      <ConfirmModal
        text="NOTICE: you are saving a status that you will no longer be able to edit. Do you wish to continue?"
        confirmBtn="SAVE"
        :show="showModal.savingStatusWarning"
        button="restored"
        @confirm="submitRetailProgram"
        @close="showModal.savingStatusWarning = false"
      />
    </ValidationObserver>
  </section>
</template>

<script>
import PageHeader from "../../component/PageHeader";
import Dropdown from "../../component/Dropdown";
import Loading from "../../component/Loading";
import { mapActions, mapState } from "vuex";
import ConfirmModal from "../../component/ConfirmModal";
import ShowHideSection from "../../component/ShowHideSection";
import RetailBatchEdit from "../../component/retailPrograms/RetailBatchEdit";
import HistoryViewer from "../../component/HistoryViewer";
import moment from "moment";

export default {
  name: "RetailProgramEdit",
  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      batchID: null,
      historyVersion: null,
      buttonDisabled: false,
      
      showModal: {
        discard: false,
        savingStatusWarning: false,
      },
      showHistory: false,

      allSelectedProducts: [],
      selectedStatus: { id: null, description: "" },
      originalStatusName: null,
    };
  },
  components: {
    PageHeader,
    Loading,
    Dropdown,
    ConfirmModal,
    ShowHideSection,
    RetailBatchEdit,
    HistoryViewer,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      registrationDetails: (state) => state.registration.registrationsDetails,
      batchDetails: (state) =>
        state.programs.programBatchDetails.program_batch_details,
      dealership: (state) => state.programs.programBatchDetails.dealership,
      warrantyRegistration: (state) =>
        state.programs.programBatchDetails.warranty_registration,
      statusList: (state) => state.programs.statuses,
      payMethods: (state) => state.programs.pay_methods,
      programBatchFilters: (state) => state.programs.programBatchFilters,
    }),

    permissionActions() {
      return this.user.permission_actions["Retail Program"] ?? null;
    },

    userCanViewAdminNotes() {
      return this.user.role.id < 3;
    },

    userCanSave() {
      return (
        this.user.permissions?.includes("GLOBAL") ||
        (this.permissionActions?.Edit?.from_status?.includes(
          this.originalStatusName
        ) &&
          this.permissionActions?.Save)
      );
    },

    userCanEdit() {
      return (
        this.user.permissions?.includes("GLOBAL") ||
        this.permissionActions?.Edit?.from_status?.includes(
          this.originalStatusName
        )
      );
    },

    userCanViewHistory() {
      return this.user.role.id == 1 && this.isEdit;
    },

    userCanEditPaymentInfo() {
      return (
        !this.viewOnly &&
        (this.user.permissions?.includes("GLOBAL") ||
          this.user.permissions?.includes("PRGM_REVIEW"))
      );
    },

    viewOnly() {
      if (!this.isEdit) return true;
      if (this.historyVersion) return true;

      return false;
    },

    historySubtitle() {
      if (!this.userCanViewHistory) return null;

      if (this.historyVersion) {
        const date = new Date(this.batchDetails.timestamp);
        const dateOptions = {
          month: "long",
          day: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
        };
        return `Viewing: ${date.toLocaleDateString("en-US", dateOptions)}`;
      } else {
        return "Viewing Current Version";
      }
    },

    historyLines() {
      if (!this.batchDetails.history) return;
      let [, ...history] = this.batchDetails.history;
      history = [
        {
          ...this.batchDetails.history[0],
          id: null,
          // timestamp: `${this.batchDetails.history[0].timestamp} (Current)`,
        },
        ...history,
      ];

      return history;
    },
  },
  watch: {
    isEdit: function () {
      this.init_component();
    },
  },
  created() {
    this.init_component();
  },
  methods: {
    ...mapActions({
      getProgramBatchEdit: "programs/getProgramBatchEdit",
      getProgramBatchDetails: "programs/getProgramBatchDetails",
      updateProgramBatch: "programs/updateProgramBatch",
      getRegistrationsDetails: "registration/getRegistrationsDetails",
    }),
    async init_component() {
      try {
        this.batchID = this.$route.params.id;

        if (this.isEdit) {
          this.historyVersion = parseInt(this.$route.query.history) || null;

          await this.getProgramBatchEdit({
            id: this.batchID,
            history: this.historyVersion,
          });
        } else {
          await this.getProgramBatchDetails(this.batchID);
        }

        //=== set all program batch values ===//

        //set status
        this.selectedStatus = this.statusList.find(
          (s) => s.id == this.batchDetails.status_id
        ) ?? { id: null, description: "" };

        this.originalStatusName = this.selectedStatus.description;

        //set line items
      } catch (err) {
        this.$toasted.error(
          err.response?.data?.message || err.message || "Server Error"
        );
      }
    },
    saveClicked() {
      const allowedToEditStatuses = this.user.permission_actions["Retail Program"]?.["Edit"]?.["from_status"] ?? [];

      if (
        !this.isEdit ||
        this.user.permissions.includes("GLOBAL") ||
        allowedToEditStatuses.includes(this.selectedStatus.description
      )) {
        this.submitRetailProgram();
      } else {
        this.showModal.savingStatusWarning = true;
      }
    },
    async submitRetailProgram() {
      //has permission to save program batch
      if (this.userCanSave) {
        this.buttonDisabled = true;

        const submitData = {
          ...this.$refs.batchDetails.submitData,
          status_id: this.selectedStatus.id,
        };

        try {
          //validation
          if (!(await this.$refs.form.validate())) {
            this.$toasted.error("Your submission contains errors");
            this.buttonDisabled = false;
            return;
          }

          await this.updateProgramBatch({
            id: this.batchID,
            programBatch: submitData,
          });
          this.returnToProgramsIndex(() => {
            this.$toasted.success("Retail program entry saved");
          });
        } catch (err) {
          this.buttonDisabled = false;
          this.$toasted.error(err.response?.data?.message || "Server Error");
          return;
        }
      } else {
        this.$toasted.error(
          "You do not have permission to update this retail program"
        );
      }
    },

    selectStatus(status) {
      this.selectedStatus = status;
    },

    selectPayMethod(method) {
      this.batchDetails.pay_method = method?.description;
      this.batchDetails.pay_method_id = method?.id;
    },

    toggleHistory() {
      this.showHistory = !this.showHistory;
    },

    async selectHistoryEntry(entry) {
      await this.getProgramBatchEdit({
        id: this.batchID,
        history: entry.id,
      });

      this.historyVersion = entry.id;
      this.$router.replace({ query: entry.id ? { history: entry.id } : {} });

      //set status
      this.selectedStatus = this.statusList.find(
        (s) => s.id == this.batchDetails.status_id
      ) ?? { id: null, description: "" };
    },

    returnToProgramsIndex(cb = () => {}) {
      this.$router.push(
        {
          name: "RetailPrograms",
          query: { ...this.programBatchFilters },
        },
        cb
      );
    },
  },
  directives: {},
};
</script>

<style lang="scss" scoped>
table,
td,
th {
  border: none;
}

table {
  table-layout: fixed;
}

th {
  padding-right: 1.5em;
  padding-bottom: 8px;
  // font-size: 14px;
}

.c-status {
  padding-top: 2px;
  padding-bottom: 2px;
}

.discard-link {
  font-weight: $font-weight-medium;
  font-size: 13px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #383838;
  cursor: pointer;
  margin-right: 25px;
}

.capitalizeDropdownInput /deep/ {
  input,
  button {
    text-transform: capitalize;
  }
}

.info-block {
  width: 100%;
  padding: 25px 30px;
  background: #ffffff;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  align-items: center;
  margin-top: 20px;

  .info {
    display: flex;
    flex-wrap: wrap;
    margin: -25px;
    margin-bottom: 0px;

    @media (max-width: 450px) {
      flex-direction: column;
    }

    > div {
      margin: 25px;
      margin-bottom: 0px;

      label {
        display: block;
        font-style: normal;
        font-weight: $font-weight-medium;
        font-size: 13px;
        line-height: 16px;
      }

      span {
        font-weight: $font-weight-medium;
        font-size: 13px;
        line-height: 19px;
        color: #5f5f5f;
      }
    }
  }
}

.retail-programs .main-section .form-group {
  display: grid;
}

@media print {
  /deep/ .c-status {
    color: black;
    border: 1px solid rgba(0, 0, 0, 0.7);
  }
}
</style>
