import { http } from '../../../api';

const laborCode = {
    namespaced: true,
    state: {
        laborsList: [],
        pagination: {},
        initialPage: 1,
    },
    actions: {
        getLabors: ({ commit }, params) => {
            const q = new URLSearchParams(params);
            return http.get('labors?' + q.toString()).then(res => {
                commit('setLabors', res.data.data.labors)
            });
        },
        getInitialPage: ({ commit }, params) => {
            commit('setInitialPage', params)
        },
    },
    mutations: {
        setInitialPage(state, page) {
            state.initialPage = page
        },
        setLabors(state, data) {
            state.laborsList = data.data
            state.pagination = data.pagination
        },
    },
    getters: {
        laborsList: state =>  state.laborsList,
        pagination: state =>  state.pagination,
        initialPage: state =>  state.initialPage
    }
};

export default laborCode;
