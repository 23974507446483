import { http } from '../../../api';

const emailLists = {
    namespaced: true,
    state: {
        lists: []
    },
    actions: {
        getEmailLists: ({ commit }) => {
            return http.get('email-lists')
                .then(res => {
                    console.log(res);
                    commit('setEmailLists', res.data.data.emails);
                    return res.data.data.emails;
                })
                .catch(error => {
                    throw error;
                });
        },
        updateEmailLists: ({ commit }, params) => {
            return http.post('email-lists', params)
                .then(res => {
                    return res;
                })
                .catch(error => {
                    throw error;
                });
        },
    },
    mutations: {
        setEmailLists(state, data) {
            state.lists = data
        },
    },
    getters: {
        lists: state =>  state.lists,
    }
};

export default emailLists;
