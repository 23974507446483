<template>
  <section class="registration-details col-span-12">
    <div class="c-printHeader">Warranty Registration</div>
    <div class="container">
      <PageHeader
        :saveButton="false"
        :discardLink="false"
        backText="Back to Registrations"
        :backRoute="{
          name: 'WarrantyRegistrations',
          query: { ...registrationFilters },
        }"
        :details="{
          title: '#' + registrationsDetails.primary_sn,
          status:
            registrationsDetails.status && registrationsDetails.status.name,
          date: registrationsDetails.date_registered,
          dateFormat: 'MMMM Do, YYYY',
        }"
      >
        <template v-slot:actions>
          <router-link
            v-if="
              registrationsDetails.status &&
              registrationsDetails.status.id === 1 &&
              user &&
              userCanFileClaim
            "
            tag="button"
            class="btn btn-info px-40"
            :to="
              '/claims/add/step-two?primary_sn=' +
              registrationsDetails.primary_sn
            "
            >File Claim</router-link
          >

          <router-link
            tag="button"
            class="btn btn-info px-4"
            :to="'/retail-programs/add?registration_id=' + $route.params.id"
          >
            Add Retail Program
          </router-link>
          <router-link
            v-if="user && userCanEdit"
            tag="button"
            class="btn btn-info px-40"
            :to="'/registrations/' + registrationsDetails.id + '/edit'"
            >Edit</router-link
          >
          <button
            type="button"
            class="btn btn-info px-40"
            @click="printWarrantyRegistration()"
          >
            Print
          </button>
        </template>
      </PageHeader>
      <div class="information">
        <div class="subtitle">Owner Information</div>
        <div class="info-block">
          <div class="info">
            <div>
              <div>Name</div>
              <span
                >{{ registrationsDetails.customer_fname }}
                {{ registrationsDetails.customer_lname }}</span
              >
            </div>
            <div>
              <div>Phone Number</div>
              <span>{{ formattedPhone }}</span>
            </div>
            <div v-if="registrationsDetails.customer_email">
              <div>Email Address</div>
              <span>{{ registrationsDetails.customer_email }}</span>
            </div>
            <div>
              <div>Address</div>
              <span>{{ registrationsDetails.customer_address }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="information">
        <div class="subtitle">Warranty Information</div>
        <div class="info-block">
          <div class="info">
            <div>
              <div>Warranty Type</div>
              <span>{{
                transformWarrantyType(registrationsDetails.warranty_type)
              }}</span>
            </div>
            <div>
              <div>Retail Dealership</div>
              <span v-if="registrationsDetails.dealership">{{
                registrationsDetails.dealership.name
              }}</span>
              <span v-else></span>
            </div>
            <div>
              <div>Registration Date</div>
              <span>{{ registrationsDetails.date_registered }}</span>
            </div>
            <div>
              <div>Sale Date</div>
              <span>{{ registrationsDetails.sale_date }}</span>
            </div>
            <div>
              <div>Warranty Expiration</div>
              <span>{{ registrationsDetails.expiration_date }}</span>
            </div>
            <div v-if="registrationsDetails.tractor_sn">
              <div>Tractor S/N</div>
              <span>{{ registrationsDetails.tractor_sn }}</span>
            </div>
            <div v-if="registrationsDetails.loader_sn">
              <div>Loader S/N</div>
              <span>{{ registrationsDetails.loader_sn }}</span>
            </div>
            <div v-if="registrationsDetails.backhoe_sn">
              <div>Backhoe SN</div>
              <span>{{ registrationsDetails.backhoe_sn }}</span>
            </div>
            <div v-if="registrationsDetails.mower_sn">
              <div>Mid Mount Mower</div>
              <span>{{ registrationsDetails.mower_sn }}</span>
            </div>
            <div v-if="registrationsDetails.cabin_sn">
              <div>Cabin</div>
              <span>{{ registrationsDetails.cabin_sn }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="information print:hidden">
        <div class="subtitle">Retail Programs</div>
        <div v-if="filedProgramBatches.length" class="info-block max-w-full overflow-x-scroll print:overflow-visible">
          <table>
            <thead>
              <th>Program Type</th>
              <th>Date</th>
              <th>Dealer</th>
              <th>Salesperson</th>
              <th>S/N(s)</th>
              <th>Claimed</th>
              <th>Paid</th>
              <th>Status</th>
            </thead>
            <tbody>
              <tr
                v-for="(batch, i) in filedProgramBatches"
                v-bind:key="'program_batch_' + i"
              >
                <td>{{ batch.program_type }}</td>
                <td>{{ batch.created_at }}</td>
                <td>{{ batch.dealership }}</td>
                <td>{{ batch.salesperson }}</td>
                <td class="max-w-xs">
                  {{
                    batch.serials.slice(0, 2).join(", \n") +
                    (batch.serials.length > 2 ? " ..." : "")
                  }}
                </td>
                <td>${{ batch.amount_claimed }}</td>
                <td>${{ batch.amount_adjusted }}</td>
                <td>
                  <span
                    class="c-status"
                    v-bind:class="{
                      'c-status--pending': batch.status.id == 1, //pending
                      'c-status--draft': batch.status.id == 2, //submitted
                      'c-status--blue': batch.status.id == 3, //reviewed
                      'c-status--paid': batch.status.id == 4, //approved
                      'c-status--paid': batch.status.id == 5, //paid
                      'c-status--rejected': batch.status.id == 6, //rejected
                    }"
                    >{{ batch.status.description }}</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="flex -m-2 mt-4">
          <router-link
            v-if="userCanFileRetailPrograms"
            tag="button"
            class="btn btn-info btn--outline m-2 px-2 print:hidden"
            :to="'/retail-programs/add?registration_id=' + $route.params.id"
          >
            + Add Retail Program
          </router-link>
        </div>
      </div>
      <div
        class="pt-border print:hidden"
        v-if="
          user.role.id !== 3 &&
          registrationsDetails.claim_history &&
          registrationsDetails.claim_history.length
        "
      >
        <div class="subtitle">Claim History</div>
        <div class="table-block">
          <data-table
            :data="registrationsDetails.claim_history"
            :header="claimHistory.header"
            :link="{ url: '/claims/', name: 'id' }"
          />
        </div>
      </div>

      <!------------------------------->
      <!------- PRINT VIEW ONLY ------->
      <!------------------------------->

      <!-- Warranty Dates -->
      <div class="hidden print:block mt-8 px-4 text-sm">
        <table>
          <thead>
            <tr>
              <th>Warranty Coverage</th>
              <th>Dates</th>
            </tr>
          </thead>
          <tbody class="text-center">
            <tr>
              <td><strong>Bumper-to-Bumper</strong></td>
              <td>
                  {{bumperToBumperDates}}
              </td>
            </tr>
            <tr>
              <td><strong>Parts and Labor (Drive Train Only)</strong></td>
              <td>
                  {{partsAndLaborDates}}
              </td>
            </tr>
            <tr v-if="Date.parse(registrationsDetails.sale_date) < Date.parse('04/01/2014')">
                <td>Drive Train Parts Only ($200.00 deductible per claim)</td>
                <td>
                    {{driveTrainPartsDates}}
                </td>
            </tr>
            <tr>
                <td colspan="2">Batteries and Tires are warranted by Manufacturer</td>
            </tr>
            <tr>
                <td colspan="2">Limited to 3000 Hours of Use</td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Signatures -->
      <div class="hidden print:block mt-8 px-4 text-sm">
        <p class="mb-4">
          <strong>For the customer:</strong> I acknowledge that I have read and I accepted the warranty terms & conditions as provided to me by above mentioned retail dealership.
        </p>
        <div class="flex justify-between mb-10">
          <div class="mr-8 flex-grow flex-shrink">Customer Signature ____________________________________ </div>
          <div class="mr-8 flex-grow flex-shrink">Signature Date ___________________ </div>
        </div>
        <p class="mb-4">
          <strong>For the dealer:</strong> I acknowledge that I have provided the customer with a copy of TYM-USA's warranty policy. I am responsible for maintaining a copy of the warranty registration form and that the information contained herein is accurate and complete. I understand that the warranty registration is auditable by TYMUSA at any time and failure to obtain both the customer's and dealer's signatures will result in the cancellation of the warranty policy and all applicable commissions.
        </p>
        <div class="flex justify-between">
          <div class="mr-8 flex-grow flex-shrink">Dealer Signature _______________________________________ </div>
          <div class="mr-8 flex-grow flex-shrink">Signature Date ___________________ </div>
        </div>
        <strong class="absolute bottom-0 right-0">Terms & Conditions on next page.</strong>
      </div>

      <!-- Terms And Conditions -->
      <div
        class="termsAndConditions hidden print:block"
        v-if="termsAndConditions.length"
      >
        <div class="subtitle">Terms & Conditions</div>
        <div
          v-for="(line, i) in termsAndConditions"
          :key="`terms_and_conditions_${i}`"
          class="italic mt-4 flex"
        >
          <span class="pt-2">_____</span>
          <div class="ml-3">
            {{line}}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../component/PageHeader";
import { mapActions, mapState } from "vuex";
import DataTable from "../../component/DataTable";
import { http } from "../../../api";

export default {
  name: "RegistrationDetail",
  props: {
    fromClaimId: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      background: "",
      color: "",
      border: "",
      registrationsDetails: {},
      claimHistory: {
        header: [
          {
            field: "id",
            name: "Claim #",
            transformToComponent: "TransformToLink",
          },
          { field: "date", name: "Claim Date" },
          { field: "primary_sn", name: "Serial #" },
          { field: "dealership", name: "Dealership" },
          {
            field: "original_cost",
            name: "Original $",
            transformField: "original",
            transform: this.transformToCost,
          },
          {
            field: "current_cost",
            name: "Current $",
            transformField: "current",
            transform: this.transformToCost,
          },
          { field: "last_update", name: "Last Update" },
          {
            field: "status.name",
            name: "Status",
            transformField: "status.id",
            transform: this.transformToStatus,
          },
          {
            transformField: "id",
            name: "Actions",
            transformToComponent: "ClaimsTableDropdown",
          },
        ],
      },
      termsAndConditions: [],
    };
  },
  components: {
    PageHeader,
    DataTable,
  },
  computed: {
    ...mapState({
      registrationEditObj: (state) => state.registration.registrationsDetails,
      filedProgramBatches: (state) => state.registration.program_batches,
      user: (state) => state.auth.user,
      registrationFilters: (state) => state.registration.indexFilters,
    }),
    userCanEdit: function () {
      return (
        this.user.permissions.includes("GLOBAL") ||
        this.user.permissions.includes("REG_EDIT")
      );
    },
    userCanFileClaim: function () {
      return (
        this.user.permissions.includes("GLOBAL") ||
        this.user.permissions.includes("CLAIM_CREATE")
      );
    },
    userCanFileRetailPrograms: function() {
      return this.registrationsDetails.user_can_file_programs;
    },
    formattedPhone: function() {
      if (this.registrationsDetails.customer_phone?.length != 10) return this.registrationsDetails.customer_phone;

      return `(${this.registrationsDetails.customer_phone.substring(0,3)}) ${this.registrationsDetails.customer_phone.substring(3,6)}-${this.registrationsDetails.customer_phone.substring(6,10)}`;
    },
    bumperToBumperDates() {
      const startDate = new Date(this.registrationsDetails.sale_date);
      const endDate = new Date(this.registrationsDetails.sale_date);
      endDate.setFullYear(endDate.getFullYear() + this.registrationsDetails.warranty_terms?.bb_year);

      const options = {}

      return `(${startDate.toLocaleDateString('en-us', options)} - ${endDate.toLocaleDateString('en-us', options)})`;
    },
    partsAndLaborDates() {
      const startDate = new Date(this.registrationsDetails.sale_date);
      const endDate = new Date(this.registrationsDetails.expiration_date);

      const options = {}

      return `(${startDate.toLocaleDateString('en-us', options)} - ${endDate.toLocaleDateString('en-us', options)})`;      
    },
    driveTrainPartsDates() {
      const startDate = new Date(this.registrationsDetails.sale_date);
      const endDate = new Date(this.registrationsDetails.sale_date);
      startDate.setFullYear(startDate.getFullYear() + 4);
      startDate.setDate(startDate.getDate() + 1);
      endDate.setFullYear(endDate.getFullYear() + this.registrationsDetails.warranty_terms?.pt_year);

      const options = {}

      return `(${startDate.toLocaleDateString('en-us', options)} - ${endDate.toLocaleDateString('en-us', options)})`;    
    }
  },
  async created() {
    let loaded = await this.getRegistrations();

    //if page loaded and view is print, open print dialogue (for newly created registrations)
    if (loaded && this.$route.query.view == 'print') {
      //remove print view from query
      let {view, query} = this.$route.query;
      this.$router.replace({query});

      window.print();
    }
    
  },
  destroyed() {
    this.registrationsDetails = {};
  },
  methods: {
    ...mapActions({
      getRegistrationsDetails: "registration/getRegistrationsDetails",
    }),
    async getRegistrations() {
      try {
        let res = await this.getRegistrationsDetails({ id: this.$route.params.id });

        this.registrationsDetails = this.registrationEditObj;
        this.termsAndConditions = res.terms_and_conditions;
        this.transformStatus();
        return true;
      } catch(err) {
        this.$toasted.error(err.response.data.message || "Server Error");
        return false;
      }
    },

    printWarrantyRegistration() {
      window.print();
      // http
      //   .get("registrations/" + id + "/pdf", {
      //     responseType: "blob",
      //   })
      //   .then((res) => {
      //     let blob = new Blob([res.data], { type: "application/pdf" });
      //     var fileURL = URL.createObjectURL(blob);
      //     window.open(fileURL);
      //   });
    },
    transformWarrantyType(wt) {
      const types = {
        C: "Commercial",
        S: "Stock",
        NC: "Non-Commercial",
        default: "Non-Commercial"
      }
      return types[wt] ?? types.default;
    },
    transformToStatus(id, val) {
      let background;
      let border;
      let color;
      let width;
      color = "#0B4067";
      switch (id) {
        case 1:
          background = "rgba(99, 220, 43, 0.3)";
          color = "#24610E";
          border = "none";
          width = "77px";
          break;
        case 2:
          background = "rgba(212, 4, 4, 0.2)";
          color = "#7D0505";
          border = "none";
          width = "77px";
          break;
        case 3:
          background = "rgba(215, 173, 65, 0.4)";
          color = "#5C4E00";
          border = "none";
          width = "67px";
          break;
        case 4:
          background = "#F9F9F9";
          color = "#606060";
          border = "1px solid #606060";
          width = "62px";
          break;
        case 5:
          background = "rgba(29, 184, 216, 0.2)";
          color = "#0B4067";
          border = "none";
          width = "95px";
          break;
        case 6:
          background = "rgba(212, 4, 4, 0.2)";
          color = "#7d0b67";
          border = "none";
          width = "100px";
          break;
      }
      return (
        '<div class="td-status" style="background-color:' +
        background +
        ";color:" +
        color +
        ";border:" +
        border +
        ";width:" +
        width +
        '">' +
        val +
        "</div>"
      );
    },
    transformToCost(id, val) {
      return "$" + val;
    },
    transformStatus() {
      switch (this.registrationsDetails.status.id) {
        case 6:
          this.background = "rgba(205, 216, 29, 0.2)";
          this.color = "#7c8110";
          this.border = "none";
          break;
        case 5:
          this.background = "rgba(29, 184, 216, 0.2)";
          this.color = "#0B4067";
          this.border = "none";
          break;
        case 4:
          this.background = "#F9F9F9";
          this.color = "#606060";
          this.this.border = "1px solid #606060";
          break;
        case 3:
          this.background = "rgba(215, 173, 65, 0.4)";
          this.color = "#5C4E00";
          this.border = "none";
          break;
        case 2:
          this.background = "rgba(212, 4, 4, 0.2)";
          this.color = "#7D0505";
          this.border = "none";
          break;
        case 1:
          this.background = "rgba(99, 220, 43, 0.3)";
          this.color = "#24610E";
          this.border = "none";
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.info-block {
  table,
  th,
  td {
    border: none;
  }

  th,
  td {
    padding-right: 20px;
    padding-bottom: 15px;
    font-size: 13px;
    line-height: 19px;
    font-weight: $font-weight-medium;

    @media print {
      font-size: 10px;
      line-height: 14px;

      .c-status {
        padding: 0;
        border: none;
      }
    }
  }

  tr:last-child td {
    padding-bottom: 0px;
  }

  th {
    text-align: left;
    font-style: normal;
    line-height: 16px;

    @media print {
      line-height: 12px;
    }
  }

  td {
    color: #5f5f5f;
    white-space: pre;
  }
}

.container /deep/ .c-pageHeader {
  .actions {
    @media print {
      display: none;
    }
  }
}

@media print {
  .termsAndConditions {
    page-break-before: always;
    // margin-top: 78px;
    padding-top: 24px;
  }

  .table-block /deep/ .tables {
    .data-table-head .table-head-container {
      width: 100%;

      .tr {
        margin-bottom: 0;
      }
    }

    .table .th, .table .td {
      font-size: 10px;
      padding: 0px 0px 5px;

      &:first-of-type {
        width: 8%;
      }

      &:last-of-type {
        display: none;
      }
      .td-status {
        padding: 0;
        border: none !important;
      }
    }

    .table .tbody  {
      border: none;
      .tr {
        width: 100%;
      }
    }
  }
}

</style>
