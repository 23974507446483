<template>
  <section class="manage col-span-12">
    <div class="container grid grid-cols-1 gap-0">
      <PageHeader
        title="Gallery"
        :backLink="false"
        :saveButton="false"
        :discardLink="false"
      />
      <div class="filter-block filter-block-border">
        <div>
          <div class="table-filter-search-input">
            <div class="form-group">
              <label class="control-label">Search</label>
              <input
                type="text"
                name="search"
                v-model="filterData.term"
                v-on:keyup.enter="applyFilter"
                class="search"
                placeholder=""
              />
              <inline-svg
                class="search-icon"
                :src="require('@/assets/image/icons/search.svg')"
              />
            </div>
          </div>
        </div>
        <div class="card">
          <div class="btn btn-black px-40" @click="applyFilter">
            <div class="text"><a>Apply</a></div>
          </div>
          <div class="btn redo px-15" @click="resetFilter">
            <a>
              <inline-svg :src="require('@/assets/image/icons/redo.svg')" />
            </a>
          </div>
        </div>
      </div>
      <div
        class="video-block ml-minus-15"
        v-if="gallery && Object.keys(gallery).length"
      >
        <div
          class="video-card ml-15"
          @click="openFolder(photos.slug, photos.count)"
          v-for="photos in gallery"
        >
          <div class="video">
            <img v-if="photos.img[0]" :src="photos.img[0].file" alt="" />
            <img v-else src="../../../assets/image/no-image.png" alt="" />
          </div>
          <div class="video-title">
            <p class="text-center">{{ photos.title }} ({{ photos.count }})</p>
          </div>
        </div>
      </div>
      <div v-else class="text-center mt-5">
        No results found. Please adjusts your search criteria message.
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../component/PageHeader";
import { mapActions, mapState } from "vuex";

export default {
  name: "Gallery",
  data() {
    return {
      filterData: {},
      pageActive: "active",
    };
  },
  computed: {
    ...mapState({
      gallery: (state) => state.gallery.gallery,
    }),
  },
  mounted() {
    if (this.$route.query.term) {
      this.filterData.term = this.$route.query.term;
      this.getGalleryFolderList(this.filterData);
    } else {
      this.getGallery();
    }
  },
  methods: {
    ...mapActions({
      getGalleryFolderList: "gallery/getGalleryFolderList",
    }),
    getGallery() {
      this.getGalleryFolderList()
        .then((res) => {})
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    },
    applyFilter() {
      this.$router.push(
        "/gallery?" + new URLSearchParams(this.filterData).toString()
      );
      this.getGalleryFolderList(this.filterData)
        .then((res) => {})
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    },
    resetFilter() {
      this.filterData = {};
      this.applyFilter();
    },
    openFolder(slug, count) {
      if (count > 0) {
        this.$router.push("/gallery-advertisements/" + slug);
      } else {
        this.$toasted.error("Gallery images not found.");
      }
    },
  },
  components: {
    PageHeader,
  },
};
</script>

<style lang="scss">
</style>
