<template>
  <div class="flex">
    <div class="icon-blue"  v-if="item.media_type === 'draft_claim'">
      <inline-svg v-if="item.action === 'update'" :src="require('@/assets/image/icons/claim-flag-update.svg')"/>
      <inline-svg v-else :src="require('@/assets/image/icons/claim-flag.svg')"/>
    </div>
    <div class="icon-blue"  v-if="item.media_type === 'registration'">
      <inline-svg v-if="item.action === 'update'" :src="require('@/assets/image/icons/bulletins-update.svg')"/>
      <inline-svg v-else :src="require('@/assets/image/icons/bulletins.svg')"/>
    </div>
    <div class="icon-red" v-if="item.media_type === 'claim'">
      <inline-svg v-if="item.action === 'update'" :src="require('@/assets/image/icons/claim-flag-update.svg')"/>
      <inline-svg v-else :src="require('@/assets/image/icons/claim-flag.svg')"/>
    </div>
    <div class="icon-green" v-if="item.media_type === 'order' ">
      <inline-svg v-if="item.action === 'update'" :src="require('@/assets/image/icons/order-basket-update.svg')"/>
      <inline-svg v-else :src="require('@/assets/image/icons/order-basket.svg')"/>
    </div>
    <div class="text">
      <div v-if="item.media_type === 'registration'">
        <div v-if="item.action === 'update'">
          <span class="link" v-if="linkText" @click="transformToLink(item.media_type,item.details.id)">{{linkText}}{{item.details.id}}</span> was <b>updated</b>
          for {{item.details.tractor_sn}} ({{item.details && item.details.dealership ? item.details.dealership.name : ''}}).
        </div>
        <div v-else>
          <span class="link" v-if="linkText" @click="transformToLink(item.media_type,item.details.id)">{{linkText}}{{item.details.id}}</span> was <b>submitted</b>
          for {{item.details.tractor_sn}} ({{item.details && item.details.dealership ? item.details.dealership.name : ''}}).
        </div>
        <span class="data">{{item.created_at}} / {{item.details && item.details.created_by ? item.details.created_by.name : ''}}</span>
      </div>
      <div v-else-if="item.media_type === 'order'">
        <div v-if="item.action === 'update'">
          <span class="link" v-if="linkText" @click="transformToLink(item.media_type,item.details.id)">{{linkText}}{{item.details.id}}</span> was <b>updated</b>
          for {{item.details && item.details.dealership ? item.details.dealership.name : ''}}.
        </div>
        <div v-else>
          <span class="link" v-if="linkText" @click="transformToLink(item.media_type,item.details.id)">{{linkText}}{{item.details.id}}</span> was <b>submitted</b>
          for {{item.details && item.details.dealership ? item.details.dealership.name : ''}}.
        </div>
        <span class="data">{{item.created_at}} / {{item.details && item.details.created_by ? item.details.created_by.name : ''}}</span>
      </div>
      <div v-else-if="item.media_type === 'claim'">
        <div v-if="item.action === 'update'">
          <span class="link" v-if="linkText" @click="transformToLink(item.media_type,item.details.id)">{{linkText}}{{item.details.id}}</span> was <b>submitted</b>
          for {{item.details.tractor_sn}} ({{item.details && item.details.dealership ? item.details.dealership.name : ''}}).
        </div>
        <div v-else>
          <span class="link" v-if="linkText" @click="transformToLink(item.media_type,item.details.id)">{{linkText}}{{item.details.id}}</span> was <b>updated</b>
          for {{item.details.tractor_sn}} ({{item.details && item.details.dealership ? item.details.dealership.name : ''}}).
        </div>
        <span class="data">{{item.created_at}} / {{item.details && item.details.created_by ? item.details.created_by.name : ''}}</span>
      </div>
      <div v-else-if="item.media_type === 'draft_claim'">
        <div>
          {{item.details.id}} Draft claim created by User
        </div>
        <span class="data">{{item.created_at}} / {{item.details && item.details.created_by ? item.details.created_by.name : ''}}</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "RecentActivity",
  props: ['item'],
  data () {
    return {
      linkText:'',
      historyText:'',
      tractorSn:'',
    }
  },
  mounted(){
    this.transformHistoryText(this.item)
  },
  methods:{
    transformHistoryText(item) {
      if(item.media_type === 'claim'){
        switch(item.action) {
          case 'delete':
            this.linkText = ''
            this.historyText = 'Claim ' + item.details.id + ' has been deleted on '
            break;
          case 'update':
            this.linkText = 'Claim '
            this.historyText = ' has been updated on '
            break;
          case 'create':
            this.linkText = 'Claim '
            this.historyText = ''
            this.tractorSn = item.details.tractor_sn
            break;
        }
      }else if(item.media_type === 'registration'){
        switch(item.action) {
          case 'delete':
            this.linkText = ''
            this.historyText = 'Registration ' + item.details.id + ' has been deleted on '
            break;
          case 'update':
            this.linkText = 'Registration '
            this.historyText = ' has been updated on '
            break;
          case 'create':
            this.linkText = 'Registration '
            this.historyText = ' has been created on '
            break;
        }
      }else if(item.media_type === 'order'){
        switch(item.action) {
          case 'delete':
            this.linkText = ''
            this.historyText = 'Order ' + item.details.id + ' has been deleted on '
            break;
          case 'update':
            this.linkText = 'Order '
            this.historyText = ' has been updated on '
            break;
          case 'create':
            this.linkText = 'Order '
            this.historyText = ' has been created on '
            break;
        }
      }
    },
    transformToLink(type,id){
      switch(type) {
        case 'claim':
          this.$router.push('/claims/' + id);
          break;
        case 'registration':
          this.$router.push('/registrations/' + id);
          break;
        case 'tractor_sn':
          this.$router.push('/registrations/' + id);
          break;
        case 'order':
          this.$router.push('/orders/' + id);
          break;
      }
    }
  }
}
</script>
