<template>
  <div>
    <h2 class="title">{{ title }}</h2>
    <p v-if="subtitle != ''" class="text">{{ subtitle }}</p>

    <template v-for="(programType, i) in availablePrograms">
      <ShowHideSection
        v-bind:key="'program_type_' + i"
        :title="`Apply for ${programType.name}`"
        :startOpen="startOpen"
        v-if="!types.length || types.includes(programType.id)"
      >
        <RetailProgramTypeForm
          :type="programType"
          :products="products"
          :salespeople="salespeople"
          :dealership="dealership"
          :ref="'bonuses_' + i"
        />
      </ShowHideSection>
    </template>
  </div>
</template>


<script>
import { mapActions, mapState } from "vuex";
import ShowHideSection from "../../ShowHideSection";
import RetailProgramTypeForm from "../RetailProgramTypeForm";

export default {
  name: "RetailProgramsForm",
  data() {
    return {};
  },
  props: {
    products: {
      type: Array,
      required: true,
    },
    saleDate: {
      type: String,
      required: false,
      default: null,
    },
    trackSaleDateChange: {
      type: Boolean,
      required: false,
      default: true,
    },
    types: {
      type: Array,
      required: false,
      default: function () {
        return [];
      },
    },
    title: {
      type: String,
      required: false,
      default: "Retail Programs",
    },
    subtitle: {
      type: String,
      required: false,
      default: "",
    },
    startOpen: {
      type: Boolean,
      required: false,
      default: false,
    },
    dealership: {
      type: Object,
      required: false,
      default() {
        return null;
      },
    },
  },
  components: {
    ShowHideSection,
    RetailProgramTypeForm,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      allSalespeople: (state) => state.programs.salespeople,
      availablePrograms: (state) => state.programs.availablePrograms,
    }),

    salespeople: function () {
      return [
        {
          name: "Pay to Dealer",
          id: null,
        },
        ...this.allSalespeople.filter((p) => {
          return !this.dealership?.id || p.dealership_id == this.dealership?.id;
        }),
      ];
    },

    userCanSubmit() {
      if (
        this.user.permissions?.includes("GLOBAL") ||
        this.user.permission_actions["Retail Program"]?.Create
      )
        return true;

      return false;
    },
    bonusTotal() {
      return Object.keys(this.$refs)
        .filter((ref) => ref.includes("bonuses_"))
        .reduce(
          (total, ref) => total + this.$refs[ref][0]?.bonus_total,
          0
        );
    }
  },

  watch: {
    dealership: function (dealership) {
      this.getSalespeople();
      
      this.getAvailablePrograms({date: this.saleDate, dealership_id: dealership?.id}).then(res => {
        this.updateFormData();
      });
    },
    saleDate: function(val) {
      if (!this.trackSaleDateChange) return;
      
      const prevTotal = this.bonusTotal;
      this.getAvailablePrograms({date: val, dealership_id: this.dealership?.id}).then(res => {
        this.updateFormData();

        if (this.products.length && prevTotal != this.bonusTotal) {
          this.$toasted.success("Retail Program entries have been updated to reflect the selected sale date.");
        }
      });
    },
    products: {
      async handler(newVal, oldVal) {
        await this.$nextTick();
        this.updateFormData();
      },
      deep: true,
    }
  },
  created() {
    this.getAvailablePrograms();
    this.getSalespeople();
  },

  methods: {
    ...mapActions({
      getAvailablePrograms: "programs/getAvailablePrograms",
      createProgramBatch: "programs/createProgramBatch",
      updateProgramBatch: "programs/updateProgramBatch",
      getSalespeople: "programs/getSalespeople",
    }),

    async saveRetailPrograms(registration_id) {
      //has permission to save program batch
      if (this.userCanSubmit) {
        const bonusData = Object.keys(this.$refs)
          .filter((ref) => ref.includes("bonuses_"))
          .map((ref) => this.$refs[ref][0]?.submitData)
          .filter((x) => x && x.lines?.length); // filter out null values

        if (!bonusData.length) return;

        const retailProgramsData = {
          registration_id: registration_id,
          program_batches: [...bonusData],
        };

        try {
          await this.createProgramBatch(retailProgramsData);
        } catch (err) {
          throw new Error(err.response?.data?.message ?? "SERVER ERROR");
        }
      } else {
        throw new Error(
          "You do not have permission to submit a retail program"
        );
      }
    },

    updateFormData() {
      Object.keys(this.$refs).forEach(ref => {
        this.$refs[ref][0]?.updateManualEntries();
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.c-dropdown-container {
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
}
</style>
