<template>
  <section class="add-user col-span-12">
    <ValidationObserver tag="div" class="container" ref="form">
      <PageHeader
        :title="isEdit ? 'Edit Media' : 'Add Media'"
        backText="Back to Mange Media"
        :backRoute="{ name: 'ManageMedia', query: { ...indexFilters } }"
        saveText="Save Media"
        @save="addMedia"
        @discard="showDiscardModal = true"
        :buttonsDisabled="buttonDisabled"
      />
      <div class="user-details">
        <div class="subtitle">Enter Media Details</div>
        <div class="user-block">
          <div class="user-block-container">
            <div class="form-group w-full">
              <label class="control-label">Title</label>
              <ValidationProvider
                rules="required"
                tag="div"
                v-slot="{ classes }"
              >
                <input
                  type="text"
                  name="name"
                  v-model="formData.title"
                  placeholder="Media Detail Title Here"
                  :class="classes"
                />
              </ValidationProvider>
            </div>
            <div class="user-type">
              <dropdown
                :data="createdMedia.types"
                :status="false"
                fieldName="name"
                label="Media Type"
                @select="selectMediaType"
                :validation="true"
                inputName="media_type"
                :editValue="selectedMediaType"
                :all="false"
                placeholder="Media Type"
              ></dropdown>
            </div>
            <div class="user-type" v-if="hasCategory">
              <dropdown
                :data="categories"
                :status="false"
                fieldName="name"
                label="Category"
                @select="selectCategory"
                :validation="true"
                inputName="category"
                :editValue="selectedCategory"
                :all="false"
                placeholder="Category"
              ></dropdown>
            </div>
            <div class="form-group w-full">
              <label class="control-label">File</label>
              <div class="add-file">
                <label for="files" id="btn" class="cursor-pointer">
                  <span>Choose File</span>
                  <input
                    id="files"
                    name="userfile"
                    class="invisible hidden"
                    @change="uploadCoverFile($event.target.files)"
                    type="file"
                  />
                </label>
                <p v-if="!formData.file">No File Chosen</p>
                <p v-else>
                  {{
                    this.formData.file.name
                      ? this.formData.file.name
                      : this.formData.file.path
                  }}
                </p>
              </div>
              <p class="text-red-500" v-if="errorMessageFiles">
                {{ errorMessageFiles }}
              </p>
            </div>
          </div>
        </div>
        <div class="send">
          <div class="edit-link" @click="showDiscardModal = true">Discard</div>
          <button
            class="btn btn-black px-60"
            type="button"
            @click="addMedia"
            :disabled="buttonDisabled"
            :class="buttonDisabled ? 'opacity-50 cursor-wait' : ''"
          >
            Save Media
          </button>
        </div>
      </div>
      <ConfirmModal
        text="Discard changes?"
        confirmBtn="Discard"
        :show="showDiscardModal"
        @confirm="returnToIndex"
        @close="showDiscardModal = false"
      />
    </ValidationObserver>
  </section>
</template>

<script>
import PageHeader from "../../../component/PageHeader";
import Dropdown from "../../../component/Dropdown";
import ConfirmModal from "../../../component/ConfirmModal";
import { mapActions, mapState } from "vuex";

export default {
  name: "addMedia",
  data() {
    return {
      selectedMediaType: "",
      selectedCategory: "",
      errorMessageFiles: "",
      isEdit: false,
      showDiscardModal: false,
      hasCategory: true,
      buttonDisabled: false,
      formData: {
        file: null,
        title: "",
      },
      categories: [],
    };
  },
  computed: {
    ...mapState({
      createdMedia: (state) => state.media.createdMedia,
      indexFilters: (state) => state.media.indexFilters,
    }),
  },
  created() {
    if (this.$route.params.id) {
      this.isEdit = true;
      this.editMedia();
    } else {
      this.isEdit = false;
      this.createMediaData().then(() => {
        this.categories = this.createdMedia.categories;
      });
    }
  },
  components: {
    PageHeader,
    Dropdown,
    ConfirmModal,
  },
  methods: {
    ...mapActions({
      createMediaData: "media/createMediaData",
      createMedia: "media/createMedia",
      editMediaData: "media/editMediaData",
      updateMedia: "media/updateMedia",
    }),
    editMedia() {
      this.editMediaData({ id: this.$route.params.id }).then((res) => {
        this.formData.title = this.createdMedia.media.title;
        this.selectedMediaType = this.createdMedia.media.type.media_type;
        this.formData.type = this.createdMedia.media.type.id;
        this.selectedCategory = this.createdMedia.media.category.media_type;
        this.formData.category = this.createdMedia.media.category.id;
        this.formData.file = this.createdMedia.media.file;
      });
    },
    addMedia() {
      if (this.formData.file) {
        this.errorMessageFiles = "";
      } else {
        this.$toasted.error("Your submission contains errors");
        this.errorMessageFiles = "Fill in the field";
        return false;
      }
      this.$refs.form
        .validate()
        .then((success) => {
          if (success) {
            let data = new FormData();
            for (var formData in this.formData) {
              data.append(formData, this.formData[formData]);
            }

            if (this.isEdit && this.formData.file.path) {
              data.delete("file");
            }

            this.buttonDisabled = true;

            if (this.isEdit) {
              this.updateMedia({ id: this.$route.params.id, formData: data })
                .then((res) => {
                  this.returnToIndex();
                })
                .catch((err) => {
                  this.buttonDisabled = false;
                  this.$toasted.error(
                    err.response.data.message || "Server Error"
                  );
                });
            } else {
              this.createMedia(data)
                .then((res) => {
                  this.returnToIndex();
                })
                .catch((err) => {
                  this.buttonDisabled = false;
                  this.$toasted.error(
                    err.response.data.message || "Server Error"
                  );
                });
            }
          } else {
            this.$toasted.error("Your submission contains errors");
          }
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    },
    selectMediaType(type) {
      this.selectedMediaType = type.name;
      this.formData.type = type.id;
      this.hasCategory = type.has_category;
      this.selectedCategory = "";
      if (this.hasCategory === false) {
        delete this.formData.category;
      } else {
        this.categories = this.createdMedia.categories.filter((res) => {
          return res.parent_id === type.id;
        });
      }
    },
    selectCategory(category) {
      this.selectedCategory = category.name;
      this.formData.category = category.id;
    },
    uploadCoverFile(file) {
      let uploadFile = file[0];
      this.formData.file = uploadFile;
      this.errorMessageFiles = "";
    },
    returnToIndex() {
      this.$router.push({
        name: "ManageMedia",
        query: { ...this.indexFilters },
      });
    },
  },
};
</script>

<style scoped>
</style>
