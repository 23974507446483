var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"claim view-original col-span-12"},[_c('div',{staticClass:"container"},[_c('PageHeader',{attrs:{"backText":"Back to Claims","backRoute":{ name: 'Claims' },"saveButton":false,"discardLink":false,"details":{
        title:
          '#' +
          (_vm.originalClaim.claim_details && _vm.originalClaim.claim_details.id),
        status: 'Original',
        date:
          _vm.originalClaim.claim_details &&
          _vm.originalClaim.claim_details.failure_date,
        fields: {
          'Primary S/N': _vm.originalClaim.tractor_details.tractor_sn || '',
        },
      }},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('button',{staticClass:"btn btn-info px-40",attrs:{"type":"button"},on:{"click":_vm.viewCurrent}},[_vm._v(" View Current ")]),_c('button',{staticClass:"btn btn-info px-40",attrs:{"type":"button"},on:{"click":_vm.printClaim}},[_vm._v(" Print ")])]},proxy:true}])}),_c('div',{staticClass:"details-block"},[_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"subtitle"},[_vm._v("Tractor Details")]),_c('div',{staticClass:"details"},[_c('div',{staticClass:"details-inform",class:_vm.originalClaim.tractor_details.owner_name ||
              _vm.originalClaim.tractor_details.owner_address ||
              _vm.originalClaim.tractor_details.owner_phone ||
              _vm.originalClaim.tractor_details.dealership
                ? 'details-mr'
                : ''},[(_vm.originalClaim.tractor_details.owner_name)?_c('div',[_c('div',[_vm._v("Customer Name")]),_c('span',[_vm._v(_vm._s(_vm.originalClaim.tractor_details.owner_name))])]):_vm._e(),(_vm.originalClaim.tractor_details.owner_address)?_c('div',[_c('div',[_vm._v("Address")]),_c('span',[_vm._v(_vm._s(_vm.originalClaim.tractor_details.owner_address))])]):_vm._e(),(_vm.originalClaim.tractor_details.owner_phone)?_c('div',[_c('div',[_vm._v("Phone Number")]),_c('span',[_vm._v(_vm._s(_vm.originalClaim.tractor_details.owner_phone))])]):_vm._e(),(
                _vm.originalClaim.tractor_details.dealership &&
                _vm.originalClaim.tractor_details.dealership.name
              )?_c('div',[_c('div',[_vm._v("Retail Dealership")]),_c('span',[_vm._v(_vm._s(_vm.originalClaim.tractor_details.dealership.name))])]):_vm._e()]),_c('div',{staticClass:"details-inform"},[_c('div',[_c('div',[_vm._v("Primary S/N")]),_c('span',[_vm._v(_vm._s(_vm.originalClaim.tractor_details.tractor_sn))])])])])]),_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"subtitle"},[_vm._v("Claim Details")]),_c('div',{staticClass:"details"},[_c('div',{staticClass:"details-mr details-inform"},[_c('div',[_c('div',[_vm._v("Dealer Placing Claim")]),(
                  _vm.originalClaim.claim_details &&
                  _vm.originalClaim.claim_details.delaer
                )?_c('span',[_vm._v(_vm._s(_vm.originalClaim.claim_details.delaer.name))]):_vm._e()]),_c('div',[_c('div',[_vm._v("Cause Code")]),_c('span',[_vm._v(_vm._s(_vm.originalClaim.claim_details.cause_code))])]),_c('div',[_c('div',[_vm._v("Failure Date")]),_c('span',[_vm._v(_vm._s(_vm.originalClaim.claim_details.failure_date))])]),_c('div',[_c('div',[_vm._v("Repair Date")]),_c('span',[_vm._v(_vm._s(_vm.originalClaim.claim_details.repair_date))])])]),_c('div',{staticClass:"details-inform"},[_c('div',[_c('div',[_vm._v("Hours Used")]),_c('span',[_vm._v(_vm._s(_vm.originalClaim.claim_details.hours_used))])]),_c('div',[_c('div',[_vm._v("Warranty Administrators Name")]),_c('span',[_vm._v(_vm._s(_vm.originalClaim.claim_details.administrator_name))])])])])])]),_c('div',{staticClass:"table-block"},[_c('div',{staticClass:"subtitle"},[_vm._v("Labor & Part Details")]),(_vm.originalClaim.labor_parts_description.labors)?_c('div',{staticClass:"tab"},[_c('div',{staticClass:"tables"},[_c('div',{staticClass:"table"},[_vm._m(0),_c('div',{staticClass:"tbody"},[(
                  _vm.originalClaim.labor_parts_description.labors.length == 0
                )?_c('div',{staticClass:"text-center no-result"},[_c('span',[_vm._v("No results found. Please adjust your search criteria.")])]):_vm._e(),_vm._l((_vm.originalClaim.labor_parts_description
                  .labors),function(labor,i){return _c('div',{key:i,staticClass:"tr",class:labor.parts.length > 1 ? 'i-start' : ''},[_c('div',{staticClass:"td"},[_vm._v(" "+_vm._s(labor.value)+" ")]),_c('div',{staticClass:"td"},[_vm._v(" "+_vm._s(labor.hours)+" ")]),_c('div',{staticClass:"td"},[_vm._v("$"+_vm._s(labor.cost))]),_c('div',{staticClass:"td"},[_vm._v("$"+_vm._s(labor.total))]),_c('div',{staticClass:"td-part"},_vm._l((labor.parts),function(part){return _c('div',{staticClass:"part"},[_c('div',{staticClass:"td"},[_vm._v(" "+_vm._s(part.invoice)+" ")]),_c('div',{staticClass:"td"},[_vm._v(" "+_vm._s(part.part)+" ")]),_c('div',{staticClass:"td"},[_vm._v(" "+_vm._s(part.partDescription)+" ")]),_c('div',{staticClass:"td"},[_vm._v("$"+_vm._s(part.partPrice))]),_c('div',{staticClass:"td"},[_vm._v(" "+_vm._s(part.qty.toFixed(2))+" ")]),_c('div',{staticClass:"td"},[_vm._v("$"+_vm._s(part.total))])])}),0)])})],2)])])]):_vm._e(),_c('div',{staticClass:"claim-totals"},[_c('div',{staticClass:"totals"},[_c('div',[_c('div',{staticClass:"flex justify-between pt-3 pl-4 pr-4"},[_c('div',[_vm._v("Labor Total")]),_c('div',[_vm._v(" $"+_vm._s(_vm.originalClaim.labor_parts_description.labor_subtotal)+" ")])]),_c('div',{staticClass:"flex justify-between pt-3 pl-4 pr-4"},[_c('div',[_vm._v("Parts Total")]),_c('div',[_vm._v(" $"+_vm._s(_vm.originalClaim.labor_parts_description.parts_subtotal)+" ")])])]),_c('div',{staticClass:"total"},[_c('div',{staticClass:"flex justify-between pt-3 pl-4 pr-4"},[_c('div',[_vm._v("Total")]),_c('div',[_vm._v("$"+_vm._s(_vm.originalClaim.labor_parts_description.total))])])])])])]),_c('div',{staticClass:"description-block",class:_vm.originalClaim &&
        _vm.originalClaim.activity_history &&
        _vm.originalClaim.activity_history.length === 0
          ? 'mb-20'
          : ''},[_c('div',[_c('div',{staticClass:"description"},[_c('div',{staticClass:"subtitle"},[_vm._v("Description of Failure")]),(_vm.originalClaim.description_of_failre)?_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.originalClaim.description_of_failre)+" ")]):_c('div',{staticClass:"text"},[_vm._v("No Description of Failure")])]),_c('div',{staticClass:"description"},[_c('div',{staticClass:"subtitle"},[_vm._v("Description of Repair")]),(_vm.originalClaim.description_of_repair)?_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.originalClaim.description_of_repair)+" ")]):_c('div',{staticClass:"text"},[_vm._v("No Description of Repair")])]),_c('div',{staticClass:"description"},[_c('div',{staticClass:"subtitle"},[_vm._v("Admin Notes")]),(_vm.originalClaim.admin_notes)?_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.originalClaim.admin_notes)+" ")]):_c('div',{staticClass:"text"},[_vm._v("No Notes")])])]),_c('div',{staticClass:"export-group"},[_c('div',{staticClass:"subtitle"},[_vm._v("File Attachments")]),(_vm.originalClaim.files && _vm.originalClaim.files.length)?_c('div',{staticClass:"button-group d-block"},_vm._l((_vm.originalClaim.files),function(file){return _c('button',{staticClass:"button",attrs:{"disabled":_vm.disableFileDownload.includes(file.id)},on:{"click":function($event){return _vm.downloadClaimFile(file)}}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"file-name"},[_vm._v(" "+_vm._s(file.slug .replace(/\s+/g, "_") .match(/.*(?=\.[^.]*$)/)[0] .substr(0, 20))+" ")]),_vm._v(" ."+_vm._s(file.slug.match(/[^\.]+$/)[0])+" ")]),(!_vm.disableFileDownload.includes(file.id))?_c('inline-svg',{attrs:{"src":require('@/assets/image/icons/download.svg')}}):_vm._e(),(_vm.disableFileDownload.includes(file.id))?_c('loading',{staticClass:"loading-dovnload"}):_vm._e()],1)}),0):_c('div',[_vm._v("No File")])])]),(
        _vm.originalClaim &&
        _vm.originalClaim.activity_history &&
        _vm.originalClaim.activity_history.length !== 0
      )?_c('div',{staticClass:"activity-history"},[_c('div',{staticClass:"subtitle"},[_vm._v("Claim Activity History")]),_c('div',{staticClass:"info-block"},_vm._l((_vm.originalClaim.activity_history),function(history){return _c('div',{staticClass:"info"},[_c('inline-svg',{attrs:{"src":require('@/assets/image/icons/history.svg')}}),(history.created_by)?_c('p',[_vm._v(_vm._s(history.created_by.email))]):_vm._e(),_vm._v(" "+_vm._s(history.description)+" on "+_vm._s(history.created_at)+" ")],1)}),0)]):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"data-table-head"},[_c('div',{staticClass:"table-head-container"},[_c('div',{staticClass:"tr"},[_c('div',{staticClass:"th text-center"},[_vm._v("Labor Code")]),_c('div',{staticClass:"th text-center"},[_vm._v("Labor Time")]),_c('div',{staticClass:"th text-center"},[_vm._v("Labor Cost")]),_c('div',{staticClass:"th text-center"},[_vm._v("Labor Total")]),_c('div',{staticClass:"th text-center"},[_vm._v("Invoice #")]),_c('div',{staticClass:"th text-center"},[_vm._v("Part #")]),_c('div',{staticClass:"th text-center"},[_vm._v("Part Description")]),_c('div',{staticClass:"th text-center"},[_vm._v("Per Part Cost")]),_c('div',{staticClass:"th text-center"},[_vm._v("Quantity")]),_c('div',{staticClass:"th text-center"},[_vm._v("Parts Total")])])])])}]

export { render, staticRenderFns }