import { http } from '../../api';
import Vue from 'vue';
import Toasted from 'vue-toasted';
import ToastedOptions from '../../utils/toasted.config';

Vue.use(Toasted, {
  ...ToastedOptions,
  duration: 2000,
});

export default function auth ({ next, store }){

  const auth = store.state.auth;

  if(!auth.isAuthenticated) {
    return next({
      name: 'Auth'
    })
  }

  //If the user version does not match, user will be logged out and require new login.
  if ((auth.user?.api_v ?? 0) < 1.0) {

    //log out
    localStorage.removeItem('access_token');
    localStorage.removeItem('user');
    localStorage.removeItem('vuex');
    http.defaults.headers.common.Authorization = '';
    auth.isAuthenticated = false;
    auth.access_token = null;
    auth.user = null;
    
    //show message
    Vue.toasted.success('The user portal has been updated and requires that you log in again.');
    localStorage.toastedId = Vue.toasted.id;

    //redirect
    return next({
      name: 'Auth'
    });
  }


  return next()
}
