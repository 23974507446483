<template>
  <section class="warranty-registrations col-span-12">
    <div class="container grid grid-cols-1 gap-0">
      <PageHeader
        title="Field Inventory"
        :backLink="false"
        :saveButton="false"
        :discardLink="false"
      >
        <template v-slot:actions>
          <button
            class="btn btn-info mr-3 px-15"
            @click="exportExcel"
            :disabled="disableExportExcel"
          >
            <loading v-if="disableExportExcel" />
            <div class="text mr-3"><span>Export</span></div>
            <div class="export">
              <inline-svg :src="require('@/assets/image/icons/download.svg')" />
            </div>
          </button>
        </template>
      </PageHeader>
      <div class="filter-block">
        <div>
          <div class="table-filter-search-input">
            <div class="form-group">
              <label class="control-label">Search</label>
              <input
                v-model="filterData.searchVal"
                type="text"
                name="search"
                value=""
                class="search"
                v-on:keyup.enter="applyFilter"
                placeholder="Tractor S/N"
              />
              <inline-svg
                class="search-icon"
                :src="require('@/assets/image/icons/search.svg')"
              />
            </div>
          </div>

          <div class="table-filter-dealership-select" v-if="user.role.id !== 3">
            <dropdown
              :data="dealerships"
              :status="false"
              fieldName="name"
              label="Dealer"
              @select="selectDealership"
              :validation="true"
              :all="true"
              :search="true"
              placeholder="Dealer"
              inputName="dealership"
              :editValue="selectedDealership"
              :placeholder="'Select Dealership'"
            ></dropdown>
          </div>
          <div class="table-filter-territory-select" v-if="user.role.id == 1">
            <dropdown
              :data="territoryManagers"
              :status="false"
              fieldName="name"
              label="Territory Manager"
              @select="selectTerritoryManager"
              :validation="true"
              :all="true"
              :search="true"
              placeholder="Territory Manager"
              inputName="territory_manager"
              :editValue="selectedTerritoryManager"
              :placeholder="'Select Territory Manager'"
            ></dropdown>
          </div>
          <div class="table-filter-status-select">
            <dropdown
              :data="inventoryStatuses"
              :status="true"
              fieldName="status"
              label="Status"
              @select="selectStatus"
              :validation="false"
              inputName="status"
              :editValue="selectedStatus"
            ></dropdown>
          </div>
        </div>
        <div class="card">
          <div
            class="btn btn-black cursor-pointer px-40"
            @click.prevent="applyFilter"
          >
            <div class="text"><a>Apply</a></div>
          </div>
          <div class="btn redo px-15" @click.prevent="resetFilter">
            <a>
              <inline-svg :src="require('@/assets/image/icons/redo.svg')" />
            </a>
          </div>
        </div>
      </div>
      <div class="table-block col-span-1">
        <data-table
          :page="filterData.page"
          :header="fieldInventoryTable.header"
          :data="inventory"
          :link="{ url: '/field-inventory/', name: 'tractor_sn' }"
          :isLoading="isLoading"
        />
      </div>
      <div class="pages" v-if="pagination.total && pagination.total_pages > 1">
        <div class="pages-quantity">
          <template v-if="!isLoading">
            Page {{ filterData.page }} of {{ pagination.total_pages }} - Showing
            {{ pagination.per_page }} Results of {{ pagination.total }} Total
          </template>
        </div>
        <paginate
          :page-count="pagination.total_pages"
          :page-range="4"
          :margin-pages="1"
          :initial-page="initialPage - 1"
          :click-handler="clickCallback"
          :prev-text="'&laquo;'"
          :next-text="'&raquo;'"
          :container-class="'pages-btn'"
          :active-class="pageActive"
        >
        </paginate>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../component/PageHeader";
import DataTable from "../../component/DataTable";
import Dropdown from "../../component/Dropdown";
import Loading from "../../component/Loading";
import { FunctionalCalendar } from "vue-functional-calendar";
import { http } from "../../../api";
import { mapActions, mapState } from "vuex";
import ClickOutside from "vue-click-outside";

export default {
  name: "FieldInventory",
  data() {
    return {
      pageActive: "active",
      // calendarData: {
      //   dateRange: {
      //     start: "",
      //     end: "",
      //   },
      // },
      isLoading: false,
      date: "",
      disableExportExcel: false,
      selectedDealership: "All",
      selectedTerritoryManager: "All",
      selectedStatus: "All",
      filterData: {
        page: 1,
      },

      fieldInventoryTable: {
        header: [
          {
            field: "tractor_sn",
            // transformField: "tractor_sn",
            name: "Tractor SN",
            width: "15%",
            // transformToComponent: "TransformToLink",
          },
          {
            field: "model",
            name: "Model",
            width: "20%",
            headerWidth: "18%",
          },
          {
            field: "dealership",
            name: "Dealership",
            width: "30%",
            headerWidth: "25%",
          },
          {
            field: "territory_manager",
            name: "Territory Manager",
          },
          {
            field: "status.name",
            name: "Status",
            transformField: "status.id",
            transform: this.transformToStatus,
          },
          {
            transformField: "tractor_sn",
            name: "Actions",
            transformToComponent: "InventoryTableDropdown",
          },
        ],
      },
    };
  },
  mounted() {
    this.isLoading = true;
    this.getFields()
      .then((res) => {
        if (this.$route.query.page) {
          if (this.$route.query.dealership_id) {
            let dealershipName = this.dealerships.filter(
              (item) => item.id == this.$route.query.dealership_id
            );
            this.selectedDealership = dealershipName[0].name;
            this.filterData.dealership_id = this.$route.query.dealership_id;
          }
          if (this.$route.query.page) {
            this.filterData.page = +this.$route.query.page;
            this.pageActive = "active";
          }
          if (this.$route.query.searchVal) {
            this.filterData.searchVal = this.$route.query.searchVal;
          }
          if (this.$route.query.status_id) {
            this.filterData.status_id = this.$route.query.status_id;
            let status = this.inventoryStatuses.filter(
              (item) => item.id == this.$route.query.status_id
            );
            this.selectedStatus = status[0].status;
          }
          if (this.$route.query.tm) {
            let territoryManager = this.territoryManagers.filter(
              (item) => item.id == this.$route.query.tm
            );
            this.selectedTerritoryManager = territoryManager[0].name;
            this.filterData.tm_id = this.$route.query.tm;
          }
          this.filterInventoryList();
        } else {
          this.getInventoryList().then(() => {
            if (this.$route.query.dealer) {
              this.dealerFilter();
            }
            if (this.$route.query.tm) {
              this.tmFilter();
            }
          });
        }
      })
      .catch((err) => {
        this.isLoading = false;
        this.$toasted.error(err.response.data.message || "Server Error");
      });
  },
  components: {
    PageHeader,
    Loading,
    DataTable,
    FunctionalCalendar,
    Dropdown,
  },
  computed: {
    ...mapState({
      inventory: (state) => state.inventory.inventory,
      pagination: (state) => state.inventory.pagination,
      dealerships: (state) => state.inventory.dealerships,
      inventoryStatuses: (state) => state.inventory.inventoryStatuses,
      territoryManagers: (state) => state.inventory.territoryManagers,
      user: (state) => state.auth.user,
      initialPage: (state) => state.inventory.initialPage,
    }),
  },
  methods: {
    ...mapActions({
      getFields: "inventory/getFields",
      getInventory: "inventory/getInventory",
      filterInventory: "inventory/filterInventory",
      getInitialPage: "inventory/getInitialPage",
    }),
    exportExcel() {
      if (!this.disableExportExcel) {
        this.disableExportExcel = true;
        const q = new URLSearchParams(this.filterData);
        http
          .get("field-inventory/export?" + q.toString(), {
            responseType: "blob",
          })
          .then((res) => {
            let blob = new Blob([res.data]);
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "field-inventory.xlsx";
            link.click();
          })
          .catch((err) => {
            this.$toasted.error("Inventory Export failed");
          })
          .finally(() => {
            this.disableExportExcel = false;
          });
      }
    },
    selectStatus(status) {
      if (status.id) {
        this.filterData.status_id = status.id;
      } else {
        delete this.filterData.status_id;
      }
      this.selectedStatus = status.status;
      this.applyFilter();
    },
    selectDealership(dealership) {
      //avoid multiple submissions:
      if (this.selectDealerWaiting) return;
      this.selectDealerWaiting = true;
      setTimeout(() => {
        this.selectDealerWaiting = false;
      }, 50);
      //-------------------------------//

      this.selectedDealership = dealership.name;
      if (dealership.id || dealership.id === 0) {
        this.filterData.dealership_id = dealership.id;
      } else {
        delete this.filterData.dealership_id;
      }
      this.applyFilter();
    },
    selectTerritoryManager(tm) {
      //avoid multiple submissions:
      //   if (this.selectDealerWaiting) return;
      //   this.selectDealerWaiting = true;
      //   setTimeout(() => {
      //     this.selectDealerWaiting = false;
      //   }, 50);
      //-------------------------------//

      this.selectedTerritoryManager = tm.name;
      if (tm.id || tm.id === 0) {
        this.filterData.tm_id = tm.id;
      } else {
        delete this.filterData.tm_id;
      }
      this.applyFilter();
    },
    dealerFilter() {
      let dealershipName = this.dealerships.filter(
        (item) => item.id == this.$route.query.dealer
      );
      this.selectedDealership = dealershipName[0].name;
      this.filterData.dealership_id = this.$route.query.dealer;
      this.filterInventoryList();
    },
    tmFilter() {
      let tm = this.territoryManagers.filter(
        (item) => item.id == this.$route.query.tm
      );
      this.filterData.tm_id = this.$route.query.tm;
      this.filterInventoryList();
    },
    resetFilter() {
      this.pageActive = "";
      this.selectedDealership = "All";
      this.selectedStatus = "All";
      this.selectedTerritoryManager = "All";
      this.filterData = {
        page: 1,
      };
      this.getInitialPage(1);
      this.applyFilter();
    },
    filterInventoryList() {
      this.isLoading = true;
      this.filterInventory(this.filterData)
        .then((res) => {})
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    applyFilter() {
      this.pageActive = "";
      this.filterData.page = 1;
      this.$router.push(
        "/field-inventory?" + new URLSearchParams(this.filterData).toString()
      );

      this.filterInventoryList();
    },
    getInventoryList() {
      this.filterData.page = this.initialPage;
      this.isLoading = true;
      return this.getInventory({ page: this.filterData.page })
        .then((res) => {
          console.log(this.inventory);
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getDotColor(status) {
      switch (status) {
        case 1:
          return "#A7CBE5";
        case 2:
          return "#EA9797";
        case 3:
          return "#B1D69F";
        case 5:
          return "#EBDBB0";
        case 6:
          return "#606060";
      }
    },
    transformToStatus(id, val) {
      let background;
      let border;
      let color;
      let width;
      color = "#0B4067";
      switch (id) {
        case 1: //green
          background = "rgba(99, 220, 43, 0.3)";
          color = "#24610E";
          border = "none";
          width = "77px";
          break;
        case 2: //red
          background = "rgba(212, 4, 4, 0.2)";
          color = "#7D0505";
          border = "none";
          width = "77px";
          break;
        case 3: //yellow
          background = "rgba(215, 173, 65, 0.4)";
          color = "#5C4E00";
          border = "none";
          width = "67px";
          break;
        case "null": //draft
          background = "#F9F9F9";
          color = "#606060";
          border = "1px solid #606060";
          width = "62px";
          break;
        case 5: //blue
          background = "rgba(29, 184, 216, 0.2)";
          color = "#0B4067";
          border = "none";
          width = "95px";
          break;
        case 6: //red
          background = "rgba(212, 4, 4, 0.2)";
          color = "#7d0b67";
          border = "none";
          width = "100px";
          break;
      }
      return (
        '<div class="td-status" style="background-color:' +
        background +
        ";color:" +
        color +
        ";border:" +
        border +
        ";width:" +
        width +
        '">' +
        val +
        "</div>"
      );
    },
    clickCallback(pageNum) {
      this.filterData.page = pageNum;
      this.getInitialPage(pageNum);
      this.$router.push(
        "/field-inventory?" + new URLSearchParams(this.filterData).toString()
      );
      this.filterInventoryList();
      this.pageActive = "active";
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style scoped>
</style>
