<template>
  <section class="manage col-span-12">
    <div class="container grid grid-cols-1 gap-0">
      <PageHeader
        title="Manage Media"
        :saveButton="false"
        :discardLink="false"
        :addButton="true"
        addText="Add New Media"
        @add="$router.push({ name: 'addMedia' })"
        backText="Back to Settings"
        :backRoute="{ name: 'AdminSettings' }"
      >
        <template v-slot:actions>
          <router-link
            tag="button"
            :to="{ name: 'ManageCategories' }"
            class="btn btn-info px-15 mr-3"
            >Manage Categories</router-link
          >
        </template>
      </PageHeader>
      <div class="filter-block">
        <div>
          <div class="table-filter-search-input">
            <div class="form-group">
              <label class="control-label">Search</label>
              <input
                v-model="filterData.media"
                v-on:keyup.enter="filter"
                type="text"
                name="search"
                value=""
                class="search"
                placeholder="Media"
              />
              <inline-svg
                class="search-icon"
                :src="require('@/assets/image/icons/search.svg')"
              />
            </div>
          </div>
          <div class="table-filter-dealership-select">
            <dropdown
              :data="types"
              :status="false"
              fieldName="name"
              label="Media Type"
              @select="selectMediaType"
              :validation="false"
              inputName="media_type"
              :editValue="selectedMediaType"
            ></dropdown>
          </div>
          <div class="table-filter-dealership-select">
            <dropdown
              :data="categories"
              :status="false"
              fieldName="name"
              label="Category"
              @select="selectCategory"
              :validation="false"
              inputName="category"
              :editValue="selectedCategory"
            ></dropdown>
          </div>
        </div>
        <div class="card">
          <div class="btn btn-black px-40" @click="filter">
            <div class="text"><a>Apply</a></div>
          </div>
          <div class="btn redo px-15" @click="resetFilter">
            <a>
              <inline-svg :src="require('@/assets/image/icons/redo.svg')" />
            </a>
          </div>
        </div>
      </div>
      <div class="table-block col-span-1">
        <data-table
          :page="filterData.page"
          :header="fieldMediaTable.header"
          :data="media"
          :link="{ url: '/admin/media/', name: 'title' }"
          class="media-table"
          :isLoading="isLoading"
        />
      </div>
      <div class="pages" v-if="pagination.total && pagination.total_pages > 1">
        <div class="pages-quantity">
          Page {{ filterData.page }} of {{ pagination.total_pages }} - Showing
          {{ pagination.count }} Results of {{ pagination.total }} Total
        </div>
        <paginate
          v-if="pagination.total_pages"
          :page-count="pagination.total_pages"
          :page-range="6"
          :margin-pages="1"
          :initial-page="initialPage - 1"
          :click-handler="clickCallback"
          :prev-text="'&laquo;'"
          :next-text="'&raquo;'"
          :container-class="'pages-btn'"
          :active-class="pageActive"
        >
        </paginate>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../../component/PageHeader";
import DataTable from "../../../component/DataTable";
import Dropdown from "../../../component/Dropdown";
import { mapActions, mapState } from "vuex";

export default {
  name: "ManageMedia",
  data() {
    return {
      baseUrl: "/admin/media",
      isLoading: false,
      selectedMediaType: "All",
      selectedCategory: "All",
      fieldMediaTable: {
        header: [
          {
            field: "title",
            name: "Media Title",
            transformToComponent: "TransformToLink",
            width: "22%",
            headerWidth: "21%",
          },
          { field: "type.media_type", name: "Media Type" },
          { field: "category.media_type", name: "Category" },
          { field: "posted_on", name: "Posted On" },
          {
            field: "id",
            name: "Actions",
            transformToComponent: "MediaDropdownSettings",
          },
        ],
      },
      filterData: {
        page: 1,
      },
      pageActive: "active",
    };
  },
  components: {
    PageHeader,
    DataTable,
    Dropdown,
  },
  computed: {
    ...mapState({
      media: (state) => state.media.media,
      types: (state) => state.media.types,
      categories: (state) => state.media.categories,
      pagination: (state) => state.media.pagination,
      initialPage: (state) => state.media.initialPage,
    }),
  },
  async mounted() {
    await this.getMediaList();

    if (this.$route.query.page) {
      if (this.$route.query.media) {
        this.filterData.media = this.$route.query.media;
      }
      if (this.$route.query.type_id) {
        this.filterData.type_id = this.$route.query.type_id;
        let type = this.types.find(
          (item) => item.id == this.$route.query.type_id
        );
        this.selectedMediaType = type?.name;
      }
      if (this.$route.query.category_id) {
        this.filterData.category_id = this.$route.query.category_id;
        let category = this.categories.find(
          (item) => item.id == this.$route.query.category_id
        );
        this.selectedCategory = category?.name;
      }
      this.filterData.page = +this.$route.query.page;
      this.getInitialPage(this.filterData.page);
      this.isLoading = true;
      this.filterMedia(this.filterData).finally(() => {
        this.isLoading = false;
      });
    } else {
      // this.getMediaList();
    }
  },
  methods: {
    ...mapActions({
      getMedia: "media/getMedia",
      filterMedia: "media/filterMedia",
      getInitialPage: "media/getInitialPage",
      setFilterState: "media/setIndexFilters",
    }),
    async getMediaList() {
      this.filterData.page = this.initialPage;
      this.isLoading = true;
      try {
        await this.getMedia({ page: this.filterData.page });
      } catch (err) {
        this.$toasted.error(err.response.data.message || "Server Error");
      } finally {
        this.isLoading = false;
      }
    },
    filter() {
      this.updateRouter();
      this.isLoading = true;
      this.filterMedia(this.filterData)
        .then((res) => {})
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    resetFilter() {
      this.pageActive = "";
      this.filterData = {
        page: 1,
      };
      this.selectedMediaType = "All";
      this.selectedCategory = "All";
      this.filterData.type_id = "";
      this.filterData.category_id = "";
      this.filterData.media = "";
      this.getInitialPage(1);
      this.filter();
    },
    selectMediaType(mt) {
      this.filterData.page = 1;
      this.filterData.type_id = mt.id;
      this.selectedMediaType = mt.name;
      if (mt.id) {
        this.filterData.type_id = mt.id;
      } else {
        delete this.filterData.type_id;
      }
      this.filter();
    },
    selectCategory(category) {
      this.filterData.page = 1;
      this.filterData.category_id = category.id;
      this.selectedCategory = category.name;
      if (category.id) {
        this.filterData.category_id = category.id;
      } else {
        delete this.filterData.category_id;
      }
      this.filter();
    },
    clickCallback(pageNum) {
      this.getInitialPage(pageNum);
      this.filterData.page = pageNum;
      this.pageActive = "active";
      this.filter();
    },
    updateRouter(saveState = true) {
      this.$router.push(
        this.baseUrl + "?" + new URLSearchParams(this.filterData).toString()
      );
      if (saveState) this.setFilterState(this.filterData);
    },
  },
};
</script>

<style lang="scss">
.media-table {
  .data-table-head {
    .tr {
      .th:first-of-type {
        text-align: left;
        padding-left: 12px;
      }
    }
  }
  .tbody {
    .tr {
      .td:first-of-type {
        justify-content: flex-start;
        span {
          text-align: left;
          padding-left: 12px;
        }
      }
    }
  }
}
</style>
