<template>
  <section class="manage col-span-12">
    <div class="container grid grid-cols-1 gap-0">
      <PageHeader
        title="Manage Old Warranty Claims"
        :saveButton="false"
        :discardLink="false"
        backText="Back to Settings"
        :backRoute="{ name: 'AdminSettings' }"
      />
      <div class="filter-block">
        <div>
          <div class="table-filter-search-input">
            <div class="form-group">
              <label class="control-label">Search</label>
              <input
                type="text"
                name="search"
                v-model="filterData.searchVal"
                v-on:keyup.enter="applyFilter"
                class="search"
                placeholder="Tractor S/N or Claim #"
              />
              <inline-svg
                class="search-icon"
                :src="require('@/assets/image/icons/search.svg')"
              />
            </div>
          </div>
          <div class="table-filter-dealership-select">
            <dropdown
              :data="oldClaimDealerships"
              :status="false"
              fieldName="name"
              label="Dealership"
              :validation="true"
              :all="true"
              :search="true"
              placeholder="Dealership"
              @select="selectDealer"
              inputName="dealer"
              :editValue="selectedDealer"
            ></dropdown>
          </div>
          <div class="table-filter-status-select">
            <dropdown
              :data="oldClaimStatuses"
              :status="true"
              fieldName="status"
              label="Status"
              @select="selectStatus"
              :validation="false"
              inputName="status"
              :editValue="status"
            ></dropdown>
          </div>
          <div class="form-group calendar">
            <div>
              <label class="control-label">Claim Date</label>
              <div class="relative">
                <functional-calendar
                  :placeholder="' '"
                  v-model="calendarData"
                  :sundayStart="true"
                  :date-format="'mm/dd/yyyy'"
                  :is-modal="true"
                  :is-date-range="true"
                  :is-multiple="true"
                  :calendars-count="2"
                  :change-year-function="true"
                ></functional-calendar>
                <inline-svg
                  class="date-down"
                  :src="require('@/assets/image/icons/date-down.svg')"
                />
                <span class="dash">-</span>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="btn btn-black px-40" @click="applyFilter">
            <div class="text"><a>Apply</a></div>
          </div>
          <div class="btn redo px-15" @click="resetFilter">
            <a>
              <inline-svg :src="require('@/assets/image/icons/redo.svg')" />
            </a>
          </div>
        </div>
      </div>
      <div class="table-block col-span-1">
        <data-table
          :page="filterData.page"
          :header="fieldManageOldWarrantyClaimsTable.header"
          :data="oldClaims"
          :link="{ url: '/admin/old-warranty-claims/', name: 'id' }"
          class="media-table"
          :isLoading="isLoading"
        />
      </div>
      <div class="pages" v-if="pagination.total_pages > 1">
        <div class="pages-quantity">
          <template v-if="!isLoading">
            Page {{ filterData.page }} of {{ pagination.total_pages }} - Showing
            {{ pagination.per_page }} Results of {{ pagination.total }} Total
          </template>
        </div>
        <paginate
          v-if="pagination.total_pages"
          :page-count="pagination.total_pages"
          :page-range="6"
          :margin-pages="1"
          :initial-page="oldClaimInitialPage - 1"
          :click-handler="clickCallback"
          :prev-text="'&laquo;'"
          :next-text="'&raquo;'"
          :container-class="'pages-btn'"
          :active-class="pageActive"
        >
        </paginate>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../../component/PageHeader";
import DataTable from "../../../component/DataTable";
import Dropdown from "../../../component/Dropdown";
import { mapActions, mapState } from "vuex";

export default {
  name: "ManageOldWarrantyClaims",
  data() {
    return {
      baseUrl: "/admin/old-warranty-claims",
      isLoading: false,
      fieldManageOldWarrantyClaimsTable: {
        header: [
          {
            field: "id",
            name: "Claim No.",
            transformToComponent: "TransformToLink",
          },
          { field: "tractor_sn", name: "Tractor S/N" },
          { field: "owner_name", name: "Owner Name" },
          { field: "dealership", name: "Dealership" },
          { field: "claim_date", name: "Claim Date" },
          {
            field: "status",
            name: "Status",
            transformField: "status",
            transform: this.transformToStatus,
          },
          {
            field: "total_cost",
            name: "Total Cost (Adjusted)",
            width: "11.5%",
            headerWidth: "10%",
          },
        ],
      },
      calendarData: {
        dateRange: {
          start: "",
          end: "",
        },
      },
      filterData: {
        page: 1,
      },
      pageActive: "active",
      selectedDealer: "All",
      status: "All",
    };
  },
  components: {
    PageHeader,
    DataTable,
    Dropdown,
  },
  computed: {
    ...mapState({
      oldClaims: (state) => state.claim.oldClaims,
      pagination: (state) => state.claim.oldClaimsPagination,
      oldClaimStatuses: (state) => state.claim.oldClaimStatuses,
      oldClaimDealerships: (state) => state.claim.oldClaimDealerships,
      oldClaimInitialPage: (state) => state.claim.oldClaimInitialPage,
    }),
  },
  mounted() {
    if (this.$route.query.page) {
      if (this.$route.query.searchVal) {
        this.filterData.searchVal = this.$route.query.searchVal;
      }
      if (this.$route.query.dealership_id) {
        this.filterData.dealership_id = this.$route.query.dealership_id;
        let dealer = this.oldClaimDealerships.filter(
          (item) => item.id == this.$route.query.dealership_id
        );
        this.selectedDealer = dealer[0].name;
      }
      if (this.$route.query.status_id) {
        this.filterData.status_id = this.$route.query.status_id;
        let status = this.oldClaimStatuses.filter(
          (item) => item.id == this.$route.query.status_id
        );
        this.status = status[0].status;
      }
      if (this.$route.query.start_claim) {
        this.calendarData.dateRange.start = this.$route.query.start_claim;
        this.filterData.start_claim = this.$route.query.start_claim;
      }
      if (this.$route.query.end_claim) {
        this.calendarData.dateRange.end = this.$route.query.end_claim;
        this.filterData.end_claim = this.$route.query.end_claim;
      }
      this.filterData.page = +this.$route.query.page;
      this.getOldClaimInitialPage(this.filterData.page);
      this.isLoading = true;
      this.filterOldClaims(this.filterData).finally(() => {
        this.isLoading = false;
      });
    } else {
      this.getOldClaimsList();
    }
  },
  methods: {
    ...mapActions({
      getOldClaims: "claim/getOldClaims",
      filterOldClaims: "claim/filterOldClaims",
      getOldClaimInitialPage: "claim/getOldClaimInitialPage",
      setFilterState: "claim/setOldClaimIndexFilters",
    }),
    applyFilter() {
      if (this.calendarData.dateRange != null) {
        if (this.calendarData.dateRange.start) {
          this.filterData.start_claim = this.calendarData.dateRange.start;
        }
        if (this.calendarData.dateRange.end) {
          this.filterData.end_claim = this.calendarData.dateRange.end;
        }
      }
      this.updateRouter();

      this.isLoading = true;
      this.filterOldClaims(this.filterData)
        .then((res) => {})
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    resetFilter() {
      this.pageActive = "";
      this.selectedDealer = "All";
      this.status = "All";
      this.filterData = {
        page: 1,
      };
      if (this.calendarData.dateRange) {
        this.calendarData.dateRange.end = "";
        this.calendarData.dateRange.start = "";
      }
      this.getOldClaimInitialPage(1);
      this.applyFilter();
    },
    getOldClaimsList() {
      this.filterData.page = this.oldClaimInitialPage;
      this.isLoading = true;
      this.getOldClaims({ page: this.filterData.page })
        .then((res) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    clickCallback(pageNum) {
      this.getOldClaimInitialPage(pageNum);
      this.filterData.page = pageNum;
      this.pageActive = "active";
      this.applyFilter();
    },
    transformToStatus(id, val) {
      let background;
      let border;
      let color;
      let width;
      color = "#0B4067";
      switch (val) {
        case "Approved":
          background = "rgba(99, 220, 43, 0.3)";
          color = "#24610E";
          border = "none";
          width = "77px";
          break;
        case "Cancelled":
          background = "rgba(212, 4, 4, 0.2)";
          color = "#7D0505";
          border = "none";
          width = "77px";
          break;
        case "Pending":
          background = "rgba(215, 173, 65, 0.4)";
          color = "#5C4E00";
          border = "none";
          width = "67px";
          break;
      }
      return (
        '<div class="td-status" style="background-color:' +
        background +
        ";color:" +
        color +
        ";border:" +
        border +
        ";width:" +
        width +
        '">' +
        val +
        "</div>"
      );
    },
    selectDealer(dealer) {
      if (dealer.name === "All") {
        this.selectedDealer = dealer.name;
        delete this.filterData.dealership_id;
      } else {
        this.selectedDealer = dealer.name;
        this.filterData.dealership_id = dealer.id;
      }
      this.filterData.page = 1;
      this.pageActive = "";
      this.applyFilter();
    },
    selectStatus(status) {
      if (status.status === "All") {
        this.status = status.status;
        delete this.filterData.status_id;
      } else {
        this.status = status.status;
        this.filterData.status_id = status.id;
      }
      this.filterData.page = 1;
      this.pageActive = "";
      this.applyFilter();
    },
    updateRouter(saveState = true) {
      this.$router.push(
        this.baseUrl + "?" + new URLSearchParams(this.filterData).toString()
      );
      if (saveState) this.setFilterState(this.filterData);
    },
  },
};
</script>

<style scoped>
.manage .container .filter-block .table-filter-status-select .form-group {
  width: 140px !important;
}
.manage .container .filter-block .table-filter-dealership-select .form-group {
  width: 195px !important;
}
</style>
