<template>
  <section class="user-details col-span-12">
    <div class="container manage-container">
      <PageHeader
        backText="Back to Videos"
        :backRoute="{ name: 'Videos' }"
        :saveButton="false"
        :discardLink="false"
        :details="{
          title: videoItem.title,
          date: videoItem.posted_on,
          dateFormat: 'MMMM Do, YYYY',
        }"
      >
        <template v-slot:actions>
          <button
            :readonly="disableDownloadVideo"
            class="btn btn-info px-15"
            type="button"
            @click="download(videoItem.id)"
          >
            <loading v-if="disableDownloadVideo" />
            <div class="text">Download Video</div>
            <div class="create">
              <inline-svg :src="require('@/assets/image/icons/download.svg')" />
            </div>
          </button>
        </template>
      </PageHeader>
      <div class="video-card" v-if="videoItem && videoItem.youtube_url">
        <div class="video">
          <iframe
            width="654"
            height="368"
            :src="transformToLink(videoItem.youtube_url)"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          >
          </iframe>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../component/PageHeader";
import { mapActions, mapState } from "vuex";
import { http } from "@/api";
import Loading from "../../component/Loading";

export default {
  name: "VideoDetail",
  data() {
    return {
      disableDownloadVideo: false,
    };
  },
  computed: {
    ...mapState({
      videoItem: (state) => state.videos.videoItem,
    }),
  },
  mounted() {
    this.getVideoItem({ id: this.$route.params.id });
  },
  destroyed() {
    this.videoItem.youtube_url = "";
    this.videoItem.title = "";
    this.videoItem.posted_on = "";
  },
  components: {
    PageHeader,
    Loading,
  },
  methods: {
    ...mapActions({
      getVideoItem: "videos/getVideoItem",
    }),
    download(id) {
      this.disableDownloadVideo = true;
      let url = "/videos/" + id + "/youtube-download";
      http
        .get(url, {
          responseType: "blob",
        })
        .then((res) => {
          let blob = new Blob([res.data], {
            type: res.headers["content-type"],
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "video";
          link.click();
        })
        .catch((err) => {
          if (err.response.status === 404) {
            this.$toasted.error(
              "File could not be located on server. Please contact TYM for assistance."
            );
          } else {
            this.$toasted.error(err.response.data.message || "Server Error");
          }
        })
        .finally(() => {
          this.disableDownloadVideo = false;
        });
    },
    transformToLink(link) {
      var rx =
        /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
      let linkArray = link.match(rx);
      let id = linkArray[1];
      let youtubeLink = "https://www.youtube.com/embed/" + id;
      return youtubeLink;
    },
  },
};
</script>

<style scoped>
</style>
