import { http } from '../../../api';

const salesDocumentation = {
    namespaced: true,
    state: {
        sales: [],
        types: [],
    },
    actions: {
        getSalesDocumentationList: ({ commit }, params) => {
            const q = new URLSearchParams(params);
            return http.get('/sales?' + q.toString()).then(res => {
                commit('setSalesDocumentation', res.data.data)
          });
        },
    },
    mutations: {
        setSalesDocumentation(state, data){
            state.sales = data.sales;
            state.types = data.types;
        },
    },
    getters: {
        sales: state => state.sales,
        types: state => state.types,
    }
};

export default salesDocumentation;
