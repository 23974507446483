<template>
    <div class="auth">
        <div class="container">
            <div class="logo">
                <img
                    class="m-auto"
                    :src="require('@/assets/image/logo.png')"
                    alt="Tym-Tractors"
                    :srcset="`${require('@/assets/image/logo.png')},
                            ${require('@/assets/image/logo@2x.png')} 2x`"
                />
            </div>
            <div class="panel">
                <div class="panel-heading">Send Password Reset Email</div>
                <ValidationObserver tag="div" class="panel-body" ref="form">
                    <form class="edit-form" @submit.prevent="submit">
                        <div class="form-group">
                            <label class="control-label">Email Address</label>
                            <ValidationProvider rules="required|email" tag="div" v-slot="{ classes }">
                                <input type="email" name="email" value="" :class="classes" v-model="email">
                            </ValidationProvider>
                        </div>
                        <div class="form-group">
                            <div class="formBtn">
                                <button type="submit" class="btn">Submit</button>
                                <router-link to="/login" class="note">Return to Log In</router-link>
                            </div>
                        </div>
                        <div v-if="loading" class="text-center mt-3">
                            <Loading position="center" />
                        </div>
                        <div v-else class="mt-2">
                            <div class="error-msg" v-if="errorMessage">
                                <p>{{errorMessage}}</p>
                            </div>
                            <div class="success-msg" v-if="successMessage">
                                <p>{{successMessage}}</p>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import Loading from '../../../component/Loading.vue';

    export default {
        name: "ForgotPassword",

        data() {
            return {
                email: "",
                errorMessage: null,
                successMessage: null,
                loading: false,
            }
        },

        methods: {
            ...mapActions({
                sendForgotPasswordEmail: "auth/sendForgotPasswordEmail",
            }),
            async submit() {
                this.errorMessage = null;
                this.successMessage = null;
                this.loading = true;

                try {
                    const res = await this.sendForgotPasswordEmail(this.email);
                    this.successMessage = res.data?.message;
                } catch(error) {
                    this.errorMessage = error.message ?? "Email could not be sent.";
                } finally {
                    this.loading = false;
                }
            }
        },

        components: {
            Loading,
        }
    }
</script>

<style scoped>

</style>