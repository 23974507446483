<template>
  <section class="manage col-span-12">
    <div class="container grid grid-cols-1 gap-0">
      <PageHeader
        title="Brochures"
        :backLink="false"
        :saveButton="false"
        :discardLink="false"
      />
      <div class="filter-block filter-block-border">
        <div>
          <div class="table-filter-search-input">
            <div class="form-group">
              <label class="control-label">Search</label>
              <input
                type="text"
                name="search"
                v-model="filterData.term"
                v-on:keyup.enter="applyFilter"
                class="search"
                placeholder=""
              />
              <inline-svg
                class="search-icon"
                :src="require('@/assets/image/icons/search.svg')"
              />
            </div>
          </div>
        </div>
        <div class="card">
          <div class="btn btn-black px-40" @click="applyFilter">
            <div class="text"><a>Apply</a></div>
          </div>
          <div class="btn redo px-15" @click="resetFilter">
            <a>
              <inline-svg :src="require('@/assets/image/icons/redo.svg')" />
            </a>
          </div>
        </div>
      </div>
      <div class="video-block ml-minus-15" v-if="brochures && brochures.length">
        <div class="video-card ml-15" v-for="brochure in brochures">
          <div class="video">
            <img :src="brochure.thumbnail" alt="" />
          </div>
          <div class="video-title">
            <p>{{ brochure.title }}</p>
            <button class="btn btn-info px-15 mt-4" @click="download(brochure)">
              <loading v-if="disableIndex.includes(brochure.id)" />
              <div class="text">Download</div>
              <div class="create">
                <inline-svg
                  :src="require('@/assets/image/icons/download.svg')"
                />
              </div>
            </button>
          </div>
        </div>
      </div>
      <div v-else class="text-center mt-5">
        No results found. Please adjusts your search criteria message.
      </div>
      <div class="pages" v-if="brochuresPagination.total_pages > 1">
        <div class="pages-quantity">
          Page {{ filterData.page }} of {{ brochuresPagination.total_pages }} -
          Showing {{ brochuresPagination.count }} Results of
          {{ brochuresPagination.total }} Total
        </div>
        <paginate
          v-if="brochuresPagination.total_pages"
          :page-count="brochuresPagination.total_pages"
          :page-range="6"
          :initial-page="initialPage - 1"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="'&laquo;'"
          :next-text="'&raquo;'"
          :container-class="'pages-btn'"
          :active-class="pageActive"
        >
        </paginate>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../component/PageHeader";
import { mapActions, mapState } from "vuex";
import { http } from "@/api";
import Loading from "../../component/Loading";

export default {
  name: "Brochures",
  data() {
    return {
      filterData: {
        page: 1,
        term: "",
      },
      pageActive: "active",
      disableIndex: [],
    };
  },
  components: {
    PageHeader,
    Loading,
  },
  computed: {
    ...mapState({
      brochures: (state) => state.gallery.brochures,
      brochuresPagination: (state) => state.gallery.brochuresPagination,
      initialPage: (state) => state.gallery.initialPage,
    }),
  },
  mounted() {
    if (this.$route.query.page) {
      if (this.$route.query.term) {
        this.filterData.term = this.$route.query.term;
      }
      this.filterData.page = this.$route.query.page;
      this.getBrochuresList(this.filterData);
    } else {
      this.getBrochures();
    }
  },
  methods: {
    ...mapActions({
      getBrochuresList: "gallery/getBrochuresList",
      getInitialPage: "gallery/getInitialPage",
    }),
    getBrochures() {
      this.filterData.page = this.initialPage;
      this.getBrochuresList(this.filterData)
        .then((res) => {})
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    },
    applyFilter() {
      this.$router.push(
        "/brochures?" + new URLSearchParams(this.filterData).toString()
      );
      this.getBrochuresList(this.filterData)
        .then((res) => {})
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    },
    resetFilter() {
      this.pageActive = "";
      this.filterData = {
        page: 1,
      };
      this.getInitialPage(1);
      this.applyFilter();
    },
    clickCallback(pageNum) {
      this.getInitialPage(pageNum);
      this.filterData.page = pageNum;
      this.pageActive = "active";
      this.getBrochures();
    },
    download(url) {
      this.disableIndex.push(url.id);
      let file = url.file;
      http
        .get(file, {
          responseType: "blob",
        })
        .then((res) => {
          let blob = new Blob([res.data], {
            type: res.headers["content-type"],
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = url.title;
          link.click();
        })
        .catch((err) => {
          if (err.response.status === 404) {
            this.$toasted.error(
              "File could not be located on server. Please contact TYM for assistance."
            );
          }
        })
        .finally(() => {
          this.disableIndex = this.disableIndex.filter((i) => i !== url.id);
        });
    },
  },
};
</script>

<style lang="scss">
</style>
