<template>
    <iframe :src="apiBase + $route.params.type + '/' + $route.params.file_name" style="width: 100%; height: 100vh"></iframe>
</template>

<script>
export default{
  name: "File",
  data: () => ({
    apiBase: process.env.VUE_APP_WEB_API.replace('api/v1/', '')
  })
}
</script>

<style scoped>
</style>
