<template>
  <section class="manage col-span-12">
    <div class="container grid grid-cols-1 gap-0">
      <PageHeader
        title="Manage Users"
        :saveButton="false"
        :discardLink="false"
        :addButton="true"
        addText="Add New User"
        @add="$router.push({ name: 'addUser', params: { id: 'add' } })"
        backText="Back to Settings"
        :backRoute="{ name: 'AdminSettings' }"
      />
      <div class="filter-block">
        <div>
          <div class="table-filter-search-input">
            <div class="form-group">
              <label class="control-label">Search</label>
              <input
                type="text"
                name="search"
                @keyup.enter="applyFilter"
                v-model="filterData.username"
                value=""
                class="search"
                placeholder="Username"
              />
              <inline-svg
                class="search-icon"
                :src="require('@/assets/image/icons/search.svg')"
              />
            </div>
          </div>
          <div class="table-filter-dealership-select" v-if="userDealershipList">
            <dropdown
              :data="userDealershipList"
              :status="false"
              fieldName="name"
              label="Dealer"
              :validation="true"
              :all="true"
              :search="true"
              placeholder="Dealer"
              @select="selectDealer"
              inputName="territory_manager"
              :editValue="selectedDealer"
              v-if="user.permissions.includes('GLOBAL') || user.role.id !== 3"
            ></dropdown>
          </div>
          <div
            class="table-filter-dealership-select user-type"
            v-if="userTypesList"
          >
            <dropdown
              :data="userTypesList"
              :status="false"
              fieldName="name"
              label="User Type"
              @select="selectUserType"
              :validation="false"
              inputName="user_types"
              :editValue="selectedUserType"
            ></dropdown>
          </div>
          <div
            class="table-filter-dealership-select user-type"
            v-if="userPermissionsList"
          >
            <dropdown
              :data="userPermissionsList"
              :status="false"
              fieldName="name"
              label="User Permission"
              @select="selectUserPermission"
              :search="true"
              :validation="false"
              inputName="user_permission"
              :editValue="selectedUserPermission"
            ></dropdown>
          </div>
        </div>
        <div class="card">
          <div class="btn btn-black px-40" @click.prevent="applyFilter">
            <div class="text">Apply</div>
          </div>
          <div class="btn redo px-15" @click.prevent="resetFilter">
            <inline-svg :src="require('@/assets/image/icons/redo.svg')" />
          </div>
        </div>
      </div>
      <div
        class="
          table-block
          col-span-1
          sm:col-span-1
          md:col-span-1
          lg:col-span-1
          xl:col-span-1
        "
      >
        <data-table
          v-if="users.data"
          v-bind:page="filterData.page"
          :header="fieldUsersTable.header"
          :data="users.data"
          :link="{ url: '/admin/users/', name: 'name' }"
          class="media-table"
          :isLoading="isLoading"
        />
      </div>
      <div
        class="pages"
        v-if="
          users &&
          users.pagination &&
          users.pagination.total &&
          users.pagination.total_pages > 1
        "
      >
        <div class="pages-quantity">
          Page {{ filterData.page }} of {{ users.pagination.total_pages }} -
          Showing {{ users.pagination.per_page }} Results of
          {{ users.pagination.total }} Total
        </div>
        <paginate
          v-if="users.pagination"
          :page-count="users.pagination.total_pages"
          :page-range="6"
          :margin-pages="1"
          :initial-page="initialPage - 1"
          :click-handler="clickCallback"
          :prev-text="'&laquo;'"
          :next-text="'&raquo;'"
          :container-class="'pages-btn'"
          :active-class="pageActive"
        >
        </paginate>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../../component/PageHeader";
import DataTable from "../../../component/DataTable";
import Dropdown from "../../../component/Dropdown";
import { mapActions, mapState } from "vuex";

export default {
  name: "ManageUsers",
  data() {
    return {
      baseUrl: "/admin/users",
      isLoading: false,
      selectedDealer: "All",
      selectedUserType: "All",
      selectedUserPermission: "All",
      fieldUsersTable: {
        header: [
          {
            field: "name",
            name: "Name",
            transformToComponent: "TransformToLink",
            headerWidth: "15%",
          },
          { field: "email", name: "Email", width: "30%", headerWidth: "18%" },
          { field: "dealer", name: "Dealer", headerWidth: "18%" },
          {
            field: "role.role",
            name: "User Type",
            transform: this.transformUserRole,
            width: "20%",
            class: ["capitalize"],
            headerWidth: "11%",
          },
          {
            field: "role.group",
            name: "User Group",
            transform: this.transformUserRole,
            width: "20%",
            class: ["capitalize"],
            headerWidth: "19%",
          },
          {
            field: "status",
            name: "Status",
            transformField: "status",
            transform: this.transformToStatus,
            headerWidth: "10%",
          },
          {
            field: "y",
            name: "Actions",
            transformToComponent: "ManageUsersDropdownSettings",
            headerWidth: "9%",
          },
        ],
      },
      filterData: {
        page: 1,
      },
      pageActive: "active",
    };
  },
  components: {
    PageHeader,
    DataTable,
    Dropdown,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      users: (state) => state.user.users,
      userDealershipList: (state) => state.user.userDealershipList,
      userTypesList: (state) => state.user.userTypesList,
      initialPage: (state) => state.user.initialPage,
      userPermissionsList: (state) => state.user.userPermissionsList,
    }),
  },
  mounted() {
    if (this.$route.query.page) {
      this.isLoading = false;
      this.getUsersData()
        .then((res) => {
          if (this.$route.query.username) {
            this.filterData.username = this.$route.query.username;
          }
          if (
            this.$route.query.dealership_id ||
            this.$route.query.dealership_id === 0
          ) {
            let dealer = this.userDealershipList.find(
              (item) => item.id == this.$route.query.dealership_id
            );

            this.selectedDealer = dealer.name;
            this.filterData.dealership_id = dealer.id;
          }
          if (this.$route.query.type) {
            let userType = this.userTypesList.find(
              (item) => item.id == this.$route.query.type
            );

            this.selectedUserType = userType.name;
            this.filterData.type = userType.id;
          }
          if (this.$route.query.permission) {
            let perm = this.userPermissionsList.find(
              (item) => item.code == this.$route.query.permission
            );

            this.selectedUserPermission = perm.name;
            this.filterData.permission = perm.code;
          }
          this.filterData.page = parseInt(this.$route.query.page);
          this.getInitialPage(this.filterData.page);
          this.isLoading = true;
          this.filterUsers(this.filterData)
            .then((res) => {})
            .catch((err) => {
              this.$toasted.error(err.response.data.message || "Server Error");
            })
            .finally(() => {
              this.isLoading = false;
            });
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.getUsersList();
    }
  },
  methods: {
    ...mapActions({
      getUsers: "user/getUsers",
      getUsersData: "user/getUsersData",
      filterUsers: "user/filterUsers",
      getInitialPage: "user/getInitialPage",
      setFilterState: "user/setIndexFilters",
    }),
    transformToStatus(status, val) {
      let background;
      let border;
      let color;
      let width;
      color = "#0B4067";
      switch (status) {
        case "Active":
          background = "rgba(99, 220, 43, 0.3)";
          color = "#24610E";
          border = "none";
          width = "77px";
          break;
        case "Inactive":
          background = "rgba(212, 4, 4, 0.2)";
          color = "#7D0505";
          border = "none";
          width = "77px";
          break;
      }
      return (
        '<div class="td-status" style="background-color:' +
        background +
        ";color:" +
        color +
        ";border:" +
        border +
        ";width:" +
        width +
        '">' +
        (status === "Active" ? "Active" : "Inactive") +
        "</div>"
      );
    },
    transformUserRole(id, value) {
      if (!value) return "";
      let text = "";
      value.split("_").map((item) => {
        text += " " + item;
      });
      return text.toLowerCase();
    },
    selectDealer(dealer) {
      this.selectedDealer = dealer.name;

      if (dealer.id || dealer.id === 0) {
        this.filterData.dealership_id = dealer.id;
      } else {
        delete this.filterData.dealership_id;
      }
      this.applyFilter();
    },
    getUsersList() {
      this.filterData.page = this.initialPage;
      this.isLoading = true;
      this.filterUsers(this.filterData)
        .then((res) => {})
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    selectUserType(userType) {
      this.selectedUserType = userType.name;
      if (userType.id) {
        this.filterData.type = userType.id;
      } else {
        delete this.filterData.type;
      }
      this.applyFilter();
    },
    selectUserPermission(userPerm) {
      this.selectedUserPermission = userPerm.name;
      if (userPerm.code) {
        this.filterData.permission = userPerm.code;
      } else {
        delete this.filterData.permission;
      }
      this.applyFilter();
    },
    clickCallback(pageNum) {
      this.filterData.page = pageNum;
      this.updateRouter();

      this.getInitialPage(parseInt(pageNum));
      this.pageActive = "active";
      this.isLoading = true;
      this.filterUsers(this.filterData)
        .then((res) => {})
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    applyFilter() {
      this.filterData.page = 1;
      this.pageActive = "";
      // if (this.$route.fullPath == newRoute) return;
      this.updateRouter();

      this.isLoading = true;
      this.filterUsers(this.filterData)
        .then((res) => {})
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    resetFilter() {
      this.pageActive = "";
      this.selectedDealer = "All";
      this.selectedUserType = "All";
      this.selectedUserPermission = "All";
      this.filterData = {
        page: 1,
      };
      this.getInitialPage(1);
      this.applyFilter();
    },

    updateRouter(saveState = true) {
      this.$router.push(
        this.baseUrl + "?" + new URLSearchParams(this.filterData).toString()
      );
      if (saveState) this.setFilterState(this.filterData);
    },
  },
};
</script>

<style scoped>
</style>
