<template>
  <section class="col-span-12 retail-programs">
    <ValidationObserver tag="div" ref="form" class="container">
      <PageHeader
        title="Add Retail Program"
        backText="Back to Retail Programs"
        :backRoute="{ name: 'RetailPrograms' }"
        @discard="showDiscardModal = true"
        @save="submitRetailProgram"
        :canSave="userCanFilePrograms"
        saveText="Submit"
        :buttonsDisabled="buttonDisabled"
      />
      <div class="main-section">
        <div class="flex flex-col items-start tablet:flex-row">
          <div>
            <h2 class="title">Dealer and Serial Number Information</h2>
            <div class="text">
              Enter Dealer and Serial Number of Associated Tractor
            </div>
            <div class="input-group" id="warrantyRegistration">
              <div v-if="user.role.id !== 3 || user.permissions.includes('GLOBAL')">
                <dropdown
                  :data="dealerships"
                  :status="false"
                  fieldName="name"
                  label="Dealership"
                  @select="selectDealership($event)"
                  :all="true"
                  :search="true"
                  placeholder="Dealership"
                  inputName="dealership"
                  :editValue="selectedDealership && selectedDealership.name"
                ></dropdown>
              </div>
              <div
                class="warranty-dropdown form-group mt-1"
                v-click-outside="hideWarrSearch"
              >
                <label class="control-label">Serial Number</label>

                <div class="relative">
                  <ValidationProvider
                    rules="required"
                    tag="div"
                    v-slot="{ classes }"
                  >
                    <input
                      type="text"
                      class="input pl-3 pr-12 cursor-text uppercase"
                      :class="classes"
                      v-model="warrantySearch.search"
                      autocomplete="off"
                      @input="searchWarranties"
                      @focus="showWarrSearch"
                    />
                  </ValidationProvider>
                  <inline-svg
                    class="m-auto down pointer-events-none"
                    :src="require('@/assets/image/icons/chevron-down.svg')"
                  />
                  <div class="sns-dropdown dropdown" v-if="warrantySearch.show">
                    <template v-if="warrantySearch.results.length > 0">
                      <button
                        v-for="(res, i) in warrantySearch.results"
                        v-bind:key="'warranty_' + i"
                        @click="selectWarrantyRegistration(res)"
                      >
                        {{ res.primary_sn }}
                      </button>
                    </template>
                    <div v-else style="cursor: default; background: unset">
                      <p>No Results Found</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-12 tablet:mt-0 tablet:ml-24" v-if="registeredSerials.length">
            <h3 class="mb-4 text-xl font-medium">Registered Items</h3>
            <div class="c-callout">
              <div class="pr-8">
                <table class="border-0 text-sm">
                  <tr
                    v-for="(serial, i) in registeredSerials"
                    v-bind:key="`product_${i}`"
                  >
                    <td><strong class="capitalize">{{ serial.type }}:</strong></td>
                    <td class="pl-2 text-gray-800 font-medium">{{ serial.serial }}</td>
                    <td class="pl-6"><strong>Model:</strong></td>
                    <td class="pl-2 text-gray-800 font-medium">{{ serial.model }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-section" style="max-width: 1200px">
        <div id="retail-programs" class="mb-10" v-show="!registrationIsStock">
          <RetailProgramsForm
            :dealership="selectedDealership"
            :products="allSelectedProducts"
            :saleDate="registrationDetails.sale_date"
            :trackSaleDateChange="false"
            ref="retailProgramsForm"
            :startOpen="true"
          />
        </div>
        <div
          v-if="registrationIsStock"
          class="text mb-8"
        >
          {{ registrationStockMessage }}
        </div>
        <div>
          <div class="flex items-center">
            <button
              @click="submitRetailProgram"
              :readonly="buttonDisabled || !userCanFilePrograms"
              v-bind:class="{
                'opacity-50 cursor-wait' : buttonDisabled,
                'opacity-50 cursor-not-allowed' : !userCanFilePrograms
              }"
              type="button"
              class="btn btn-black px-40"
            >
              Submit
            </button>
            <div class="discard-link ml-4" @click="showDiscardModal = true">
              Discard
            </div>
          </div>
        </div>
      </div>
      <ConfirmModal
        text="Discard changes?"
        confirmBtn="Discard"
        :show="showDiscardModal"
        @confirm="returnToProgramsIndex"
        @close="showDiscardModal = false"
      />
    </ValidationObserver>
  </section>
</template>

<script>
import PageHeader from "../../component/PageHeader";
import Dropdown from "../../component/Dropdown";
import Loading from "../../component/Loading";
import { mapActions, mapState } from "vuex";
import ConfirmModal from "../../component/ConfirmModal";
import RetailProgramsForm from "../../component/retailPrograms/RetailProgramsForm";
import ClickOutside from "vue-click-outside";

export default {
  name: "RetailProgramsAdd",
  data() {
    return {
      selectedWarrantyId: null,
      selectedDealership: { id: null, name: "All" },
      buttonDisabled: false,
      allSelectedProducts: [],
      registeredSerials: [],

      showDiscardModal: false,

      warrantySearch: {
        search: "",
        show: false,
        results: [],
        timeout: null,
      },

      registrationStockMessage: "This registration is registered for Stock, and not currently eligible for retail programs.",

      isLoading: false,
    };
  },
  components: {
    PageHeader,
    Loading,
    Dropdown,
    ConfirmModal,
    RetailProgramsForm,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      dealerships: (state) => state.programs.dealerships,
      registrationList: (state) => state.registration.registrationList.filter(
        r => r.user_can_file_programs
      ),
      registrationDetails: (state) => state.registration.registrationsDetails,
      programBatchFilters: (state) => state.programs.programBatchFilters,
    }),
    userCanFilePrograms() {
      return this.registrationDetails.user_can_file_programs && !this.registrationIsStock;
    },
    registrationIsStock() {
      return 's' == this.registrationDetails?.warranty_type?.toLowerCase();
    }
  },
  watch: {
    registrationList: function (val, oldVal) {
      this.warrantySearch.results = [...val];
      this.showWarrSearch();
    },
  },
  async created() {
    if (this.user.role.id == 3)
      this.selectedDealership = {
        id: this.user.dealership_id,
        name: null,
      };

    try {
      await this.getCreateProgramBatch();
    } catch (err) {
      this.$router.push("/retail-programs", () => {
        setTimeout(() => {
          this.$toasted.error(err.response.data.message || "Server Error");
        }, 200);
      });
    }

    const regId = this.$route.query.registration_id;

    if (regId) {
      //set selected warranty and dealership
      this.selectedWarrantyId = regId;

      await this.setProducts(regId);

      clearTimeout(this.warrantySearch.timeout);
      this.warrantySearch = {
        timeout: null,
        show: false,
        search: this.registrationDetails.primary_sn,
        results: [
          {
            id: regId,
            primary_sn: this.registrationDetails.primary_sn,
          },
        ],
      };
    } else {
      await this.getRegistrations();

      this.$nextTick((_) => {
        this.$nextTick((_) => {
          this.warrantySearch.show = false;
        });
      });
    }
  },
  methods: {
    ...mapActions({
      getCreateProgramBatch: "programs/getCreateProgramBatch",
      getRegistrationList: "registration/getRegistrationList",
      getRegistrationsDetails: "registration/getRegistrationsDetails",
      getProgramInfoBySns: "programs/getProgramInfoBySns",
    }),

    async getRegistrations(search = "") {
      await this.getRegistrationList({
        search: search,
        dealership_id: this.selectedDealership?.id,
      });
    },

    async submitRetailProgram() {
      console.log(this.buttonDisabled);
      console.log(this.userCanFilePrograms);
      if (this.buttonDisabled || !this.userCanFilePrograms) return;

      this.buttonDisabled = true;

      //submit retail programs
      try {
        //validation
        if (!(await this.$refs.form.validate())) {
          this.$toasted.error("Your submission contains errors");
          this.buttonDisabled = false;
          return;
        }

        await this.$refs.retailProgramsForm?.saveRetailPrograms(
          this.selectedWarrantyId
        );
        this.returnToProgramsIndex(() => {
          this.$toasted.success("New retail program entry created");
        });
      } catch (err) {
        this.buttonDisabled = false;
        this.$toasted.error(
          err || "Server Error"
        );
        return;
      }
    },

    selectDealership(dealership) {
      this.selectedDealership = dealership == "noItem" ? null : dealership;
      this.searchWarranties();
    },

    selectWarrantyRegistration(data) {
      this.selectedWarrantyId = data.id;
      this.setProducts(data.id);

      this.warrantySearch = {
        ...this.warrantySearch,
        show: false,
        search: data.primary_sn,
      };
    },

    searchWarranties() {
      const ws = this.warrantySearch;
      ws.results = [...this.registrationList].filter((item) =>
        item.primary_sn?.toLowerCase().includes(ws.search.toLowerCase())
      );

      clearTimeout(ws.timeout);

      ws.timeout = setTimeout(() => {
        ws.timeout = null;
        this.getRegistrations(ws.search);
      }, 500);
    },

    hideWarrSearch() {
      this.warrantySearch.show = false;
    },

    showWarrSearch() {
      this.warrantySearch.show = false;

      //only show if there are results,
      //hide if too many results and short input
      if (
        this.warrantySearch.results.length <= 50 ||
        this.warrantySearch.search.length >= 4
      )
        this.warrantySearch.show = true;
    },

    async setProducts(registrationID) {
      this.isLoading = true;

      try {
        const res = await this.getRegistrationsDetails({ id: registrationID });

        console.log(res);

        //set dealership: only need ID and name
        this.selectedDealership = (({ id, name }) => ({ id, name }))(
          res.registration.dealership
        );

        const keys = [
          "tractor",
          "loader",
          "backhoe",
          "mower",
          "cabin",
        ];

        this.allSelectedProducts = [];


        if (!this.userCanFilePrograms) {
          this.$toasted.error("This registration is not eligible for filing retail programs at this time.");
          this.isLoading = false;
          return false;
        }

        this.registeredSerials = keys
          .map((key) => ( res.registration[`${key}_sn`] ? {
            type: key,
            serial: res.registration[`${key}_sn`],
            model: res.registration[`${key}_model`],
          } : null))
          .filter(x => x);
                  
        this.allSelectedProducts = await this.getProgramInfoBySns(this.registeredSerials);
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },

    returnToProgramsIndex(cb = () => {}) {
      this.$router.push(
        {
          name: "RetailPrograms",
          query: { ...this.programBatchFilters },
        },
        cb
      );
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style lang="scss" scoped>
  table, td, th {
    border: unset;
  }
</style>
