<template>
  <section class="create-claims col-span-12">
    <ValidationObserver tag="div" ref="form" class="container">
      <PageHeader
        title="Order Tractor"
        backText="Back to Orders"
        :backRoute="{ name: 'Orders', query: { ...orderFilters } }"
        :discardLink="false"
        :saveButton="false"
      />
      <div class="create">
        <div class="title">Enter Dealer & Shipping Information</div>
        <div class="text">
          Who is this order for and where is it being delivered?
        </div>
        <div class="dropdown-group">
          <div class="dealer" v-if="user && user.role.id !== 3">
            <dropdown
              :data="createOrder.dealership"
              :status="false"
              fieldName="name"
              label="Dealer"
              :search="true"
              @select="selectDealership"
              @noResultFound="selectDealershipNoResult"
              :validation="true"
              inputName="dealer"
              :editValue="dealerName"
              :all="false"
            ></dropdown>
          </div>
          <div class="address">
            <dropdown
              :data="dealerAddresses"
              :status="false"
              fieldName="name"
              label="Shipping Address"
              @select="selectAddress"
              :validation="true"
              inputName="address"
              :editValue="addressName"
              :all="false"
            ></dropdown>
          </div>
        </div>
      </div>
      <div>
        <button class="btn btn-black px-60" @click="nextCreate" type="button">
          Next
        </button>
      </div>
    </ValidationObserver>
  </section>
</template>

<script>
import PageHeader from "../../component/PageHeader";
import Dropdown from "../../component/Dropdown";
import { mapActions, mapState } from "vuex";
import { http } from "../../../api";

export default {
  name: "CreateOrder",
  data() {
    return {
      dealerName: "",
      addressName: "",
      dealershipId: "",
      addressId: "",
      dealerAddresses: [],
    };
  },
  components: {
    PageHeader,
    Dropdown,
  },
  computed: {
    ...mapState({
      createOrder: (state) => state.order.createOrder,
      user: (state) => state.auth.user,
      orderFilters: (state) => state.order.indexFilters,
    }),
  },
  mounted() {
    this.getCreateOrder()
      .then(() => {
        if (this.user.role.id === 3) {
          this.dealershipId = this.createOrder.dealership[0].id;
          this.getDealerAddress(this.dealershipId);
        }
      })
      .catch((err) => {
        this.$toasted.error(err.response.data.message || "Server Error");
      });
  },
  methods: {
    ...mapActions({
      getCreateOrder: "order/getCreateOrder",
    }),
    nextCreate() {
      this.$refs.form
        .validate()
        .then((success) => {
          if (success) {
            this.$router.push(
              "/order/add" +
                "?dealership=" +
                this.dealershipId +
                "&shipping_address=" +
                this.addressId
            );
          } else {
            this.$toasted.error("Your submission contains errors");
          }
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    },
    getDealerAddress(dealerId) {
      http
        .get("orders/dealer-address?dealership_id=" + dealerId)
        .then((res) => {
          this.dealerAddresses = res.data.data.addresses;
          this.selectAddress(this.dealerAddresses[0]);
        })
        .catch((err) => {
          console.error(err);
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    },
    selectDealership(dealership) {
      this.dealerName = dealership.name;
      this.dealershipId = dealership.id;
      this.addressName = "";
      this.addressId = "";
      if (this.dealershipId || this.dealershipId === 0) {
        this.getDealerAddress(dealership.id);
      }
    },
    selectDealershipNoResult() {
      this.dealerAddresses = null;
      this.selectAddress({ id: null, name: null });
    },
    selectAddress(address) {
      this.addressName = address.name;
      this.addressId = address.id;
    },
  },
};
</script>

<style scoped>
</style>
