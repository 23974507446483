<template>
  <div class="c-retailProgramEdit text-sm">
    <div class="p-5">
      <div
        class="
          flex
          mb-4
          md:mb-6
          flex-col
          md:flex-row
          items-start
          md:items-center
        "
        v-if="details.use_salesperson || details.approved_by"
      >
        <template v-if="details.use_salesperson">
          <p v-if="viewOnly" class="mb-4 md:mr-8 md:mb-0">
            <span class="font-medium">Salesperson: </span>
            <span class="whitespace-no-wrap">{{
              selectedBonusSalesperson && selectedBonusSalesperson.name
            }}</span>
          </p>
          <div v-else class="mb-4 md:mr-8 md:mb-0">
            <span class="inline-block font-medium mb-2 mr-4">Salesperson:</span>
            <div class="inline-block">
              <dropdown
                :data="salespeople"
                :status="false"
                fieldName="name"
                inputName="salesperson"
                @select="selectBonusSalesperson"
                :all="false"
                :search="false"
                disabled
                :editValue="
                  selectedBonusSalesperson && selectedBonusSalesperson.name
                "
              ></dropdown>
            </div>
          </div>
        </template>
        <div v-if="details.approved_by" class="mb-4 md:mb-0">
          <span class="font-medium">Approved By: </span>
          <span class="whitespace-no-wrap">{{ details.approved_by }}</span>
        </div>
      </div>
      <div class="overflow-x-scroll">
        <table
          :style="`border: none; min-width: ${tableMinWidth}; max-width: ${tableMaxWidth};`"
          v-bind:class="{
            'mb-6': !details.lines.length,
          }"
        >
          <thead>
            <th class="text-left font-normal">Serial Number</th>
            <th class="text-center font-normal">Model</th>
            <th
              class="text-left font-normal"
              v-if="details.program_type_id > 1"
            >
              Program
            </th>
            <th
              class="text-left font-normal"
            >
              Description
            </th>
            <th class="text-center font-normal">Claimed</th>
            <th class="text-center font-normal">Adjusted</th>
            <th
              class="text-left font-normal"
              v-if="details.program_type_id > 1"
            >
              File
            </th>
            <th class="text-left font-normal">Status</th>
          </thead>
          <tbody class="text-gray-800">
            <template v-for="(line, i) in details.lines">
              <tr
                class="text-gray-700 text-sm relative"
                v-bind:key="'sales_bonus_serial_' + i"
              >
                <td>
                  <div class="flex items-center">
                    <span
                      class="mr-1 submit_type_icon"
                      v-bind:class="{
                        'text-gray-800': line.submit_type == 'auto',
                        'text-green-600': line.submit_type == 'preapproval',
                        'text-red-700': line.submit_type == 'variable',
                      }"
                      style="width: 16px; height: auto"
                    >
                      <inline-svg
                        v-if="submitTypeIcon(line.submit_type)"
                        class="w-full h-auto"
                        :src="
                          require(`@/assets/image/icons/${submitTypeIcon(
                            line.submit_type
                          )}.svg`)
                        "
                      />
                    </span>
                    {{ line.serial }}
                  </div>
                </td>
                <td class="text-center">
                  {{line.model}}
                </td>
                <td
                  v-if="details.program_type_id > 1"
                  class="leading-none py-2"
                >
                  {{ line.program_name }}
                </td>
                <td
                  class="leading-none py-2"
                >
                  {{ line.description }}
                </td>
                <td class="text-center">
                  ${{ line.amount_orig.toLocaleString("en-US") }}
                </td>
                <td class="text-center whitespace-no-wrap">
                  <span>$</span>
                  <span v-if="viewOnly">{{ line.amount_curr }}</span>
                  <input
                    v-else
                    type="text"
                    v-model="line.amount_curr"
                    class="inline-block rounded text-right"
                    style="width: 68px; padding-right: 10px"
                    :disabled="disabled"
                    :class="getStatusClass(line)"
                    @blur="setLineAmount(i)"
                  />
                </td>
                <td
                  v-if="details.program_type_id > 1"
                  data-file-upload-container
                >
                  <FileInput
                    name="retail_program_file"
                    input-class="h-full my-1"
                    accept="image/*,.pdf"
                    :loadFile="{
                      ...line.attachment,
                      displayName: 'Download',
                    }"
                    @change="
                      line.file = {
                        data: $event.fileData,
                        name: $event.file.name,
                      }
                    "
                    @delete-file="deleteAttachment(line)"
                    :disabled="disabled"
                    :options="{
                      showNoFileMessage: false,
                      chooseFileMessage: 'Upload',
                    }"
                  />
                </td>
                <td :class="getStatusClass(line, true)">
                  {{ getStatus(line, true) }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <div class="dropdown-bottom">
      <div class="font-medium">
        <span>Total Amount: </span>
        <span class="text-gray-800 inline-block ml-1"
          >${{
            details.lines
              .reduce((prev, curr) => prev + parseFloat(curr.amount_orig), 0)
              .toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
          }}</span
        >
      </div>
      <div class="font-medium">
        <span>Total Adjusted Amount: </span>
        <span class="text-gray-800 inline-block ml-1"
          >${{
            details.lines
              .reduce(
                (prev, curr) => prev + (parseFloat(curr.amount_curr) || 0),
                0
              )
              .toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
          }}</span
        >
      </div>
      <!-- <div class="flex">
        <RadioInput
          :model.sync="details.pay_method"
          labelClass="mr-4"
          name="payment_method"
          value="check"
          label="Issue Check"
          disabled
        />
        <RadioInput
          :model.sync="details.pay_method"
          labelClass="mr-8"
          name="payment_method"
          value="credit"
          label="Issue Credit"
          disabled
        />
      </div> -->
    </div>
  </div>
</template>


<script>
import Dropdown from "../../Dropdown";
import RadioInput from "../../Inputs/Radio";
import FileInput from "../../Inputs/File";
import { mapActions, mapState } from "vuex";
import { http } from "../../../../api";

export default {
  name: "RetailBatchEdit",
  data() {
    return {
      selectedBonusSalesperson: null,
    };
  },
  props: {
    details: {
      type: Object,
      required: true,
    },
    dealership: {
      type: Object,
      required: false,
      default() {
        return null;
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    viewOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    Dropdown,
    RadioInput,
    FileInput,
  },
  computed: {
    ...mapState({
      allSalespeople: (state) => state.programs.salespeople,
    }),

    salespeople: function () {
      return [
        {
          name: "Pay to Dealer",
          id: null,
        },
        ...this.allSalespeople.filter((p) => {
          return !this.dealership?.id || p.dealership_id == this.dealership?.id;
        }),
      ];
    },

    submitData: function () {
      return {
        lines: this.details.lines
          .filter((i) => i.sn)
          .map((i) => ({
            ...i,
            amount_curr: parseFloat(i.amount_curr), //salesperson bonus program
          })),
        salesperson_id: this.selectedBonusSalesperson?.id,
        ...this.details,
      };
    },

    tableMaxWidth: function () {
      if (this.details.program_type_id > 1) return "1120px";
      return "540px";
    },

    tableMinWidth: function () {
      if (this.details.program_type_id > 1) return "700px";
      return "380px";
    },
  },
  async created() {
    // if (this.details.program_type_id != 1) return;

    await this.getSalespeople();

    console.log(this.details);

    //set salesperson
    this.selectedBonusSalesperson = this.salespeople.find(
      (p) => p.id == this.details.salesperson_id
    );
  },

  methods: {
    ...mapActions({
      getSalespeople: "programs/getSalespeople",
    }),

    selectBonusSalesperson(person) {
      this.selectedBonusSalesperson = person;
    },

    setLineAmount(i) {
      let line = this.details.lines[i];

      if (line.amount_curr == "") line.amount_curr = 0;
    },

    getStatus(line, forText = false) {
      if (
        line.submit_type === "variable" &&
        !this.details.approved_by &&
        forText
      )
        return "Needs Approval";
      if (!line.amount_curr || line.amount_curr == "0") return "Declined";
      if (line.amount_curr == line.amount_orig) return "-";

      return "Adjusted";
    },

    getStatusClass(line, forText = false) {
      const status = this.getStatus(line, forText);

      const classes = {
        Declined: "declined",
        Adjusted: "adjusted",
        "Needs Approval": "needsApproval",
        "-": "pl-2",
      };

      return classes[status] ?? "";
    },

    deleteAttachment(lineItem) {
      lineItem.attachment = null;
      if (lineItem.file) {
        delete lineItem.file;
      }
    },

    submitTypeIcon(type) {
      if (!type) return null;

      const icons = {
        preapproval: "check_circle",
        auto: "settings",
        variable: "exclamation",
      };
      return icons[type] ?? null;
    },
  },
};
</script>

<style lang="scss" scoped>
.c-retailProgramEdit {
  $border: 1px solid #cdcdcd;

  margin-bottom: -20px;

  td,
  th {
    border: none;

    &:not(:last-child) {
      padding-right: 10px;
    }
  }

  input[type="text"] {
    border: 1px solid #dedede;
    padding: 5px;
    margin: 2px;
  }

  .dropdown-bottom {
    border-top: $border;
    display: flex;

    > * {
      padding: 20px;

      &:not(:last-child) {
        border-right: $border;
      }
    }

    @media (max-width: 825px) {
      flex-wrap: wrap;
      justify-content: stretch;
      border-top: none;

      > * {
        flex: 1 1 auto;
        border-top: $border;
      }
    }
  }

  td {

    &:first-of-type {
      width: 50px;
      white-space: nowrap;
    }
    &.declined {
      color: #e1211e;
    }
    &.needsApproval {
      color: $blue;
    }
  }

  input {
    &.declined {
      background-color: #fff1f1;
    }
    &.adjusted {
      background-color: #eef8db;
    }
  }
}

.submit_type_icon {
  vertical-align: middle;
  /deep/ path {
    fill: currentColor;
  }
}

[data-file-upload-container] {
  width: 100px;

  @media (max-width: 900px) {
    width: 170px;
    max-width: 170px;

    > div {
      justify-content: center;
    }
  }
}

@media (max-width: 900px) {
  /deep/ {
    [data-no-file-message] {
      display: none;
    }

    [data-choose-file-button] {
      margin-right: 0px;
    }

    [data-uploaded-file-name] {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-right: 10px;
    }
  }
}
</style>
