import { http } from '../../../api';

const news = {
    namespaced: true,
    state: {
        recentActivity: [],
        serviceBulletins: [],
        news: [],
        authors: [],
        statuses: [],
        news_item: {},
        initialPage: 1,
        pagination: {},
        manageInitialPage: 1,
        indexFilters: {},
    },
    actions: {
        getNewsBulletins: ({ commit }, params) => {
            return http.get('/news-bulletins',params).then(res => {
                commit('setNewsBulletins', res.data.data)
          });
        },
        getNews: ({ commit }, params) => {
            const q = new URLSearchParams(params);
            return http.get('/news?' + q.toString()).then(res => {
                commit('setNewsData', res.data.data)
            });
        },
        filterNews: ({ commit }, params) => {
            const q = new URLSearchParams(params);
            return http.get('news/filter?' + q.toString()).then(res => {
                commit('setNews', res.data.data)
            });
        },
        getNewsItem: ({ commit }, params) => {
            return http.get('/news/' + params.id).then(res => {
                commit('setNewsItem', res.data.data);
                return res.data.data;
            });
        },
        create: ({ commit }, params) => {
            return http.get('/news/create',params).then(res => {
                commit('setCreate', res.data.data);
                return res.data.data;
            });
        },
        edit: ({ commit }, params) => {
            return http.get('/news/' + params.id +'/edit',params).then(res => {
                commit('setEdit', res.data.data);
                return res.data.data;
            });
        },
        createNews: ({ commit }, params) => {
            return http.post('/news', params).then(res => {
                return res;
            });
        },
        updateNews: ({ commit }, params) => {
            return http.post('/news/' + params.id, params.data).then(res => {
                return res;
            });
        },
        removeNews: ({ commit }, params) => {
            return http.delete('/news/' + params.id).then(res => {
                commit('deleteNews', params.id)
                return res;
            });
        },
        getInitialPage: ({ commit }, params) => {
            commit('setInitialPage', params)
        },
        getManageInitialPage: ({ commit }, params) => {
            commit('setManageInitialPage', params)
        },

        setIndexFilters: ({commit}, params) => {
            commit('setIndexFilters', {...params});
        }
    },
    mutations: {
        setInitialPage(state, page) {
            state.initialPage = page
        },
        setManageInitialPage(state, page) {
            state.manageInitialPage = page
        },
        setNewsBulletins(state, data){
            state.recentActivity = data.recentActivity;
            state.news = data.news;
            state.serviceBulletins = data.serviceBulletins;
        },
        deleteNews(state, id) {
            state.news = state.news.filter(item => id !== item.id)
        },
        setCreate(state, data) {
            state.statuses = data.statuses
        },
        setEdit(state, data) {
            state.editData = data.news
            state.statuses = data.statuses
        },
        setNewsData(state, data){
            state.news = data.news.data;
            state.pagination = data.news.pagination;
            state.authors = data.authors;
            state.statuses = data.statuses;
        },
        setNews(state, data){
            state.news = data.news.data;
            state.pagination = data.news.pagination;
        },
        setNewsItem(state, data){
            state.news_item = data.news;
        },

        setIndexFilters(state, data) {
            state.indexFilters = data;
        }
    },
    getters: {
        recentActivity: state => state.recentActivity,
        serviceBulletins: state => state.serviceBulletins,
        news: state => state.news,
        statuses: state => state.statuses,
        editData: state => state.editData,
        initialPage: state => state.initialPage,
        manageInitialPage: state => state.manageInitialPage,
        indexFilters: state => state.indexFilters,
    }
};

export default news;
