import Vue from 'vue'
import App from './App.vue'
import './sass/app.scss'
import router from './router/index'
import store from './store'
import {configure, extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import * as rules from 'vee-validate/dist/rules';
import VueToasted from 'vue-toasted';
import ToastedOptions from './utils/toasted.config';
import VTooltip from "v-tooltip";
Vue.use(VTooltip);
Vue.config.productionTip = false;
import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

import VModal from 'vue-js-modal'
Vue.use(VModal, { componentName: 'VModal' })

import VueMask from 'v-mask'
Vue.use(VueMask);

// Or as a directive
import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);

import ClickOutside from 'vue-click-outside'
Vue.directive('click-outside', ClickOutside);

import InlineSvg from 'vue-inline-svg';
Vue.component('inline-svg', InlineSvg);

import CKEditor from 'ckeditor4-vue';
Vue.use( CKEditor );

// Or only as a filter
import { VueMaskFilter } from 'v-mask'
Vue.filter('VMask', VueMaskFilter)

import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)

import FunctionalCalendar from 'vue-functional-calendar';
Vue.use(FunctionalCalendar, {
    dayNames: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']
});
Vue.use(require('vue-moment'));

Vue.use(VueToasted, ToastedOptions);

configure({
    classes: {
        invalid: 'is-invalid',
    }
});

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});
extend('password', {
    params: ['target'],
    validate(value, { target }) {
        return value === target;
    },
    message: 'Password confirmation does not match'
});
extend('positive', value => {
    if (value.slice(0,24) >= 'https://www.youtube.com/') {
        return true;
    }

    message: 'The {fieldName} must be a valid...'
});
extend('phone-number', value => {
    if (value.match(/^[(][2-9]\d{2}[)]\s[2-9]\d{2}[-]\d{4}$/) !== null) {
    return true;
}

message: 'The {fieldName} must be a valid...'
})

Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
    router: router,
    store: store
}).$mount('#app')
