<template>
  <section class="orders col-span-12">
    <div class="container grid grid-cols-1 gap-0">
      <PageHeader
        title="Orders"
        :backLink="false"
        :discardLink="false"
        :saveButton="false"
        :addButton="userCanCreate"
        addText="Create Order"
        @add="$router.push({ name: 'CreateOrder' })"
      />
      <div class="filter-block">
        <div>
          <div class="table-filter-search-input">
            <div class="form-group">
              <label class="control-label">Search</label>
              <input
                type="text"
                v-model="filterData.searchVal"
                name="search"
                value=""
                class="search"
                v-on:keyup.enter="applyFilter"
                placeholder="Order #"
              />
              <inline-svg
                class="search-icon"
                :src="require('@/assets/image/icons/search.svg')"
              />
            </div>
          </div>
          <div
            class="table-filter-dealership-select"
            v-if="user.role.id !== 3 || user.permissions.includes('GLOBAL')"
          >
            <dropdown
              :data="dealerships"
              :status="false"
              fieldName="name"
              label="Dealership"
              :validation="true"
              :all="true"
              :search="true"
              placeholder="Dealership"
              @select="selectDealership"
              inputName="dealership"
              :editValue="selectedDealership"
            ></dropdown>
          </div>

          <div class="table-filter-territory-select" v-if="user.role.id == 1">
            <dropdown
              :data="territoryManager"
              :status="false"
              fieldName="name"
              label="Territory Managers"
              @select="selectTerritoryManager"
              :validation="false"
              inputName="territory"
              :editValue="selectedTerritoryManagers"
            ></dropdown>
          </div>

          <div class="table-filter-status-select">
            <dropdown
              :data="orderStatuses"
              :status="true"
              fieldName="name"
              label="Status"
              @select="selectStatus"
              :validation="false"
              inputName="status"
              :editValue="selectedStatus"
            ></dropdown>
          </div>
          <div class="form-group">
            <div>
              <label class="control-label">Date Range</label>
              <functional-calendar
                :placeholder="' '"
                v-model="calendarData"
                :sundayStart="true"
                :date-format="'mm/dd/yyyy'"
                :is-modal="true"
                :is-date-range="true"
                :is-multiple="true"
                :calendars-count="2"
                :change-year-function="true"
              ></functional-calendar>
              <svg
                class="date-down"
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 0L0 1L4.99999 6.00003L10 1.00002L9 2.05677e-05L5.00001 4.00001L1 0Z"
                  fill="black"
                />
              </svg>
              <span class="dash">-</span>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="btn btn-black px-40" @click.prevent="applyFilter">
            <div class="text"><a>Apply</a></div>
          </div>
          <div class="btn redo px-15" @click.prevent="resetFilter">
            <a>
              <inline-svg :src="require('@/assets/image/icons/redo.svg')" />
            </a>
          </div>
        </div>
      </div>
      <div class="table-block col-span-1">
        <data-table
          v-bind:page="filterData.page"
          :header="fieldOrdersTable.header"
          :data="orders"
          :link="{ url: '/orders/', name: 'id' }"
          :isLoading="isLoading"
        />
      </div>
      <div class="pages" v-if="pagination.total && pagination.total_pages > 1">
        <div class="pages-quantity">
          <template v-if="!isLoading">
            Page {{ filterData.page }} of {{ pagination.total_pages }} - Showing
            {{ pagination.per_page }} Results of {{ pagination.total }} Total
          </template>
        </div>
        <paginate
          :page-count="pagination.total_pages"
          :page-range="screenWidth < 468 ? 3 : 6"
          :margin-pages="1"
          :initial-page="filterData.page - 1"
          :click-handler="paginationClick"
          :prev-text="'&laquo;'"
          :next-text="'&raquo;'"
          :container-class="'pages-btn'"
        >
        </paginate>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../component/PageHeader";
import DataTable from "../../component/DataTable";
import Dropdown from "../../component/Dropdown";
import { FunctionalCalendar } from "vue-functional-calendar";
import ClickOutside from "vue-click-outside";
import { mapActions, mapState } from "vuex";

export default {
  name: "Orders",
  data() {
    return {
      baseUrl: "/orders",
      isLoading: false,
      screenWidth: 0,
      statusOpen: false,
      dealershipOpen: false,
      territoryManagersOpen: false,
      calendarData: {
        dateRange: {
          start: "",
          end: "",
        },
      },
      selectedStatus: "All",
      selectedDealership: "All",
      selectedTerritoryManagers: "All",
      fieldOrdersTable: {
        header: [
          {
            field: "id",
            name: "Order ID",
            transformToComponent: "TransformToLink",
            width: "14%",
          },
          { field: "dealer", name: "Dealer" },
          { field: "territory_manager", name: "Territory Manager" },
          { field: "model", name: "Model" },
          {
            field: "status.name",
            name: "Status",
            transformField: "status.id",
            transform: this.transformToStatus,
          },
          { field: "created_by.name", name: "Created By" },
          { field: "updated_by.name", name: "Last Editing" },
          { field: "submission_date", name: "Submission Date" },
          {
            transformField: "id",
            name: "Actions",
            transformToComponent: "OrdersTableDropdown",
          },
        ],
      },
      filterData: {
        page: 1,
      },
    };
  },
  components: {
    PageHeader,
    DataTable,
    FunctionalCalendar,
    Dropdown,
  },
  computed: {
    ...mapState({
      orderStatuses: (state) => state.order.orderStatuses,
      dealerships: (state) => state.order.dealerships,
      orders: (state) => state.order.orders,
      pagination: (state) => state.order.pagination,
      territoryManager: (state) => state.order.territoryManager,
      user: (state) => state.auth.user,
    }),
    userCanCreate: function () {
      if (!this.user) return false;
      return (
        this.user.permissions.includes("GLOBAL") ||
        this.user.permissions.includes("ORDER_CREATE")
      );
    },
  },
  async created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    const {page, ...queryData} = this.$route.query;

    this.filterData = {
      ...this.filterData,
      ...queryData,
    }

    if (page) {
      this.filterData.page = Number(page);
    }

    if (this.filterData.date_start) {
      this.calendarData.dateRange.start = this.filterData.date_start;
    }
    if (this.filterData.date_end) {
      this.calendarData.dateRange.end = this.filterData.date_end;
    }

    await this.getOrdersList();

    // sync query with available filters
    let matchedDealer = this.dealerships.find(
      (dealer) => dealer.id == this.$route.query.dealership_id
    );
    this.selectedDealership = matchedDealer?.name ?? 'All';
    
    let matchedStatus = this.orderStatuses.find(
      (status) => status.id == this.filterData.status_id
    );
    this.selectedStatus = matchedStatus?.name ?? 'All';

    let matchedTm = this.territoryManager.find(
      (tm) => tm.id == this.filterData.tm_id
    );
    this.selectedTerritoryManagers = matchedTm?.name ?? 'All';
  },
  methods: {
    ...mapActions({
      getOrders: "order/getOrders",
      setFilterState: "order/setIndexFilters",
    }),
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
    async getOrdersList() {
      this.isLoading = true;

      try {
        await this.getOrders(this.filterData);
      } catch(err) {
        this.$toasted.error(err.response.data.message || "Server Error");
      }

      this.isLoading = false;
    },
    paginationClick(pageNum) {
      this.filterData.page = pageNum;
      this.updateRouter();
      this.getOrdersList();
    },
    applyFilter() {
      if (this.calendarData.dateRange != null) {
        if (this.calendarData.dateRange.start) {
          this.filterData.date_start = this.calendarData.dateRange.start;
        }
        if (this.calendarData.dateRange.end) {
          this.filterData.date_end = this.calendarData.dateRange.end;
        }
      }
      this.$set(this.filterData, 'page', 1);
      this.updateRouter();
      this.getOrdersList();
    },
    resetFilter() {
      this.selectedDealership = "All";
      this.selectedTerritoryManagers = "All";
      this.selectedStatus = "All";
      this.filterData = {
        page: 1,
      };

      if (this.calendarData.dateRange) {
        this.calendarData.dateRange.end = "";
        this.calendarData.dateRange.start = "";
      }
      this.applyFilter();
    },
    transformToStatus(id, val) {
      let statusName = "pending";
      console.log(id);

      switch (id) {
        case 1: //accepted
        default:
          statusName = "success";
          break;
        case 3: //draft
          statusName = "draft w-16";
          break;
        case 7: //pending
          statusName = "pending";
          break;
      }
      return `<div class="c-status c-status--${statusName}">${val}</div>`;
    },
    selectStatus(status) {
      this.selectedStatus = status.name;
      if (status.id) {
        this.filterData.status_id = status.id;
      } else {
        delete this.filterData.status_id;
      }
      this.applyFilter();
    },
    selectDealership(dealership) {
      //avoid multiple submissions:
      if (this.selectDealerWaiting) return;

      this.selectDealerWaiting = true;
      setTimeout(() => {
        this.selectDealerWaiting = false;
      }, 50);
      //-------------------------------//

      this.selectedDealership = dealership?.name ?? 'All';

      if (dealership.id || dealership.id === 0) {
        this.filterData.dealership_id = dealership.id;
      } else {
        delete this.filterData.dealership_id;
      }
      this.applyFilter();
    },
    selectTerritoryManager(territoryManager) {
      this.selectedTerritoryManagers = territoryManager.name;
      if (territoryManager.id) {
        this.filterData.tm_id = territoryManager.id;
      } else {
        delete this.filterData.tm_id;
      }
      this.applyFilter();
    },

    updateRouter(saveState = true) {
      this.$router.push(
        this.baseUrl + "?" + new URLSearchParams(this.filterData).toString()
      );
      if (saveState) this.setFilterState(this.filterData);
    },
  },
  directives: {
    ClickOutside,
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped>
</style>
