<template>
  <!-- History Sidebar View -->
  <div
    class="historySidebar"
    v-bind:class="{
      hidden: !visible,
    }"
  >
    <!-- Title and Close Button -->
    <div class="historyHeader">
      <h2>History</h2>
      <button @click="close">Close</button>
    </div>

    <div class="overflow-y-auto">
      <!-- List View -->
      <ul class="historyList">
        <li
          v-for="(entry, i) in data"
          :key="`history_entry_${i}`"
          class="historyEntry"
        >
          <!-- Single History Item -->

          <!-- Selection Indicator -->
          <div class="historySelector" v-if="selection == entry.id"></div>

          <!-- Date and clickable link -->
          <h3
            class="
              text-blue
              underline
              font-medium
              mb-2
              inline-block
              cursor-pointer
            "
            @click="selectItem(entry)"
          >
            <!-- Add "Current" if no id exists -->
            {{ formatDate(entry.timestamp) }}{{ entry.id ? "" : " (Current)" }}
          </h3>

          <!-- Loop through additional fields -->
          <div
            class="mb-1"
            v-for="field in fields"
            :key="`history_entry_${i}_field_${field.field}`"
          >
            <h4 class="inline-block">{{ field.name }}:</h4>
            <span class="inline-block ml-4">{{
              field.currency
                ? `$${formatCurrency(getPropByPath(entry, field.field))}`
                : getPropByPath(entry, field.field)
            }}</span>
          </div>

          <!-- Status -->
          <div class="mb-1">
            <h4 class="inline-block">Status:</h4>
            <span
              class="inline-block ml-4 c-status"
              :class="getStatusClass(entry.status)"
              >{{ entry.status && entry.status.description }}</span
            >
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "HistoryViewer",
  props: {
    data: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    fields: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    visible: {
      type: Boolean,
      required: false,
      default: true,
    },
    selection: {
      type: Number,
      required: false,
      default: null,
    },
    statusClasses: {
      type: Object,
      required: false,
      default() {
        return {
          1: "success",
          2: "rejected",
          3: "pending",
          4: "draft",
          5: "blue",
        };
      },
    },
  },
  // data() {},
  computed: {},
  created() {
  },
  methods: {
    close() {
      this.$emit("update:visible", false);
    },
    selectItem(item) {
      this.$emit("select", item);

      if (screen.width <= 600) {
        this.$emit("update:visible", false);
      }
    },

    getStatusClass(status) {
      return `c-status--${this.statusClasses[status?.id] ?? "draft"}`;
    },
    formatCurrency(number) {
      if (number === null || number === undefined) return null;
      return number.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },

    formatDate(input) {
      if (input) {
        let date = new Date(input);
        const dateOptions = {
          month: "2-digit",
          day: "2-digit",
          year: "2-digit",
          hour: "numeric",
          minute: "numeric",
        };
        date = date.toLocaleDateString("en-US", dateOptions);
        return date.replace(",", " -");
      }
    },

    getPropByPath(obj, path) {
      if (path && path.length)
        return path.split(".").reduce(function (prev, curr) {
          return prev ? prev[curr] : null;
        }, obj);
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
@import "../../../sass/main/history-settings";
$borderColor: $gray-300;
$border: 1px solid $borderColor;

.historySidebar {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 290px;
  z-index: 2000;
  background-color: white;
  border-right: $border;
  transform: translateX(0);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  visibility: visible;

  transition: all 0.5s ease-in-out, visibility 0s;

  @media print {
    display: none;
  }

  &.hidden {
    transform: translateX(-100%);
    box-shadow: rgba(0, 0, 0, 0) 0px;
    visibility: hidden;
    transition: all 0.5s ease-in-out, visibility 0s 0.5s;
  }

  @media (max-width: $history-breakpoint) {
    $transitionTime: 0.2s;
    // position: static;
    width: unset;
    top: 3vh;
    bottom: 3vh;
    left: 5vw;
    right: 5vw;
    border: $border;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    opacity: 1;
    // visibility: visible;
    transition: all #{$transitionTime} ease-out, visibility 0s;

    &.hidden {
      transform: translateY(20px);
      opacity: 0;
      // visibility: hidden;
      transition: all #{$transitionTime} ease-out,
        visibility 0s #{$transitionTime};
    }
  }
}

.historyHeader {
  background-color: $gray-100;
  padding: 30px 25px;
  display: flex;
  justify-content: space-between;
  border-bottom: $border;

  @media (max-height: 450px), (max-width: 500px) {
    padding: 20px 25px;
  }

  h2 {
    font-size: 28px;
    font-weight: 500;

    @media (max-height: 450px), (max-width: 500px) {
      font-size: 24px;
    }
  }
  button {
    text-decoration: underline;
    outline: none;
  }
}

.historyList {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

.historyEntry {
  $hPad: 25px;
  padding: 20px $hPad;
  position: relative;
  font-size: 13px;

  &:not(:last-child)::after {
    content: "";
    display: block;
    position: absolute;
    left: $hPad;
    right: $hPad;
    bottom: 0;
    height: 1px;
    background-color: $borderColor;
  }

  h3 {
    font-size: 15px;
  }

  h4 {
    font-size: inherit;
    font-weight: 500;
  }

  .c-status {
    padding-top: 1px;
    padding-bottom: 1px;
  }
}

.historySelector {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 7px;
  background-color: #3b9810;
}
</style>
