<template>
  <div class="dropdown-settings relative">
    <button
      aria-label="actions-menu"
      type="button"
      class="button btn-td-hover"
      :class="openClose ? 'active' : ''"
      v-click-outside="hide"
      @click="openClose = !openClose"
    >
      <inline-svg
        class="m-auto"
        :src="require('@/assets/image/icons/more-alt.svg')"
      />
    </button>
    <div class="dropdown" v-if="openClose">
      <router-link tag="div" :to="`/admin/news/` + item.item.id"
        >View</router-link
      >
      <router-link tag="div" :to="`/admin/news/` + item.item.id + '/edit'"
        >Edit</router-link
      >
      <div class="delete" @click="openConfirmModal">Delete</div>
    </div>
    <ConfirmModal
      text="Are you sure you want to delete this news?"
      confirmBtn="Delete News"
      :show="showDeleteNewsModal"
      @confirm="deleteNews"
      @close="closeConfirmModal"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ConfirmModal from "../ConfirmModal";

export default {
  name: "NewsTableDropdown",
  props: ["item"],
  data() {
    return {
      openClose: false,
      showDeleteNewsModal: false,
    };
  },
  components: {
    ConfirmModal,
  },
  methods: {
    ...mapActions({
      removeNews: "news/removeNews",
      getNews: "news/getNews",
    }),
    openConfirmModal() {
      this.showDeleteNewsModal = true;
    },
    deleteNews() {
      this.removeNews({ id: this.item.item.id })
        .then((res) => {
          this.getNews({ page: this.item.page });
          this.$toasted.success(res.data.message);
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        })
        .finally(() => {
          this.closeConfirmModal();
        });
    },
    closeConfirmModal() {
      this.showDeleteNewsModal = false;
    },
    hide() {
      this.openClose = false;
    },
  },
};
</script>