<template>
    <div class="tooltip" :class="right ? 'right' : ''">
        <div class="container">
            <div class="title">{{title}}</div>
            <div class="text">
                {{text}}
            </div>
            <inline-svg class="m-auto cursor-pointer close" v-close-popover :src="require('@/assets/image/icons/close.svg')"/>
        </div>

    </div>
</template>

<script>
    export default {
        name: "Tooltip",
        props: {
            title: {
                type: String,
                required: false
            },
            text: {
                type: String,
                required: false
            },
            right: {
                type: Boolean,
                required: false
            },
        },
        data () {
            return {

            }
        },
    }
</script>

<style type="text/css" scoped lang="scss">
    .tooltip {
        width: 588px;
        height: 135px;
        background: #FFFFFF;
        border: 0.5px solid #D6D6D6;
        box-sizing: border-box;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        position: relative;
        left: 255px;
        top: 9px;

        @media(max-width: 1023px) {
            width: 340px;
            left: 150px !important;
        }

        @media(max-width: 630px) {
            width: 300px;
            left: 80px !important;
        }
    }

    .right{
        left: -255px;
    }

    .container{
        margin: 24px auto;
        width: 90%;
    }
    .title{
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 32px;
        padding-bottom: 8px;
    }
    .text{
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 22px;
        color: #5F5F5F;
    }
    .close{
        position: absolute;
        right: 20px;
        top: 24px;
    }
    .red{

    }
</style>