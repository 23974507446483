<template>
  <section class="retail-programs col-span-12">
    <div class="container">
      <ValidationObserver>
        <PageHeader
          :title="pageTitle"
          backText="Back to Program Preapprovals"
          :backRoute="{ name: 'ProgramPreapprovalIndex' }"
          saveText="Submit"
          @save="save"
          @discard="showDiscardModal = true"
          :buttonsDisabled="buttonDisabled"
        />
        <div class="main-section">
          <h2 class="title">Preapproval Details</h2>
          <div class="c-preapprovalContainer">
            <div class="mb-6" v-if="user && user.role.id !== 3">
              <dropdown
                id="dealershipDropdown"
                :data="dealerships"
                :status="false"
                fieldName="name"
                label="Dealer"
                @select="selectDealership"
                :editValue="selectedDealership && selectedDealership.name"
                :validation="true"
                inputName="dealership"
                :all="false"
                :search="true"
              />
              <span class="text-red-500">{{ errorMessages.dealership }}</span>
            </div>
            <div
              class="form-group block mb-6"
              v-click-outside="hideSerialDropdown"
            >
              <label class="control-label">Tractor SN</label>
              <ValidationProvider tag="div" v-slot="{ classes }">
                <input
                  type="text"
                  v-model="serial"
                  autocomplete="off"
                  @input="searchSerials"
                  name="serial"
                  :class="{ ...serialLookup.class, ...classes }"
                />
              </ValidationProvider>
              <inline-svg
                v-if="serialLookup.class === 'error'"
                class="icon"
                :src="require('@/assets/image/icons/error.svg')"
              />
              <inline-svg
                v-if="serialLookup.class === 'success'"
                class="icon"
                :src="require('@/assets/image/icons/success.svg')"
              />
              <div
                class="sns-dropdown dropdown"
                :class="serialLookup.class"
                v-if="serialLookup.showDropdown"
              >
                <button
                  v-for="(sn, i) in serialLookup.results"
                  v-bind:key="'serial_' + i"
                  @click="selectSerial(sn)"
                >
                  {{ sn.serial_no }}
                </button>
              </div>
            </div>
            <div class="mb-6">
              <dropdown
                id="programDropdown"
                :data="programs"
                :status="false"
                fieldName="name"
                label="Retail Program"
                @select="selectProgram"
                :editValue="selectedProgram && selectedProgram.name"
                :validation="true"
                inputName="program"
                :all="false"
                :search="true"
              />
            </div>
            <div class="mb-6 form-group block">
              <label for="preapproval_amount">Preapproved Amount</label>
              <div class="relative">
                <input
                  id="preapproval_amount"
                  type="text"
                  style="padding-left: 16px"
                  v-model="amount"
                />
                <span
                  class="absolute"
                  style="
                    top: 50%;
                    left: 8px;
                    transform: translateY(-50%);
                    font-size: 13px;
                    line-height: 15px;
                  "
                  >$</span
                >
              </div>
            </div>
            <div class="mb-6 form-group block">
              <label for="notes">Notes</label>
              <textarea id="notes" rows="5" v-model="notes"></textarea>
            </div>
          </div>
        </div>
        <div class="main-section">
          <div>
            <div class="flex items-center">
              <button
                @click="save"
                :readonly="buttonDisabled"
                :class="buttonDisabled ? 'opacity-50 cursor-wait' : ''"
                type="button"
                class="btn btn-black px-40"
              >
                Submit
              </button>
              <div class="discard-link ml-4" @click="showDiscardModal = true">
                Discard
              </div>
            </div>
          </div>
        </div>
        <ConfirmModal
          text="Discard changes?"
          confirmBtn="Discard"
          :show="showDiscardModal"
          @confirm="$router.push({ name: 'ProgramPreapprovalIndex' })"
          @close="showDiscardModal = false"
        />
      </ValidationObserver>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../component/PageHeader";
import Dropdown from "../../component/Dropdown";
import { mapActions, mapState } from "vuex";
import ConfirmModal from "../../component/ConfirmModal";

export default {
  name: "ProgramPreApprovalAdd",
  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      preapprovalId: null,

      selectedDealership: null,
      selectedProgram: null,
      serial: null,
      amount: null,
      notes: "",

      serialLookup: {
        showDropdown: false,
        class: "",
        results: [],
      },

      errorMessages: {
        dealership: null,
      },

      buttonDisabled: false,
      showDiscardModal: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      dealerships: (state) => state.programPreapprovals.dealerships,
      programs: (state) => state.programPreapprovals.programs,
      preapproval: (state) => state.programPreapprovals.preapprovalDetails,
    }),
    pageTitle() {
      if (this.isEdit) return "Edit Preapproval";

      return "Create Preapproval";
    },

    formData() {
      return {
        dealership_id: this.selectedDealership?.id,
        program_id: this.selectedProgram?.id,
        serial: this.serial,
        amount: this.amount,
        notes: this.notes,
      };
    },
  },
  async created() {
    try {
      if (this.isEdit) {
        //update existing preapproval

        //set preapproval ID from route
        this.preapprovalId = this.$route.params.id;
        //get preapproval details, set dealer & programs lists
        await this.getEditDetails(this.preapprovalId);

        //populate form data from results
        this.populateFormData();
      } else {
        //create new preapproval
        await this.getCreate();
      }
    } catch (err) {
      this.$toasted.error(err.response?.data.message || "Server Error");
    }

    // if (this.user.role.id === 3) {
    //   //User is a dealer - select dealership
    //   this.selectedDealership = { id: this.user.dealership_id };
    // }
  },
  methods: {
    ...mapActions({
      getEditDetails: "programPreapprovals/getPreapprovalEdit",
      getCreate: "programPreapprovals/getPreapprovalCreate",
      createPreapproval: "programPreapprovals/createPreapproval",
      updatePreapproval: "programPreapprovals/updatePreapproval",
    }),

    populateFormData() {
      this.selectedDealership = this.dealerships.find(
        (d) => d.id === this.preapproval.dealership?.id
      );
      this.serial = this.preapproval.serial;
      this.selectedProgram = this.programs.find(
        (p) => p.id === this.preapproval.program?.id
      );
      this.amount = this.preapproval.amount;
      this.notes = this.preapproval.notes;
    },

    selectDealership(dealership) {
      console.log(dealership);
      this.selectedDealership = dealership;
    },

    selectProgram(program) {
      this.selectedProgram = program;
    },

    searchSerials() {},

    hideSerialDropdown() {
      this.serialLookup.showDropdown = false;
    },

    async save() {
      let successMessage = "";
      try {
        if (this.isEdit) {
          await this.updatePreapproval({
            id: this.preapprovalId,
            data: this.formData,
          });
          successMessage = "Preapproval successfully updated";
        } else {
          await this.createPreapproval(this.formData);
          successMessage = "New preapproval created";
        }
      } catch (err) {
        this.$toasted.error(err.response?.data.message || "Server Error");
        return;
      }

      this.$router.push({ name: "ProgramPreapprovalIndex" }, () => {
        this.$toasted.success(successMessage);
      });
    },
  },
  components: {
    PageHeader,
    ConfirmModal,
    Dropdown,
  },
};
</script>

<style lang="scss" scoped>
.c-preapprovalContainer {
  max-width: 350px;

  label {
    font-weight: $font-weight-medium;
    font-size: 13px;
    line-height: 16px;
    color: #000000;
    padding-bottom: 8px;
    display: block;
  }
}
</style>
