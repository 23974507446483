<template>
  <div class="dropdown-settings relative">
    <button
      aria-label="actions-menu"
      type="button"
      class="button btn-td-hover"
      :class="openClose ? 'active' : ''"
      v-click-outside="hide"
      @click="openClose = !openClose"
    >
      <inline-svg
        class="m-auto"
        :src="require('@/assets/image/icons/more-alt.svg')"
      />
    </button>
    <div class="dropdown" v-if="openClose">
      <router-link tag="div" :to="`/admin/media/` + item.item.id"
        >View</router-link
      >
      <router-link tag="div" :to="`/admin/media/` + item.item.id + '/edit'"
        >Edit</router-link
      >
      <div class="delete" @click="openConfirmModal">Delete</div>
    </div>
    <ConfirmModal
      text="Are you sure you want to delete this media?"
      confirmBtn="Delete Media"
      :show="showDeleteMediaModal"
      @confirm="deleteMedia"
      @close="closeConfirmModal"
    />
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import { mapActions, mapState } from "vuex";
import ConfirmModal from "../ConfirmModal";

export default {
  name: "MediaDropdownSettings",
  props: ["item"],
  data() {
    return {
      openClose: false,
      showDeleteMediaModal: false,
    };
  },
  components: {
    ConfirmModal,
  },
  methods: {
    ...mapActions({
      removeMedia: "media/removeMedia",
      getMedia: "media/getMedia",
    }),
    openConfirmModal() {
      this.showDeleteMediaModal = true;
    },
    deleteMedia() {
      this.removeMedia({ id: this.item.item.id })
        .then((res) => {
          this.getMedia({ page: this.item.page });
          this.$toasted.success(res.data.message);
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        })
        .finally(() => {
          this.closeConfirmModal();
        });
    },
    closeConfirmModal() {
      this.showDeleteMediaModal = false;
    },
    hide() {
      this.openClose = false;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>