<template>
  <section class="manage col-span-12">
    <div class="container grid grid-cols-1 gap-0">
      <PageHeader
        title="Labor Codes Lookup"
        :saveButton="false"
        :discardLink="false"
        backText="Back to Settings"
        :backRoute="{ name: 'AdminSettings' }"
      />
      <div class="filter-block">
        <div>
          <div class="table-filter-search-input">
            <div class="form-group">
              <label class="control-label">Search</label>
              <input
                type="text"
                name="search"
                v-model="filterData.term"
                v-on:keyup.enter="applyFilter"
                class="search"
                placeholder="Labor Code"
              />
              <inline-svg
                class="search-icon"
                :src="require('@/assets/image/icons/search.svg')"
              />
            </div>
          </div>
        </div>
        <div class="card">
          <div class="btn btn-black px-40" @click="getLaborCodes">
            <div class="text"><a>Apply</a></div>
          </div>
          <div class="btn redo px-15" @click="resetFilter">
            <a>
              <inline-svg :src="require('@/assets/image/icons/redo.svg')" />
            </a>
          </div>
        </div>
      </div>
      <div class="table-block col-span-1">
        <data-table
          :page="filterData.page"
          :header="fieldLaborCodesLookupTable.header"
          :data="laborsList"
          class="media-table"
          :isLoading="isLoading"
        />
      </div>
      <div class="pages" v-if="pagination.total_pages > 1">
        <div class="pages-quantity">
          <template v-if="!isLoading">
            Page {{ filterData.page }} of {{ pagination.total_pages }} - Showing
            {{ pagination.per_page }} Results of {{ pagination.total }} Total
          </template>
        </div>
        <paginate
          v-if="pagination.total_pages"
          :page-count="pagination.total_pages"
          :page-range="6"
          :initial-page="initialPage - 1"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="'&laquo;'"
          :next-text="'&raquo;'"
          :container-class="'pages-btn'"
          :active-class="pageActive"
        >
        </paginate>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../../component/PageHeader";
import DataTable from "../../../component/DataTable";
import { mapActions, mapState } from "vuex";

export default {
  name: "LaborCodesLookup",
  data() {
    return {
      isLoading: true,
      fieldLaborCodesLookupTable: {
        header: [
          {
            field: "code",
            name: "Labor Code #",
            width: "10%",
            headerWidth: "9%",
          },
          { field: "model", name: "Model" },
          { field: "description", name: "Description" },
          { field: "group", name: "Group" },
          { field: "time", name: "Time" },
          { field: "created_at", name: "Creation Date" },
        ],
      },
      filterData: {
        page: 1,
      },
      pageActive: "active",
    };
  },
  computed: {
    ...mapState({
      laborsList: (state) => state.laborCode.laborsList,
      pagination: (state) => state.laborCode.pagination,
      initialPage: (state) => state.laborCode.initialPage,
    }),
  },
  components: {
    PageHeader,
    DataTable,
  },
  mounted() {
    if (this.$route.query.page) {
      if (this.$route.query.term) {
        this.filterData.term = this.$route.query.term;
      }
      this.filterData.page = +this.$route.query.page;
      this.getInitialPage(this.filterData.page);
      this.isLoading = true;
      this.getLabors(this.filterData)
        .then((res) => {
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    } else {
      this.getLaborCodes();
    }
  },
  methods: {
    ...mapActions({
      getLabors: "laborCode/getLabors",
      getInitialPage: "laborCode/getInitialPage",
    }),
    resetFilter() {
      this.pageActive = "";
      this.filterData = {
        page: 1,
      };
      this.getInitialPage(1);
      this.applyFilter();
    },
    applyFilter() {
      this.$router.push(
        "/admin/search-labor?" + new URLSearchParams(this.filterData).toString()
      );
      this.isLoading = true;
      this.getLabors(this.filterData)
        .then((res) => {
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    },
    clickCallback(pageNum) {
      this.getInitialPage(pageNum);
      this.filterData.page = pageNum;
      this.pageActive = "active";
      this.getLaborCodes();
    },
    getLaborCodes() {
      this.filterData.page = this.initialPage;
      this.isLoading = true;
      this.getLabors(this.filterData)
        .then((res) => {
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    },
  },
};
</script>

<style scoped>
.manage .container .filter-block .table-filter-status-select .form-group {
  width: 140px !important;
}
.manage .container .filter-block .table-filter-dealership-select .form-group {
  width: 195px !important;
}
</style>
