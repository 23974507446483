<template>
  <section class="manage col-span-12">
    <div class="container grid grid-cols-1 gap-0">
      <PageHeader
        title="Service Bulletins"
        :backLink="false"
        :saveButton="false"
        :discardLink="false"
      />
      <div class="filter-block filter-block-border">
        <div>
          <div class="table-filter-search-input">
            <div class="form-group">
              <label class="control-label">Search</label>
              <input
                type="text"
                name="search"
                v-model="filterData.term"
                v-on:keyup.enter="filter"
                class="search"
                placeholder=""
              />
              <inline-svg
                class="search-icon"
                :src="require('@/assets/image/icons/search.svg')"
              />
            </div>
          </div>
        </div>
        <div class="card">
          <div class="btn btn-black px-40" @click="filter">
            <div class="text"><a>Apply</a></div>
          </div>
          <div class="btn redo px-15" @click="resetFilter">
            <a>
              <inline-svg :src="require('@/assets/image/icons/redo.svg')" />
            </a>
          </div>
        </div>
      </div>
      <div
        class="video-block pt-5 ml-minus-17"
        v-if="serviceBulletins && serviceBulletins.length"
      >
        <div class="card-rs" v-for="service in serviceBulletins">
          <div class="card-container">
            <span>{{ service.posted_on }}</span>
            <p>{{ service.title }}</p>
            <button class="btn btn-info px-15" @click="download(service)">
              <loading v-if="disableIndex.includes(service.id)" />
              <div class="text">Download</div>
              <div class="create">
                <inline-svg
                  :src="require('@/assets/image/icons/download.svg')"
                />
              </div>
            </button>
          </div>
        </div>
      </div>
      <div v-else class="text-center mt-5">
        No results found. Please adjusts your search criteria message.
      </div>
      <div class="pages" v-if="pagination.total && pagination.total_pages > 1">
        <div class="pages-quantity">
          Page {{ filterData.page }} of {{ pagination.total_pages }} - Showing
          {{ pagination.per_page }} Results of {{ pagination.total }} Total
        </div>
        <paginate
          :page-count="pagination.total_pages"
          :page-range="4"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="'&laquo;'"
          :next-text="'&raquo;'"
          :container-class="'pages-btn'"
          :active-class="pageActive"
        >
        </paginate>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../component/PageHeader";
import { mapActions, mapState } from "vuex";
import { http } from "@/api";
import Loading from "@/components/component/Loading";

export default {
  name: "ServiceBulletins",
  data() {
    return {
      filterData: {
        page: 1,
        term: "",
      },
      pageActive: "active",
      disableIndex: [],
    };
  },
  components: {
    PageHeader,
    Loading,
  },
  computed: {
    ...mapState({
      serviceBulletins: (state) => state.serviceBulletins.serviceBulletins,
      pagination: (state) => state.serviceBulletins.pagination,
    }),
  },
  mounted() {
    if (this.$route.query.page) {
      if (this.$route.query.term) {
        this.filterData.term = this.$route.query.term;
      }
      this.filterData.page = this.$route.query.page;
      this.filterNews(this.filterData);
    } else {
      this.getServiceBulletins();
    }
  },
  methods: {
    ...mapActions({
      getServiceBulletinsList: "serviceBulletins/getServiceBulletinsList",
    }),
    getServiceBulletins() {
      this.getServiceBulletinsList().then((res) => {});
    },
    filter() {
      this.$router.push(
        "/service-bulletins?" + new URLSearchParams(this.filterData).toString()
      );
      this.getServiceBulletinsList(this.filterData)
        .then((res) => {})
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    },
    resetFilter() {
      this.filterData = {
        page: 1,
        term: "",
      };
      this.pageActive = "";
      this.filter();
    },
    clickCallback(pageNum) {
      this.filterData.page = pageNum;
      this.pageActive = "active";
      this.filter();
    },
    download(url) {
      this.disableIndex.push(url.id);
      let file = url.file;
      http
        .get(file, {
          responseType: "blob",
        })
        .then((res) => {
          let blob = new Blob([res.data], {
            type: res.headers["content-type"],
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "service-bulletins";
          link.click();
        })
        .catch((err) => {
          if (err.response.status === 404) {
            this.$toasted.error(
              "File could not be located on server. Please contact TYM for assistance."
            );
          }
        })
        .finally(() => {
          this.disableIndex = this.disableIndex.filter((i) => i !== url.id);
        });
    },
  },
};
</script>

<style lang="scss">
</style>
