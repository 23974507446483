<template>
  <section class="manage col-span-12">
    <div class="container grid grid-cols-1 gap-0">
      <PageHeader
        title="News"
        :backLink="false"
        :saveButton="false"
        :discardLink="false"
      />
      <div class="filter-block filter-block-border">
        <div>
          <div class="table-filter-search-input">
            <div class="form-group">
              <label class="control-label">Search</label>
              <input
                type="text"
                name="search"
                v-model="filterData.title"
                v-on:keyup.enter="filter"
                class="search"
                placeholder=""
              />
              <inline-svg
                class="search-icon"
                :src="require('@/assets/image/icons/search.svg')"
              />
            </div>
          </div>
        </div>
        <div class="card">
          <div class="btn btn-black px-40" @click="filter">
            <div class="text"><a>Apply</a></div>
          </div>
          <div class="btn redo px-15" @click="resetFilter">
            <a>
              <inline-svg :src="require('@/assets/image/icons/redo.svg')" />
            </a>
          </div>
        </div>
      </div>
      <div class="video-block ml-minus-17" v-if="news && news.length">
        <div class="card ml-17" v-for="item in news">
          <div class="name">
            <span>{{ item.posted_on_1 }}</span>
            <div>{{ item.title }}</div>
            <router-link :to="'news/' + item.id">Read More</router-link>
          </div>
        </div>
      </div>
      <div v-else class="text-center mt-5">
        No results found. Please adjusts your search criteria message.
      </div>
      <div class="pages" v-if="pagination.total_pages > 1">
        <div class="pages-quantity">
          <template v-if="!isLoading">
            Page {{ filterData.page }} of {{ pagination.total_pages }} - Showing
            {{ pagination.per_page }} Results of {{ pagination.total }} Total
          </template>
        </div>
        <paginate
          v-if="pagination.total_pages"
          :page-count="pagination.total_pages"
          :initial-page="initialPage - 1"
          :page-range="6"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="'&laquo;'"
          :next-text="'&raquo;'"
          :container-class="'pages-btn'"
          :active-class="pageActive"
        >
        </paginate>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../component/PageHeader";
import { mapActions, mapState } from "vuex";

export default {
  name: "News",
  data() {
    return {
      filterData: {
        page: 1,
      },
      pageActive: "active",
    };
  },
  components: {
    PageHeader,
  },
  computed: {
    ...mapState({
      news: (state) => state.news.news,
      pagination: (state) => state.news.pagination,
      initialPage: (state) => state.news.initialPage,
    }),
  },
  mounted() {
    if (this.$route.query.page) {
      if (this.$route.query.title) {
        this.filterData.title = this.$route.query.title;
      }
      this.filterData.page = this.$route.query.page;
      this.filterNews(this.filterData);
    } else {
      this.getNewsList();
    }
  },
  methods: {
    ...mapActions({
      getNews: "news/getNews",
      filterNews: "news/filterNews",
      getInitialPage: "news/getInitialPage",
    }),
    getNewsList() {
      this.filterData.page = this.initialPage;
      this.getNews({ page: this.filterData.page })
        .then((res) => {})
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    },
    filter() {
      this.$router.push(
        "/news?" + new URLSearchParams(this.filterData).toString()
      );
      this.filterNews(this.filterData)
        .then((res) => {})
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    },
    resetFilter() {
      this.pageActive = "";
      this.filterData = {
        page: 1,
      };
      this.getInitialPage(1);
      this.filter();
    },
    clickCallback(pageNum) {
      this.getInitialPage(pageNum);
      this.filterData.page = pageNum;
      this.pageActive = "active";
      this.filter();
    },
  },
};
</script>

<style lang="scss">
</style>
