import { render, staticRenderFns } from "./addCategories.vue?vue&type=template&id=143106ea&scoped=true&"
import script from "./addCategories.vue?vue&type=script&lang=js&"
export * from "./addCategories.vue?vue&type=script&lang=js&"
import style0 from "./addCategories.vue?vue&type=style&index=0&id=143106ea&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "143106ea",
  null
  
)

export default component.exports