import { http } from '../../../api';

const manageVideos = {
    namespaced: true,
    state: {
        videos: [],
        pagination: {},
        videoItem: {},
        updateVideoData: {},
        initialPage: 1
    },
    actions: {
        getVideos: ({ commit }, params) => {
            const q = new URLSearchParams(params);
            return http.get('/videos?' + q.toString()).then(res => {
                commit('setVideosData', res.data.data)
            });
        },
        filterVideos: ({ commit }, params) => {
            const q = new URLSearchParams(params);
            return http.get('/videos/filter?' + q.toString()).then(res => {
                commit('setVideos', res.data.data)
            });
        },
        removeVideo: ({ commit }, params) => {
            return http.delete('/videos/' + params.id).then(res => {
                commit('deleteVideo', params.id)
                return res;
            });
        },
        getVideoItem: ({ commit }, params) => {
            return http.get('/videos/' + params.id).then(res => {
                commit('setVideoItem', res.data.data);
                return res.data.data;
            });
        },
        createVideo: ({ commit }, params) => {
            return http.post('/videos', params).then(res => {
                return res;
            });
        },
        editVideoData: ({ commit }, params) => {
            return http.get('/videos/' + params.id + '/edit').then(res => {
                commit('setEditVideo', res.data.data)
            });
        },
        updateVideo: ({ commit }, params) => {
            return http.post('/videos/' + params.id, params.formData).then(res => {
                return res;
            });
        },
        getInitialPage: ({ commit }, params) => {
            commit('setInitialPage', params)
        },
    },
    mutations: {
        setInitialPage(state, page) {
            state.initialPage = page
        },
        deleteVideo(state, id) {
            state.videos = state.videos.filter(item => id !== item.id)
        },
        setEditVideo(state, data) {
            state.updateVideoData = data
        },
        setVideosData(state, data){
            state.videos = data.videos.data;
            state.pagination = data.videos.pagination;
        },
        setVideos(state, data){
            state.videos = data.media.data;
            state.pagination = data.media.pagination;
        },
        setVideoItem(state, data){
            state.videoItem = data.videoItem;
            state.pagination = data.pagination;
        },
    },
    getters: {
        videos: state => state.videos,
        pagination: state => state.pagination,
        videoItem: state => state.videoItem,
        updateVideoData: state => state.updateVideoData,
        initialPage: state => state.initialPage
    }
};

export default manageVideos;
