<template>
  <section class="details-page model-info-details col-span-12">
    <div class="container">
      <PageHeader
        backText="Back to Model Info Lookup"
        :backRoute="{ name: 'modelInfoLookup', query: { ...indexFilters } }"
        :saveButton="false"
        :discardLink="false"
        :details="{
          title: entry.model,
          fields: {
            'Type': entry.model_type,
            'Service Model': entry.service_model,
          },
        }"
      >
        <template v-slot:actions>
          <router-link
            tag="button"
            class="btn btn-info px-40"
            :to="{ name: 'modelInfoEdit', params: {model: entry.model}}"
            v-if="user && userCanEdit"
            >Edit</router-link
          >
        </template>
      </PageHeader>
      <div class="details-section">
        <div class="subtitle">Model Info</div>
        <div class="info-block info-block--vertical">
          <div class="info">
            <label class="info-label">Model ID</label>
            <span class="info-value">{{entry.model}}</span>
          </div>       
          <div class="info">
            <label class="info-label">Model Type</label>
            <span class="info-value">{{entry.model_type}}</span>
          </div>
          <div class="info">
            <label class="info-label">Service Model</label>
            <span class="info-value">{{entry.service_model}}</span>
          </div>     
          <div class="info">
            <label class="info-label">Weight</label>
            <span class="info-value">{{ entry.weight.toLocaleString("en-US") }}</span>
          </div>
          <div class="info">
            <label class="info-label">Description</label>
            <span class="info-value">{{entry.description}}</span>
          </div>
          <div class="info">
            <label class="info-label">Serial Prefixes</label>
            <span
              v-for="(prefix, p) in prefixes"
              :key="`prefix_${p}`"
              class="info-value"
            >
              {{prefix}}
            </span>
          </div>
          <div class="info" v-if="entry.program_default">
            <span class="info-note block mt-4">*Included in default retail program model list</span>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import PageHeader from "../../../component/PageHeader";
import { mapActions, mapState } from "vuex";

export default {
  name: "ModelInfoDetail",
  data() {
    return {
      prefixes: [],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      entry: state => state.modelInfo.model,
      indexFilters: state => state.modelInfo.indexFilters,
    }),
    userCanEdit: function() {
      return (
        this.user.permissions.includes('GLOBAL') || 
        this.user.permission_actions?.["Model Info"]?.Edit
      )
    }
  },
  components: {
    PageHeader,
  },
  created() {
    this.getModel(this.$route.params.model)
      .then((res) => {
        console.log(res);
        this.prefixes = res.prefixes;
      })
      .catch((err) => {
        this.$toasted.error(err.response.data.message || "Server Error");
      });
  },
  methods: {
    ...mapActions({
      getModel: "modelInfo/getSingle",
    }),
  },
};
</script>

<style scoped lang="scss">
.details-page.model-info-details {
  .info-block {
    max-width: 600px;
    margin-bottom: 32px;
  }
}
</style>
