<template>
  <section class="manage col-span-12">
    <div class="container grid grid-cols-1 gap-0">
      <PageHeader
        backText="Back to Gallery"
        :backRoute="{
          name: 'Gallery',
        }"
        :saveButton="false"
        :discardLink="false"
        :details="{
          title: galleryFolder.title,
          subtitle: galleryFolder.count + ' Documents',
        }"
      />
      <div class="video-block ml-minus-15">
        <router-link
          tag="div"
          class="video-card ml-15"
          :to="'/gallery-detail/' + $route.params.id + '/' + image.slug"
          v-for="(image, i) in galleryFolder.images"
          :key="'gallery_image_' + i"
        >
          <div class="video">
            <img v-if="image.file" :src="image.file" alt="" />
            <img v-else src="../../../assets/image/no-image.png" alt="" />
          </div>
          <div class="video-title">
            <p class="text-center">{{ image.title }}</p>
          </div>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../component/PageHeader";
import { mapActions, mapState } from "vuex";

export default {
  name: "GalleryAdvertisements",
  data() {
    return {
      filterData: {
        page: 1,
      },
      pageActive: "active",
    };
  },
  components: {
    PageHeader,
  },
  computed: {
    ...mapState({
      ...mapState({
        galleryFolder: (state) => state.gallery.galleryFolder,
      }),
    }),
  },
  mounted() {
    this.getGallery();
  },
  methods: {
    ...mapActions({
      getGalleryList: "gallery/getGalleryList",
    }),
    getGallery() {
      this.getGalleryList({ slug: this.$route.params.id })
        .then((res) => {})
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    },
  },
};
</script>

<style lang="scss">
</style>
