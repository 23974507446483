<template>
  <section class="review-order col-span-12">
    <div class="container">
      <PageHeader
        title="Review Order"
        backText="Back to Orders"
        :backRoute="{ name: 'Orders', query: { ...orderFilters } }"
        saveText="Submit Order"
        @save="confirm"
        :buttonsDisabled="disableBtn"
        discardText="Edit Order"
        @discard="
          $router.push({ name: 'editTractorOrder', params: { id: order.id } })
        "
      />
      <div class="review-section grid grid-cols-12 gap-0">
        <div class="dealer-information col-span-6">
          <div class="subtitle">Dealer & Shipping Information</div>
          <div class="inform mt-5">
            <div class="title" v-if="order.dealer && order.dealer.name">
              <div>Dealer</div>
              <div>{{ order.dealer.name }}</div>
            </div>
            <div class="title" v-if="order && order.shipping_address">
              <div>Shipping Address</div>
              <div>{{ order.shipping_address }}</div>
            </div>
            <div
              class="title"
              v-if="order.dealer && order.dealer.phone_numbers"
            >
              <div>Phone Number</div>
              <div>
                <p v-for="dealer in order.dealer.phone_numbers">
                  {{ dealer.phone_number }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="order-summary col-span-6">
          <div class="container">
            <div class="flex items-center justify-between">
              <div class="subtitle">Order Summary</div>
              <p>
                <span class="key">
                  <inline-svg :src="require('@/assets/image/icons/key.svg')" />
                </span>
                Delivered Assembled
              </p>
            </div>
            <div class="orders" v-for="lineItem in lineItems">
              <div class="order-image">
                <img v-if="lineItem.img" :src="lineItem.img" alt="model" />
              </div>
              <div class="order-information">
                <div>
                  <div class="information">
                    <div class="title">
                      <div>Model</div>
                      <div>
                        {{
                          lineItem.model
                            ? lineItem.model.model
                            : "None Selected"
                        }}
                      </div>
                    </div>
                    <div class="title">
                      <div>Configuration</div>
                      <div v-if="lineItem.configuration">
                        {{ lineItem.configuration.name }} /
                        {{ lineItem.configuration.description }}
                      </div>
                      <div v-else>None Selected</div>
                    </div>
                    <div class="title">
                      <div>Tires</div>
                      <div>
                        {{ lineItem.tires ? lineItem.tires.name : "" }}
                        <span
                          class="key"
                          v-if="lineItem.configuration_assembled"
                        >
                          <inline-svg
                            :src="require('@/assets/image/icons/key.svg')"
                          />
                        </span>
                      </div>
                    </div>
                    <div
                      class="title"
                      v-for="(attachment, index) in lineItem.attachments"
                      :key="index"
                    >
                      <div>{{ attachment.category.name }}</div>
                      <div>
                        {{ attachment.name_description }}
                        <span class="key" v-if="attachment.is_installed">
                          <inline-svg
                            :src="require('@/assets/image/icons/key.svg')"
                          />
                        </span>
                      </div>
                    </div>
                    <div class="title">
                      <div>Options</div>
                      <div>
                        <div v-for="option in lineItem.options">
                          <div class="description">
                            {{ option.name_description }}
                            <span class="key" v-if="option.is_installed">
                              <inline-svg
                                :src="require('@/assets/image/icons/key.svg')"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="title">
                      <div>Quantity</div>
                      <div>{{ lineItem.quantity }}</div>
                    </div>
                    <div class="title">
                      <div>Terms</div>
                      <div>
                        <span v-for="term in lineItem.terms">
                          {{ term.term }}</span
                        >
                      </div>
                    </div>
                    <div class="title">
                      <div>Notes</div>
                      <div>{{ lineItem.notes }}</div>
                    </div>
                    <div class="title">
                      <div>PO Number</div>
                      <div>{{ lineItem.po_number }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../component/PageHeader";
import ClickOutside from "vue-click-outside";
import { http } from "../../../api";
import { mapActions, mapState } from "vuex";

export default {
  name: "ReviewOrder",
  data() {
    return {
      lineItems: [],
      order: {},
      dealerOpen: false,
      addressOpen: false,
      disableBtn: false,
      dealerName: "",
      addressName: "",
    };
  },
  computed: {
    ...mapState({
      orderFilters: (state) => state.order.indexFilters,
    }),
  },
  components: {
    PageHeader,
  },
  mounted() {
    this.getOrderReview();
  },
  methods: {
    ...mapActions({
      confirmOrder: "order/confirmOrder",
    }),
    confirm() {
      this.disableBtn = true;
      this.confirmOrder({ id: this.$route.params.id })
        .then((res) => {
          this.$router.push({
            name: "Orders",
            query: { ...this.orderFilters },
          });
          this.$toasted.success("Order submitted successfully");
          localStorage.toastedId = this.$toasted.id;
        })
        .catch((err) => {
          this.disableBtn = false;
        });
    },
    getOrderReview() {
      http
        .get("orders/" + this.$route.params.id + "/preview")
        .then((res) => {
          this.lineItems = res.data.data.lineItems;
          this.order = res.data.data.order;
        })
        .catch((err) => {});
    },
    dropdownOpenModal() {
      this.dealerOpen = !this.dealerOpen;
    },
    dropdownOpenAddressModal() {
      this.addressOpen = !this.addressOpen;
    },
    dropdownHide() {
      this.dealerOpen = false;
    },
    dropdownAddressHide() {
      this.addressOpen = false;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style scoped>
</style>
