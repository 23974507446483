var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"add-user col-span-12"},[_c('ValidationObserver',{ref:"form",staticClass:"container",attrs:{"autocomplete":"off","tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.saveUser($event)}}},[_c('PageHeader',{attrs:{"title":"Edit Account Settings","backText":"Back to Dashboard","backRoute":{ name: 'Home' },"disabledButtons":_vm.disableBtn},on:{"save":_vm.saveUser,"discard":function($event){_vm.showDiscardModal = true}}}),_c('div',{staticClass:"user-details"},[_c('div',{staticStyle:{"margin":"0 0 40px"}}),_c('div',{staticClass:"user-block-group"},[_c('div',{staticClass:"user-block"},[_c('div',{staticClass:"user-block-container"},[_c('div',{staticClass:"form-group w-full"},[_c('label',{staticClass:"control-label"},[_vm._v("Name")]),_c('ValidationProvider',{attrs:{"rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.name),expression:"formData.name"}],class:classes,attrs:{"type":"text","name":"name"},domProps:{"value":(_vm.formData.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "name", $event.target.value)}}})]}}])})],1),_c('div',{staticClass:"form-group w-full"},[_c('label',{staticClass:"control-label"},[_vm._v("Email Address")]),_c('ValidationProvider',{attrs:{"rules":"email|required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.email),expression:"formData.email"}],class:classes,attrs:{"type":"email","name":"email"},domProps:{"value":(_vm.formData.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "email", $event.target.value)}}})]}}])}),(_vm.user.role.id === 1)?_c('span',[_vm._v(" A valid e-mail address. All e-mails from the system will be sent to this address. The e-mail address is not made public and will only be used if you wish to receive a new password or wish to receive certain news or notifications by e-mail. ")]):_vm._e()],1),_c('div',{staticClass:"form-group w-full"},[_c('label',{staticClass:"control-label"},[_vm._v("New Password")]),_c('div',{staticStyle:{"display":"none"}},[_c('input',{attrs:{"type":"text","autocomplete":"new-password"}}),_c('input',{attrs:{"type":"password","autocomplete":"new-password"}})]),_c('ValidationProvider',{attrs:{"rules":_vm.user_id === 'add' ? 'required|password:@confirm' : '',"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.password),expression:"formData.password"}],class:classes,attrs:{"autocomplete":"off","type":"password","name":"password","value":""},domProps:{"value":(_vm.formData.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "password", $event.target.value)}}})]}}])})],1),_c('div',{staticClass:"form-group w-full"},[_c('label',{staticClass:"control-label"},[_vm._v("Confirm New Password")]),_c('ValidationProvider',{attrs:{"vid":"confirm","rules":_vm.user_id === 'add' ? 'required' : '',"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.password_confirmation),expression:"formData.password_confirmation"}],class:classes,attrs:{"type":"password","name":"confirm_password","value":""},domProps:{"value":(_vm.formData.password_confirmation)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "password_confirmation", $event.target.value)}}})]}}])})],1)])]),_c('div',{staticClass:"user-block user-block--basics"},[_c('div',{staticClass:"user-block-container"},[_c('div',{staticClass:"user-type"},[_c('dropdown',{attrs:{"status":false,"fieldName":"name","label":"User Type","validation":true,"inputName":"user_type","editValue":_vm.selectedUserType,"all":false,"search":true,"textOnly":true,"disabled":""}})],1),_c('div',{staticClass:"user-type"},[_c('dropdown',{attrs:{"status":false,"fieldName":"name","label":"User Group","validation":true,"inputName":"user_group","editValue":_vm.selectedUserGroup,"all":false,"search":true,"textOnly":true,"disabled":""}})],1),_c('div',{staticClass:"user-type"},[_c('dropdown',{attrs:{"data":_vm.dealership,"status":false,"fieldName":"name","label":"Dealership","validation":true,"inputName":"territory_manager","editValue":_vm.selectedDealership,"all":false,"search":true,"textOnly":!_vm.userCanEditBasics,"disabled":!_vm.userCanEditBasics},on:{"select":_vm.selectDealership}})],1)])])]),_c('div',{staticClass:"send"},[_c('div',{staticClass:"edit-link",on:{"click":function($event){_vm.showDiscardModal = true}}},[_vm._v("Discard")]),_c('button',{staticClass:"btn btn-black px-60",class:_vm.disableBtn ? 'opacity-50 cursor-wait' : '',attrs:{"disabled":_vm.disableBtn,"type":"submit"}},[_vm._v(" Save ")])])])],1),_c('ConfirmModal',{attrs:{"text":"Discard changes?","confirmBtn":"Discard","show":_vm.showDiscardModal},on:{"confirm":function($event){return _vm.$router.push('/admin/users')},"close":function($event){_vm.showDiscardModal = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }