<template>
  <div class="dropdown-settings relative">
    <button
      aria-label="actions-menu"
      type="button"
      class="button btn-td-hover"
      :class="openClose ? 'active' : ''"
      v-click-outside="hide"
      @click="openClose = !openClose"
    >
      <inline-svg
        class="m-auto"
        :src="require('@/assets/image/icons/more-alt.svg')"
      />
    </button>
    <div v-if="openClose">
      <div class="dropdown">
        <div>-</div>
        <div class="delete" @click="openConfirmModal">Delete</div>
        <div>-</div>
      </div>
    </div>
    <ConfirmModal
      text="Are you sure you want to delete this Co-op Claim?"
      confirmBtn="Delete Co-op Claim"
      :show="showDeleteCoopModal"
      @confirm="deleteCoop"
      @close="closeConfirmModal"
    />
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import { mapActions } from "vuex";
import ConfirmModal from "../ConfirmModal";

export default {
  name: "CoOpClaimDropdown",
  props: ["item"],
  data() {
    return {
      openClose: false,
      showDeleteCoopModal: false,
    };
  },
  components: {
    ConfirmModal,
  },
  methods: {
    ...mapActions({
      getCoops: "coops/getCoops",
      removeCoop: "coops/removeCoop",
    }),
    deleteCoop() {
      this.removeCoop({ id: this.item.item.id })
        .then((res) => {
          this.getCoops({ page: this.item.page });
          this.$toasted.success(res.data.message);
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        })
        .finally(() => {
          this.closeConfirmModal();
        });
    },
    openConfirmModal() {
      this.showDeleteCoopModal = true;
    },
    closeConfirmModal() {
      this.showDeleteCoopModal = false;
    },
    hide() {
      this.openClose = false;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>