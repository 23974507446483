<template>
  <section class="manage col-span-12">
    <div class="container grid grid-cols-1 gap-0">
      <PageHeader
        title="Manage News"
        :saveButton="false"
        :discardLink="false"
        :addButton="true"
        addText="Add New Post"
        @add="$router.push({ name: 'addNews' })"
        backText="Back to Settings"
        :backRoute="{ name: 'AdminSettings' }"
      />
      <div class="filter-block">
        <div>
          <div class="table-filter-search-input">
            <div class="form-group">
              <label class="control-label">Search</label>
              <input
                v-on:keyup.enter="filter"
                v-model="filterData.username"
                type="text"
                name="search"
                value=""
                class="search"
                placeholder="Username"
              />
              <inline-svg
                class="search-icon"
                :src="require('@/assets/image/icons/search.svg')"
              />
            </div>
          </div>
          <div class="table-filter-dealership-select">
            <dropdown
              :data="authors"
              :status="false"
              fieldName="name"
              label="Author"
              @select="selectAuthor"
              :validation="false"
              inputName="author"
              :editValue="selectedAuthor"
            ></dropdown>
          </div>
          <div class="table-filter-dealership-select user-type">
            <dropdown
              :data="statuses"
              fieldName="name"
              label="Status"
              @select="selectStatus"
              :validation="false"
              inputName="status"
              :editValue="selectedStatus"
            ></dropdown>
          </div>
        </div>
        <div class="card">
          <button class="btn btn-black px-40" @click="filter">
            <span class="text">Apply</span>
          </button>
          <div class="btn redo px-15" @click="resetFilter">
            <a>
              <inline-svg :src="require('@/assets/image/icons/redo.svg')" />
            </a>
          </div>
        </div>
      </div>
      <div class="table-block col-span-1">
        <data-table
          :page="filterData.page"
          :header="newsTable.header"
          :data="news"
          :link="{ url: '/admin/news/', name: 'title' }"
          class="media-table"
          :isLoading="isLoading"
        />
      </div>
      <div class="pages" v-if="pagination.total && pagination.total_pages > 1">
        <div class="pages-quantity">
          <template v-if="!isLoading">
            Page {{ filterData.page }} of {{ pagination.total_pages }} - Showing
            {{ pagination.per_page }} Results of {{ pagination.total }} Total
          </template>
        </div>
        <paginate
          v-if="pagination.total_pages"
          :page-count="pagination.total_pages"
          :page-range="6"
          :margin-pages="1"
          :initial-page="manageInitialPage - 1"
          :click-handler="clickCallback"
          :prev-text="'&laquo;'"
          :next-text="'&raquo;'"
          :container-class="'pages-btn'"
          :active-class="pageActive"
        >
        </paginate>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../../component/PageHeader";
import DataTable from "../../../component/DataTable";
import Dropdown from "../../../component/Dropdown";
import { mapActions, mapState } from "vuex";

export default {
  name: "ManageNews",
  data() {
    return {
      baseUrl: "/admin/news",
      isLoading: false,
      selectedAuthor: "All",
      selectedStatus: "All",
      newsTable: {
        header: [
          {
            field: "title",
            name: "News Title",
            transformToComponent: "TransformToLink",
            headerWidth: "15%",
          },
          { field: "posted_on", name: "Posted On" },
          { field: "posted_by", name: "Posted By" },
          {
            field: "status",
            name: "Status",
            transformField: "status",
            transform: this.transformToStatus,
          },
          {
            field: "id",
            name: "Actions",
            transformToComponent: "NewsTableDropdown",
          },
        ],
      },
      filterData: {
        page: 1,
      },
      pageActive: "active",
    };
  },
  components: {
    PageHeader,
    DataTable,
    Dropdown,
  },
  computed: {
    ...mapState({
      news: (state) => state.news.news,
      authors: (state) => state.news.authors,
      statuses: (state) => state.news.statuses,
      pagination: (state) => state.news.pagination,
      manageInitialPage: (state) => state.news.manageInitialPage,
    }),
  },
  mounted() {
    if (this.$route.query.page) {
      if (this.$route.query.username) {
        this.filterData.username = this.$route.query.username;
      }
      if (this.$route.query.author_id) {
        this.filterData.author_id = this.$route.query.author_id;
        let autor = this.authors.filter(
          (item) => item.id == this.$route.query.author_id
        );
        this.selectedAuthor = autor[0].name;
      }
      if (this.$route.query.status) {
        this.filterData.status = this.$route.query.status;
        let status = this.statuses.filter(
          (item) => item.name == this.$route.query.status
        );
        this.selectedStatus = status[0].name;
      }
      this.filterData.page = +this.$route.query.page;
      this.getManageInitialPage(this.filterData.page);
      this.isLoading = true;
      this.filterNews(this.filterData).finally(() => {
        this.isLoading = false;
      });
    } else {
      this.getNewsList();
    }
  },
  methods: {
    ...mapActions({
      getNews: "news/getNews",
      filterNews: "news/filterNews",
      getManageInitialPage: "news/getManageInitialPage",
      setFilterState: "news/setIndexFilters",
    }),
    getNewsList() {
      this.filterData.page = this.manageInitialPage;
      this.isLoading = true;
      this.getNews({ page: this.filterData.page })
        .then((res) => {})
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    filter() {
      this.updateRouter();
      this.isLoading = true;
      this.filterNews(this.filterData)
        .then((res) => {})
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    resetFilter() {
      this.pageActive = "";
      this.filterData = {
        page: 1,
      };
      this.selectedAuthor = "All";
      this.selectedStatus = "All";
      this.filterData.author_id = "";
      this.filterData.status = "";
      this.getManageInitialPage(1);
      this.filter();
    },
    transformToStatus(name, val) {
      let background;
      let border;
      let color;
      let width;
      color = "#0B4067";
      switch (name) {
        case "Publish":
          background = "rgba(99, 220, 43, 0.3)";
          color = "#24610E";
          border = "none";
          width = "77px";
          break;
        case "Draft":
          background = "rgba(212, 4, 4, 0.2)";
          color = "#7D0505";
          border = "none";
          width = "77px";
          break;
      }
      return (
        '<div class="td-status" style="background-color:' +
        background +
        ";color:" +
        color +
        ";border:" +
        border +
        ";width:" +
        width +
        '">' +
        name +
        "</div>"
      );
    },
    selectAuthor(author) {
      this.selectedAuthor = author.name;
      this.filterData.author_id = author.id;
      this.filterData.page = 1;
      if (author.id) {
        this.filterData.author_id = author.id;
      } else {
        delete this.filterData.author_id;
      }
      this.filter();
    },
    selectStatus(status) {
      this.filterData.status = status.name;
      this.selectedStatus = status.name;
      this.filterData.page = 1;
      if (status.name !== "All") {
        this.filterData.status = status.name;
      } else {
        delete this.filterData.status;
      }
      this.filter();
    },
    clickCallback(pageNum) {
      this.getManageInitialPage(pageNum);
      this.filterData.page = pageNum;
      this.pageActive = "active";
      this.filter();
    },

    updateRouter(saveState = true) {
      this.$router.push(
        this.baseUrl + "?" + new URLSearchParams(this.filterData).toString()
      );
      if (saveState) this.setFilterState(this.filterData);
    },
  },
};
</script>

<style scoped>
</style>
