export default function perm (codes = [], errorRoute = 'Home'){

    return function({ next, store }) {

        if (!store.state.auth.user?.permissions)
            return next({
                name: errorRoute
            });
        
        const permissions = store.state.auth.user.permissions;

        if (!Array.isArray(codes)) {
            codes = [codes];
        }

        if (permissions?.includes("GLOBAL")) return next();

        //check all provided permissions
        let allowed = false;
        for (let code of codes) {
            if (permissions.includes(code)) {
                allowed = true;
                break;
            }
        }

        if (allowed) return next();



        //redirect to error route, show message
        setTimeout(() => {
            Vue.toasted.error('You do not have permission to view this page');
            localStorage.toastedId = Vue.toasted.id;
        }, 200);

        return next({
            name: errorRoute
        });

    }
  }
  