<template>
  <section class="manage col-span-12">
    <div class="container grid grid-cols-1 gap-0">
      <PageHeader
        title="Manuals"
        :backLink="false"
        :saveButton="false"
        :discardLink="false"
      />
      <div class="filter-block filter-block-border">
        <div>
          <div class="table-filter-search-input">
            <div class="form-group">
              <label class="control-label">Search</label>
              <input
                type="text"
                name="search"
                v-model="filterData.term"
                v-on:keyup.enter="applyFilter"
                class="search"
                placeholder=""
              />
              <inline-svg
                class="search-icon"
                :src="require('@/assets/image/icons/search.svg')"
              />
            </div>
          </div>
          <div class="table-filter-dealership-select">
            <dropdown
              :data="types"
              :status="false"
              fieldName="name"
              label="Type"
              @select="selectType"
              :validation="false"
              inputName="type"
              :editValue="selectedType"
            ></dropdown>
          </div>
        </div>
        <div class="card">
          <div class="btn btn-black px-40" @click="applyFilter">
            <div class="text"><a>Apply</a></div>
          </div>
          <div class="btn redo px-15" @click="resetFilter">
            <a>
              <inline-svg :src="require('@/assets/image/icons/redo.svg')" />
            </a>
          </div>
        </div>
      </div>
      <div v-if="manuals && Object.keys(manuals).length">
        <div class="cards-block" v-for="manual in manuals">
          <div class="subtitle">{{ manual.title }}</div>
          <div class="video-block border-top-none">
            <div class="card-rs" v-for="sub in manual.sub">
              <div class="card-container">
                <p>{{ sub.title }}</p>
                <button class="btn btn-info px-15" @click="download(sub)">
                  <loading v-if="disableIndex.includes(sub.id)" />
                  <div class="text">Download</div>
                  <div class="create">
                    <inline-svg
                      :src="require('@/assets/image/icons/download.svg')"
                    />
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="text-center mt-5">
        No results found. Please adjusts your search criteria message.
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../component/PageHeader";
import { mapActions, mapState } from "vuex";
import Dropdown from "../../component/Dropdown";
import Loading from "@/components/component/Loading";
import { http } from "@/api";

export default {
  name: "Manuals",
  data() {
    return {
      selectedType: "All",
      disableIndex: [],
      filterData: {},
    };
  },
  components: {
    PageHeader,
    Dropdown,
    Loading,
  },
  computed: {
    ...mapState({
      manuals: (state) => state.manuals.manuals,
      types: (state) => state.manuals.types,
    }),
  },
  mounted() {
    if (this.$route.query.term || this.$route.query.status) {
      if (this.$route.query.term) {
        this.filterData.term = this.$route.query.term;
      }
      if (this.$route.query.type) {
        this.filterData.type = this.$route.query.type;
        let type = this.types.filter(
          (item) => item.id == this.$route.query.type
        );
        this.selectedType = type[0].name;
      }
      this.getManuals(this.filterData);
    } else {
      this.getManualsList();
    }
  },
  methods: {
    ...mapActions({
      getManuals: "manuals/getManuals",
    }),
    getManualsList() {
      this.getManuals().then((res) => {});
    },
    applyFilter() {
      this.$router.push(
        "/manuals?" + new URLSearchParams(this.filterData).toString()
      );
      this.getManuals(this.filterData).then((res) => {});
    },
    selectType(type) {
      if (type.name === "All") {
        this.selectedType = type.name;
        delete this.filterData.type;
        this.applyFilter();
      } else {
        this.selectedType = type.name;
        this.filterData.type = type.id;
        this.applyFilter();
      }
    },
    resetFilter() {
      this.selectedType = "All";
      delete this.filterData.type;
      this.filterData.term = "";
      delete this.filterData.term;
      this.applyFilter();
    },
    download(url) {
      this.disableIndex.push(url.id);
      let file = url.file;
      http
        .get(file, {
          responseType: "blob",
        })
        .then((res) => {
          let blob = new Blob([res.data], {
            type: res.headers["content-type"],
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = url.title;
          link.click();
        })
        .catch((err) => {
          if (err.response.status === 404) {
            this.$toasted.error(
              "File could not be located on server. Please contact TYM for assistance."
            );
          }
        })
        .finally(() => {
          this.disableIndex = this.disableIndex.filter((i) => i !== url.id);
        });
    },
  },
};
</script>

<style lang="scss">
</style>
