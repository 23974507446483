<template>
  <!-- Custom Component -->
  <div
    class="
      border border-gray-300
      bg-gray-100
      rounded-md
      px-2
      py-1
      flex
      items-center
      font-standard
      text-sm
    "
    v-bind:class="inputClass"
  >
    <template v-if="file">
      <!-- File is uploaded -->
      <div
        data-uploaded-file-name
        class="cursor-pointer leading-none py-1 mr-4 hover:underline"
        @click="downloadFile()"
      >
        {{ file && (file.displayName || file.name) }}
      </div>
      <inline-svg
        v-if="
          !disabled &&
          (setOptions.allowLoadedFileDelete || !loadFile || !loadFile.url)
        "
        @click="deleteFile()"
        class="cursor-pointer ml-auto flex-shrink-0"
        :src="require('@/assets/image/icons/delete.svg')"
      />
    </template>
    <template v-else>
      <!-- No File Selected -->

      <label v-bind:class="{ disabled: disabled }">
        <ValidationProvider
          tag="div"
          :rules="validation ? 'required' : ''"
          v-slot="{ classes }"
        >
          <input
            type="file"
            :name="name"
            @change="uploadFile($event.target.files)"
            :accept="accept"
            :disabled="disabled"
          />
          <div
            data-choose-file-button
            class="
              border border-gray-900
              bg-gray-200
              rounded-md
              px-3
              py-1
              font-medium
              leading-none
            "
            :class="classes"
          >
            {{ setOptions.chooseFileMessage }}
          </div>
        </ValidationProvider>
      </label>
      <span
        data-no-file-message
        class="text-gray-800 font-medium ml-4"
        v-if="setOptions.showNoFileMessage"
        >No File Chosen</span
      >
    </template>
  </div>
</template>

<script>
import { http } from "../../../../api";

const MB = 1048576;

export default {
  name: "FileInput",
  data() {
    return {
      file: null,
      objUrl: null,
      defaultOptions: {
        removeFileOnDelete: true,
        allowLoadedFileDelete: true,
        showNoFileMessage: true,
        chooseFileMessage: "Choose File",
      },
      setOptions: {},
    };
  },
  props: {
    name: {
      type: String,
      required: false,
      default: null,
    },
    accept: {
      type: String,
      required: false,
      default: "*",
    },
    inputClass: {
      type: String,
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    validation: {
      type: Boolean,
      required: false,
      default: false,
    },
    maxSize: {
      type: Number,
      required: false,
      default: 2,
    },
    loadFile: {
      type: Object,
      required: false,
      default() {
        return null;
      },
    },
    options: {
      type: Object,
      required: false,
      default() {
        return null;
      },
    },
  },
  computed: {},
  created() {
    if (this.loadFile?.url) {
      this.file = { ...this.loadFile };
    }

    this.setOptions = {
      ...this.defaultOptions,
      ...this.options,
    };
  },
  watch: {
    loadFile: {
      handler(newVal, oldVal) {
        if (newVal?.url !== oldVal?.url) {
          this.file = newVal ? { ...newVal } : null;
        }
      },
      deep: true,
    },
    options: function (newVal) {
      this.setOptions = {
        ...this.defaultOptions,
        ...newVal,
      };
    },
  },
  methods: {
    async uploadFile(file) {
      if (this.maxSize && file[0].size > this.maxSize * MB) {
        this.$toasted.error(
          `ERROR: The file exceeds the maximum size of ${parseFloat(
            this.maxSize
          )} MB`
        );
        return;
      }

      this.file = file[0];

      //set object URL for local download
      if (this.objUrl) URL.revokeObjectURL(this.objUrl);
      this.objUrl = URL.createObjectURL(this.file);

      const reader = new FileReader();
      reader.readAsDataURL(file[0]);

      reader.onload = () => {
        const data = {
          file: file[0],
          fileData: reader.result,
        };

        this.$emit("change", data);
      };
      reader.onerror = (error) => {
        const data = {
          file: file[0],
          fileData: null,
          error: error,
        };
      };
    },

    async downloadFile() {
      const link = document.createElement("a");

      if (this.objUrl) {
        link.href = this.objUrl;
      } else {
        try {
          const res = await http.get(this.file.url, {
            responseType: "blob",
          });

          let blob = new Blob([res.data], {
            type: res.headers["content-type"],
          });
          link.href = URL.createObjectURL(blob);
        } catch (err) {
          this.$toasted.error("Download failed");
          return;
        }
      }

      link.download = this.file?.name || "download";
      link.click();
    },

    deleteFile() {
      if (this.setOptions.removeFileOnDelete) {
        this.file = null;
      }

      this.$emit("delete-file");
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  cursor: pointer;

  &.disabled {
    opacity: 0.8;
    cursor: default;
  }
}

input[type="file"] {
  display: none;

  &:disabled + div {
    opacity: 0.7;
  }
}

.is-invalid {
  border: 2px solid #e3342f !important;
}
</style>
