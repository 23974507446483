import { http } from '../../../api';

const programs = {
    namespaced: true,
    state: {
        programsList: {
            types: [],
            programs: [],
            filteredPrograms: [],
        },
        availablePrograms: [],
        salespeople: [],

        programBatches: {
            batches: [],
            pagination: {},
        },

        programBatchDetails: {
            dealership: {},
            warranty_registration: {},
            program_batch_details: {
                lines: [],
            },
        },

        statuses: [],
        statusList: [],
        pay_methods: [],
        dealerships: [],
        programBatchFilters: {},
    },
    actions: {
        getStatusList: ({commit}, params) => {
            return http.get('retail-program-batches/statuses').then(res => {
                commit('setStatusList', res.data.data);
                return res.data.data;
            })
        },
        getProgramsList: ({commit}, params) => {
            return http.get('retail-programs').then(res => {
                commit('setProgramsList', res.data.data);
                return res.data.data;
            });
        },
        filterProgramsList: ({commit}, programType) => {
            commit('filterProgramsList', programType);
        },
        getAvailablePrograms: ({commit}, params) => {
            return http.get('retail-programs/available', {
                params
            }).then(res => {
                commit('setAvailablePrograms', res.data.data);
            });
        },
        getProgramInfoBySns: ({commit}, params) => {
            return http.get("retail-programs/info-by-sn", {
                params: { sns: [...params] }
            }).then(res => {
                return res.data.data;
            });
        },
        getSalespeople: ({commit}, params) => {
            return http.get('users/filter', {
                params: {
                    permission: 'BONUS_REC',
                    no_pagination: true,
                }
            }).then(res => {
                commit('setSalespeople', res.data.data.users );
            });
        },
        getProgramBatches: ({commit}, params) => {
            return http.get('retail-program-batches', {
                params: params
            }).then(res => {
                commit('setProgramBatches', res.data.data.program_batches);
                commit('setDealerships', res.data.data.dealerships);
            })
        },

        getCreateProgramBatch: ({commit}, params) => {
            return http.get('retail-program-batches/create').then(res => {
                commit('setDealerships', res.data.data.dealerships);
            });
        },

        createProgramBatch: ({commit}, params) => {
            return http.post('retail-program-batches', params).then(res => {
                return res.data.data;
            });
        },

        getProgramBatchDetails: ({commit}, id) => {
            return http.get('retail-program-batches/' + id).then(res => {
                commit('setProgramBatchDetails', res.data.data.details);
                commit('setProgramBatchStatuses', res.data.data.statuses);
            }).catch(err => {
                commit('setProgramBatchDetails', {
                    dealership: {},
                    warranty_registration: {},
                    program_batch_details: {
                        lines: [],
                    },
                });
                throw err;
            });
        },
        getProgramBatchEdit: ({commit}, batchParams) => {

            if (!batchParams.id) throw new Error('No program batch ID given');
            const id = batchParams.id;

            const url = 'retail-program-batches/' + id + '/edit';
            let params = {};
            if (batchParams.history) params = {...params, history: batchParams.history};

            return http.get(url, {
                params: params,
            }).then(res => {
                commit('setProgramBatchDetails', res.data.data.details);
                commit('setProgramBatchStatuses', res.data.data.statuses);
                commit('setPayMethods', res.data.data.pay_methods);
            }).catch(err => {
                commit('setProgramBatchDetails', {
                    dealership: {},
                    warranty_registration: {},
                    program_batch_details: {
                        lines: [],
                    },
                });
                throw err;
            });
        },

        updateProgramBatch: ({commit}, params) => {
            return http.post('retail-program-batches/' + params.id, params.programBatch).then(res => {
                return res.data.data;
            });
        },

        setProgramBatchFilters: ({commit}, params) => {
            commit('setBatchFilters', {...params});
        }
    },
    mutations: {
        setProgramsList(state, data) {
            state.programsList = {
                types: data.types,
                programs: data.programs,
                filteredPrograms: data.programs,
            }
        },
        filterProgramsList(state, type) {
            state.programsList.filteredPrograms = state.programsList.programs.filter(p => (p.program_type_id == type.id));
        },
        setAvailablePrograms(state, data) {
            state.availablePrograms = data;
        },
        setSalespeople(state, data) {
            state.salespeople = data;
        },
        setProgramBatches(state, data) {
            state.programBatches = {
                batches: data.data,
                pagination: data.pagination
            };
        },
        setProgramBatchDetails(state, data) {
            state.programBatchDetails = {
                dealership: data.dealership,
                warranty_registration: data.warranty_registration,
                program_batch_details: data.program_batch_details,
            };
        },
        setProgramBatchStatuses(state, data) {
            state.statuses = data;
        },
        setStatusList(state, data) {
            state.statusList = data;
        },
        setPayMethods(state, data) {
            state.pay_methods = data;
        },
        setDealerships(state, data) {
            state.dealerships = data;
        },

        setBatchFilters(state, data) {
            state.programBatchFilters = data;
        }

    },
    getters: {
        programsList: state => state.allProgramTypes,
        availablePrograms: state => state.availablePrograms,
        salespeople: state => state.salespeople,
        programBatches: state => state.programBatches,
        programBatchDetails: state => state.programBatchDetails,
        statuses: state => state.statuses,
        statusList: state => state.statusList,
        pay_methods: state => state.pay_methods,

        dealerships: state => state.dealerships,

        programBatchFilters: state => state.programBatchFilters,
    }
};

export default programs;
