import { http } from '../../../api';

const serviceBulletins = {
    namespaced: true,
    state: {
        serviceBulletins: [],
        pagination: {}
    },
    actions: {
        getServiceBulletinsList: ({ commit }, params) => {
            const q = new URLSearchParams(params);
            return http.get('/service-bulletins?' + q.toString()).then(res => {
                commit('setServiceBulletins', res.data.data.service_bulletins)
            });
        },
    },
    mutations: {
        setServiceBulletins(state, data){
            state.serviceBulletins = data.data;
            state.pagination = data.pagination;
        },
    },
    getters: {
        serviceBulletins: state => state.serviceBulletins,
        pagination: state => state.pagination
    }
};

export default serviceBulletins;
