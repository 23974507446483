<template>
  <div class="dropdown-settings relative">
    <button
      aria-label="actions-menu"
      type="button"
      class="button btn-td-hover"
      :class="openClose ? 'active' : ''"
      v-click-outside="hide"
      @click="openClose = !openClose"
    >
      <inline-svg
        class="m-auto"
        :src="require('@/assets/image/icons/more-alt.svg')"
      />
    </button>
    <div class="dropdown" v-if="openClose">
      <router-link tag="div" :to="`/admin/users/` + item.item.id"
        >View</router-link
      >
      <router-link
        v-if="item.item.allowed_to_edit"
        tag="div"
        :to="`/admin/user/` + item.item.id + '/edit'"
        >Edit</router-link
      >
      <div
        v-if="item.item.allowed_to_edit"
        class="delete"
        @click="openConfirmModal"
      >
        <span class="text-green-700" v-if="item.item.status === 'Inactive'"
          >Activate</span
        >
        <span v-else>Deactivate</span>
      </div>
    </div>
    <ConfirmModal
      :text="`Are you sure you want to ${
        item.item.status === 'Inactive' ? 'Activate' : 'Deactivate'
      } this user?`"
      :confirmBtn="`${
        item.item.status === 'Inactive' ? 'Activate' : 'Deactivate'
      } User`"
      :button="item.item.status === 'Inactive' ? 'restored' : 'delete'"
      :show="deleteUserModal"
      @confirm="deleteUser"
      @close="closeConfirmModal"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ConfirmModal from "../ConfirmModal";

export default {
  name: "ManageUsersDropdownSettings",
  props: ["item"],
  data() {
    return {
      openClose: false,
      deleteUserModal: false,
    };
  },
  computed: {
    ...mapState({
      authUser: (state) => state.auth.user,
    }),
  },
  components: {
    ConfirmModal,
  },
  mounted() {},
  methods: {
    ...mapActions({
      removeUser: "user/removeUser",
      getUsers: "user/getUsers",
      filterUsers: "user/filterUsers",
    }),
    openConfirmModal() {
      this.deleteUserModal = true;
    },
    deleteUser() {
      this.removeUser({ id: this.item.item.id })
        .then((res) => {
          this.$toasted.success(res.data.message);

          if (this.$route.query.page) {
            const form = {
              page: this.$route.query.page,
            };
            if (this.$route.query.username) {
              form.username = this.$route.query.username;
            }
            if (this.$route.query.type) {
              form.type = this.$route.query.type;
            }
            if (this.$route.query.dealership_id) {
              form.dealership_id = this.$route.query.dealership_id;
            }
            if (this.$route.query.permission) {
              form.permission = this.$route.query.permission;
            }
            console.log(form.page);
            this.filterUsers(form);
          } else {
            this.getUsers({ page: this.item.page });
          }
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        })
        .finally(() => {
          this.closeConfirmModal();
        });
    },
    closeConfirmModal() {
      this.deleteUserModal = false;
    },
    hide() {
      this.openClose = false;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
