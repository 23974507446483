<template>
  <section class="manage col-span-12">
    <div class="container grid grid-cols-1 gap-0">
      <PageHeader
        title="Manage Tractors"
        :saveButton="false"
        :discardLink="false"
        :addButton="true"
        addText="Add New Tractor"
        @add="$router.push({ name: 'addTractor', params: { id: 'add' } })"
        backText="Back to Settings"
        :backRoute="{ name: 'AdminSettings' }"
      />
      <div class="filter-block">
        <div>
          <div class="table-filter-search-input">
            <div class="form-group">
              <label class="control-label">Search</label>
              <input
                type="text"
                name="search"
                v-on:keyup.enter="filter"
                v-model="filterData.model"
                class="search"
                placeholder="Dealer Name"
              />
              <inline-svg
                class="search-icon"
                :src="require('@/assets/image/icons/search.svg')"
              />
            </div>
          </div>
        </div>
        <div class="card">
          <div class="btn btn-black px-40" @click="filter">
            <div class="text"><a>Apply</a></div>
          </div>
          <div class="btn redo px-15" @click="resetFilter">
            <a>
              <inline-svg :src="require('@/assets/image/icons/redo.svg')" />
            </a>
          </div>
        </div>
      </div>
      <div
        class="
          table-block
          col-span-1
          sm:col-span-1
          md:col-span-1
          lg:col-span-1
          xl:col-span-1
        "
      >
        <data-table
          :page="filterData.page"
          :header="fieldTractorsTable.header"
          :data="tractors"
          :link="{ url: '/admin/tractors/', name: 'model' }"
          class="media-table"
          :isLoading="isLoading"
        />
      </div>
      <div class="pages" v-if="pagination.total && pagination.total_pages > 1">
        <div class="pages-quantity">
          <template v-if="!isLoading">
            Page {{ filterData.page }} of {{ pagination.total_pages }} - Showing
            {{ pagination.per_page }} Results of {{ pagination.total }} Total
          </template>
        </div>
        <paginate
          v-if="pagination.total_pages"
          :page-count="pagination.total_pages"
          :page-range="6"
          :margin-pages="1"
          :initial-page="initialPage - 1"
          :click-handler="clickCallback"
          :prev-text="'&laquo;'"
          :next-text="'&raquo;'"
          :container-class="'pages-btn'"
          :active-class="pageActive"
        >
        </paginate>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../../component/PageHeader";
import DataTable from "../../../component/DataTable";
import Dropdown from "../../../component/Dropdown";
import { mapActions, mapState } from "vuex";

export default {
  name: "ManageTractors",
  data() {
    return {
      isLoading: false,
      fieldTractorsTable: {
        header: [
          {
            field: "model",
            name: "Tractor Model",
            transformToComponent: "TransformToLink",
            width: "14%",
            headerWidth: "11%",
          },
          { field: "created_at", name: "Creation Date" },
          {
            field: "id",
            name: "Actions",
            transformToComponent: "TractorDropdownSettings",
            ml: "50%",
            headerMl: "48%",
          },
        ],
      },
      filterData: {
        page: 1,
        model: "",
      },
      pageActive: "active",
    };
  },
  components: {
    PageHeader,
    DataTable,
    Dropdown,
  },
  computed: {
    ...mapState({
      tractors: (state) => state.tractor.tractors,
      pagination: (state) => state.tractor.pagination,
      initialPage: (state) => state.tractor.initialPage,
    }),
  },
  mounted() {
    if (this.$route.query.page) {
      if (this.$route.query.model) {
        this.filterData.model = this.$route.query.model;
      }
      this.filterData.page = +this.$route.query.page;
      this.getInitialPage(this.filterData.page);
      this.isLoading = true;
      this.filterTractors(this.filterData).finally(() => {
        this.isLoading = false;
      });
    } else {
      this.getTractorsList();
    }
  },
  methods: {
    ...mapActions({
      getTractors: "tractor/getTractors",
      filterTractors: "tractor/filterTractors",
      getInitialPage: "tractor/getInitialPage",
    }),
    getTractorsList() {
      this.filterData.page = this.initialPage;
      this.isLoading = true;
      this.getTractors({ page: this.filterData.page })
        .then((res) => {})
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    filter() {
      this.$router.push(
        "/admin/tractors?" + new URLSearchParams(this.filterData).toString()
      );
      this.isLoading = true;
      this.filterTractors(this.filterData)
        .then((res) => {})
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    resetFilter() {
      this.pageActive = "";
      this.filterData = {
        page: 1,
      };
      this.filterData.model = "";
      this.getInitialPage(1);
      this.filter();
    },
    clickCallback(pageNum) {
      this.getInitialPage(pageNum);
      this.filterData.page = pageNum;
      this.pageActive = "active";
      this.filter();
    },
  },
};
</script>

<style scoped>
</style>
