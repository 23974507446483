<template>
  <section class="add-user col-span-12">
    <ValidationObserver
      @submit.prevent="addTractor"
      tag="form"
      class="container"
      ref="form"
    >
      <PageHeader
        :title="isEdit ? 'Edit Tractor' : 'Add Tractor'"
        backText="Back to Mange Tractors"
        :backRoute="{ name: 'ManageTractors' }"
        saveText="Submit"
        @save="addTractor"
        @discard="showDiscardModal = true"
        :buttonsDisabled="buttonDisabled"
      />
      <div class="user-details">
        <div class="subtitle">Enter Tractor Details</div>
        <div class="user-block">
          <div class="user-block-container">
            <div class="form-group w-full">
              <label class="control-label">Tractor Model</label>
              <ValidationProvider
                rules="required"
                tag="div"
                v-slot="{ classes }"
              >
                <input
                  :class="classes"
                  type="text"
                  v-model="formData.tractor_model"
                  name="model"
                  placeholder="(i.e. T254)"
                />
              </ValidationProvider>
            </div>
            <div class="form-group w-full">
              <label class="control-label"
                >Tractor Image (.jpg Files Only)</label
              >
              <div class="add-file">
                <label for="files" id="btn" class="cursor-pointer">
                  <span>Choose File</span>
                  <input
                    id="files"
                    name="userfile"
                    @change="uploadCoverFile($event.target.files)"
                    class="invisible hidden"
                    accept=".jpg"
                    type="file"
                  />
                </label>
                <p v-if="formData.tractor_image === null">No File Chosen</p>
                <p v-else>
                  {{
                    formData.tractor_image.name
                      ? formData.tractor_image.name
                      : formData.tractor_image.file
                  }}
                </p>
              </div>
              <div v-if="errorMessageFiles" class="text-red-500">
                {{ errorMessageFiles }}
              </div>
            </div>
            <!--                        <div class="radio-section">-->
            <!--                            <div class="radio-subtitle">Transmission Types</div>-->
            <!--                            <div class="radio-block">-->
            <!--                                <label for="hst" class="remember">-->
            <!--                                    <input @click="checkedTransmission('HST')" :checked="formData.transmissions.includes('HST')" type="checkbox" id="hst" name="agree">-->
            <!--                                    <span></span>-->
            <!--                                    <div>HST</div>-->
            <!--                                </label>-->
            <!--                                <label for="gear" class="remember">-->
            <!--                                    <input @click="checkedTransmission('Gear')" :checked="formData.transmissions.includes('Gear')" type="checkbox" id="gear" name="agree">-->
            <!--                                    <span></span>-->
            <!--                                    <div>Gear</div>-->
            <!--                                </label>-->
            <!--                                <label for="powerShuttle" class="remember">-->
            <!--                                    <input @click="checkedTransmission('Power Shuttle')" :checked="formData.transmissions.includes('Power Shuttle')" type="checkbox" id="powerShuttle" name="agree">-->
            <!--                                    <span></span>-->
            <!--                                    <div>Power Shuttle</div>-->
            <!--                                </label>-->
            <!--                            </div>-->
            <!--                            <div class="radio-subtitle">ROPs</div>-->
            <!--                            <div class="radio-block">-->
            <!--                                <label for="rops" class="remember">-->
            <!--                                    <input @click="checkedRops('ROPs')" :checked="formData.rops.includes('ROPs')" type="checkbox" id="rops" name="agree">-->
            <!--                                    <span></span>-->
            <!--                                    <div>ROPs</div>-->
            <!--                                </label>-->
            <!--                                <label for="cabin" class="remember">-->
            <!--                                    <input @click="checkedRops('Cabin')" :checked="formData.rops.includes('Cabin')" type="checkbox" id="cabin" name="agree">-->
            <!--                                    <span></span>-->
            <!--                                    <div>Cabin</div>-->
            <!--                                </label>-->
            <!--                            </div>-->
            <!--                        </div>-->
          </div>
        </div>
        <!--                <div class="subtitle">Enter Tire Details</div>-->
        <!--                <div class="user-block">-->
        <!--                    <div class="user-block-container">-->
        <!--                      <div class="border-bottom" v-for="(tire,index) in formData.tires">-->
        <!--                        <div class="form-group w-full">-->
        <!--                          <label class="control-label ">Tire Description</label>-->
        <!--                          <ValidationProvider rules="required" tag="div" v-slot="{ classes }">-->
        <!--                            <input :class="classes" type="text" v-model="tire.description" name="tire" placeholder="(i.e. IND Titan)">-->
        <!--                          </ValidationProvider>-->
        <!--                        </div>-->
        <!--                        <div class="form-group w-full">-->
        <!--                          <label class="control-label ">Front Size</label>-->
        <!--                          <ValidationProvider rules="required" tag="div" v-slot="{ classes }">-->
        <!--                            <input :class="classes" type="text" v-model="tire.front_size" name="front_size" placeholder="(i.e. 20x8-10 4PR)">-->
        <!--                          </ValidationProvider>-->
        <!--                        </div>-->
        <!--                        <div class="form-group w-full">-->
        <!--                          <label class="control-label ">Rear Size</label>-->
        <!--                          <ValidationProvider rules="required" tag="div" v-slot="{ classes }">-->
        <!--                            <input :class="classes" type="text" v-model="tire.rear_size" name="rear_size" placeholder="(i.e. 27x12.5-15 4PR)">-->
        <!--                          </ValidationProvider>-->
        <!--                        </div>-->
        <!--                        <inline-svg @click="deleteTire(index)" v-if="formData.tires.length > 1" :src="require('@/assets/image/icons/delete.svg')"/>-->
        <!--                      </div>-->
        <!--                        <div class="btn-tires">-->
        <!--                            <button class="btn btn-info px-15" type="button" @click="addTires">+ Add Tires</button>-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--<div class="subtitle">Enter Attachment Details</div>-->
        <!--<div class="user-block">-->
        <!--<div class="user-block-container">-->
        <!--<div class="radio-block mt-0">-->
        <!--<label for="fel" class="remember">-->
        <!--<input type="checkbox" v-model="formData.front_end_loader" id="fel" name="agree">-->
        <!--<span></span>-->
        <!--<div>Front-End Loader</div>-->
        <!--</label>-->
        <!--<label for="backhoe" class="remember">-->
        <!--<input type="checkbox" v-model="formData.backhoe" id="backhoe" name="agree">-->
        <!--<span></span>-->
        <!--<div>Backhoe</div>-->
        <!--</label>-->
        <!--<label for="mmm" class="remember">-->
        <!--<input type="checkbox" v-model="formData.mid_mount_mower" id="mmm" name="agree">-->
        <!--<span></span>-->
        <!--<div>Mid-Mount Mower</div>-->
        <!--</label>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <!--<div class="subtitle">Enter Add-on Details</div>-->
        <!--<div class="user-block">-->
        <!--<div class="user-block-container">-->
        <!--<div :class="formData.addons.length > 1 ? 'border-bottom' : ''" v-for="(addon,index) in formData.addons">-->
        <!--<div class="form-group w-full flex justify-between">-->
        <!--<div class="w-full">-->
        <!--<label class="control-label ">Add-on Name</label>-->
        <!--<ValidationProvider rules="required" tag="div" class="mt-2" v-slot="{ classes }">-->
        <!--<input :class="classes" type="text" v-model="addon.name" name="backhoe" placeholder="(i.e. Block Heater)">-->
        <!--</ValidationProvider>-->
        <!--</div>-->
        <!--<div class="radio-block installable mt-0">-->
        <!--<label :for="'retailed' + index" class="remember">-->
        <!--<p>Installable (Y/N)</p>-->
        <!--<input type="checkbox" v-model="addon.installable" :id="'retailed' + index" :name="'agree' + index">-->
        <!--<span></span>-->
        <!--</label>-->
        <!--</div>-->
        <!--</div>-->
        <!--<inline-svg @click="deleteOption(index)" v-if="formData.addons.length > 1" :src="require('@/assets/image/icons/delete.svg')"/>-->
        <!--</div>-->
        <!--<div class="btn-tires">-->
        <!--<button class="btn btn-info px-15" type="button" @click="addOption">+ Add Option</button>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <div class="send">
          <div class="edit-link" @click="showDiscardModal = true">Discard</div>
          <button
            class="btn btn-black px-60"
            :disabled="buttonDisabled"
            :class="buttonDisabled ? 'opacity-50 cursor-wait' : ''"
            type="submit"
          >
            Submit
          </button>
        </div>
      </div>
      <ConfirmModal
        text="Discard changes?"
        confirmBtn="Discard"
        :show="showDiscardModal"
        @confirm="$router.push('/admin/tractors')"
        @close="showDiscardModal = false"
      />
    </ValidationObserver>
  </section>
</template>

<script>
import PageHeader from "../../../component/PageHeader";
import Dropdown from "../../../component/Dropdown";
import ConfirmModal from "../../../component/ConfirmModal";
import { mapActions, mapState } from "vuex";

export default {
  name: "addTractor",
  data() {
    return {
      selectedTerritoryManager: "",
      errorMessageFiles: "",
      buttonDisabled: false,
      showDiscardModal: false,
      isEdit: false,
      formData: {
        tractor_image: null,
        // transmissions:[],
        // rops:[],
        front_end_loader: false,
        backhoe: false,
        mid_mount_mower: false,
      },
    };
  },
  components: {
    PageHeader,
    Dropdown,
    ConfirmModal,
  },
  created() {
    if (this.$route.params.id === "add") {
      this.isEdit = false;
    } else {
      this.isEdit = true;
      this.editTractor();
    }
  },
  methods: {
    ...mapActions({
      createTractor: "tractor/createTractor",
      updateTractor: "tractor/updateTractor",
      getTractorEdit: "tractor/getTractorEdit",
    }),
    selectTerritoryManager(item) {
      this.selectedTerritoryManager = item.name;
    },
    addTires() {
      this.formData.tires.push({
        description: "",
        front_size: "",
        rear_size: "",
      });
    },
    addOption() {
      this.formData.addons.push({
        name: "",
        installable: false,
      });
    },
    deleteOption(index) {
      this.formData.addons = this.formData.addons.filter(
        (item, i) => i !== index
      );
    },
    editTractor() {
      this.getTractorEdit({ id: this.$route.params.id })
        .then((res) => {
          this.formData = res;
          if (!this.formData.tires.length) {
            this.formData.tires.push({
              description: "",
              front_size: "",
              rear_size: "",
            });
          }
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    },
    deleteTire(index) {
      this.formData.tires = this.formData.tires.filter(
        (item, i) => i !== index
      );
    },
    uploadCoverFile(file) {
      let uploadFile = file[0];
      this.formData.tractor_image = uploadFile;
      this.errorMessageFiles = "";
    },
    addTractor() {
      if (this.formData.tractor_image !== null) {
        this.errorMessageFiles = "";
      } else {
        this.$toasted.error("Your submission contains errors");
        this.errorMessageFiles = "Fill in the field";
        return false;
      }
      this.$refs.form
        .validate()
        .then((success) => {
          if (success) {
            let data = new FormData();
            for (var formData in this.formData) {
              data.append(formData, this.formData[formData]);
            }
            // data.delete('rops');
            // for(var rops in this.formData.rops){
            //   data.append('rops[]', this.formData.rops[rops])
            // }
            //
            // data.delete('transmissions');
            // for(var transmissions in this.formData.transmissions){
            //   data.append('transmissions[]', this.formData.transmissions[transmissions])
            // }
            if (this.isEdit && this.formData.tractor_image.file) {
              data.delete("tractor_image");
            }
            this.buttonDisabled = true;
            if (this.isEdit) {
              this.updateTractor({ id: this.$route.params.id, formData: data })
                .then((res) => {
                  this.$router.push("/admin/tractors");
                })
                .catch((err) => {
                  this.buttonDisabled = false;
                  this.$toasted.error(
                    err.response.data.message || "Server Error"
                  );
                });
            } else {
              this.createTractor(data)
                .then((res) => {
                  this.$router.push("/admin/tractors");
                })
                .catch((err) => {
                  this.buttonDisabled = false;
                  this.$toasted.error(
                    err.response.data.message || "Server Error"
                  );
                });
            }
          } else {
            this.$toasted.error("Your submission contains errors");
          }
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    },
    // checkedTransmission(type){
    //   if(this.formData.transmissions.includes(type)){
    //     this.formData.transmissions = this.formData.transmissions.filter(item => item !== type)
    //   }else{
    //     this.formData.transmissions.push(type)
    //   }
    // },
    // checkedRops(type){
    //   if(this.formData.rops.includes(type)){
    //     this.formData.rops = this.formData.rops.filter(item => item !== type)
    //   }else{
    //     this.formData.rops.push(type)
    //   }
    // },
  },
};
</script>

<style type="text/css" scoped lang="scss">
.input-checkbox {
  .flex {
    align-items: center;
    padding-bottom: 8px;

    .pb-0 {
      padding-bottom: 0 !important;
    }
    .radio-block {
      margin-top: 0 !important;
      margin-left: 10px;
      .remember {
        margin-top: 0 !important;

        p {
          font-style: italic;
          font-weight: 500;
          font-size: 10px;
          line-height: 14px;
          color: #5f5f5f;
          margin-left: 5px;
        }
      }
    }
  }
}
.mt-0 {
  margin-top: 0 !important;

  label:first-of-type {
    margin-top: 0 !important;
  }
}
.installable {
  min-width: 130px;
  margin-left: 33px;
  .remember {
    flex-direction: column;
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: #000000;
      margin-bottom: 28px;
    }
    span {
      font-style: initial !important;
    }
  }
}
.border-bottom {
  padding-bottom: 20px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 20px;

  svg {
    margin: 0 auto;
    margin-top: 20px;
    cursor: pointer;
  }
}
.btn-tires {
  button {
    margin-top: 20px;
    border: 1px solid #a0a0a0;
  }
}
.radio-section {
  margin-top: 30px;
  border-top: 1px solid #e6e6e6;

  .radio-title {
    margin-top: 25px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 16px;
    color: #000000;
  }

  .radio-subtitle {
    margin-top: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #000000;
  }
}
</style>
