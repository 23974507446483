<template>
  <section class="manage col-span-12">
    <div class="container grid grid-cols-1 gap-0">
      <PageHeader
        title="Co-op Claims"
        :backLink="false"
        :saveButton="false"
        :discardLink="false"
        :addButton="true"
        addText="Create Claim"
      />
      <div class="filter-block">
        <div>
          <div class="table-filter-search-input">
            <div class="form-group">
              <label class="control-label">Search</label>
              <input
                type="text"
                name="search"
                v-model="filterData.searchVal"
                v-on:keyup.enter="applyFilter"
                class="search"
                placeholder="Tractor S/N or Claim #"
              />
              <inline-svg
                class="search-icon"
                :src="require('@/assets/image/icons/search.svg')"
              />
            </div>
          </div>
          <div class="table-filter-dealership-select">
            <dropdown
              :data="dealerships"
              :status="false"
              fieldName="name"
              label="Dealer"
              @select="selectDealer"
              :validation="false"
              inputName="dealer"
              :editValue="selectedDealer"
            ></dropdown>
          </div>
          <div class="table-filter-status-select">
            <dropdown
              :data="statuses"
              :status="true"
              fieldName="name"
              label="Status"
              @select="selectStatus"
              :validation="false"
              inputName="status"
              :editValue="status"
            ></dropdown>
          </div>
          <div class="form-group calendar">
            <div>
              <label class="control-label">Claim Date</label>
              <div class="relative">
                <functional-calendar
                  :placeholder="' '"
                  v-model="calendarData"
                  :sundayStart="true"
                  :date-format="'mm/dd/yyyy'"
                  :is-modal="true"
                  :is-date-range="true"
                  :is-multiple="true"
                  :calendars-count="2"
                  :change-year-function="true"
                ></functional-calendar>
                <inline-svg
                  class="date-down"
                  :src="require('@/assets/image/icons/date-down.svg')"
                />
                <span class="dash">-</span>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="btn btn-black px-40" @click="applyFilter">
            <div class="text"><a>Apply</a></div>
          </div>
          <div class="btn redo px-15" @click="resetFilter">
            <a>
              <inline-svg :src="require('@/assets/image/icons/redo.svg')" />
            </a>
          </div>
        </div>
      </div>
      <div class="table-block col-span-1">
        <data-table
          :page="filterData.page"
          :header="fieldManageOldWarrantyClaimsTable.header"
          :data="coops"
          :link="{ url: '/admin/old-warranty-claims/', name: 'id' }"
          class="media-table"
          :isLoading="isLoading"
        />
      </div>
      <div class="pages" v-if="pagination.total && pagination.total_pages > 1">
        <div class="pages-quantity">
          <template v-if="!isLoading">
            Page {{ filterData.page }} of {{ pagination.total_pages }} - Showing
            {{ pagination.per_page }} Results of {{ pagination.total }} Total
          </template>
        </div>
        <paginate
          :page-count="pagination.total_pages"
          :page-range="6"
          :initial-page="initialPage - 1"
          :margin-pages="1"
          :click-handler="clickCallback"
          :prev-text="'&laquo;'"
          :next-text="'&raquo;'"
          :container-class="'pages-btn'"
          :active-class="pageActive"
        >
        </paginate>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../component/PageHeader";
import DataTable from "../../component/DataTable";
import Dropdown from "../../component/Dropdown";
import { mapActions, mapState } from "vuex";

export default {
  name: "CoOpClaims",
  data() {
    return {
      isLoading: false,
      fieldManageOldWarrantyClaimsTable: {
        header: [
          {
            field: "id",
            name: "Claim ID",
            transformToComponent: "TransformToLink",
            headerWidth: "8%",
            width: "9%",
          },
          {
            field: "dealership",
            name: "Dealer",
            width: "20%",
            headerWidth: "5%",
          },
          {
            field: "status.name",
            name: "Status",
            transformField: "status.id",
            transform: this.transformToStatus,
            headerWidth: "14%",
          },
          { field: "submission_date", name: "Submission Date", width: "30%" },
          {
            transformField: "id",
            name: "Actions",
            transformToComponent: "CoOpClaimDropdown",
          },
        ],
      },
      calendarData: {},
      filterData: {
        page: 1,
      },
      pageActive: "active",
      selectedDealer: "All",
      status: "All",
    };
  },
  components: {
    PageHeader,
    DataTable,
    Dropdown,
  },
  computed: {
    ...mapState({
      coops: (state) => state.coops.coops,
      pagination: (state) => state.coops.pagination,
      dealerships: (state) => state.coops.dealerships,
      statuses: (state) => state.coops.statuses,
      initialPage: (state) => state.coops.initialPage,
    }),
  },
  mounted() {
    this.getCoopsList();
  },
  methods: {
    ...mapActions({
      getCoops: "coops/getCoops",
      filterCoops: "coops/filterCoops",
      getInitialPage: "coops/getInitialPage",
    }),
    getCoopsList() {
      this.filterData.page = this.initialPage;
      this.isLoading = true;
      this.getCoops({ page: this.filterData.page }).finally(() => {
        this.isLoading = false;
      });
    },
    applyFilter() {
      this.pageActive = "";
      if (this.calendarData.dateRange != null) {
        if (this.calendarData.dateRange.start) {
          this.filterData.start_claim = this.calendarData.dateRange.start;
        }
        if (this.calendarData.dateRange.end) {
          this.filterData.end_claim = this.calendarData.dateRange.end;
        }
      }
      this.filterData.page = 1;
      this.isLoading = true;
      this.filterCoops(this.filterData)
        .then((res) => {})
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    resetFilter() {
      this.pageActive = "";
      this.selectedDealer = "All";
      this.status = "All";
      this.filterData = {
        page: 1,
      };
      if (this.calendarData.dateRange) {
        this.calendarData.dateRange.end = "";
        this.calendarData.dateRange.start = "";
      }
      this.getInitialPage(1);
      this.applyFilter();
    },
    clickCallback(pageNum) {
      this.getInitialPage(pageNum);
      this.filterData.page = pageNum;
      this.pageActive = "active";
      this.getCoopsList();
    },
    transformToStatus(id, val) {
      let background;
      let border;
      let color;
      let width;
      color = "#0B4067";
      switch (id) {
        case 1:
          background = "rgba(99, 220, 43, 0.3)";
          color = "#24610E";
          border = "none";
          width = "77px";
          break;
        case 2:
          background = "rgba(212, 4, 4, 0.2)";
          color = "#7D0505";
          border = "none";
          width = "77px";
          break;
        case 3:
          background = "rgba(215, 173, 65, 0.4)";
          color = "#5C4E00";
          border = "none";
          width = "67px";
          break;
        case 4:
          background = "#F9F9F9";
          color = "#606060";
          border = "1px solid #606060";
          width = "62px";
          break;
        case 5:
          background = "rgba(29, 184, 216, 0.2)";
          color = "#0B4067";
          border = "none";
          width = "95px";
          break;
        case 6:
          background = "#F9F9F9";
          color = "#606060";
          background = "rgba(212, 4, 4, 0.2)";
          color = "#7d0b67";
          border = "none";
          width = "100px";
          break;
      }
      return (
        '<div class="td-status" style="background-color:' +
        background +
        ";color:" +
        color +
        ";border:" +
        border +
        ";width:" +
        width +
        '">' +
        val +
        "</div>"
      );
    },
    selectDealer(dealer) {
      if (dealer.name == "All") {
        this.selectedDealer = null;
        delete this.filterData.dealership_id;
      } else {
        this.selectedDealer = dealer.name;
        this.filterData.dealership_id = dealer.id;
      }
      this.applyFilter();
    },
    selectStatus(status) {
      if (status.name == "All") {
        this.status = null;
        delete this.filterData.status_id;
      } else {
        this.status = status.name;
        this.filterData.status_id = status.id;
      }

      this.applyFilter();
    },
  },
};
</script>

<style scoped>
.manage .container .filter-block .table-filter-status-select .form-group {
  width: 140px !important;
}
.manage .container .filter-block .table-filter-dealership-select .form-group {
  width: 195px !important;
}
</style>
