import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
import Main from '@/components/component/Layouts/Main'

import Auth from '@/components/pages/Auth'
import ForgotPassword from '@/components/pages/Auth/ForgotPassword'
import ResetPassword from '@/components/pages/Auth/ResetPassword'

import Home from '@/components/pages/Home'

import News from '@/components/pages/News'

import WarrantyRegistrations from '@/components/pages/WarrantyRegistrations'
import RegistrationsAdd from '@/components/pages/WarrantyRegistrations/RegistrationsAdd'
import RegistrationDetail from '@/components/pages/WarrantyRegistrations/RegistrationDetail'

import Claims from '@/components/pages/Claims'
import Claim from '@/components/pages/Claims/claimDetail'
import CreateClaim from '@/components/pages/Claims/CreateClaim'
import CreateClaimStep2 from '@/components/pages/Claims/CreateClaimStep2'
import ViewOriginalClaim from '@/components/pages/Claims/ViewOriginalClaim'

import Orders from '@/components/pages/Orders'
import CreateOrder from '@/components/pages/Orders/createOrder'
import ReviewOrder from '@/components/pages/Orders/reviewOrder'
import orderTractor from '@/components/pages/Orders/orderTractor'
import orderDetail from '@/components/pages/Orders/orderDetail'

import FieldInventory from '@/components/pages/FieldInventory'
import RetailPrograms from '@/components/pages/Programs'
import RetailProgramsAdd from '@/components/pages/Programs/programsAdd'
import RetailProgramEdit from '@/components/pages/Programs/programEdit'

import ProgramPreApproval from '@/components/pages/ProgramPreApproval'
import ProgramPreApprovalAdd from '@/components/pages/ProgramPreApproval/ProgramPreApprovalAdd'


import Gallery from '@/components/pages/Gallery'
import GalleryAdvertisements from '@/components/pages/Gallery/GalleryAdvertisements'
import GalleryDetail from '@/components/pages/Gallery/GalleryDetail'

import Brochures from '@/components/pages/Brochures'

import CoOpClaims from '@/components/pages/CoOpClaims'

import RadioScripts from '@/components/pages/RadioScripts'

import Videos from '@/components/pages/Videos'
import VideoDetail from '@/components/pages/Videos/VideoDetail'

import Manuals from '@/components/pages/Manuals'
import File from '@/components/pages/File'

import SalesDocumentation from '@/components/pages/SalesDocumentation'

import AdminSettings from '@/components/pages/AdminSettings'
import ManageTractors from '@/components/pages/AdminSettings/ManageTractors'
import addTractor from '@/components/pages/AdminSettings/ManageTractors/addTractor'
import tractorDetail from '@/components/pages/AdminSettings/ManageTractors/tractorDetail'
import ManageDealers from '@/components/pages/AdminSettings/ManageDealers'
import addDealer from '@/components/pages/AdminSettings/ManageDealers/addDealer'
import dealerDetail from '@/components/pages/AdminSettings/ManageDealers/dealerDetail'
import ManageEmailLists from '@/components/pages/AdminSettings/ManageEmailLists'
import ManageCategories from '@/components/pages/AdminSettings/ManageCategories'
import addCategories from '@/components/pages/AdminSettings/ManageCategories/addCategories'
import categoriesDetail from '@/components/pages/AdminSettings/ManageCategories/categoriesDetail'
import ManageUsers from '@/components/pages/AdminSettings/ManageUsers'
import ManageNews from '@/components/pages/AdminSettings/ManageNews'
import newsDetail from '@/components/pages/AdminSettings/ManageNews/newsDetail'
import addNews from '@/components/pages/AdminSettings/ManageNews/addNews'
import ManageMedia from '@/components/pages/AdminSettings/ManageMedia'
import addMedia from '@/components/pages/AdminSettings/ManageMedia/addMedia'
import mediaDetail from '@/components/pages/AdminSettings/ManageMedia/mediaDetail'
import addUser from '@/components/pages/AdminSettings/ManageUsers/addUser'
import accountSettings from '@/components/pages/AdminSettings/ManageUsers/accountSettings'
import userDetail from '@/components/pages/AdminSettings/ManageUsers/userDetail'
import ManageVideos from '@/components/pages/AdminSettings/ManageVideos'
import addVideo from '@/components/pages/AdminSettings/ManageVideos/addVideo'
import ManageOldWarrantyClaims from '@/components/pages/AdminSettings/ManageOldWarrantyClaims'
import oldClaimDetail from '@/components/pages/AdminSettings/ManageOldWarrantyClaims/oldClaimDetail'
import LaborCodesLookup from '@/components/pages/AdminSettings/LaborCodesLookup'
import ModelInfoLookup from '@/components/pages/AdminSettings/ModelInfoLookup'
import AddModelInfo from '@/components/pages/AdminSettings/ModelInfoLookup/addModelInfo'
import ModelInfoDetail from '@/components/pages/AdminSettings/ModelInfoLookup/modelInfoDetail'
import EditPrograms from '@/components/pages/AdminSettings/ManagePrograms/editPrograms'
import CreateProgram from '@/components/pages/AdminSettings/ManagePrograms/createProgram'
import ServiceBulletins from '@/components/pages/ServiceBulletins'

import page404 from '@/components/pages/404/404'

import middlewarePipeline from './middlewarePipeline'
import guest from './middleware/guest'
import auth from './middleware/auth'
import perm from './middleware/perm'
import hasAction from './middleware/hasAction'
import user from '../store/modules/user'

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    linkActiveClass: "active",
    routes: [
        {
            path: '/files/:type/:file_name',
            name: 'file',
            component: File,
        },
        {
            path: '/login',
            name: 'Auth',
            component: Auth,
            meta: {
                middleware: [
                    guest
                ]
            },
        },
        {
            path: '/forgot-password',
            redirect: {name: 'ForgotPassword'},
        },
        {
            path: '/password/forgot',
            name: 'ForgotPassword',
            component: ForgotPassword,
            meta: {
                middleware: [
                    guest
                ]
            },
        },
        {
            path: '/password/reset',
            name: 'ResetPassword',
            component: ResetPassword,
            meta: {
                middleware: [
                    guest
                ]
            },
        },
        {
            path: '/',
            component: Main,
            redirect:'/dashboard',
            children: [
                {
                    path: '/',
                    redirect: {name: 'Home'},
                },
                {
                    path: '/dashboard',
                    name: 'Home',
                    component: Home,
                    meta: {
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: '/news',
                    name: 'News',
                    component: News,
                    meta: {
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: 'news/:id',
                    name: 'newsDetail',
                    component: newsDetail,
                    meta: {
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: '/registrations',
                    name: 'WarrantyRegistrations',
                    component: WarrantyRegistrations,
                    meta: {
                        middleware: [
                            auth,
                            perm('REG_VIEW'),
                        ]
                    },
                },
                {
                    path: '/claims',
                    name: 'Claims',
                    component: Claims,
                    meta: {
                        middleware: [
                            auth,
                            perm('CLAIM_VIEW'),
                        ]
                    },
                },
                {
                    path: '/orders',
                    name: 'Orders',
                    component: Orders,
                    meta: {
                        middleware: [
                            auth,
                            perm('ORDER_VIEW'),
                        ]
                    },
                },
                {
                    path: '/field-inventory',
                    name: 'FieldInventory',
                    component: FieldInventory,
                    meta: {
                        middleware: [
                            auth,
                            perm('INV_VIEW')
                        ]
                    },
                },
                {
                    path: '/programs',
                    redirect: {name: 'RetailPrograms'},
                },
                {
                    path: '/retail-programs',
                    name: 'RetailPrograms',
                    component: RetailPrograms,
                    meta: {
                        middleware: [
                            auth,
                            hasAction('Retail Program', 'View')
                        ]
                    },
                },
                {
                    path: '/programs/add',
                    redirect: {name: 'RetailProgramsAdd'},
                },
                {
                    path: '/retail-programs/add',
                    name: 'RetailProgramsAdd',
                    component: RetailProgramsAdd,
                    meta: {
                        middleware: [
                            auth,
                            hasAction('Retail Program', 'Create', 'RetailPrograms')
                        ]
                    },
                },
                {
                    path: '/retail-programs/:id',
                    name: 'RetailProgramView',
                    component: RetailProgramEdit,
                    meta: {
                        middleware: [
                            auth,
                            hasAction('Retail Program', 'View', 'RetailPrograms')
                        ]
                    },
                },
                {
                    path: '/retail-programs/:id/edit',
                    name: 'RetailProgramEdit',
                    component: RetailProgramEdit,
                    meta: {
                        middleware: [
                            auth,
                            hasAction('Retail Program', 'Edit', 'RetailPrograms')
                        ]
                    },
                    props: {
                        isEdit: true
                    }
                },
                {
                    path: '/retail-program-preapprovals',
                    name: 'ProgramPreapprovalIndex',
                    component: ProgramPreApproval,
                    meta: {
                        middleware: [
                            auth,
                            hasAction('Program Preapproval', 'View')
                        ]
                    },
                },
                {
                    path: '/retail-program-preapprovals/add',
                    name: 'ProgramPreapprovalAdd',
                    component: ProgramPreApprovalAdd,
                    meta: {
                        middleware: [
                            auth,
                            hasAction('Program Preapproval', 'Create', 'ProgramPreapprovalIndex')
                        ]
                    },
                },
                {
                    path: '/retail-program-preapprovals/:id/edit',
                    name: 'ProgramPreapprovalEdit',
                    component: ProgramPreApprovalAdd,
                    props: {isEdit: true},
                    meta: {
                        middleware: [
                            auth,
                            hasAction('Program Preapproval', 'Edit', 'ProgramPreapprovalIndex')
                        ]
                    },
                },
                {
                    path: '/registrations/add',
                    name: 'RegistrationsAdd',
                    component: RegistrationsAdd,
                    meta: {
                        middleware: [
                            auth,
                            perm('REG_CREATE', 'WarrantyRegistrations'),
                        ]
                    },
                },
                {
                    path: '/registrations/:id/edit',
                    name: 'RegistrationsEdit',
                    component: RegistrationsAdd,
                    meta: {
                        middleware: [
                            auth,
                            perm('REG_EDIT', 'WarrantyRegistrations'),
                        ]
                    },
                },
                {
                    path: '/orders-create',
                    name: 'CreateOrder',
                    component: CreateOrder,
                    meta: {
                        middleware: [
                            auth,
                            perm('ORDER_CREATE', 'Orders'),
                        ]
                    },
                },
                {
                    path: '/review-order/:id',
                    name: 'ReviewOrder',
                    component: ReviewOrder,
                    meta: {
                        middleware: [
                            auth,
                            perm('ORDER_VIEW'),
                        ]
                    },
                },
                {
                    path: '/orders/:id',
                    name: 'orderDetail',
                    component:orderDetail,
                    meta: {
                        middleware: [
                            auth,
                            perm('ORDER_VIEW'),
                        ]
                    },
                },
                {
                    path: '/order/add',
                    name: 'orderTractor',
                    component: orderTractor,
                    meta: {
                        middleware: [
                            auth,
                            perm('ORDER_CREATE', 'Orders'),
                        ]
                    },
                },
                {
                    path: '/order/:id/edit',
                    name: 'editTractorOrder',
                    component: orderTractor,
                    meta: {
                        middleware: [
                            auth,
                            perm('ORDER_APPR', 'Orders'),
                        ]
                    },
                },
                {
                    path: '/co-op-claims',
                    name: 'CoOpClaims',
                    component: CoOpClaims,
                    meta: {
                        middleware: [
                            auth,
                            perm('CLAIM_VIEW'),
                        ]
                    },
                },
                {
                    path: '/claims/add',
                    name: 'CreateClaim',
                    component: CreateClaim,
                    meta: {
                        middleware: [
                            auth,
                            perm('CLAIM_CREATE', 'Claims'),
                        ]
                    },
                },
                {
                    path: '/claims/add/:id',
                    name: 'CreateClaimStep2',
                    component: CreateClaimStep2,
                    meta: {
                        middleware: [
                            auth,
                            perm('CLAIM_CREATE', 'Claims'),
                        ]
                    },
                },
                {
                    path: '/claims/:id/continue',
                    name: 'ContinueClaim',
                    component: CreateClaimStep2,
                    props: {continueDraft : true},
                    meta: {
                        middleware: [
                            auth,
                            perm('CLAIM_CREATE', 'Claims'),
                        ]
                    },
                },
                {
                    path: '/claims/:id/edit',
                    name: 'ClaimEdit',
                    component: CreateClaimStep2,
                    meta: {
                        middleware: [
                            auth,
                            perm('CLAIM_EDIT', 'Claims'),
                        ]
                    },
                },
                {
                    path: '/gallery',
                    name: 'Gallery',
                    component: Gallery,
                    meta: {
                        middleware: [
                            auth,
                            perm('AD_VIEW'),
                        ]
                    },
                },
                {
                    path: '/gallery-advertisements/:id',
                    name: 'GalleryAdvertisements',
                    component: GalleryAdvertisements,
                    meta: {
                        middleware: [
                            auth,
                            perm('AD_VIEW'),
                        ]
                    },
                },
                {
                    path: '/gallery-detail/:id/:slug',
                    name: 'GalleryDetail',
                    component: GalleryDetail,
                    meta: {
                        middleware: [
                            auth,
                            perm('AD_VIEW'),
                        ]
                    },
                },
                {
                    path: '/brochures',
                    name: 'Brochures',
                    component: Brochures,
                    meta: {
                        middleware: [
                            auth,
                            perm('AD_VIEW'),
                        ]
                    },
                },
                {
                    path: '/radio-scripts',
                    name: 'RadioScripts',
                    component: RadioScripts,
                    meta: {
                        middleware: [
                            auth,
                            perm('AD_VIEW'),
                        ]
                    },
                },
                {
                    path: '/videos',
                    name: 'Videos',
                    component: Videos,
                    meta: {
                        middleware: [
                            auth,
                            perm('AD_VIEW'),
                        ]
                    },
                },
                {
                    path: '/videos/:id',
                    name: 'VideoDetail',
                    component: VideoDetail,
                    meta: {
                        middleware: [
                            auth,
                            perm('AD_VIEW'),
                        ]
                    },
                },
                {
                    path: '/manuals',
                    name: 'Manuals',
                    component: Manuals,
                    meta: {
                        middleware: [
                            auth,
                            perm('RES_MANUAL_VIEW'),
                        ]
                    },
                },
                {
                    path: '/sales-documentation',
                    name: 'SalesDocumentation',
                    component: SalesDocumentation,
                    meta: {
                        middleware: [
                            auth,
                            perm('RES_DOC_VIEW'),
                        ]
                    },
                },
                {
                    path: '/service-bulletins',
                    name: 'ServiceBulletins',
                    component: ServiceBulletins,
                    meta: {
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: '/admin',
                    name: 'AdminSettings',
                    component: AdminSettings,
                    meta: {
                        middleware: [
                            auth,
                            perm(["ADMIN_DEALERS","ADMIN_USERS","ADMIN_MEDIA","ADMIN_NEWS","ADMIN_PROD_CAT","ADMIN_PROGRAMS","ADMIN_WARR_INFO_VIEW"]),
                        ]
                    },
                },
                {
                    path: '/admin/tractors',
                    name: 'ManageTractors',
                    component: ManageTractors,
                    meta: {
                        middleware: [
                            auth,
                            perm('ADMIN_PROD_CAT', 'AdminSettings'),
                        ]
                    },
                },
                {
                    path: '/admin/tractors/:id',
                    name: 'tractorDetail',
                    component: tractorDetail,
                    meta: {
                        middleware: [
                            auth,
                            perm('ADMIN_PROD_CAT', 'AdminSettings'),
                        ]
                    },
                },
                {
                    path: '/admin/tractor/:id',
                    name: 'addTractor',
                    component: addTractor,
                    meta: {
                        middleware: [
                            auth,
                            perm('ADMIN_PROD_CAT', 'AdminSettings'),
                        ]
                    },
                },
                {
                    path: '/admin/tractor/:id/edit',
                    name: 'editTractor',
                    component: addTractor,
                    meta: {
                        middleware: [
                            auth,
                            perm('ADMIN_PROD_CAT', 'AdminSettings'),
                        ]
                    },
                },
                {
                    path: '/admin/dealers',
                    name: 'ManageDealers',
                    component: ManageDealers,
                    meta: {
                        middleware: [
                            auth,
                            perm('ADMIN_DEALERS', 'AdminSettings'),
                        ]
                    },
                },
                {
                    path: '/admin/dealers/:id',
                    name: 'addDealer',
                    component: addDealer,
                    // props: {isEdit: true},
                    meta: {
                        middleware: [
                            auth,
                            perm('ADMIN_DEALERS', 'AdminSettings'),
                        ]
                    },
                },
                {
                    path: '/admin/dealers/:id/edit',
                    name: 'editDealer',
                    component: addDealer,
                    meta: {
                        middleware: [
                            auth,
                            perm('ADMIN_DEALERS', 'AdminSettings'),
                        ]
                    },
                },
                {
                    path: '/admin/dealer/:id',
                    name: 'dealerDetail',
                    component: dealerDetail,
                    meta: {
                        middleware: [
                            auth,
                            perm('ADMIN_DEALERS', 'AdminSettings'),
                        ]
                    },
                },
                {
                    path: '/admin/email-lists',
                    name: 'ManageEmailLists',
                    component: ManageEmailLists,
                    meta: {
                        middleware: [
                            auth,
                            perm('GLOBAL','AdminSettings'),
                        ]
                    },
                },
                {
                    path: '/admin/media/categories',
                    name: 'ManageCategories',
                    component: ManageCategories,
                    meta: {
                        middleware: [
                            auth,
                            perm('ADMIN_MEDIA','AdminSettings'),
                        ]
                    },
                },
                {
                    path: '/admin/media/categories/:id',
                    name: 'categoriesDetail',
                    component: categoriesDetail,
                    meta: {
                        middleware: [
                            auth,
                            perm('ADMIN_MEDIA','AdminSettings'),
                        ]
                    },
                },
                {
                    path: '/admin/media/categorie/add',
                    name: 'addCategories',
                    component: addCategories,
                    meta: {
                        middleware: [
                            auth,
                            perm('ADMIN_MEDIA','AdminSettings'),
                        ]
                    },
                },
                {
                    path: '/admin/media/categorie/:id/edit',
                    name: 'editCategories',
                    component: addCategories,
                    meta: {
                        middleware: [
                            auth,
                            perm('ADMIN_MEDIA','AdminSettings'),
                        ]
                    },
                },
                {
                    path: '/admin/news',
                    name: 'ManageNews',
                    component: ManageNews,
                    meta: {
                        middleware: [
                            auth,
                            perm('ADMIN_NEWS','AdminSettings'),
                        ]
                    },
                },
                {
                    path: '/admin/news/add',
                    name: 'addNews',
                    component: addNews,
                    meta: {
                        middleware: [
                            auth,
                            perm('ADMIN_NEWS','AdminSettings'),
                        ]
                    },
                },
                {
                    path: '/admin/news/:id',
                    name: 'adminNewsDetail',
                    component: newsDetail,
                    meta: {
                        middleware: [
                            auth,
                            perm('ADMIN_NEWS','AdminSettings'),
                        ]
                    },
                },
                {
                    path: '/admin/news/:id/edit',
                    name: 'editNews',
                    component: addNews,
                    meta: {
                        middleware: [
                            auth,
                            perm('ADMIN_NEWS','AdminSettings'),
                        ]
                    },
                },
                {
                    path: '/admin/media',
                    name: 'ManageMedia',
                    component: ManageMedia,
                    meta: {
                        middleware: [
                            auth,
                            perm('ADMIN_MEDIA','AdminSettings'),
                        ]
                    },
                },
                {
                    path: '/admin/media/add',
                    name: 'addMedia',
                    component: addMedia,
                    meta: {
                        middleware: [
                            auth,
                            perm('ADMIN_MEDIA','AdminSettings'),
                        ]
                    },
                },
                {
                    path: '/admin/media/:id/edit',
                    name: 'editMedia',
                    component: addMedia,
                    meta: {
                        middleware: [
                            auth,
                            perm('ADMIN_MEDIA','AdminSettings'),
                        ]
                    },
                },
                {
                    path: '/admin/media/:id',
                    name: 'mediaDetail',
                    component: mediaDetail,
                    meta: {
                        middleware: [
                            auth,
                            perm('ADMIN_MEDIA','AdminSettings'),
                        ]
                    },
                },
                {
                    path: '/admin/users',
                    name: 'ManageUsers',
                    component: ManageUsers,
                    meta: {
                        middleware: [
                            auth,
                            perm('ADMIN_USERS', 'AdminSettings'),
                        ]
                    },
                },
                {
                    path: '/admin/user/:id',
                    name: 'addUser',
                    component: addUser,
                    meta: {
                        middleware: [
                            auth,
                            perm('ADMIN_USERS', 'AdminSettings'),
                        ]
                    },
                },
                {
                    path: '/admin/user/:id/edit',
                    name: 'adminEditUser',
                    component: addUser,
                    meta: {
                        middleware: [
                            auth,
                            perm('ADMIN_USERS', 'AdminSettings'),
                        ]
                    },
                },
                {
                    path: '/user/:id/edit',
                    name: 'editUser',
                    component: addUser,
                    meta: {
                        middleware: [
                            auth,
                            perm('ADMIN_USERS', 'AdminSettings'),
                        ]
                    },
                },
                {
                    path: '/admin/users/:id',
                    name: 'userDetail',
                    component: userDetail,
                    meta: {
                        middleware: [
                            auth,
                            perm('ADMIN_USERS', 'AdminSettings'),
                        ]
                    },
                },
                {
                    path: '/account-settings',
                    name: 'accountSettings',
                    component: accountSettings,
                    meta: {
                        middleware: [
                            auth,
                        ]
                    },
                },
                {
                    path: '/admin/videos',
                    name: 'ManageVideos',
                    component: ManageVideos,
                    meta: {
                        middleware: [
                            auth,
                            perm('ADMIN_MEDIA', 'AdminSettings'),
                        ]
                    },
                },
                {
                    path: '/admin/video/:id',
                    name: 'addVideo',
                    component: addVideo,
                    meta: {
                        middleware: [
                            auth,
                            perm('ADMIN_MEDIA', 'AdminSettings'),
                        ]
                    },
                },
                {
                    path: '/admin/video/:id/edit',
                    name: 'editVideo',
                    component: addVideo,
                    meta: {
                        middleware: [
                            auth,
                            perm('ADMIN_MEDIA', 'AdminSettings'),
                        ]
                    },
                },
                {
                    path: '/admin/videos/:id',
                    name: 'adminVideoDetail',
                    component: VideoDetail,
                    meta: {
                        middleware: [
                            auth,
                            perm('ADMIN_MEDIA', 'AdminSettings'),
                        ]
                    },
                },
                {
                    path: '/admin/old-warranty-claims',
                    name: 'ManageOldWarrantyClaims',
                    component: ManageOldWarrantyClaims,
                    meta: {
                        middleware: [
                            auth,
                            perm('ADMIN_WARR_INFO_VIEW', 'AdminSettings'),
                        ]
                    },
                },
                {
                    path: '/admin/old-warranty-claims/:id',
                    name: 'oldClaimDetail',
                    component: oldClaimDetail,
                    meta: {
                        middleware: [
                            auth,
                            perm('ADMIN_WARR_INFO_VIEW', 'AdminSettings'),
                        ]
                    },
                },
                {
                    path: '/admin/search-labor',
                    name: 'LaborCodesLookup',
                    component: LaborCodesLookup,
                    meta: {
                        middleware: [
                            auth
                        ]
                    },
                },
                {
                    path: '/admin/model-info',
                    name: 'modelInfoLookup',
                    component: ModelInfoLookup,
                    meta: {
                        middleware: [
                            auth,
                            hasAction('Model Info', 'View')
                        ]
                    },
                },
                {
                    path: '/admin/model-info/add',
                    name: 'modelInfoAdd',
                    component: AddModelInfo,
                    meta: {
                        middleware: [
                            auth,
                            hasAction('Model Info', 'Create', 'modelInfoLookup')
                        ]
                    },
                },
                {
                    path: '/admin/model-info/:model',
                    name: 'modelInfoDetail',
                    component: ModelInfoDetail,
                    meta: {
                        middleware: [
                            auth,
                            hasAction('Model Info', 'View')
                        ]
                    },
                },
                {
                    path: '/admin/model-info/:model/edit',
                    name: 'modelInfoEdit',
                    component: AddModelInfo,
                    props: {isEdit: true},
                    meta: {
                        middleware: [
                            auth,
                            hasAction('Model Info', 'Edit', 'modelInfoLookup')
                        ]
                    },
                },
                {
                    path: '/admin/retail-programs',
                    name: 'EditPrograms',
                    component: EditPrograms,
                    meta: {
                        middleware: [
                            auth,
                            perm('ADMIN_PROGRAMS')
                        ]
                    },
                },
                {
                    path: '/admin/retail-programs/create',
                    name: 'CreateProgram',
                    component: CreateProgram,
                    meta: {
                        middleware: [
                            auth,
                            perm('ADMIN_PROGRAMS')
                        ]
                    },
                },
                {
                    path: '/admin/retail-programs/:id/edit',
                    name: 'EditProgram',
                    component: CreateProgram,
                    props: {isEdit: true},
                    meta: {
                        middleware: [
                            auth,
                            perm('ADMIN_PROGRAMS')
                        ]
                    },
                },
                {
                    path: '/registrations/:id',
                    name: 'RegistrationDetail',
                    component: RegistrationDetail,
                    meta: {
                        middleware: [
                            auth,
                            perm('REG_VIEW'),
                        ]
                    },
                },
                {
                    path: '/claims/:id/original',
                    name: 'ViewOriginalClaim',
                    component: ViewOriginalClaim,
                    meta: {
                        middleware: [
                            auth,
                            perm('CLAIM_VIEW'),
                        ]
                    },
                },
                {
                    path: '/claims/:id',
                    name: 'Claim',
                    component: Claim,
                    meta: {
                        middleware: [
                            auth,
                            perm('CLAIM_VIEW'),
                        ]
                    },
                },
                {
                    path: '/*',
                    name: '404',
                    component: page404,
                    meta: {
                        middleware: [
                            auth,
                        ]
                    }
                },
            ],
        }
    ],
});

router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) {
        return next()
    }
    const middleware = to.meta.middleware

    const context = {
        to,
        from,
        next,
        store
    }

    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    })

});
export default router
