<template>
  <section class="create-program col-span-12">
    <ValidationObserver tag="div" ref="form" class="container">
      <PageHeader
        :title="
          isEdit
            ? 'Edit ' + programDetails.name + ' Program'
            : 'Create Retail Program'
        "
        :canSave="canSave"
        saveText="Save Program"
        @save="saveNewProgram"
        @discard="showDiscardModal = true"
        backText="Back to Manage Retail Programs"
        :backRoute="{ name: 'EditPrograms' }"
      />
      <div class="search-filters main-section">
        <div class="max-w-xl">
          <div class="title">Basic Information</div>
          <div class="dropdown-group">
            <div class="w-56 mr-3">
              <dropdown
                :data="allProgramTypes"
                fieldName="name"
                label="Program Type"
                @select="selectProgramType"
                inputName="program_type"
                :editValue="selectedProgramType.name"
                :all="false"
                :disabled="isEdit"
              ></dropdown>
            </div>
            <div class="w-56 flex-grow">
              <div class="form-group w-full">
                <label for="program_name_input" class="control-label"
                  >Program Name</label
                >
                <input
                  id="program_name_input"
                  type="text"
                  class="input pl-3"
                  v-model="programDetails.name"
                />
              </div>
            </div>
          </div>

          <div class="mt-12">
            <p class="text-black font-semibold text-sm">Description</p>
            <textarea
              class="
                rounded
                border border-gray-300
                my-2
                text-sm
                p-2
                w-full
                block
              "
              rows="3"
              v-model="programDetails.description"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="program-details main-section">
        <div class="title">Program Details</div>
        <p class="text-black font-medium" v-if="!isEdit">
          Select the program pricing structure
        </p>
        <div class="inline-flex flex-col items-start gap-2 text-gray-800 pt-4 pb-8">
          <RadioInput
            :model.sync="programDetails.pricing"
            name="pricing"
            value="flat"
            label="Flat Rate"
            :disabled="isEdit"
          />
          <RadioInput
            :model.sync="programDetails.pricing"
            name="pricing"
            value="dynamic"
            label="Dynamic*"
            :disabled="isEdit"
          />
          <RadioInput
            :model.sync="programDetails.pricing"
            name="pricing"
            value="variable"
            label="Variable Amount"
            :disabled="isEdit"
          />
          <p class="text-sm mt-4">*Dynamic pricing uses a point structure where the number of products registered during the program period affects the pricing rate per unit</p>
        </div>

        <p class="text-black font-medium">Submission Rules</p>
        <div class="text-gray-800 py-4">
          <CheckboxInput
            class="mb-2"
            :model.sync="programDetails.unique"
            :reverseSync="true"
            name="unique"
            label="Allow program to be claimed multiple times"
          />
          <CheckboxInput
            class="mb-2"
            :model.sync="programDetails.require_attachment"
            name="require_attachment"
            label="Require attachment file"
          />
          <CheckboxInput
            v-if="programDetails.pricing != 'variable'"
            class="mb-2"
            :model.sync="programDetails.auto_populate"
            name="auto_populate"
            label="Auto-populate entries"
          />
        </div>
      </div>
      <div class="main-section flex items-center">
        <button
          type="button"
          class="btn btn-black px-8"
          :disabled="!canSave"
          @click="saveNewProgram"
        >
          Save Program
        </button>
        <button class="discard-link ml-6" @click="showDiscardModal = true">
          {{ isEdit ? "Discard Changes" : "Discard" }}
        </button>
      </div>
    </ValidationObserver>

    <ConfirmModal
      text="Discard changes?"
      confirmBtn="Discard"
      :show="showDiscardModal"
      @confirm="$router.push('/admin/retail-programs')"
      @close="showDiscardModal = false"
    />
  </section>
</template>

<script>
import PageHeader from "../../../component/PageHeader";
import Dropdown from "../../../component/Dropdown";
import { mapActions, mapState } from "vuex";
import { http } from "../../../../api";
import Loading from "../../../component/Loading.vue";
import RadioInput from "../../../component/Inputs/Radio";
import CheckboxInput from "../../../component/Inputs/Checkbox";
import ConfirmModal from "../../../component/ConfirmModal";

export default {
  props: ["isEdit"],
  name: "CreateProgram",
  data() {
    return {
      programDetails: {
        pricing: "flat",
        require_attachment: true,
        unique: true,
        auto_populate: false,
      },

      selectedProgramType: {
        name: "",
      },

      showDiscardModal: false,
    };
  },
  components: {
    PageHeader,
    Dropdown,
    Loading,
    RadioInput,
    CheckboxInput,
    ConfirmModal,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      allProgramTypes: (state) => state.programs.programsList.types,
    }),
    canSave: function () {
      return this.selectedProgramType.id && this.programDetails.name?.length
        ? true
        : false;
    },
  },
  mounted() {
    this.getProgramsList().then((data) => {
      if (this.isEdit) {
        const id = this.$route.params.id;

        //populate data
        const program = data.programs.find((p) => p.id == id);

        this.programDetails = {
          id: program.id,
          name: program.name,
          description: program.description,
          pricing: program.pricing,
          require_attachment: program.require_attachment,
          unique: program.unique,
          auto_populate: program.auto_populate,
        };

        this.selectedProgramType = data.types.find(
          (t) => t.id == program.program_type_id
        );
      }
    });
  },
  methods: {
    ...mapActions({
      getProgramsList: "programs/getProgramsList",
    }),
    selectProgramType(programType) {
      //set selected program type
      this.selectedProgramType = programType;
    },
    saveNewProgram() {
      this.savingProgram = true;

      if (this.isEdit) {
        http
          .post(`retail-programs/${this.$route.params.id}`, {
            ...this.programDetails,
            program_type_id: this.selectedProgramType.id,
          })
          .then((res) => {
            this.$router.push("/admin/retail-programs");
            this.$toasted.success(
              `program "${res.data.data.name}" has been updated!`
            );
            localStorage.toastedId = this.$toasted.id;
          })
          .catch((err) => {
            this.$toasted.error(err.response.data.message || "Server Error");
          });
      } else {
        http
          .post("retail-programs", {
            ...this.programDetails,
            program_type_id: this.selectedProgramType.id,
          })
          .then((res) => {
            this.$router.push("/admin/retail-programs");
            this.$toasted.success("New program created!");
            localStorage.toastedId = this.$toasted.id;
          })
          .catch((err) => {
            this.$toasted.error(err.response.data.message || "Server Error");
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#effective_dates input {
  width: unset;
  background: #e8e8e8;
  height: 1.85rem;
  font-size: 0.75rem;
}

button[disabled] {
  opacity: 0.3;
  cursor: not-allowed;
}
</style>
