<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
  updated() {
    if(localStorage.toastedId !== this.$toasted.id){
      this.$toasted.clear()
    }else{
      localStorage.toastedId = ''
    }
  }
}

</script>

<style>
</style>
