<template>
  <section class="user-details col-span-12">
    <div class="container">
      <PageHeader
        backText="Back to Manage Media"
        :backRoute="{ name: 'ManageMedia', query: { ...indexFilters } }"
        :saveButton="false"
        :discardLink="false"
        :details="{
          title: media_item.title,
          date: media_item.posted_on,
          dateFormat: 'MMMM Do, YYYY',
        }"
      >
        <template v-slot:actions>
          <router-link
            tag="button"
            class="btn btn-info px-40"
            :to="'/admin/media/' + media_item.id + '/edit'"
            >Edit</router-link
          >
        </template>
      </PageHeader>
      <div class="user-information">
        <div class="information">
          <div class="subtitle">Media Details</div>
          <div class="information-block" v-if="media_item">
            <div class="block-container">
              <div>
                <p>Title</p>
                <span>{{ media_item.title }}</span>
              </div>
              <div>
                <p>Media Type</p>
                <span>{{ media_item.type.media_type }}</span>
              </div>
              <div>
                <p>Category</p>
                <span>{{ media_item.category.media_type }}</span>
              </div>
              <div>
                <p>Date Added</p>
                <span>{{ media_item.posted_on }}</span>
              </div>
              <div>
                <p>Added By</p>
                <router-link
                  class="link"
                  :to="'/admin/users/' + media_item.created_by.id"
                  >{{ media_item.created_by.name }}</router-link
                >
              </div>
              <div>
                <p>Last Modified</p>
                <span>{{ media_item.last_modified }}</span>
              </div>
              <div>
                <p>File</p>
                <router-link
                  tag="a"
                  target="_blank"
                  class="link"
                  v-if="media_item.file"
                  :to="'/files/file/' + media_item.file.name"
                  >{{ media_item.file.name }}</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../../component/PageHeader";
import { mapActions, mapState } from "vuex";

export default {
  name: "mediaDetail",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      media_item: (state) => state.media.media_item,
      indexFilters: (state) => state.media.indexFilters,
    }),
  },
  mounted() {
    this.getMediaItem({ id: this.$route.params.id }).then(() => {});
  },
  methods: {
    ...mapActions({
      getMediaItem: "media/getMediaItem",
    }),
  },
  components: {
    PageHeader,
  },
};
</script>

<style scoped>
</style>
