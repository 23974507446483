<template>
  <section class="retail-programs-index col-span-12">
    <div class="container grid grid-cols-1 gap-0">
      <PageHeader
        title="Retail Program Preapprovals"
        :saveButton="false"
        :discardLink="false"
        :backLink="false"
        :addButton="userCanCreate"
        addText="Create Preapproval"
        @add="$router.push({ name: 'ProgramPreapprovalAdd' })"
      />
      <div class="filter-block">
        <div class="flex flex-wrap -m-1 sm:mr-4">
          <div class="dropdown-group m-1 w-full sm:w-64">
            <dropdown
              :data="allPrograms"
              fieldName="name"
              label="Program Name"
              @select="selectProgram"
              inputName="program"
              :editValue="selectedProgram.name"
            ></dropdown>
          </div>
          <div
            class="dropdown-group m-1 w-full sm:w-auto"
            v-if="user.role.id !== 3 || user.permissions.includes('GLOBAL')"
          >
            <dropdown
              :data="dealerships"
              :status="false"
              fieldName="name"
              label="Dealership"
              @select="selectDealership"
              :validation="true"
              :all="true"
              :search="true"
              placeholder="Dealership"
              inputName="dealership"
              :editValue="selectedDealership"
            ></dropdown>
          </div>
          <div class="form-group m-1 w-full sm:w-auto">
            <label class="control-label">Search</label>
            <div class="relative">
              <input
                v-model="filterData.serial_search"
                type="text"
                name="search"
                value=""
                class="search"
                v-on:keyup.enter="applyFilter"
                placeholder="Serial No."
              />
              <inline-svg
                class="search-icon"
                :src="require('@/assets/image/icons/search.svg')"
              />
            </div>
          </div>
          <div class="form-group m-1 w-full sm:w-auto">
            <dropdown
              :data="statusList"
              :statusColors="statusColors"
              :status="true"
              fieldName="name"
              label="Status"
              @select="selectStatus"
              :validation="false"
              inputName="status"
              :editValue="filterData.status"
            ></dropdown>
          </div>
        </div>
        <div class="card">
          <div
            class="btn btn-black cursor-pointer px-40"
            @click.prevent="applyFilter"
          >
            <div class="text"><a>Apply</a></div>
          </div>
          <div class="btn redo px-15" @click.prevent="resetFilter">
            <a>
              <inline-svg :src="require('@/assets/image/icons/redo.svg')" />
            </a>
          </div>
        </div>
      </div>
      <div class="text-sm table-block col-span-1">
        <data-table
          :page="filterData.page"
          :header="retailProgramsTable.header"
          :data="preapprovals"
          :isLoading="isLoading"
        />
      </div>
      <div class="pages" v-if="pagination.total && pagination.total_pages > 1">
        <div class="pages-quantity">
          <template v-if="!isLoading">
            Page {{ filterData.page }} of {{ pagination.total_pages }} - Showing
            {{ pagination.per_page }} Results of {{ pagination.total }} Total
          </template>
        </div>
        <paginate
          :page-count="pagination.total_pages"
          :page-range="screenWidth > 467 ? 6 : screenWidth > 320 ? 3 : 2"
          :margin-pages="1"
          :initial-page="filterData.page - 1"
          :click-handler="paginationClick"
          :prev-text="'&laquo;'"
          :next-text="'&raquo;'"
          :container-class="'pages-btn'"
          :active-class="isLoading ? '' : 'active'"
        >
        </paginate>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../component/PageHeader";
import DataTable from "../../component/DataTable";
import Dropdown from "../../component/Dropdown";
import Loading from "../../component/Loading";
import { mapActions, mapState } from "vuex";
import ClickOutside from "vue-click-outside";

export default {
  name: "ProgramPreapproval",
  data() {
    return {
      baseUrl: "/retail-program-preapprovals",
      screenWidth: window.innerWidth,

      filterData: {
        // search: "",
        page: 1,
      },

      selectedProgram: {
        name: "All",
      },
      selectedDealership: "All",

      statusList: [
        { id: 1, name: "Unclaimed", color: "606060" },
        { id: 2, name: "Claimed", color: "#B1D69F" },
      ],

      isLoading: false,

      retailProgramsTable: {
        header: [
          {
            field: "created_at",
            name: "Creation Date",
            width: "8%",
            headerWidth: "8%",
          },
          {
            field: "program_name",
            name: "Program Name",
            width: "18%",
            headerWidth: "18%",
            transform: this.transformBold,
            transformField: "program_name",
          },
          {
            field: "dealership",
            name: "Dealership",
            width: "18%",
            headerWidth: "18%",
          },
          // {
          //   field: "salesperson",
          //   name: "Salesperson",
          //   width: "14%",
          //   headerWidth: "14%",
          // },
          {
            field: "serial",
            name: "Serial No.",
            width: "14%",
            headerWidth: "14%",
          },
          {
            field: "amount",
            name: "Amount",
            transformField: "amount",
            transform: this.transformToCurrency,
            width: "8%",
            headerWidth: "8%",
          },
          {
            field: "preapproved_by",
            name: "Preapproved By",
            width: "16%",
            headerWidth: "16%",
          },
          {
            field: "status",
            name: "Status",
            transformField: "status",
            transform: this.transformToStatus,
            width: "12%",
            headerWidth: "12%",
          },
          {
            transformField: "id",
            name: "Actions",
            transformToComponent: "ProgramPreapprovalsTableDropdown",
            width: "6%",
            headerWidth: "6%",
          },
        ],
      },
    };
  },
  async created() {
    window.addEventListener("resize", this.handleResize);

    await this.getProgramsList();

    //set up parameters based on route query
    if (this.$route.query.page) {
      this.filterData.page = parseInt(this.$route.query.page);
    }

    // if (this.$route.query.search) {
    //   this.filterData.search = this.$route.query.search;
    // }

    if (this.$route.query.program_id) {
      this.filterData.program_id = this.$route.query.program_id;

      const program = this.allPrograms.find(
        (p) => p.id == this.$route.query.program_id
      );
      if (program) {
        this.selectedProgram = program;
      }
    }

    if (this.$route.query.dealership_id) {
      this.filterData.dealership_id = this.$route.query.dealership_id;

      this.selectedDealership = this.dealerships?.find(
        (item) => item.id == this.$route.query.dealership_id
      )?.name;
    }

    this.isLoading = true;
    try {
      await this.getPreapprovals(this.filterData);
    } catch (err) {
      this.$toasted.error(err.response.data.message || "Server Error");
    }

    this.isLoading = false;

    if (this.filterData.dealership_id) {
      this.selectedDealership = this.dealerships.find(
        (d) => d.id == this.filterData.dealership_id
      )?.name;
    }
  },
  components: {
    PageHeader,
    Loading,
    DataTable,
    Dropdown,
  },
  computed: {
    ...mapState({
      dealerships: (state) => state.programPreapprovals.dealerships,

      user: (state) => state.auth.user,
      allPrograms: (state) => state.programs.programsList.programs,
      preapprovals: (state) => state.programPreapprovals.preapprovals.data,
      pagination: (state) => state.programPreapprovals.preapprovals.pagination,
    }),
    userCanCreate: function () {
      return (
        this.user.permissions.includes("GLOBAL") ||
        this.user.permission_actions?.["Program Preapproval"]?.Create
      );
    },
    statusColors: function () {
      const colors = {};

      this.statusList.forEach((status) => {
        colors[status.id] = status.color;
      });

      return colors;
    },
  },
  methods: {
    ...mapActions({
      getProgramsList: "programs/getProgramsList",
      // getProgramBatches: "programs/getProgramBatches",
      getPreapprovals: "programPreapprovals/getPreapprovals",
    }),

    handleResize() {
      this.screenWidth = window.innerWidth;
    },

    selectProgram(program) {
      this.selectedProgram = program;

      if (program.id) {
        this.filterData.program_id = program.id;
      } else {
        delete this.filterData.program_id;
      }

      this.applyFilter();
    },
    selectDealership(dealership) {
      this.selectedDealership = dealership.name;

      if (dealership.id || dealership.id === 0) {
        this.filterData.dealership_id = dealership.id;
      } else {
        delete this.filterData.dealership_id;
      }

      this.applyFilter();
    },
    selectStatus(status) {
      this.$set(this.filterData, "status", status?.name);

      if (!status || !status.name) delete this.filterData.status;
    },
    resetFilter() {
      this.selectedDealership = "All";
      this.selectedProgram = {
        name: "All",
      };
      this.filterData = {
        page: 1,
      };
      this.applyFilter();
    },
    applyFilter() {
      this.filterData.page = 1;
      this.$router.push(
        this.baseUrl + "?" + new URLSearchParams(this.filterData).toString()
      );

      this.isLoading = true;
      this.getPreapprovals(this.filterData)
        .then((res) => {})
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    transformBold(text) {
      return `<strong>${text}</strong>`;
    },
    transformToStatus(text, val) {
      let statusName = "pending";

      switch (text.toLowerCase()) {
        case "claimed":
          statusName = "success";
          break;
        case "rejected":
          statusName = "rejected";
          break;
        case "cancelled":
          statusName = "rejected";
          break;
        case "unclaimed":
        default:
          statusName = "draft";
          break;
      }
      return `<div class="c-status c-status--${statusName} capitalize" style="white-space: nowrap;">${text}</div>`;
    },
    transformToCurrency(amount, _) {
      return (
        "$" +
        (amount ?? 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
    },
    paginationClick(pageNum) {
      this.filterData.page = pageNum;
      this.$router.push(
        this.baseUrl + "?" + new URLSearchParams(this.filterData).toString()
      );
      this.isLoading = true;
      this.getPreapprovals(this.filterData)
        .then((res) => {})
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  directives: {
    ClickOutside,
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
.filter-block {
  display: flex;
  align-items: flex-end;

  .dropdown-group {
    display: flex;
    // margin-top: 40px;

    @media (max-width: 700px) {
      flex-direction: column;

      .dealer,
      .address {
        margin-top: 10px;
        width: 100% !important;
      }
    }
  }

  .card {
    display: flex;
    padding-top: 27.5px;
    margin-left: auto;
  }
}
</style>
