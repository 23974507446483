<template>
  <section class="user-details col-span-12">
    <div class="container">
      <PageHeader
        backText="Back to Manage News"
        :backRoute="{ name: 'ManageNews', query: { ...indexFilters } }"
        :saveButton="false"
        :discardLink="false"
        :details="{
          title: news_item.title,
          date: news_item.posted_on,
          dateFormat: 'MMMM Do, YYYY',
        }"
      >
        <template v-slot:actions>
          <router-link
            tag="button"
            class="btn btn-info px-40"
            :to="'/admin/news/' + news_item.id + '/edit'"
            >Edit</router-link
          >
        </template>
      </PageHeader>
      <div class="news-information">
        <div class="subtitle">{{ news_item.title }}</div>
        <div v-html="news_item.article"></div>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../../component/PageHeader";
import { mapActions, mapState } from "vuex";

export default {
  name: "newsDetail",
  data() {
    return {
      news_item: {},
    };
  },
  computed: {
    ...mapState({
      news: (state) => state.news.news_item,
      user: (state) => state.auth.user,
      indexFilters: (state) => state.news.indexFilters,
    }),
  },
  mounted() {
    this.getNewsItem({ id: this.$route.params.id })
      .then((res) => {
        this.news_item = this.news;
      })
      .catch((err) => {
        this.$toasted.error(err.response.data.message || "Server Error");
      });
  },
  distroyed() {
    this.news_item = {};
  },
  methods: {
    ...mapActions({
      getNewsItem: "news/getNewsItem",
    }),
  },
  components: {
    PageHeader,
  },
};
</script>

<style>
table,
td,
th {
  border: 1px solid black;
}

table {
  width: 100%;
  border-collapse: collapse;
}
</style>
