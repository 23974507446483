var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"user-details col-span-12"},[_c('div',{staticClass:"container manage-container"},[_c('PageHeader',{attrs:{"backText":"Back to Advertisements","backRoute":{
        name: 'GalleryAdvertisements',
        params: { id: _vm.galleryItem.media_type },
      },"saveButton":false,"discardLink":false,"details":{
        title: _vm.galleryItem.title,
        date: _vm.galleryItem.posted_on,
        dateFormat: 'MMMM Do, YYYY',
      }},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"btn btn-info px-15",on:{"click":function($event){return _vm.download(_vm.galleryItem)}}},[_c('button',{staticClass:"btn btn-info mr-3",attrs:{"readonly":_vm.disableFileDownload,"type":"button"}},[(_vm.disableFileDownload)?_c('loading'):_vm._e(),_c('div',{staticClass:"text mr-3"},[_c('span',[_vm._v("Download")])]),_c('div',{staticClass:"export"},[_c('inline-svg',{attrs:{"src":require('@/assets/image/icons/download.svg')}})],1)],1)])]},proxy:true}])}),_c('div',{staticClass:"video-card"},[_c('div',{staticClass:"image"},[_c('img',{attrs:{"src":_vm.galleryItem.file,"alt":""}})])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }