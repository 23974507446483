<template>
  <section class="user-details col-span-12">
    <div class="container">
      <PageHeader
        backText="Back to Manage Categories"
        :backRoute="{ name: 'ManageCategories' }"
        :saveButton="false"
        :discardLink="false"
        :details="{
          title: categoryItem.title,
          date: categoryItem.date_added_time,
        }"
      >
        <template v-slot:actions>
          <router-link
            tag="button"
            class="btn btn-info px-40"
            :to="'/admin/media/categorie/' + categoryItem.id + '/edit'"
            >Edit</router-link
          >
        </template>
      </PageHeader>
      <div class="user-information">
        <div class="information">
          <div class="subtitle">Category Details</div>
          <div class="information-block">
            <div class="block-container" v-if="categoryItem">
              <div>
                <p>Title</p>
                <span v-if="categoryItem.title">{{ categoryItem.title }}</span>
                <span v-else>No Title</span>
              </div>
              <div>
                <p>Media Type</p>
                <span
                  v-if="categoryItem.media_type && categoryItem.media_type.name"
                  >{{ categoryItem.media_type.name }}</span
                >
                <span v-else>No Media Type</span>
              </div>
              <div>
                <p>Description</p>
                <span v-if="categoryItem.description">{{
                  categoryItem.description
                }}</span>
                <span v-else>No Description</span>
              </div>
              <div>
                <p>Date Added</p>
                <span v-if="categoryItem.date_added">{{
                  categoryItem.date_added
                }}</span>
                <span v-else>No Date Added</span>
              </div>
              <div>
                <p>Added By</p>
                <a
                  v-if="categoryItem.created_by && categoryItem.created_by.name"
                  class="link"
                  :href="categoryItem.created_by"
                  target="_blank"
                  >{{ categoryItem.created_by.name }}</a
                >
                <span v-else>No Added By</span>
              </div>
              <div>
                <p>Last Modified</p>
                <span v-if="categoryItem.last_modified">{{
                  categoryItem.last_modified
                }}</span>
                <span v-else>No Last Modified</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../../component/PageHeader";
import { mapActions, mapState } from "vuex";

export default {
  name: "categoriesDetail",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      categoryItem: (state) => state.mediaCategory.categoryItem,
    }),
  },
  mounted() {
    this.getCategoryItem({ id: this.$route.params.id });
  },
  methods: {
    ...mapActions({
      getCategoryItem: "mediaCategory/getCategoryItem",
    }),
  },
  components: {
    PageHeader,
  },
};
</script>

<style scoped>
</style>
