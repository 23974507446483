<template>
  <section class="user-details col-span-12">
    <div class="container">
      <PageHeader
        backText="Back to Manage Dealers"
        :backRoute="{ name: 'ManageDealers' }"
        :saveButton="false"
        :discardLink="false"
        :details="{
          title: dealerItem.name,
          subtitle: dealerItem.dealer_code,
        }"
      >
        <template v-slot:actions>
          <router-link
            tag="button"
            class="btn btn-info px-40"
            :to="{ name: 'editDealer', params: { id: dealerItem.id}}"
            >Edit</router-link
          >
        </template>
      </PageHeader>
      <div class="user-information">
        <div class="information">
          <div class="subtitle">Dealership Settings</div>
          <div class="information-block" v-if="dealerItem">
            <div class="block-container">
              <div>
                <p>Name</p>
                <span>{{ dealerItem.name }}</span>
              </div>
              <div>
                <p>Email Address</p>
                <a
                  :href="`mailto:${dealerItem.email}`"
                  class="link"
                  target="_blank"
                  >{{ dealerItem.email }}</a
                >
              </div>
              <div v-if="dealerItem.territory_manager">
                <p>Territory Manager</p>
                <span>{{ dealerItem.territory_manager.name }}</span>
              </div>
              <div>
                <p>Office Number</p>
                <span>{{
                  dealerItem.office_number ? dealerItem.office_number : "---"
                }}</span>
              </div>
              <div>
                <p>Fax Number</p>
                <span>{{
                  dealerItem.fax_number ? dealerItem.fax_number : "---"
                }}</span>
              </div>
              <div v-if="dealerItem.website_address">
                <p>Website Address</p>
                <a
                  class="link"
                  :href="'//' + dealerItem.website_address"
                  target="_blank"
                  >{{ dealerItem.website_address }}</a
                >
              </div>
              <div>
                <p>Dealer Code</p>
                <span>{{ dealerItem.dealer_code }}</span>
              </div>
              <div v-if="dealerItem.parent_dealership">
                <p>Parent Dealership</p>
                <router-link
                  tag="a"
                  class="link"
                  :to="{ name: 'dealerDetail', params: { id: dealerItem.parent_dealership.id}}"
                >{{ dealerItem.parent_dealership.name }}</router-link>
              </div>
              <div>
                <p>Primary Shipping Address</p>
                <span>{{
                  dealerItem.shipping_address
                    ? dealerItem.shipping_address
                    : "---"
                }}</span>
              </div>
              <div>
                <p>Billing Address</p>
                <span>{{
                  dealerItem.billing_address
                    ? dealerItem.billing_address
                    : "---"
                }}</span>
              </div>
              <div v-if="dealerItem.additional_addresses && dealerItem.additional_addresses.length">
                <p>Additional Addresses</p>
                <span
                  v-for="(address, index) in dealerItem.additional_addresses"
                  v-bind:key="`address_${index}`"
                >
                  {{ address.additional_address }}
                  <br v-if="index < dealerItem.additional_addresses.length - 1" />
                </span >
              </div>
            </div>
          </div>
        </div>
        <div class="child-dealerships-col" v-if="dealerItem.child_dealerships && dealerItem.child_dealerships.length">

          <div class="subtitle mb-5">Child Dealerships</div>
          <div class="c-callout">
            <router-link
              v-for="dealership in dealerItem.child_dealerships"
              :key="`child_dealership_${dealership.id}`"
              tag="a"
              class="text-blue underline"
              :to="{ name: 'dealerDetail', params: { id: dealership.id}}"
            >{{ dealership.name }}</router-link>
            <!-- <a
              v-for="dealership in dealerItem.child_dealerships"
              :key="`child_dealership_${dealership.id}`"
              :href=""
            >{{ dealership.name }}</a> -->
          </div>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../../component/PageHeader";
import { mapActions, mapState } from "vuex";

export default {
  name: "dealerDetail",
  watch:{
    $route (to, from){
      this.loadDealership();
    }
  },
  created() {
    this.loadDealership();
  },
  computed: {
    ...mapState({
      dealerItem: (state) => state.dealers.dealerItem,
    }),
  },
  methods: {
    ...mapActions({
      getDealerItem: "dealers/getDealerItem",
    }),

    loadDealership() {
      this.getDealerItem({ id: this.$route.params.id })
        .then((res) => {})
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    }

  },
  components: {
    PageHeader,
  },
};
</script>

<style type="text/css" lang="scss" scoped>
.title-code {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #5f5f5f;
}

.user-details .container .user-information {
  display: flex;
  width: 100%;
  .information {
    flex: 0 0 50%;
  }
}

.child-dealerships-col {
  margin-top: 40px;
  margin-left: 50px;
  min-width: 250px;

  a {
    display: block;
    font-size: 14px;
    font-weight: 500;
    
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
</style>
