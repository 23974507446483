export default {
    iconPack: 'custom-class',
    type: ['success', 'error'],
    position: "top-right",
    className: 'toasted',
    duration : 1500,
    keepOnHover: true,
    singleton:true,
    action : {
        icon :'fas fa-times',
        onClick : (e, toastObject) => {
            toastObject.goAway(0);
        }
    },
  }