<template>
  <section class="create-program col-span-12">
    <ValidationObserver tag="div" ref="form" class="container">
      <PageHeader
        title="Manage Retail Programs"
        :saveButton="false"
        :addButton="true"
        addText="Create New Program"
        @add="$router.push({ name: 'CreateProgram' })"
        :discardLink="selectedProgram && selectedProgram.id ? true : false"
        :discardText="`Edit ${selectedProgram.name} Program`"
        @discard="
          $router.push({
            name: 'EditProgram',
            params: { id: selectedProgram.id },
          })
        "
        backText="Back to Settings"
        :backRoute="{ name: 'AdminSettings' }"
      />
      <div class="search-filters main-section">
        <div class="title">Program Details</div>
        <div class="dropdown-group">
          <div class="w-64 mr-3 max-w-full">
            <dropdown
              :data="allProgramTypes"
              fieldName="name"
              label="Program Type"
              @select="selectProgramType"
              inputName="program_type"
              :editValue="selectedProgramType.name"
              :all="false"
            ></dropdown>
          </div>
          <div class="w-64 mr-3 max-w-full">
            <dropdown
              :data="filteredProgramsList"
              fieldName="name"
              label="Program"
              @select="selectProgram"
              inputName="program"
              :editValue="selectedProgram.name"
              :all="false"
            ></dropdown>
          </div>
          <div class="w-64 max-w-full" v-if="selectedProgram.id">
            <dropdown
              :data="programInstances"
              fieldName="formattedDateRange"
              label="Instance"
              @select="selectProgramInstance"
              inputName="program_instance"
              :editValue="selectedProgramInstance.formattedDateRange"
              :all="false"
            ></dropdown>
          </div>
        </div>
      </div>
      <template v-if="this.selectedProgram.id">
        <div class="program-details main-section">
          <div class="title">Instance Dates & Eligibilities</div>
          <p class="text mb-8">
            Enter the Effective Dates, Tractor Model and Bonus Amount
          </p>

          <div class="flex flex-col xs:flex-row">
            <button
              class="btn btn-info btn--outline px-12 mb-4 xs:mb-0"
              @click="newInstance"
              type="button"
            >
              + Create New Instance
            </button>
            <button
              class="btn btn-info btn--outline px-12 xs:ml-4"
              @click="cloneInstance"
              type="button"
              v-if="this.selectedProgramInstance.id"
            >
              Clone Instance
            </button>
          </div>

          <div class="my-8">
            <label class="control-label mb-2 block">Effective Dates</label>
            <div
              class="
                border border-gray-300
                bg-gray-100
                rounded
                effective-dates
              "
            >
              <input
                type="date"
                class="bg-gray-200 rounded py-2 px-4"
                @change="programInstanceDidChange = true"
                v-model="selectedProgramInstance.start_date"
              />
              <span> - </span>
              <input
                type="date"
                :min="selectedProgramInstance.start_date"
                class="bg-gray-200 rounded py-2 px-4"
                @change="programInstanceDidChange = true"
                v-model="selectedProgramInstance.end_date"
              />
            </div>
          </div>

          <div class="flex flex-col tablet:flex-row items-start">
            <div class="inline-block max-w-xl order-last tablet:order-none">
              <template v-if="this.selectedProgramInstance.id">
                <div
                  class="
                    border border-gray-300
                    bg-white
                    py-4
                    px-2
                    xs:px-4
                    rounded
                    mb-12
                    -mx-2
                    xs:mx-0
                  "
                  style="max-width: 400px"
                >
                  <template v-if="selectedProgram.pricing != 'variable'">
                    <label class="control-label mb-4 block">Eligibilities By Model</label>
                    <table class="mb-4 table">
                      <thead>
                        <tr>
                          <th class="text-left pb-4 col1">
                            <span>Model</span>
                          </th>
                          <th class="text-center pb-4 col2" v-if="selectedProgram.pricing == 'flat'">
                            <span>Amount</span>
                          </th>
                          <template v-if="selectedProgram.pricing == 'dynamic'">
                            <th class="text-left pb-4 pl-1 col2">
                              <span>Points</span>
                            </th>
                            <th class="text-left pb-4 pl-4 col2">
                              <span>Payout</span>
                            </th>
                          </template>
                          <th>
                            <span></span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <template
                          v-for="(
                            row, index
                          ) in selectedProgramInstance.eligibilities"
                        >
                          <tr
                            :key="'eligibility_' + index"
                            v-bind:class="{
                              'bg-gray-100': index % 2 === 1,
                              'bg-green-200': addedModel === row.model,
                              'transition-colors duration-1000':
                                addedModel !== row.model,
                            }"
                          >
                            <td class="align-middle p-1 pr-12 col1">
                              {{ row.model }}
                            </td>
                            <td class="p-1 pl-4 col2 relative" v-if="selectedProgram.pricing == 'flat'">
                              <span
                                class="
                                  absolute
                                  left-0
                                  top-0
                                  h-full
                                  flex
                                  items-center
                                "
                                >$</span
                              >
                              <input
                                class="inline-block w-full align-middle"
                                type="number"
                                v-model="row.amount"
                                size="3"
                                @change="programInstanceDidChange = true"
                              />
                            </td>
                            <template v-if="selectedProgram.pricing == 'dynamic'">
                              <td class="p-1 pr-4 col2">
                                <input
                                  class="w-full"
                                  type="number"
                                  min="0"
                                  step="any"
                                  v-model="row.points"
                                  size="3"
                                  @change="programInstanceDidChange = true"
                                />
                              </td>
                              <td class="p-1 pr-4 col2 whitespace-no-wrap">
                                <span>x</span>
                                <input
                                  class="w-16 ml-1"
                                  type="number"
                                  min="0"
                                  step="any"
                                  v-model="row.payout_multiplier"
                                  size="3"
                                  @change="programInstanceDidChange = true"
                                />
                              </td>
                            </template>
                            <td class="align-middle col3 p-1 pl-4">
                              <button
                                type="button"
                                class="c-removeButton align-middle"
                                @click="removeBonus(index)"
                              ></button>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                    <div class="inline-flex mt-4">
                      <dropdown
                        ref="addModel"
                        v-if="this.selectedProgramInstance.eligibilities"
                        :data="remainingTractorModels"
                        :search="true"
                        fieldName="model_code"
                        @select="addBonus"
                        :all="false"
                        placeholder="+ Add Model"
                        :noSelectOnClose="true"
                      ></dropdown>
                    </div>
                  </template>
                  <div v-else class="text-center">
                    <p class="p-4 italic">
                      This program has variable pricing and does not require
                      pricing by model.
                    </p>
                  </div>
                </div>
                <div
                  class="
                    -mt-4
                    mb-6
                    flex
                    items-center
                    text-sm
                    flex-col
                    xs:flex-row
                  "
                >
                  <button
                    class="
                      btn btn-black btn--inline
                      relative
                      px-6
                      mr-0
                      mb-4
                      xs:mr-4 xs:mb-0
                    "
                    :disabled="!programInstanceDidChange || instanceButtonsDisabled"
                    @click="saveProgramInstance"
                    type="button"
                  >
                    <span v-bind:class="{ 'opacity-0': savingProgram }"
                      >Save</span
                    >
                    <div
                      v-if="savingProgram"
                      class="
                        absolute
                        w-full
                        h-full
                        top-0
                        left-0
                        z-10
                        flex
                        items-center
                        justify-center
                      "
                    >
                      <loading />
                    </div>
                  </button>
                  <button
                    v-if="selectedProgram.pricing == 'dynamic'"
                    class="
                      btn btn-black btn--inline
                      relative
                      px-6
                      mr-0
                      mb-4
                      xs:mr-4 xs:mb-0
                    "
                    @click="recalculateBatchesForInstance"
                    :disabled="instanceButtonsDisabled || programInstanceDidChange"
                  >
                    Recalculate
                  </button>
                  <button
                    @click="showConfirmModal.discardChanges = true"
                    :disabled="!programInstanceDidChange || instanceButtonsDisabled"
                    type="button"
                    class="underline hover:no-underline mb-4 xs:mr-6 xs:mb-0"
                  >
                    {{
                      selectedProgramInstance.id === "new" ? "Cancel" : "Reset"
                    }}
                  </button>

                  <button
                    v-if="
                      selectedProgramInstance.id &&
                      selectedProgramInstance.id != 'new'
                    "
                    type="button"
                    class="text-red-600 underline hover:no-underline xs:ml-auto"
                    @click="showConfirmModal.deleteInstance = true"
                    :disabled="instanceButtonsDisabled"
                  >
                    Delete
                  </button>
                </div>
              </template>
            </div>
            <div
              v-if="selectedProgram.pricing == 'dynamic' && this.selectedProgramInstance.id"
              class="
                align-top
                border border-gray-300
                bg-white
                py-4
                px-2
                xs:px-4
                rounded
                mb-8
                ml-0
                tablet:ml-8
              "
              style="max-width: min-content"
            >
              <label class="control-label mb-4 block">Payout Structure</label>
              <table class="mb-4 table">
                <thead>
                  <tr>
                    <th class="text-left pb-4 pr-4 col-registered">
                      <span>Registered</span>
                    </th>
                    <th class="text-left pb-4 pr-4 col2">
                      <span>Amount</span>
                    </th>
                    <th>
                      <span></span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template
                    v-for="(
                      row, index
                    ) in selectedProgramInstance.payouts"
                  >
                    <tr
                      :key="'payout_' + index"
                      v-bind:class="{
                        'bg-gray-100': index % 2 === 1
                      }"
                    >
                      <td class="align-middle p-1 col-registered whitespace-no-wrap pr-8">
                        <span>&GreaterEqual;</span>
                        <input
                          class="inline-block ml-1 w-20 align-middle"
                          type="number"
                          v-model="row.qty"
                          min="0"
                          step="1"
                          size="4"
                          @change="programInstanceDidChange = true"
                        />
                      </td>
                      <td class="align-middle p-1 pl-4 col2 relative">
                        <span
                          class="
                            absolute
                            left-0
                            top-0
                            h-full
                            flex
                            items-center
                          "
                          >$</span
                        >
                        <input
                          class="inline-block w-20 align-middle"
                          type="number"
                          v-model="row.amount"
                          min="0"
                          step="0.01"
                          size="5"
                          @change="programInstanceDidChange = true"
                        />
                      </td>
                      <td class="align-middle col3 p-1 pl-4">
                        <button
                          type="button"
                          class="c-removeButton align-middle"
                          @click="removePayout(index)"
                        ></button>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
              <div class="mt-3">
                <button type="button" class="underline hover:no-underline text-sm" @click="addPayout">
                  + Add Payout
                </button>
              </div>
            </div>

          </div>
        </div>
        <div class="main-section mb-20"></div>
      </template>
    </ValidationObserver>

    <ConfirmModal
      text="Are you sure you want to discard changes to this program instance?"
      confirmBtn="discard changes"
      :show="showConfirmModal.discardChanges"
      @confirm="discardProgramInstanceChanges"
      @close="showConfirmModal.discardChanges = false"
    />
    <ConfirmModal
      text="Are you sure you want to DELETE this program instance?"
      confirmBtn="DELETE"
      :show="showConfirmModal.deleteInstance"
      @confirm="deleteInstance"
      @close="showConfirmModal.deleteInstance = false"
    />
  </section>
</template>

<script>
import PageHeader from "../../../component/PageHeader";
import Dropdown from "../../../component/Dropdown";
import ConfirmModal from "../../../component/ConfirmModal";
import { mapActions, mapState } from "vuex";
import { http } from "../../../../api";
import Loading from "../../../component/Loading.vue";

export default {
  name: "EditPrograms",
  data() {
    return {
      baseUrl: window.location.href,
      tractorModels: [],

      selectedProgramType: {
        name: "",
      },
      selectedProgram: {
        name: "",
      },
      selectedProgramInstance: {
        formattedDateRange: "",
      },
      selectedProgramInstanceOriginal: {
        formattedDateRange: "",
      },

      programInstances: [],

      programInstanceDidChange: false,

      showConfirmModal: {
        discardChanges: false,
        deleteInstance: false,
      },
      savingProgram: false,
      recalculating: false,
      deletingInstance: false,
      addedModel: null,
    };
  },
  components: {
    PageHeader,
    Dropdown,
    ConfirmModal,
    Loading,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      allProgramTypes: (state) => state.programs.programsList.types,
      //   allPrograms: (state) => state.programs.programsList.programs,
      filteredProgramsList: (state) =>
        state.programs.programsList.filteredPrograms,
      defaultModelsList: (state) => state.programs.defaultModelsList,
    }),

    remainingTractorModels: function () {
      return this.tractorModels?.filter(
        (model) =>
          !this.selectedProgramInstance?.eligibilities?.find(
            (bonus) => bonus.model === model.model_code
          )
      );
    },

    instanceButtonsDisabled() {
      return this.savingProgram || this.deletingInstance || this.recalculating;
    }
  },
  created() {
    this.getProgramsList().then((data) => {
      this.tractorModels = [...data.models];
    });
    // this.getCreateProgramInstance();
  },
  methods: {
    ...mapActions({
      getProgramsList: "programs/getProgramsList",
      getCreateProgramInstance: "programs/getCreateProgramInstance",
      filterProgramsList: "programs/filterProgramsList",
    }),
    updateUrl() {
      // const newUrl = {};
      // if (this.selectedProgramType.id)
      //   newUrl.program_type = this.selectedProgramType.id;
      // if (this.selectedProgram.id) newUrl.program = this.selectedProgram.id;
      // if (this.selectedProgramInstance.id)
      //   newUrl.program_instance = this.selectedProgramInstance.id;
      // window.history.replaceState(
      //   "",
      //   "",
      //   this.baseUrl + "?" + new URLSearchParams(newUrl).toString()
      // );
    },
    getInitialParams() {
      const urlFilters = new URLSearchParams(window.location.search);

      // this.search = urlFilters.get('search') ?? '';
      // this.sortField = urlFilters.get('sort_by');
      // this.sortDirection = urlFilters.get('sort_direction');

      // if (searchParams.get('page')) {
      //     this.page = urlFilters.get('page');
      // }
    },
    selectProgramType(programType) {
      //set selected program type
      this.selectedProgramType = programType;

      //filter programs dropdown by selected program type
      this.filterProgramsList(programType);

      //if there is a selected program that does not match the new program type, reset selected program and instance
      if (this.selectedProgram.program_type_id != programType.id) {
        this.selectedProgram = { name: "" };
        this.selectedProgramInstance = { formattedDateRange: "" };
        this.programInstances = [];
        this.programInstanceDidChange = false;
      }

      if (!this.selectedProgram.id && this.filteredProgramsList.length === 1) {
        this.selectProgram(this.filteredProgramsList[0]);
      }

      this.updateUrl();
    },
    selectProgram(program) {
      // If the selected program just changed, reset the selected program instance
      if (!this.selectedProgram.id || this.selectedProgram.id != program.id) {
        this.selectedProgramInstance = {
          formattedDateRange: "",
        };
        this.programInstanceDidChange = false;
      }

      // If there was not a selected program type, select the matching program type and filter the programs dropdown list to match
      if (this.selectedProgramType.id != program.program_type_id) {
        this.selectedProgramType = this.allProgramTypes.find(
          (t) => t.id === program.program_type_id
        );
        if (this.selectedProgramType)
          this.filterProgramsList(this.selectedProgramType);
      }

      // Set the selected program, and get program instances
      this.selectedProgram = program;
      this.getProgramInstances(program);
      this.updateUrl();
    },
    selectProgramInstance(programInstance) {
      this.selectedProgramInstance = JSON.parse(
        JSON.stringify(programInstance)
      );

      this.selectedProgramInstance.eligibilities.sort((a, b) =>
        a.model > b.model ? 1 : -1
      );

      this.selectedProgramInstanceOriginal = JSON.parse(
        JSON.stringify(this.selectedProgramInstance)
      );

      this.updateUrl();
    },

    async getProgramInstances(program = this.selectedProgram) {
      await http
        .get("retail-programs/" + program.id + "/instances")
        .then((res) => {
          // this.isNewInstance = false;
          this.programInstances = res.data.data;

          if (
            !this.selectedProgramInstance.id &&
            this.programInstances.length
          ) {
            this.selectProgramInstance(this.programInstances[0]);
          } else {
            this.updateSelectedInstance();
          }
        });
    },

    removeBonus(i) {
      this.selectedProgramInstance.eligibilities.splice(i, 1);
      this.programInstanceDidChange = true;
    },

    removePayout(i) {
      this.selectedProgramInstance.payouts.splice(i, 1);
      this.programInstanceDidChange = true;
    },

    addBonus(model) {
      if (!this.selectedProgramInstance.eligibilities) return;
      if (!model.model_code) return;

      this.$refs.addModel.searchValue = "";
      this.$refs.addModel.$el.querySelector("input").focus();


      this.selectedProgramInstance.eligibilities.push({
        model: model.model_code,
        ...(this.selectedProgram.pricing === "dynamic" ? {
          points: 1,
          payout_multiplier: 1
        } : {
          amount: 0,
        })
      });

      this.selectedProgramInstance.eligibilities.sort((a, b) =>
        a.model > b.model ? 1 : -1
      );

      //temporarily highlight newly added model
      this.addedModel = model.model_code;
      setTimeout(() => {
        this.addedModel = null;
      }, 2500);
      this.programInstanceDidChange = true;
    },

    addPayout() {
      if (!this.selectedProgramInstance) return;

      if (!this.selectedProgramInstance.payouts) {
        this.selectedProgramInstance.payouts = [];
      }
      this.selectedProgramInstance.payouts.push(this.newPayout());
    },

    newPayout(qty = null, amount = null) {
      return {
        qty,
        amount
      };
    },

    newInstance() {

      // create eligibilities array based on program pricing
      let eligibilities = [];
      if ((this.selectedProgram.pricing !== "variable")) {
        // Only list program default models, and convert format to model and amount
        const models = this.tractorModels.filter((m) => m.program_default);

        if (this.selectedProgram.pricing === "flat") {
          eligibilities = models.map((m) => ({
            model: m.model_code,
            amount: 0,
          }));
        } else if (this.selectedProgram.pricing === "dynamic") {
          eligibilities = models.map((m) => ({
            model: m.model_code,
            points: 1,
            payout_multiplier: 1
          }));
        }
      }


      // create new blank instance
      this.selectedProgramInstance = {
        id: "new",
        start_date: this.getDefaultNewStartDate(),
        end_date: null,
        eligibilities: eligibilities,
        formattedDateRange: "(new)",
        payouts: this.selectedProgram.pricing === "dynamic" ? [this.newPayout(0)] : null
      };

      this.programInstanceDidChange = true;
    },

    cloneInstance() {
      this.selectedProgramInstance = {
        ...this.selectedProgramInstance,
        id: "new",
        start_date: this.getDefaultNewStartDate(),
        end_date: null,
        formattedDateRange: "(clone)",
      };

      this.programInstanceDidChange = true;
    },

    getDefaultNewStartDate() {
      let now = new Date();
      let newStartDate;

      //wrap month if December
      if (now.getMonth() === 11) {
        newStartDate = new Date(now.getFullYear() + 1, 0, 1);
      } else {
        newStartDate = new Date(now.getFullYear(), now.getMonth() + 1, 1);
      }

      const year = newStartDate.getFullYear();
      const month = ("0" + (newStartDate.getMonth() + 1)).slice(-2);
      const day = ("0" + newStartDate.getDate()).slice(-2);

      return `${year}-${month}-${day}`;
    },

    saveProgramInstance() {
      this.savingProgram = true;

      let params = {
        start_date: this.selectedProgramInstance.start_date,
        end_date: this.selectedProgramInstance.end_date,
        eligibilities: this.selectedProgramInstance.eligibilities,
      };

      if (this.selectedProgram.pricing == 'dynamic') {
        params.payouts = this.selectedProgramInstance.payouts;
      }

      // an id of "new" will reach a different api call for creating a new instance
      if (this.selectedProgramInstance.id === "new") {
        params.program_id = this.selectedProgram.id;
      }

      http
        .post(
          "retail-program-instance/" + this.selectedProgramInstance.id,
          params
        )
        .then((res) => {
          this.selectedProgramInstanceOriginal = JSON.parse(
            JSON.stringify(this.selectedProgramInstance)
          );
          this.programInstanceDidChange = false;

          if (res.data.data.new_instance) {
            this.selectedProgramInstance.id = res.data.data.new_instance.id;
          }

          if (res.data.message) {
            this.$toasted.success(res.data.message);
          }

          this.getProgramInstances();
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        })
        .finally(() => {
          this.savingProgram = false;
        });
    },
    recalculateBatchesForInstance() {
      if (this.instanceButtonsDisabled) return;
      if (!this.selectedProgramInstance) return;

      this.recalculating = true;
      http.post(`retail-program-instance/${this.selectedProgramInstance.id}/recalculate`)
        .then((res) => {
            if (res.data.message) {
              this.$toasted.success(res.data.message);
            }
          })
          .catch((err) => {
            this.$toasted.error(err.response.data.message || "Server Error");
          })
          .finally(() => {
            this.recalculating = false;
          });
    },
    deleteInstance() {
      this.deletingInstance = true;
      http
        .post(
          "retail-program-instance/" +
            this.selectedProgramInstance.id +
            "/delete"
        )
        .then((res) => {
          this.programInstanceDidChange = false;
          this.selectedProgramInstance = {
            formattedDateRange: "",
          };
          this.getProgramInstances();

          if (res.data.message) {
            this.$toasted.success(res.data.message);
          }
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        })
        .finally(() => {
          this.showConfirmModal.deleteInstance = false;
          this.deletingInstance = false;
        });
    },
    discardProgramInstanceChanges() {
      this.selectedProgramInstance = JSON.parse(
        JSON.stringify(this.selectedProgramInstanceOriginal)
      );
      this.showConfirmModal.discardChanges = false;
      this.programInstanceDidChange = false;
    },
    updateSelectedInstance() {
      if (!this.selectedProgramInstance.id) return;

      const updatedInstance = this.programInstances.find(
        (i) => i.id === this.selectedProgramInstance.id
      );

      if (updatedInstance) this.selectProgramInstance(updatedInstance);
    },
  },
};
</script>

<style lang="scss" scoped>
table,
tr,
td,
th {
  border: none !important;
}

table {
  $table-pad: 8px;
  margin: 0 -#{$table-pad};
  width: calc(100% + #{2 * $table-pad});

  td,
  th {
    &:first-child {
      padding-left: #{$table-pad};
    }

    &:last-child {
      padding-right: #{$table-pad};
    }
  }

  .col1 {
  }

  .col-registered {
    display: block;
    width: 145px;
  }
  

  .col2 {
    width: 100px;
  }

  .col3 {
    width: #{50px + $table-pad};
  }
}

button[disabled] {
  opacity: 0.3;
  cursor: not-allowed;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  padding: 2px 8px;
  background: #f6f6f6;
  border: 1px solid #dedede;
  border-radius: 0.25em;
  -moz-appearance: textfield;
}

.effective-dates {
  padding: 4px;
  margin-bottom: 15px;
  width: max-content;

  input {
    @media (max-width: 500px) {
      max-width: calc(50% - 10px);
      font-size: 14px;
      padding: 6px;
    }
  }
}
</style>
