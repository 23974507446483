<template>
  <div
    class="form-group w-full"
    v-click-outside="dropdownHide"
    @click="dropdownOpen"
    v-bind:class="{ 'text-only': textOnly }"
  >
    <label v-if="label" class="control-label">{{ label }}</label>
    <div class="relative">
      <template v-if="search">
        <ValidationProvider
          :rules="validationRules"
          tag="div"
          v-slot="{ classes }"
        >
          <input
            :readonly="disabled"
            v-model="searchValue"
            @input="searchItem"
            @focusout="$emit('focusout', $event)"
            autocomplete="off"
            :name="inputName"
            :placeholder="placeholder"
            class="input pl-3"
            v-bind:class="{
              'opacity-75 cursor-default': disabled,
              'cursor-text': !disabled,
              ...classes
            }"
            :disabled="disabled"
          />
        </ValidationProvider>
      </template>
      <template v-else>
        <ValidationProvider
          :rules="validationRules"
          tag="div"
          v-slot="{ classes }"
        >
          <input
            readonly
            :value="editValue"
            autocomplete="off"
            :name="inputName"
            :placeholder="placeholder"
            class="input pl-3"
            v-bind:class="{
              'opacity-75 cursor-default': disabled,
              'cursor-pointer': !disabled,
              ...classes
            }"
            :disabled="disabled"
          />
        </ValidationProvider>
      </template>

      <inline-svg
        class="m-auto down"
        :src="require('@/assets/image/icons/chevron-down.svg')"
        v-if="!disabled"
      />
      <div
        class="dropdown"
        v-if="openDropdown"
        :class="{ 'overflow-y-auto': !status }"
      >
        <template v-if="searchAnswer && searchAnswer.length">
          <button
            v-if="all"
            @click.stop="selectDropdown({ [fieldName]: 'All' })"
          >
            All
          </button>
          <button
            v-if="empty"
            @click.stop="selectDropdown({ [fieldName]: '' })"
          >
            {{ emptyLabel }}
          </button>
          <template v-if="status">
            <button
              style="display: flex"
              v-for="(item, i) in searchAnswer"
              v-bind:key="'status_item_' + i"
              @click.stop="selectDropdown(item)"
            >
              <span :style="'color:' + getDotColor(item.id)">&#8226;</span
              >{{ item[fieldName] }}
            </button>
          </template>
          <template v-else>
            <button
              v-for="(item, i) in searchAnswer"
              v-bind:key="'item_' + i"
              @click.stop="selectDropdown(item)"
            >
              {{ item[fieldName] }}
            </button>
          </template>
        </template>
        <p class="no-result" v-else>No Result</p>
      </div>
    </div>
  </div>
</template>
<script>
import ClickOutside from "vue-click-outside";

export default {
  name: "Dropdown",
  data() {
    return {
      openDropdown: false,
      value: "",
      searchValue: "",
      searchAnswer: [],
      selectDropdownAnswer: [],
    };
  },
  props: {
    data: {
      type: Array,
      required: false,
    },
    status: {
      type: Boolean,
      required: false,
      default: false,
    },
    search: {
      type: Boolean,
      required: false,
      default: false,
    },
    all: {
      type: Boolean,
      required: false,
      default: true,
    },
    empty: {
      type: Boolean,
      required: false,
      default: false,
    },
    emptyLabel: {
      type: String,
      required: false,
      default: "Select",
    },
    fieldName: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    editValue: {
      type: String,
      required: false,
    },
    validation: {
      type: Boolean,
      required: false,
    },
    inputName: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    textOnly: {
      type: Boolean,
      required: false,
    },
    noSelectOnClose: {
      type: Boolean,
      required: false,
      default: false,
    },
    statusColors: {
      type: Object,
      required: false,
      default() {
        return {
          0: "#B1D69F", //default
          1: "#B1D69F",
          2: "#EA9797",
          3: "#EBDBB0",
          4: "#606060",
          5: "#A7CBE5",
          6: "#561b60",
          7: "#ebce24",
          8: "#084B59",
        };
      },
    },
  },
  computed: {
    validationRules: function () {
      let rules = [];
      if (this.validation) rules.push("required");
      return rules.join(" ");
    },
  },
  mounted() {
    this.searchAnswer = this.data;
    this.searchValue = this.editValue;
  },
  watch: {
    editValue: function (newValue) {
      this.searchValue = newValue;
    },
  },
  methods: {
    dropdownHide(select = true) {
      if (this.openDropdown && this.search && !this.noSelectOnClose && select) {
        const firstMatch = this.data?.find(
          (item) => item[this.fieldName] === this.searchValue
        );
        this.$emit("select", firstMatch ?? "noItem");
      }
      this.openDropdown = false;
    },
    selectDropdown(item) {
      this.searchValue = this.value = item[this.fieldName];
      this.$emit("select", item);
      this.dropdownHide(false);
    },
    dropdownOpen() {
      if (this.disabled) return;
      this.openDropdown = true;
      this.searchAnswer = this.data;
    },
    getDotColor(status) {
      return this.statusColors[status] ?? this.statusColors[0] ?? "606060";
    },
    searchItem() {
      if (this.search) {
        if (this.searchValue) {
          this.openDropdown = true;
          this.searchAnswer = this.data.filter((item) =>
            item[this.fieldName]
              .toLowerCase()
              .includes(this.searchValue.toLowerCase())
          );
          if (!this.searchAnswer.length) {
            this.$emit("noResultFound");
          }
        } else {
          this.openDropdown = false;
          this.searchAnswer = this.data;
        }
      }
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style scoped>
span {
  font-size: 40px;
  align-items: baseline;
  padding-right: 5px;
  margin-left: -3px;
  line-height: 30px;
}
</style>

