var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"claim view-original col-span-12"},[_c('div',{staticClass:"container"},[_c('PageHeader',{attrs:{"backText":"Back to Old Warranty Claims","backRoute":{
        name: 'ManageOldWarrantyClaims',
        query: Object.assign({}, _vm.oldClaimIndexFilters),
      },"saveButton":false,"discardLink":false,"details":{
        title: '#' + _vm.oldClaimItem.id,
        status: _vm.oldClaimItem.status,
        date: _vm.oldClaimItem.claim_date,
        fields: {
          'Tractor S/N': _vm.oldClaimItem.tractor_sn,
        },
      }}}),_c('div',{staticClass:"title py-5 flex justify-between"}),_c('div',[(_vm.oldClaimItem)?_c('div',{staticClass:"table-claim"},[_c('table',[_c('tbody',[_c('tr',[_c('th',[_vm._v("Owner Name:")]),_c('td',[_vm._v(_vm._s(_vm.oldClaimItem.owner_name))]),_c('th',[_vm._v("Dealership:")]),_c('td',[_vm._v(_vm._s(_vm.oldClaimItem.dealership))])]),_c('tr',[_c('th',[_vm._v("Tractor S/N:")]),_c('td',[_vm._v(_vm._s(_vm.oldClaimItem.tractor_sn))]),_c('th',[_vm._v("Date Submitted:")]),_c('td',[_vm._v(_vm._s(_vm.oldClaimItem.claim_date))])]),_c('tr',[_c('th',[_vm._v("Operating Hours:")]),_c('td',[_vm._v(_vm._s(_vm.oldClaimItem.hours))]),_c('th',[_vm._v("Claim Status:")]),_c('td',[_c('div',{style:('color:' +
                    _vm.color +
                    ';background-color:' +
                    _vm.background +
                    ';border:' +
                    _vm.border)},[_vm._v(" "+_vm._s(_vm.oldClaimItem.status)+" ")])])])])])]):_vm._e()]),_c('div',{staticClass:"\n        table-block\n        col-span-1\n        sm:col-span-1\n        md:col-span-1\n        lg:col-span-1\n        xl:col-span-1\n      "},[_c('data-table',{staticClass:"media-table",attrs:{"data":_vm.oldClaimItem.actions,"header":_vm.actionsHeader.header,"isLoading":_vm.isLoading}})],1),_c('div',{staticClass:"\n        table-block\n        col-span-1\n        sm:col-span-1\n        md:col-span-1\n        lg:col-span-1\n        xl:col-span-1\n      "},[_c('data-table',{staticClass:"media-table",attrs:{"data":_vm.oldClaimItem.parts,"header":_vm.partsHeader.header,"isLoading":_vm.isLoading}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }