<template>
  <section class="claim col-span-12">
    <div class="container">
      <PageHeader
        backText="Back to Claims"
        :backRoute="{ name: 'Claims', query: { ...claimFilters } }"
        :saveButton="false"
        :discardLink="false"
        :details="{
          title: '#' + claimDetails.id,
          status: claimStatus.name,
          date: claimDetails.create_date,
          fields: {
            'Primary S/N': tractorDetails.primary_sn,
          },
        }"
      >
        <template v-slot:actions>
          <button
            type="button"
            @click="viewOriginal"
            class="btn btn-info px-40"
            v-if="claimDetails.has_original"
          >
            View Original
          </button>
          <button
            v-if="user && userCanEdit"
            type="button"
            @click="editClaim"
            class="btn btn-info px-40"
          >
            Edit Claim
          </button>
          <button class="btn btn-info px-40" @click="printClaim" type="button">
            Print
          </button>
        </template>
      </PageHeader>
      <div class="details-block">
        <div class="flex-1">
          <div class="subtitle">Tractor Details</div>
          <div class="c-callout">
            <div class="details-mr details-inform">
              <div>
                <div>Customer Name</div>
                <span>{{ tractorDetails.owner_name }}</span>
              </div>
              <div>
                <div>Address</div>
                <span>{{ tractorDetails.owner_address }}</span>
              </div>
              <div>
                <div>Phone Number</div>
                <span>{{ tractorDetails.owner_phone }}</span>
              </div>
              <div>
                <div>Retail Dealership</div>
                <span>{{ tractorDetails.dealership && tractorDetails.dealership.name }}</span>
              </div>
            </div>
            <div class="details-inform">
              <div v-if="tractorDetails.model_name">
                <div>Tractor Model</div>
                <span>{{ tractorDetails.model_name }}</span>
              </div>
              <div v-if="tractorDetails.tractor_sn">
                <div>Tractor S/N</div>
                <span>{{ tractorDetails.tractor_sn }}</span>
              </div>
              <div v-if="tractorDetails.loader_sn">
                <div>Loader S/N</div>
                <span>{{ tractorDetails.loader_sn }}</span>
              </div>
              <div v-if="tractorDetails.backhoe_sn">
                <div>Backhoe S/N</div>
                <span>{{ tractorDetails.backhoe_sn }}</span>
              </div>
              <div v-if="tractorDetails.mower_sn">
                <div>Mid-Mount Mower S/N</div>
                <span>{{ tractorDetails.mower_sn }}</span>
              </div>
              <div>
                <div>Date of Sale</div>
                <span>{{ tractorDetails.sale_date }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-1">
          <div class="subtitle">Claim Details</div>
          <div class="c-callout">
            <div class="details-mr details-inform">
              <div>
                <div>Dealer Placing Claim</div>
                <span v-if="claimDetails.delaer">{{
                  claimDetails.delaer.name
                }}</span>
              </div>
              <div>
                <div>Cause Code</div>
                <span>{{ claimDetails.cause_code }}</span>
              </div>
              <div>
                <div>Failure Date</div>
                <span>{{ claimDetails.failure_date }}</span>
              </div>
              <div>
                <div>Repair Date</div>
                <span>{{ claimDetails.repair_date }}</span>
              </div>
            </div>
            <div class="details-inform">
              <div>
                <div>Hours Used</div>
                <span>{{ claimDetails.hours_used }}</span>
              </div>
              <div>
                <div>Warranty Administrators Name</div>
                <span>{{ claimDetails.administrator_name }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="table-block">
        <div class="subtitle">Labor & Part Details</div>
        <div class="tab" v-if="claimLabors.labors">
          <div class="tables">
            <div class="table">
              <div class="data-table-head">
                <div class="table-head-container">
                  <div class="tr">
                    <div class="th text-center">Labor Code</div>
                    <div class="th text-center">Labor Description</div>
                    <div class="th text-center">Labor Time</div>
                    <div class="th text-center">Labor Cost</div>
                    <div class="th text-center">Labor Total</div>
                    <div class="th text-center">Invoice #</div>
                    <div class="th text-center">Part #</div>
                    <div class="th text-center">Part Description</div>
                    <div class="th text-center">Per Part Cost</div>
                    <div class="th text-center">Quantity</div>
                    <div class="th text-center">Parts Total</div>
                  </div>
                </div>
              </div>
              <div class="tbody">
                <div
                  v-if="claimLabors.labors.length == 0"
                  class="text-center no-result"
                >
                  <span
                    >No results found. Please adjust your search criteria.</span
                  >
                </div>
                <div
                  class="tr"
                  v-for="(labor, i) in claimLabors.labors"
                  :class="labor.parts.length > 1 ? 'i-start' : ''"
                  :key="i"
                >
                  <div class="td">
                    {{ labor.value }}
                  </div>
                  <div class="td">
                    {{ labor.description }}
                  </div>
                  <div class="td">
                    {{ labor.hours }}
                  </div>
                  <div class="td">${{ labor.cost }}</div>
                  <div class="td">${{ labor.total }}</div>
                  <div class="td-part">
                    <div class="part" v-for="part in labor.parts">
                      <div class="td">
                        {{ part.invoice }}
                      </div>
                      <div class="td">
                        {{ part.part }}
                      </div>
                      <div class="td">
                        {{ part.partDescription }}
                      </div>
                      <div class="td">${{ part.partPrice }}</div>
                      <div class="td">
                        {{ part.qty.toFixed(2) }}
                      </div>
                      <div class="td">${{ part.total }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="claim-totals">
          <div class="totals">
            <div>
              <div class="flex justify-between pt-3 pl-4 pr-4">
                <div>Labor Total</div>
                <div>${{ claimLabors.labor_subtotal }}</div>
              </div>
              <div class="flex justify-between pt-3 pl-4 pr-4">
                <div>Parts Total</div>
                <div>${{ claimLabors.parts_subtotal }}</div>
              </div>
            </div>
            <div class="total">
              <div class="flex justify-between pt-3 pl-4 pr-4">
                <div>Total</div>
                <div>${{ claimLabors.total }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="description-block"
        :class="
          claim && claim.activity_history && claim.activity_history.length === 0
            ? 'mb-20'
            : ''
        "
      >
        <div>
          <div class="description">
            <div class="subtitle">Description of Failure</div>
            <div class="text" v-if="claim.description_of_failre">
              {{ claim.description_of_failre }}
            </div>
            <div class="text" v-else>No Description of Failure</div>
          </div>
          <div class="description">
            <div class="subtitle">Description of Repair</div>
            <div class="text" v-if="claim.description_of_repair">
              {{ claim.description_of_repair }}
            </div>
            <div class="text" v-else>No Description of Repair</div>
          </div>
          <div class="description">
            <div class="subtitle">Admin Notes</div>
            <div class="text" v-if="claim.admin_notes">
              {{ claim.admin_notes }}
            </div>
            <div class="text" v-else>No Notes</div>
          </div>
        </div>
        <div class="export-group">
          <div class="subtitle">File Attachments</div>
          <div class="button-group d-block" v-if="claimFiles.length">
            <button
              class="button"
              v-for="file in claimFiles"
              :disabled="disableFileDownload.includes(file.id)"
              @click="downloadClaimFile(file)"
            >
              <div class="flex">
                <div class="file-name">
                  {{
                    file.slug
                      .replace(/\s+/g, "_")
                      .match(/.*(?=\.[^.]*$)/)[0]
                      .substr(0, 20)
                  }}
                </div>
                .{{ file.slug.match(/[^\.]+$/)[0] }}
              </div>
              <inline-svg
                v-if="!disableFileDownload.includes(file.id)"
                :src="require('@/assets/image/icons/download.svg')"
              />
              <loading
                class="loading-dovnload"
                v-if="disableFileDownload.includes(file.id)"
              />
            </button>
          </div>
          <div v-else>No File</div>
        </div>
      </div>
      <div
        class="activity-history"
        v-if="
          claim && claim.activity_history && claim.activity_history.length !== 0
        "
      >
        <div class="subtitle">Claim Activity History</div>
        <div class="info-block">
          <div class="info" v-for="history in claim.activity_history">
            <inline-svg :src="require('@/assets/image/icons/history.svg')" />
            <p v-if="history.created_by">{{ history.created_by.email }}</p>
            &#160; {{ history.description }} on {{ history.created_at }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../component/PageHeader";
import DataTable from "../../component/DataTable";
import Loading from "../../component/Loading";
import { mapState } from "vuex";
import { http } from "../../../api";

export default {
  name: "claimDetail",
  data() {
    return {
      background: "",
      disableFileDownload: [],
      color: "",
      border: "",
      formatFile: "",
      tractorDetails: {},
      claimDetails: {},
      claimStatus: {},
      claimLabors: [],
      claimFiles: [],
      claim: {},
      claimAccess: {},
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      claimFilters: (state) => state.claim.indexFilters,
    }),
    userCanEdit: function () {
      return this.claimAccess.edit;
    },
  },
  mounted() {
    this.getClaim();
  },
  components: {
    PageHeader,
    DataTable,
    Loading,
  },
  methods: {
    getClaim() {
      http.get("claims/" + this.$route.params.id).then((res) => {
        const data = res.data.data;
        console.log(data);
        this.tractorDetails = data.tractor_details;
        this.claimDetails = data.claim_details;
        this.claimFiles = data.files;
        this.claimStatus = data.status;
        this.claim = data;
        this.claimLabors = data.labor_parts_description;
        this.claimAccess = data.access;
        this.transformStatus();
      });
    },
    transformStatus() {
      switch (this.claimStatus.id) {
        case 6:
          this.background = "rgba(205, 216, 29, 0.2)";
          this.color = "#7c8110";
          this.border = "none";
          break;
        case 5:
          this.background = "rgba(29, 184, 216, 0.2)";
          this.color = "#0B4067";
          this.border = "none";
          break;
        case 4:
          this.background = "#F9F9F9";
          this.color = "#606060";
          this.border = "1px solid #606060";
          break;
        case 3:
          this.background = "rgba(215, 173, 65, 0.4)";
          this.color = "#5C4E00";
          this.border = "none";
          break;
        case 2:
          this.background = "rgba(212, 4, 4, 0.2)";
          this.color = "#7D0505";
          this.border = "none";
          break;
        case 1:
          this.background = "rgba(99, 220, 43, 0.3)";
          this.color = "#24610E";
          this.border = "none";
          break;
      }
    },
    transformToCost(id, val) {
      return "$" + val;
    },
    viewOriginal() {
      this.$router.push("/claims/" + this.$route.params.id + "/original");
    },
    downloadClaimFile(file) {
      this.disableFileDownload.push(file.id);
      let url = file.file;
      let name = file.slug;
      http
        .get(url, {
          responseType: "blob",
        })
        .then((res) => {
          let blob = new Blob([res.data], {
            type: res.headers["content-type"],
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = name;
          link.click();
        })
        .catch((err) => {
          this.$toasted.error("Download failed");
        })
        .finally(() => {
          this.disableFileDownload = this.disableFileDownload.filter(
            (id) => id !== file.id
          );
        });
    },
    printClaim() {
      http
        .get("claims/" + this.$route.params.id + "/pdf", {
          responseType: "blob",
        })
        .then((res) => {
          let blob = new Blob([res.data], { type: "application/pdf" });
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL);
        });
    },
    editClaim() {
      if (this.claimStatus.id == 4) {
        this.$router.push("/claims/" + this.claimDetails.id + "/continue");
      } else {
        this.$router.push("/claims/" + this.claimDetails.id + "/edit");
      }
    },
  },
};
</script>
