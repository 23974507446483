import { http } from '../../../api';

const tractor = {
    namespaced: true,
    state: {
        tractors: [],
        pagination: {},
        initialPage: 1,
        tractor_item: {},
    },
    actions: {
        getTractors: ({ commit }) => {
            return http.get('tractors').then(res => {
                commit('setTractors', res.data.data.tractors)
                commit('setTractors', res.data.data.tractors)
            });
        },
        filterTractors: ({ commit }, params) => {
            const q = new URLSearchParams(params);
            return http.get('tractors/filter?' + q.toString()).then(res => {
                commit('setTractors', res.data.data.tractors)
            });
        },
        removeTractor: ({ commit }, params) => {
            return http.delete('/tractors/' + params.id).then(res => {
                commit('deleteTractors', params.id)
                return res;
            });
        },
        getTractorItem: ({ commit }, params) => {
            return http.get('/tractors/' + params.id).then(res => {
                commit('setTractorItem', res.data.data);
                return res.data.data;
            });
        },
        getTractorEdit: ({ commit }, params) => {
            return http.get('/tractors/' + params.id + '/edit').then(res => {
                return res.data.data.tractor;
            });
        },
        createTractor: ({ commit }, params) => {
            return http.post('/tractors', params).then(res => {
                return res;
            });
        },
        updateTractor: ({ commit }, params) => {
            return http.post('/tractors/' + params.id, params.formData).then(res => {
                return res;
            });
        },
        getInitialPage: ({ commit }, params) => {
            commit('setInitialPage', params)
        },
    },
    mutations: {
        setInitialPage(state, page) {
            state.initialPage = page
        },
        deleteTractors(state, id) {
            state.tractors = state.tractors.filter(item => id !== item.id)
        },
        setTractors(state, data) {
            state.tractors = data.data;
            state.pagination = data.pagination;
        },
        setTractorItem(state, data){
            state.tractor_item = data.tractor;
        },
    },
    getters: {
        tractors: state =>  state.tractors,
        pagination: state =>  state.pagination,
        tractor_item: state =>  state.tractor_item,
        initialPage: state =>  state.initialPage
    }
};

export default tractor;
