<template>
  <section class="claim view-original col-span-12">
    <div class="container">
      <PageHeader
        backText="Back to Old Warranty Claims"
        :backRoute="{
          name: 'ManageOldWarrantyClaims',
          query: { ...oldClaimIndexFilters },
        }"
        :saveButton="false"
        :discardLink="false"
        :details="{
          title: '#' + oldClaimItem.id,
          status: oldClaimItem.status,
          date: oldClaimItem.claim_date,
          fields: {
            'Tractor S/N': oldClaimItem.tractor_sn,
          },
        }"
      >
      </PageHeader>
      <div class="title py-5 flex justify-between"></div>
      <div>
        <div class="table-claim" v-if="oldClaimItem">
          <table>
            <tbody>
              <tr>
                <th>Owner Name:</th>
                <td>{{ oldClaimItem.owner_name }}</td>
                <th>Dealership:</th>
                <td>{{ oldClaimItem.dealership }}</td>
              </tr>
              <tr>
                <th>Tractor S/N:</th>
                <td>{{ oldClaimItem.tractor_sn }}</td>
                <th>Date Submitted:</th>
                <td>{{ oldClaimItem.claim_date }}</td>
              </tr>
              <tr>
                <th>Operating Hours:</th>
                <td>{{ oldClaimItem.hours }}</td>
                <th>Claim Status:</th>
                <td>
                  <div
                    :style="
                      'color:' +
                      color +
                      ';background-color:' +
                      background +
                      ';border:' +
                      border
                    "
                  >
                    {{ oldClaimItem.status }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        class="
          table-block
          col-span-1
          sm:col-span-1
          md:col-span-1
          lg:col-span-1
          xl:col-span-1
        "
      >
        <data-table
          :data="oldClaimItem.actions"
          :header="actionsHeader.header"
          class="media-table"
          :isLoading="isLoading"
        />
      </div>
      <div
        class="
          table-block
          col-span-1
          sm:col-span-1
          md:col-span-1
          lg:col-span-1
          xl:col-span-1
        "
      >
        <data-table
          :data="oldClaimItem.parts"
          :header="partsHeader.header"
          class="media-table"
          :isLoading="isLoading"
        />
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../../component/PageHeader";
import { mapActions, mapState } from "vuex";
import DataTable from "../../../component/DataTable";
import Loading from "../../../component/Loading";

export default {
  name: "oldClaimDetail",
  data() {
    return {
      isLoading: false,
      background: "",
      color: "",
      border: "",
      actionsHeader: {
        header: [
          {
            field: "description",
            name: "Failure Description",
            headerWidth: "29%",
            width: "30%",
          },
          { field: "code", name: "Code" },
          { field: "hours", name: "Hours" },
        ],
      },
      partsHeader: {
        header: [
          { field: "ref_no", name: "Inv No", headerWidth: "9%", width: "10%" },
          { field: "no", name: "Part No" },
          { field: "description", name: "Desc" },
          { field: "qty", name: "QTY" },
          { field: "uprice", name: "U/Price" },
          { field: "amount", name: "Amount (US$)" },
          { field: "adjusted", name: "ADJUSTED" },
        ],
      },
    };
  },
  components: {
    PageHeader,
    DataTable,
    Loading,
  },
  computed: {
    ...mapState({
      oldClaimItem: (state) => state.claim.oldClaimItem,
      oldClaimIndexFilters: (state) => state.claim.oldClaimIndexFilters,
    }),
  },
  mounted() {
    this.getOldClaim();
  },
  methods: {
    ...mapActions({
      getOldClaimItem: "claim/getOldClaimItem",
    }),
    getOldClaim() {
      this.isLoading = true;
      this.getOldClaimItem({ id: this.$route.params.id })
        .then((res) => {
          this.transformStatus();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    transformStatus() {
      switch (this.oldClaimItem.status) {
        case "Approved":
          this.background = "rgba(205, 216, 29, 0.2)";
          this.color = "#7c8110";
          this.border = "none";
          break;
        case "Pending":
          this.background = "rgba(215, 173, 65, 0.4)";
          this.color = "#5C4E00";
          this.border = "none";
          break;
        case "Cancelled":
          this.background = "rgba(212, 4, 4, 0.2)";
          this.color = "#7D0505";
          this.border = "none";
          break;
      }
    },
  },
};
</script>
