<template>
  <section class="add-user col-span-12">
    <ValidationObserver tag="div" class="container" ref="form">
      <PageHeader
        :title="isEdit ? 'Edit Video' : 'Add Video'"
        backText="Back to Mange Videos"
        :backRoute="{ name: 'ManageVideos' }"
        saveText="Save Video"
        @save="addVideo"
        @discard="showDiscardModal = true"
        :buttonsDisabled="buttonDisabled"
      />
      <div class="user-details">
        <div class="subtitle">Enter Video Details</div>
        <div class="user-block">
          <div class="user-block-container">
            <div class="form-group w-full">
              <label class="control-label">Title</label>
              <ValidationProvider
                rules="required"
                tag="div"
                v-slot="{ classes }"
              >
                <input
                  type="text"
                  name="title"
                  v-model="formData.title"
                  placeholder="Media Detail Title Here"
                  :class="classes"
                />
              </ValidationProvider>
            </div>
            <div class="form-group w-full">
              <label class="control-label">Description</label>
              <ValidationProvider
                rules="required"
                tag="div"
                v-slot="{ classes }"
              >
                <input
                  type="text"
                  name="description"
                  v-model="formData.description"
                  placeholder="Description Here"
                  :class="classes"
                />
              </ValidationProvider>
            </div>
            <div class="form-group w-full">
              <label class="control-label">YouTube URL</label>
              <ValidationProvider
                rules="required|positive"
                tag="div"
                v-slot="{ classes }"
              >
                <input
                  type="text"
                  name="url"
                  v-model="formData.youtube_url"
                  placeholder="https://www.youtube.com/TYM"
                  :class="classes"
                />
              </ValidationProvider>
            </div>
          </div>
        </div>
        <div class="send">
          <div class="edit-link" @click="showDiscardModal = true">Discard</div>
          <button
            class="btn btn-black px-60"
            type="button"
            @click="addVideo"
            :disabled="buttonDisabled"
            :class="buttonDisabled ? 'opacity-50 cursor-wait' : ''"
          >
            Save Video
          </button>
        </div>
      </div>
      <ConfirmModal
        text="Discard changes?"
        confirmBtn="Discard"
        :show="showDiscardModal"
        @confirm="$router.push('/admin/videos')"
        @close="showDiscardModal = false"
      />
    </ValidationObserver>
  </section>
</template>

<script>
import PageHeader from "../../../component/PageHeader";
import Dropdown from "../../../component/Dropdown";
import ConfirmModal from "../../../component/ConfirmModal";
import { mapActions, mapState } from "vuex";

export default {
  name: "addMedia",
  data() {
    return {
      buttonDisabled: false,
      showDiscardModal: false,
      formData: {
        title: "",
      },
    };
  },
  computed: {
    ...mapState({
      updateVideoData: (state) => state.manageVideos.updateVideoData,
    }),
  },
  created() {
    if (this.$route.params.id === "add") {
      this.isEdit = false;
    } else {
      this.isEdit = true;
      this.editVideo();
    }
  },
  components: {
    PageHeader,
    Dropdown,
    ConfirmModal,
  },
  methods: {
    ...mapActions({
      editVideoData: "manageVideos/editVideoData",
      createVideo: "manageVideos/createVideo",
      updateVideo: "manageVideos/updateVideo",
    }),
    editVideo() {
      this.editVideoData({ id: this.$route.params.id }).then((res) => {
        this.formData = this.updateVideoData.video;
      });
    },
    addVideo() {
      this.$refs.form
        .validate()
        .then((success) => {
          if (success) {
            let data = new FormData();
            for (var formData in this.formData) {
              data.append(formData, this.formData[formData]);
            }

            this.buttonDisabled = true;

            if (this.isEdit) {
              this.updateVideo({ id: this.$route.params.id, formData: data })
                .then((res) => {
                  this.$router.push("/admin/videos");
                })
                .catch((err) => {
                  this.buttonDisabled = false;
                  this.$toasted.error(
                    err.response.data.message || "Server Error"
                  );
                });
            } else {
              this.createVideo(data)
                .then((res) => {
                  this.$router.push("/admin/videos");
                })
                .catch((err) => {
                  this.buttonDisabled = false;
                  this.$toasted.error(
                    err.response.data.message || "Server Error"
                  );
                });
            }
          } else {
            for (let item in this.$refs.form.errors) {
              if (this.$refs.form.errors[item].length > 0) {
                this.$toasted.error(this.$refs.form.errors[item]);
              }
            }
          }
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    },
  },
};
</script>

<style scoped>
</style>
