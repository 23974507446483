<template>
  <div class="dropdown-settings relative" v-click-outside="hide">
    <button
      aria-label="actions-menu"
      type="button"
      class="button btn-td-hover"
      :class="openClose ? 'active' : ''"
      @click="openClose = !openClose"
    >
      <inline-svg
        class="m-auto"
        :src="require('@/assets/image/icons/more-alt.svg')"
      />
    </button>
    <div class="dropdown" v-if="openClose">
      <div @click="printWarrantyRegistration">Print</div>
      <div
        @click="approveRegistration"
        v-if="warranty.item.status.id === 3 && user && userCanApprove"
      >
        Approve
      </div>
      <div @click="editWarrantyRegistration" v-if="user && userCanEdit">
        Edit
      </div>
      <div
        class="delete"
        @click="
          hide();
          showDeleteRegistrationModal = true;
        "
        v-if="user && userCanDelete"
      >
        Delete
      </div>
    </div>
    <ConfirmModal
      :text="`Are you sure you want to delete the registration for Tractor ${warranty.item.tractor_sn}?`"
      confirmBtn="Delete"
      :show="showDeleteRegistrationModal"
      @confirm="deleteWarrantyRegistrations"
      @close="showDeleteRegistrationModal = false"
    />
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import { mapActions, mapState } from "vuex";
import { http } from "../../../api";
import ConfirmModal from "../ConfirmModal";

export default {
  name: "RegistrationsTableDropdown",
  props: ["warranty"],
  components: {
    ConfirmModal,
  },
  data() {
    return {
      openClose: false,
      showDeleteRegistrationModal: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    userCanApprove: function () {
      return (
        this.user.permissions.includes("GLOBAL") ||
        this.user.permissions.includes("REG_APPR")
      );
    },
    userCanEdit: function () {
      return (
        this.user.permissions.includes("GLOBAL") ||
        this.user.permissions.includes("REG_EDIT")
      );
    },
    userCanDelete: function () {
      return (
        this.user.permissions.includes("GLOBAL") ||
        this.user.permissions.includes("REG_DEST")
      );
    },
  },
  methods: {
    ...mapActions({
      deleteWarranty: "registration/deleteWarranty",
      getRegistrations: "registration/getRegistrations",
    }),
    hide() {
      this.openClose = false;
    },
    deleteWarrantyRegistrations() {
      this.deleteWarranty({ id: this.warranty.item.id })
        .then((res) => {
          this.getRegistrations({ page: this.warranty.page });
          this.$toasted.success(res.data.message);
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        })
        .finally(() => {
          this.showDeleteRegistrationModal = false;
          this.openClose = false;
        });
    },
    approveRegistration() {
      http
        .get("registrations/" + this.warranty.item.id + "/approve")
        .then((res) => {
          this.getRegistrations({ page: this.warranty.page });
          this.$toasted.success(res.data.message);
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    },
    printWarrantyRegistration() {
      http
        .get("registrations/" + this.warranty.item.id + "/pdf", {
          responseType: "blob",
        })
        .then((res) => {
          let blob = new Blob([res.data], { type: "application/pdf" });
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL);
        });
    },
    editWarrantyRegistration() {
      if (this.warranty.item.access.edit) {
        this.$router.push("/registrations/" + this.warranty.item.id + "/edit");
      } else {
        this.$toasted.error("You can only view orders that belong to you.");
      }
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>
