<template>
  <ValidationObserver
    ref="form"
    tag="section"
    class="registrations col-span-12"
  >
    <form class="container grid grid-cols-5 gap-0">
      <PageHeader
        :title="isEdit ? 'Edit Registration' : 'Create Registration'"
        :backText="
          isEdit ? 'Back to Registration Details' : 'Back to Registrations'
        "
        :backRoute="backRoute"
        @save="submitRegistrationForm(isEdit ? 'update' : 'create')"
        @discard="showDiscardModal = true"
        :buttonsDisabled="buttonDisabled"
        class="col-span-5"
      >
        <template v-slot:actions>
          <div style="margin-right: 25px" v-if="isEdit">
            <dropdown
              :data="registrationStatuses"
              :status="true"
              fieldName="status"
              @select="selectStatus"
              :validation="false"
              inputName="registration_status"
              :all="false"
              :editValue="selectedStatus"
            ></dropdown>
          </div>
        </template>
      </PageHeader>
      <div class="col-span-4">
        <div class="sale-inform">
          <div class="title">Sale Information</div>
          <div class="text">Share details on the sale of this tractor?</div>
          <div class="input-group -ml-2" id="dealership">
            <div
              v-if="user && user.role.id !== 3 && (!isEdit || dealershipName)"
              class="dealershipWidth ml-2"
            >
              <dropdown
                id="dealershipDropdown"
                :data="createRegistrations.dealership"
                :status="false"
                fieldName="name"
                label="Dealer"
                @select="dealershipSelect"
                :editValue="dealershipName"
                :validation="true"
                inputName="dealership"
                :all="false"
                :search="true"
              ></dropdown>
              <span class="text-red-500">{{ errorMessageDealership }}</span>
            </div>
            <div class="form-group flex flex-wrap sm:flex-no-wrap ml-2 -mr-4">
              <div class="form-group date-width mr-2">
                <div>
                  <label class="control-label">Date of Sale</label>
                  <ValidationProvider
                    rules="required"
                    tag="div"
                    class="relative"
                    v-slot="{ classes }"
                  >
                    <functional-calendar
                      :placeholder="' '"
                      :is-modal="true"
                      ref="Calendar"
                      :marked-dates="saleDate.selectedInCalendar"
                      :is-date-picker="true"
                      :isAutoCloseable="true"
                      :date-format="'mm/dd/yyyy'"
                      :change-year-function="true"
                      v-model="datePicker"
                      :class="classes"
                    ></functional-calendar>
                    <inline-svg
                      class="date-down"
                      :src="require('@/assets/image/icons/date-down.svg')"
                    />
                  </ValidationProvider>
                  <span class="text-red-500">{{ errorMessageDate }}</span>
                </div>
              </div>
              <div v-if="isEdit" class="form-group date-width mr-2">
                <div>
                  <label class="control-label">Expiration Date</label>
                  <ValidationProvider
                    rules="required"
                    tag="div"
                    class="relative"
                    v-slot="{ classes }"
                  >
                    <functional-calendar
                      :placeholder="' '"
                      :is-modal="true"
                      ref="ExpirationCalendar"
                      :marked-dates="expirationDate.selectedInCalendar"
                      :is-date-picker="true"
                      :isAutoCloseable="true"
                      :date-format="'mm/dd/yyyy'"
                      :change-year-function="true"
                      v-model="expirationDatePicker"
                      :class="classes"
                    ></functional-calendar>
                    <inline-svg
                      class="date-down"
                      :src="require('@/assets/image/icons/date-down.svg')"
                    />
                  </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="customer-inform" id="customer-inform">
          <div class="title">Customer Information</div>
          <div class="grid grid-cols-7 gap-0">
            <div
              class="
                form-group
                col-span-7
                sm:col-span-7
                md:col-span-3
                lg:col-span-3
                xl:col-span-2
              "
            >
              <label class="control-label">First Name</label>
              <ValidationProvider
                rules="required"
                tag="div"
                v-slot="{ classes }"
              >
                <input
                  type="text"
                  v-model="registrationsFormData.customer_fname"
                  name="first_name"
                  :class="classes"
                />
              </ValidationProvider>
            </div>
            <div
              class="
                form-group
                col-span-7
                sm:col-span-7
                md:col-span-3
                lg:col-span-3
                xl:col-span-2
              "
            >
              <label class="control-label">Last Name</label>
              <ValidationProvider
                rules="required"
                tag="div"
                v-slot="{ classes }"
              >
                <input
                  type="text"
                  v-model="registrationsFormData.customer_lname"
                  name="last_name"
                  :class="classes"
                />
              </ValidationProvider>
            </div>
            <div
              class="
                form-group
                col-span-7
                sm:col-span-7
                md:col-span-7
                lg:col-span-7
                xl:col-span-3
              "
            ></div>
            <div
              class="
                form-group
                col-span-7
                sm:col-span-7
                md:col-span-3
                lg:col-span-3
                xl:col-span-2
              "
            >
              <label class="control-label">Customer Home Phone</label>
              <input
                type="text"
                v-model="registrationsFormData.customer_phone"
                name="home_phone"
                v-mask="'(###) ###-####'"
              />
            </div>
            <div
              class="
                form-group
                col-span-7
                sm:col-span-7
                md:col-span-3
                lg:col-span-3
                xl:col-span-2
              "
            >
              <label class="control-label">Customer Mobile Phone</label>
              <input
                type="text"
                v-model="registrationsFormData.customer_mobile"
                v-mask="'(###) ###-####'"
                name="mobile_phone"
              />
            </div>
            <div
              class="
                form-group
                col-span-7
                sm:col-span-7
                md:col-span-7
                lg:col-span-7
                xl:col-span-3
              "
            >
              <label class="control-label">Customer Email Address</label>
              <input
                type="text"
                v-model="registrationsFormData.customer_email"
                name="email"
                class="xl"
              />
            </div>
            <div
              class="
                form-group
                col-span-7
                sm:col-span-7
                md:col-span-7
                lg:col-span-7
                xl:col-span-7
              "
            >
              <label class="control-label"
                >Customer Address (Street, City, State Zip)</label
              >
              <ValidationProvider
                rules="required"
                tag="div"
                v-slot="{ classes }"
              >
                <input
                  type="text"
                  v-model="registrationsFormData.customer_address"
                  name="address"
                  :class="classes"
                  class="full-width-input"
                />
              </ValidationProvider>
            </div>
            <div
              class="
                form-group
                col-span-7
                sm:col-span-7
                md:col-span-7
                lg:col-span-7
                xl:col-span-7
              "
            >
              <label class="control-label">Admin Comments</label>
              <textarea
                type="text"
                :disabled="user.role.id === 3"
                v-model="registrationsFormData.admincomments"
                name="admin_comments"
                class="textarea"
              ></textarea>
            </div>
          </div>
        </div>
        <div
          id="snTractor"
          class="registr-block"
          v-bind:class="{
            'grid grid-cols-9 gap-0': user && user.role.id !== 1,
          }"
        >
          <div
            class="product-inform product-admin"
            v-if="user && user.role.id === 1"
          >
            <div class="container-register-block">
              <div class="title-block-admin">
                <div class="title">Product Information</div>
                <div class="text">
                  Enter the Tractor and Attachments serial numbers to be
                  registered.
                </div>
              </div>
              <div>
                <div class="product-form-block">
                  <div class="form-row">
                    <div class="form-group" v-click-outside="snHide">
                      <label class="control-label">Tractor SN</label>
                      <ValidationProvider tag="div" v-slot="{ classes }">
                        <input
                          type="text"
                          v-model="selectedTractorSn"
                          autocomplete="off"
                          @input="searchTractorSnDebounced"
                          name="tractor"
                          :class="{ ...inputClass, ...classes }"
                        />
                      </ValidationProvider>
                      <inline-svg
                        v-if="inputClass === 'error'"
                        class="icon"
                        :src="require('@/assets/image/icons/error.svg')"
                      />
                      <inline-svg
                        v-if="inputClass === 'success'"
                        class="icon"
                        :src="require('@/assets/image/icons/success.svg')"
                      />
                      <div
                        class="sns-dropdown dropdown"
                        :class="inputClass"
                        v-if="snDropdown"
                      >
                        <button
                          v-for="(sn, i) in searchTractorSns"
                          v-bind:key="'tractor_sn_' + i"
                          @click="tractorSnSelect(sn)"
                        >
                          {{ sn.serial_no }}
                        </button>
                      </div>
                    </div>
                    <div>
                      <dropdown
                        :data="modelOptions.tractor"
                        :all="false"
                        fieldName="model"
                        label="Tractor Model"
                        @select="selectModel('tractor', $event.model)"
                        @focusout="selectModel('tractor', $event.srcElement.value)"
                        :search="true"
                        inputName="tractor_model"
                        :editValue="selectedModels.tractor"
                        :disabled="!!autoSelectedModels.tractor || !selectedTractorSn"
                        :noSelectOnClose="true"
                      ></dropdown>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group">
                      <label class="control-label">Loader SN</label>
                      <input
                        type="text"
                        v-model="registrationsFormData.loader_sn"
                        name="tractor"
                        @input="getLoaderModel"
                      />
                    </div>
                    <div>
                      <dropdown
                        :data="modelOptions.loader"
                        :all="false"
                        fieldName="model"
                        label="Loader Model"
                        @select="selectModel('loader', $event.model)"
                        @focusout="selectModel('loader', $event.srcElement.value)"
                        :search="true"
                        inputName="loader_model"
                        :editValue="selectedModels.loader"
                        :disabled="!!autoSelectedModels.loader || !selectedProducts.loader"
                        :noSelectOnClose="true"
                      ></dropdown>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group">
                      <label class="control-label">Backhoe SN</label>
                      <input
                        type="text"
                        v-model="registrationsFormData.backhoe_sn"
                        name="backhoe"
                        @input="getBackhoeModel"
                      />
                    </div>
                    <div>
                      <dropdown
                        :data="modelOptions.backhoe"
                        :all="false"
                        fieldName="model"
                        label="Backhoe Model"
                        @select="selectModel('backhoe', $event.model)"
                        @focusout="selectModel('backhoe', $event.srcElement.value)"
                        :search="true"
                        inputName="backhoe_model"
                        :editValue="selectedModels.backhoe"
                        :disabled="!!autoSelectedModels.backhoe || !selectedProducts.backhoe"
                        :noSelectOnClose="true"
                      ></dropdown>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group">
                      <label class="control-label">Mid Mount Mower SN</label>
                      <input
                        type="text"
                        v-model="registrationsFormData.mower_sn"
                        name="mid_mount"
                        @input="getMowerModel"
                      />
                    </div>
                    <div>
                      <dropdown
                        :data="modelOptions.mower"
                        :all="false"
                        fieldName="model"
                        label="Mower Model"
                        @select="selectModel('mower', $event.model)"
                        @focusout="selectModel('mower', $event.srcElement.value)"
                        :search="true"
                        inputName="mower_model"
                        :editValue="selectedModels.mower"
                        :disabled="!!autoSelectedModels.mower || !selectedProducts.mower"
                        :noSelectOnClose="true"
                      ></dropdown>
                    </div>
                  </div>
                  <div class="form-row">
                    <div class="form-group">
                      <label class="control-label">Cabin SN</label>
                      <input
                        type="text"
                        v-model="registrationsFormData.cabin_sn"
                        name="cabin"
                        @input="getCabinModel"
                      />
                    </div>
                    <div>
                      <dropdown
                        :data="modelOptions.cabin"
                        :all="false"
                        fieldName="model"
                        label="Cabin Model"
                        @select="selectModel('cabin', $event.model)"
                        @focusout="selectModel('cabin', $event.srcElement.value)"
                        :search="true"
                        inputName="cabin_model"
                        :editValue="selectedModels.cabin"
                        :disabled="!!autoSelectedModels.cabin || !selectedProducts.cabin"
                        :noSelectOnClose="true"
                      ></dropdown>
                    </div>
                  </div>
                </div>
              </div>
              <small class="text-red-400">{{ errorMessageSn }}</small>
            </div>
          </div>
          <div
            class="
              product-inform
              col-span-9
              sm:col-span-9
              md:col-span-9
              lg:col-span-5
              xl:col-span-4
              pb-8
              lg:pb-0
            "
            v-else
          >
            <div class="container-register-block">
              <div class="title">Product Information</div>
              <div class="text">
                <p>
                  Select the Tractor and Attachments Serial Numbers to be
                  registered.
                </p>
                <p class="mt-2">
                  *Selecting a tractor will clear all attachment selections.
                </p>
              </div>
              <div class="button-group-tractor-sn button-selsect">
                <button
                  type="button"
                  v-bind:class="{ 'active-button': selectView === 'tractor' }"
                  @click="selectView = 'tractor'"
                >
                  1. Tractor
                </button>
                <button
                  type="button"
                  :readonly="attachments === null"
                  v-bind:class="{
                    'active-button': selectView === 'attachments',
                  }"
                  @click="showAttachments"
                >
                  2. Attachments
                </button>
              </div>
              <div class="grid relative" v-if="selectView === 'tractor'">
                <div class="flex justify-center mt-10" v-if="loadingTractorSn">
                  <loading />
                </div>
                <template v-if="tractorSns">
                  <div
                    class="form-group"
                    v-bind:class="{
                      active: checkboxDropdownOpen.includes(index),
                    }"
                    v-for="(tractorSn, name, index) in tractorSns"
                    v-bind:key="'tractor_select_sn_' + index"
                  >
                    <div
                      class="dropdown-inp uppercase"
                      @click="checkboxDropdownOpenModal(index)"
                    >
                      {{ name }}<span>{{ tractorSn.length }}</span>
                    </div>
                    <inline-svg
                      class="down"
                      :src="require('@/assets/image/icons/date-down.svg')"
                    />
                    <div
                      class="dropdown dropdown--stack pb-2"
                      v-if="checkboxDropdownOpen.includes(index)"
                    >
                      <template v-if="tractorSn">
                        <label
                          :for="'remember' + sn.id"
                          class="remember"
                          v-for="(sn, i) in tractorSn"
                          v-bind:key="`tractor_select_${index}_${i}`"
                        >
                          <input
                            type="checkbox"
                            :id="'remember' + sn.id"
                            name="remember"
                            :checked="sn.serial_no == selectedTractorSn"
                            @click="chooseTractorSn(sn.serial_no)"
                          />
                          <span></span>
                          <div>{{ sn.serial_no }}</div>
                        </label>
                      </template>
                    </div>
                  </div>
                </template>
                <small class="text-red-400">{{ errorMessageSn }}</small>
                <div
                  v-if="selectedTractorSn !== ''"
                  class="select text-right"
                  @click="selectView = 'attachments'"
                >
                  Select Attachments →
                </div>
              </div>
              <div class="relative" v-else-if="selectView === 'attachments'">
                <div
                  class="form-group"
                  :class="attachmentsOpen === attachment.type ? 'active' : ''"
                  v-for="(attachment, index) in sortedAttachments"
                  v-bind:key="'attachment_' + index"
                >
                  <div
                    class="dropdown-inp"
                    @click="openCloseAttachments(attachment.type)"
                  >
                    {{ getAttachmentTitle(attachment.type)
                    }}<span>{{ attachment.items.length }}</span>
                  </div>
                  <inline-svg
                    class="down"
                    :src="require('@/assets/image/icons/date-down.svg')"
                  />
                  <div
                    class="dropdown"
                    v-if="attachmentsOpen === attachment.type"
                  >
                    <label
                      :for="'remember-loader' + i"
                      class="remember"
                      v-for="(item, i) in attachment.items"
                      v-bind:key="'loader_item_' + i"
                    >
                      <input
                        type="checkbox"
                        :id="'remember-loader' + i"
                        name="remember"
                        value="Bike"
                        :checked="
                          item.name ==
                          registrationsFormData[attachment.type + '_sn']
                        "
                        @click="chooseAttachment(attachment.type, item.name)"
                      />
                      <span></span>
                      <div>{{ item.name }}</div>
                    </label>
                  </div>
                </div>
                <div class="select left" @click="selectView = 'tractor'">
                  ←Select Tractors
                </div>
              </div>
            </div>
          </div>
          <div
            class="
              registration-summary
              col-span-9
              sm:col-span-9
              md:col-span-9
              lg:col-span-4
              xl:col-span-5
            "
          >
            <div
              class="container-registration-summary"
              v-if="user && user.role.id !== 1"
            >
              <div class="title">Registration Summary</div>
              <div class="text">
                Summary of selected Tractor and Attachment serial numbers to be
                registered.
              </div>
              <div class="summary-block">
                <div
                  class="summary"
                  v-if="
                    selectedTractorSn ||
                    registrationsFormData.loader_sn ||
                    registrationsFormData.backhoe_sn ||
                    registrationsFormData.mower_sn ||
                    registrationsFormData.cabin_sn
                  "
                >
                  <div class="section" v-if="selectedTractorSn">
                    <div>Tractor SN:</div>
                    <div>{{ selectedTractorSn }}</div>
                  </div>
                  <div class="section" v-if="registrationsFormData.loader_sn">
                    <div>Loader SN:</div>
                    <div>{{ registrationsFormData.loader_sn }}</div>
                  </div>
                  <div class="section" v-if="registrationsFormData.backhoe_sn">
                    <div>Backhoe SN:</div>
                    <div>{{ registrationsFormData.backhoe_sn }}</div>
                  </div>
                  <div class="section" v-if="registrationsFormData.mower_sn">
                    <div>Mid-Mount Mower SN:</div>
                    <div>{{ registrationsFormData.mower_sn }}</div>
                  </div>
                  <div class="section" v-if="registrationsFormData.cabin_sn">
                    <div>Cabin SN:</div>
                    <div>{{ registrationsFormData.cabin_sn }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!isEdit && userCanCreateRetailProgram"
        v-show="isEligibleForRetailPrograms"
        class="warranty-information col-span-4"
        id="retail-programs"
      >
        <RetailProgramsForm
          :products="allSelectedProducts"
          :saleDate="datePicker.selectedDate ? datePicker.selectedDate : null"
          :dealership="selectedDealership"
          ref="retailProgramsForm"
          subtitle="Are you interested in adding a retail program?"
          :startOpen="true"
        />
      </div>
      <div class="warranty-information col-span-4" id="wt">
        <div class="title">Warranty Information</div>
        <div class="text">Please select the warranty type</div>
        <div class="button-commercial">
          <div class="grid grid-cols-9 gap-2">
            <button
              type="button"
              @click="selectWarrantyType('NC')"
              :class="wt == 'NC' ? 'btn-active' : ''"
              class="
                col-span-9
                sm:col-span-9
                md:col-span-9
                lg:col-span-3
                xl:col-span-3
              "
            >
              Non-Commercial
              <v-popover>
                <inline-svg
                  class="info"
                  :src="require('@/assets/image/icons/notes.svg')"
                />
                <Tooltip
                  slot="popover"
                  title="Non-Commercial"
                  text="Non-Commercial: Tractor not intended for use in generating income, or for rental, loan, or lease purposes."
                />
              </v-popover>
            </button>
            <button
              type="button"
              @click="selectWarrantyType('C')"
              :class="wt == 'C' ? 'btn-active' : ''"
              class="
                col-span-9
                sm:col-span-9
                md:col-span-9
                lg:col-span-3
                xl:col-span-3
              "
            >
              Commercial
              <v-popover>
                <inline-svg
                  class="info"
                  :src="require('@/assets/image/icons/notes.svg')"
                />
                <Tooltip
                  slot="popover"
                  title="Commercial"
                  text="Commercial: Tractor intended for use in generating income; for rental, loan, or lease; or for nonagricultural commercial purposes."
                />
              </v-popover>
            </button>
            <button
              type="button"
              @click="selectWarrantyType('S')"
              :class="wt == 'S' ? 'btn-active' : ''"
              class="
                col-span-9
                sm:col-span-9
                md:col-span-9
                lg:col-span-3
                xl:col-span-3
              "
            >
              Stock
              <v-popover>
                <inline-svg
                  class="info"
                  :src="require('@/assets/image/icons/notes.svg')"
                />
                <Tooltip
                  :right="true"
                  slot="popover"
                  title="Stock"
                  text="Stock: Tractor still in dealer inventory. Stock registration does not start manufacturer warranty period."
                />
              </v-popover>
            </button>
          </div>
          <div v-if="errorMessageWT" class="text-red-500">
            {{ errorMessageWT }}
          </div>
        </div>
        <div class="dropdown-block">
          <h3 class="mb-4">Warranty Details</h3>
          <ShowHideSection title="Bumper-to-Bumper Warranty" class="mb-4">
            <div class="list-block">
              <div class="list-group">
                <div>Product</div>
                <ul>
                  <li>All Tier 4 Tractors</li>
                  <li>
                    Attachments (Front-End Loaders/Backhoes/Mid-Mount Mowers)
                  </li>
                  <li>Replacement Parts</li>
                  <li>Replacement Engines</li>
                  <li>All Tier 4 Tractors (Rental and Commercial Use)</li>
                </ul>
              </div>
              <div class="list-group">
                <div>Warranty Period</div>
                <ul>
                  <li>24 Months or 2,000 Hours</li>
                  <li>12 Months</li>
                  <li>3 Months or Remainder of Unit Warranty (Longer)</li>
                  <li>12 Months</li>
                  <li>24 Months or 2,000 Hours</li>
                </ul>
              </div>
              <div class="list-group">
                <div>Coverage</div>
                <ul>
                  <li>Parts / Labor</li>
                  <li>Parts / Labor</li>
                  <li>Parts Only</li>
                  <li>Parts Only</li>
                  <li>Parts / Labor</li>
                </ul>
              </div>
            </div>
            <div class="span">
              *Batteries and Tires are warranted by Manufacturer
            </div>
          </ShowHideSection>

          <ShowHideSection title="Powertrain Warranty">
            <div class="list-block">
              <div class="list-group">
                <div>Product Type and Usage</div>
                <ul>
                  <li>Tier IV Non-Commercial Use</li>
                  <li style="height: 32px"></li>
                  <li>Tier IV Rental & Commercial Use</li>
                </ul>
              </div>
              <div class="list-group">
                <div>Applicable Models</div>
                <ul>
                  <li>Between 20 and 70 engine horsepower</li>
                  <li>Less than 20 or more than 70 engine horsepower</li>
                  <li>All models</li>
                </ul>
              </div>
              <div class="list-group">
                <div>Warranty Period</div>
                <ul>
                  <li>72 months or 3,000 hrs</li>
                  <li>60 months or 2,000 hrs</li>
                  <li>36 months or 2,000 hrs</li>
                </ul>
              </div>
              <div class="list-group">
                <div>Coverage</div>
                <ul>
                  <li>Parts / Labor</li>
                  <li>Parts / Labor</li>
                  <li>Parts / Labor</li>
                </ul>
              </div>
            </div>
          </ShowHideSection>
        </div>
      </div>
      <div class="submit-registration col-span-4">
        <div>
          <ValidationProvider tag="div" rules="required" v-slot="{ errors }">
            <label for="applicableWarrantyTerms" class="remember">
              <input
                v-model="registrationsFormData.applicableWarrantyTerms"
                required
                type="checkbox"
                id="applicableWarrantyTerms"
                name="applicableWarrantyTerms"
              />
              <span></span>
              <div>
                I have explained the applicable warranty terms to the retail
                customer.
              </div>
            </label>
            <div v-if="errors[0]" class="text-red-500">
              This field is required!
            </div>
          </ValidationProvider>
          <ValidationProvider tag="div" rules="required" v-slot="{ errors }">
            <label for="discussedExclusions" class="remember">
              <input
                :value="isEdit"
                v-model="registrationsFormData.discussedExclusions"
                required
                type="checkbox"
                id="discussedExclusions"
                name="discussedExclusions"
              />
              <span></span>
              <div>
                I have discussed exclusions to warranty coverage with the retail
                customer, including transportation.
              </div>
            </label>
            <div v-if="errors[0]" class="text-red-500">
              This field is required!
            </div>
          </ValidationProvider>
          <ValidationProvider tag="div" rules="required" v-slot="{ errors }">
            <label for="operationAndMaintenance" class="remember">
              <input
                v-model="registrationsFormData.operationAndMaintenance"
                required
                type="checkbox"
                id="operationAndMaintenance"
                name="operationAndMaintenance"
              />
              <span></span>
              <div>
                I have reviewed proper operation and maintenance procedures with
                the retail customer.
              </div>
            </label>
            <div v-if="errors[0]" class="text-red-500">
              This field is required!
            </div>
          </ValidationProvider>
          <ValidationProvider tag="div" rules="required" v-slot="{ errors }">
            <label for="agreeSignedCopy" class="remember">
              <input
                v-model="registrationsFormData.agreeSignedCopy"
                required
                type="checkbox"
                id="agreeSignedCopy"
                name="agreeSignedCopy"
              />
              <span></span>
              <div>I will keep a signed copy of the registration on file.</div>
            </label>
            <div v-if="errors[0]" class="text-red-500">
              This field is required!
            </div>
          </ValidationProvider>
        </div>
        <div class="submit">
          <button
            v-if="isEdit"
            @click="submitRegistrationForm('update')"
            :readonly="buttonDisabled"
            :class="buttonDisabled ? 'opacity-50 cursor-wait' : ''"
            type="button"
            class="btn btn-black px-40"
          >
            Save
          </button>
          <button
            v-else
            type="button"
            @click="submitRegistrationForm('create')"
            :readonly="buttonDisabled"
            :class="buttonDisabled ? 'opacity-50 cursor-wait' : ''"
            class="btn btn-black px-40"
          >
            Save
          </button>
          <div class="edit-link" @click="showDiscardModal = true">Discard</div>
        </div>
      </div>
      <ConfirmModal
        text="Discard changes?"
        confirmBtn="Discard"
        :show="showDiscardModal"
        @confirm="
          $router.push({
            name: 'WarrantyRegistrations',
            query: { ...registrationFilters },
          })
        "
        @close="showDiscardModal = false"
      />
    </form>
  </ValidationObserver>
</template>

<script>
import PageHeader from "../../component/PageHeader";
import Tooltip from "../../component/Tooltip";
import Loading from "../../component/Loading";
import Dropdown from "../../component/Dropdown";
import ShowHideSection from "../../component/ShowHideSection";
import moment from "moment";
import ClickOutside from "vue-click-outside";
import { mapActions, mapState } from "vuex";
import { FunctionalCalendar } from "vue-functional-calendar";
import { http } from "../../../api";
import ConfirmModal from "../../component/ConfirmModal";
import { debounce } from "@/utils/debounce";
import RetailProgramsForm from "../../component/retailPrograms/RetailProgramsForm";

export default {
  name: "RegistrationsAdd",
  data() {
    return {
      buttonDisabled: false,
      showDiscardModal: false,
      loadingTractorSn: false,
      inputClass: "",
      searchTractorSns: [],
      dealerId: "",
      selectView: "tractor",
      wt: "",
      saleDate: {
        selectedInCalendar: [],
      },
      expirationDate: {
        selectedInCalendar: [],
      },
      calendarData: {},
      dealershipOpen: false,
      registrationsFormData: {},
      checkboxDropdownOpen: [],
      datePicker: {},
      expirationDatePicker: {},
      selectedTractorSn: "",

      selectedProducts: {
        tractor: null,
        loader: null,
        backhoe: null,
        mower: null,
        cabin: null,
      },

      autoSelectedModels: {
        tractor: null,
        loader: null,
        backhoe: null,
        mower: null,
        cabin: null,
      },

      showInput: false,
      showInputCabin: false,
      isEdit: false,
      errorMessage: "",
      errorMessageSn: "",
      errorMessageWT: "",
      errorMessageDealership: "",
      errorMessageDate: "",
      dealershipName: "",
      selectedStatus: "",
      availableAttachments: [],
      attachments: null,
      attachmentsOpen: "",
      snDropdown: false,

      tractorModel: null,
    };
  },
  components: {
    PageHeader,
    Tooltip,
    FunctionalCalendar,
    Dropdown,
    Loading,
    ConfirmModal,
    ShowHideSection,
    RetailProgramsForm,
  },
  computed: {
    ...mapState({
      createRegistrations: (state) => state.registration.createRegistrations,
      tractorSns: (state) => state.registration.tractorSns,
      registrationsDetails: (state) => state.registration.registrationsDetails,
      registrationStatuses: (state) => state.registration.registrationStatuses,
      user: (state) => state.auth.user,
      registrationFilters: (state) => state.registration.indexFilters,
    }),

    backRoute: function () {
      if (this.isEdit) {
        return {
          name: "RegistrationDetail",
          params: { id: this.$route.params.id },
        };
      } else {
        return {
          name: "WarrantyRegistrations",
          query: { ...this.registrationFilters },
        };
      }
    },

    allSelectedProducts: function () {
      return [
        {
          ...(this.selectedProducts.tractor ?? {}),
        },
        {
          ...(this.selectedProducts.loader ?? {}),
        },
        {
          ...(this.selectedProducts.backhoe ?? {}),
        },
        {
          ...(this.selectedProducts.mower ?? {}),
        },
        {
          ...(this.selectedProducts.cabin ?? {}),
        },
      ].filter((p) => p.sn);
    },

    selectedDealership: function () {
      return {
        id: this.dealerId,
      };
    },


    modelOptions() {
      return {
        tractor: this.createRegistrations?.models?.tractor,
        loader: this.createRegistrations?.models?.loader,
        backhoe: this.createRegistrations?.models?.backhoe,
        mower: this.createRegistrations?.models?.mower,
        cabin: this.createRegistrations?.models?.cabin,
      };
    },

    selectedModels() {
      return Object.keys(this.selectedProducts).reduce((obj, key) => {
        return {
          ...obj,
          [key]: this.selectedProducts[key]?.model
        }
      }, {});
    },

    sortedAttachments: function () {
      const order = ["loader", "backhoe", "mower", "cabin"];

      return Object.keys(this.attachments)
        .map((key) => ({
          items: [...this.attachments[key]],
          type: key,
          order: order.indexOf(key) ?? 9999,
        }))
        .sort((a, b) => a.order - b.order);
    },

    userCanCreateRetailProgram: function () {
      return (
        this.user.permissions.includes("GLOBAL") ||
        this.user.permission_actions?.["Retail Program"]?.Create
      );
    },

    isEligibleForRetailPrograms: function () {
      return this.wt != 'S';
    }
  },
  watch: {
    // selectedTractorSn: {
    //   handler(val) {
    //     this.getTractorModel(val);
    //   },
    // },
    datePicker: {
      handler(val) {
        this.errorMessageDate = "";
      },
      deep: true,
    },
  },
  created() {
    this.getCreateRegistrations();

    this.getLoaderModel = this.createGetModelFunc("loader");
    this.getBackhoeModel = this.createGetModelFunc("backhoe");
    this.getMowerModel = this.createGetModelFunc("mower");
    this.getCabinModel = this.createGetModelFunc("cabin");
    this.searchTractorSnDebounced = debounce(this.searchTractorSn, 250);

    const debouncedGetTractorModel = debounce(this.getTractorModel, 250);

    this.$store.commit("registration/setTractorSn", []);
    if (this.user.role.id === 3) {
      this.registrationsFormData.dealership_id = this.user.dealership_id;
      this.loadingTractorSn = true;
      this.getRegistrationTractorSn({ id: this.user.dealership_id })
        .then((res) => {})
        .finally(() => {
          this.loadingTractorSn = false;
        });
    }
    if (this.$route.path === "/registrations/add") {
      this.isEdit = false;

      this.$watch('selectedTractorSn', val => {
        debouncedGetTractorModel(val);
      });

      if (this.$route.query.dealership && this.user.role.id > 1) {
        const newDealership = this.createRegistrations.dealership?.find(
          (item) => item.name === this.$route.query.dealership
        );

        if (newDealership) {
          this.dealershipName = newDealership.name;
          this.dealerId = newDealership.id;
        }
      }

      if (this.user.role.id === 3) {
        //remove dealership from query for dealers - this is not allowed
        if (this.$route.query.dealership) {
          let query = { ...this.$route.query };
          delete query.dealership;
          this.$router.replace({ query });
        }

        //dealership is the user ID
        this.dealerId = this.user.dealership_id;
      }

      if (this.user.role.id > 1 && this.dealerId && this.dealerId != "") {
        this.loadAvailableAttachments(this.dealerId).then(() => {
          if (this.$route.query.tractor_sn) {
            this.chooseTractorSn(this.$route.query.tractor_sn);
          }
        });
      }
    } else {
      this.showInputCabin = true;
      this.showInput = true;
      this.isEdit = true;
      this.getRegistrationsDetails({ id: this.$route.params.id })
        .then((res) => {
          this.selectedTractorSn = this.registrationsDetails.tractor_sn;

          this.registrationsFormData = this.registrationsDetails;
          this.wt = this.registrationsDetails.warranty_type;
          this.dealershipName = this.registrationsDetails.dealership.name;
          this.selectedStatus = this.registrationsDetails.status.name;
          this.registrationsFormData.status =
            this.registrationsDetails.status.id;
          this.dealerId = this.registrationsDetails.dealership.id;
          this.getRegistrationTractorSn({
            id: this.registrationsDetails.dealership.id,
          });
          this.$refs.Calendar.ChooseDate(this.registrationsDetails.sale_date);
          this.saleDate.selectedInCalendar.push(
            moment(this.registrationsDetails.sale_date).format("M/D/yyyy")
          );
          this.$refs.ExpirationCalendar.ChooseDate(
            this.registrationsDetails.expiration_date
          );
          this.expirationDate.selectedInCalendar.push(
            moment(this.registrationsDetails.expiration_date).format("M/D/yyyy")
          );
          this.registrationsFormData.agreeSignedCopy = true;
          this.registrationsFormData.operationAndMaintenance = true;
          this.registrationsFormData.discussedExclusions = true;
          this.registrationsFormData.applicableWarrantyTerms = true;

          setTimeout(() => {
            this.datePicker.selectedDate = this.registrationsDetails.sale_date;
            this.expirationDatePicker.selectedDate =
              this.registrationsDetails.expiration_date;
          }, 0);

          this.selectedProducts = {
            tractor: {
              sn: this.registrationsDetails.tractor_sn,
              model:this.registrationsDetails.tractor_model,
            },
            loader: {
              sn: this.registrationsDetails.loader_sn,
              model:this.registrationsDetails.loader_model,
            },
            backhoe: {
              sn: this.registrationsDetails.backhoe_sn,
              model:this.registrationsDetails.backhoe_model,
            },
            mower: {
              sn: this.registrationsDetails.mower_sn,
              model:this.registrationsDetails.mower_model,
            },
            cabin: {
              sn: this.registrationsDetails.cabin_sn,
              model:this.registrationsDetails.cabin_model,
            },
          }


          this.$watch('selectedTractorSn', val => {
            debouncedGetTractorModel(val);
          });

        })
        .catch((err) => {
          if (err.response.status === 403) {
            this.$router.push({
              name: "WarrantyRegistrations",
              query: { ...this.registrationFilters },
            });
            this.$toasted.error(err.response.data.message || "Server Error");
          }
        });
    }
  },
  methods: {
    ...mapActions({
      getCreateRegistrations: "registration/getCreateRegistrations",
      createNewTractor: "registration/createNewTractor",
      getRegistrationTractorSn: "registration/getRegistrationTractorSn",
      getRegistrationsDetails: "registration/getRegistrationsDetails",
      updateRegistration: "registration/updateRegistration",
      getProgramInfoBySns: "programs/getProgramInfoBySns",
    }),
    getAttachmentTitle(attachment) {
      const titles = {
        mower: "Mid-Mount Mowers",
        loader: "Loaders",
        backhoe: "Backhoes",
        cabin: "Cabins",
      };
      return titles[attachment] ?? attachment;
    },
    tractorSnSelect(sn) {
      this.selectedTractorSn = sn.serial_no;
      this.snDropdown = false;
      this.inputClass = "success";
    },
    selectModel(product, model) {
      if (!this.selectedProducts[product]) return;

      this.selectedProducts[product].model = model;
    },
    snHide() {
      this.snDropdown = false;
    },
    showAttachments() {
      if (this.selectView === "attachments") return;
      this.getAttachments();
    },
    async loadAvailableAttachments(dealership_id) {
      try {
        const res = await http.get(
          `registrations/attachments-by-dealer/${dealership_id}`
        );
        this.availableAttachments = { ...res.data.data.attachments };
        this.attachments = { ...this.availableAttachments };
      } catch (err) {
        console.error(err);
        this.errorMessageSn = err.response.data.message;
      }
    },
    async getAttachments() {
      if (this.attachments) {
        this.selectView = "attachments";
      } else if (this.dealerId) {
        await this.loadAvailableAttachments(this.dealerId);
        this.selectView = "attachments";
      } else {
        this.$toasted.error("Please select a dealership first!");
      }
    },
    selectStatus(status) {
      this.selectedStatus = status.status;
      this.registrationsFormData.status = status.id;
    },
    selectWarrantyType(wt) {
      this.wt = wt;
      this.registrationsFormData.warranty_type = wt;
      this.errorMessageWT = "";
    },
    dealershipSelect(dealership) {
      if (dealership === "noItem") {
        this.errorMessageDealership = "Select a dealership.";
        this.dealerId = "";
        this.registrationsFormData.dealership_id = "";
      } else {
        this.dealerId = dealership.id;
        this.dealershipName = dealership.name;
        this.registrationsFormData.dealership_id = dealership.id;
        this.loadingTractorSn = true;
        this.getRegistrationTractorSn({ id: dealership.id })
          .then((res) => {
          })
          .finally(() => {
            this.loadingTractorSn = false;
          });

        this.loadAvailableAttachments(this.dealerId)
          .then(() => {})
          .catch((err) => {});
        this.errorMessageDealership = "";
      }
      this.selectedTractorSn = "";
      this.inputClass = "";
    },
    dealershipHide() {
      this.dealershipOpen = false;
    },
    clearProducts() {
      this.registrationsFormData.loader_sn = "";
      this.registrationsFormData.backhoe_sn = "";
      this.registrationsFormData.mower_sn = "";
      this.registrationsFormData.cabin_sn = "";
      this.selectedProducts = {
        ...this.selectedProducts,
        loader: null,
        backhoe: null,
        mower: null,
        cabin: null,
      };
    },
    chooseTractorSn(sn) {
      this.errorMessageSn = "";
      this.attachments = this.availableAttachments;
      this.clearProducts();

      if (this.selectedTractorSn == sn) {
        this.selectedTractorSn = "";
      } else {
        this.selectedTractorSn = sn;

        http
          .get("registrations/attachments-by-sn/" + this.selectedTractorSn)
          .then((res) => {
            this.attachmentsOpen = "";
            this.selectView = "attachments";

            Object.assign(this.registrationsFormData, {
              loader_sn: "",
              backhoe_sn: "",
              mower_sn: "",
              cabin_sn: "",
            });

            //filter results through available attachments
            this.attachments = {};
            Object.keys(this.availableAttachments).forEach((type) => {
              this.attachments[type] = this.availableAttachments[type].filter(
                (a) => res.data.data.attachments.includes(a.model)
              );

              const match = this.attachments[type].find(
                (a) => a.invoice_num == res.data.data.invoice
              );
              if (match) {
                this.chooseAttachment(type, match.name);
              }

              if (this.attachments[type].length === 0)
                delete this.attachments[type];
            });
          })
          .catch((err) => {
            this.errorMessageSn = err.response.data.message;
          });
      }
    },
    async getTractorModel(sn) {
      if (sn == "") {
        this.selectedProducts.tractor = null;
        this.autoSelectedModels.tractor = null;
        return "";
      }

      try {
        const res = await this.getProgramInfoBySns([sn]);

        this.selectedProducts.tractor = res[0] ?? null;
        this.autoSelectedModels.tractor = !this.isEdit && res[0]?.model;
        return res[0]?.model;
      } catch (err) {
        console.error(err);
        return null;
      }
    },
    createGetModelFunc(propName) {
      return debounce(async (e) => {
        try {
          if (
            this.selectedProducts.hasOwnProperty(propName)
          ) {
            console.log(e);
            const res = await this.getProgramInfoBySns([e.target.value]);
            this.selectedProducts[propName] = res[0];
            this.autoSelectedModels[propName] = !this.isEdit && res[0]?.model;
          }
        } catch (err) {
          console.error(err);
        }
      }, 500);
    },
    searchTractorSn() {
      this.errorMessageSn = "";
      this.inputClass = "error";
      if (this.selectedTractorSn.length >= 1) {
        http
          .get(
            "registrations/search/tractor-sn?term=" +
              this.selectedTractorSn +
              "&dealership_id=" +
              this.dealerId
          )
          .then((res) => {
            this.searchTractorSns = res.data.data.sns;
            if (this.isEdit && this.registrationsDetails.tractor_sn.includes(this.selectedTractorSn)) {
              this.searchTractorSns.unshift({
                serial_no: this.registrationsDetails.tractor_sn
              });
            }
            if (
              this.searchTractorSns.find(
                (item) => item.serial_no === this.selectedTractorSn
              )
            ) {
              this.inputClass = "success";
            } else {
              this.inputClass = "error";
            }
            if (this.searchTractorSns.length) {
              this.snDropdown = true;
            } else {
              this.snDropdown = false;
            }
          })
          .catch((err) => {});
      } else {
        this.snDropdown = false;
      }
    },
    async chooseAttachment(type, sn) {
      // this.selectView = "tractor";
      if (this.registrationsFormData[type + "_sn"] == sn) {
        this.registrationsFormData[type + "_sn"] = "";
        this.selectedProducts[type] = null;
      } else {
        this.registrationsFormData[type + "_sn"] = sn;

        try {
          const res = await this.getProgramInfoBySns([sn]);
          this.selectedProducts[type] = res[0] ?? null;
        } catch (err) {
          console.error(err);
        }
      }
      // this.selectView = "attachments";
    },
    validationInputs() {
      let returnVal = true;
      let toastedMessage = null;

      if (
        this.createRegistrations.dealership.find(
          (item) => item.name === this.dealershipName
        ) ||
        this.user.role.id === 3
      ) {
        this.errorMessageDealership = "";
      } else {
        this.errorMessageDealership = "Select a dealership";
        toastedMessage = toastedMessage ?? "Select a dealership";
        returnVal = false;
      }

      if (!this.dealershipName.length && this.user.role.id === 1) {
        this.errorMessageDealership = "Select a dealership";
        toastedMessage = toastedMessage ?? "Select a dealership";
        returnVal = false;
      } else {
        this.errorMessageDealership = "";
      }

      // if (!this.selectedTractorSn.length && this.user.role.id !== 1) {
      //   this.errorMessageSn = "Select tractor serial number";
      //   toastedMessage = toastedMessage ?? "Select tractor serial number";
      //   returnVal = false;
      // } else {
      //   this.errorMessageSn = "";
      // }

      if (!this.datePicker.selectedDate) {
        this.errorMessageDate = "Select Sale Date";
        toastedMessage = toastedMessage ?? "Select Sale Date";
        returnVal = false;
      } else {
        this.errorMessageDate = "";
        this.registrationsFormData.sale_date = this.datePicker.selectedDate;
      }

      if (this.isEdit && this.expirationDatePicker.selectedDate) {
        this.registrationsFormData.expiration_date =
          this.expirationDatePicker.selectedDate;
      }

      if (!this.registrationsFormData.warranty_type) {
        this.errorMessageWT = "Select warranty type";
        toastedMessage = toastedMessage ?? "Select warranty type";
        returnVal = false;
      } else {
        this.errorMessageWT = "";
      }

      if (returnVal) return true;

      this.$toasted.error(toastedMessage);
      return false;
    },

    async submitRegistrationForm(buttonType) {
      this.registrationsFormData.tractor_sn = this.selectedTractorSn;

      // models
      this.registrationsFormData.tractor_model = this.selectedModels.tractor;
      this.registrationsFormData.loader_model = this.selectedModels.loader;
      this.registrationsFormData.backhoe_model = this.selectedModels.backhoe;
      this.registrationsFormData.mower_model = this.selectedModels.mower;
      this.registrationsFormData.cabin_model = this.selectedModels.cabin;

      try {
        const success = await this.$refs.form.validate();
        if (!success) {
          this.$toasted.error("Your submission contains errors");
        }
        if (!this.validationInputs() || !success) return false;
      } catch (err) {
        this.$toasted.error(err.response.data.message || "Server Error");
        return;
      }

      this.buttonDisabled = true;

      if (buttonType === "create") {
        //Save new tractor registration
        let response;
        try {
          response = await this.createNewTractor(this.registrationsFormData);
        } catch (err) {
          this.buttonDisabled = false;
          this.$toasted.error(err.response.data.message || "Server Error");
          return;
        }

        //submit retail programs
        try {
          if (this.isEligibleForRetailPrograms) {
            await this.$refs.retailProgramsForm?.saveRetailPrograms(response.id);
          }
        } catch (err) {
          this.buttonDisabled = false;
          this.$router.push({name: "WarrantyRegistrations"}, () => {
            setTimeout( () => {
              this.$toasted.error(
                "Registration saved, but encountered an error saving the retail program information:\n" +
                  (err || "Server Error")
              );
            }, 200);
          });
          return;
        }

        //Open up to newly created registration, open print dialogue
        let props = this.$router.resolve({ 
          name: 'RegistrationDetail',
          params: { id: response.id },
          query: { view: "print" }
        });

        window.open(props.href);
        this.$router.push("/registrations");

      } else if (buttonType === "update") {
        this.registrationsFormData.registration_transfer = 12;

        try {
          await this.updateRegistration({
            id: this.$route.params.id,
            formData: this.registrationsFormData,
          });
        } catch (err) {
          this.buttonDisabled = false;
          this.$toasted.error(
            err.response?.data?.message || err.response ? "Server Error" : err
          );
          return;
        }

        this.$router.push("/registrations");

        try {
          const res = await http.get(
            "registrations/" + this.$route.params.id + "/pdf",
            {
              responseType: "blob",
            }
          );
          const blob = new Blob([res.data], {
            type: "application/pdf",
          });
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL);
        } catch (err) {}
      }
    },
    dealershipOpenModal() {
      this.dealershipOpen = !this.dealershipOpen;
    },
    checkboxDropdownOpenModal(index) {
      if (this.checkboxDropdownOpen.includes(index)) {
        this.checkboxDropdownOpen = this.checkboxDropdownOpen.filter(
          (item) => item !== index
        );
      } else {
        this.checkboxDropdownOpen.push(index);
      }
    },
    openCloseAttachments(key) {
      this.attachmentsOpen = this.attachmentsOpen === key ? "" : key;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style scoped>
.tooltip {
  outline: none;
}
</style>
