<template>
  <section class="add-user col-span-12">
    <ValidationObserver
      autocomplete="off"
      @submit.prevent="saveUser"
      tag="form"
      class="container"
      ref="form"
    >
      <PageHeader
        title="Edit Account Settings"
        backText="Back to Dashboard"
        :backRoute="{ name: 'Home' }"
        @save="saveUser"
        @discard="showDiscardModal = true"
        :disabledButtons="disableBtn"
      />
      <div class="user-details">
        <div style="margin: 0 0 40px"></div>
        <div class="user-block-group">
          <div class="user-block">
            <div class="user-block-container">
              <div class="form-group w-full">
                <label class="control-label">Name</label>
                <ValidationProvider
                  rules="required"
                  tag="div"
                  v-slot="{ classes }"
                >
                  <input
                    v-model="formData.name"
                    :class="classes"
                    type="text"
                    name="name"
                  />
                </ValidationProvider>
              </div>
              <div class="form-group w-full">
                <label class="control-label">Email Address</label>
                <ValidationProvider
                  rules="email|required"
                  tag="div"
                  v-slot="{ classes }"
                >
                  <input
                    v-model="formData.email"
                    type="email"
                    :class="classes"
                    name="email"
                  />
                </ValidationProvider>
                <span v-if="user.role.id === 1">
                  A valid e-mail address. All e-mails from the system will be
                  sent to this address. The e-mail address is not made public
                  and will only be used if you wish to receive a new password or
                  wish to receive certain news or notifications by e-mail.
                </span>
              </div>

              <div class="form-group w-full">
                <label class="control-label">New Password</label>
                <div style="display: none">
                  <input type="text" autocomplete="new-password" />
                  <input type="password" autocomplete="new-password" />
                </div>
                <ValidationProvider
                  :rules="user_id === 'add' ? 'required|password:@confirm' : ''"
                  tag="div"
                  v-slot="{ classes }"
                >
                  <input
                    v-model="formData.password"
                    autocomplete="off"
                    type="password"
                    :class="classes"
                    name="password"
                    value=""
                  />
                </ValidationProvider>
              </div>
              <div class="form-group w-full">
                <label class="control-label">Confirm New Password</label>
                <ValidationProvider
                  vid="confirm"
                  :rules="user_id === 'add' ? 'required' : ''"
                  tag="div"
                  v-slot="{ classes }"
                >
                  <input
                    v-model="formData.password_confirmation"
                    type="password"
                    :class="classes"
                    name="confirm_password"
                    value=""
                  />
                </ValidationProvider>
              </div>
            </div>
          </div>
          <div class="user-block user-block--basics">
            <div class="user-block-container">
              <div class="user-type">
                <dropdown
                  :status="false"
                  fieldName="name"
                  label="User Type"
                  :validation="true"
                  inputName="user_type"
                  :editValue="selectedUserType"
                  :all="false"
                  :search="true"
                  :textOnly="true"
                  disabled
                ></dropdown>
              </div>
              <div class="user-type">
                <dropdown
                  :status="false"
                  fieldName="name"
                  label="User Group"
                  :validation="true"
                  inputName="user_group"
                  :editValue="selectedUserGroup"
                  :all="false"
                  :search="true"
                  :textOnly="true"
                  disabled
                ></dropdown>
              </div>
              <div class="user-type">
                <dropdown
                  :data="dealership"
                  :status="false"
                  fieldName="name"
                  label="Dealership"
                  @select="selectDealership"
                  :validation="true"
                  inputName="territory_manager"
                  :editValue="selectedDealership"
                  :all="false"
                  :search="true"
                  :textOnly="!userCanEditBasics"
                  :disabled="!userCanEditBasics"
                ></dropdown>
              </div>
            </div>
          </div>
        </div>
        <div class="send">
          <div class="edit-link" @click="showDiscardModal = true">Discard</div>
          <button
            :disabled="disableBtn"
            :class="disableBtn ? 'opacity-50 cursor-wait' : ''"
            class="btn btn-black px-60"
            type="submit"
          >
            Save
          </button>
        </div>
      </div>
    </ValidationObserver>
    <ConfirmModal
      text="Discard changes?"
      confirmBtn="Discard"
      :show="showDiscardModal"
      @confirm="$router.push('/admin/users')"
      @close="showDiscardModal = false"
    />
  </section>
</template>



<script>
import PageHeader from "../../../component/PageHeader";
import Dropdown from "../../../component/Dropdown";
import ConfirmModal from "../../../component/ConfirmModal";
import { http } from "../../../../api";
import { mapActions, mapState } from "vuex";

export default {
  name: "accountSettings",
  data() {
    return {
      formData: {
        permissions: [],
      },
      disableBtn: false,
      showDiscardModal: false,
      selectedUserType: null,
      selectedUserGroup: null,
      selectedDealership: null,
      displayTab: "account",
      dealership: [],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    userCanEditBasics: function () {
      return this.user.permissions.includes("GLOBAL");
    },
  },
  created() {
    this.user_id = this.user.id;
    let vm = this;

    this.getUser({ id: this.user_id })
      .then((res) => {
        if (!res.permissions) {
          res.permissions = [];
        }

        this.formData = res;

        if (res.dealer) {
          this.selectDealership(res.dealer);
        }
        this.formData.password = this.formData.password_confirmation =
          res.password;
        this.selectedUserType = res.role.name;
        this.selectedUserGroup = res.role.group;
        this.formData.status = res.status.id;
      })
      .catch((err) => {
        this.$toasted.error(err.response.data.message || "Server Error");
      });

    if (this.user.permissions.includes("GLOBAL")) {
      http
        .get("/users/create")
        .then((res) => {
          this.dealership = res.data.data.dealership;
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    }
  },
  components: {
    PageHeader,
    Dropdown,
    ConfirmModal,
  },
  methods: {
    ...mapActions({
      updateUser: "user/updateUser",
      getUser: "user/getUser",
    }),
    selectDealership(item) {
      this.selectedDealership = item.name;
      this.formData.dealership_id = item.id;
    },
    saveUser() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.disableBtn = true;
          if (true) {
            this.updateUser({ id: this.user_id, data: this.formData })
              .then((res) => {
                this.$toasted.success(res.data.message);
                this.$router.push("/dashboard");
              })
              .catch((error) => {
                if (error.response.data.errors) {
                  let errors = error.response.data.errors;
                  this.$toasted.error(errors[Object.keys(errors)[0]]);
                  return false;
                }
                this.$toasted.error(
                  err.response.data.message || "Server Error"
                );
              })
              .finally(() => {
                this.disableBtn = false;
              });
          }
        } else {
          this.$toasted.error("Your submission contains errors");
        }
      });
    },
  },
};
</script>

<style scoped>
.control-label-title {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  padding-bottom: 8px;
}
</style>
