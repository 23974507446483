<template>
  <section class="manage col-span-12">
    <div class="container grid grid-cols-1 gap-0">
      <PageHeader
        title="Videos"
        :backLink="false"
        :saveButton="false"
        :discardLink="false"
      />
      <div class="filter-block filter-block-border">
        <div>
          <div class="table-filter-search-input">
            <div class="form-group">
              <label class="control-label">Search</label>
              <input
                type="text"
                name="search"
                v-model="filterData.media"
                v-on:keyup.enter="filter"
                class="search"
                placeholder="Video Name"
              />
              <inline-svg
                class="search-icon"
                :src="require('@/assets/image/icons/search.svg')"
              />
            </div>
          </div>
        </div>
        <div class="card">
          <div class="btn btn-black px-40" @click="filter">
            <div class="text"><a>Apply</a></div>
          </div>
          <div class="btn redo px-15" @click="resetFilter">
            <a>
              <inline-svg :src="require('@/assets/image/icons/redo.svg')" />
            </a>
          </div>
        </div>
      </div>
      <div class="video-block ml-minus-15" v-if="videos && videos.length">
        <router-link
          tag="div"
          class="video-card ml-15"
          v-for="(video, i) in videos"
          :to="'/videos/' + video.id"
          :key="'video_' + i"
        >
          <div class="video">
            <img :src="video.img" alt="" />
          </div>
          <div class="video-title">
            <span>{{ video.posted_on_1 }}</span>
            <p>{{ video.title }}</p>
          </div>
        </router-link>
      </div>
      <div v-else class="text-center mt-5">
        No results found. Please adjusts your search criteria message.
      </div>
      <div class="pages" v-if="pagination.total_pages > 1">
        <div class="pages-quantity">
          Page {{ filterData.page }} of {{ pagination.total_pages }} - Showing
          {{ pagination.per_page }} Results of {{ pagination.total }} Total
        </div>
        <paginate
          v-if="pagination.total_pages"
          :page-count="pagination.total_pages"
          :page-range="screenWidth < 468 ? 3 : 6"
          :margin-pages="1"
          :initial-page="initialPage - 1"
          :click-handler="clickCallback"
          :prev-text="'&laquo;'"
          :next-text="'&raquo;'"
          :container-class="'pages-btn'"
          :active-class="pageActive"
        >
        </paginate>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../component/PageHeader";
import { mapActions, mapState } from "vuex";

export default {
  name: "Videos",
  data() {
    return {
      screenWidth: 0,
      filterData: {
        page: 1,
        term: "",
      },
      pageActive: "active",
    };
  },
  computed: {
    ...mapState({
      videos: (state) => state.videos.videos,
      pagination: (state) => state.videos.pagination,
      initialPage: (state) => state.videos.initialPage,
    }),
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (this.$route.query.page) {
      if (this.$route.query.media) {
        this.filterData.media = this.$route.query.media;
      }
      this.filterData.page = this.$route.query.page;
      this.filterVideos(this.filterData);
    } else {
      this.getVideos();
    }
  },
  methods: {
    ...mapActions({
      getVideosList: "videos/getVideosList",
      filterVideos: "videos/filterVideos",
      getInitialPage: "videos/getInitialPage",
    }),
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
    getVideos() {
      this.filterData.page = this.initialPage;
      this.getVideosList({ page: this.filterData.page })
        .then((res) => {})
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    },
    filter() {
      this.$router.push(
        "/videos?" + new URLSearchParams(this.filterData).toString()
      );
      this.filterVideos(this.filterData)
        .then((res) => {})
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    },
    resetFilter() {
      this.pageActive = "";
      this.filterData = {
        page: 1,
      };
      this.getInitialPage(1);
      this.filter();
    },
    clickCallback(pageNum) {
      this.getInitialPage(pageNum);
      this.filterData.page = pageNum;
      this.pageActive = "active";
      this.filter();
    },
  },
  components: {
    PageHeader,
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss">
</style>
