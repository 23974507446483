import { http } from '../../../api';

const gallery = {
    namespaced: true,
    state: {
        gallery: [],
        brochures: [],
        initialPage: 1,
        brochuresPagination: {},
        galleryFolder: {},
        galleryItem: {},
    },
    actions: {
        getGalleryFolderList: ({ commit }, params) => {
            const q = new URLSearchParams(params);
            return http.get('/gallery?' + q.toString()).then(res => {
                commit('setGalleryFolderList', res.data.data.gallery)
          });
        },
        getBrochuresList: ({ commit }, params) => {
            const q = new URLSearchParams(params);
            return http.get('/gallery/brochures?' + q.toString()).then(res => {
                commit('setBrochuresList', res.data.data.brochures)
          });
        },
        getGalleryList: ({ commit }, params) => {
            return http.get('/gallery/' + params.slug).then(res => {
                commit('setGalleryList', res.data.data.gallery_images)
          });
        },
        getGalleryItem: ({ commit }, params) => {
            return http.get('/gallery/' + params.slug + '/' + params.imageSlug).then(res => {
                commit('setGalleryItem', res.data.data.image)
            });
        },
        getInitialPage: ({ commit }, params) => {
            commit('setInitialPage', params)
        },
    },
    mutations: {
        setGalleryFolderList(state, data){
            state.gallery = data;
        },
        setInitialPage(state, page){
            state.initialPage = page;
        },
        setBrochuresList(state, data){
            state.brochures = data.data;
            state.brochuresPagination = data.pagination;
        },
        setGalleryList(state, data){
            state.galleryFolder = data;
        },
        setGalleryItem(state, data){
            state.galleryItem = data;
        },
    },
    getters: {
        gallery: state => state.gallery,
        galleryFolder: state => state.galleryFolder,
        galleryItem: state => state.galleryItem,
        brochures: state => state.brochures,
        brochuresPagination: state => state.brochuresPagination,
        initialPage: state => state.initialPage,
    }
};

export default gallery;
