<template>
  <label
    tabindex="0"
    class="flex items-center"
    v-bind:class="{ disabled: disabled }"
    @click.prevent="checkboxClick"
    @keyup.enter="checkboxClick"
  >
    <div class="mr-2">
      <input
        type="checkbox"
        :name="name"
        :selected="isSelected"
        :disabled="disabled"
        v-bind:class="{ selected: isSelected }"
      />
      <span>
        <inline-svg :src="require('@/assets/image/icons/check.svg')" />
      </span>
    </div>
    <div :class="labelClass">
      {{ label }}
    </div>
  </label>
</template>

<script>
export default {
  name: "CheckboxInput",
  data() {
    return {};
  },
  computed: {
    isSelected: function () {
      return this.reverseSync ? !this.model : this.model;
    },
  },
  props: {
    model: {
      type: Boolean,
      required: true,
    },
    reverseSync: {
      type: Boolean,
      required: false,
      default: false,
    },
    name: {
      type: String,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    labelClass: {
      type: String,
      required: false,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    checkboxClick() {
      if (this.disabled) return;
      
      this.$emit("update:model", !this.model);
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
$checkboxSize: 16px;
$color: #5f5f5f;
$fill: #ffffff;

label {
  cursor: pointer;

  &.disabled {
    opacity: 0.8;
    cursor: default;
  }
}

input[type="checkbox"] {
  display: none;

  + span {
    position: relative;
    display: block;
    border-radius: calc(min(20%, 5px));
    width: $checkboxSize;
    height: $checkboxSize;
    border: 1px solid $color;

    svg {
      display: none;
      width: 60%;
      height: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      path {
        fill: $fill;
      }
    }
  }

  &.selected + span {
    background-color: $color;

    svg {
      display: block;
    }
  }
}
</style>
