<template>
  <section class="order-tractor col-span-12">
    <div class="container">
      <PageHeader
        title="Order Tractor"
        backText="Back to Orders"
        :backRoute="{ name: 'Orders', query: { ...orderFilters } }"
        :discardLink="false"
        :saveButton="false"
      />
      <div class="review-section grid grid-cols-12 gap-0">
        <div class="major-configuration col-span-6">
          <ValidationObserver tag="div" ref="form" class="major-container">
            <div class="subtitle-block mt-30">
              <div class="subtitle">Major Configuration</div>
              <p>Select your model and configuration details.</p>
            </div>
            <div class="tractor-confi">
              <div class="tractor-model-title">
                <div>
                  <p>Tractor, Configuration & Tires</p>
                </div>
              </div>
              <div class="tractor-model">
                <div class="form-block grid grid-flow-col grid-cols-12 gap-5">
                  <div class="col-span-8">
                    <div>
                      <div>
                        <dropdown
                          :data="secondStep.models"
                          :status="false"
                          fieldName="model"
                          label="Model"
                          :empty="true"
                          @select="selectTractor"
                          placeholder="Select"
                          inputName="model"
                          :editValue="activeTractor.model"
                          :all="false"
                        ></dropdown>
                      </div>
                    </div>
                    <div class="form-block-image col-span-4 max-768">
                      <div class="model-image">
                        <img
                          v-if="activeTractor && activeTractor.img"
                          :src="activeTractor.img"
                          :alt="activeTractor.model"
                        />
                        <div v-else>No Tractor Selected</div>
                      </div>
                    </div>
                    <div class="mt-5">
                      <div>
                        <dropdown
                          :data="selectedTractor.configurations"
                          :status="false"
                          placeholder="Select"
                          fieldName="description"
                          label="Configuration"
                          @select="selectConfiguration"
                          :validation="validationReset"
                          inputName="configuration"
                          :editValue="addTractorFormData.configuration"
                          :all="false"
                        ></dropdown>
                      </div>
                    </div>
                    <div class="mt-5 tires-assembled">
                      <div class="tires-min-screen">
                        <dropdown
                          :data="selectedTractor.tires"
                          :status="false"
                          placeholder="Select"
                          fieldName="name_description"
                          label="Tires"
                          @select="selectTires"
                          :validation="validationReset"
                          inputName="tires"
                          :editValue="addTractorFormData.tires"
                          :all="false"
                        ></dropdown>
                      </div>
                      <div class="form-block-image col-span-4 max-768">
                        <div class="checkbox">
                          <label for="checkbox-assembled" class="remember">
                            <div class="checkbox-label">Assembled (Y/N)</div>
                            <input
                              type="checkbox"
                              id="checkbox-assembled"
                              name="configuration_assembled"
                              v-model="
                                addTractorFormData.configuration_assembled
                              "
                            />
                            <span></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-block-image col-span-4 min-768">
                    <div class="model-image">
                      <img
                        v-if="activeTractor && activeTractor.img"
                        :src="activeTractor.img"
                        :alt="activeTractor.model"
                      />
                      <div v-else>No Tractor Selected</div>
                    </div>
                    <div class="checkbox">
                      <label for="checkbox-center" class="remember">
                        <div class="checkbox-label">Assembled (Y/N)</div>
                        <input
                          type="checkbox"
                          id="checkbox-center"
                          name="configuration_assembled"
                          v-model="addTractorFormData.configuration_assembled"
                        />
                        <span></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="options">
              <div class="subtitle-block">
                <div>Attachments</div>
                <p>
                  Select the Attachments you would like with your tractor order.
                </p>
              </div>
              <div class="tractor-model-title">
                <div>
                  <p>Item</p>
                  <p>Assembled (Y/N)</p>
                </div>
              </div>
              <div class="options-block mb-0">
                <div
                  class="left-group"
                  v-if="
                    reset &&
                    addTractorFormData.attachments &&
                    addTractorFormData.attachments.length
                  "
                >
                  <div
                    class="option-checkbox"
                    v-for="(
                      attachment, index
                    ) in addTractorFormData.attachments"
                    :key="index"
                  >
                    <label :for="`front-end-${index}`" class="remember">
                      <input
                        type="checkbox"
                        :id="`front-end-${index}`"
                        @click="selectAttachment(index)"
                        :checked="attachment && attachment.is_selected"
                        name="agree"
                      />
                      <span></span>
                      <div>
                        <div>{{ attachment.description }}</div>
                        <div class="attachment-name">{{ attachment.name }}</div>
                      </div>
                    </label>
                    <label
                      class="remember"
                      :for="`front-end-assembled-${index}`"
                      v-if="attachment.is_installable"
                    >
                      <input
                        type="checkbox"
                        :id="`front-end-assembled-${index}`"
                        @click="selectAttachmentIsInstalled(index)"
                        :checked="attachment && attachment.is_installed"
                        name="agree"
                      />
                      <span></span>
                    </label>
                  </div>
                </div>
                <div v-else class="left-group text-center pt-1">
                  No results found
                </div>
              </div>
              <div class="notes">
                <div>Compatibility Notes</div>
                <inline-svg :src="require('@/assets/image/icons/notes.svg')" />
              </div>
            </div>
            <div class="options">
              <div class="subtitle-block">
                <div>Additional Options</div>
                <p>
                  Select the Add-Ons you would like with your tractor order.
                </p>
              </div>
              <div class="tractor-model-title">
                <div>
                  <p>Item</p>
                  <p>Installation (Y/N)</p>
                </div>
              </div>
              <div class="options-block">
                <div
                  class="left-group"
                  v-if="
                    selectedTractor.options && selectedTractor.options.length
                  "
                >
                  <div
                    v-for="(option, index) in selectedTractor.options"
                    v-bind:key="`option_${index}`"
                    class="option-checkbox"
                    :class="index % 2 !== 0 ? 'silver' : ''"
                  >
                    <label :for="'option' + option.id" class="remember">
                      <input
                        @click="selectOption(option)"
                        :checked="optionsId.includes(option.id)"
                        type="checkbox"
                        :id="'option' + option.id"
                        name="agree"
                      />
                      <span></span>
                      <div>{{ option.description }}</div>
                    </label>
                    <label
                      :for="'option-instalation' + option.id"
                      class="remember"
                      v-if="option.is_installable"
                    >
                      <input
                        @click="selectInstallationOption(option)"
                        :checked="installationOptionsId.includes(option.id)"
                        type="checkbox"
                        :id="'option-instalation' + option.id"
                        name="agree"
                      />
                      <span></span>
                    </label>
                  </div>
                </div>
                <div v-else class="left-group text-center pt-1">
                  No results found
                </div>
              </div>
            </div>
            <div class="notes-block">
              <div class="subtitle-block">
                <div>Item Notes</div>
                <p>Tell us a little bit more about your order.</p>
              </div>
              <div class="form-block">
                <div class="form-container">
                  <div class="form-group notes-block-top">
                    <div class="form">
                      <label class="control-label">Quantity</label>
                      <ValidationProvider
                        rules="required"
                        tag="div"
                        v-slot="{ classes }"
                      >
                        <input
                          :class="classes"
                          type="number"
                          min="1"
                          name="labor_code"
                          v-model="addTractorFormData.quantity"
                          class="outline-none"
                        />
                      </ValidationProvider>
                    </div>
                    <div class="dropdown-term">
                      <dropdown
                        :data="secondStep.terms"
                        :status="false"
                        fieldName="term"
                        label="Payment Terms"
                        @select="selectPayment"
                        :validation="validationReset"
                        inputName="terms"
                        :all="false"
                        :editValue="addTractorFormData.terms"
                      ></dropdown>
                    </div>
                    <div class="checkbox">
                      <label for="retailed" class="remember">
                        <div>Retailed</div>
                        <input
                          type="checkbox"
                          id="retailed"
                          name="agree"
                          v-model="addTractorFormData.retailed"
                        />
                        <span></span>
                      </label>
                    </div>
                  </div>
                  <div class="form-input">
                    <label class="control-label">PO Number</label>
                    <input
                      type="text"
                      name="po_number"
                      v-model="addTractorFormData.po_number"
                      class="input pl-3 outline-none"
                    />
                  </div>
                  <div class="form-input">
                    <label class="control-label">Notes</label>
                    <input
                      type="text"
                      name=">notes"
                      v-model="addTractorFormData.notes"
                      class="input pl-3 outline-none"
                    />
                  </div>
                </div>
              </div>
            </div>
            <button
              v-if="tractorEdit"
              type="button"
              class="btn btn-info px-40"
              @click="editSaveOrder"
            >
              Update Order
            </button>
            <button
              v-else
              type="button"
              class="btn btn-black px-40"
              @click="addOrder"
            >
              Add to Order
            </button>
          </ValidationObserver>
        </div>
        <div class="order-summary col-span-6">
          <div class="container">
            <div class="flex items-center justify-between">
              <div class="subtitle">Order Summary</div>
            </div>
            <template v-if="addTractor.length">
              <div v-for="(tractor, index) in addTractor" :key="index">
                <p v-if="tractor.configuration_assembled" class="justify-end">
                  <span class="key">
                    <inline-svg
                      :src="require('@/assets/image/icons/key.svg')"
                    />
                  </span>
                  Delivered Assembled
                </p>
                <div class="orders">
                  <div class="order-image">
                    <img
                      v-if="tractor.tractorImg"
                      :src="tractor.tractorImg"
                      alt=""
                    />
                  </div>
                  <div class="w-full">
                    <div class="order-information">
                      <div>
                        <div class="information">
                          <div class="title">
                            <div>Model</div>
                            <div>{{ tractor.tractorModel.model }}</div>
                          </div>
                          <div class="title">
                            <div>Configuration</div>
                            <div>{{ tractor.configuration }}</div>
                          </div>
                          <div class="title">
                            <div>Tires</div>
                            <div>
                              {{ tractor.tires }}
                              <span
                                class="key"
                                v-if="tractor.configuration_assembled"
                              >
                                <inline-svg
                                  :src="require('@/assets/image/icons/key.svg')"
                                />
                              </span>
                            </div>
                          </div>
                          <div
                            class="title"
                            v-for="(attachment, index) in tractor.attachments"
                            :key="index"
                            v-if="attachment.is_selected"
                          >
                            <div>{{ attachment.category.name }}</div>
                            <div>
                              {{ attachment.name_description }}
                              <span class="key" v-if="attachment.is_installed">
                                <inline-svg
                                  :src="require('@/assets/image/icons/key.svg')"
                                />
                              </span>
                            </div>
                          </div>
                          <div class="title">
                            <div>Options</div>
                            <div>
                              <div v-for="option in tractor.options">
                                <div class="description">
                                  {{ option.name_description }}
                                  <span
                                    class="key"
                                    v-if="
                                      tractor.installationOptionsId.includes(
                                        option.id
                                      )
                                    "
                                  >
                                    <inline-svg
                                      :src="
                                        require('@/assets/image/icons/key.svg')
                                      "
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="title">
                            <div>Quantity</div>
                            <div>{{ tractor.quantity }}</div>
                          </div>
                          <div class="title" v-if="tractor.terms">
                            <div>Terms</div>
                            <div>{{ tractor.terms }}</div>
                          </div>
                          <div class="title" v-if="tractor.notes">
                            <div>Notes</div>
                            <div>{{ tractor.notes }}</div>
                          </div>
                          <div class="title" v-if="tractor.po_number">
                            <div>P.O. Number</div>
                            <div>{{ tractor.po_number }}</div>
                          </div>
                          <div class="title">
                            <div>Retailed</div>
                            <div>
                              {{ tractor.retailed === true ? "Yes" : "No" }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="set">
                      <a
                        class="cursor-pointer"
                        @click="editTractor(tractor, index)"
                        >Edit Item</a
                      >
                      <a
                        class="text-red-500 cursor-pointer"
                        @click="deleteTractor(index)"
                        >Delete Item</a
                      >
                      <a
                        class="cursor-pointer"
                        @click="duplicateTractor(tractor, index)"
                        >Duplicate Item</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div class="pt-6 text-center" v-else>No Items Currently Added.</div>
            <button
              type="button"
              class="btn btn-black w-full"
              v-if="addTractor.length"
              @click="createEditOrder"
              :disabled="disableBtn"
              :class="disableBtn ? 'opacity-50 cursor-wait' : ''"
            >
              Review Order
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../component/PageHeader";
import Dropdown from "../../component/Dropdown";
import ClickOutside from "vue-click-outside";
import { mapActions, mapState } from "vuex";
import { http } from "../../../api";

export default {
  name: "orderTractor",
  data() {
    return {
      disableBtn: false,
      validationReset: true,
      reset: true,
      activeTractor: {},
      tiresSelected: {},
      tractor: {},
      addTractor: [],
      installationOptionsId: [],
      optionsId: [],
      selectAttachments: [],
      tractorOptions: [],
      addTractorFormData: {
        terms: "",
        tire_id: "",
        term_id: "",
        configuration: "",
        configuration_id: "",
        tires: "",
        options: [],
        quantity: 1,
        retailed: false,
        configuration_assembled: false,
        notes: "",
        po_number: "",
        attachments: [],
      },
      formData: {
        dealership_id: this.$route.query.dealership,
        shipping_address_id: this.$route.query.shipping_address,
        items: [],
      },
      tractorEdit: false,
      tractorIndex: "",
    };
  },
  components: {
    PageHeader,
    Dropdown,
  },
  computed: {
    ...mapState({
      secondStep: (state) => state.order.secondStep,
      selectedTractor: (state) => state.order.selectedTractor,
      orderFilters: (state) => state.order.indexFilters,
      allowedEditStatusIds: state => state.order.allowedEditStatusIds,
    }),
    redirect() {
      return {
        name: 'orderDetail',
        params: {id: this.$route.params.id}
      }
    }
  },
  async created() {
    await this.getSecondStep();

    if (!this.$route.params.id) {
      this.isEdit = false;
    } else {
      this.isEdit = true;
      this.editOrder();
    }
  },
  methods: {
    ...mapActions({
      getSecondStep: "order/getSecondStep",
      getSelectedTractor: "order/getSelectedTractor",
      getOrderEdit: "order/getOrderEdit",
      createOrder: "order/createOrder",
      updateOrder: "order/updateOrder",
    }),
    selectInstallationOption(option) {
      let installed = 0;
      if (this.installationOptionsId.includes(option.id)) {
        this.installationOptionsId = this.installationOptionsId.filter(
          (item) => item !== option.id
        );
      } else {
        this.installationOptionsId.push(option.id);
        installed = 1;
      }

      if (this.optionsId.includes(option.id)) {
        this.addTractorFormData.options
          .find(item => item.option_id === option.id )
          .is_installed = installed;
      } else {
        this.optionsId.push(option.id);
        this.tractorOptions.push(option);
        this.addTractorFormData.options.push({
          option_id: option.id,
          is_installed: installed,
        });
      }
    },
    selectAttachment(index) {
      this.addTractorFormData.attachments[index].is_selected =
        !this.addTractorFormData.attachments[index].is_selected;
      if (this.addTractorFormData.attachments[index].is_installed) {
        this.addTractorFormData.attachments[index].is_installed =
          !this.addTractorFormData.attachments[index].is_installed;
      }
      if (
        this.selectAttachments.includes(
          this.addTractorFormData.attachments[index]
        )
      ) {
        this.selectAttachments = this.selectAttachments.filter(
          (item) => item !== this.addTractorFormData.attachments[index]
        );
      } else {
        this.selectAttachments.push(this.addTractorFormData.attachments[index]);
      }
    },
    selectAttachmentIsInstalled(index) {
      this.addTractorFormData.attachments[index].is_installed =
        !this.addTractorFormData.attachments[index].is_installed;
      if (this.addTractorFormData.attachments[index].is_installed) {
        this.addTractorFormData.attachments[index].is_selected = true;
      }
    },
    editOrder() {
      this.getOrderEdit({ id: this.$route.params.id }).then((res) => {


        //ONLY allow drafts to be edited
        if ( !this.allowedEditStatusIds.includes(this.secondStep.order.status.id) ) {
          this.$router.push(this.redirect, () => {
            setTimeout(() => {
              this.$toasted.error('This order is not available for editing');
            }, 200)
          })
        }
        



        // this.formData.dealership_id = this.secondStep.order.dealer.id
        // this.formData.shipping_address_id = this.secondStep.order.shipping_address
        // let tractor = this.secondStep.line_items[0]
        // let arr = this.secondStep.models.filter(item => item.id === tractor.model.id)
        // this.selectTractor(arr[0])
        // this.addTractorFormData.configuration = tractor.configuration ? tractor.configuration.description : null
        // this.addTractorFormData.quantity = tractor.quantity
        // this.addTractorFormData.po_number = tractor.po_number
        // this.addTractorFormData.notes = tractor.notes
        // this.addTractorFormData.tires = tractor.tires ? tractor.tires.name : null
        // this.addTractorFormData.terms = tractor.terms.length ? tractor.terms[0].term : null;
        // this.addTractorFormData.retailed = tractor.retailed
        // this.addTractorFormData.configuration_assembled = tractor.configuration_assembled
        //
        // this.addTractorFormData.attachments = tractor.attachments
        // this.tractorOptions = tractor.options
        // tractor.options.forEach(item => this.optionsId.push(item.id))
        // tractor.options.forEach(item => item.is_installed === 1 ? this.installationOptionsId.push(item.id) : '')
        let tractors = this.secondStep.line_items;

        tractors.forEach((tractor, index) => {
          let installationOptionsId = tractor.options
            .filter(option => option.is_installed === 1)
            .map(option => option.id);

          let optionsId = tractor.options.map(option => option.id);

          this.addTractor.push({
            tractorImg: tractor.img,
            tractorModel: tractor.model,
            tractor: tractor.model?.name,
            tractor_id: tractor.model?.id,
            tire_id: tractor.tires?.id,
            tires: tractor.tires?.name,
            quantity: tractor.quantity,
            po_number: tractor.po_number,
            notes: tractor.notes,
            configuration_assembled: tractor.configuration_assembled,
            configuration: tractor.configuration?.description,
            configuration_id: tractor.configuration?.id,
            term_id: tractor.terms?.[0]?.id,
            terms: tractor.terms?.[0]?.term,
            loader: tractor.loader?.name,
            backhoe: tractor.backhoe?.name,
            attachments: tractor.attachments,
            retailed: tractor.retailed,
            installationOptionsId: installationOptionsId,
            options: tractor.options,
            optionsId: optionsId,
          });
          this.formData.items.push({
            model_id: tractor.model?.id,
            tire_id: tractor.tires?.id,
            quantity: tractor.quantity,
            po_number: tractor.po_number,
            line_no: index,
            configuration_id: tractor.configuration?.id,
            loader_id: tractor.loader?.id,
            backhoe_id: tractor.backhoe?.id,
            term_id: tractor.terms?.[0]?.id,
            options: tractor.options,
            notes: tractor.notes,
            retailed: tractor.retailed,
            attachments: tractor.attachments,
          });
        });
      });
    },
    selectOption(option) {
      if (this.optionsId.includes(option.id)) {
        this.installationOptionsId = this.installationOptionsId.filter(
          (item) => item !== option.id
        );
        this.optionsId = this.optionsId.filter((item) => item !== option.id);
        this.addTractorFormData.options =
          this.addTractorFormData.options.filter((item) => {
            return (item.option_id ?? item.id) !== option.id;
          });
        this.tractorOptions = this.tractorOptions.filter(
          (item) => item.id !== option.id
        );
      } else {
        this.optionsId.push(option.id);
        this.tractorOptions.push(option);
        this.addTractorFormData.options.push({
          option_id: option.id,
          is_installed: 0,
        });
      }
    },
    createEditOrder() {
      this.disableBtn = true;
      if (this.isEdit) {
        this.updateOrder({ id: this.$route.params.id, formData: this.formData })
          .then((res) => {
            this.$router.push("/review-order/" + res.data.data.order.id);
          })
          .catch((err) => {
            this.disableBtn = false;
            this.$toasted.error(err.response.data.message || "Server Error");
          });
      } else {
        this.createOrder(this.formData)
          .then((res) => {
            this.$router.push("/review-order/" + res.data.data.order.id);
          })
          .catch((err) => {
            this.disableBtn = false;
            this.$toasted.error(err.response.data.message || "Server Error");
          });
      }
    },
    addOrder() {
      this.$refs.form
        .validate()
        .then((success) => {
          if (success) {
            this.tractorOptions.forEach((item) =>
              item.description ? (item.name = item.description) : ""
            );
            this.addTractor.unshift({
              tractorImg: this.tractor.img,
              tractorModel: this.tractor,
              tractor: this.tractor.model,
              tractor_id: this.tractor.id,
              tires: this.addTractorFormData.tires,
              tire_id: this.addTractorFormData.tire_id,
              quantity: this.addTractorFormData.quantity,
              po_number: this.addTractorFormData.po_number,
              notes: this.addTractorFormData.notes,
              configuration_id: this.addTractorFormData.configuration_id,
              configuration: this.addTractorFormData.configuration,
              terms: this.addTractorFormData.terms,
              term_id: this.addTractorFormData.term_id,
              attachments: this.addTractorFormData.attachments,
              retailed: this.addTractorFormData.retailed,
              configuration_assembled:
                this.addTractorFormData.configuration_assembled,
              installationOptionsId: this.installationOptionsId,
              options: this.tractorOptions,
              optionsId: this.optionsId,
            });
            this.formData.items.unshift({
              model_id: this.tractor.id,
              tire_id: this.addTractorFormData.tire_id,
              quantity: this.addTractorFormData.quantity,
              po_number: this.addTractorFormData.po_number,
              line_no: 0,
              configuration_id: this.addTractorFormData.configuration_id,
              term_id: this.addTractorFormData.term_id,
              options: [...this.addTractorFormData.options],
              notes: this.addTractorFormData.notes,
              retailed: this.addTractorFormData.retailed,
              configuration_assembled:
                this.addTractorFormData.configuration_assembled,
              attachments: this.addTractorFormData.attachments.filter(
                (attribute) => attribute.is_selected
              ),
            });
            window.scrollTo(0, 0);
            this.validationReset = false;
            this.$toasted.success("Items successfully added to order");
            this.selectTractor({ model: "" });
            this.resetForm();
            console.log(this.formData);
          } else {
            this.$toasted.error("Your submission contains errors");
          }
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        })
        .finally(() => {
          this.validationReset = true;
        });
    },
    editSaveOrder() {
      this.$refs.form
        .validate()
        .then((success) => {
          this.tractorOptions.forEach((option) => {
            if (this.installationOptionsId.includes(option.id)) {
              option.is_installed = 1;
            }
          });
          if (success) {
            this.addTractor[this.tractorIndex] = {
              tractorImg: this.tractor.img,
              tractorModel: this.tractor,
              tractor: this.tractor.model,
              tractor_id: this.tractor.id,
              tires: this.addTractorFormData.tires,
              tire_id: this.addTractorFormData.tire_id,
              quantity: this.addTractorFormData.quantity,
              po_number: this.addTractorFormData.po_number,
              notes: this.addTractorFormData.notes,
              configuration: this.addTractorFormData.configuration,
              configuration_id: this.addTractorFormData.configuration_id,
              terms: this.addTractorFormData.terms,
              term_id: this.addTractorFormData.term_id,
              loader:
                this.selectedTractor.loaders?.[0]?.name,
              backhoe:
                this.selectedTractor.backhoes?.[0]?.name,
              attachments: this.addTractorFormData.attachments,
              retailed: this.addTractorFormData.retailed,
              configuration_assembled:
                this.addTractorFormData.configuration_assembled,
              installationOptionsId: this.installationOptionsId,
              options: this.tractorOptions,
              optionsId: this.optionsId,
            };
            this.formData.items[this.tractorIndex] = {
              model_id: this.tractor.id,
              tire_id: this.addTractorFormData.tire_id,
              quantity: this.addTractorFormData.quantity,
              po_number: this.addTractorFormData.po_number,
              line_no: 0,
              configuration_id: this.addTractorFormData.configuration_id,
              loader_id:
                this.selectedTractor.loaders?.[0]?.id,
              backhoe_id:
                this.selectedTractor.backhoes?.[0]?.id,
              term_id: this.addTractorFormData.term_id,
              options: [...this.addTractorFormData.options],
              notes: this.addTractorFormData.notes,
              retailed: this.addTractorFormData.retailed,
              configuration_assembled:
                this.addTractorFormData.configuration_assembled,
              attachments: this.addTractorFormData.attachments.filter(
                (attribute) => attribute.is_selected
              ),
            };
            window.scrollTo(0, 0);
            this.validationReset = false;
            this.resetForm();
            this.tractorEdit = false;
            console.log(this.formData);
          } else {
            this.$toasted.error("Your submission contains errors");
          }
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        })
        .finally(() => {
          this.validationReset = true;
        });
    },
    async editTractor(item, index) {
      console.log(item);
      this.tractorIndex = index;
      this.tractorEdit = true;
      this.reset = false;
      let model = this.secondStep.models.find(
        model => model.id === item.tractorModel.id
      );

      await this.getSelectedTractor({ id: model?.id })
        .then(() => {
          this.addTractorFormData.attachments = [
            ...this.selectedTractor.loaders,
            ...this.selectedTractor.mowers,
            ...this.selectedTractor.backhoes,
          ];
          this.addTractorFormData.attachments.map((attachment) => {
            item.attachments.map((att) => {
              if (attachment.id === att.id) {
                attachment.is_installed = att.is_installed;
                attachment.is_selected = att.is_selected;
              }
            });
          });
          this.tractorOptions = [...this.selectedTractor.options]
            .filter(option => item.optionsId.includes(option.id));

          console.log('tractor options:');
          console.log(this.tractorOptions);

        }).catch(() => {});

      this.tractor = {...model};
      this.activeTractor = {...model};
      this.addTractorFormData.tire_id = item.tire_id;
      this.addTractorFormData.tires = item.tires;
      this.addTractorFormData.quantity = item.quantity;
      this.addTractorFormData.po_number = item.po_number;
      this.addTractorFormData.notes = item.notes;
      this.addTractorFormData.term_id = item.term_id;
      this.addTractorFormData.terms = item.terms;
      this.addTractorFormData.retailed = item.retailed;
      this.addTractorFormData.configuration_assembled =
        item.configuration_assembled;
      this.addTractorFormData.configuration = item.configuration;
      this.addTractorFormData.configuration_id = item.configuration_id;
      this.addTractorFormData.attachments = item.attachments;
      this.addTractorFormData.options = [...item.options].map(option => ({
        option_id: option.id,
        is_installed: option.is_installed,
      }));
      this.installationOptionsId = item.installationOptionsId;
      this.optionsId = item.optionsId;

      console.log('add tractor form data');
      console.log(this.addTractorFormData);

      this.reset = true;
    },
    deleteTractor(index) {
      this.addTractor = this.addTractor.filter((item, i) => i !== index);
      this.formData.items = this.formData.items.filter(
        (item, i) => i !== index
      );
    },
    duplicateTractor(item, index) {
      let data = item;
      data.configuration_id = item.configuration.id;
      data.term_id = item.configuration.id;
      data.tire_id = item.tires.id;
      data.model_id = item.tractor.id;
      this.addTractor.splice(index + 1, 0, data);
      this.formData.items.splice(index + 1, 0, data);
    },
    selectTractor(tractor) {
      this.activeTractor = {...tractor};
      this.tractor = {...tractor};
      
      this.getSelectedTractor({ id: tractor.id }).then(() => {
        
        this.addTractorFormData.attachments = [
          ...this.selectedTractor.loaders,
          ...this.selectedTractor.mowers,
          ...this.selectedTractor.backhoes,
        ].map(item => ({
          ...item,
          ...(item.default ? {is_installed: item.default} : {})
        }));

        this.selectedTractor.tires.forEach(item => {
          if (item.default) {
            this.selectTires(item);
          }
        });
        this.selectedTractor.configurations.forEach(item => {
          if (item.default) {
            this.selectConfiguration(item);
          }
        });
      });
    },
    resetForm() {
      (this.addTractorFormData = {
        terms: "",
        tire_id: "",
        term_id: "",
        configuration: "",
        tires: "",
        options: [],
        quantity: 1,
        retailed: false,
        configuration_assembled: false,
        notes: "",
        po_number: "",
      }),
        (this.installationOptionsId = []);
      this.optionsId = [];
      this.tractorOptions = [];
      this.activeTractor = {};

      this.$refs.form.reset();
    },
    selectPayment(payment) {
      this.addTractorFormData.terms = payment.term;
      this.addTractorFormData.term_id = payment.id;
    },
    selectConfiguration(configuration) {
      this.addTractorFormData.configuration = configuration.description;
      this.addTractorFormData.configuration_id = configuration.id;
    },
    selectTires(tire) {
      this.addTractorFormData.tires = tire.name_description;
      this.tiresSelected = tire;
      this.addTractorFormData.tire_id = tire.id;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style scoped>
.attachment-name {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 11px !important;
  line-height: 13px !important;
  color: #969696 !important;
  margin-top: 4px;
}
</style>
