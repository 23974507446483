import { http } from '../../../api';

const inventory = {
    namespaced: true,
    state: {
        inventory: [],
        inventoryStatuses: [],
        territoryManagers: [],
        dealerships: [],
        // inventoryDetails: [],
        // createInventory: [],
        pagination: {},
        tractorSns:{},
        initialPage: 1
    },
    actions: {
        getFields: ({commit}, params) => {
            return http.get('field-inventory/fields')
                .then(res => {
                    commit('setInventoryStatuses', res.data.data.inventoryStatuses);
                    commit('setTerritoryManagers', res.data.data.territoryManagers);
                    commit('setDealerships', res.data.data.dealerships);
                    return res;
                });
        },
        getInventory: ({ commit }, params) => {
            return http.get('field-inventory?page=' + params.page)
                .then(res => {
                    commit('setInventory', res.data.data.inventory.data);
                    commit('setPagination', res.data.data.inventory.pagination);
                    // commit('setInventoryStatuses', res.data.data.inventoryStatuses);
                    // commit('setTerritoryManagers', res.data.data.territoryManagers);
                    // commit('setDealerships', res.data.data.dealerships);
                    return res;
                });
        },
        filterInventory: ({ commit }, params) => {
            const q = new URLSearchParams(params);
            return http.get('field-inventory/filter?' + q.toString()).then(res => {
                commit('setInventory', res.data.data.inventory.data);
                commit('setPagination', res.data.data.inventory.pagination);
                return res;
            });
        },
        // // getCreateRegistrations: ({commit},params)=>{
        // //     return http.get('registrations/create',params).then(res => {
        // //         commit('setCreateRegistrations', res.data.data)
        // //     })
        // // },
        // // deleteWarranty: ({commit},params)=>{
        // //     return http.delete('registrations/'+ params.id).then(res => {
        // //         commit('setDeleteWarranty', params.id);
        // //         return res;
        // //     })
        // // },
        // getRegistrationTractorSn: ({commit},params)=>{
        //     return http.get('tractor-sn?dealership_id=' + params.id).then(res => {
        //         commit('setTractorSn', res.data.data.tractor_sns)
        //     })
        // },
        // getRegistrationsDetails: ({commit},params)=>{
        //     return http.get('registrations/' + params.id).then(res => {
        //         commit('setRegistrationsDetails', res.data.data)
        //     })
        // },
        // createNewTractor: ({commit},params)=>{
        //     return http.post('registrations',params).then(res => {
        //         return res.data.data
        //     })
        // },
        // updateRegistration: ({commit},params)=>{
        //     return http.post('registrations/' + params.id, params.formData).then(res => {
        //     })
        // },
        getInitialPage: ({ commit }, params) => {
            commit('setInitialPage', params)
        },
    },
    mutations: {
        setInitialPage(state, page) {
            state.initialPage = page
        },
        // setDeleteWarranty(state, id) {
        //     state.registrations = state.registrations.filter(el => el.id !== id )
        // },
        // setTractorSn(state, data) {
        //     state.tractorSns = data;
        // },
        setTerritoryManagers(state, data) {
            state.territoryManagers = data;
        },
        // setRegistrationsDetails(state, data) {
        //     state.registrationsDetails = data.registration;
        //     state.registrationStatus = data.registrationStatus;
        // },
        // setCreateRegistrations(state, data) {
        //     state.createRegistrations = data;
        // },
        setInventory(state, data) {
            state.inventory = data;
        },
        setPagination(state, data) {
            state.pagination = data;
        },
        setInventoryStatuses(state, data) {
            state.inventoryStatuses = data;
        },
        setDealerships(state, data) {
            state.dealerships = data;
        },
    },
    getters: {
        inventory: state =>  state.inventory,
        // inventoryStatus: state =>  state.inventoryStatus,
        dealerships: state =>  state.dealerships,
        pagination: state =>  state.pagination,
        inventoryStatuses: state =>  state.inventoryStatuses,
        // createRegistrations: state =>  state.createRegistrations,
        tractorSns: state =>  state.tractorSns,
        // registrationsDetails: state =>  state.registrationsDetails,
        territoryManagers: state =>  state.territoryManagers,
        initialPage: state =>  state.initialPage,
    }
};

export default inventory;
