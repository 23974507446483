import { http } from '../../../api';

const dashboard = {
    namespaced: true,
    state: {
        dealers: [],
        warrantyClaims: [],
        territoryManagers: [],
        fieldInventory: [],
        warrantyRegistrations: {},
    },
    actions: {
        getDashboard: ({ commit }, params) => {
            return http.get('/dashboard',params).then(res => {
                commit('setDashboardData', res.data.data)
            });
        }
    },
    mutations: {
        setDashboardData(state, data){
            state.dealers = data.dealers;
            state.warrantyRegistrations = data.warrantyRegistrations;
            state.fieldInventory = data.fieldInventory;
            state.territoryManagers = data.territoryManagers;
            state.warrantyClaims = data.warrantyClaims;
        },
    },
    getters: {
        dealers: state => state.dealers,
        fieldInventory: state => state.fieldInventory,
        warrantyClaims: state => state.warrantyClaims,
        territoryManagers: state => state.territoryManagers,
        warrantyRegistrations: state => state.warrantyRegistrations
    }
};

export default dashboard;
