import { http } from '../../../api';

const mediaCategory = {
    namespaced: true,
    state: {
        mediaCategories: [],
        types: [],
        createdCategory: {},
        categoryItem: {},
        pagination: {},
        initialPage: 1
    },
    actions: {
        getMediaCategories: ({ commit }, params) => {
            const q = new URLSearchParams(params);
            return http.get('/categories?' + q.toString()).then(res => {
                commit('setMediaCategoriesData', res.data.data)
            });
        },
        filterCategories: ({ commit }, params) => {
            const q = new URLSearchParams(params);
            return http.get('categories/filter?' + q.toString()).then(res => {
                commit('setCategories', res.data.data)
            });
        },
        removeCategory: ({ commit }, params) => {
            return http.delete('/categories/' + params.id).then(res => {
                return res;
            });
        },
        getCategoryItem: ({ commit }, params) => {
            return http.get('/categories/' + params.id).then(res => {
                commit('setCategoryItem', res.data.data);
                return res.data.data;
            });
        },
        createCategory: ({ commit }, params) => {
            return http.post('/categories', params).then(res => {
                return res;
            });
        },
        createCategoryData: ({ commit }, params) => {
            return http.get('/categories/create', params).then(res => {
                commit('setCreateCategory', res.data.data)
            });
        },
        editCategoryData: ({ commit }, params) => {
            return http.get('/categories/' + params.id + '/edit').then(res => {
                commit('setCreateCategory', res.data.data)
            });
        },
        updateCategory: ({ commit }, params) => {
            return http.post('/categories/' + params.id, params.formData).then(res => {
                return res;
            });
        },
        getInitialPage: ({ commit }, params) => {
            commit('setInitialPage', params)
        },
    },
    mutations: {
        setInitialPage(state, page) {
            state.initialPage = page
        },
        setCreateCategory(state, data) {
            state.createdCategory = data.category
            state.types = data.types;
        },
        setMediaCategoriesData(state, data){
            state.mediaCategories = data.categories.data;
            state.pagination = data.categories.pagination;
            state.types = data.types;
        },
        setCategories(state, data){
            state.mediaCategories = data.categories.data;
            state.pagination = data.categories.pagination;
        },
        setCategoryItem(state, data){
            state.categoryItem = data.category;
        },
    },
    getters: {
        mediaCategories: state => state.mediaCategories,
        types: state => state.types,
        pagination: state => state.pagination,
        categoryItem: state => state.categoryItem,
        createdCategory: state => state.createdMedia,
        initialPage: state => state.initialPage,
    }
};

export default mediaCategory;
