<template>
    <div class="auth">
        <div class="container">
            <div class="logo">
                <img
                    class="m-auto"
                    :src="require('@/assets/image/logo.png')"
                    alt="Tym-Tractors"
                    :srcset="`${require('@/assets/image/logo.png')},
                            ${require('@/assets/image/logo@2x.png')} 2x`"
                />
            </div>
           <div class="panel">
               <div class="panel-heading">Reset Password: {{ email }}</div>
               <ValidationObserver tag="div" class="panel-body" ref="form">
                   <form class="edit-form" @submit.prevent="submit">
                       <div class="form-group">
                            <label class="control-label">Password</label>
                            <ValidationProvider
                                rules="required|password:@confirm|min:6"
                                tag="div"
                                v-slot="{ classes }"
                            >
                               <input type="password" v-model="formData.password" autocomplete="off" name="password" value="" :class="classes" ref="password">
                            </ValidationProvider>
                       </div>
                       <div class="form-group mt-4">
                           <label class="control-label ">Confirm Password</label>
                           <ValidationProvider
                                vid="confirm"
                                rules="required"
                                tag="div"
                                v-slot="{ classes }"
                            >
                               <input type="password" v-model="formData.password_confirmation" autocomplete="off" name="password_confirmation" value="" :class="classes">
                           </ValidationProvider>
                       </div>
                       <div class="form-group">
                           <div class="formBtn">
                               <button type="submit" class="btn">Submit</button>
                               <router-link to="/login" class="note">Return to Log In</router-link>
                           </div>
                       </div>
                        <div v-if="loading" class="text-center mt-3">
                            <Loading position="center" />
                        </div>
                        <div v-else class="mt-2">
                            <div class="error-msg" v-if="errorMessage">
                                <p>{{errorMessage}}</p>
                            </div>
                            <div class="success-msg" v-if="successMessage">
                                <p>{{successMessage}}</p>
                            </div>
                        </div>
                   </form>
               </ValidationObserver>
           </div>
        </div>
    </div>
</template>
<script>

import {mapActions} from 'vuex'
import Loading from '../../../component/Loading.vue';

export default {
    name: 'ResetPassword',
    data () {
        return {
            formData:{
                password:'',
                password_confirmation:'',
            },
            email: null,
            token: null,


            errorMessage: null,
            successMessage: null,
            loading: false,
        }
    },
    created() {
        this.email = this.$route.query.email;
        this.token = this.$route.query.token;
    },
    methods: {
        ...mapActions({
            resetPassword: 'auth/resetPassword',
        }),
        submit(){
            this.errorMessage = null;
            this.successMessage = null;
            this.loading = true;

            this.$refs.form.validate()
                .then(success => {
                    if (success) {
                        this.resetPassword({
                            ...this.formData,
                            email: this.email,
                            token: this.token,
                        }).then((res) => {
                            this.successMessage = res.data?.message ?? "Password successfully reset";
                        }).catch(err => {
                            this.errorMessage = err.message ?? "An error occurred";
                        }).finally(() => {
                            this.loading = false;
                        });
                    } else {
                        this.loading = false;
                        if (this.formData.password != this.formData.password_confirmation) {
                            this.errorMessage = "Passwords do not match";
                        } else {
                            this.errorMessage = "Password must be at least 6 characters :)";
                        }
                    }
                });
        },
    },
    components: {
        Loading
    }
}

</script>
