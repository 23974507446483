import { http } from '../../../api';

const claim = {
    namespaced: true,
    state: {
        claims: [],
        initialPage: 1,
        oldClaimInitialPage: 1,
        pagination: {},
        dealerships: [],
        claimStatuses: [],
        tractorSn: [],
        originalClaim: {},
        claimCreate: {},
        oldClaims: [],
        oldClaimsPagination: {},
        oldClaimStatuses: [],
        oldClaimDealerships: [],
        oldClaimItem: {},
        lpOptions: [],

        indexFilters: {},
        oldClaimIndexFilters: {},
    },
    actions: {
        getClaimsDealerships: ({commit}, params) => {
            return http.get('claims').then(res => {
                commit('setDealerships', res.data.data.dealerships);
                return res;
            });
        },
        getClaims: ({ commit }, params) => {
            return http.get('claims?page=' + params.page).then(res => {
                commit('setClaims', res.data.data.claims.data)
                commit('setPagination', res.data.data.claims.pagination)
                commit('setClaimStatuses', res.data.data.claimStatuses)
                commit('setDealerships', res.data.data.dealerships)
                return res;
            });
        },
        getLP: ({ commit }, params) => {
            return http.get('claims/invoice/lp').then(res =>{
                commit('setLP', res.data.data.invoice_parts);
                return res.data.data.invoice_parts;
            })
        },
        filterClaims: ({ commit }, params) => {
            const q = new URLSearchParams(params);
            return http.get('claims/filter?' + q.toString()).then(res => {
              commit('setClaims', res.data.data.claims.data)
              commit('setPagination', res.data.data.claims.pagination)
              return res;
          });
        },
        deleteClaimById: ({commit},params)=>{
            return http.delete('claims/' + params.id).then(res => {
                commit('setDeleteClaim', params.id);
                return res;
            })
        },
        viewOriginalClaim: ({commit},params)=>{
            return http.get('claims/originals/' + params.id).then(res => {
                commit('setOriginalClaim', res.data.data);
            })
        },
        getClaimCreate: ({commit},params)=>{
            return http.get('claims/create', {params}).then(res => {
                commit('setClaimCreate', res.data.data);
            })
        },
        getTractorSn: ({commit},params)=>{
            const q = new URLSearchParams(params);
            return http.get('claims/tractor-sn?' + q.toString()).then(res => {
                commit('setTractorSn', res.data.data);
            })
        },
        addNewClaim: ({commit},params)=>{
            return http.post('claims',params).then(res => {
            })
        },
        updateClaim: ({commit},params)=>{
            return http.post('claims/'+params.id,params.formData).then(res => {
            })
        },
        saveDraftClaim: ({commit},params)=>{
            return http.post('claims/draft',params.formData).then(res => {
            })
        },
        getOldClaims: ({ commit }, params) => {
            return http.get('old-claims?page=' + params.page).then(res => {
                commit('setOldClaims', res.data.data)
            });
        },
        filterOldClaims: ({ commit }, params) => {
            const q = new URLSearchParams(params);
            return http.get('old-claims/filter?' + q.toString()).then(res => {
                commit('setFilterOldClaims', res.data.data)
            });
        },
        getOldClaimItem: ({ commit }, params) => {
            return http.get('old-claims/' + params.id).then(res => {
                commit('setOldClaimItem', res.data.data)
            });
        },
        getInitialPage: ({ commit }, params) => {
            commit('setInitialPage', params)
        },
        getOldClaimInitialPage: ({ commit }, params) => {
            commit('setOldClaimInitialPage', params)
        },

        setIndexFilters: ({commit}, params) => {
            commit('setIndexFilters', {...params});
        },
        setOldClaimIndexFilters: ({commit}, params) => {
            commit('setOldClaimIndexFilters', {...params});
        },
    },
    mutations: {
        setDeleteClaim(state, id) {
            state.claims = state.claims.filter(el => el.id !== id )
        },
        setInitialPage(state, page) {
            state.initialPage = page
        },
        setOldClaimInitialPage(state, page) {
            state.oldClaimInitialPage = page
        },
        setClaims(state, data) {
            state.claims = data;
        },
        setOldClaims(state,data){
            state.oldClaims = data.claims.data;
            state.oldClaimsPagination = data.claims.pagination;
            state.oldClaimStatuses = data.claimStatuses;
            state.oldClaimDealerships = data.dealerships;
        },
        setOldClaimItem(state,data){
            state.oldClaimItem = data.claim
        },
        setFilterOldClaims(state,data){
            state.oldClaims = data.claims.data;
            state.oldClaimsPagination = data.claims.pagination;
        },
        setTractorSn(state, data) {
            state.tractorSn = data;
        },
        setClaimCreate(state, data) {
            state.claimCreate = data;
        },
        setOriginalClaim(state, data) {
            state.originalClaim = data;
        },
        setDealerships(state, data) {
            state.dealerships = data;
        },
        setPagination(state, data) {
            state.pagination = data;
        },
        setClaimStatuses(state, data) {
            state.claimStatuses = data;
        },
        setIndexFilters(state, data) {
            state.indexFilters = data;
        },
        setOldClaimIndexFilters(state, data) {
            state.oldClaimIndexFilters = data;
        },
        setLP(state, data) {
            state.lpOptions = data;
        }
    },
    getters: {
        claims: state =>  state.claims,
        dealerships: state =>  state.dealerships,
        pagination: state =>  state.pagination,
        claimStatuses: state =>  state.claimStatuses,
        originalClaim: state =>  state.originalClaim,
        initialPage: state =>  state.initialPage,
        oldClaimInitialPage: state =>  state.oldClaimInitialPage,
        indexFilters: state => state.indexFilters,
        oldClaimIndexFilters: state => state.oldClaimIndexFilters,
        lpOptions: state => state.lpOptions,
    }
};

export default claim;
