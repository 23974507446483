import { http } from '../../../api';

const coops = {
    namespaced: true,
    state: {
        coops:[],
        dealerships:[],
        statuses:[],
        initialPage: 1,
        pagination:{},
    },
    actions: {
        getCoops: ({ commit }, params) => {
            return http.get('coops?page=' + params.page).then(res => {
                commit('setCoops', res.data.data)
          });
        },
        filterCoops: ({ commit }, params) => {
            const q = new URLSearchParams(params);
            return http.get('coops/filter?' + q.toString()).then(res => {
                commit('setCoopsFilter', res.data.data)
            });
        },
        removeCoop: ({ commit }, params) => {
            return http.delete('coops/' + params.id).then(res => {
                commit('deleteCoop', params.id)
                return res;
            });
        },
        getInitialPage: ({ commit }, params) => {
            commit('setInitialPage', params)
        },
    },
    mutations: {
        setInitialPage(state, page) {
            state.initialPage = page
        },
        deleteCoop(state, id) {
            state.coops = state.coops.filter(item => id !== item.id)
        },
        setCoops(state, data) {
            state.coops = data.coops.data
            state.pagination = data.coops.pagination
            state.dealerships = data.dealerships
            state.statuses = data.statuses
        },
        setCoopsFilter(state, data) {
            state.coops = data.coops.data
            state.pagination = data.coops.pagination
        },
    },
    getters: {
        coops: state =>  state.coops,
        dealerships: state =>  state.dealerships,
        statuses: state =>  state.statuses,
        pagination: state =>  state.pagination,
        initialPage: state =>  state.initialPage
    }
};

export default coops;
