import { http } from '../../../api';

const manuals = {
    namespaced: true,
    state: {
        manuals:[],
        types:[],
    },
    actions: {
        getManuals: ({ commit }, params) => {
            const q = new URLSearchParams(params);
            return http.get('manuals?' + q.toString()).then(res => {
                commit('setManuals', res.data.data)
            });
        },
    },
    mutations: {
        setManuals(state, data) {
            state.manuals = data.manuals
            state.types = data.types
        },
    },
    getters: {
        manuals: state =>  state.manuals,
        types: state =>  state.types,
        pagination: state =>  state.pagination
    }
};

export default manuals;
