<template>
  <div
    class="dropdown-settings relative"
    v-click-outside="hide"
    v-if="item.item.program_batch_id || userCanEditPreapproval(item)"
  >
    <button
      aria-label="actions-menu"
      type="button"
      class="button btn-td-hover"
      :class="openClose ? 'active' : ''"
      @click="openClose = !openClose"
    >
      <inline-svg
        class="m-auto"
        :src="require('@/assets/image/icons/more-alt.svg')"
      />
    </button>
    <div class="dropdown" v-if="openClose">
      <router-link
        tag="div"
        v-if="item.item.program_batch_id"
        :to="`/retail-programs/${item.item.program_batch_id}`"
      >
        View Batch Submission
      </router-link>
      <router-link
        tag="div"
        :to="{ name: 'ProgramPreapprovalEdit', params: { id: item.item.id } }"
        v-else-if="userCanEditPreapproval(item)"
      >
        Edit Preapproval
      </router-link>

      <div
        class="delete"
        tag="div"
        v-if="userCanDelete(item)"
        @click="
          hide();
          showDeleteConfirmation = true;
        "
      >
        Delete
      </div>
    </div>

    <ConfirmModal
      :text="`Are you sure you want to delete the ${item.item.program_name} preapproval for SN: ${item.item.serial}?`"
      confirmBtn="Delete"
      :show="showDeleteConfirmation"
      @confirm="deletePreapproval"
      @close="showDeleteConfirmation = false"
    />
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import { mapActions, mapState } from "vuex";
import ConfirmModal from "../ConfirmModal";

export default {
  name: "ProgramPreapprovalsTableDropdown",
  props: ["item"],
  components: {},
  data() {
    return {
      openClose: false,
      showDeleteConfirmation: false,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    userCanViewRegistrations: function () {
      return (
        this.user.permissions.includes("GLOBAL") ||
        this.user.permissions.includes("REG_VIEW")
      );
    },
  },
  created() {
    console.log(this.item);
  },
  methods: {
    ...mapActions({
      getPreapprovals: "programPreapprovals/getPreapprovals",
      deleteProgramPreapproval: "programPreapprovals/deletePreapproval",
    }),
    hide() {
      this.openClose = false;
    },
    userCanEditPreapproval: function (item) {
      return (
        this.user.permissions.includes("GLOBAL") ||
        this.user.permission_actions?.["Program Preapproval"]?.Edit
      );
    },
    userCanDelete: function (item) {
      return (
        item.item.status.toLowerCase() === "unclaimed" &&
        this.userCanEditPreapproval(item)
      );
    },
    deletePreapproval() {
      this.deleteProgramPreapproval({ id: this.item.item.id })
        .then((res) => {
          this.getPreapprovals({ page: this.item.page });
          this.$toasted.success(res.data.message);
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        })
        .finally(() => {
          this.showDeleteConfirmation = false;
          this.openClose = false;
        });
    },
  },
  components: {
    ConfirmModal,
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style lang="scss" scoped>
</style>
