<template>
  <section class="claim view-original col-span-12">
    <div class="container">
      <PageHeader
        backText="Back to Claims"
        :backRoute="{ name: 'Claims' }"
        :saveButton="false"
        :discardLink="false"
        :details="{
          title:
            '#' +
            (originalClaim.claim_details && originalClaim.claim_details.id),
          status: 'Original',
          date:
            originalClaim.claim_details &&
            originalClaim.claim_details.failure_date,
          fields: {
            'Primary S/N': originalClaim.tractor_details.tractor_sn || '',
          },
        }"
      >
        <template v-slot:actions>
          <button type="button" class="btn btn-info px-40" @click="viewCurrent">
            View Current
          </button>
          <button type="button" class="btn btn-info px-40" @click="printClaim">
            Print
          </button>
        </template>
      </PageHeader>
      <div class="details-block">
        <div class="flex-1">
          <div class="subtitle">Tractor Details</div>
          <div class="details">
            <div
              class="details-inform"
              :class="
                originalClaim.tractor_details.owner_name ||
                originalClaim.tractor_details.owner_address ||
                originalClaim.tractor_details.owner_phone ||
                originalClaim.tractor_details.dealership
                  ? 'details-mr'
                  : ''
              "
            >
              <div v-if="originalClaim.tractor_details.owner_name">
                <div>Customer Name</div>
                <span>{{ originalClaim.tractor_details.owner_name }}</span>
              </div>
              <div v-if="originalClaim.tractor_details.owner_address">
                <div>Address</div>
                <span>{{ originalClaim.tractor_details.owner_address }}</span>
              </div>
              <div v-if="originalClaim.tractor_details.owner_phone">
                <div>Phone Number</div>
                <span>{{ originalClaim.tractor_details.owner_phone }}</span>
              </div>
              <div
                v-if="
                  originalClaim.tractor_details.dealership &&
                  originalClaim.tractor_details.dealership.name
                "
              >
                <div>Retail Dealership</div>
                <span>{{ originalClaim.tractor_details.dealership.name }}</span>
              </div>
            </div>
            <div class="details-inform">
              <div>
                <div>Primary S/N</div>
                <span>{{ originalClaim.tractor_details.tractor_sn }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-1">
          <div class="subtitle">Claim Details</div>
          <div class="details">
            <div class="details-mr details-inform">
              <div>
                <div>Dealer Placing Claim</div>
                <span
                  v-if="
                    originalClaim.claim_details &&
                    originalClaim.claim_details.delaer
                  "
                  >{{ originalClaim.claim_details.delaer.name }}</span
                >
              </div>
              <div>
                <div>Cause Code</div>
                <span>{{ originalClaim.claim_details.cause_code }}</span>
              </div>
              <div>
                <div>Failure Date</div>
                <span>{{ originalClaim.claim_details.failure_date }}</span>
              </div>
              <div>
                <div>Repair Date</div>
                <span>{{ originalClaim.claim_details.repair_date }}</span>
              </div>
            </div>
            <div class="details-inform">
              <div>
                <div>Hours Used</div>
                <span>{{ originalClaim.claim_details.hours_used }}</span>
              </div>
              <div>
                <div>Warranty Administrators Name</div>
                <span>{{
                  originalClaim.claim_details.administrator_name
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="table-block">
        <div class="subtitle">Labor & Part Details</div>
        <div class="tab" v-if="originalClaim.labor_parts_description.labors">
          <div class="tables">
            <div class="table">
              <div class="data-table-head">
                <div class="table-head-container">
                  <div class="tr">
                    <div class="th text-center">Labor Code</div>
                    <div class="th text-center">Labor Time</div>
                    <div class="th text-center">Labor Cost</div>
                    <div class="th text-center">Labor Total</div>
                    <div class="th text-center">Invoice #</div>
                    <div class="th text-center">Part #</div>
                    <div class="th text-center">Part Description</div>
                    <div class="th text-center">Per Part Cost</div>
                    <div class="th text-center">Quantity</div>
                    <div class="th text-center">Parts Total</div>
                  </div>
                </div>
              </div>
              <div class="tbody">
                <div
                  v-if="
                    originalClaim.labor_parts_description.labors.length == 0
                  "
                  class="text-center no-result"
                >
                  <span
                    >No results found. Please adjust your search criteria.</span
                  >
                </div>
                <div
                  class="tr"
                  v-for="(labor, i) in originalClaim.labor_parts_description
                    .labors"
                  :class="labor.parts.length > 1 ? 'i-start' : ''"
                  :key="i"
                >
                  <div class="td">
                    {{ labor.value }}
                  </div>
                  <div class="td">
                    {{ labor.hours }}
                  </div>
                  <div class="td">${{ labor.cost }}</div>
                  <div class="td">${{ labor.total }}</div>
                  <div class="td-part">
                    <div class="part" v-for="part in labor.parts">
                      <div class="td">
                        {{ part.invoice }}
                      </div>
                      <div class="td">
                        {{ part.part }}
                      </div>
                      <div class="td">
                        {{ part.partDescription }}
                      </div>
                      <div class="td">${{ part.partPrice }}</div>
                      <div class="td">
                        {{ part.qty.toFixed(2) }}
                      </div>
                      <div class="td">${{ part.total }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="claim-totals">
          <div class="totals">
            <div>
              <div class="flex justify-between pt-3 pl-4 pr-4">
                <div>Labor Total</div>
                <div>
                  ${{ originalClaim.labor_parts_description.labor_subtotal }}
                </div>
              </div>
              <div class="flex justify-between pt-3 pl-4 pr-4">
                <div>Parts Total</div>
                <div>
                  ${{ originalClaim.labor_parts_description.parts_subtotal }}
                </div>
              </div>
            </div>
            <div class="total">
              <div class="flex justify-between pt-3 pl-4 pr-4">
                <div>Total</div>
                <div>${{ originalClaim.labor_parts_description.total }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="description-block"
        :class="
          originalClaim &&
          originalClaim.activity_history &&
          originalClaim.activity_history.length === 0
            ? 'mb-20'
            : ''
        "
      >
        <div>
          <div class="description">
            <div class="subtitle">Description of Failure</div>
            <div class="text" v-if="originalClaim.description_of_failre">
              {{ originalClaim.description_of_failre }}
            </div>
            <div class="text" v-else>No Description of Failure</div>
          </div>
          <div class="description">
            <div class="subtitle">Description of Repair</div>
            <div class="text" v-if="originalClaim.description_of_repair">
              {{ originalClaim.description_of_repair }}
            </div>
            <div class="text" v-else>No Description of Repair</div>
          </div>
          <div class="description">
            <div class="subtitle">Admin Notes</div>
            <div class="text" v-if="originalClaim.admin_notes">
              {{ originalClaim.admin_notes }}
            </div>
            <div class="text" v-else>No Notes</div>
          </div>
        </div>
        <div class="export-group">
          <div class="subtitle">File Attachments</div>
          <div
            class="button-group d-block"
            v-if="originalClaim.files && originalClaim.files.length"
          >
            <button
              class="button"
              v-for="file in originalClaim.files"
              :disabled="disableFileDownload.includes(file.id)"
              @click="downloadClaimFile(file)"
            >
              <div class="flex">
                <div class="file-name">
                  {{
                    file.slug
                      .replace(/\s+/g, "_")
                      .match(/.*(?=\.[^.]*$)/)[0]
                      .substr(0, 20)
                  }}
                </div>
                .{{ file.slug.match(/[^\.]+$/)[0] }}
              </div>
              <inline-svg
                v-if="!disableFileDownload.includes(file.id)"
                :src="require('@/assets/image/icons/download.svg')"
              />
              <loading
                class="loading-dovnload"
                v-if="disableFileDownload.includes(file.id)"
              />
            </button>
          </div>
          <div v-else>No File</div>
        </div>
      </div>
      <div
        class="activity-history"
        v-if="
          originalClaim &&
          originalClaim.activity_history &&
          originalClaim.activity_history.length !== 0
        "
      >
        <div class="subtitle">Claim Activity History</div>
        <div class="info-block">
          <div class="info" v-for="history in originalClaim.activity_history">
            <inline-svg :src="require('@/assets/image/icons/history.svg')" />
            <p v-if="history.created_by">{{ history.created_by.email }}</p>
            &#160; {{ history.description }} on {{ history.created_at }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../component/PageHeader";
import { mapActions, mapState } from "vuex";
import DataTable from "../../component/DataTable";
import { http } from "@/api";
import Loading from "../../component/Loading";

export default {
  name: "ViewOriginalClaim",
  data() {
    return {
      disableFileDownload: [],
      background: "",
      color: "",
      border: "",
      a: 0,
      viewOriginalClaimTable: {
        header: [
          { field: "", name: "#", showIndex: true },
          { field: "invoice_no", name: "Inv #" },
          { field: "part_no", name: "Part #" },
          { field: "part_description", name: "Part Description" },
          {
            transformField: "part_cost",
            transform: this.transformToCost,
            field: "part_cost",
            name: "Per Part Cost",
          },
          { field: "quantity", name: "Quantity" },
          { field: "labor_code", name: "Labor Code" },
          { field: "labor_time", name: "Labor Time" },
          {
            transformField: "labor_cost",
            transform: this.transformToCost,
            field: "labor_cost",
            name: "Line Cost",
          },
        ],
      },
    };
  },
  components: {
    PageHeader,
    DataTable,
    Loading,
  },
  computed: {
    ...mapState({
      originalClaim: (state) => state.claim.originalClaim,
    }),
  },
  mounted() {
    this.getOriginalClaim();
  },
  methods: {
    ...mapActions({
      viewOriginalClaim: "claim/viewOriginalClaim",
    }),
    transformToCost(id, val) {
      val = val + "";
      if (val.indexOf(".") == -1) {
        val = val + ".00";
      }
      let arr = val.split(".");
      if (arr[0].length > 3) {
        arr[0] =
          arr[0].substr(0, arr[0].length - 3) +
          "," +
          arr[0].substr(arr[0].length - 3, arr[0].length);
      }
      if (arr[1].length > 2) {
        let a = arr[1].length - 3;
        let b = 10;
        for (let i = 0; i < a; i++) {
          b *= 10;
        }
        arr[1] = Math.round(arr[1] / b);
      }
      if (arr[1].length === 1) {
        arr[1] = arr[1] + "0";
      }
      val = arr.join(".");
      return "$" + val;
    },
    getOriginalClaim() {
      this.viewOriginalClaim({ id: this.$route.params.id }).then((res) => {
        this.transformStatus();
      });
    },
    transformStatus() {
      switch (this.originalClaim.status.id) {
        case 6:
          this.background = "rgba(205, 216, 29, 0.2)";
          this.color = "#7c8110";
          this.border = "none";
          break;
        case 5:
          this.background = "rgba(29, 184, 216, 0.2)";
          this.color = "#0B4067";
          this.border = "none";
          break;
        case 4:
          this.background = "#F9F9F9";
          this.color = "#606060";
          this.border = "1px solid #606060";
          break;
        case 3:
          this.background = "rgba(215, 173, 65, 0.4)";
          this.color = "#5C4E00";
          this.border = "none";
          break;
        case 2:
          this.background = "rgba(212, 4, 4, 0.2)";
          this.color = "#7D0505";
          this.border = "none";
          break;
        case 1:
          this.background = "rgba(99, 220, 43, 0.3)";
          this.color = "#24610E";
          this.border = "none";
          break;
      }
    },
    viewCurrent() {
      this.$router.push("/claims/" + this.$route.params.id);
    },
    printClaim() {
      http
        .get("claims/" + this.$route.params.id + "/pdf", {
          responseType: "blob",
        })
        .then((res) => {
          let blob = new Blob([res.data], { type: "application/pdf" });
          var fileURL = URL.createObjectURL(blob);
          window.open(fileURL);
        });
    },
    downloadClaimFile(file) {
      this.disableFileDownload.push(file.id);
      let url = file.file;
      let name = file.slug;
      http
        .get(url, {
          responseType: "blob",
        })
        .then((res) => {
          let blob = new Blob([res.data], {
            type: res.headers["content-type"],
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = name;
          link.click();
        })
        .catch((err) => {
          this.$toasted.error("Download failed");
        })
        .finally(() => {
          this.disableFileDownload = this.disableFileDownload.filter(
            (id) => id !== file.id
          );
        });
    },
  },
};
</script>
