<template>
  <section
    class="
      warranty-registrations
      col-span-12
      sm:col-span-12
      md:col-span-12
      lg:col-span-12
      xl:col-span-12
    "
  >
    <div class="container grid grid-cols-1 gap-0">
      <PageHeader
        title="Registrations"
        :backLink="false"
        :discardLink="false"
        :saveButton="false"
        :addButton="userCanCreate"
        addText="Add Registration"
        @add="$router.push({ name: 'RegistrationsAdd' })"
      >
        <template v-slot:actions>
          <button
            class="btn btn-info px-15"
            @click="exportExcel"
            :disabled="disableExportExcel"
            v-if="user && userCanExport"
          >
            <loading v-if="disableExportExcel" />
            <div class="text mr-3"><span>Export</span></div>
            <div class="export">
              <inline-svg :src="require('@/assets/image/icons/download.svg')" />
            </div>
          </button>
        </template>
      </PageHeader>
      <div class="filter-block">
        <div>
          <div class="table-filter-search-input">
            <div class="form-group">
              <label class="control-label">Search</label>
              <input
                v-model="filterData.searchVal"
                type="text"
                name="search"
                value=""
                class="search"
                v-on:keyup.enter="applyFilter"
                placeholder="Tractor S/N or Owner Name"
              />
              <inline-svg
                class="search-icon"
                :src="require('@/assets/image/icons/search.svg')"
              />
            </div>
          </div>

          <div class="table-filter-dealership-select" v-if="user.role.id !== 3">
            <dropdown
              :data="dealerships"
              :status="false"
              fieldName="name"
              label="Dealer"
              @select="selectDealership"
              :validation="true"
              :all="true"
              :search="true"
              placeholder="Dealer"
              inputName="dealership"
              :editValue="selectedDealership"
              :placeholder="'Deur Implement'"
            ></dropdown>
          </div>
          <div class="table-filter-status-select">
            <dropdown
              :data="registrationStatuses"
              :status="true"
              fieldName="status"
              label="Status"
              @select="selectStatus"
              :validation="false"
              inputName="status"
              :editValue="selectedStatus"
            ></dropdown>
          </div>

          <div class="form-group">
            <div>
              <label class="control-label">Registered Date Range</label>
              <div class="relative">
                <functional-calendar
                  :placeholder="' '"
                  v-model="calendarData"
                  :sundayStart="true"
                  :date-format="'mm/dd/yyyy'"
                  :is-modal="true"
                  :is-date-range="true"
                  :is-multiple="true"
                  :calendars-count="2"
                  :change-year-function="true"
                ></functional-calendar>
                <inline-svg
                  class="date-down"
                  :src="require('@/assets/image/icons/date-down.svg')"
                />
                <span class="dash">-</span>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div
            class="btn btn-black cursor-pointer px-40"
            @click.prevent="applyFilter"
          >
            <div class="text"><a>Apply</a></div>
          </div>
          <div class="btn redo px-15" @click.prevent="resetFilter">
            <a>
              <inline-svg :src="require('@/assets/image/icons/redo.svg')" />
            </a>
          </div>
        </div>
      </div>
      <div
        class="
          table-block
          col-span-1
          sm:col-span-1
          md:col-span-1
          lg:col-span-1
          xl:col-span-1
        "
      >
        <data-table
          :page="filterData.page"
          :header="fieldRegistrationsTable.header"
          :data="registrations"
          :link="{ url: '/registrations/', name: 'primary_sn' }"
          :isLoading="isLoading"
        />
      </div>
      <div class="pages" v-if="pagination.total && pagination.total_pages > 1">
        <div class="pages-quantity">
          <template v-if="!isLoading">
            Page {{ filterData.page }} of {{ pagination.total_pages }} - Showing
            {{ pagination.per_page }} Results of {{ pagination.total }} Total
          </template>
        </div>
        <paginate
          :page-count="pagination.total_pages"
          :page-range="4"
          :margin-pages="1"
          :initial-page="initialPage - 1"
          :click-handler="clickCallback"
          :prev-text="'&laquo;'"
          :next-text="'&raquo;'"
          :container-class="'pages-btn'"
          :active-class="pageActive"
        >
        </paginate>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../component/PageHeader";
import DataTable from "../../component/DataTable";
import Dropdown from "../../component/Dropdown";
import Loading from "../../component/Loading";
import { FunctionalCalendar } from "vue-functional-calendar";
import { http } from "../../../api";
import { mapActions, mapState } from "vuex";
import ClickOutside from "vue-click-outside";

export default {
  name: "WarrantyRegistrations",
  data() {
    return {
      baseUrl: "/registrations",
      isLoading: false,
      pageActive: "active",
      calendarData: {
        dateRange: {
          start: "",
          end: "",
        },
      },
      date: "",
      disableExportExcel: false,
      selectedDealership: "All",
      selectedStatus: "All",
      filterData: {
        page: 1,
      },

      fieldRegistrationsTable: {
        header: [
          {
            transformField: "primary_sn",
            name: "Primary SN",
            width: "15%",
            headerWidth: "15%",
            transformToComponent: "TransformToLink",
          },
          {
            field: "owner_name",
            name: "Owner Name",
            width: "17%",
            headerWidth: "17%",
          },
          {
            field: "dealership",
            name: "Dealership",
            width: "18%",
            headerWidth: "18%",
          },
          {
            field: "date_registered",
            name: "Date Registered",
            width: "12%",
            headerWidth: "12%",
          },
          {
            field: "sale_date",
            name: "Retail Sale Date",
            width: "12%",
            headerWidth: "12%",
          },
          {
            field: "program_claimed",
            name: "Programs",
            width: "11%",
            headerWidth: "11%",
          },
          {
            field: "status.name",
            name: "Status",
            transformField: "status.id",
            transform: this.transformToStatus,
            width: "9%",
            headerWidth: "9%",
          },
          {
            transformField: "id",
            name: "Actions",
            transformToComponent: "RegistrationsTableDropdown",
            width: "6%",
            headerWidth: "6%",
          },
        ],
      },
    };
  },
  mounted() {
    if (this.$route.query.page) {
      if (this.$route.query.dealership_id) {
        let dealershipName = this.dealerships.filter(
          (item) => item.id == this.$route.query.dealership_id
        );
        this.selectedDealership = dealershipName[0].name;
        this.filterData.dealership_id = this.$route.query.dealership_id;
      }
      if (this.$route.query.page) {
        this.filterData.page = +this.$route.query.page;
        this.pageActive = "active";
      }
      if (this.$route.query.searchVal) {
        this.filterData.searchVal = this.$route.query.searchVal;
      }
      if (this.$route.query.status_id) {
        this.filterData.status_id = this.$route.query.status_id;
        let status = this.registrationStatuses.filter(
          (item) => item.id == this.$route.query.status_id
        );
        this.selectedStatus = status[0].status;
      }
      if (this.$route.query.start_claim) {
        this.calendarData.dateRange.start = this.$route.query.start_claim;
        this.filterData.start_claim = this.$route.query.start_claim;
      }
      if (this.$route.query.end_claim) {
        this.calendarData.dateRange.end = this.$route.query.end_claim;
        this.filterData.end_claim = this.$route.query.end_claim;
      }
      this.isLoading = true;
      this.filterRegistrations(this.filterData).finally(() => {
        this.isLoading = false;
      });
    } else {
      this.getRegistrationsList().then(() => {
        if (this.$route.query.dealer) {
          this.dealerFilter();
        }
        if (this.$route.query.tm) {
          this.tmFilter();
        }
      });
    }
  },
  components: {
    PageHeader,
    Loading,
    DataTable,
    FunctionalCalendar,
    Dropdown,
  },
  computed: {
    ...mapState({
      registrations: (state) =>
        state.registration.registrations.map((r) => ({
          ...r,
          program_claimed: r.program_claimed ? "Claimed" : "Unclaimed",
        })),
      pagination: (state) => state.registration.pagination,
      dealerships: (state) => state.registration.dealerships,
      registrationStatuses: (state) => state.registration.registrationStatuses,
      territoryManagers: (state) => state.registration.territoryManagers,
      user: (state) => state.auth.user,
      initialPage: (state) => state.registration.initialPage,
    }),
    userCanCreate: function () {
      return (
        this.user.permissions.includes("GLOBAL") ||
        this.user.permissions.includes("REG_CREATE")
      );
    },
    userCanExport: function () {
      return (
        this.user.permissions.includes("GLOBAL") ||
        this.user.permissions.includes("REG_EXP")
      );
    },
  },
  methods: {
    ...mapActions({
      getRegistrations: "registration/getRegistrations",
      filterRegistrations: "registration/filterRegistrations",
      getInitialPage: "registration/getInitialPage",
      setFilterState: "registration/setIndexFilters",
    }),
    exportExcel() {
      if (!this.disableExportExcel) {
        this.disableExportExcel = true;
        const q = new URLSearchParams(this.filterData);
        http
          .get("registrations/export?" + q.toString(), {
            responseType: "blob",
          })
          .then((res) => {
            let blob = new Blob([res.data]);
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "registrations.xlsx";
            link.click();
          })
          .catch((err) => {
            this.$toasted.error("Registrations Export failed");
          })
          .finally(() => {
            this.disableExportExcel = false;
          });
      }
    },
    selectStatus(status) {
      if (status.id) {
        this.filterData.status_id = status.id;
      } else {
        delete this.filterData.status_id;
      }
      this.selectedStatus = status.status;
      this.applyFilter();
    },
    selectDealership(dealership) {
      //avoid multiple submissions:
      if (this.selectDealerWaiting) return;
      this.selectDealerWaiting = true;
      setTimeout(() => {
        this.selectDealerWaiting = false;
      }, 50);
      //-------------------------------//

      this.selectedDealership = dealership.name;
      if (dealership.id || dealership.id === 0) {
        this.filterData.dealership_id = dealership.id;
      } else {
        delete this.filterData.dealership_id;
      }
      this.applyFilter();
    },
    dealerFilter() {
      let dealershipName = this.dealerships.filter(
        (item) => item.id == this.$route.query.dealer
      );
      this.selectedDealership = dealershipName[0].name;
      this.filterData.dealership_id = this.$route.query.dealer;
      this.isLoading = true;
      this.filterRegistrations(this.filterData)
        .then((res) => {})
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    tmFilter() {
      let tm = this.territoryManagers.filter(
        (item) => item.id == this.$route.query.tm
      );
      this.filterData.tm_id = this.$route.query.tm;
      this.isLoading = true;
      this.filterRegistrations(this.filterData)
        .then((res) => {})
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    resetFilter() {
      this.pageActive = "";
      this.selectedDealership = "All";
      this.selectedStatus = "All";
      this.filterData = {
        page: 1,
      };
      if (this.calendarData.dateRange) {
        this.calendarData.dateRange.end = "";
        this.calendarData.dateRange.start = "";
      }
      this.getInitialPage(1);
      this.applyFilter();
    },
    applyFilter() {
      this.pageActive = "";
      if (this.calendarData.dateRange != null) {
        if (this.calendarData.dateRange.start) {
          this.filterData.start_claim = this.calendarData.dateRange.start;
        }
        if (this.calendarData.dateRange.end) {
          this.filterData.end_claim = this.calendarData.dateRange.end;
        }
      }
      this.filterData.page = 1;
      this.updateRouter();

      this.isLoading = true;
      this.filterRegistrations(this.filterData)
        .then((res) => {})
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    getRegistrationsList() {
      this.filterData.page = this.initialPage;

      this.isLoading = true;
      return this.getRegistrations({ page: this.filterData.page })
        .then((res) => {
          console.log(res);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getDotColor(status) {
      switch (status) {
        case 1:
          return "#A7CBE5";
        case 2:
          return "#EA9797";
        case 3:
          return "#B1D69F";
        case 5:
          return "#EBDBB0";
        case 6:
          return "#606060";
      }
    },
    transformToStatus(id, val) {
      let background;
      let border;
      let color;
      let width;
      color = "#0B4067";
      switch (id) {
        case 1:
          background = "rgba(99, 220, 43, 0.3)";
          color = "#24610E";
          border = "none";
          width = "77px";
          break;
        case 2:
          background = "rgba(212, 4, 4, 0.2)";
          color = "#7D0505";
          border = "none";
          width = "77px";
          break;
        case 3:
          background = "rgba(215, 173, 65, 0.4)";
          color = "#5C4E00";
          border = "none";
          width = "67px";
          break;
        case 4:
          background = "#F9F9F9";
          color = "#606060";
          border = "1px solid #606060";
          width = "62px";
          break;
        case 5:
          background = "rgba(29, 184, 216, 0.2)";
          color = "#0B4067";
          border = "none";
          width = "95px";
          break;
        case 6:
          background = "rgba(212, 4, 4, 0.2)";
          color = "#7d0b67";
          border = "none";
          width = "100px";
          break;
      }
      return (
        '<div class="td-status" style="background-color:' +
        background +
        ";color:" +
        color +
        ";border:" +
        border +
        ";width:" +
        width +
        '">' +
        val +
        "</div>"
      );
    },
    clickCallback(pageNum) {
      this.filterData.page = pageNum;
      this.getInitialPage(pageNum);
      if (this.calendarData.dateRange != null) {
        if (this.calendarData.dateRange.start) {
          this.filterData.start_claim = this.calendarData.dateRange.start;
        }
        if (this.calendarData.dateRange.end) {
          this.filterData.end_claim = this.calendarData.dateRange.end;
        }
      }
      this.updateRouter();

      this.isLoading = true;
      this.filterRegistrations(this.filterData)
        .then((res) => {})
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
      this.pageActive = "active";
    },

    updateRouter(saveState = true) {
      this.$router.push(
        this.baseUrl + "?" + new URLSearchParams(this.filterData).toString()
      );
      if (saveState) this.setFilterState(this.filterData);
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style scoped>
</style>
