<template>
  <div class="dropdown-settings relative">
    <button
      aria-label="actions-menu"
      type="button"
      class="button btn-td-hover"
      :class="openClose ? 'active' : ''"
      v-click-outside="hide"
      @click="openClose = !openClose"
    >
      <inline-svg
        class="m-auto"
        :src="require('@/assets/image/icons/more-alt.svg')"
      />
    </button>
    <div class="dropdown" v-if="openClose">
      <router-link tag="div" :to="`/admin/media/categories/` + item.item.id"
        >View</router-link
      >
      <router-link
        tag="div"
        :to="`/admin/media/categorie/` + item.item.id + '/edit'"
        >Edit</router-link
      >
      <div
        class="delete"
        v-if="item.item.status === 'Active'"
        @click="openConfirmModal"
      >
        Delete
      </div>
      <div class="text-green-400" v-else @click="openConfirmModalRestored">
        Restored
      </div>
    </div>
    <ConfirmModal
      text="Are you sure you want to delete this category?"
      confirmBtn="Delete category"
      :show="showDeleteCategoryModal"
      @confirm="deleteRestoredCategory"
      @close="closeConfirmModal"
    />
    <ConfirmModal
      text="Are you sure you want to restored this category?"
      confirmBtn="Restored category"
      button="restored"
      :show="showRestoredCategoryModal"
      @confirm="deleteRestoredCategory"
      @close="closeConfirmModal"
    />
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import { mapActions, mapState } from "vuex";
import ConfirmModal from "../ConfirmModal";

export default {
  name: "MediaCategoryDropdownSettings",
  props: ["item"],
  data() {
    return {
      openClose: false,
      showDeleteCategoryModal: false,
      showRestoredCategoryModal: false,
    };
  },
  components: {
    ConfirmModal,
  },
  methods: {
    ...mapActions({
      removeCategory: "mediaCategory/removeCategory",
      getMediaCategories: "mediaCategory/getMediaCategories",
    }),
    openConfirmModal() {
      this.showDeleteCategoryModal = true;
    },
    openConfirmModalRestored() {
      this.showRestoredCategoryModal = true;
    },
    deleteRestoredCategory() {
      this.removeCategory({ id: this.item.item.id })
        .then((res) => {
          this.getMediaCategories({ page: this.item.page });
          this.$toasted.success(res.data.message);
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        })
        .finally(() => {
          this.closeConfirmModal();
        });
    },
    closeConfirmModal() {
      this.showDeleteCategoryModal = false;
      this.showRestoredCategoryModal = false;
    },
    hide() {
      this.openClose = false;
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>