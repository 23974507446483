import axios from 'axios';
import store from '../store';

export const baseURL = process.env.VUE_APP_WEB_API;

const http = axios.create({
    baseURL,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
    },
});

//attach access token to all requsts
http.interceptors.request.use((config) => {
    config.headers = { ...config.headers };
    if (localStorage.access_token) {
        config.headers.Authorization = `Bearer ${localStorage.access_token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

//store updated user data in vuex on every api call
http.interceptors.response.use(res => {
    if (res?.data?.user && localStorage.getItem('vuex')) {
        store.commit('auth/setUserData', res.data.user);
    }
    if (res?.data?.hasOwnProperty('isAuthenticated')) {
        store.commit('auth/setAuthenticated', res.data.isAuthenticated);
    }
    return res;
}, error => {
    if (error.response?.data?.hasOwnProperty('isAuthenticated') && !error.response.data.isAuthenticated) {
        store.commit('auth/userLogout', true);
    }
    throw error;
});


export {
    http,
};
