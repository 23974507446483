import { http } from '../../../api';

const order = {
    namespaced: true,
    state: {
        allowedEditStatusIds: [3],
        orders: [],
        pagination: {},
        orderStatuses: [],
        dealerships: [],
        territoryManager: [],
        createOrder: [],
        secondStep: [],
        selectedTractor: {},

        indexFilters: {}
    },
    actions: {
        getOrders: ({commit}, params) => {
            const q = new URLSearchParams(params);
            return http.get('orders?' + q.toString())
                .then(res => {
                    commit('setOrders', res.data.data.orders.data);
                    commit('setPagination', res.data.data.orders.pagination);
                    commit('setOrderStatuses', res.data.data.status);
                    commit('setDealerships', res.data.data.dealer);
                    commit('setTerritoryManager', res.data.data.territory_managers);
                });
        },
        deleteOrderById: ({commit}, params) => {
            return http.delete('orders/' + params.id).then(res => {
                commit('setDeleteOrder', params.id);
                return res;
            });
        },
        getCreateOrder: ({commit}, params) => {
            return http.get('orders/create', params).then(res => {
                commit('setCreateOrder', res.data.data);
            });
        },
        getSecondStep: ({commit}, params) => {
            return http.get('orders/create/second-step', params).then(res => {
                commit('setSecondStep', res.data.data);
            });
        },
        getOrderEdit: ({commit}, params) => {
            return http.get('orders/'  + params.id + '/edit').then(res => {
                commit('setOrderEdit', res.data.data);
            });
        },
        getSelectedTractor: ({commit}, params) => {
            return http.get('orders/tractors/' + params.id).then(res => {
                commit('setSelectedTractor', res.data.data.asset);
                return res
            });
        },
        approveOrder: ({commit}, params) => {
            return http.get('orders/' + params+ '/approve').then(res => {
                return res
            });
        },
        createOrder: ({commit}, params) => {
            return http.post('orders',params).then(res => {
                return res;
            });
        },
        updateOrder: ({commit}, params) => {
            return http.post('orders/' + params.id , params.formData).then(res => {
                return res;
            });
        },
        confirmOrder: ({commit}, params) => {
            return http.get('orders/' + params.id + '/confirm').then(res => {
            });
        },
        setIndexFilters: ({commit}, params) => {
            commit('setIndexFilters', {...params});
        },
    },
    mutations: {
        setDeleteOrder(state, id) {
            state.orders = state.orders.filter(el => el.id !== id )
        },
        setCreateOrder(state, data) {
            state.createOrder = data;
        },
        setSelectedTractor(state, data) {
            state.selectedTractor = data;
        },
        setSecondStep(state, data) {
            state.secondStep = data;
        },
        setOrderEdit(state, data) {
            state.secondStep = data;
        },
        setOrders(state, data) {
            state.orders = data;
        },
        setPagination(state, data) {
            state.pagination = data;
        },
        setOrderStatuses(state, data) {
            state.orderStatuses = data;
        },
        setDealerships(state, data) {
            state.dealerships = data;
        },
        setTerritoryManager(state, data) {
            state.territoryManager = data;
        },
        setIndexFilters(state, data) {
            state.indexFilters = data;
        }
    },
    getters: {
        orders: state =>  state.orders,
        dealerships: state =>  state.dealerships,
        pagination: state =>  state.pagination,
        orderStatuses: state =>  state.orderStatuses,
        territoryManager: state =>  state.territoryManager,
        createOrder: state =>  state.createOrder,
        secondStep: state =>  state.secondStep,
        selectedTractor: state =>  state.selectedTractor,
        indexFilters: state => state.indexFilters,
        allowedEditStatusIds: state => state.allowedEditStatusIds,
    }
};

export default order;
