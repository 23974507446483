<template>
  <section
    class="
      claims
      col-span-12
      sm:col-span-12
      md:col-span-12
      lg:col-span-12
      xl:col-span-12
    "
  >
    <div class="container grid grid-cols-1 gap-0">
      <PageHeader
        title="Claims"
        :backLink="false"
        :discardLink="false"
        :saveButton="false"
        :addButton="userCanCreate"
        addText="Add New Claim"
        @add="$router.push({ name: 'CreateClaim' })"
      >
        <template v-slot:actions>
          <button
            :readonly="disableClaimExport"
            type="button"
            class="btn btn-info mr-3 px-15"
            @click="claimExport"
            v-if="userCanExport"
          >
            <loading v-if="disableClaimExport" />
            <div class="text mr-3"><span>Claims Export</span></div>
            <div class="export">
              <inline-svg :src="require('@/assets/image/icons/download.svg')" />
            </div>
          </button>
          <button
            :readonly="disableClaimPartsExport"
            @click="claimPartsExport"
            class="btn btn-info mr-3 px-15"
            v-if="userCanExport"
          >
            <loading v-if="disableClaimPartsExport" />
            <div class="text mr-3"><span>Parts Export</span></div>
            <div class="export">
              <inline-svg :src="require('@/assets/image/icons/download.svg')" />
            </div>
          </button>
        </template>
      </PageHeader>
      <div class="filter-block">
        <div>
          <div class="table-filter-search-input">
            <div class="form-group">
              <label class="control-label">Search</label>
              <input
                type="text"
                v-model="filterData.searchVal"
                name="search"
                value=""
                v-on:keyup.enter="applyFilter"
                class="search"
                placeholder="S/N or Claim #"
              />
              <inline-svg
                class="search-icon"
                :src="require('@/assets/image/icons/search.svg')"
              />
            </div>
          </div>
          <div
            class="table-filter-dealership-select"
            v-if="user.role.id !== 3 || user.permissions.includes('GLOBAL')"
          >
            <dropdown
              :data="dealerships"
              :status="false"
              fieldName="name"
              label="Dealership"
              @select="selectDealership"
              :validation="true"
              :all="true"
              :search="true"
              placeholder="Dealership"
              inputName="dealership"
              :editValue="selectedDealership"
            ></dropdown>
          </div>
          <div class="table-filter-status-select">
            <dropdown
              :data="claimStatuses"
              :status="true"
              fieldName="status"
              label="Status"
              @select="selectStatus"
              :validation="false"
              inputName="status"
              :editValue="selectedStatus"
            ></dropdown>
          </div>
          <div class="form-group mr-3">
            <div>
              <label class="control-label">Claim Date</label>
              <functional-calendar
                :placeholder="' '"
                v-model="calendarData"
                :sundayStart="true"
                :date-format="'mm/dd/yyyy'"
                :is-modal="true"
                :is-date-range="true"
                :is-multiple="true"
                :calendars-count="2"
                :change-year-function="true"
              ></functional-calendar>
              <inline-svg
                class="date-down"
                :src="require('@/assets/image/icons/date-down.svg')"
              />
              <span class="dash">-</span>
            </div>
          </div>
          <div class="table-filter-status-select">
            <div class="form-group">
              <label class="control-label">Start Claim Range</label>
              <input
                type="number"
                min="1"
                :max="maxClaimRange"
                v-model="filterData.start_claim_range"
                name="start_claim_range"
                v-on:keyup.enter="applyFilter"
              />
            </div>
          </div>
          <div class="table-filter-status-select">
            <div class="form-group">
              <label class="control-label">End Claim Range</label>
              <input
                type="number"
                :min="
                  filterData.start_claim_range
                    ? filterData.start_claim_range
                    : 1
                "
                :max="maxClaimRange"
                v-model="filterData.end_claim_range"
                name="end_claim_range"
                v-on:keyup.enter="applyFilter"
              />
            </div>
          </div>
        </div>
        <div class="card">
          <div class="btn btn-black px-40" @click.prevent="applyFilter">
            <div class="text">
              <a href="#">Apply</a>
            </div>
          </div>
          <div class="btn redo px-15" @click.prevent="resetFilter">
            <a>
              <inline-svg :src="require('@/assets/image/icons/redo.svg')" />
            </a>
          </div>
        </div>
      </div>
      <div
        class="
          table-block
          col-span-1
          sm:col-span-1
          md:col-span-1
          lg:col-span-1
          xl:col-span-1
        "
      >
        <data-table
          v-bind:page="filterData.page"
          :data="claims"
          :header="fieldClaimsTable.header"
          :link="{ url: '/claims/', name: 'id' }"
          :isLoading="isLoading"
        />
      </div>
      <div class="pages" v-if="pagination.total && pagination.total_pages > 1">
        <div class="pages-quantity">
          <template v-if="!isLoading">
            Page {{ filterData.page }} of {{ pagination.total_pages }} - Showing
            {{ pagination.per_page }} Results of {{ pagination.total }} Total
          </template>
        </div>
        <paginate
          :page-count="pagination.total_pages"
          :page-range="screenWidth < 468 ? 3 : 6"
          :margin-pages="1"
          :initial-page="initialPage - 1"
          :click-handler="clickCallback"
          :prev-text="'&laquo;'"
          :next-text="'&raquo;'"
          :container-class="'pages-btn'"
          :active-class="pageActive"
        >
        </paginate>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from "../../component/PageHeader";
import DataTable from "../../component/DataTable";
import Dropdown from "../../component/Dropdown";
import Loading from "../../component/Loading";
import { FunctionalCalendar } from "vue-functional-calendar";
import { mapActions, mapState } from "vuex";
import ClickOutside from "vue-click-outside";
import { http } from "../../../api";

export default {
  name: "Claims",
  data() {
    return {
      baseUrl: "/claims",
      isLoading: false,
      screenWidth: 0,
      pageActive: "active",
      calendarData: {
        dateRange: {
          start: "",
          end: "",
        },
      },
      maxClaimRange: 12357,
      disableClaimExport: false,
      disableClaimPartsExport: false,
      selectedDealership: "All",
      selectedStatus: "All",
      fieldClaimsTable: {
        header: [
          {
            field: "id",
            name: "Claim #",
            transformToComponent: "TransformToLink",
          },
          { field: "date", name: "Claim Date" },
          { field: "primary_sn", name: "Serial #" },
          {
            field: "dealership",
            name: "Dealership",
            width: "25%",
            headerWidth: "19%",
          },
          {
            transformField: "original_cost",
            transform: this.transformToCost,
            field: "original_cost",
            name: "Original $",
          },
          {
            transformField: "current_cost",
            transform: this.transformToCost,
            field: "current_cost",
            name: "Current $",
            headerWidth: "14%",
          },
          { field: "last_update", name: "Last Update", headerWidth: "13%" },
          {
            field: "status.name",
            name: "Status",
            transformField: "status.id",
            transform: this.transformToStatus,
            width: "17%",
            headerWidth: "15%",
          },
          {
            transformField: "id",
            name: "Actions",
            transformToComponent: "ClaimsTableDropdown",
          },
        ],
      },
      filterData: {
        page: 1,
      },
    };
  },
  computed: {
    ...mapState({
      claimStatuses: (state) => state.claim.claimStatuses,
      dealerships: (state) => state.claim.dealerships,
      claims: (state) => state.claim.claims,
      pagination: (state) => state.claim.pagination,
      user: (state) => state.auth.user,
      initialPage: (state) => state.claim.initialPage,
    }),
    userCanCreate: function () {
      if (!this.user) return false;
      return (
        this.user.permissions.includes("GLOBAL") ||
        this.user.permissions.includes("CLAIM_CREATE")
      );
    },
    userCanExport: function () {
      if (!this.user) return false;
      return (
        this.user.permissions.includes("GLOBAL") ||
        this.user.permissions.includes("CLAIM_EXP")
      );
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    if (this.$route.query.page) {
      this.getClaimsDealerships();
      if (this.$route.query.dealership_id) {
        let dealershipName = this.dealerships.filter(
          (item) => item.id == this.$route.query.dealership_id
        );
        this.selectedDealership = dealershipName[0].name;
        this.filterData.dealership_id = this.$route.query.dealership_id;
      }
      if (this.$route.query.page) {
        this.filterData.page = +this.$route.query.page;
        this.pageActive = "active";
      }
      if (this.$route.query.start_claim_range) {
        this.filterData.start_claim_range =
          +this.$route.query.start_claim_range;
      }
      if (this.$route.query.end_claim_range) {
        this.filterData.end_claim_range = +this.$route.query.end_claim_range;
      }
      if (this.$route.query.searchVal) {
        this.filterData.searchVal = this.$route.query.searchVal;
      }
      if (this.$route.query.status_id) {
        this.filterData.status_id = this.$route.query.status_id;
        let status = this.claimStatuses.filter(
          (item) => item.id == this.$route.query.status_id
        );
        this.selectedStatus = status[0].status;
      }
      if (this.$route.query.start_claim) {
        this.calendarData.dateRange.start = this.$route.query.start_claim;
        this.filterData.start_claim = this.$route.query.start_claim;
      }
      if (this.$route.query.end_claim) {
        this.calendarData.dateRange.end = this.$route.query.end_claim;
        this.filterData.end_claim = this.$route.query.end_claim;
      }
      this.filterClaims(this.filterData);
    } else {
      this.getClaimsList();
    }
  },
  components: {
    PageHeader,
    DataTable,
    Loading,
    FunctionalCalendar,
    Dropdown,
  },
  methods: {
    ...mapActions({
      getClaims: "claim/getClaims",
      getClaimsDealerships: "claim/getClaimsDealerships",
      filterClaims: "claim/filterClaims",
      getInitialPage: "claim/getInitialPage",
      setFilterState: "claim/setIndexFilters",
    }),
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
    claimExport(e) {
      this.disableClaimExport = true;
      e.preventDefault();
      const q = new URLSearchParams(this.filterData);
      http
        .get("claims/export?" + q.toString(), {
          responseType: "blob",
        })
        .then((res) => {
          let blob = new Blob([res.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "claim.xls";
          link.click();
        })
        .catch((err) => {
          this.$toasted.error("Claim Export failed");
        })
        .finally(() => {
          this.disableClaimExport = false;
        });
    },
    claimPartsExport(e) {
      this.disableClaimPartsExport = true;
      e.preventDefault();
      const q = new URLSearchParams(this.filterData);
      http
        .get("claims/export-parts?" + q.toString(), {
          responseType: "blob",
        })
        .then((res) => {
          let blob = new Blob([res.data]);
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "claim-parts.xls";
          link.click();
        })
        .catch((err) => {
          this.$toasted.error("Claim Parts Export failed");
        })
        .finally(() => {
          this.disableClaimPartsExport = false;
        });
    },
    selectStatus(status) {
      this.selectedStatus = status.status;
      if (status.id) {
        this.filterData.status_id = status.id;
      } else {
        delete this.filterData.status_id;
      }
      this.applyFilter();
    },
    transformToCost(id, val) {
      if (val === null) {
        return "";
      }
      val = val + "";
      if (val.indexOf(".") == -1) {
        val = val + ".00";
      }
      let arr = val.split(".");
      if (arr[0].length > 3) {
        arr[0] =
          arr[0].substr(0, arr[0].length - 3) +
          "," +
          arr[0].substr(arr[0].length - 3, arr[0].length);
      }
      if (arr[1].length > 2) {
        let a = arr[1].length - 3;
        let b = 10;
        for (let i = 0; i < a; i++) {
          b *= 10;
        }
        arr[1] = Math.round(arr[1] / b);
      }
      if (arr[1].length === 1) {
        arr[1] = arr[1] + "0";
      }
      if (arr[1].length === 1) {
        arr[1] = arr[1] + "0";
      }
      val = arr.join(".");
      return "$" + val;
    },
    selectDealership(dealership) {
      //avoid multiple submissions:
      if (this.selectDealerWaiting) return;
      this.selectDealerWaiting = true;
      setTimeout(() => {
        this.selectDealerWaiting = false;
      }, 50);
      //-------------------------------//

      this.selectedDealership = dealership.name;
      if (dealership.id || dealership.id === 0) {
        this.filterData.dealership_id = dealership.id;
      } else {
        delete this.filterData.dealership_id;
      }
      this.applyFilter();
    },
    resetFilter() {
      this.pageActive = "";
      this.selectedDealership = "All";
      this.selectedStatus = "All";
      this.filterData = {
        page: 1,
      };
      if (this.calendarData.dateRange) {
        this.calendarData.dateRange.end = "";
        this.calendarData.dateRange.start = "";
      }
      this.getInitialPage(1);
      this.applyFilter();
    },
    applyFilter() {
      this.pageActive = "";
      if (this.calendarData.dateRange != null) {
        if (this.calendarData.dateRange.start) {
          this.filterData.start_claim = this.calendarData.dateRange.start;
        }
        if (this.calendarData.dateRange.end) {
          this.filterData.end_claim = this.calendarData.dateRange.end;
        }
      }
      this.filterData.page = 1;
      this.updateRouter();

      this.isLoading = true;
      this.filterClaims(this.filterData)
        .then((res) => {})
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    transformToStatus(id, val) {
      let background;
      let border;
      let color;
      let width;
      color = "#0B4067";
      switch (id) {
        case 1:
          background = "rgba(99, 220, 43, 0.3)";
          color = "#24610E";
          border = "none";
          width = "77px";
          break;
        case 2:
          background = "rgba(212, 4, 4, 0.2)";
          color = "#7D0505";
          border = "none";
          width = "77px";
          break;
        case 3:
          background = "rgba(215, 173, 65, 0.4)";
          color = "#5C4E00";
          border = "none";
          width = "67px";
          break;
        case 4:
          background = "#F9F9F9";
          color = "#606060";
          border = "1px solid #606060";
          width = "62px";
          break;
        case 5:
          background = "rgba(29, 184, 216, 0.2)";
          color = "#0B4067";
          border = "none";
          width = "95px";
          break;
        case 6:
          background = "#F9F9F9";
          color = "#606060";
          background = "rgba(212, 4, 4, 0.2)";
          color = "#7d0b67";
          border = "none";
          width = "100px";
          break;
      }
      return (
        '<div class="td-status" style="background-color:' +
        background +
        ";color:" +
        color +
        ";border:" +
        border +
        ";width:" +
        width +
        '">' +
        val +
        "</div>"
      );
    },
    getClaimsList() {
      this.filterData.page = this.initialPage;
      this.isLoading = true;
      this.getClaims({ page: this.filterData.page })
        .then((res) => {})
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    clickCallback(pageNum) {
      this.filterData.page = pageNum;
      this.getInitialPage(pageNum);
      this.pageActive = "active";
      if (this.calendarData.dateRange != null) {
        if (this.calendarData.dateRange.start) {
          this.filterData.start_claim = this.calendarData.dateRange.start;
        }
        if (this.calendarData.dateRange.end) {
          this.filterData.end_claim = this.calendarData.dateRange.end;
        }
      }
      this.updateRouter();

      this.isLoading = true;
      this.filterClaims(this.filterData)
        .then((res) => {})
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },

    updateRouter(saveState = true) {
      this.$router.push(
        this.baseUrl + "?" + new URLSearchParams(this.filterData).toString()
      );
      if (saveState) this.setFilterState(this.filterData);
    },
  },
  directives: {
    ClickOutside,
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
