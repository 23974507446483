<template>
  <div class="dropdown-settings relative">
    <button
      aria-label="actions-menu"
      type="button"
      class="button btn-td-hover"
      :class="openClose ? 'active' : ''"
      v-click-outside="hide"
      @click="openClose = !openClose"
    >
      <inline-svg
        class="m-auto"
        :src="require('@/assets/image/icons/more-alt.svg')"
      />
    </button>
    <div class="dropdown" v-if="openClose">
      <router-link
        v-if="order.item.access.show"
        tag="div"
        :to="`/orders/` + order.item.id"
        >View</router-link
      >
      <router-link
        v-if="order.item.access.edit"
        tag="div"
        :to="`/order/` + order.item.id + '/edit'"
        >Edit</router-link
      >
      <div
        @click="approve"
        v-if="order && order.item.status.id !== 1 && user && user.role.id === 1"
      >
        Approve Order
      </div>
      <div
        class="delete"
        @click="showDeleteOrderModal = true"
        v-if="order.item.access.delete"
      >
        Delete
      </div>
    </div>
    <ConfirmModal
      text="Are you sure you want to delete this order?"
      confirmBtn="Delete order"
      :show="showDeleteOrderModal"
      @confirm="deleteOrder"
      @close="showDeleteOrderModal = false"
    />
  </div>
</template>

<script>
import ClickOutside from "vue-click-outside";
import { mapActions, mapState } from "vuex";
import ConfirmModal from "../ConfirmModal";

export default {
  name: "OrdersTableDropdown",
  props: ["order"],
  data() {
    return {
      openClose: false,
      showDeleteOrderModal: false,
    };
  },
  components: {
    ConfirmModal,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      allowedEditStatusIds: state => state.order.allowedEditStatusIds,
    }),
    // userCanDelete: function () {
    //   // deprecated - using item.access.delete instead
    //   return (
    //     this.user.permissions.includes("GLOBAL") ||
    //     this.user.permissions.includes("ORDER_DEST")
    //   );
    // },
  },
  methods: {
    ...mapActions({
      deleteOrderById: "order/deleteOrderById",
      getOrders: "order/getOrders",
      approveOrder: "order/approveOrder",
    }),
    hide() {
      this.openClose = false;
    },
    approve() {
      this.approveOrder(this.order.item.id).then((res) => {
        this.getOrders({ page: this.order.page }).then((res) => {
          this.$toasted.success(
            "Order " + this.order.item.id + " approved successfully."
          );
        });
      });
    },
    deleteOrder() {
      this.deleteOrderById({ id: this.order.item.id })
        .then((res) => {
          this.getOrders({ page: this.order.page });
          this.$toasted.success(res.data.message);
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        })
        .finally(() => {
          this.showDeleteOrderModal = false;
        });
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>
