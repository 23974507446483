<template>
  <section class="add-user col-span-12">
    <ValidationObserver
      @submit.prevent="saveDealer"
      tag="form"
      class="container"
      ref="form"
    >
      <PageHeader
        :title="isEdit ? 'Edit Dealer' : 'Add Dealer'"
        backText="Back to Manage Dealers"
        :backRoute="{ name: 'ManageDealers' }"
        saveText="Save Dealer"
        @save="saveDealer"
        @discard="showDiscardModal = true"
        :buttonsDisabled="buttonDisabled"
      />
      <div class="flex">

        <div class="user-details">
          <div class="subtitle">Enter Dealer Details</div>
          <div class="c-callout">
            <div class="form-group w-full">
              <label class="control-label">Name</label>
              <ValidationProvider
                rules="required"
                tag="div"
                v-slot="{ classes }"
              >
                <input
                  :class="classes"
                  v-model="formData.name"
                  type="text"
                  name="name"
                  placeholder="Name Here"
                />
              </ValidationProvider>
            </div>
            <div class="form-group w-full">
              <label class="control-label">Email Address</label>
              <ValidationProvider
                rules="email|required"
                tag="div"
                v-slot="{ classes }"
              >
                <input
                  type="email"
                  v-model="formData.email"
                  :class="classes"
                  name="email"
                  placeholder="Email Address Here"
                />
              </ValidationProvider>
              <span>
                A valid e-mail address. All e-mails from the system will be sent
                to this address. The e-mail address is not made public and will
                only be used if you wish to receive a new password or wish to
                receive certain news or notifications by e-mail.
              </span>
            </div>
            <div
              class="user-type"
              v-if="(isEdit && selectedTerritoryManager !== null) || !isEdit"
            >
              <dropdown
                :data="createdDealer.territory_managers"
                :status="false"
                fieldName="name"
                label="Territory Manager"
                @select="selectTerritoryManager"
                :validation="true"
                inputName="territory_manager"
                :editValue="selectedTerritoryManager"
                :all="false"
                placeholder="Territory Manager"
                :search="true"
              ></dropdown>
            </div>
            <div class="form-group w-full">
              <label class="control-label">Office Number</label>
              <ValidationProvider
                :rules="{ regex: /^[(][2-9]\d{2}[)]\s[2-9]\d{2}[-]\d{4}$/ }"
                tag="div"
                v-slot="{ classes }"
              >
                <input
                  :class="classes"
                  v-model="formData.office_number"
                  v-mask="'(###) ###-####'"
                  type="text"
                  name="number_office"
                  placeholder="(555) 555-5555"
                />
              </ValidationProvider>
            </div>
            <div class="form-group w-full">
              <label class="control-label">Fax Number</label>
              <ValidationProvider
                :rules="{ regex: /^[(][2-9]\d{2}[)]\s[2-9]\d{2}[-]\d{4}$/ }"
                tag="div"
                v-slot="{ classes }"
              >
                <input
                  :class="classes"
                  v-model="formData.fax_number"
                  v-mask="'(###) ###-####'"
                  type="text"
                  name="number_fax"
                  placeholder="(555) 555-5555"
                />
              </ValidationProvider>
            </div>
            <div class="form-group w-full">
              <label class="control-label">Website Address</label>
              <ValidationProvider tag="div" v-slot="{ classes }">
                <input
                  :class="classes"
                  v-model="formData.website_address"
                  type="text"
                  name="website"
                  placeholder="www.dealerwebsite.com"
                />
              </ValidationProvider>
            </div>
            <div class="form-group w-full">
              <label class="control-label">Dealer Code</label>
              <ValidationProvider
                rules="required"
                tag="div"
                v-slot="{ classes }"
              >
                <input
                  :class="classes"
                  v-model="formData.dealer_code"
                  type="text"
                  name="code"
                  placeholder="C00585"
                />
              </ValidationProvider>
            </div>
            <div class="form-group w-full" v-if="!isParent">
              <!-- <label class="control-label">Parent Dealer</label> -->
              <!-- <ValidationProvider
                rules="required"
                tag="div"
                v-slot="{ classes }"
              > -->

                <dropdown
                  :data="createdDealer.parent_dealerships"
                  fieldName="name"
                  label="Parent Dealer"
                  @select="selectParentDealership"
                  inputName="parent_dealer_id"
                  :editValue="selectedParentDealership"
                  :all="false"
                  :empty="true"
                  emptyLabel="None"
                  placeholder="Parent Dealership"
                  :search="true"
                ></dropdown>
              <!-- </ValidationProvider> -->
            </div>
            <div class="form-group w-full">
              <label class="control-label">Labor Rate</label>
              <ValidationProvider tag="div" v-slot="{ classes }">
                <input
                  :class="classes"
                  v-model="formData.labor_rate"
                  type="text"
                  name="rate"
                  placeholder="$52.99"
                />
              </ValidationProvider>
            </div>
            <div class="form-group w-full">
              <label class="control-label">Primary Shipping Address</label>
              <ValidationProvider tag="div" v-slot="{ classes }">
                <input
                  :class="classes"
                  v-model="formData.shipping_address"
                  type="text"
                  name="address"
                  placeholder="5911 Sunshine Ave. Jefferson City, MO 49623"
                />
              </ValidationProvider>
            </div>
            <div class="form-group w-full">
              <label class="control-label">Additional Addresses</label>
              <ValidationProvider
                v-for="address, i in formData.additional_addresses"
                v-bind:key="`address+${i}`"
                tag="div"
                v-slot="{ classes }"
              >
                <div
                  class="additional-address-line mb-2"
                  v-if="!address.deleted"
                >
                  <input
                    :class="classes"
                    v-model="formData.additional_addresses[i].additional_address"
                    type="text"
                    name="notes"
                  />
                  <button
                    type="button"
                    class="c-removeButton"
                    @click.prevent="$event.target.blur(); removeAddress(i)"
                  ></button>
                </div>
              </ValidationProvider>
              <button
                role="button"
                class="btn btn-info px-4"
                @click.prevent="addAdditionalAddress"
              >
                + Add Additional Address
              </button>
            </div>
            <div class="form-group w-full input-checkbox">
              <div class="flex">
                <label class="control-label pb-0">Billing Address</label>
                <div class="radio-block">
                  <label for="billingAddress" class="remember">
                    <input
                      type="checkbox"
                      v-model="sameShippingAddress"
                      id="billingAddress"
                      name="agree"
                    />
                    <span></span>
                    <p>Same as Shipping Address</p>
                  </label>
                </div>
              </div>
              <ValidationProvider tag="div" v-slot="{ classes }">
                <input
                  :class="classes"
                  v-if="sameShippingAddress"
                  v-model="formData.shipping_address"
                  disabled
                  class="opacity-50 cursor-not-allowed"
                  type="text"
                  name="billing_address"
                />
                <input
                  :class="classes"
                  v-else
                  v-model="formData.billing_address"
                  type="text"
                  name="billing_address"
                />
              </ValidationProvider>
            </div>
            <div class="form-group w-full">
              <label class="control-label">Dealership Notes</label>
              <ValidationProvider tag="div" v-slot="{ classes }">
                <input
                  :class="classes"
                  v-model="formData.dealership_notes"
                  type="text"
                  name="notes"
                />
              </ValidationProvider>
            </div>
            <div class="radio-block">
              <label for="flagDealer" class="remember">
                <input
                  type="checkbox"
                  v-model="formData.flag_dealer"
                  id="flagDealer"
                  name="agree"
                />
                <span></span>
                <div>Flag Dealer</div>
              </label>
            </div>
          </div>
          <div class="send">
            <div class="edit-link" @click="showDiscardModal = true">Discard</div>
            <button
              class="btn btn-black px-60"
              type="submit"
              :disabled="buttonDisabled"
              :class="buttonDisabled ? 'opacity-50 cursor-wait' : ''"
            >
              Save Dealer
            </button>
          </div>
        </div>
        <div v-if="isParent" class="child-dealerships-col">

          <div class="subtitle">Child Dealerships</div>
          <div class="c-callout">
            <router-link
              v-for="dealership in childDealerships"
              :key="`child_dealership_${dealership.id}`"
              tag="a"
              class="text-blue underline"
              :to="{ name: 'dealerDetail', params: { id: dealership.id}}"
            >{{ dealership.name }}</router-link>  
          </div>
        </div>
      </div>
      <ConfirmModal
        text="Discard changes?"
        confirmBtn="Discard"
        :show="showDiscardModal"
        @confirm="$router.push('/admin/dealers')"
        @close="showDiscardModal = false"
      />
    </ValidationObserver>
  </section>
</template>

<script>
import PageHeader from "../../../component/PageHeader";
import Dropdown from "../../../component/Dropdown";
import ConfirmModal from "../../../component/ConfirmModal";
import { mapActions, mapState } from "vuex";

export default {
  name: "addDealer",
  data() {
    return {
      selectedTerritoryManager: null,
      selectedParentDealership: null,
      isEdit: false,
      showDiscardModal: false,
      buttonDisabled: false,
      sameShippingAddress: false,
      formData: {
        flag_dealer: false,
        additional_addresses: [],
      },

      isParent: false,
      childDealerships: [],
    };
  },
  created() {
    if (this.$route.params.id === "add") {
      if (this.user.role.id > 1) {
        this.$router.back();
      }

      this.isEdit = false;
      this.createDealerData()
        .then((res) => {})
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    } else {
      this.isEdit = true;
      this.editDealer();
    }
  },
  computed: {
    ...mapState({
      createdDealer: (state) => state.dealers.createdDealer,
      user: (state) => state.auth.user,
    }),
  },
  components: {
    PageHeader,
    Dropdown,
    ConfirmModal,
  },
  methods: {
    ...mapActions({
      createDealerData: "dealers/createDealerData",
      createDealer: "dealers/createDealer",
      updateDealer: "dealers/updateDealer",
      editDealerData: "dealers/editDealerData",
    }),
    selectTerritoryManager(item) {
      this.selectedTerritoryManager = item.name;
      this.formData.tm_id = item.id;
    },
    selectParentDealership(dealership) {
      this.selectedParentDealership = dealership.name;
      this.formData.parent_dealership_id = dealership.id;
    },
    editDealer() {
      this.editDealerData({ id: this.$route.params.id })
        .then((res) => {
          const {
            territory_manager,
            parent_dealership,
            child_dealerships,
            ...data
          } = this.createdDealer.dealership;
          this.formData = data;
          
          if (territory_manager) {
            this.selectTerritoryManager(territory_manager);
          }
          if (parent_dealership) {
            this.selectParentDealership(parent_dealership);
          }
          if (child_dealerships.length) {
            this.isParent = true;
            this.childDealerships = child_dealerships;
          }
          
          if (
            this.formData.shipping_address === this.formData.billing_address
            ) {
              this.sameShippingAddress = true;
            }
        })
        .catch((err) => {
          this.$toasted.error(err.response?.data?.message || "Server Error");
        });
    },
    addAdditionalAddress() {
      this.formData.additional_addresses.push({
        additional_address: ""
      })
    },
    removeAddress(i) {
      this.$set(this.formData.additional_addresses[i], 'deleted',  true);
    },
    saveDealer() {
      if (this.sameShippingAddress) {
        this.formData.billing_address = this.formData.shipping_address;
      }
      this.$refs.form
        .validate()
        .then((success) => {
          if (success) {
            this.buttonDisabled = true;

            if (this.isEdit) {
              this.updateDealer({
                id: this.$route.params.id,
                data: this.formData,
              })
                .then((res) => {
                  this.$router.push("/admin/dealers");
                })
                .catch((err) => {
                  this.buttonDisabled = false;
                  if (err.response.data.errors) {
                    let errors = err.response.data.errors;
                    this.$toasted.error(errors[Object.keys(errors)[0]]);
                    return false;
                  }
                  this.$toasted.error(
                    err.response.data.message || "Server Error"
                  );
                });
            } else {
              this.createDealer(this.formData)
                .then((res) => {
                  this.$router.push("/admin/dealers");
                })
                .catch((err) => {
                  this.buttonDisabled = false;
                  if (err.response.data.errors) {
                    let errors = err.response.data.errors;
                    this.$toasted.error(errors[Object.keys(errors)[0]]);
                    return false;
                  }
                  this.$toasted.error(
                    err.response.data.message || "Server Error"
                  );
                });
            }
          } else {
            this.$toasted.error("Your submission contains errors");
          }
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    },
  },
};
</script>

<style type="text/css" scoped lang="scss">
.input-checkbox {
  .flex {
    align-items: center;
    padding-bottom: 8px;

    .pb-0 {
      padding-bottom: 0 !important;
    }
    .radio-block {
      margin-top: 0 !important;
      margin-left: 10px;
      .remember {
        margin-top: 0 !important;

        span {
          font-style: normal !important;
        }

        p {
          font-style: italic;
          font-weight: 500;
          font-size: 10px;
          line-height: 14px;
          color: #5f5f5f;
          margin-left: 5px;
        }
      }
    }
  }
}

.additional-address-line {
  position: relative;

  input {
    padding-right: 20px;
  }
  .c-removeButton {
    opacity: 0;
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);

    &:focus {
      opacity: 1;
    }
  }

  &:hover .c-removeButton,
  input:focus + .c-removeButton {
    opacity: 1;
  }
}

.user-details {

  flex: 0 1 600px;
  margin-right: 50px;
  .form-group {
    padding-top: 20px;

    &:first-of-type {
      padding-top: 0;
    }

    span {
      font-style: italic;
      font-weight: $font-weight-medium;
      font-size: 10px;
      line-height: 14px;
      color: #5F5F5F;
      padding-top: 10px;
    }
  }

}

.child-dealerships-col {
  flex: 0 1 250px;
  min-width: max-content;
  a {
    display: block;
    font-size: 14px;
    font-weight: 500;
    
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
</style>
