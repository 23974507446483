<template>
  <section class="add-user col-span-12">
    <ValidationObserver
      @submit.prevent="saveNews"
      tag="form"
      class="container"
      ref="form"
    >
      <PageHeader
        :title="isEdit ? 'Edit News' : 'Add News'"
        backText="Back to Mange News"
        :backRoute="{ name: 'ManageNews', query: { ...indexFilters } }"
        @save="saveNews"
        @discard="showDiscardModal = true"
        :buttonsDisabled="disableBtn"
      />
      <div class="add-news-details">
        <div class="subtitle">News Details</div>
        <div class="add-news-block">
          <div class="news-container">
            <div class="form-group w-full">
              <label class="control-label">Title</label>
              <ValidationProvider
                rules="required"
                tag="div"
                v-slot="{ classes }"
              >
                <input
                  :class="(error ? 'error' : '', classes)"
                  type="text"
                  name="name"
                  v-model="newsData.title"
                  @input="error = false"
                  placeholder="News Article Title"
                />
              </ValidationProvider>
            </div>
            <ValidationProvider
              tag="div"
              rules="required"
              v-slot="{ errors, classes }"
              class="form-group w-full mt-3"
            >
              <ckeditor
                id="ckeEditor"
                :class="errors.length ? 'is-invalid' : ''"
                name="article"
                v-model="newsData.article"
                :config="editorConfig"
              ></ckeditor>
            </ValidationProvider>
            <div class="save-as">
              <dropdown
                :data="statuses"
                :status="false"
                fieldName="name"
                label="Save As"
                @select="selectStatus"
                :validation="true"
                inputName="status"
                :editValue="newsData.status"
                :all="false"
                placeholder="Status"
              ></dropdown>
            </div>
          </div>
        </div>
        <div class="send">
          <div class="edit-link" @click="showDiscardModal = true">Discard</div>
          <button
            :disabled="disableBtn"
            :class="disableBtn ? 'opacity-50 cursor-wait' : ''"
            class="btn btn-black px-60"
            type="submit"
          >
            Save
          </button>
        </div>
      </div>
      <ConfirmModal
        text="Discard changes?"
        confirmBtn="Discard"
        :show="showDiscardModal"
        @confirm="returnToIndex"
        @close="showDiscardModal = false"
      />
    </ValidationObserver>
  </section>
</template>

<script>
import PageHeader from "../../../component/PageHeader";
import Dropdown from "../../../component/Dropdown";
import ConfirmModal from "../../../component/ConfirmModal";
import { mapActions, mapState } from "vuex";

export default {
  name: "addNews",
  data() {
    return {
      disableBtn: false,
      showDiscardModal: false,
      error: false,
      editorData: "",
      isEdit: false,
      newsData: {
        status: "",
      },
      editorConfig: {
        contentsCss: "",
        language: "en",
        toolbar: [
          [
            "Source",
            "Bold",
            "Italic",
            "Strike",
            "BulletedList",
            "Image",
            "Table",
            "Link",
            "bidi",
          ],
        ],
      },
    };
  },
  components: {
    PageHeader,
    Dropdown,
    ConfirmModal,
  },
  computed: {
    ...mapState({
      statuses: (state) => state.news.statuses,
      editData: (state) => state.news.editData,
      indexFilters: (state) => state.news.indexFilters,
    }),
  },
  created() {
    if (this.$route.params.id) {
      this.isEdit = true;
      this.editNews();
    } else {
      this.create()
        .then((res) => {})
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    }
  },
  methods: {
    ...mapActions({
      createNews: "news/createNews",
      updateNews: "news/updateNews",
      create: "news/create",
      edit: "news/edit",
    }),
    selectStatus(sa) {
      this.newsData.status = sa.name;
    },
    editNews() {
      this.edit({ id: this.$route.params.id })
        .then(() => {
          this.newsData = this.editData;
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    },
    saveNews() {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.disableBtn = true;
          if (!this.$route.params.id) {
            this.createNews(this.newsData)
              .then(() => {
                this.returnToIndex();
              })
              .catch((err) => {
                if (err.response.data.errors.title) {
                  this.error = true;
                  this.$toasted.error(err.response.data.errors.title[0]);
                } else {
                  this.$toasted.error(
                    err.response.data.message || "Server Error"
                  );
                }
              })
              .finally(() => {
                this.disableBtn = false;
              });
          } else {
            this.updateNews({ id: this.$route.params.id, data: this.newsData })
              .then(() => {
                this.returnToIndex();
              })
              .catch((err) => {
                this.$toasted.error(
                  err.response.data.message || "Server Error"
                );
              })
              .finally(() => {
                this.disableBtn = false;
              });
          }
        } else {
          this.$toasted.error("Your submission contains errors");
        }
      });
    },
    returnToIndex() {
      this.$router.push({
        name: "ManageNews",
        query: { ...this.indexFilters },
      });
    },
  },
};
</script>

<style scoped>
.error {
  border: 2px solid #e3342f !important;
}
</style>
