<template>
  <section
    class="
      create-claims-next
      col-span-12
      sm:col-span-12
      md:col-span-12
      lg:col-span-12
      xl:col-span-12
    "
  >
    <ValidationObserver tag="div" class="container" ref="form">
      <PageHeader
        :title="
          isEdit ? 'Edit Claim #' + claimEdit.claim_details.id : 'Create Claim'
        "
        backText="Back To Claims"
        :backRoute="{ name: 'Claims', query: { ...claimFilters } }"
        :saveButton="userCanSubmit"
        saveText="Submit"
        @save="createOrUpdateClaim()"
        @discard="showDiscardModal = true"
        :buttonsDisabled="buttonDisabled"
      >
        <template v-slot:actions>
          <div style="margin-right: 30px" v-if="isEdit">
            <dropdown
              :data="claimEdit.claim_statuses"
              fieldName="status"
              @select="selectStatus"
              :validation="true"
              :status="true"
              inputName="status"
              :all="false"
              :editValue="selectedStatus"
              :disabled="!userCanApprove"
              style="margin-left: 15px"
            ></dropdown>
          </div>
        </template>
        <template v-slot:actions-after>
          <button
            type="button"
            @click="createOrUpdateClaim('draftClaim')"
            :readonly="buttonDisabled"
            :class="buttonDisabled ? 'opacity-50 cursor-wait' : ''"
            class="btn btn-info px-40"
            style="margin-left: 15px"
          >
            Save as Draft
          </button>
        </template>
      </PageHeader>

      <div class="grid grid-cols-11 gap-0">
        <div
          class="
            col-span-11
            sm:col-span-11
            md:col-span-11
            lg:col-span-8
            xl:col-span-8
          "
        >
          <div class="details">
            <div class="subtitle">Claim Details</div>
            <div class="form-block">
              <div class="container-form">
                <div class="grid grid-cols-9 gap-3">
                  <div
                    v-if="user && user.role.id !== 3"
                    class="
                      dealership
                      col-span-3
                      sm:col-span-3
                      md:col-span-3
                      lg:col-span-3
                      xl:col-span-3
                    "
                  >
                    <div v-if="(isEdit && dealershipName !== '') || !isEdit">
                      <dropdown
                        :data="claimCreate.dealership"
                        :status="false"
                        fieldName="name"
                        label="Dealer Placing Claim"
                        @select="dealershipSelect"
                        :validation="true"
                        inputName="dealership"
                        :editValue="dealershipName"
                        :all="false"
                        :search="true"
                      ></dropdown>
                    </div>
                  </div>
                  <div
                    v-if="(isEdit && cause_code) || !isEdit"
                    class="caus-dropdown col-span-2"
                  >
                    <dropdown
                      :data="causeCodes"
                      :status="false"
                      fieldName="name"
                      label="Cause Code"
                      @select="causSelect"
                      :validation="true"
                      inputName="code"
                      :editValue="cause_code"
                      :all="false"
                      placeholder="Select"
                    ></dropdown>
                  </div>
                  <div class="date form-group col-span-2">
                    <div>
                      <label class="control-label"
                        >Failure Date (mm/dd/yyyy)</label
                      >
                      <div class="relative">
                        <ValidationProvider
                          rules="required"
                          class="calendar-func"
                          tag="div"
                          v-slot="{ classes }"
                        >
                          <functional-calendar
                            placeholder="Select"
                            :is-modal="true"
                            ref="Calendar"
                            :marked-dates="failureData.selectedInCalendar"
                            :is-date-picker="true"
                            :date-format="'mm/dd/yyyy'"
                            :change-year-function="true"
                            v-model="dataPickerFailure"
                            :class="classes"
                            class="input p-0"
                          ></functional-calendar>
                        </ValidationProvider>
                        <inline-svg
                          class="down"
                          :src="
                            require('@/assets/image/icons/chevron-down.svg')
                          "
                        />
                      </div>
                      <span
                        class="text-red-500"
                        v-if="errorMessageFailureDate"
                        >{{ errorMessageFailureDate }}</span
                      >
                    </div>
                  </div>
                  <div class="date form-group col-span-2">
                    <div>
                      <label class="control-label"
                        >Repair Date (mm/dd/yyyy)</label
                      >
                      <div class="relative">
                        <ValidationProvider
                          rules="required"
                          class="calendar-func"
                          tag="div"
                          v-slot="{ classes }"
                        >
                          <functional-calendar
                            placeholder="Select"
                            :is-modal="true"
                            ref="CalendarRepair"
                            :marked-dates="repairData.selectedInCalendar"
                            :is-date-picker="true"
                            :date-format="'mm/dd/yyyy'"
                            :change-year-function="true"
                            v-model="dataPickerRepair"
                            :class="classes"
                            class="input p-0"
                          ></functional-calendar>
                        </ValidationProvider>
                        <inline-svg
                          class="down"
                          :src="
                            require('@/assets/image/icons/chevron-down.svg')
                          "
                        />
                      </div>
                      <span
                        class="text-red-500"
                        v-if="errorMessageRepairDate"
                        >{{ errorMessageRepairDate }}</span
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="grid grid-cols-9 gap-3"
                  v-if="claimEdit.claim_details"
                >
                  <div
                    class="
                      form-group form-input
                      col-span-6
                      sm:col-span-6
                      md:col-span-6
                      lg:col-span-6
                      xl:col-span-3
                    "
                  >
                    <label class="control-label">Hours on Tractor</label>
                    <ValidationProvider
                      rules="required"
                      tag="div"
                      v-slot="{ classes }"
                    >
                      <input
                        type="text"
                        name="hours_used"
                        class="pl-3 outline-none input"
                        v-model="claimEdit.claim_details.hours_used"
                        :class="classes"
                      />
                    </ValidationProvider>
                  </div>
                  <div
                    class="
                      form-group form-input
                      col-span-6
                      sm:col-span-6
                      md:col-span-6
                      lg:col-span-6
                      xl:col-span-3
                    "
                  >
                    <label class="control-label"
                      >Warranty Administrator’s Name</label
                    >
                    <ValidationProvider
                      rules="required"
                      tag="div"
                      v-slot="{ classes }"
                    >
                      <input
                        type="text"
                        name="admin_name"
                        class="pl-3 outline-none input"
                        v-model="claimEdit.claim_details.administrator_name"
                        :class="classes"
                      />
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="parts">
            <div class="flex-block">
              <div class="subtitle items-center">Labor & Parts Details</div>
              <div class="btn-group">
                <button
                  v-if="claimCreate.flat_rate_manual"
                  type="button"
                  :class="loadingRateManual ? 'loading' : ''"
                  @click="openLoaderFlatManual(claimCreate.flat_rate_manual)"
                >
                  <loading v-if="loadingRateManual" />Tractor Flat Rate Manual
                </button>
                <button
                  v-if="claimCreate.loaderflatrate"
                  type="button"
                  @click="openLoaderFlatManual(claimCreate.loaderflatrate)"
                  :class="loadingManual ? 'loading' : ''"
                >
                  <loading v-if="loadingManual" />Loader Flat Rate Manual
                </button>
              </div>
            </div>
            <div
              class="form-block"
              v-for="(labor, index) in labors"
              v-bind:key="`labor_${index}`"
            >
              <div class="container-form">
                <div class="grid grid-cols-9 gap-2 relative mb-6">
                  <div
                    class="
                      form-group
                      col-span-3
                      sm:col-span-3
                      md:col-span-3
                      lg:col-span-3
                      xl:col-span-3
                      text-left
                    "
                    v-click-outside="() => {hideSearchLabor(labor)}"
                  >
                    <label class="control-label">Labor Code</label>
                    <ValidationProvider
                      class="relative"
                      rules="required"
                      tag="div"
                      v-slot="{ classes }"
                    >
                      <input
                        type="text"
                        name="labor_code"
                        autocomplete="off"
                        @click="searchLaborCode('', index)"
                        @input="searchLaborCode($event.target.value, index)"
                        v-model="labor.value"
                        class="pl-3 input uppercase"
                        placeholder=""
                        :class="classes"
                      />
                      <div
                        class="labor-dropdown dropdown"
                        v-if="labor.openSearchLabor"
                      >
                        <div v-if="searchLabors && !searchLabors.length">
                          No Labor Code
                        </div>
                        <div
                          class="uppercase"
                          v-else
                          v-for="(lab, l) in searchLabors"
                          :key="`${index}-labor_${l}`"
                          @click="selectLaborCode(lab, index)"
                        >
                          {{ lab.value + " - " + lab.description }}
                        </div>
                      </div>
                    </ValidationProvider>
                  </div>
                  <div
                    class="
                      form-group
                      col-span-1
                      sm:col-span-1
                      md:col-span-1
                      lg:col-span-1
                      xl:col-span-1
                      text-center
                    "
                  >
                    <label class="control-label">Time</label>
                    <input
                      type="text"
                      name="time"
                      v-mask="maskTime"
                      :readonly="laborInputDisable"
                      @input="discoverSubtotal(labor.hours, labor.cost, index)"
                      v-model="labor.hours"
                      :class="laborInputDisable ? 'disabled text-center' : ''"
                      class="input text-center padding-0"
                      placeholder=""
                    />
                  </div>
                  <div
                    class="
                      form-group
                      col-span-1
                      sm:col-span-1
                      md:col-span-1
                      lg:col-span-1
                      xl:col-span-1
                      text-center
                    "
                  >
                    <label class="control-label">Cost</label>
                    <input
                      type="text"
                      name="cost"
                      v-mask="mask"
                      @input="discoverSubtotal(labor.hours, labor.cost, index)"
                      :readonly="laborInputDisable"
                      v-model="labor.cost"
                      :class="laborInputDisable ? 'disabled text-center' : ''"
                      class="input text-center padding-0"
                    />
                  </div>
                  <div
                    class="
                      form-group
                      col-span-1
                      sm:col-span-1
                      md:col-span-1
                      lg:col-span-1
                      xl:col-span-1
                      text-center
                    "
                  >
                    <label class="control-label">Subtotal</label>
                    <div class="disable-block-input">
                      <div class="disable-block">
                        <p v-if="labor.total.length">
                          ${{
                            parseFloat(String(labor.total)).toFixed(2)
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="close-o cursor-pointer" v-if="labors.length > 1">
                    <inline-svg
                      @click="deleteLabor(index)"
                      :src="require('@/assets/image/icons/delete.svg')"
                    />
                  </div>
                </div>
                <div
                  class="relative flex flex-col smd:flex-row"
                  v-for="(part, i) in labor.parts"
                  :key="`labor_${index}_part_${i}`"
                >
                  <div class="form-part flex-wrap flex-1">
                    <div class="form-group">
                      <label class="control-label">LP</label>
                      <CheckboxInput
                        :model.sync="part.lp"
                        name="lp"
                        class="mb-3"
                        @click="partLPUpdated(part)"
                      />
                    </div>
                    <div
                      v-click-outside="() => {hidePartDropdown(part)}"
                      class="form-group w-full smd:w-auto 3xl:w-50"
                      style="min-width: 200px"
                    >
                      <label class="control-label">Part #</label>
                      <div class="relative">
                        <ValidationProvider
                          rules="required"
                          tag="div"
                          v-slot="{ classes }"
                        >
                          <input
                            type="text"
                            name="labor_code"
                            :value="part.part"
                            autocomplete="off"
                            @focus="getPartsFromInvoice(part)"
                            @keydown.tab="hidePartDropdown(part, $event)"
                            @keyup.enter="$set(part, 'partOpen', true)"
                            @click="$set(part, 'partOpen', true)"
                            @input="
                              part.filterParts = true
                              updatePart(part, $event.target.value);
                            "
                            class="input pl-3 cursor-pointer"
                            v-bind:class="{
                              ...classes
                            }"
                          />
                        </ValidationProvider>
                        <inline-svg
                          class="down"
                          :src="
                            require('@/assets/image/icons/chevron-down.svg')
                          "
                        />
                        <div
                          class="part-dropdown dropdown"
                          v-if="part.partOpen"
                        >
                          <template
                            v-for="(invoicePart, x) in part.invoiceParts"
                          >
                            <div
                              tabindex="0"
                              v-if="
                                !part.filterParts ||
                                String(invoicePart.part_no).includes(part.part)
                              "
                              v-bind:key="`labor_${index}_part_${i}_invoicePart_${x}`"
                              @click.prevent="selectPart(invoicePart, part)"
                              @keyup.enter="selectPart(invoicePart, part)"
                            >
                              {{ invoicePart.part_no }}
                            </div>
                          </template>
                        </div>
                      </div>
                      <small
                        class="input-error-message"
                        v-if="part.didLoadInvoices && !part.invoiceParts.length && !part.loading"
                      >
                        No Part.
                      </small>
                    </div>
                    <div
                      v-click-outside="() => {hideInvoiceDropdown(part)}"
                      class="form-group w-full smd:w-auto 3xl:w-24"
                      style="min-width: 90px"
                    >
                      <label class="control-label">Invoice #</label>
                      <div class="relative">
                        <ValidationProvider
                          rules="required"
                          tag="div"
                          v-slot="{ classes }"
                        >
                          <input
                            type="text"
                            name="labor_code"
                            autocomplete="off"
                            v-model="part.invoice"
                            @focus="getInvoicesFromPart(part)"
                            @keydown.tab="hideInvoiceDropdown(part)"
                            @keyup.enter="$set(part, 'invoicesOpen', true)"
                            @click="$set(part, 'invoicesOpen', true)"
                            @input="$set(part, 'filterInvoices', true)"
                            class="input pl-3"
                            placeholder=""
                            :readonly="part.lp"
                            v-bind:class="{
                              'cursor-pointer': part.invoiceDropdown,
                              disabled: part.lp,
                              ...classes
                            }"
                          />
                        </ValidationProvider>
                        <inline-svg
                          class="down"
                          v-if="part.invoiceDropdown"
                          :src="
                            require('@/assets/image/icons/chevron-down.svg')
                          "
                        />
                        <div
                          class="invoice-dropdown dropdown"
                          v-if="part.invoiceDropdown && part.invoicesOpen"
                        >
                          <template
                            v-for="(invoice, inv) in part.invoices"
                          >
                            <div
                              v-bind:key="`labor_${index}_part_${i}_invoice_number_${inv}`"
                              v-if="
                                !part.filterInvoices ||
                                String(invoice.invoice_no).includes(part.invoice) ||
                                part.invoice == 'Invoice'"
                              @click="selectInvoice(invoice, i, index)"
                            >
                              {{ invoice.invoice_no }}
                            </div>
                          </template>
                        </div>
                      </div>
                      <small
                        class="input-error-message"
                        v-if="part.didLoadInvoices && !part.invoices.length && !part.loading"
                      >
                        No Invoice.
                      </small>
                    </div>
                    <div
                      class="form-group w-full smd:w-auto 3xl:w-20"
                      style="min-width: 210px"
                    >
                      <label class="control-label">Part Description</label>
                      <input
                        type="text"
                        name="labor_code"
                        :readonly="part.lp &&  !['Other Cost', 'Freight'].includes(part.part)"
                        v-model="part.partDescription"
                        class="input pl-3"
                        v-bind:class="{
                          disabled: part.lp &&  !['Other Cost', 'Freight'].includes(part.part)
                        }"
                        placeholder=""
                      />
                    </div>
                    <div
                      v-if="
                        !part.lp ||
                        !['Other Cost', 'Freight'].includes(part.part)
                      "
                      class="flex"
                    >
                      <div
                        class="form-group text-left smd:text-center 3xl:w-20"
                        style="min-width: 80px"
                      >
                        <label class="control-label">Cost Per</label>
                        <input
                          type="text"
                          name="total"
                          v-mask="mask"
                          @input="
                            getTotalPartCost($event.target.value, part)
                          "
                          :readonly="part.lp && user.role.id !== 1"
                          v-model="part.partPrice"
                          v-bind:class="{
                            disabled:part.lp && user.role.id !== 1,
                          }"
                          class="input text-center padding-0"
                          placeholder=""
                        />
                      </div>
                      <div
                        class="form-group text-left smd:text-center 3xl:w-12"
                        style="min-width: 45px; max-width: 55px"
                      >
                        <label class="control-label">Qty</label>
                        <ValidationProvider
                          rules="required"
                          tag="div"
                          v-slot="{ classes }"
                        >
                          <input
                            type="number"
                            name="total"
                            v-model="part.qty"
                            @input="getTotalPart($event.target.value, part)"
                            class="input"
                            placeholder=""
                            :class="classes"
                          />
                        </ValidationProvider>
                      </div>
                    </div>
                    <div
                      class="form-group text-left smd:text-center"
                      style="min-width: 120px"
                    >
                      <label class="control-label">Subtotal</label>
                      <input
                        v-if=" part.lp && ['Other Cost', 'Freight'].includes(part.part)"
                        type="text"
                        name="labor_code"
                        v-model="part.total"
                        class="input"
                        placeholder=""
                      />
                      <div v-else class="disable-block-input">
                        <div class="disable-block">
                          <p v-if="part.total">
                            ${{
                              parseFloat(String(part.total)).toFixed(2)
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex self-start items-end mb-12 smd:mb-0">
                    <div class="form-group form-group-checkbox">
                      <label
                        class="remember"
                        @click="selectCausalPart(part.part)"
                      >
                        <div>Causal Part</div>
                        <input
                          type="checkbox"
                          :disabled="true"
                          :checked="causalPart === part.part"
                        />
                        <span></span>
                      </label>
                    </div>
                    <div class="close-part cursor-pointer">
                      <inline-svg
                        @click="deletePart(index, i)"
                        :src="require('@/assets/image/icons/delete.svg')"
                      />
                    </div>
                  </div>
                </div>
                <div class="button">
                  <button type="button" @click="addPart(index)">
                    + Add Part
                  </button>
                </div>
              </div>
            </div>
            <div class="submit">
              <button type="button" @click="addLabor">+ Add Labor Code</button>
            </div>
            <div class="claim-totals">
              <div class="totals" v-if="claimEdit">
                <div class="container-subtotal">
                  <div class="flex justify-between">
                    <div>Labor Subtotal</div>
                    <div>
                      ${{ labor_subtotal.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                      }) }}
                    </div>
                  </div>
                  <div class="flex justify-between">
                    <div>Parts Subtotal</div>
                    <div>
                      ${{ parts_subtotal.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                      }) }}
                    </div>
                  </div>
                </div>
                <div class="container-total">
                  <div class="flex justify-between">
                    <div>Total</div>
                    <div>
                      ${{ parts_total.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                      }) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="notes">
            <div class="subtitle">Notes</div>
            <div class="form-block margint-20">
              <div class="container-form">
                <div class="grid grid-cols-1 gap-0">
                  <div
                    class="
                      form-group
                      col-span-1
                      sm:col-span-1
                      md:col-span-1
                      lg:col-span-1
                      xl:col-span-1
                      text-left
                    "
                  >
                    <label class="control-label">Description of Failure</label>
                    <ValidationProvider
                      v-if="repairValidation"
                      rules="required"
                      tag="div"
                      v-slot="{ classes }"
                    >
                      <input
                        type="text"
                        name="failure"
                        class="input pl-3"
                        placeholder=""
                        :class="classes"
                        v-model="claimEdit.description_of_failre"
                      />
                    </ValidationProvider>
                    <input
                      v-else
                      type="text"
                      name="failure"
                      class="input pl-3"
                      placeholder=""
                      v-model="claimEdit.description_of_failre"
                    />
                  </div>
                  <div
                    class="
                      form-group
                      col-span-1
                      sm:col-span-1
                      md:col-span-1
                      lg:col-span-1
                      xl:col-span-1
                      text-left
                      mt-6
                    "
                  >
                    <label class="control-label">Description of Repair</label>
                    <ValidationProvider
                      v-if="repairValidation"
                      rules="required"
                      tag="div"
                      v-slot="{ classes }"
                    >
                      <input
                        type="text"
                        name="repair"
                        class="input pl-3"
                        placeholder=""
                        :class="classes"
                        v-model="claimEdit.description_of_repair"
                      />
                    </ValidationProvider>
                    <input
                      v-else
                      type="text"
                      name="repair"
                      class="input pl-3"
                      placeholder=""
                      v-model="claimEdit.description_of_repair"
                    />
                  </div>
                  <div
                    class="
                      form-group
                      col-span-1
                      sm:col-span-1
                      md:col-span-1
                      lg:col-span-1
                      xl:col-span-1
                      text-left
                      mt-6
                    "
                    v-if="user.role.id !== 3"
                  >
                    <label class="control-label">Admin Notes</label>
                    <textarea
                      name="admin_notes"
                      class="input pl-3 untouched pristine required"
                      v-model="claimEdit.admin_notes"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex attachment">
              <div class="subtitle mr-8 pt-1">Attachments</div>
              <div class="submit cursor-pointer">
                <label for="files" id="btn" class="cursor-pointer">
                  <span>+ Add Attachment</span>
                  <input
                    id="files"
                    name="userfile"
                    class="invisible hidden"
                    @change="uploadCoverFile($event.target.files)"
                    accept="*"
                    type="file"
                  />
                </label>
              </div>
            </div>
            <span class="text-red-500" v-if="errorMessageFiles">{{
              errorMessageFiles
            }}</span>
            <div class="submit">
              <div class="tractor" v-for="(file, index) in claimEdit.files">
                <div
                  class="flex h-full items-center cursor-pointer"
                  :disabled="disableFileDownload.includes(file.id)"
                  @click="downloadClaimFile(file)"
                >
                  <div>
                    {{ file.name.replace(/\.\w{0,}$/, "") }}
                  </div>
                  <p>{{ file.name.match(/\.\w{0,}$/)[0] }}</p>
                </div>
                <inline-svg
                  @click="deleteFile(index, file)"
                  class="close-o cursor-pointer"
                  :src="require('@/assets/image/icons/delete.svg')"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row-start-1 lg:row-start-auto col-span-11 lg:col-span-3">
          <div class="container-right mt-8 lg:mt-12 lg:ml-8">
            <div class="subtitle">Tractor Details</div>
            <div class="details" v-if="claimEdit.tractor_details">
              <div
                class="
                  container-details
                  grid
                  lg:block
                  grid-cols-1
                  xs:grid-cols-2
                  md:grid-cols-3
                "
              >
                <div class="item">
                  <div>Customer Name</div>
                  <span v-if="claimEdit.tractor_details.owner_name">{{
                    claimEdit.tractor_details.owner_name
                  }}</span>
                  <span v-else>None Selected</span>
                </div>
                <div class="item">
                  <div>Address</div>
                  <span v-if="claimEdit.tractor_details.owner_address">{{
                    claimEdit.tractor_details.owner_address
                  }}</span>
                  <span v-else>None Selected</span>
                </div>
                <div class="item">
                  <div>Phone Number</div>
                  <span v-if="claimEdit.tractor_details.owner_phone">{{
                    claimEdit.tractor_details.owner_phone
                  }}</span>
                  <span v-else>None Selected</span>
                </div>
                <div class="item">
                  <div>Retail Dealership</div>
                  <span v-if="claimEdit.tractor_details.dealership && claimEdit.tractor_details.dealership.name">{{
                    claimEdit.tractor_details.dealership.name
                  }}</span>
                  <span v-else>None Selected</span>
                </div>
                <div class="item">
                  <div>Tractor Model</div>
                  <span v-if="claimEdit.tractor_details.model_name">{{
                    claimEdit.tractor_details.model_name
                  }}</span>
                  <span v-else>None Selected</span>
                </div>
                <div class="item">
                  <div>Tractor S/N</div>
                  <span v-if="claimEdit.tractor_details.tractor_sn">
                    <router-link
                      class="uppercase"
                      v-if="user && user.role.id !== 3"
                      :to="{
                        name: 'RegistrationDetail',
                        params: {
                          id: claimEdit.tractor_details.registration_id,
                        },
                      }"
                      >{{ claimEdit.tractor_details.tractor_sn }}</router-link
                    >
                    <span class="uppercase" v-else>{{
                      claimEdit.tractor_details.tractor_sn
                    }}</span>
                  </span>
                  <span v-else>None Selected</span>
                </div>
                <div class="item">
                  <div>Loader S/N</div>
                  <span v-if="claimEdit.tractor_details.loader_sn">{{
                    claimEdit.tractor_details.loader_sn
                  }}</span>
                  <span v-else>None Selected</span>
                </div>
                <div class="item">
                  <div>Backhoe S/N</div>
                  <span v-if="claimEdit.tractor_details.backhoe_sn">{{
                    claimEdit.tractor_details.backhoe_sn
                  }}</span>
                  <span v-else>None Selected</span>
                </div>
                <div class="item">
                  <div>Mid-Mount Mower S/N</div>
                  <span v-if="claimEdit.tractor_details.mower_sn">{{
                    claimEdit.tractor_details.mower_sn
                  }}</span>
                  <span v-else>None Selected</span>
                </div>
                <div class="item">
                  <div>Cabin S/N</div>
                  <span v-if="claimEdit.tractor_details.cabin_sn">{{
                    claimEdit.tractor_details.cabin_sn
                  }}</span>
                  <span v-else>None Selected</span>
                </div>
                <div class="item">
                  <div>Date of Sale</div>
                  <span v-if="claimEdit.tractor_details.sale_date">{{
                    claimEdit.tractor_details.sale_date
                  }}</span>
                  <span v-else>None Selected</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="send">
        <button
          type="button"
          @click="createOrUpdateClaim()"
          :readonly="buttonDisabled || !userCanSubmit"
          v-bind:class="{
            'cursor-not-allowed': !userCanSubmit,
            'opacity-50': buttonDisabled || !userCanSubmit,
            'cursor-wait': userCanSubmit && buttonDisabled,
          }"
          :disabled="!userCanSubmit"
          class="btn btn-black px-40"
        >
          Submit<loading class="loading" v-if="buttonDisabled" />
        </button>
        <button
          type="button"
          @click="createOrUpdateClaim('draftClaim')"
          :readonly="buttonDisabled"
          :class="buttonDisabled ? 'opacity-50 cursor-wait' : ''"
          class="btn btn-info px-40 ml-3"
        >
          Save as Draft<loading class="loading" v-if="buttonDisabled" />
        </button>
        <div class="edit-link" @click="showDiscardModal = true">Discard</div>
      </div>
    </ValidationObserver>
    <v-modal
      ref="imageModal"
      name="imageModal"
      class="modalImageShow"
      :width="'60%'"
      :height="'90%'"
    >
      <div class="modal-header-image">
        <inline-svg
          @click="hideimageModal"
          :src="require('@/assets/image/icons/close.svg')"
        />
      </div>
      <img :src="modalImage" alt="" />
    </v-modal>
    <ConfirmModal
      text="Discard changes?"
      confirmBtn="Discard"
      :show="showDiscardModal"
      @confirm="$router.push({ name: 'Claims', query: { ...claimFilters } })"
      @close="showDiscardModal = false"
    />
  </section>
</template>

<script>
import PageHeader from "../../component/PageHeader";
import Dropdown from "../../component/Dropdown";
import ConfirmModal from "../../component/ConfirmModal";
import Loading from "../../component/Loading";
import ClickOutside from "vue-click-outside";
import { mapActions, mapState } from "vuex";
import { http } from "../../../api";
import moment from "moment";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import CheckboxInput from "../../component/Inputs/Checkbox";

export default {
  props: ["id", "continueDraft"],
  name: "CreateClaimStep2",
  data() {
    return {
      mask: createNumberMask({
        prefix: "$",
        allowDecimal: true,
        includeThousandsSeparator: true,
        allowNegative: false,
      }),
      maskTime: createNumberMask({
        prefix: "",
        allowDecimal: true,
        includeThousandsSeparator: true,
        allowNegative: false,
      }),
      buttonDisabled: false,
      repairValidation: false,
      showDiscardModal: false,
      loadingRateManual: false,
      loadingManual: false,
      errorMessageRepairDate: "",
      imagesArr: [],
      selectedStatus: "",
      errorMessageFiles: "",
      errorMessageFailureDate: "",
      failureData: {
        current: null,
        selectedInCalendar: [],
      },
      repairData: {
        current: null,
        selectedInCalendar: [],
      },
      causeCodes: [
        { value: "cabin", name: "Cabin" },
        { value: "electric", name: "Electrical" },
        { value: "engine", name: "Engine" },
        { value: "exterior", name: "Exterior" },
        { value: "frontloader", name: "Front-End Loader" },
        { value: "hydraulic", name: "Hydraulic" },
        { value: "steering", name: "Steering" },
        { value: "transmission", name: "Transmission" },
      ],
      labors: [],
      claimEdit: {
        activity_history: [],
        claim_details: {},
        files: [],
        parts: {},
        tractor_details: {},
        claim_statuses: [],
      },
      dealershipName: "",
      cause_code: "",
      causalPart: null,
      causalPartArr: [],
      isEdit: false,
      modalImage: null,
      claimCreateFormData: {},
      dataPickerFailure: {},
      dataPickerRepair: {},
      searchLabors: [],
      laborCode: "",
      disableFileDownload: [],
      laborInputDisable: true,

      isDraft: false,
    };
  },
  components: {
    PageHeader,
    Dropdown,
    Loading,
    ConfirmModal,
    CheckboxInput,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      claimCreate: (state) => state.claim.claimCreate,
      claimFilters: (state) => state.claim.indexFilters,
      lpOptions: (state) => state.claim.lpOptions,
    }),
    labor_subtotal() {
      return this.labors.reduce((subtotal, item) => subtotal + (isNaN(parseFloat(item.total)) ? 0 : parseFloat(item.total)), 0);
    },
    parts_subtotal() {
      return this.labors.reduce((subtotal, labor) => (
        subtotal + labor.parts.reduce((laborTotal, part) => {
          let cost = part.total ?? 0;
          if (typeof part.total == "string") cost = parseFloat(cost.replace(",", ""));
          if (isNaN(cost)) cost = 0;
          return laborTotal + cost;
        }, 0)
      ), 0);
    },
    parts_total() {
      let total =
        parseFloat(this.parts_subtotal) + parseFloat(this.labor_subtotal);
      return total ? total : 0;
    },
    userCanApprove() {
      if (this.isDraft) return false;
      return (
        this.user.permissions.includes("CLAIM_APPR") ||
        this.user.permissions.includes("GLOBAL")
      );
    },
    userCanSubmit() {
      // return true;
      if (this.isEdit && !this.continueDraft) {
        return (
          this.user.permissions.includes("CLAIM_EDIT") ||
          this.user.permissions.includes("GLOBAL")
        );
      } else {
        return (
          this.user.permissions.includes("CLAIM_SUBMIT") ||
          this.user.permissions.includes("GLOBAL")
        );
      }
    },
    causalPartDuplicate() {
      if (!this.causalPart) return false;

      return this.labors
        .reduce((arr, labor) => ([
          ...arr,
          ...labor.parts
        ]), [])
        .filter(p => p.part == this.causalPart)
        ?.length > 1;
    }
  },
  async created() {
    await this.getLP();
    if (this.user.role.id === 1) {
      this.laborInputDisable = false;
    }

    console.log('created');
    if (this.$route.params.id === "step-two") {
      this.isEdit = false;
      this.createClaim(this.$route.query.primary_sn);
      if (this.user.role.id === 3) {
        this.claimCreateFormData.dealership_id = this.user.dealership_id;
      }
    } else {
      console.log('isEdit');
      this.isEdit = true;
      this.getClaimEdit();
    }
  },
  methods: {
    ...mapActions({
      getClaimCreate: "claim/getClaimCreate",
      addNewClaim: "claim/addNewClaim",
      updateClaim: "claim/updateClaim",
      saveDraftClaim: "claim/saveDraftClaim",
      getLP: "claim/getLP",
    }),
    downloadClaimFile(file) {
      this.disableFileDownload.push(file.id);
      let url = file.file;
      let name = file.slug;
      http
        .get(url, {
          responseType: "blob",
        })
        .then((res) => {
          let blob = new Blob([res.data], {
            type: res.headers["content-type"],
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = name;
          link.click();
        })
        .catch((err) => {
          this.$toasted.error("Download failed");
        })
        .finally(() => {
          this.disableFileDownload = this.disableFileDownload.filter(
            (id) => id !== file.id
          );
        });
    },
    openImageModal(file) {
      http
        .get(file, { responseType: "blob" })
        .then((res) => {
          let blob = new Blob([res.data], { type: "application/pdf" });
          if (res.data.type.split("/")[0] === "image") {
            this.modalImage = URL.createObjectURL(blob);
            this.$modal.show("imageModal");
          } else {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.target = "_blank";
            link.click();
          }
        })
        .catch((err) => {
          if (err.response.status === 404) {
            this.$toasted.error(
              "File could not be located on server. Please contact TYM for assistance."
            );
          }
        });
    },
    hideSearchLabor(labor) {
      Object.assign(labor, {
        openSearchLabor: false,
      })
    },
    hidePartDropdown(part, e = null) {
      this.$set(part, 'partOpen', false);
      this.$forceUpdate();
    },
    hideInvoiceDropdown(part) {
      this.$set(part, 'invoicesOpen', false);
      this.$forceUpdate();
    },
    // navigateDropdown(e) {
    //   console.log(e);
    //   let dropdown = e.target.parentElement.parentElement.querySelector('.dropdown');
    //   console.log(dropdown);
    //   dropdown.firstChild.focus();
    // },
    hideimageModal() {
      this.modalImage = null;
      this.$modal.hide("imageModal");
    },
    dealershipHide() {
      this.dealershipOpen = false;
    },
    dealershipOpenModal() {
      this.dealershipOpen = !this.dealershipOpen;
    },
    selectCausalPart(part) {
      if (!part) {
        this.$toasted.error("Part fill in the field!");
        return;
      }

      this.causalPart = (this.causalPart === part) ? null : part;      
    },
    discoverSubtotal(hours, cost, index) {
      let costValue = cost.replace(/[$|,]/gi, '');
      costValue = parseFloat(costValue.length ? costValue : 0);

      let timeValue = hours.replace(/[$|,]/gi, '');
      timeValue = parseFloat(timeValue.length ? timeValue : 0);

      this.labors[index].total = ( timeValue * costValue ).toFixed(2);
    },


    updatePart(part, value) {
      let oldVal = part.part;
      
      if (!this.causalPartDuplicate && this.causalPart === oldVal) this.causalPart = value;
      this.$set(part, 'part', value);
    },

    partLPUpdated(part) {
      if (!this.causalPartDuplicate && this.causalPart === part.part) this.causalPart = null;

      this.$set(part, 'part', "");
      this.clearInvoice(part);

      if (part.lp) {
        Object.assign(part, {
          invoiceParts: [...this.lpOptions].map(opt => ({...opt})),
          invoices: [...this.lpOptions].map(opt => ({...opt})),
          didLoadInvoices: true,
          invoice: "LP",
          invoiceDropdown: false,
        });
      } else {
        Object.assign(part, {
          invoiceParts: [],
          invoices: [],
          lastLoad: false,
        })
      }
    },

    async getInvoiceParts(value, from="part") {
      if (!this.claimCreateFormData.dealership_id) {
        this.$toasted.error("Please select Dealer Placing Claim!");
        window.scrollTo(0, 0);
        throw new Error('dealer is required to get invoice parts');
      }

      if (!['part', 'invoice'].includes(from)) {
        throw new Error('incorrect from value: options are "part" or "invoice"');
      }

      try {
        const res = await http.get(
          `claims/invoice/${from}?${from}=${value}&dealership_id=${this.claimCreateFormData.dealership_id}`
        );
        
        return res.data.data.invoice_parts;
      } catch(err) {
        console.error(err);
        throw err;
      }
    },
    async getInvoicesFromPart(part) {
      try {

        if (part.lastLoad != 'invoices') {
          Object.assign(part, {
            invoices: [],
            invoiceParts: [],
            loading: true,
          });
          this.$forceUpdate();
        }

        const data = await this.getInvoiceParts(part.part, 'part');

        Object.assign(part, {
          invoiceParts: data,
          invoices: data,
        // invoicesOpen: true,
          didLoadInvoices: true,
          lastLoad: 'invoices',
          loading: false,
        });
        this.$forceUpdate();
      } catch(err) {
        console.error(err);
      }

    },
    async getPartsFromInvoice(part) {

      Object.assign(part, {
        // partOpen: true,
        didLoadInvoices: true,
        ...( !['parts','lp'].includes(part.lastLoad) ? {
          invoices: [],
          invoiceParts: [],
          loading: true,
        }:{})
      })

      // LP - load standard LP options
      if (part.lp) {
        Object.assign(part, {
          invoiceParts: [...this.lpOptions].map(opt => ({...opt})),
          invoices: [...this.lpOptions].map(opt => ({...opt})),
          lastLoad: 'lp',
          loading: false,
        });
        return;
      }
      this.$forceUpdate();

      try {
        let searchVal = String(part.invoice);
        if (searchVal.toLowerCase() == 'invoice') searchVal = "";
        const data = await this.getInvoiceParts(searchVal, 'invoice');

        Object.assign(part, {
          invoiceParts: data,
          invoices: data,
          lastLoad: 'parts',
          loading: false,
        });

      } catch(err) {
        console.error(err);
      }
    },
    clearInvoice(part) {
      Object.assign(part, {
        partDescription: "",
        costPer: "",
        total: "",
        qty: 1,
        partPrice: "",
        invoice: "Invoice",
        didLoadInvoices: false,
        invoiceDropdown: true,
      });
    },
    async selectInvoice(invoice, index, laborIndex) {
      console.log("selecting invoice");
      let part = {...this.labors[laborIndex].parts[index]};

      this.$set(this.labors[laborIndex].parts, index, {
        ...part,
        invoicesOpen: false,
        filterInvoices: false,
        invoice: invoice.invoice_no,
        // part: invoice.part_no,
        ...(part.part ? {
          partPrice: invoice.part_price,
          partDescription: invoice.part_description,
          costPer: invoice.part_price,
          total: invoice.partPrice * part.qty,
        }:{})
      });
      console.log('started');

      console.log(part);

      if (!this.claimCreateFormData.dealership_id) return;
      if (part.part) return; //part is already filled, stop here

      try {
        const data = await this.getInvoiceParts(invoice.invoice_no, 'invoice');
        if (!data.length) return;
        
        this.$set(this.labors[laborIndex].parts, index, {
          ...this.labors[laborIndex].parts[index],
          invoices: data,
          partOpen: true,
          ...(data.length === 1 ? {
            partOpen: false,
            part: data[0].part_no,
            partPrice: data[0].part_price,
            partDescription: data[0].part_description,
            costPer: data[0].part_price,
            total: (data[0].part_price ?? 0) * part.qty,
          }:{})
        })

        console.log('set');

      } catch(err) {}
      
    },
    async selectPart(newPart, part) {
      
      // let part = {...this.labors[laborIndex].parts[index]};
      if (!this.causalPartDuplicate && this.causalPart === part.part) this.causalPart = newPart.part_no;

      Object.assign(part, {
        ...part,
        partOpen: false,
        filterParts: false,
        part: newPart.part_no,
        ...(part.invoice ? {
          partPrice: newPart.part_price,
          partDescription: newPart.part_description,
          costPer: newPart.part_price,
          total: newPart.part_price * part.qty,
        }:{})
      });
      
      if (!this.claimCreateFormData.dealership_id) return;
      if (part.invoice && part.invoice !== "Invoice") return; //invoice is already filled, stop here

      try {
        const data = await this.getInvoiceParts(newPart.part_no, 'part');
        if (!data.length) return;
        
        Object.assign(part, {
          ...part,
          invoiceDropdown: true,
          invoices: data,
          invoicesOpen: true,
          ...(data.length === 1 ? {
            invoicesOpen: false,
            invoice: data[0].invoice_no,
            partPrice: data[0].part_price,
            partDescription: data[0].part_description,
            costPer: data[0].part_price,
            total: (data[0].part_price ?? 0) * part.qty,
          }:{})
        })

        if ( !data.find( i => i.invoice_no == part.invoice )) {
          //existing invoice no is not in the list of available invoices - clear
          this.clearInvoice(part);
        }

      } catch(err) {}
    },
    getTotalPart(value, part) {
      part.total = part.partPrice * value;
    },
    getTotalPartCost(value, part) {
      if (value) {
        let costValue = parseFloat(value.replace(/[$|,]/gi, ''));
        part.total = part.qty * costValue;
      } else {
        part.total = 0;
      }
    },
    selectStatus(status) {
      this.selectedStatus = status.status;
      this.claimCreateFormData.status = status.id;
    },
    searchLaborCode(code, index) {
      if (this.labors[index].value !== this.laborCode) {
        this.labors[index].hours = "";
        this.labors[index].total = "";
        this.labors[index].cost = "";
      }
      this.laborCode = code;

      // const primarySn = this.isEdit
      //   ? this.claimEdit.tractor_details.primary_sn
      //   : this.$route.query.primary_sn;

      const model = this.claimEdit.tractor_details.model_name;

      http
        .get(
          "claims/labor-search?model=" +
            model +
            "&labor_code=" +
            this.laborCode +
            "&dealership_id=" + (this.claimCreateFormData?.dealership_id ?? "")
        )
        .then((res) => {
          this.searchLabors = res.data.data.labors;
          this.labors[index].openSearchLabor = true;
          if (this.searchLabors.length > 1) {
            if (code == this.searchLabors[index].value) {
              this.labors[index].hours = this.searchLabors[0].hours;
              this.labors[index].cost = this.searchLabors[0].cost;
              this.labors[index].description = this.searchLabors[0].description;
            }
          } else if (this.searchLabors.length === 1) {
            this.labors[index].hours = this.searchLabors[0].hours;
            this.labors[index].cost = this.searchLabors[0].cost;
            this.labors[index].description = this.searchLabors[0].description;
          }
        })
        .catch((err) => {});
    },
    selectLaborCode(labor, index) {
      this.labors[index].hours = labor.hours;
      this.labors[index].value = this.laborCode =
        labor.value + " - " + labor.description;
      this.labors[index].value = this.labors[index].value.replace(/\s+/g, " ");
      this.labors[index].cost = labor.cost;
      this.labors[index].total = (
        parseFloat(labor.cost.replace(",", "")) * parseFloat(labor.hours)
      ).toFixed(2);
      this.labors[index].description = labor.description;
      this.labors[index].openSearchLabor = false;

      if (labor.id === 0 || this.user.role.id === 1) {
        this.laborInputDisable = false;
      } else {
        this.laborInputDisable = true;
      }
    },
    openLoaderFlatManual(manual) {
      const route = "/files/PDF/" + manual.split("PDF/")[1];
      window.open(route, "_blank");
    },
    addPart(index) {
      this.labors[index].parts.push({
        lp: false,
        part: "",
        invoice: "",
        partDescription: "",
        costPer: "",
        qty: "1",
        total: "",
        partPrice: "",
        invoices: [],
        invoiceParts: [],
        invoiceDropdown: true,
        invoicesOpen: false,
        partOpen: false,
      });
    },
    addLabor() {
      this.labors.push({
        value: "",
        hours: "",
        cost: "",
        total: "",
        description: "",
        openSearchLabor: false,
        parts: [],
      });
    },
    deleteLabor(index) {
      this.labors = this.labors.filter((item, i) => i !== index);
    },
    deletePart(index, partindex) {
      this.labors[index].parts = this.labors[index].parts.filter(
        (item, i) => i !== partindex
      );
      if (this.causalPart === this.labors[index].parts[partindex].part && !this.causalPartDuplicate) {
        this.causalPart = null;
      }
    },
    getClaimEdit() {
      http
        .get("claims/" + this.$route.params.id + "/edit")
        .then((res) => {
          this.claimEdit = res.data.data;
          console.log('claimEdit:', this.claimEdit);

          if (this.claimEdit.status.id != 4 && this.continueDraft) {
            this.returnToIndex();
          }

          if (this.claimEdit.status.id == 4) {
            this.isDraft = true;
          }

          Object.assign(this.claimCreate, {
            dealership: this.claimEdit.dealership,
            flat_rate_manual: this.claimEdit.flat_rate_manual,
            loaderflatrate: this.claimEdit.loaderflatrate,
          });
          ;
          this.dealershipName = this.claimEdit.claim_details.delaer.name;
          this.selectedStatus = this.claimEdit.claim_details.status.name;

          this.claimCreateFormData.status = this.claimEdit.claim_details.status.id;
          this.claimCreateFormData.dealership_id = this.claimEdit.claim_details.delaer.id;

          this.cause_code = this.claimEdit.claim_details.cause_code;
          this.cause_code = this.cause_code[0].toUpperCase() + this.cause_code.slice(1); // capitalize

          this.labors = this.claimEdit.labor_parts_description.labors.map(lab => ({
            ...lab,
            parts: lab.parts.map(part => ({
              ...part,
              invoices: [],
              invoiceParts: [],
              lp: String(part.invoice ?? "").toLowerCase() == 'lp',
              invoiceDropdown: String(part.invoice ?? "").toLowerCase() != 'lp',
            }))
          }));

          this.causalPart = this.claimEdit.labor_parts_description.causal_part;

          this.$refs.Calendar.ChooseDate(
            this.claimEdit.claim_details.failure_date
          );

          this.claimEdit.labor_parts_description?.labors?.forEach(labor => {
            labor.value += " " + labor.description;
          });

          this.failureData.selectedInCalendar.push(
            moment(this.claimEdit.claim_details.failure_date).format("M/D/yyyy")
          );
          setTimeout(() => {
            this.dataPickerFailure.selectedDate =
              this.claimEdit.claim_details.failure_date;
          }, 0);
          this.$refs.CalendarRepair.ChooseDate(
            this.claimEdit.claim_details.repair_date
          );
          this.repairData.selectedInCalendar.push(
            moment(this.claimEdit.claim_details.repair_date).format("M/D/yyyy")
          );
          setTimeout(() => {
            this.dataPickerRepair.selectedDate =
              this.claimEdit.claim_details.repair_date;
          }, 0);
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    },
    createClaim(primary_sn) {
      this.getClaimCreate({ primary_sn: primary_sn }).then((res) => {
        this.claimEdit.tractor_details = this.claimCreate.tractor_details;

        this.claimEdit.claim_statuses = this.claimCreate.claim_statuses;

        let defaultStatus = this.claimEdit.claim_statuses?.find(
          (el) => el.id == 4
        );

        this.selectedStatus = defaultStatus?.status ?? "Draft";
        this.claimCreateFormData.status = 4;
      });
    },
    checkValidantion() {
      if (this.dataPickerFailure.selectedDate) {
        this.errorMessageFailureDate = "";
        this.claimCreateFormData.failure_date =
          this.dataPickerFailure.selectedDate;
      } else {
        this.errorMessageFailureDate = "Fill in the field";
        this.$toasted.error("Your submission contains errors");
        return false;
      }

      if (this.dataPickerRepair.selectedDate) {
        this.errorMessageRepairDate = "";
        this.claimCreateFormData.repair_date =
          this.dataPickerRepair.selectedDate;
      } else {
        this.errorMessageRepairDate = "Fill in the field";
        this.$toasted.error("Your submission contains errors");
        return false;
      }

      let data = new FormData();
      for (var claimCreateFormDataItem in this.claimCreateFormData) {
        data.append(
          claimCreateFormDataItem,
          this.claimCreateFormData[claimCreateFormDataItem]
        );
      }
      if (this.claimEdit.files && this.claimEdit.files.length) {
        for (let i = 0; i < this.claimEdit.files.length; i++) {
          data.append("image_proof[]", this.claimEdit.files[i]);
        }
      }
      return true;
    },
    createOrUpdateClaim(claim = "") {
      if (claim === "draftClaim") {
        this.repairValidation = false;
      } else {
        this.repairValidation = true;
      }

      this.claimCreateFormData = {
        ...this.claimCreateFormData,

        administrator_name: this.claimEdit.claim_details.administrator_name,
        hours_used: this.claimEdit.claim_details.hours_used,
        failure_description: this.claimEdit.description_of_failre,
        repair_description: this.claimEdit.description_of_repair,
        admin_notes: this.claimEdit.admin_notes,
        tractor_sn: this.claimEdit?.tractor_details?.tractor_sn ?? "",
        loader_sn: this.claimEdit?.tractor_details?.loader_sn ?? "",
        backhoe_sn: this.claimEdit?.tractor_details?.backhoe_sn ?? "",
        mower_sn: this.claimEdit?.tractor_details?.mower_sn ?? "",
        causal_part: this.causalPart ?? "",
      };

      if (this.isEdit) {
        this.claimCreateFormData.cause_code = this.cause_code;
        this.claimCreateFormData.tractor_sn =
          this.claimEdit.tractor_details.tractor_sn ?? "";
      }

      this.$refs.form
        .validate()
        .then((success) => {
          if (success) {
            if (!this.checkValidantion()) {
              return false;
            }
            const data = new FormData();
            for (var claimCreateFormDataItem in this.claimCreateFormData) {
              data.append(
                claimCreateFormDataItem,
                this.claimCreateFormData[claimCreateFormDataItem]
              );
            }
            if (this.claimEdit.files && this.claimEdit.files.length) {
              for (let i = 0; i < this.claimEdit.files.length; i++) {
                data.append("image_proof[]", this.claimEdit.files[i]);
              }
            }

            this.labors.forEach((labor, index) => {
              let laborValue = labor.value.replace(" - " + labor.description, "");
              data.append("labors[" + index + "][value]", laborValue);
              data.append("labors[" + index + "][hours]", labor.hours);
              data.append(
                "labors[" + index + "][description]",
                labor.description
              );
              data.append("labors[" + index + "][cost]", labor.cost);
              data.append("labors[" + index + "][total]", labor.total);
              labor.parts.forEach((part, i) => {
                data.append(
                  "labors[" + index + "][parts][" + i + "][invoice]",
                  part.invoice
                );
                data.append(
                  "labors[" + index + "][parts][" + i + "][part]",
                  part.part
                );
                data.append(
                  "labors[" + index + "][parts][" + i + "][partDescription]",
                  part.partDescription
                );
                data.append(
                  "labors[" + index + "][parts][" + i + "][partPrice]",
                  part.partPrice
                );
                data.append(
                  "labors[" + index + "][parts][" + i + "][qty]",
                  part.qty
                );

                if (typeof part.total == "string") {
                  data.append(
                    "labors[" + index + "][parts][" + i + "][total]",
                    parseFloat(part.total.replace(",", ""))
                  );
                } else {
                  data.append(
                    "labors[" + index + "][parts][" + i + "][total]",
                    part.total
                  );
                }
              });
            });
            data.append("claim_total", this.parts_total);

            this.buttonDisabled = true;
            if (claim === "draftClaim") {
              // If on edit page
              if (this.isEdit && this.claimEdit) {
                // Set draft status
                data.set("status", 4);

                this.updateClaim({ id: this.$route.params.id, formData: data })
                  .then((res) => {
                    this.returnToIndex();
                  })
                  .catch((err) => {
                    this.buttonDisabled = false;
                    this.$toasted.error(
                      err.response.data.message || "Server Error"
                    );
                  });

                return;
              }

              this.saveDraftClaim({ formData: data })
                .then((res) => {
                  this.returnToIndex();
                })
                .catch((err) => {
                  this.buttonDisabled = false;
                  this.$toasted.error(
                    err.response.data.message || "Server Error"
                  );
                });
            } else {
              if (this.claimEdit.files.length > 0 || this.user.role.id === 1) {
                this.errorMessageFiles = "";
              } else {
                this.errorMessageFiles = "Please add attachment";
                this.buttonDisabled = false;
                return false;
              }
              if (this.isEdit) {
                // Make draft claim pending
                if (this.claimEdit && this.claimEdit.status.id === 4) {
                  data.set("status", 3);
                }
                this.updateClaim({ id: this.$route.params.id, formData: data })
                  .then((res) => {
                    this.returnToIndex();
                  })
                  .catch((err) => {
                    this.buttonDisabled = false;
                    this.$toasted.error(
                      err.response.data.message || "Server Error"
                    );
                  });
              } else {
                this.addNewClaim(data)
                  .then((res) => {
                    this.returnToIndex();
                  })
                  .catch((err) => {
                    this.buttonDisabled = false;
                    this.$toasted.error(
                      err.response.data.message || "Server Error"
                    );
                  });
              }
            }
          } else {
            this.$toasted.error("Your submission contains errors");
          }
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message || "Server Error");
        });
    },
    dealershipSelect(dealership) {
      this.dealershipName = dealership.name;
      this.claimCreateFormData.dealership_id = dealership.id;
      this.labors = [
        {
          value: "",
          hours: "",
          cost: "",
          total: "",
          openSearchLabor: false,
          description: "",
          parts: [],
        },
      ];
    },
    causSelect(causCode) {
      (this.cause_code = causCode.name),
        (this.claimCreateFormData.cause_code = causCode.value);
    },
    uploadCoverFile(file) {
      let uploadFile = file[0];
      this.claimEdit.files.push(uploadFile);
      this.errorMessageFiles = "";
      let arr = this.imagesArr;

      const reader = new FileReader();

      reader.addEventListener(
        "load",
        function () {
          // convert image file to base64 string
          arr.push(reader.result);
        },
        false
      );

      this.imagesArr = arr;

      if (uploadFile) {
        reader.readAsDataURL(uploadFile);
      }
    },
    deleteFile(index, file) {
      if (file.id) {
        http
          .delete("/claims/file/" + file.id + "/delete")
          .then((res) => {
            this.claimEdit.files = this.claimEdit.files.filter(
              (item) => item.id !== file.id
            );
          })
          .catch((err) => {
            this.$toasted.error("Claim file not found in FS");
          });
      } else {
        this.claimEdit.files = this.claimEdit.files.filter(
          (item, i) => index !== i
        );
        this.imagesArr = this.imagesArr.filter((item, i) => index !== i);
      }
    },
    returnToIndex() {
      this.$router.push({ name: "Claims", query: { ...this.claimFilters } });
    },
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style scoped>
.input-error-message {
  position: absolute;
  bottom: -14px;
  font-size: 11px;
  color: #ff111a;
}
</style>
