import { http } from '../../../api';

const modelInfo = {
    namespaced: true,
    state: {
        entries: [],
        modelTypes: [],
        pagination: {},
        initialPage: 1,
        model: {
            model: "",
            model_type: "",
            service_model: "",
            weight: "",
        },
        prefixList: [],

        indexFilters: {},
    },
    actions: {
        getModelInfo: ({ commit }, params) => {
            const q = new URLSearchParams(params);
            return http.get('model_info?' + q.toString()).then(res => {
                commit('setEntries', res.data.data.models);
                commit('setModelTypes', res.data.data.types);
            });
        },
        getInitialPage: ({ commit }, params) => {
            commit('setInitialPage', params)
        },
        getSingle: ({ commit }, model) => {
            return http.get(`model_info/${model}`).then(res => {
                commit('setModel', res.data.data.model);
                return res.data.data;
            });
        },
        getModelInfoCreate: ({ commit }, params) => {
            return http.get('model_info/create').then(res => {
                commit('setModelTypes', res.data.data.types);
            });
        },
        createModelInfo: ({ commit }, params) => {
            return http.post('model_info', {...params.formData}).then(res => {
                return res;
            })
        },
        getModelInfoEdit: ({ commit }, model) => {
            return http.get(`model_info/${model}/edit`).then(res => {
                commit('setModelTypes', res.data.data.types);
                commit('setModel', res.data.data.model);
                commit('setPrefixList', res.data.data.master_prefix_list);
                return res.data.data;
            });
        },
        updateModelInfo: ({ commit }, params) => {
            return http.put(`model_info/${params.model}`, {...params.formData}).then(res => {
                return res;
            })
        },
        deleteEntry: ({ commit }, model) => {
            return http.delete(`model_info/${model}`).then(res => {
                return res;
            }).catch(err => {
                throw err;
            })
        },

        setIndexFilters: ({commit}, params) => {
            commit('setIndexFilters', {...params});
        }
    },
    mutations: {
        setInitialPage(state, page) {
            state.initialPage = page
        },
        setEntries(state, data) {
            state.entries = data.data
            state.pagination = data.pagination
        },
        setModelTypes(state, data) {
            state.modelTypes = data;
        },
        setModel(state, data) {
            state.model = data;
        },
        setPrefixList(state, data) {
            state.prefixList = data;
        },
        setIndexFilters(state, data) {
            state.indexFilters = data;
        },
    },
    getters: {
        entries: state =>  state.entries,
        pagination: state =>  state.pagination,
        initialPage: state =>  state.initialPage,
        modelTypes: state => state.modelTypes,
        model: state => state.model,
        prefixList: state => state.prefixList,
        indexFilters: state => state.indexFilters,
    }
};

export default modelInfo;
